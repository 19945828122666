import React, { useState, useEffect } from "react";
import sanitize from "sanitize-html";
import Badge from '@mui/material/Badge';
import Icon from '../../../uhoo-components/iconLibrary.jsx';

import "./css/post.css";
import ViewComments from './viewComments.jsx';

import Modal from "../../../uhoo-components/Modal.jsx";
import ActionPost from "./actionPost.jsx";
import DeletePost from "./deletePost";
import ApprovedPost from "./approvedPost";

import SanitizeConfig from '../../../reactUtils/generalUtils/SanitizeConfig'

// Queries
import { likePost } from "../../../actions/uhoo/Post.actions";
import { getUser } from "../../../actions/uhoo/Users.actions";

//Localization
import { useTranslation } from 'react-i18next';

const Post = (props) => {

  let postInfo = props.item;

  //console.log(postInfo);

  const { t, i18n } = useTranslation(); //  Localization    
  const [userSearch, setUserSearch] = useState([]);
  const [modalAction, setModalAction] = useState({ show: false });
  const [showDeletePost, setShowDeletePost] = useState(false);
  const [showApprovedPost, setShowApprovedPost] = useState(false);

  useEffect(() => {
    const GetUserData = async (query) => {
      const info = await getUser(query);
      setUserSearch(info.data[0]);
    };

    if (postInfo.user === undefined) {
      let user = postInfo.creator;
      GetUserData(user);
    }

  }, []);

  function likeThePost() {
    setModalAction({ show: true, type: "like", post: postInfo });
  }

  function deletePost() {
    setShowDeletePost(true);
  }

  function approvePost() {
    setShowApprovedPost(true);
  }

  function showComents() {
    let div = document.getElementById("commentsPost-" + postInfo.uuid + "-" + props.column)
    div.classList.toggle("show");

    ////////////////////////////////////////////////////////////////////////////////////////////////
    var elInput = document.getElementById("commentInPost" + postInfo.uuid + "-" + props.column);
    elInput.addEventListener('keyup', function (e) {
      var keycode = e.keyCode || e.which;
      if (keycode == 13) {
        let comment = elInput.value;
        //console.log("Enter!", comment);
        setModalAction({ show: true, type: "comment", post: postInfo, comment: comment });
      }
    });
    ////////////////////////////////////////////////////////////////////////////////////////////////
  }

  function dropPostAction(event) {
    document.getElementById("dropPostAction-" + postInfo.uuid).classList.toggle("show");
    event.stopPropagation();
  }

  document.addEventListener('click', function (event) { // cerrar drop al dar click por fuera del elementos
    if (event.target.className !== "dropPostAction-content") {
      let get = document.getElementById("dropPostAction-" + postInfo.uuid);
      if (get !== null) { get.classList.remove("show"); } // pevencion de errores 
    }
    event.stopPropagation();
  });

  const sanitized = sanitize(postInfo.content, SanitizeConfig());

  return (
    <React.Fragment>
      <div className="post">
        <section className="headerPost">
          <div className="userPost">
            <div className="imgUserPost">
              {postInfo.user !== undefined && postInfo.user.avatar !== null && postInfo.user.avatar !== undefined
                ? <img src={postInfo.user.avatar.thumbnail} alt="user" />
                : (
                  postInfo.user === undefined && userSearch.avatar !== null && userSearch.avatar !== undefined
                    ? <img src={userSearch.avatar.thumbnail} alt="user" />
                    : ""
                )
              }
            </div>
            <div className="captionUserPost">
              {postInfo.user !== undefined
                ? <div className="nameUserPost">{postInfo.user.name + " " + (postInfo.user.middle_name !== null ? (postInfo.user.middle_name + " ") : "") + postInfo.user.last_name}</div>
                : <div className="nameUserPost">{userSearch.name + " " + (userSearch.middle_name !== null ? (userSearch.middle_name + " ") : "") + userSearch.last_name}</div>
              }
              {/*<div className="nameUserPost">{postInfo.user.name + " " + postInfo.user.last_name}</div>*/}
              <div className="datePost">{postInfo.created_at}</div>
            </div>
          </div>
          {postInfo && postInfo.approved && postInfo.approved != null ?
            <div className="actionPostDelete pointerCursor" onClick={deletePost}>
              <Icon.trash />
            </div>
          :
            <div className="actionPostDelete pointerCursor" onClick={dropPostAction}>
              <Icon.options />
            </div>
          }

          <div className="dropColumnAction" id={"dropPostAction-" + postInfo.uuid}>
            <div className="dropColumnAction-content">
              <div className="itemDropColumnAction hover" onClick={approvePost} role="button">
                <Icon.notify />
                <div>{t('basicWords.toApprove')}</div>
              </div>
              <div className="itemDropColumnAction hover" onClick={deletePost} role="button">
                <Icon.trash />
                <div>{t('uhoo.activity.column.trash')}</div>
              </div>
            </div>
          </div>
          
        </section>

        <section className="contentPost">
          <div className="imgContentPost">

            {postInfo.media.length > 0
              ? (
                <div className="slides">
                  {postInfo.media.map((item) => (
                    <>
                    {
                      item.url.search('.mp4') != -1 ?
                      // <video key={item.uuid} src={item.url} aalt={item.mime_type}></video>
                      <video controls poster={item.url}>
                        <source src={item.url} />
                      </video>
                      :
                      <>
                      {
                          item.mime_type.includes('png') || item.mime_type.includes('jpeg') || item.mime_type.includes('jpg') ?
                          <img key={item.uuid} src={item.thumbnail} alt={item.mime_type} />
                          :                          
                          <img  className="documentPreviePost" onClick={() => window.open(item.url,'_blank')} key={item.uuid} src='http://cdn.onlinewebfonts.com/svg/img_162799.png' alt='document' />
                      }
                      </>
                    }
                    </>
                  ))}
                </div>
              )
              : ""
            }
          </div>

          <div className="txtTitlePost">{postInfo.title}</div>
          <div className="txtContentPost"> <span dangerouslySetInnerHTML={{ __html: sanitized }} /> </div>
          
        </section>

        <section className="actionsPost">
          <div className="interactionsPost">
            <div>
              {/*
              <select id="channelsPost" className="">
                <option value="uuid">sin Filtro</option>
                {postInfo.channels.map((item) => (<option value={item.uuid}>{item.uuid}</option>))}
              </select>
              */}
            </div>
            <Badge badgeContent={postInfo.likes.length} color="primary" className="pointerCursor" onClick={likeThePost}>
              <Icon.like />
            </Badge>
            <Badge badgeContent={postInfo.comments.length} color="primary" className="pointerCursor" onClick={showComents}>
              <Icon.comment />
            </Badge>
          </div>
          <div className="commentsPost" id={"commentsPost-" + postInfo.uuid + "-" + props.column}>
            <div className="toCommentPost">
              <input type="text" name="" id={"commentInPost" + postInfo.uuid + "-" + props.column} placeholder={t('uhoo.activity.post.comment')} />
            </div>
            {postInfo.comments.length > 0
              ? (<ViewComments key={postInfo.uuid + props.key} item={postInfo.comments} />)
              : ""
            }
          </div>
        </section>
      </div>

      {modalAction.show ? (
        <Modal>
          <ActionPost
            setModalAction={setModalAction}
            modalAction={modalAction}
            audiences={postInfo.channels}
          />
        </Modal>
      ) : null}

      {showApprovedPost ? (
        <Modal>
          <ApprovedPost
            setOpenModal={setShowApprovedPost}
            post={postInfo}
          />
        </Modal>
      ) : null}

      {showDeletePost ? (
        <Modal>
          <DeletePost
            setOpenModal={setShowDeletePost}
            post={postInfo}
          />
        </Modal>
      ) : null}

    </React.Fragment>
  );
};

export default Post;