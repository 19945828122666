import React, { useState, useEffect, useContext } from "react";

import { ReactComponent as OptionCard } from '../../../assets/icons/navigation/optionCard.svg';

//Localization
import { useTranslation } from 'react-i18next';

//Context
import { UsersCommunityContext } from '../contex';

import "./css/communityCard.css";
import Modal from "../../../uhoo-components/Modal.jsx";
import ConfigureUser from "../../global/modals/configureUser.jsx";
//import DeleteUser from "../../global/modals/deleteUser.jsx";
import DeleteUser from "../../global/modals/deleteUser.jsx";
import RecoveryUser from "../../global/modals/recoveryUser.jsx";


const UserCard = (props) => {

  //const { userSelect, setUserSelect } = useContext(UsersCommunityContext);

  let userSelect = props.userSelect;
  let setUserSelect = props.setUserSelect;

  let id = props.uuid;
  const [openDrop, setOpenDrop] = useState(false);
  const [user, setUser] = useState({});
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDeleted, setOpenModalDeleted] = useState(false);
  const [openModalRecovery, setOpenModalRecovery] = useState(false);
  const { t, i18n } = useTranslation();
  const [canDeleteUsers, setCanDeleteUsers] = useState(true);
  const [canEditUsers, setCanEditUsers] = useState(true);

  //let selectItem = document.querySelectorAll('input.checkboxCardUser')[0];

  let name, last_name, avatar;

  //console.log("avatar", props.avatar);

  function testImage(url) { // prevenir error de carga al visualizar en front
    if(url.naturalHeight == 0){
      return name.charAt(0).toUpperCase();
    } else {
      return props && props.avatar && props.avatar.thumbnail ? props.avatar.thumbnail : '';
    }
  }

  if (props.name === null) { name = "" } else { name = props.name }
  if (props.last_name === null) { last_name = "" } else { last_name = props.last_name }
  if (props.avatar === null) { 
    avatar = name.charAt(0).toUpperCase(); 
  } else { 
    console.log({props});
    avatar = testImage(props && props.avatar && props.avatar.thumbnail ? props.avatar.thumbnail : ''); 
  }
/*
  if(avatar.naturalHeight !== 0){
    console.log("la imagen no existe");
  }
*/
  let roles = props.roles;
  let moreRoles = " +" + (roles.length - 1).toString();

  let rolesList = [];
  roles.forEach(item => item && item.uuid ? rolesList.push(item.uuid) : null);

  function checkboxSelectItem(event) {

    let checkboxItem = document.getElementById("cardUserSelect-"+id);
    
    function checkedFilter(item){
      //console.log("filter", item !== id)
      return item !== id; 
    }

    let selectList = userSelect.filter(checkedFilter);  

    if ( checkboxItem !== null & checkboxItem.checked === true ) {
      selectList.push(id);      
    }

    setUserSelect(selectList);
    event.stopPropagation();
  }

  //console.log("userSelect", userSelect.length, userSelect)

  function dropCommunity() { // despliega o cierra el dorp de audiencias     

    let elements = document.getElementsByClassName("dropCommunity-content")
    let element = "dropCommunity-" + id;

    for (let item of elements) {  // verifica los drop de otros usuarios y los cierra antes de abrir el actual
      if (item.id == element) {
        document.getElementById(element).classList.toggle("show");
        setOpenDrop(!openDrop); element = "";
      } else {
        document.getElementById(item.id).classList.remove("show");
      }
    }
  }

  document.addEventListener('click', function (event) { // cerrar drop al dar click por fuera del elementos
    //console.log(event.target);
    if (event.target.className !== "dropCommunity" && openDrop === true && event.target.id !== "OptionCard") {
      if (event.target.className.baseVal !== "paginationICO" || event.target.className.baseVal === null) { // fix pagination Button
        let element = "dropCommunity-" + id;
        let get = document.getElementById(element);
        if (get !== null) { get.classList.remove("show"); } // pevencion de errores
      }
    }
    event.stopPropagation();
  });

  function timeout(ms) { return new Promise(resolve => setTimeout(resolve, ms)); }

  useEffect(() => {
    let checkboxItem = document.getElementById("cardUserSelect-"+id);
    const found = userSelect.find(element => element === id);
    if ( found !== undefined & found === id ) {
      checkboxItem.checked = true;
    }
    setCanDeleteUsers(sessionStorage.getItem("delete_user") == 'true' || sessionStorage.getItem("super_admin") == 'true')
    setCanEditUsers(sessionStorage.getItem("edit_user") == 'true' || sessionStorage.getItem("super_admin") == 'true')
  });

  const configOpenModalEdit = async (_user) => {
    setUser(_user)
    await timeout(500);
    setOpenModalEdit(true);
  }

  const configOpenModalDelete = (_user) => {
    setUser(_user)
    setOpenModalDeleted(true);
  }

  const configOpenModalRecovery = (_user) => {
    setUser(_user)
    setOpenModalRecovery(true);
  }

  let isChecked =  false;
  if(userSelect.length){
    userSelect.forEach(user => {
      if(user == props.uuid) isChecked = true;
    })
  }

  return (
    <React.Fragment>

      {openModalEdit ? (
        <Modal>
          <ConfigureUser
            settingUserValue={user}
            setOpenModal={setOpenModalEdit}
          />
        </Modal>
      ) : (
        null
      )}

      {openModalDeleted ? (
        <Modal>
          <DeleteUser
            settingUserValue={user}
            setOpenModal={setOpenModalDeleted}
          />
        </Modal>
      ) : (
        null
      )}

      {openModalRecovery ? (
        <Modal>
          <RecoveryUser
            settingUserValue={user}
            setOpenModal={setOpenModalRecovery}
          />
        </Modal>
      ) : (
        null
      )}
 


      {/* <div className={props.active == 1 ? "cardUser" : "cardUserDeleted"} id="cardUser"> */}
      <div className="cardUser" id="cardUser">
        <div className="cardUserSelect">
          <input type="checkbox"  checked={isChecked} className="checkboxCardUser" id={"cardUserSelect-"+id} onClick={checkboxSelectItem}/>
          {/* <input type="checkbox"   className="checkboxCardUser" id={"cardUserSelect-"+id} onClick={checkboxSelectItem}/> */}
        </div>
        <div className="cardUserOption pointerCursor" onClick={dropCommunity}>
          <OptionCard id="OptionCard" />
        </div>

        <div className="dropCommunity-content" id={"dropCommunity-" + id}>
          <div className="dropCommunity">
            {
              props.active == 1 ?
              <>
              <div className={"dropOptionCommunity"} role="button" id={"config-" + id} onClick={()=> configOpenModalEdit(props.item)}>
                {t("uhoo.globalWords.profile")}
              </div>
              <div className={"dropOptionCommunity"} role="button" id={"delete-" + id} onClick={()=> configOpenModalDelete(props.item)}>
                {t("uhoo.globalWords.delete")}
              </div>
              </>
              :
              <div className="dropOptionCommunity" role="button" id={"config-" + id} onClick={()=> configOpenModalRecovery(props.item)}>
                {t("uhoo.login.recoveryPassword.action")}
              </div>
            }
            
          </div>
        </div>

        <div className="cardUserContent">
          <div className="cardUserImage">
            {avatar.length > 1
              ? <img src={avatar} alt="avatar" />
              : avatar
            }
          </div>
          <div className="cardUserInfo">
            <div className="cardUserName">
              {
                name ? name : '' + " " + last_name ? last_name : ''
              }
            </div>
            <div className="cardUserRole">

              <UsersCommunityContext.Consumer>
                {({ RolData }) => {
                  let userRoles = roles.map(item =>  item ?  RolData.find(select =>select.uuid === item.uuid) : null )
                  if (userRoles[0] === undefined) {
                    return ("");
                  } else {
                    return (userRoles[0] && userRoles[0].name ? userRoles[0].name : "");
                  }
                }}
              </UsersCommunityContext.Consumer>
              {roles.length > 1
                ? moreRoles
                : ""
              }

            </div>
          </div>
        </div>
      </div>

      <UsersCommunityContext.Consumer>
        {({ openModalCommunity, modalConfig }) => {
          if (openModalCommunity) { return (modalConfig); }
        }}
      </UsersCommunityContext.Consumer>

    </React.Fragment>
  );
};

export default UserCard;

