import React from "react";


const UserUsers = () => {
  return(
  <div>
    <br />
    <p> Usuarios </p>    
  </div>
  );
};
export default UserUsers;