import userSessionActions from '../actions/privilege.actions'

export default function (state = [], action) {
   
   switch (action.type) {
      case userSessionActions.PRIVILEGES:
         return Object.assign({}, state, { privileges: action.privileges })
      case userSessionActions.USER_SESSION:
         return Object.assign({}, state, { userSession: action.userSession })
      default:
         return state
   }
}