import { useState, useEffect } from "react";

import axios from "axios";

import {
  protocol,
  host,
  port,
  route,
  dataSource,
} from "../../reactUtils/systemVariables/UhooServerInformation";

const query = "/post/";

export async function GetPost(Params) {
  let sendParams = "";
  if (Params !== undefined || null) {
    if (typeof Params !== "string") {
      Params.forEach((item) =>
        item.key === "search" ? (sendParams += "search") : (sendParams += "")
      ); // Get Size Post Filter
      Params = Params.filter((item) => item.key !== "search");

      //Params.forEach(item => (item.key === 'page') ? sendParams += "page/" + item.value : sendParams += ""); // Get Post Page --> Si Value no es definido por defecto va a ser 10
      //Params = Params.filter((item) => item.key !== 'page');

      Params.forEach((item) =>
        item.key === "size"
          ? (sendParams += "size/" + item.value)
          : (sendParams += "")
      ); // Get Size Post Filter
      Params = Params.filter((item) => item.key !== "size");

      sendParams += "?";

      Params.forEach(
        (item) => (sendParams += item.key + "=" + item.value + "&")
      ); // Envia otros parametros adicionales Ej: Get Post Page Filter
    } else {
      sendParams = Params;
    } // Asigna el String como parametro de la consulta Ej: main o size
  } else {
    sendParams = "";
  }

  //console.log("test consulta",sendParams);

  //const objSessionInformation = JSON.parse(sessionStorage.userInfo);
  //const userToken = "Bearer " + objSessionInformation.token;
  const userToken = "Bearer " + sessionStorage.access_token;

  try {
    let getPostData = await axios({
      method: "GET",
      url: protocol + host + port + route + dataSource + query + sendParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    });

    if (!getPostData.error) {
      return getPostData.data;
      //console.log("Consulta inicial: ", getPostData);
    }
  } catch (error) {
    return error;
  }
}

////////////////////////////
/*
  export async function likePost(Params) {
    let sendParams = "";
  
    if (Params !== undefined || null) {
      if (typeof Params !== "string") {
        
        Params.forEach((item) =>
          item.key === "channel"
            ? (sendParams += item.value + "/")
            : (sendParams += "")
        ); 
        Params = Params.filter((item) => item.key !== "channel");      
  
        Params.forEach((item) =>
          item.key === "post"
            ? (sendParams += "post/" + item.value  + "/")
            : (sendParams += "")
        ); 
        Params = Params.filter((item) => item.key !== "post");
  
        sendParams += "like/"
      }
    }

    const resource = '/channel/';
  
    //const objSessionInformation = JSON.parse(sessionStorage.userInfo);
    //const userToken = "Bearer " + objSessionInformation.token;
    const userToken = "Bearer " + sessionStorage.access_token;
  
    let PostLike;
  
    try {
      PostLike = await axios({
        method: "POST",
        url: protocol + host + port + route + dataSource + query + sendParams,
        headers: {
          "Content-Type": "application/json",
          Authorization: userToken,
        },
      });
  
      if (!PostLike.error) {
        return PostLike.data;
      }
    } catch (error) {
      console.log("Error: API Brilliant");
      return error;
    }
  }

*/
////////////////////////

export async function publishPost(params) {
  let sendParams = "";

  //console.log("em consulta", params)

  let querypublish = "/post";

  const userToken = "Bearer " + sessionStorage.access_token;

  let publish;

  try {
    publish = await axios({
      method: "POST",
      url:
        protocol + host + port + route + dataSource + querypublish + sendParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data: {
        channel_origin: params.channel_origin,
        content: params.content,
        hasMedia: params.hasMedia,
        title: params.title,
        type: params.type,
      },
    });

    //if (!publish.error) {
    return publish.data;
    //media(publish.data, params.file);
    //}
  } catch (error) {
    console.log("Error: API Brilliant");
    return error;
  }
}

export async function addMediaByURI(params) {
  let sendParams = params.uuidPost;
  let add = "addMediaByURI/";
  let payload = params.media;

  let addMedia;

  const userToken = "Bearer " + sessionStorage.access_token;

  try {
    addMedia = await axios({
      method: "POST",
      url:
        protocol + host + port + route + dataSource + query + add + sendParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data: payload,
    });

    //if (!PostLike.error) {
    return addMedia.data;
    //media(PostLike.data, params.file);
    //}
  } catch (error) {
    console.log("Error: API Brilliant");
    return error;
  }
}

export async function addMedia(params) {
  let sendParams = params.post;

  let addMedia;

  const userToken = "Bearer " + sessionStorage.access_token;

  try {
    addMedia = await axios({
      method: "POST",
      url: protocol + host + port + route + dataSource + query + sendParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data: params.image,
    });

    //if (!PostLike.error) {
    return addMedia.data;
    //media(PostLike.data, params.file);
    //}
  } catch (error) {
    console.log("Error: API Brilliant");
    return error;
  }
}

export async function DeletePost(params) {
  let sendParams = params.uuid;

  console.log("sendParams", sendParams);

  console.log("params", params);

  const userToken = "Bearer " + sessionStorage.access_token;

  try {
    let deletePostData = await axios({
      method: "PUT",
      url: protocol + host + port + route + dataSource + query + sendParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data: params,
    });

    if (!deletePostData.error) {
      return deletePostData;
    }
  } catch (error) {
    return error;
  }
}

/*
const GetPost = (Params) => { /// <--- actaulizar otras 

  let sendParams = "";

  if (Params !== undefined || null){

    if (typeof Params !== "string")
    {
      Params.forEach(item => (item.key === 'page') ? sendParams += "page/" + item.value + "?" : sendParams += ""); // Get Post Page --> Si Value no es definido por defecto va a ser 10
      Params = Params.filter((item) => item.key === 'page');

      Params.forEach(item => (item.key === 'size') ? sendParams += "size/" + item.value + "?" : sendParams += ""); // Get Size Post Filter
      Params = Params.filter((item) => item.key === 'size');

      Params.forEach(item => sendParams += item.key + "=" + item.value + "&");  // Envia otros parametros adicionales Ej: Get Post Page Filter

    } else {  sendParams = Params }   // Get Post By UID --> Asigna el String como parametro de la consulta
    
  }else {
    sendParams = "";
  }

  const objSessionInformation = JSON.parse(sessionStorage.userInfo);
  const userToken = "Bearer " + objSessionInformation.token;

  console.log("into GetAllPost");
  console.log(protocol + host + port + route + dataSource + query);

  const page_size = 500;

  const [info, setInfo] = useState([]);

	useEffect(async() => {
		const response = await axios(
      {
        method: 'GET',
        url: protocol + host + port + route + dataSource + query + sendParams,      
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userToken,
          }
      }
    );
		setInfo(response.data);
	},  []);

    return info;
};

export { GetPost };

/*

  Ejemplo de array objetos a enviar GetPost

    let consulta = [
    {
      key: 'page_size',
      value: '1',
    },
    {
      key: 'page',
      value: '0',
    },
    {
      key: 'communities',
      value: '77e1ebaa-a3c0-11e7-8daf-42010a800003',
    },
    {
      key: 'communities',
      value: '77e1ebaa-a3c0-11e7-8daf-42010a800000',
    },
    {
      key: 'status',
      value: 'PENDING',    
    },
    {
      key: 'range',
      value: '1507588399',    
    },
    {
      key: 'type',
      value: 'g',
    },
    {
      key: 'range',
      value: '1507588300',
    }
  ];

*/
/*
const CreatePost = (sendBody) => { /// 

  let sendParams = "";

  const objSessionInformation = JSON.parse(sessionStorage.userInfo);
  const userToken = "Bearer " + objSessionInformation.token;

  /*
  let sendBody =
  {
    "title": "Post placeholder 2",
    "content": "Lorem ipsum dolor sit amet, consectetur adipiscing 2",
    "channel_origin": "fe00326f-19d4-4c75-a957-049d35eb0666"
  }
  */

//const [info, setInfo] = useState([]);
/*
  try {
    Create_Post = await axios({
      method: "GET",
      url: protocol + host + port + route + dataSource + query,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      body: sendBody
    });

    if (!GetCreate_PostMedia.error) {
      return Create_Post.data;
    }
  } catch (error) {
    console.log("Error: API Brilliant");
    return error;
  }
/*
		const response = await axios(
      {
        method: 'POST',
        url: protocol + host + port + route + dataSource + query,      
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userToken,
          },
        body: sendBody
      }
    );
		setInfo(response.data);

    return info;*/
/*
};

export { CreatePost };*/
/*
export default async function createPost(Params) {

  const objSessionInformation = JSON.parse(sessionStorage.userInfo);
  const userToken = "Bearer " + objSessionInformation.token;

  let PostPost;

  try {
    PostPost = await axios({
      method: "POST",
      url: protocol + host + port + route + dataSource + resource,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data: Params,
    });

    if (!PostPost.error) {
      return PostPost.data;
    }
  } catch (error) {
    console.log("Error: API Brilliant");
    return error;
  }
}
*/
