import React, { useState, useEffect, useContext } from "react";
import { get as _get, cloneDeep as _cloneDeep } from "lodash";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from "../../uhoo-components/Modal.jsx";
import AudiencesModal from "../global/modals/audiencesModal.jsx";
//Localization
import { useTranslation } from 'react-i18next';
//Context
import { UsersCommunityContext } from './contex';

import "./css/contentCommunity.css";
import "./css/tableUsers.scss"

import DeleteMasiveUser from "../global/modals/deleteMasiveUser.jsx";
import ConfigureUser from "../global/modals/configureUser.jsx";

import UserCard from "./components/userCard.jsx";
import Pagination from '../global/components/create/pagination.jsx';
import SystemNotification from "../global/SystemNotification.jsx";
import Icon from '../../uhoo-components/iconLibrary.jsx';
// quert
import { updateUser } from "../../actions/uhoo";


const UsersCommunity = (props) => {

  const { search, searcher, setTabActive, UserPage, loadingData, setLoadingData, update, setUpdate, setFilterUsersDeleted, filterUsersPending, setFilterUsersPending, filterUsersFirstLogin, setFilterUsersFirstLogin, filterUsersNoFirstLogin, setFilterUsersNoFirstLogin, setSearch, setAudienceSelected, showCards, setShowCards, RolData } = useContext(UsersCommunityContext);
  const UserContext = useContext(UsersCommunityContext);
  const AudienceContext = useContext(UsersCommunityContext);
  const [userSelect, setUserSelect] = useState([]);
  const [userSelectToReestore, setUserSelectToReestore] = useState([]);
  const [Update, forceUpdate] = useState(false);
  const [openModalAudiences, setOpenModalAudiences] = useState(false);
  const [openModalRecovery, setOpenModalRecovery] = useState(false);
  const [openDeleteFromCommunity, setOpenDeleteFromCommunity] = useState(false);
  const [createOrDelete, setCreateOrDelete] = useState(null);
  const [filterAudience, setFilterAudience] = useState(null);
  const [filterState, setFilterState] = useState(null);
  const [typeFilterUsers, setTypeFilterUsers] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  //modal edit user
  const [userEdit, setUserEdit] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [showFilterUsersValue, setShowFilterUsersValue] = useState(false);
  const [counterCards, setCounterCards] = useState(0);
  // const [loadingData, setLoadingData] = useState(true);

  setTabActive("UserCommunity");
  const { t, i18n } = useTranslation();
  let numItemsShow = showCards ? 8 : 100;
  let { AudienceData } = AudienceContext;

  useEffect(async () => {
    setSearch('');
    if(props.updateUsers){
      // setUpdate(!update)
    }
  }, []);

  useEffect(async () => {
    if(props.setTotalUsers){
      props.setTotalUsers(users);
    }
  }, []);

  function selectAll(event) {

    let selectAll = document.querySelectorAll('input.inputSelectAll')[0];
    let matches = document.querySelectorAll('input.checkboxCardUser');
    let { UserQuery } = UserContext;
    let selectList = [];

    if (selectAll.checked) {
      UserQuery.forEach(user => {
          selectList.push(user.uuid);
      });
    }
    if (!selectAll.checked) {
      selectList = [];
    }

    setUserSelect(selectList);
    forceUpdate(!Update);
    event.stopPropagation();
  }

  function observerSelectAll(event) {  // verifica el # de audiecias selecioandas para activar el check de selecioanr todas   

    // for Select All option  ---------------------------------------------------------
    let matches = document.querySelectorAll('input.checkboxCardUser');
    let selectAll = document.querySelectorAll('input.inputSelectAll')[0];

    let selectItem = 0; // contador # de audiencias selecioandas 
    matches.forEach(select => (!select.checked) ? selectAll.checked = false : selectItem++);
    if (matches.length === selectItem) { selectAll.checked = true; }

    setCounterCards(selectItem);

    if (event !== undefined) {
      event.stopPropagation();
    }
  }

  function timeout(ms) { return new Promise(resolve => setTimeout(resolve, ms)); }

  useEffect(async () => {
    let { UserQuery, UserPage } = UserContext;
    UserQuery.filter( (user, index) => {
      return index === UserQuery.findIndex( _user => _user.uuid == user.uuid)
    });
    // console.log({UserQuery});
    let itemShow = [];
    // setLoadingData(!loadingData);
    if (search.length > 0) {
      let itemStart = (props.pageShow * numItemsShow - numItemsShow);
      let itemsShow = (itemStart + numItemsShow);
      if (itemsShow > searcher.length) itemsShow = searcher.length;

      for (let i = itemStart; i < itemsShow; i++) {
        itemShow.push(searcher[i]);
      }
    } else {
      // if (!UserQuery.length) { return <CircularProgress />; }
      // else {
        let itemStart = (props.pageShow * numItemsShow - numItemsShow);
        let itemsShow = (itemStart + numItemsShow);
        if (itemsShow > UserQuery.length) itemsShow = UserQuery.length;

        for (let i = itemStart; i < itemsShow; i++) {
          itemShow.push(UserQuery[i]);
        }
      // }
    }
    setUsers(itemShow);
    if(props.setTotalUsers && props.totalUsers.length != UserQuery.length){
      props.setTotalUsers(UserQuery);
    }
    let { AudienceData } = AudienceContext;
    if(props.setTotalAudiences && props.totalAudiences.length != AudienceData.length){
      props.setTotalAudiences(AudienceData);
    }
    // await timeout(1500);
    // setLoadingData(!loadingData);
  }, [UserContext, props.pageShow])

  const openModal = (flag) => {
    setOpenModalAudiences(true);
    setCreateOrDelete(flag);
  }

  const applyFilter = async () => {
    setLoadingData(true);
    let audienceSelected;
    if(typeFilterUsers == 'audience'){
      if(filterAudience == "All"){
        audienceSelected = null;
      }else{
        if(filterAudience == null){
          audienceSelected = null;
        }else{
          audienceSelected = AudienceData.filter(x => x.uuid == filterAudience);
        }
      }
      if(audienceSelected && audienceSelected.length && audienceSelected.length != 0) audienceSelected = audienceSelected[0];
      setAudienceSelected(audienceSelected);
    }else if(typeFilterUsers == 'state'){
      setAudienceSelected(null);
      if(filterState == 'includeDelete'){
        setFilterUsersDeleted(true);
        props.setPageShow(1);
      }else if(filterState == 'pending'){
        setFilterUsersPending(!filterUsersPending);
        props.setPageShow(1);
      }else if(filterState == 'firstLogin'){
        setFilterUsersDeleted(null);
        setFilterUsersFirstLogin(!filterUsersFirstLogin);
        props.setPageShow(1);
      }else if(filterState == 'firstNoLogin'){
        setFilterUsersDeleted(null);
        setFilterUsersNoFirstLogin(!filterUsersNoFirstLogin);
        props.setPageShow(1);
      }
      else{
        setFilterUsersDeleted(false);
        props.setPageShow(1);
      }
    }
    // setFilterAudience(null);
    setShowFilterUsersValue(false);
    setTypeFilterUsers(null);
    await timeout(1500);
    setLoadingData(false);
  }

  const closeFilter = () => {
    setShowFilterUsersValue(false); 
    setTypeFilterUsers(null);
  }

  const selectUserList = (user) => {
    if(user.active && user.active == 1){
      if(!userSelect.includes(user.uuid)){
        let _userSelect = _cloneDeep(userSelect);
        _userSelect.push(user.uuid);
        setUserSelect(_userSelect);
      }else{
        let _userSelect = _cloneDeep(userSelect);
        let newUsers = _userSelect.filter(x => x != user.uuid);
        setUserSelect(newUsers);
      }
    }else{
      if(!userSelectToReestore.includes(user.uuid)){
        let _userSelectToReestore = _cloneDeep(userSelectToReestore);
        _userSelectToReestore.push(user.uuid);
        setUserSelectToReestore(_userSelectToReestore);
      }else{
        let _userSelectToReestore = _cloneDeep(userSelectToReestore);
        let newUsers = _userSelectToReestore.filter(x => x != user.uuid);
        setUserSelectToReestore(newUsers);
      }
    }
  }

  const recoveryUser = async () => {
    userSelectToReestore.forEach(async (user) => {
      let tosend = {};
      tosend["uuid"] = user;
      var formData = new FormData();
      formData.append("uuid", user);
      formData.append("active", 1); // to soft delete user
      tosend["data"] = formData;
      try {
          let response = await updateUser(tosend, true); //save info user
          response = _get(response, "data[0]", {});
          setUpdate(!update);
          setFilterUsersDeleted(false);
      } catch (error) {
          console.log("error", error);
          setShowError(true);
      }
      //closeModal();
      setUserSelectToReestore([]);
      setShowSuccess(true);
    });
  }

  const configOpenModalEdit = async (_user) => {
    setUserEdit(_user)
    await timeout(500);
    setOpenModalEdit(true);
  }

  document.addEventListener('click', function (event) { // cerrar drop al dar click por fuera del elementos
    if ( showFilterUsersValue == true && event.target.className != "containerButtonsFilter" && 
    event.target.className != "buttonAplyFilterUsers" && event.target.className != "textShowFilterBetween" &&
    event.target.className != "select" && event.target.className != "titleInput" &&
    event.target.className != "containerInputs" && event.target.className != "actionFilter" &&
    event.target.className != "secondaryButton okay"

    ) {
      setShowFilterUsersValue(false);
    }
    event.stopPropagation();
  });

  return (
    <React.Fragment>

      {openModalEdit ? (
        <Modal>
          <ConfigureUser
            settingUserValue={userEdit}
            setOpenModal={setOpenModalEdit}
          />
        </Modal>
      ) : (
        null
      )}

      {openModalAudiences ? (
        <Modal>
          <AudiencesModal
            usersSelected={userSelect}
            setOpenModal={setOpenModalAudiences}
            create={createOrDelete}
          />
        </Modal>
      ) : (
        null
      )}

      {openDeleteFromCommunity ? (
        <Modal>
          <DeleteMasiveUser
            usersSelected={userSelect}
            setOpenModal={setOpenDeleteFromCommunity}
            setUserSelect={setUserSelect}
          />
        </Modal>
      ) : (
        null
      )}

      {!!showSuccess && (
          <SystemNotification
              setShowNotification={setShowSuccess}
              type="Success"
              caption={t('basicWords.successAction')}
          />
      )}

      {!!showError && (
        <SystemNotification
          setShowNotification={setShowError}
          type="Error"
          caption={t('basicWords.internalServerError')}
        />
      )}

      <div className="contentUsers">
        <div className={"contentUsersSelect"}>
          <div className="containerInputs">
            <div className="containerInputsCheckbox" style={{display: 'none'}}>
              <input type="checkbox" className="inputSelectAll" onChange={selectAll} /> {t('basicWords.selectAll')}
            </div>
            <div className="containerInputsCheckbox spaceIntoComponents">
              <div/>
              {
                showCards ? 
                <div className={"tooltip"} role="button" onClick={() => {setShowCards(!showCards); props.setPageShow(1)}}>
                  <Icon.UserList style={{ color: Icon.active }} className="gridIco"/>
                  <span className="tooltiptextGrid">{t('basicWords.designList')}</span>
                </div>
              : 
                <div className={"tooltip"} role="button" onClick={() => {setShowCards(!showCards); props.setPageShow(1)}}>
                  <Icon.Cards style={{ color: Icon.active }} className="gridIco"/>
                  <span className="tooltiptextGrid">{t('basicWords.designCard')}</span>
                </div>
              }
            </div>
          </div>
          <div className="containerButtonsFilter"> 
            <div className={showFilterUsersValue ? "buttonAplyFilterUsers" : "buttonAplyFilterUsers2"} role="button" onClick={(e) => showFilterUsersValue ? closeFilter() : setShowFilterUsersValue(true)}>
              <div className={showFilterUsersValue ? "textShowFilterBetween" : "textShowFilter"}>
              {showFilterUsersValue ? <p>X</p>:<> <p className="textShowFilterBetween">{'< '}</p> <p className="textShowFilterBetween"> {t("basicWords.doFilter")}</p> </>}
              </div>
            </div> 
          </div>
          { showFilterUsersValue ?
            <div className="dropFilterUsers">
              <div className="containerInputs" style={{padding: '10px'}}>
              <label className="titleInput">{t("basicWords.type")}</label>
                <select id="audienceSelect" className="select" onChange={(item) => setTypeFilterUsers(item.target.value)}>
                  <option value="" selected disabled hidden> {t('basicWords.selectOption')} </option> 
                  <option key={"state"} value={"state"} > {t("basicWords.state")} </option>
                  <option key={"audience"} value={"audience"} > {t("basicWords.audience")} </option>
                </select>
                {
                  typeFilterUsers == 'state' ? 
                  <>
                    <label className="titleInput">{t("basicWords.state")}</label>
                    <select defaultValue={""} id="audienceSelect" className="select" onChange={(item) => setFilterState(item.target.value)}>
                      <option value="" selected disabled hidden defaultValue={""}> {t('basicWords.selectOption')} </option> 
                      <option key={"allUsers"} value={"allUsers"} > {t("basicWords.allUsers")} </option>
                      <option key={"includeDelete"} value={"includeDelete"} > {t("basicWords.includeDelete")} </option>
                      <option key={"pending"} value={"pending"} > {t("basicWords.usersPending")} </option>
                      <option key={"firstLogin"} value={"firstLogin"} > {t("basicWords.usersFirstLogin")} </option>
                      <option key={"firstNoLogin"} value={"firstNoLogin"} > {t("basicWords.usersNoFirstLogin")} </option>
                    </select>
                  </>
                  :
                  null
                }
                {
                  typeFilterUsers == 'audience' ? 
                  <>
                    <label className="titleInput">{t("basicWords.audience")}</label>
                    <select id="audienceSelect" className="select" onChange={(item) => setFilterAudience(item.target.value)}>
                      <option value="" selected disabled hidden> {t('basicWords.selectOption')} </option> 
                      {AudienceData.map((item) => <option key={item.uuid} value={item.uuid} > {item.name} </option> )}
                      <option key={"all"} value={"All"} > {t("basicWords.allUsers")} </option>
                    </select>
                  </>
                  :
                  null
                }
                <div className="actionFilter">
                  <div className="secondaryButton okay" role="button" onClick={() => applyFilter() }>{t('basicWords.filterAction')}</div>
                </div>
              </div>
            </div>
            : null
          }
          {
            userSelect.length > 0
              ?
              <div className="massiveActionsUsers">
                <div>{userSelect.length} {t('uhoo.community.selectItem')}</div>
                <div className="secondaryButton okay" role="button" onClick={() => openModal(true)}>{t('uhoo.community.add')}</div>
                <div className="secondaryButton alert" role="button" onClick={() => openModal(false)}>{t('uhoo.community.delete')}</div>
                <div className="secondaryButton alert" role="button" onClick={() => setOpenDeleteFromCommunity(true)}>{t('uhoo.community.deleteCommunity')}</div>
              </div>
              : ""
          }
          {
            userSelectToReestore.length > 0
              ?
              <div className="massiveActionsUsers">
                <div>{userSelectToReestore.length} {t('uhoo.community.selectItem')}</div>
                <div className="secondaryButton okay" role="button" onClick={() => recoveryUser()}>{t('uhoo.community.recoveryModal.action')}</div>
              </div>
              : ""
          }
        </div>
        {
          showCards ?
            <div className="contentUsersCards" onChange={observerSelectAll}>
              {
                loadingData ?
                  <CircularProgress />
                :
                  users.length > 0 ?
                    users.map(
                      item => (
                        <UserCard
                          type="user"
                          key={item.uuid}
                          userSelect={userSelect}
                          setUserSelect={setUserSelect}
                          item={item}
                          uuid={item.uuid}
                          name={item.name}
                          last_name={item.last_name}
                          avatar={item.avatar}
                          roles={item.roles}
                          active={item.active}
                        />
                      )
                    )
                  :
                    <p>No available data</p>
              }
            </div>
          :
          <div className="containerBody">
            {loadingData ?
              <CircularProgress />
            :
            <div id="users_table">
              <div className="containerTableHeader">
                <h5>Photo</h5>
                <h5>Name</h5>
                <h5>Last name</h5>
                <p>Email</p>
                <h5>Rol</h5>
                <h5>Actions</h5>
              </div>
              <div className="containerBody">
                  { 
                    users.length > 0 ?
                      users.map(user => (
                        <div className="containerBodyInfo" key={user.uuid}>
                          <h5 >
                            {
                              _get(user, "avatar.url", null) != null ?
                                <img src={_get(user, "avatar.url", "")} alt='img user'></img>
                              :
                              <div className="imageLetter">{user.name && user.name.charAt(0) ? user.name.charAt(0).toUpperCase() : 'A'} </div>
                            }
                          </h5>
                          <h5>{user.name}</h5>
                          <h5>{user.last_name}</h5>
                          <p>{_get(user, "emails[0].email", '')}</p>
                          <h5>{ _get(RolData.filter(rol => rol.uuid == _get(user, "roles[0].uuid", '')), "[0].name", "") }</h5>
                          <h5>
                            <div className="containerActionTableUsers"> 
                              <input type="checkbox" className="filterUsersDeleted" onChange={() => selectUserList(user)}/>
                              <Icon.pincel style={{ color: Icon.active }} className="pincelIco" onClick={() => configOpenModalEdit(user)}/>
                            </div>
                          </h5>
                        </div>
                      ))
                    :
                    <p>No available data</p>
                  }
              </div>
            </div>}
          </div>

        }
        
      </div>

      <UsersCommunityContext.Consumer>
        {({ UserPage, UserQuery }) => {
          let UserCount = UserQuery.length
          if (UserPage === undefined) { return (""); }
          else {
            let pageEnd;
            if (search.length > 0) {
              pageEnd = Math.ceil(search.length / numItemsShow);
            }
            else {
              pageEnd = Math.ceil(UserCount / numItemsShow)
            }
            return (<Pagination pageShow={props.pageShow} setPageShow={props.setPageShow} pageEnd={pageEnd} />);
          }
        }}
      </UsersCommunityContext.Consumer>

    </React.Fragment>
  );
};

export default UsersCommunity;
