import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import "../css/sections.scss";
import { Edit, Eye, Plus, Trash2 } from "react-feather";
import EditRecurrenceStreamingModal from "./EditRecurrenceStreamingModal.jsx";
import { ReactComponent as BackArrow } from '../../../../assets/icons/navigation/backArrow.svg';
// Queries
import {
  getInformationBySectionIdAndLPId,
  insertSectionComponent,
  disableLpSectionComponent,
  updateSectionComponent,
  getScheduleEventInfoByLpSectionComponentId,
  putScheduleEventInfo,
  disableScheduleEvents,
  getAvailableScheduleEventTypes,
  getDataYoutube,
  updateLpSection,
} from "../../../../actions/aura";

// Components
import {
  BreadcrumbSection,
  ButtonComponent,
  ButtonsFooterComponent,
  DeleteItemModal,
  GeneralInput,
  GeneralModal,
  Title,
  TitleCard,
} from "../../../../aura-components";

//Localization
import { withTranslation } from "react-i18next";
import LocalizeScheduleEventType from "../../../../reactUtils/systemVariables/languageLocalization/ScheduleEventTypeLocalization.jsx";
import LocalizeScheduleEventTypeBD from "../../../../reactUtils/systemVariables/languageLocalization/ScheduleEventTypeLocalizationBD.jsx";

import RecurrenceStreamingModal from "./RecurrenceStreamingModal.jsx";
import AddStreamingModal from "./AddStreamingModal.jsx";
import EditStreamingModal from "./EditStreamingModal.jsx";
import getVideoId from "get-video-id";

import SystemNotification from "../../../global/SystemNotification";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TextField
} from "@material-ui/core";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;
const format = "YYYY-MM-DD";

class StreamingSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,

      landingPageId: null,
      organizationId: null,
      organizationName: null,
      country: null,
      region: null,
      city: null,
      lpSectionId: null,
      sectionName: null,

      totalElements: 0,

      loadingSection: true,
      loadingInfo: true,

      date: [],

      isAddModalVisible: false,
      isRecurrenceModal: false,
      isEditModalVisible: false,
      isEditRecurrenceModalVisible: false,

      streamingContainerTitle: {
        id: null,
        sectionComponentNodeId: null,
        value: "",
        nodeType: "",
        modified: false,
      },
      streamingContainerUrlPage: {
        id: null,
        sectionComponentNodeId: null,
        value: "",
        nodeType: "",
        modified: false,
      },
      streamingsToShow: [],
      streamings: {
        id: null,
        title: "",
        url: "",
        description: "",
        dateInit: moment(),
        timeInit: null,
        dateFinal: moment(),
        timeFinal: null,
        dateFinish: null,
        eventOcurrencyOptions: [
          "NOREPEAT",
          "ALLWEEK",
          "MONTOFRI",
          "PERWEEK",
          "CUSTOMS",
        ],
        eventOcurrency: null,
        scheduleEventTypeId: null,
        scheduleEventType: null,
        frecuencyValue: null,
        frecuencyUnit: null,
        numberOcurrences: null,
        weekdays: [],
        monthDay: null,
        specificDayOfMonth: null,
        relativeDayOfMonth: null,
        eventRule: {},
        checked: false,
        StreamingComponentLastEventsGeneratorDate: "",
        scheduleEventInfo: null,
        valueRadioGroup: null,
      },
      parentLPSectionComponentId: null,
      isLoadingButton: false,
      recurrenceSelect: "NOREPEAT",
      dataYT: [],
      disabledSaveButton: true,
      infoToUpdate: [],
      isEditPreview: false,

      scheduleEventInfoToDisable: [],
      isLoadingSheduleInfoModal: true,
      FlagClassName: false,
      goToWithoutSaveModalVisible: false,
      saveTitleAndUrl: false,

      haveChangeInLayout: false,
      // Modals
      showSuccess: false,
      showWarning: false,
      showError: false,
      contentNotificationModal: "",

      //Name modal
      openModalToChangeName: false,
      customName: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    const { location } = this.props;
    const { props } = location;

    if (props) {
      this.getStreamingInformation(props.landingPageId, props.sectionId);
      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getStreamingInformation(lpId, lpSectionId) {
    const response = await getInformationBySectionIdAndLPId(
      lpId,
      lpSectionId,
      "STREAMING"
    );
    const { t } = this.props;
    this.cleanStateVariables();
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      const sectionInfo = response.data.data;
      const streamingContainerComponent = sectionInfo.components.find(
        (comp) => comp.name === "StreamingContainerComponent"
      );
      const parentLPSectionComponentId = streamingContainerComponent.id;
      const streamingContainerTitle = {
        id: streamingContainerComponent.nodes.find(
          (node) => node.nodeType === "StreamingContainerTitle"
        ).id,
        value: streamingContainerComponent.nodes.find(
          (node) => node.nodeType === "StreamingContainerTitle"
        ).value,
        nodeType: streamingContainerComponent.nodes.find(
          (node) => node.nodeType === "StreamingContainerTitle"
        ).nodeType,
      };
      const streamingContainerUrlPage = {
        id: streamingContainerComponent.nodes.find(
          (node) => node.nodeType === "StreamingContainerUrlPage"
        ).id,
        value: streamingContainerComponent.nodes.find(
          (node) => node.nodeType === "StreamingContainerUrlPage"
        ).value,
        nodeType: streamingContainerComponent.nodes.find(
          (node) => node.nodeType === "StreamingContainerUrlPage"
        ).nodeType,
      };
      const streamings = sectionInfo.components.find(
        (comp) => comp.name === "StreamingComponent"
      )
        ? sectionInfo.components.filter(
          (comp) => comp.name === "StreamingComponent"
        )
        : [];
      const lpSectionComponentIdComponent = sectionInfo.components.find(
        (comp) => comp.name === "StreamingComponent"
      )
        ? sectionInfo.components.find(
          (comp) => comp.name === "StreamingComponent"
        ).id
        : null;
      const streamingsToShow = [];
      if (streamings.length > 0) {
        streamings.forEach((element) => {
          const streamingElement = {
            id: element.id,
            titleId: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentTitle"
            )
              ? element.nodes.find(
                (node) => node.nodeType === "StreamingComponentTitle"
              ).id
              : null,
            title: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentTitle"
            )
              ? element.nodes.find(
                (node) => node.nodeType === "StreamingComponentTitle"
              ).value
              : "",
            descriptionId: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentDescription"
            )
              ? element.nodes.find(
                (node) => node.nodeType === "StreamingComponentDescription"
              ).id
              : null,
            description: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentDescription"
            )
              ? element.nodes.find(
                (node) => node.nodeType === "StreamingComponentDescription"
              ).value
              : "",
            descriptionMaxLength: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentDescription"
            )
              ? element.nodes.find(
                (node) => node.nodeType === "StreamingComponentDescription"
              ).maxLength
              : "",
            urlId: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentUrl"
            )
              ? element.nodes.find(
                (node) => node.nodeType === "StreamingComponentUrl"
              ).id
              : null,
            url: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentUrl"
            )
              ? element.nodes.find(
                (node) => node.nodeType === "StreamingComponentUrl"
              ).value
              : null,

            dateInitId: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentInitialDate"
            )
              ? element.nodes.find(
                (node) => node.nodeType === "StreamingComponentInitialDate"
              ).id
              : null,
            dateInit: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentInitialDate"
            )
              ? element.nodes.find(
                (node) => node.nodeType === "StreamingComponentInitialDate"
              ).value
              : null,
            dateFinalId: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentFinalDate"
            )
              ? element.nodes.find(
                (node) => node.nodeType === "StreamingComponentFinalDate"
              ).id
              : null,
            dateFinal: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentFinalDate"
            )
              ? element.nodes.find(
                (node) => node.nodeType === "StreamingComponentFinalDate"
              ).value
              : null,
            scheduleEventTypeNodeId: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentScheduleEventType"
            )
              ? element.nodes.find(
                (node) =>
                  node.nodeType === "StreamingComponentScheduleEventType"
              ).id
              : null,
            scheduleEventType: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentScheduleEventType"
            )
              ? element.nodes.find(
                (node) =>
                  node.nodeType === "StreamingComponentScheduleEventType"
              ).value
              : null,

            eventRuleId: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentEventRule"
            )
              ? element.nodes.find(
                (node) => node.nodeType === "StreamingComponentEventRule"
              ).id
              : null,
            eventRule: element.nodes.find(
              (node) => node.nodeType === "StreamingComponentEventRule"
            )
              ? element.nodes.find(
                (node) => node.nodeType === "StreamingComponentEventRule"
              ).value
              : null,
            streamingComponentLastEventsGeneratorDateId: element.nodes.find(
              (node) =>
                node.nodeType === "StreamingComponentLastEventsGeneratorDate"
            )
              ? element.nodes.find(
                (node) =>
                  node.nodeType ===
                  "StreamingComponentLastEventsGeneratorDate"
              ).id
              : null,
            streamingComponentLastEventsGeneratorDate: element.nodes.find(
              (node) =>
                node.nodeType === "StreamingComponentLastEventsGeneratorDate"
            )
              ? element.nodes.find(
                (node) =>
                  node.nodeType ===
                  "StreamingComponentLastEventsGeneratorDate"
              ).value
              : null,
            buttons: element.buttons,
          };
          streamingsToShow.push(streamingElement);
        });
      }

      let customName =
        response.data.data.customName !== null
          ? response.data.data.customName
          : this.state.sectionName;

      this.setState({
        customName,
        streamingContainerTitle,
        streamingContainerUrlPage,
        streamingsToShow,
        loadingInfo: false,
        loadingSection: false,
        parentLPSectionComponentId,
        lpSectionComponentIdComponent,
      });
    }
  };

  async updateLpSection(LpSubpageDto) {
    let response = await updateLpSection(LpSubpageDto);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
    }
    this.setState({
      newCustomName: "",
      customName: LpSubpageDto.customName,
      openModalToChangeName: false,
    });
  }

  async insertSectionComponentFunction(componentToUpdate) {
    const { landingPageId, lpSectionId } = this.state;
    const response = await insertSectionComponent(componentToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });

      this.cleanStateVariables();
      this.getStreamingInformation(landingPageId, lpSectionId);
    }

    this.setState({
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
      isEditRecurrenceModalVisible: false,
    });
  }

  async updateSectionComponentFunction(componentToUpdate) {
    const { landingPageId, lpSectionId } = this.state;
    const { t } = this.props;
    const response = await updateSectionComponent(componentToUpdate);
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.successfullyUpdate"),
      });

      this.cleanStateVariables();
      this.getStreamingInformation(landingPageId, lpSectionId);
    }
    this.setState({
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
      isEditRecurrenceModalVisible: false,
    });
  }

  async disableNodeFunction(id) {
    const { landingPageId, lpSectionId } = this.state;

    const response = await disableLpSectionComponent(id);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerError"),
      });
      this.setState({
        isLoadingButton: false,
        loadingInfo: false,
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.streamingSuccessfullyDisabled"),
      });

      this.getStreamingInformation(landingPageId, lpSectionId);
      this.setState({
        isLoadingButton: false,
        loadingInfo: false,
      });
    }
  }

  async getScheduleEventInfoByLpSectionComponentIdFunction(
    lpSectionComponentId,
    row
  ) {
    const streamings = { ...row };
    const response = await getScheduleEventInfoByLpSectionComponentId(
      lpSectionComponentId
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      streamings.scheduleEventInfo = response.data.data;
      this.setState({
        isEditRecurrenceModalVisible: true,
        streamings,
        isLoadingSheduleInfoModal: false,
      });
    }
  }

  async putScheduleEventInfoFunction(
    scheduleInfoToUpdate,
    scheduleEventInfoToDisable
  ) {
    const { landingPageId, lpSectionId } = this.state;
    const response = await putScheduleEventInfo(scheduleInfoToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      if (scheduleEventInfoToDisable.length > 0) {
        this.disableScheduleEventsFunction(scheduleEventInfoToDisable);
      } else {
        this.setState({
          showSuccess: true,
          contentNotificationModal: t("basicWords.modal.streamingSuccessfullyEdited"),
        });

        this.getStreamingInformation(landingPageId, lpSectionId);

        this.setState({
          isLoadingButton: false,
          isAddModalVisible: false,
          isEditModalVisible: false,
          isEditRecurrenceModalVisible: false,
        });
      }
    }
  }

  async disableScheduleEventsFunction(scheduleEventInfoToDisable) {
    const { landingPageId, lpSectionId } = this.state;
    const response = await disableScheduleEvents(scheduleEventInfoToDisable);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.streamingSuccessfullyEdited"),
      });
      this.cleanStateVariables();
      this.getStreamingInformation(landingPageId, lpSectionId);
    }

    this.setState({
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
      isEditRecurrenceModalVisible: false,
    });
  }

  async getAvailableScheduleEventTypesFunction() {
    let scheduleEventTypeOptions = [];
    let response = await getAvailableScheduleEventTypes();
    const { t } = this.props;

    if (String(response).includes("Error:")) {
    } else {
      scheduleEventTypeOptions = response.data.data.map((item) => ({
        value: item.id,
        label: LocalizeScheduleEventType(item.name, t),
      }));
    }

    this.setState({ scheduleEventTypeOptions });
  }

  //Events

  dataYT = async (urlVideo) => {
    const idVideo = getVideoId(urlVideo);
    if (idVideo.id !== null) {
      const dataYoutube = await getDataYoutube(idVideo.id);
      this.setState({
        dataYT: dataYoutube.data.items[0].snippet,
      });
      this.updateInfoModal();
    }
  };

  addStreamingEvent = () => {
    this.getAvailableScheduleEventTypesFunction();
    const { isAddModalVisible } = this.state;
    this.setState({
      isEditModalVisible: false,
      isAddModalVisible: !isAddModalVisible,
      isEditPreview: false,
    });
  };

  streamingSuccessfullyEdited = (e, row) => {
    const streamings = { ...row };
    streamings.row = row;
    this.getAvailableScheduleEventTypesFunction();
    this.setState({
      isAddModalVisible: false,
      isEditModalVisible: true,
      streamings,
      isEditPreview: true,
    });
  };

  editRecurrenceStreamingEvent = (e, row) => {
    this.getScheduleEventInfoByLpSectionComponentIdFunction(row.id, row);
    this.setState({
      isEditRecurrenceModalVisible: true,
    });
  };

  disableStreamingEvent = (id) => {
    this.setState({
      loadingInfo: true,
    });
    this.disableNodeFunction(id);
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      showWarning: true,
      contentNotificationModal: t("basicWords.modal.noSectionSelected"),
    });
    this.handleOkWarning();
  };

  handleChange = (event, index) => {
    const { id, value } = event.target;
    var {
      streamingContainerTitle,
      streamingContainerUrlPage,
      saveTitleAndUrl,
    } = {
      ...this.state,
    };
    var { streamings } = { ...this.state };
    if (!index) {
      streamings[id] = value;
    }

    if (id === "StreamingContainerTitle") {
      if (index !== undefined) {
        streamings.scheduleEventInfo[index].title = value;
      } else {
        streamingContainerTitle.value = value;
      }
      streamingContainerTitle.modified = true;
      saveTitleAndUrl = true;
    }
    if (id === "StreamingContainerUrlPage") {
      if (index !== undefined) {
        streamings.scheduleEventInfo[index].url = value;
      } else {
        streamingContainerUrlPage.value = value;
      }
      streamingContainerUrlPage.modified = true;
      saveTitleAndUrl = true;
    }
    if (id === "StreamingComponentTitle") {
      if (index !== undefined) {
        streamings.scheduleEventInfo[index].title = value;
      } else {
        streamings.title = value;
      }
      saveTitleAndUrl = false;
    }
    if (id === "StreamingComponentDescription") {
      if (index !== undefined) {
        streamings.scheduleEventInfo[index].description = value;
      } else {
        streamings.description = value;
      }
      saveTitleAndUrl = false;
    }
    if (id === "StreamingComponentUrl") {
      if (index !== undefined) {
        streamings.scheduleEventInfo[index].url = value;
      } else {
        streamings.url = value;
      }
      saveTitleAndUrl = false;
      this.dataYT(streamings.url);
    }

    this.setState({
      streamingContainerTitle,
      streamingContainerUrlPage,
      streamings,
      saveTitleAndUrl,
      FlagClassName: true,
    });
  };

  onSaveEditRecurrenceModal = () => {
    var { infoToUpdate, scheduleEventInfoToDisable, streamings } = {
      ...this.state,
    };
    const { t } = this.props;
    let errorInput = false;
    this.setState({
      isLoadingButton: true,
    });

    infoToUpdate = streamings.scheduleEventInfo;
    infoToUpdate.forEach((element) => {
      if (element.title === "") {
        errorInput = true;
      }
    });
    if (!errorInput) {
      this.putScheduleEventInfoFunction(
        infoToUpdate,
        scheduleEventInfoToDisable
      );
    } else {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.fieldEmpty"),
        isLoadingButton: false,
      });
    }
  };

  onCheckEventModal = () => {
    const { parentLPSectionComponentId, landingPageId } = this.state;
    const nodesToAdd = [];
    const { t } = this.props;
    var newStreaming = { ...this.state.streamings };
    let streamingButtons = newStreaming.buttons
      ? newStreaming.buttons.map((b) => {
        const button = {
          id: b.id,
          name: b.name,
          externalLink: b.externalLink,
          internalLpSectionComponentId: b.component
            ? b.component.id
            : b.internalLpSectionComponentId
              ? b.internalLpSectionComponentId
              : null,
          isEnabled: b.isEnabled,
        };
        return button;
      })
      : [];
    const streamingToAdd = {
      // --- Con esto se crea el registro lpSectionComponent ---
      lpId: landingPageId, // Este valor se obtiene de la vista anterior
      lpSectionId: this.state.lpSectionId,
      sectionId: 15, // Valor estático para la sección de streaming
      sectionComponentId: 26, // Valor estático de 5, valor para el Streaming component
      id: parentLPSectionComponentId, // Valor estático de 4 puesto que el id 4 es el padre de este nuevo componente
      // --- Con el Id del lpSectionComponent creado, se crean los nodos de este componente
      nodesToAdd,
      buttons: streamingButtons,
    };
    const streamingNode = {};
    var errorFrecuencyValue = 0;
    var errorFrecuencyInit = 0;
    var errorweekdays = 0;
    var errorNumberOcurrences = 0;
    var errorMonthDay = 0;
    var errorInput = true;
    var auxEventRule = "";
    auxEventRule = newStreaming.eventRule;

    if (auxEventRule.frecuencyValue) {
      newStreaming.frecuencyValue = auxEventRule.frecuencyValue;
    }

    if (newStreaming.eventRule.frecuencyUnit !== "MONTHS") {
      //If eventRule contain relative or specific day of month
      delete newStreaming.eventRule.relativeDayOfMonth;
      delete newStreaming.eventRule.specificDayOfMonth;
    }

    //Verify frecuency value
    if (auxEventRule.frecuencyValue === null) {
      streamingNode.frecuencyValue = null;
      errorFrecuencyValue = 1;
      errorInput = false;
    } else {
      streamingNode.frecuencyValue = auxEventRule.frecuencyValue;
    }
    //Verify frecuency unit
    if (auxEventRule.frecuencyUnit === null) {
      streamingNode.frecuencyUnit = null;
      errorFrecuencyInit = 1;
      errorInput = false;
    } else {
      streamingNode.frecuencyUnit = auxEventRule.frecuencyUnit;
    }
    // conditional numberOcurrences
    if (newStreaming.valueRadioGroup === null) {
      errorNumberOcurrences = 1;
      errorInput = false;
    }

    if (newStreaming.valueRadioGroup === 2) {
      if (newStreaming.eventRule.finalDateOcurrences === null) {
        streamingNode.finalDateOcurrences = null;
        errorNumberOcurrences = 1;
        errorInput = false;
      } else {
        streamingNode.finalDateOcurrences =
          newStreaming.eventRule.finalDateOcurrences;
        // newStreaming.eventRule.finalDateOcurrences =
        //   newStreaming.eventRule.finalDateOcurrences;
      }
    }

    if (newStreaming.valueRadioGroup === 3) {
      if (newStreaming.numberOcurrences === null) {
        streamingNode.numberOcurrences = null;
        errorNumberOcurrences = 1;
        errorInput = false;
      } else {
        streamingNode.numberOcurrences = newStreaming.numberOcurrences;
        // newStreaming.numberOcurrences = newStreaming.numberOcurrences;
      }
    }

    if (auxEventRule.frecuencyUnit === "WEEKS") {
      //Verificación de la frecuencia de repetición de la semana
      if (
        auxEventRule.frecuencyUnit === "WEEKS" &&
        auxEventRule.weekdays &&
        auxEventRule.weekdays.length === 0
      ) {
        streamingNode.weekdays = null;
        errorweekdays = 1;
        errorInput = false;
      } else {
        streamingNode.weekdays = auxEventRule.weekdays;
      }
    }

    if (auxEventRule.frecuencyUnit === "MONTHS") {
      //Verificación de la frecuencia de repetición del mes
      if (newStreaming.monthDay === null) {
        streamingNode[2] = null;
        errorMonthDay = 1;
        errorInput = false;
      } else {
        var regex1 = /[A-Z]/; //Search letters in the variable
        var result = regex1.test(newStreaming.monthDay);
        if (result === false) {
          streamingNode.specificDayOfMonth = newStreaming.monthDay;
        } else {
          streamingNode.relativeDayOfMonth = newStreaming.monthDay;
        }
      }
    }

    streamingToAdd.id = newStreaming.id;
    streamingToAdd.lpId = landingPageId;
    streamingToAdd.nodesToAdd = nodesToAdd;
    var modalErrorField = "";
    if (
      auxEventRule.frecuencyUnit === "WEEKS"
      //Verificación si la frecuencia de repetición no son días para mostrar los días de la semana
    ) {
      if (!errorInput) {
        modalErrorField = "";
        if (errorFrecuencyInit === 1) {
          modalErrorField = `${t(
            "basicWords.streaming.selectOcurrence"
          )}, ${modalErrorField}`;
        }
        if (errorFrecuencyValue === 1) {
          modalErrorField = `${t(
            "basicWords.streaming.frecuencyValue"
          )}, ${modalErrorField}`;
        }
        if (errorNumberOcurrences === 1) {
          modalErrorField = `${t("basicWords.streaming.finishEvent")}, ${modalErrorField}`;
        }
        if (errorweekdays === 1) {
          modalErrorField = `${t("basicWords.streaming.weekdays")}, ${modalErrorField}`;
        }
        modalErrorField = modalErrorField.substring(0, modalErrorField.length - 2);
        this.setState({
          showError: true,
          contentNotificationModal: ` ${t("basicWords.streaming.modalErrorBody")}: ${modalErrorField} `,
        });
      } else {
        newStreaming.eventRule = streamingNode;
        this.setState({
          isRecurrenceModal: false,
          streamings: newStreaming,
        });
      }
    }
    if (
      auxEventRule.frecuencyUnit === "DAYS" ||
      auxEventRule.frecuencyUnit === "YEARS" ||
      auxEventRule.frecuencyUnit === "HOURS" ||
      auxEventRule.frecuencyUnit === "MINUTES"
    ) {
      if (!errorInput) {
        modalErrorField = "";
        if (errorFrecuencyInit === 1) {
          modalErrorField = `${t(
            "basicWords.streaming.selectOcurrence"
          )}, ${modalErrorField}`;
        }
        if (errorFrecuencyValue === 1) {
          modalErrorField = `${t(
            "basicWords.streaming.frecuencyValue"
          )}, ${modalErrorField}`;
        }
        if (errorNumberOcurrences === 1) {
          modalErrorField = `${t("basicWords.streaming.finishEvent")}, ${modalErrorField}`;
        }
        modalErrorField = modalErrorField.substring(
          0,
          modalErrorField.length - 2
        );
        this.setState({
          showError: true,
          contentNotificationModal: ` ${t("basicWords.streaming.modalErrorBody")}: ${modalErrorField} `,
        });
      } else {
        newStreaming.eventRule = streamingNode;
        this.setState({
          isRecurrenceModal: false,
          streamings: newStreaming,
        });
      }
    }
    if (
      auxEventRule.frecuencyUnit === "MONTHS"
      //Verificación si la frecuencia de repetición no son días para mostrar los días de la semana
    ) {
      if (!errorInput) {
        modalErrorField = "";
        if (errorFrecuencyInit === 1) {
          modalErrorField = `${t(
            "basicWords.streaming.selectOcurrence"
          )}, ${modalErrorField}`;
        }
        if (errorFrecuencyValue === 1) {
          modalErrorField = `${t(
            "basicWords.streaming.frecuencyValue"
          )}, ${modalErrorField}`;
        }
        if (errorNumberOcurrences === 1) {
          modalErrorField = `${t("basicWords.streaming.finishEvent")}, ${modalErrorField}`;
        }
        if (errorMonthDay === 1) {
          modalErrorField = `${t(
            "basicWords.streaming.repetitionMonth"
          )}, ${modalErrorField}`;
        }
        modalErrorField = modalErrorField.substring(
          0,
          modalErrorField.length - 2
        );
        this.setState({
          showError: true,
          contentNotificationModal: ` ${t("basicWords.streaming.modalErrorBody")}: ${modalErrorField} `,
        });
      } else {
        newStreaming.eventRule = streamingNode;
        this.setState({
          isRecurrenceModal: false,
          streamings: newStreaming,
        });
      }
    }
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  onSaveEventModal = () => {
    const {
      parentLPSectionComponentId,
      landingPageId,
      // lpSectionComponentIdComponent,
      lpSectionId,
    } = this.state;
    this.setState({
      isLoadingButton: true,
      FlagClassName: false,
    });
    const { t } = this.props;
    const streamingNodesToAdd = [];
    var newStreaming = { ...this.state.streamings };
    if (newStreaming.checked === true) {
      newStreaming.checked = "true";
    }
    if (newStreaming.checked === false) {
      newStreaming.checked = "false";
    }
    let streamingButtons = newStreaming.buttons
      ? newStreaming.buttons.map((b) => {
        const button = {
          id: b.id,
          name: b.name,
          externalLink: b.externalLink,
          internalLpSectionComponentId: b.component
            ? b.component.id
            : b.internalLpSectionComponentId
              ? b.internalLpSectionComponentId
              : null,
          isEnabled: b.isEnabled,
        };
        return button;
      })
      : [];

    const streamingToAdd = {
      // --- Con esto se crea el registro lpSectionComponent ---
      lpId: landingPageId, // Este valor se obtiene de la vista anterior
      lpSectionId,
      sectionId: 15, // Valor estático para la sección de streaming
      sectionComponentId: 26, // Valor estático de 5, valor para el Streaming component
      //id: lpSectionComponentIdComponent, // Valor estático de 4 puesto que el id 4 es el padre de este nuevo componente
      parentLPSectionComponentId, //
      // --- Con el Id del lpSectionComponent creado, se crean los nodos de este componente
      nodesToAdd: [],
      buttons: streamingButtons,
    };
    let errorInput = false;
    var errorDuration = false;
    var errorDescription = false;
    if (newStreaming.description.length > 249) {
      errorInput = true;
      errorDescription = true;
    }

    //Verification title
    if (newStreaming.title != null) {
      const streamingNode = {};
      streamingNode.id = newStreaming.titleId ? newStreaming.titleId : null;
      streamingNode.sectionComponentNodeId = 87;
      if (newStreaming.title === "") {
        errorInput = true;
      }
      streamingNode.value = newStreaming.title;
      streamingNode.nodeType = "StreamingComponentTitle";
      streamingNodesToAdd.push(streamingNode);
    }
    //Verification description
    if (newStreaming.description != null) {
      const streamingNode = {};
      streamingNode.id = newStreaming.descriptionId
        ? newStreaming.descriptionId
        : null;
      streamingNode.sectionComponentNodeId = 88;
      if (newStreaming.description === "") {
        newStreaming.description = "";
      }
      streamingNode.value = newStreaming.description;
      streamingNode.nodeType = "StreamingComponentDescription";
      streamingNodesToAdd.push(streamingNode);
    }
    //Verification url
    if (newStreaming.url != null) {
      const streamingNode = {};
      streamingNode.id = newStreaming.urlId ? newStreaming.urlId : null;
      streamingNode.sectionComponentNodeId = 89;
      if (newStreaming.url === "") {
        errorInput = true;
      }
      //if url have facebook or youtube
      var regex1 = /www.youtube.com/;
      var regex2 = /www.facebook.com/;
      var resultYT = regex1.test(newStreaming.url);
      var resultFB = regex2.test(newStreaming.url);
      if (resultYT === true || resultFB === true) {
        streamingNode.value = newStreaming.url;
        streamingNode.nodeType = "StreamingComponentUrl";
        streamingNodesToAdd.push(streamingNode);
      } else {
        errorInput = true;
      }
    }
    if (newStreaming.timeInit) {
      var daysDiff = newStreaming.dateFinal.diff( newStreaming.dateInit, "minutes" );
      // daysDiff = daysDiff * 24 * 60;
      var minutesDiff = newStreaming.timeFinal.diff( newStreaming.timeInit, "minutes" );
      // var diff = newStreaming.timeFinal.diff(newStreaming.timeInit, "days");

      if (minutesDiff > daysDiff) {  minutesDiff = minutesDiff - daysDiff; }
      var duration = minutesDiff; //+ daysDiff;
      var daysVerification = newStreaming.dateFinal.diff( newStreaming.dateInit, "days" );
    }
    if ((duration && duration < 0) || duration === 0 || daysVerification < 0) {
      errorDuration = true;
    }
    newStreaming.dateFinal = moment(newStreaming.timeFinal).add(  duration, "minutes" );
    //Verification initial date
    if (newStreaming.dateInitComplete != null) {
      const streamingNode = {};
      streamingNode.id = newStreaming.dateInitId
        ? newStreaming.dateInitId
        : null;
      streamingNode.sectionComponentNodeId = 90;
      streamingNode.value = newStreaming.dateInitComplete.format(format);
      streamingNode.nodeType = "StreamingComponentInitialDate";
      streamingNodesToAdd.push(streamingNode);
    } else {
      if (newStreaming.dateInit != null) {
        const streamingNode = {};
        streamingNode.id = newStreaming.dateInitId
          ? newStreaming.dateInitId
          : null;
        streamingNode.sectionComponentNodeId = 90;
        streamingNode.value = newStreaming.dateInit.format(format);
        streamingNode.nodeType = "StreamingComponentInitialDate";
        streamingNodesToAdd.push(streamingNode);
      } else {
        errorInput = true;
      }
    }
    if (newStreaming.valueRadioGroup !== 2) {
      if (newStreaming.checked === "true") {
        //Verification final date
        if (
          newStreaming.eventOcurrency === "ANUALLY" ||
          newStreaming.eventOcurrency === "PERWEEK" ||
          newStreaming.eventOcurrency === "MONTOFRI" ||
          newStreaming.eventOcurrency === "ALLDAYS" ||
          !newStreaming.eventRule.finalDateOcurrences
        ) {
          delete newStreaming.eventRule.finalDateOcurrences;
          if (newStreaming.dateFinal != null) {
            const streamingNode = {};
            streamingNode.id = newStreaming.dateFinalId
              ? newStreaming.dateFinalId
              : null;
            streamingNode.sectionComponentNodeId = 91;
            streamingNode.value = "";
            streamingNode.nodeType = "StreamingComponentFinalDate";
            streamingNodesToAdd.push(streamingNode);
          } else {
            errorInput = true;
          }
        }
      }
    }

    if (newStreaming.eventRule.specificDayOfMonth) {
      newStreaming.monthDay = newStreaming.eventRule.specificDayOfMonth;
    }
    if (newStreaming.eventRule.relativeDayOfMonth) {
      newStreaming.monthDay = newStreaming.eventRule.relativeDayOfMonth;
    }

    if (
      (newStreaming.monthDay != null &&
        newStreaming.eventOcurrency === newStreaming.monthDay) ||
      (newStreaming.monthDay != null && !newStreaming.eventOcurrency)
    ) {
      delete newStreaming.eventRule.weekdays;
      var regex = /[A-Z]/; //Search letters in the variable
      var result = regex.test(newStreaming.monthDay);
      if (result === false) {
        const streamingNode = {};
        streamingNode.id = newStreaming.eventRuleId
          ? newStreaming.eventRuleId
          : null;
        newStreaming.eventRule.frecuencyValue = newStreaming.eventRule
          .frecuencyValue
          ? newStreaming.eventRule.frecuencyValue
          : 1;
        newStreaming.eventRule.frecuencyValue = `${newStreaming.eventRule.frecuencyValue}`;
        newStreaming.eventRule.frecuencyUnit = "MONTHS";
        newStreaming.eventRule.specificDayOfMonth = newStreaming.monthDay;
        newStreaming.eventRule.initialTime =
          newStreaming.timeInit.format("HH:mm");
        newStreaming.eventRule.duration = `${duration}`;
        if (
          newStreaming.valueRadioGroup === 3 ||
          newStreaming.eventRule.numberOcurrences
        ) {
          // newStreaming.eventRule.numberOcurrences =
          //   newStreaming.eventRule.numberOcurrences;
          newStreaming.eventRule.numberOcurrences = `${newStreaming.eventRule.numberOcurrences}`;
        }
        if (
          newStreaming.valueRadioGroup === 2 ||
          newStreaming.eventRule.finalDateOcurrences
        ) {
          const streamingNode = {};
          streamingNode.id = newStreaming.dateFinalId
            ? newStreaming.dateFinalId
            : null;
          streamingNode.sectionComponentNodeId = 91;
          streamingNode.value = newStreaming.eventRule.finalDateOcurrences;
          streamingNode.nodeType = "StreamingComponentFinalDate";
          streamingNodesToAdd.push(streamingNode);
        }
        let jsonEventRule = JSON.stringify(newStreaming.eventRule);
        newStreaming.eventRule = jsonEventRule.replace(/['"']+/g, '"');
        streamingNode.sectionComponentNodeId = 92;
        streamingNode.value = newStreaming.eventRule;
        streamingNode.nodeType = "StreamingComponentEventRule";
        streamingNodesToAdd.push(streamingNode);
      } else {
        const streamingNode = {};
        streamingNode.id = newStreaming.eventRuleId
          ? newStreaming.eventRuleId
          : null;

        if (newStreaming.eventOcurrency !== undefined) {
          newStreaming.eventRule.frecuencyValue = 1;
          delete newStreaming.eventRule.finalDateOcurrences;
          delete newStreaming.eventRule.numberOcurrences;
          newStreaming.valueRadioGroup = 1;
        }
        newStreaming.eventRule.frecuencyValue = newStreaming.eventRule
          .frecuencyValue
          ? newStreaming.eventRule.frecuencyValue
          : 1;
        newStreaming.eventRule.frecuencyValue = `${newStreaming.eventRule.frecuencyValue}`;
        newStreaming.eventRule.frecuencyUnit = "MONTHS";

        newStreaming.eventRule.relativeDayOfMonth = newStreaming.monthDay;
        newStreaming.eventRule.initialTime =
          newStreaming.timeInit.format("HH:mm");
        newStreaming.eventRule.duration = `${duration}`;
        if (
          newStreaming.valueRadioGroup === 3 ||
          newStreaming.eventRule.numberOcurrences
        ) {
          // newStreaming.eventRule.numberOcurrences =
          //   newStreaming.eventRule.numberOcurrences;
          newStreaming.eventRule.numberOcurrences = `${newStreaming.eventRule.numberOcurrences}`;
        }
        var flag = 0;
        streamingNodesToAdd.forEach((element) => {
          if (element.sectionComponentId === 91) {
            flag = 1;
            delete element.sectionComponentId;
          }
        });
        if (flag === 1) {
          const streamingNode = {};
          streamingNode.id = newStreaming.dateFinalId
            ? newStreaming.dateFinalId
            : null;
          streamingNode.sectionComponentNodeId = 91;
          streamingNode.value = newStreaming.eventRule.finalDateOcurrences
            ? newStreaming.eventRule.finalDateOcurrences
            : "";
          streamingNode.nodeType = "StreamingComponentFinalDate";
          streamingNodesToAdd.push(streamingNode);
        }
        let jsonEventRule = JSON.stringify(newStreaming.eventRule);
        newStreaming.eventRule = jsonEventRule.replace(/['"']+/g, '"');
        streamingNode.sectionComponentNodeId = 92;
        streamingNode.value = newStreaming.eventRule;
        streamingNode.nodeType = "StreamingComponentEventRule";
        streamingNodesToAdd.push(streamingNode);
      }
    } else {
      //Verification repetition event
      if (
        newStreaming.eventOcurrency === "ALLDAYS" ||
        (newStreaming.recurrence === t("basicWords.streaming.allDays") &&
          newStreaming.eventOcurrency === undefined)
      ) {
        // All days of the week
        const streamingNode = {};
        delete newStreaming.eventRule;
        newStreaming.eventRule = {};
        streamingNode.id = newStreaming.eventRuleId
          ? newStreaming.eventRuleId
          : null;
        if (newStreaming.eventOcurrency === "ALLDAYS") {
          newStreaming.eventRule.frecuencyValue = 1;
          delete newStreaming.eventRule.finalDateOcurrences;
        }
        newStreaming.eventRule.frecuencyValue = newStreaming.frecuencyValue
          ? newStreaming.frecuencyValue
          : 1;
        newStreaming.eventRule.frecuencyValue = `${newStreaming.eventRule.frecuencyValue}`;
        newStreaming.eventRule.frecuencyUnit = "WEEKS";
        newStreaming.eventRule.initialTime =
          newStreaming.timeInit.format("HH:mm");
        newStreaming.eventRule.duration = `${duration}`;
        if (newStreaming.eventRule.numberOcurrences) {
          // newStreaming.eventRule.numberOcurrences =
          //   newStreaming.eventRule.numberOcurrences;
          newStreaming.eventRule.numberOcurrences = `${newStreaming.eventRule.numberOcurrences}`;
        }
        newStreaming.eventRule.weekdays = [
          "SUN",
          "MON",
          "TUE",
          "WED",
          "THU",
          "FRI",
          "SAT",
        ];
        streamingNode.sectionComponentNodeId = 92;
        let jsonEventRule = JSON.stringify(newStreaming.eventRule);
        newStreaming.eventRule = jsonEventRule.replace(/['"']+/g, '"');
        streamingNode.value = newStreaming.eventRule;
        streamingNode.nodeType = "StreamingComponentEventRule";
        streamingNodesToAdd.push(streamingNode);
      }

      if (
        newStreaming.eventOcurrency === "MONTOFRI" ||
        (newStreaming.recurrence === t("basicWords.streaming.monToFri") &&
          newStreaming.eventOcurrency === undefined)
      ) {
        // Monday to friday
        const streamingNode = {};
        delete newStreaming.eventRule;
        newStreaming.eventRule = {};
        streamingNode.id = newStreaming.eventRuleId
          ? newStreaming.eventRuleId
          : null;
        if (newStreaming.eventOcurrency === "MONTOFRI") {
          newStreaming.eventRule.frecuencyValue = 1;
          delete newStreaming.eventRule.finalDateOcurrences;
        }
        newStreaming.eventRule.frecuencyValue = newStreaming.frecuencyValue
          ? newStreaming.frecuencyValue
          : 1;
        newStreaming.eventRule.frecuencyValue = `${newStreaming.eventRule.frecuencyValue}`;
        newStreaming.eventRule.frecuencyUnit = "WEEKS";
        newStreaming.eventRule.initialTime =
          newStreaming.timeInit.format("HH:mm");
        newStreaming.eventRule.duration = `${duration}`;
        if (newStreaming.eventRule.numberOcurrences) {
          // newStreaming.eventRule.numberOcurrences =
          //   newStreaming.eventRule.numberOcurrences;
          newStreaming.eventRule.numberOcurrences = `${newStreaming.eventRule.numberOcurrences}`;
        }
        newStreaming.eventRule.weekdays = ["MON", "TUE", "WED", "THU", "FRI"];
        streamingNode.sectionComponentNodeId = 92;
        let jsonEventRule = JSON.stringify(newStreaming.eventRule);
        newStreaming.eventRule = jsonEventRule.replace(/['"']+/g, '"');
        streamingNode.value = newStreaming.eventRule;
        streamingNode.nodeType = "StreamingComponentEventRule";
        streamingNodesToAdd.push(streamingNode);
      }

      if (
        newStreaming.eventOcurrency === "PERWEEK" ||
        (newStreaming.recurrence &&
          newStreaming.recurrence.includes(
            t("basicWords.streaming.every") + " " + t("basicWords.streaming.week")
          ) &&
          newStreaming.eventOcurrency === undefined)
      ) {
        const streamingNode = {};
        delete newStreaming.eventRule;
        newStreaming.eventRule = {};
        if (newStreaming.eventOcurrency === "PERWEEK") {
          newStreaming.eventRule.frecuencyValue = 1;
          delete newStreaming.eventRule.finalDateOcurrences;
        }
        newStreaming.eventRule.frecuencyValue = newStreaming.frecuencyValue
          ? newStreaming.frecuencyValue
          : 1;
        newStreaming.eventRule.frecuencyValue = `${newStreaming.eventRule.frecuencyValue}`;
        newStreaming.eventRule.frecuencyUnit = "WEEKS";
        newStreaming.eventRule.initialTime =
          newStreaming.timeInit.format("HH:mm");
        newStreaming.eventRule.duration = `${duration}`;
        if (newStreaming.eventRule.numberOcurrences) {
          // newStreaming.eventRule.numberOcurrences =
          //   newStreaming.eventRule.numberOcurrences;
          newStreaming.eventRule.numberOcurrences = `${newStreaming.eventRule.numberOcurrences}`;
        }
        const day = new Date(newStreaming.dateInit).getDay(); //Day of the weeek
        if (day === 0) {
          newStreaming.eventRule.weekdays = ["SUN"];
        }
        if (day === 1) {
          newStreaming.eventRule.weekdays = ["MON"];
        }
        if (day === 2) {
          newStreaming.eventRule.weekdays = ["TUE"];
        }
        if (day === 3) {
          newStreaming.eventRule.weekdays = ["WED"];
        }
        if (day === 4) {
          newStreaming.eventRule.weekdays = ["THU"];
        }
        if (day === 5) {
          newStreaming.eventRule.weekdays = ["FRI"];
        }
        if (day === 6) {
          newStreaming.eventRule.weekdays = ["SAT"];
        }

        streamingNode.id = newStreaming.eventRuleId
          ? newStreaming.eventRuleId
          : null;
        streamingNode.sectionComponentNodeId = 92;
        let jsonEventRule = JSON.stringify(newStreaming.eventRule);
        newStreaming.eventRule = jsonEventRule.replace(/['"']+/g, '"');
        streamingNode.value = newStreaming.eventRule;
        streamingNode.nodeType = "StreamingComponentEventRule";
        streamingNodesToAdd.push(streamingNode);
      }
      if (
        newStreaming.eventOcurrency === "ANUALLY" ||
        (newStreaming.recurrence &&
          newStreaming.recurrence.includes(
            t("basicWords.streaming.every") + " " + t("basicWords.streaming.year")
          ) &&
          newStreaming.eventOcurrency === undefined)
      ) {
        const streamingNode = {};
        delete newStreaming.eventRule;
        newStreaming.eventRule = {};
        streamingNode.id = newStreaming.eventRuleId
          ? newStreaming.eventRuleId
          : null;
        if (newStreaming.eventOcurrency === "ANUALLY") {
          newStreaming.eventRule.frecuencyValue = 1;
          delete newStreaming.eventRule.finalDateOcurrences;
        }
        newStreaming.eventRule.frecuencyValue = newStreaming.frecuencyValue
          ? newStreaming.frecuencyValue
          : 1;
        newStreaming.eventRule.frecuencyValue = `${newStreaming.eventRule.frecuencyValue}`;
        newStreaming.eventRule.frecuencyUnit = "YEARS";
        newStreaming.eventRule.initialTime =
          newStreaming.timeInit.format("HH:mm");
        newStreaming.eventRule.duration = `${duration}`;
        if (newStreaming.eventRule.numberOcurrences) {
          // newStreaming.eventRule.numberOcurrences =
          //   newStreaming.eventRule.numberOcurrences;
          newStreaming.eventRule.numberOcurrences = `${newStreaming.eventRule.numberOcurrences}`;
        }
        streamingNode.sectionComponentNodeId = 92;
        let jsonEventRule = JSON.stringify(newStreaming.eventRule);
        newStreaming.eventRule = jsonEventRule.replace(/['"']+/g, '"');
        streamingNode.value = newStreaming.eventRule;
        streamingNode.nodeType = "StreamingComponentEventRule";
        streamingNodesToAdd.push(streamingNode);
      }

      if (
        newStreaming.eventOcurrency === "CUSTOMS" ||
        newStreaming.eventRule.frecuencyValue > 1
      ) {
        const streamingNode = {};
        streamingNode.id = newStreaming.eventRuleId
          ? newStreaming.eventRuleId
          : null;
        streamingNode.sectionComponentNodeId = 92;
        if (newStreaming.valueRadioGroup === 3) {
          // newStreaming.eventRule.numberOcurrences =
          //   newStreaming.eventRule.numberOcurrences;
          newStreaming.eventRule.numberOcurrences = `${newStreaming.eventRule.numberOcurrences}`;
        }
        if (newStreaming.eventRule.numberOcurrences) {
          // newStreaming.eventRule.numberOcurrences =
          //   newStreaming.eventRule.numberOcurrences;
          newStreaming.eventRule.numberOcurrences = `${newStreaming.eventRule.numberOcurrences}`;
        }
        if (
          newStreaming.valueRadioGroup === 2 ||
          newStreaming.eventRule.finalDateOcurrences
        ) {
          const streamingNode = {};
          streamingNode.id = newStreaming.dateFinalId
            ? newStreaming.dateFinalId
            : null;
          streamingNode.sectionComponentNodeId = 91;
          streamingNode.value = newStreaming.eventRule.finalDateOcurrences;
          streamingNode.nodeType = "StreamingComponentFinalDate";
          streamingNodesToAdd.push(streamingNode);
        }
        if (newStreaming.eventRule !== "") {
          var regex = /\\/;
          if (regex.test(newStreaming.eventRule) === true) {
            newStreaming.eventRule = newStreaming.eventRule.replace(
              /['\\"']+/g,
              '"'
            );
            newStreaming.eventRule = JSON.parse(newStreaming.eventRule);
            const streamingNode = {};
            delete newStreaming.eventRule;
            newStreaming.eventRule = {};
            streamingNode.id = newStreaming.eventRuleId
              ? newStreaming.eventRuleId
              : null;
            streamingNode.sectionComponentNodeId = 92;
          } else {
            // newStreaming.eventRule = newStreaming.eventRule;
          }
        }

        // newStreaming.eventRule.frecuencyValue =
        //   newStreaming.eventRule.frecuencyValue;
        newStreaming.eventRule.frecuencyValue = `${newStreaming.eventRule.frecuencyValue}`;
        // newStreaming.eventRule.frecuencyUnit =
        //   newStreaming.eventRule.frecuencyUnit;
        newStreaming.eventRule.initialTime =
          newStreaming.timeInit.format("HH:mm");
        newStreaming.eventRule.duration = `${duration}`;
        if (newStreaming.eventRule.numberOcurrences) {
          // newStreaming.eventRule.numberOcurrences =
          //   newStreaming.eventRule.numberOcurrences;
          newStreaming.eventRule.numberOcurrences = `${newStreaming.eventRule.numberOcurrences}`;
        }
        let jsonEventRule = JSON.stringify(newStreaming.eventRule);
        newStreaming.eventRule = jsonEventRule.replace(/['"']+/g, '"');
        streamingNode.value = newStreaming.eventRule;
        streamingNode.nodeType = "StreamingComponentEventRule";
        streamingNodesToAdd.push(streamingNode);
      }
    }
    if (
      newStreaming.checked === "false" || //If not repeat
      (newStreaming.recurrence === t("basicWords.streaming.noRepeat") &&
        newStreaming.eventOcurrency === undefined)
    ) {
      if (newStreaming.dateFinal != null) {
        const streamingNode = {};
        streamingNode.id = newStreaming.dateFinalId
          ? newStreaming.dateFinalId
          : null;
        streamingNode.sectionComponentNodeId = 91;
        streamingNode.value = moment(newStreaming.dateFinal).format(format);
        streamingNode.nodeType = "StreamingComponentFinalDate";
        streamingNodesToAdd.push(streamingNode);
      } else {
        errorInput = true;
      }
      delete newStreaming.eventRule;
      newStreaming.eventRule = {};
      const streamingNode = {};
      streamingNode.id = newStreaming.eventRuleId
        ? newStreaming.eventRuleId
        : null;
      streamingNode.sectionComponentNodeId = 92;
      newStreaming.eventRule.initialTime =
        newStreaming.timeInit.format("HH:mm");
      newStreaming.eventRule.duration = `${duration}`;
      let jsonEventRule = JSON.stringify(newStreaming.eventRule);
      newStreaming.eventRule = jsonEventRule.replace(/['"']+/g, '"');
      streamingNode.value = newStreaming.eventRule;
      streamingNode.nodeType = "StreamingComponentEventRule";
      streamingNodesToAdd.push(streamingNode);
    }
    if (
      newStreaming.scheduleEventType === null ||
      newStreaming.scheduleEventType === undefined
    ) {
      errorInput = true;
    } else {
      const streamingNode = {};
      streamingNode.id = newStreaming.scheduleEventTypeNodeId
        ? newStreaming.scheduleEventTypeNodeId
        : null;
      streamingNode.sectionComponentNodeId = 93;
      streamingNode.value = LocalizeScheduleEventTypeBD(
        newStreaming.scheduleEventType,
        t
      );
      streamingNode.nodeType = "StreamingComponentScheduleEventType";
      streamingNodesToAdd.push(streamingNode);
    }

    const streamingNode = {};
    streamingNode.id = newStreaming.streamingComponentLastEventsGeneratorDateId
      ? newStreaming.streamingComponentLastEventsGeneratorDateId
      : null;
    streamingNode.sectionComponentNodeId = 94;
    streamingNode.value =
      newStreaming.StreamingComponentLastEventsGeneratorDate = "";
    streamingNode.nodeType = "StreamingComponentLastEventsGeneratorDate";
    streamingNodesToAdd.push(streamingNode);
    if (
      newStreaming.eventOcurrency === null &&
      newStreaming.checked === "true"
    ) {
      errorInput = true;
    }
    streamingToAdd.id = newStreaming.id ? newStreaming.id : null;
    streamingToAdd.lpId = landingPageId;
    streamingToAdd.nodesToAdd = streamingNodesToAdd;
    if (streamingNodesToAdd.length > 0) {
      if (!errorInput && errorDuration === false) {
        let streamingTitle = false;
        let streamingUrl = false;
        let streamingInitialDate = false;
        let streamingFinalDate = false;
        let scheduleEventType = false;
        streamingNodesToAdd.forEach((obj) => {
          if (obj.nodeType === "StreamingComponentTitle") {
            streamingTitle = true;
          }
          if (obj.nodeType === "StreamingComponentUrl") {
            streamingUrl = true;
          }
          if (obj.nodeType === "StreamingComponentInitialDate") {
            streamingInitialDate = true;
          }
          if (obj.nodeType === "StreamingComponentFinalDate") {
            streamingFinalDate = true;
          }
          if (obj.nodeType === "StreamingComponentScheduleEventType") {
            scheduleEventType = true;
          }
        });

        if (
          streamingTitle &&
          streamingUrl &&
          streamingInitialDate &&
          streamingFinalDate &&
          scheduleEventType
        ) {
          if (newStreaming.id) {
            this.updateSectionComponentFunction(streamingToAdd);
          } else {
            this.insertSectionComponentFunction(streamingToAdd);
          }
        }
      } else {
        var modalErrorField = "";
        if (errorDuration === true) {
          modalErrorField = `${t(
            "basicWords.streaming.modalErrorStartDate"
          )}, ${modalErrorField}`;
        }
        if (errorDescription === true) {
          modalErrorField = `${t(
            "basicWords.streaming.modalErrorDescription"
          )}, ${modalErrorField}`;
        }
        if (newStreaming.title === "") {
          modalErrorField = `${t("basicWords.title")}, ${modalErrorField}`;
        }
        if (resultYT === false && resultFB === false) {
          modalErrorField = `${t("generalMessages.url")}, ${modalErrorField}`;
        }
        if (
          newStreaming.scheduleEventType === null ||
          newStreaming.scheduleEventType === undefined
        ) {
          modalErrorField = `${t("basicWords.streaming.typeEvent")}, ${modalErrorField}`;
        }
        if (
          newStreaming.eventOcurrency === null &&
          newStreaming.checked === "true"
        ) {
          modalErrorField = `${t(
            "basicWords.streaming.eventOcurrency"
          )}, ${modalErrorField}`;
        }
        if (newStreaming.dateInit === null || newStreaming.dateFinal === null) {
          modalErrorField = `${t("basicWords.streaming.eventDate")}, ${modalErrorField}`;
        }
        modalErrorField = modalErrorField.substring( 0, modalErrorField.length - 2 );
        this.setState({
          isLoadingButton: false,
          showError: true,
          contentNotificationModal: ` ${t("basicWords.streaming.modalErrorBody")}: ${modalErrorField} `,
        });
      }
    }
  };

  handleGoToEditScheduleStreamigs = (row) => {
    this.getScheduleEventInfoByLpSectionComponentIdFunction(row.id, row);
    this.setState({
      isAddModalVisible: false,
      isEditModalVisible: false,
      isEditRecurrenceModalVisible: true,
    });
  };
  saveStreamingEvent = () => {
    this.setState({
      isLoadingButton: true,
      FlagClassName: false,
    });
    const nodesToAdd = [];
    const {
      streamingContainerTitle,
      streamingContainerUrlPage,
      parentLPSectionComponentId,
      landingPageId,
      lpSectionId,
      saveTitleAndUrl,
    } = {
      ...this.state,
    };
    const streamingToAdd = {
      // --- Con esto se crea el registro lpSectionComponent ---
      lpId: landingPageId, // Este valor se obtiene de la vista anterior
      lpSectionId,
      sectionId: 15, // Valor estático para la sección de streaming
      sectionComponentId: 25, // Valor estático de 5, valor para el Streaming component
      id: parentLPSectionComponentId, // Valor estático de 4 puesto que el id 4 es el padre de este nuevo componente
      // --- Con el Id del lpSectionComponent creado, se crean los nodos de este componente
      nodesToAdd,
      buttons: [],
    };
    let errorInput = false;

    const { t } = this.props;

    //Is modified title or url component
    if (saveTitleAndUrl === true) {
      // Verify each one of the values of the component to add
      if (streamingContainerTitle) {
        if (streamingContainerTitle.value === "") {
          errorInput = true;
        } else {
          //Update lpsection custom name
          let dto = {
            id: lpSectionId,
            customName: streamingContainerTitle.value,
            isEnabled: 1,
          };
          this.updateLpSection(dto);

          streamingContainerTitle.sectionComponentNodeId = 85;
          streamingContainerTitle.nodeType = "StreamingContainerTitle";
          delete streamingContainerTitle.modified;
          nodesToAdd.push(streamingContainerTitle);
        }
      }
      if (streamingContainerUrlPage) {
        if (streamingContainerUrlPage.value !== "") {
          //Saber si la url tiene facebook o youtube
          var regex1 = /www.youtube.com/;
          var regex2 = /www.facebook.com/;
          var resultYT = regex1.test(streamingContainerUrlPage.value);
          var resultFB = regex2.test(streamingContainerUrlPage.value);
          if (resultYT === true || resultFB === true) {
            streamingContainerUrlPage.sectionComponentNodeId = 86;
            streamingContainerUrlPage.nodeType = "StreamingContainerUrlPage";
            delete streamingContainerUrlPage.modified;
            nodesToAdd.push(streamingContainerUrlPage);
          } else {
            errorInput = true;
          }
        } else {
          streamingContainerUrlPage.sectionComponentNodeId = 86;
          streamingContainerUrlPage.nodeType = "StreamingContainerUrlPage";
          delete streamingContainerUrlPage.modified;
          nodesToAdd.push(streamingContainerUrlPage);
        }
      }
      if (nodesToAdd.length > 0) {
        if (!errorInput) {
          this.updateSectionComponentFunction(streamingToAdd);
        } else {
          var modalErrorField = "";
          if (streamingContainerTitle.value === "") {
            modalErrorField = `${t("basicWords.title")}, ${modalErrorField}`;
          }
          if (resultYT === false && resultFB === false) {
            modalErrorField = `${t("generalMessages.url")}: ${t( "basicWords.streaming.urlMustBeChannel" )}, ${modalErrorField}`;
          }
          this.setState({ isLoadingButton: false });
          modalErrorField = modalErrorField.substring( 0, modalErrorField.length - 2  );
          this.setState({ showError: true, contentNotificationModal: ` ${t("basicWords.streaming.modalErrorBody")}: ${modalErrorField} `,});
        }
      }
    } else {
      this.onSaveEventModal();
    }
  };

  //Functions
  cleanStateVariables = () => {
    let streamings = {
      dateInit: moment(),
      timeInit: moment(),
      dateFinal: moment(),
      timeFinal: moment(),
      dateFinish: null,
      eventOcurrency: null,
      frecuencyValue: null,
      frecuencyUnit: null,
      numberOcurrences: null,
      weekdays: [],
      monthDay: null,
      eventRule: {},
      scheduleEventInfo: null,
      scheduleEventType: null,
      scheduleEventTypeId: null,
      title: "",
      url: "",
      description: "",
      date: null,
      dateToShow: null,
      checked: "false",
    };

    this.setState({ streamings });
  };

  dayNameofWeek = (date) => {
    var dayNameOfWeek = new Date(date).getDay();
    var dayWeek = "";
    const { t } = this.props;
    if (dayNameOfWeek === 7) {
      dayNameOfWeek = 0;
    }
    if (dayNameOfWeek === 0) {
      dayNameOfWeek = t("basicWords.streaming.sunday");
      dayWeek = "SUN";
    }
    if (dayNameOfWeek === 1) {
      dayNameOfWeek = t("basicWords.streaming.monday");
      dayWeek = "MON";
    }
    if (dayNameOfWeek === 2) {
      dayNameOfWeek = t("basicWords.streaming.tuesday");
      dayWeek = "TUE";
    }
    if (dayNameOfWeek === 3) {
      dayNameOfWeek = t("basicWords.streaming.wednesday");
      dayWeek = "WED";
    }
    if (dayNameOfWeek === 4) {
      dayNameOfWeek = t("basicWords.streaming.thursday");
      dayWeek = "THU";
    }
    if (dayNameOfWeek === 5) {
      dayNameOfWeek = t("basicWords.streaming.friday");
      dayWeek = "FRI";
    }
    if (dayNameOfWeek === 6) {
      dayNameOfWeek = t("basicWords.streaming.saturday");
      dayWeek = "SAT";
    }
    return [dayNameOfWeek, dayWeek];
  };

  ordinalDayOfMonth = (date) => {
    var count = 1;
    var dayNumber = moment(date).format("D");
    var relativeDay = "";
    var relativeDayValue = null;
    var startOf = dayNumber - 1;
    const { t } = this.props;
    count = Math.trunc(startOf / 7) + count;
    if (count === 1) {
      relativeDayValue = 1;
      relativeDay = t("basicWords.streaming.first");
    }
    if (count === 2) {
      relativeDayValue = 2;
      relativeDay = t("basicWords.streaming.second");
    }
    if (count === 3) {
      relativeDayValue = 3;
      relativeDay = t("basicWords.streaming.third");
    }
    if (count === 4) {
      relativeDayValue = 4;
      relativeDay = t("basicWords.streaming.fourth");
    }
    if (count === 5) {
      relativeDayValue = 5;
      relativeDay = t("basicWords.streaming.fifth");
    }
    return [dayNumber, relativeDayValue, relativeDay];
  };

  monthOfYear = (date) => {
    var dayMonth = moment(date).format("M");
    var monthName = "";
    const { t } = this.props;
    if (dayMonth === "1") {
      monthName = t("basicWords.streaming.january");
    }
    if (dayMonth === "2") {
      monthName = t("basicWords.streaming.february");
    }
    if (dayMonth === "3") {
      monthName = t("basicWords.streaming.march");
    }
    if (dayMonth === "4") {
      monthName = t("basicWords.streaming.april");
    }
    if (dayMonth === "5") {
      monthName = t("basicWords.streaming.may");
    }
    if (dayMonth === "6") {
      monthName = t("basicWords.streaming.june");
    }
    if (dayMonth === "7") {
      monthName = t("basicWords.streaming.july");
    }
    if (dayMonth === "8") {
      monthName = t("basicWords.streaming.august");
    }
    if (dayMonth === "9") {
      monthName = t("basicWords.streaming.september");
    }
    if (dayMonth === "10") {
      monthName = t("basicWords.streaming.october");
    }
    if (dayMonth === "11") {
      monthName = t("basicWords.streaming.november");
    }
    if (dayMonth === "12") {
      monthName = t("basicWords.streaming.december");
    }
    return monthName;
  };

  //// Events ///////
  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleSwitch = (e) => {
    const { streamings } = this.state;
    streamings.checked = e.target.value;
    this.setState({ streamings });
  };
  handleButtonListChange = (buttonList) => {
    const { streamings } = this.state;
    streamings.buttons = buttonList;
    this.setState({ streamings });
  };

  inputHandleEvent = (e, row, name) => {
    // general info update
    const { streamings, scheduleEventInfoToDisable } = this.state;
    const scheduleEventInfo = streamings.scheduleEventInfo;
    if (
      name === "initialDate" ||
      name === "initialTime" ||
      name === "finalDate" ||
      name === "finalTime"
    ) {
      if (e != null && e._isValid && e._isValid === false) {
        row[name] = null;
      } else {
        row[name] = e;
      }
    } else {
      row[name] = e.target.value;
    }
    this.setState({
      scheduleEventInfo: scheduleEventInfo.map((item) => {
        if (item.id === row.id) {
          item = row;
        }
        return item;
      }),
    });
    const { infoToUpdate } = this.state;
    const infoToUpdateValidation = infoToUpdate.filter(
      (item) => item.id === row.id
    );
    if (infoToUpdateValidation.length > 0) {
      _.remove(infoToUpdate, (obj) => obj.id === row.id);
    }
    infoToUpdate.push(row);
    this.setState({ infoToUpdate });
    if (infoToUpdate.length > 0 || scheduleEventInfoToDisable.length > 0) {
      this.setState({ disabledSaveButton: false });
    } else {
      this.setState({ disabledSaveButton: true });
    }
  };

  onChangeDeleteCheckboxModal = (e, row) => {
    const { infoToUpdate, scheduleEventInfoToDisable } = this.state;

    if (row && row.id) {
      const infoToUpdateValidation = scheduleEventInfoToDisable.filter(
        (item) => item === row.id
      );
      if (infoToUpdateValidation.length > 0) {
        _.remove(scheduleEventInfoToDisable, (obj) => obj === row.id);
      } else {
        scheduleEventInfoToDisable.push(row.id);
      }
    } else if (row === "all") {
      const { streamings } = this.state;
      streamings.scheduleEventInfo.forEach((row) => {
        const infoToUpdateValidation = scheduleEventInfoToDisable.filter(
          (item) => item === row.id
        );
        if (infoToUpdateValidation.length === 0) {
          scheduleEventInfoToDisable.push(row.id);
        }
      });
    }
    this.setState({ scheduleEventInfoToDisable });
    if (infoToUpdate.length > 0 || scheduleEventInfoToDisable.length > 0) {
      this.setState({ disabledSaveButton: false });
    } else {
      this.setState({ disabledSaveButton: true });
    }
  };

  onCloseModal = () => {
    this.cleanStateVariables();
    this.setState({
      isAddModalVisible: false,
      isEditModalVisible: false,
      isEditRecurrenceModalVisible: false,
      isRecurrenceModal: false,
    });
  };

  onCloseRecurrenceModal = () => {
    this.cleanStateVariables();
    const { streamings } = { ...this.state };
    streamings.eventOcurrency = "NOREPEAT";
    streamings.valueRadioGroup = null;
    this.setState({
      isRecurrenceModal: false,
      streamings,
      recurrenceSelect: "NOREPEAT",
    });
  };

  onCloseEditRecurrenceModal = () => {
    this.cleanStateVariables();
    const { streamings } = { ...this.state };
    streamings.typeEvent = "NOREPEAT";
    streamings.valueRadioGroup = null;
    streamings.scheduleEventInfo = [];
    this.setState({
      isEditRecurrenceModalVisible: false,
      streamings,
      recurrenceSelect: "NOREPEAT",
      isLoadingSheduleInfoModal: true,
    });
  };

  OnchangeInitDate = (e, index) => {
    var { streamings } = { ...this.state };
    let date = moment(e.target.value);

    if (moment(date)._isValid === false) {
      streamings.dateInit = null;
    } else {
      let data = `${date._d} `;
      let dateInit = data.substring(0, 15);
      if (index !== undefined) {
        streamings.scheduleEventInfo[index].initialDate = moment(date._d);
      } else {
        streamings.dateInitCut = dateInit;
        streamings.dateInit = moment(date._d);
      }
    }
    this.setState({
      streamings,
      FlagClassName: true,
    });
    this.OnchangeInitTime(date, index);
  };

  OnchangeInitTime = (date) => {
    const { streamings } = { ...this.state };
    if (moment(date)._isValid === false) {
      streamings.timeInit = null;
    } else {
      let data = `${date._d} `;
      let initPart = streamings.dateInitCut
        ? streamings.dateInitCut
        : data.substring(0, 15);
      let timeInit = data.substring(16, 24);
      let dateInitComplete =
        initPart + " " + timeInit + " GMT-0500 (hora estándar de Colombia)";
      streamings.timeInit = moment(date._d);
      streamings.dateInitComplete = moment(dateInitComplete);
    }
    this.setState({
      streamings,
      FlagClassName: true,
    });
  };

  OnchangeFinalDate = (e, index) => {
    var { streamings } = { ...this.state };
    let date = moment(e.target.value);

    if (moment(date)._isValid === false) {
      streamings.dateFinal = null;
    } else {
      let data = `${date._d} `;
      let dateFinal = data.substring(0, 15);
      if (index !== undefined) {
        streamings.scheduleEventInfo[index].finalDate = moment
          .utc(date._d)
          .subtract(5, "hours");
      } else {
        streamings.dateFinalCut = dateFinal;
        streamings.dateFinal = moment(date._d);
      }
    }
    this.setState({
      streamings,
      FlagClassName: true,
    });
    this.OnchangeFinalTime(date, index);
  };

  OnchangeFinalTime = (date, index) => {
    const { streamings } = { ...this.state };
    if (moment(date)._isValid === false) {
      streamings.timeFinal = null;
    } else {
      let data = `${date._d} `;
      let initPart = streamings.dateFinalCut
        ? streamings.dateInitCut
        : data.substring(0, 15);
      let timeFinal = data.substring(16, 24);
      let dateFinalComplete =
        initPart + " " + timeFinal + " GMT-0500 (hora estándar de Colombia)";
      streamings.dateFinalComplete = moment(dateFinalComplete).format(format);
      streamings.timeFinal = moment(date._d);
    }
    this.setState({
      streamings,
      FlagClassName: true,
    });
  };

  OnchangeFinishDate = (date) => {
    var { streamings } = { ...this.state };
    streamings.eventRule.finalDateOcurrences = moment(date).format(format);
    this.setState({
      streamings,
    });
  };

  onChangeNumberOcurrences = (e) => {
    let value = e.target.value;
    const { streamings } = { ...this.state };
    streamings.numberOcurrences = value;
    this.setState({ streamings });
  };

  onChangeFrecuencyUnit = (e) => {
    let value = e.target.value;
    const { streamings } = { ...this.state };
    var regex = /\\/;
    if (regex.test(streamings.eventRule) === true) {
      streamings.eventRule = streamings.eventRule.replace(/['\\"']+/g, '"');
      streamings.eventRule = JSON.parse(streamings.eventRule);
    }
    streamings.eventRule.frecuencyUnit = value;
    this.setState({ streamings });
    if (streamings.eventRule.frecuencyUnit !== "MONTHS") {
      delete streamings.eventRule.relativeDayOfMonth;
      delete streamings.eventRule.specificDayOfMonth;
    }
    this.setState({ streamings });
  };
  onChangeFrecuencyValue = (e) => {
    let value = e.target.value;
    const { streamings } = { ...this.state };
    var regex = /\\/;
    if (regex.test(streamings.eventRule) === true) {
      streamings.eventRule = streamings.eventRule.replace(/['\\"']+/g, '"');
      streamings.eventRule = JSON.parse(streamings.eventRule);
    }
    streamings.eventRule.frecuencyValue = value;
    streamings.frecuencyValue = value;
    this.setState({ streamings });
  };

  onChangeWeekdays = (e) => {
    let value = e.target.value;
    const { streamings } = { ...this.state };
    var regex = /\\/;
    if (regex.test(streamings.eventRule) === true) {
      streamings.eventRule = streamings.eventRule.replace(/['\\"']+/g, '"');
      streamings.eventRule = JSON.parse(streamings.eventRule);
    }
    streamings.eventRule.weekdays = value;
    streamings.weekdays = value;
    this.setState({ streamings });
  };

  onChangeMonthDay = (e) => {
    let value = e.target.value;
    const { streamings } = { ...this.state };
    var regex = /\\/;
    if (regex.test(streamings.eventRule) === true) {
      streamings.eventRule = streamings.eventRule.replace(/['\\"']+/g, '"');
      streamings.eventRule = JSON.parse(streamings.eventRule);
    }
    streamings.monthDay = value;
    var regex = /[A-Z]/; //Search letters in the variable
    var result = regex.test(streamings.monthDay);
    if (result === false) {
      delete streamings.eventRule.relativeDayOfMonth;
      delete streamings.eventRule.specificDayOfMonth;
      streamings.eventRule.specificDayOfMonth = streamings.monthDay;
    } else {
      delete streamings.eventRule.relativeDayOfMonth;
      delete streamings.eventRule.specificDayOfMonth;
      streamings.eventRule.relativeDayOfMonth = streamings.monthDay;
    }
    this.setState({ streamings });
  };

  onChangeRadioGroup = (e) => {
    let event = e.target.value;
    const { streamings } = { ...this.state };
    streamings.valueRadioGroup = event;
    this.setState({
      streamings,
    });
  };

  onChangeSelectScheduleEventType = (event, option) => {
    const { streamings } = { ...this.state };
    streamings.scheduleEventTypeId = event.value;
    streamings.scheduleEventType = event.label;
    this.setState({ streamings });
  };

  onChangeSelectOcurrency = (e) => {
    let event = e.target.value;
    const { streamings } = { ...this.state };
    if (
      event !== "NOREPEAT" &&
      event !== "ALLDAYS" &&
      event !== "MONTOFRI" &&
      event !== "PERWEEK" &&
      event !== "ANUALLY" &&
      event !== "CUSTOMS"
    ) {
      streamings.monthDay = event;
    } else {
      if (streamings.eventRule !== "") {
        var regex = /\\/;
        if (regex.test(streamings.eventRule) === true) {
          streamings.eventRule = streamings.eventRule.replace(/['\\"']+/g, '"');
          streamings.eventRule = JSON.parse(streamings.eventRule);
          delete streamings.eventRule.relativeDayOfMonth;
          delete streamings.eventRule.specificDayOfMonth;
        } else {
          streamings.eventRule = streamings.eventRule;
        }
      }
      streamings.monthDay = null;
    }
    streamings.eventOcurrency = event;
    this.setState({
      streamings,
      recurrenceSelect: event,
    });
    if (event === "CUSTOMS") {
      this.setState({
        isRecurrenceModal: true,
      });
    }
  };

  updateInfoModal = () => {
    const newStreaming = { ...this.state.streamings };
    newStreaming.title = this.state.dataYT.localized.title;
    newStreaming.description = this.state.dataYT.localized.description;
    this.setState({ streamings: newStreaming });
  };

  handleClickOpenDeleteImage = () => {
    this.setState({ openDeleteImage: true });
  };

  handleCloseDeleteImage = () => {
    this.setState({ openDeleteImage: false });
  };

  handleClickOpenDeleteNews = (id) => {
    this.setState({ openDeleteNews: true, elementToDeleteId: id });
  };

  handleCloseDeleteNews = () => {
    this.setState({ openDeleteNews: false });
  };

  handleClickOpenModalToChangeName = (itemToChangeName) => {
    let open = this.state.openModalToChangeName;
    this.setState({
      openModalToChangeName: !open,
      customName: itemToChangeName,
    });
  };

  OnChangeSectionCustomName = (e) => {
    let newCustomName = this.state.newCustomName;
    newCustomName = e.target.value;

    this.setState({
      newCustomName,
      FlagClassName: true,
    });
  };

  saveChangeName = () => {
    const { t } = this.props;
    var { streamingContainerTitle, newCustomName, lpSectionId } = this.state;

    //Update lpsection custom name
    let dto = {
      id: lpSectionId,
      customName: newCustomName,
      isEnabled: 1,
    };
    this.updateLpSection(dto);

    //Update section title name
    const nodesToUpdate = [];
    let errorInput = false;

    if (streamingContainerTitle) {
      if (newCustomName === "") {
        errorInput = true;
      } else {
        streamingContainerTitle.value = newCustomName;
      }
      delete streamingContainerTitle.edited;
      nodesToUpdate.push(streamingContainerTitle);
    }

    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateSectionComponentFunction(nodesToUpdate);
      } else {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.fieldEmpty"),
          isLoadingButton: false,
        });
      }
    }
  };

  setShowError = (open) => {
    this.setState({ showError: open });
  };

  setShowSuccess = (open) => {
    this.setState({ showSuccess: open });
  };

  setShowWarning = (open) => {
    this.setState({ showWarning: open });
  };

  // Render Info
  render() {
    const {
      redirectBack,
      isAddModalVisible,
      isEditModalVisible,
      streamings,
      isLoadingButton,
      isEditRecurrenceModalVisible,
      height,
      streamingContainerTitle,
      streamingContainerUrlPage,
      streamingsToShow,
      organizationId,
      organizationName,
      recurrenceSelect,
      isRecurrenceModal,
      disabledSaveButton,
      isEditPreview,
      scheduleEventTypeOptions,
      isLoadingSheduleInfoModal,
      name,
      subpageId,
      landingPageId,
      sectionName,
      FlagClassName,
      goToWithoutSaveModalVisible,
      lpUrl,

      // Modals
      showSuccess,
      showWarning,
      showError,
      contentNotificationModal,

      //Modal delete item
      openDeleteNews,
      elementToDeleteId,

      openModalToChangeName,
      customName,
    } = this.state;

    const { t } = this.props;

    if (redirectBack) {
      return <Redirect to="/manage/web" />;
    }

    if (streamingsToShow !== undefined) {
      streamingsToShow.forEach((element) => {
        if (element.eventRule !== "" && !element.eventRule.initialTime) {
          var auxEventRule = JSON.parse(element.eventRule);
          element.eventRule = JSON.parse(element.eventRule);
        } else {
          var auxEventRule = element.eventRule;
        }
        if (element.dateInit !== "") {
          if (element.dateInit._d === undefined) {
            var initDate = element.dateInit.split("-");
          }
        }
        if (element.eventRule.initialTime !== undefined) {
          if (!element.eventRule.initialTime._d) {
            var initTime = element.eventRule.initialTime.split(":");
          }
        }
        if (initDate !== undefined && initTime !== undefined) {
          element.dateInit = moment([
            initDate[0],
            parseFloat(initDate[1]) - 1,
            initDate[2],
            initTime[0],
            initTime[1],
          ]);
          element.dateFinal = moment(element.dateInit).add(
            element.eventRule.duration,
            "minutes"
          );
          element.dateI = moment(element.dateInit).format("DD/MM/YYYY - HH:mm");
          element.dateF = moment(element.dateFinal).format(
            "DD/MM/YYYY - HH:mm"
          );
          element.timeInit = element.dateInit;
          element.timeFinal = element.dateFinal;
        }

        var day = this.dayNameofWeek(element.dateInit);
        var dayNameOfWeek = day[0];
        var dayWeek = day[1];

        if (!auxEventRule.frecuencyUnit) {
          element.recurrence = t("basicWords.streaming.noRepeat");
        }
        if (auxEventRule.frecuencyUnit === "MINUTES") {
          if (auxEventRule.frecuencyValue > 1) {
            element.recurrence =
              t("basicWords.streaming.every") +
              " " +
              auxEventRule.frecuencyValue +
              " " +
              t("basicWords.streaming.minutes");
          } else {
            element.recurrence =
              t("basicWords.streaming.every") + " " + t("basicWords.streaming.minute");
          }
        }
        if (auxEventRule.frecuencyUnit === "HOURS") {
          if (auxEventRule.frecuencyValue > 1) {
            element.recurrence =
              t("basicWords.streaming.every") +
              " " +
              auxEventRule.frecuencyValue +
              " " +
              t("basicWords.streaming.hours");
          } else {
            element.recurrence =
              t("basicWords.streaming.every") + " " + t("basicWords.streaming.hour");
          }
        }
        if (auxEventRule.frecuencyUnit === "DAYS") {
          if (auxEventRule.frecuencyValue > 1) {
            element.recurrence =
              t("basicWords.streaming.every") +
              " " +
              auxEventRule.frecuencyValue +
              " " +
              t("basicWords.streaming.days");
          } else {
            element.recurrence =
              t("basicWords.streaming.every") + " " + t("basicWords.streaming.day");
          }
        }
        if (auxEventRule.frecuencyUnit === "WEEKS") {
          if (auxEventRule.weekdays) {
            var days = "";
            auxEventRule.weekdays.forEach((element) => {
              if (element === "SUN") days = `${days} ${t("basicWords.streaming.sunday")}, `;
              if (element === "MON") days = `${days} ${t("basicWords.streaming.monday")}, `;
              if (element === "TUE") days = `${days} ${t("basicWords.streaming.tuesday")}, `;
              if (element === "WED") days = `${days} ${t("basicWords.streaming.wednesday")}, `;
              if (element === "THU") days = `${days} ${t("basicWords.streaming.thursday")}, `;
              if (element === "FRI") days = `${days} ${t("basicWords.streaming.friday")}, `;
              if (element === "SAT") days = `${days} ${t("basicWords.streaming.saturday")}, `;
            });
            days = days.substring(0, days.length - 2);
            if (
              auxEventRule.frecuencyValue &&
              auxEventRule.frecuencyValue > 1
            ) {
              element.recurrence =
                t("basicWords.streaming.every") +
                " " +
                auxEventRule.frecuencyValue +
                " " +
                t("basicWords.streaming.weeks") +
                " " +
                t("basicWords.streaming.the") +
                " " +
                days;
            } else {
              element.recurrence =
                t("basicWords.streaming.every") +
                " " +
                t("basicWords.streaming.week") +
                " " +
                t("basicWords.streaming.the") +
                " " +
                days;
            }
          }
          if (
            auxEventRule.weekdays &&
            auxEventRule.weekdays[0] === "SUN" &&
            auxEventRule.weekdays[1] === "MON" &&
            auxEventRule.weekdays[2] === "TUE" &&
            auxEventRule.weekdays[3] === "WED" &&
            auxEventRule.weekdays[4] === "THU" &&
            auxEventRule.weekdays[5] === "FRI" &&
            auxEventRule.weekdays[6] === "SAT"
          ) {
            element.recurrence = t("basicWords.streaming.allDays");
          }
          if (
            auxEventRule.weekdays &&
            auxEventRule.weekdays[0] === "MON" &&
            auxEventRule.weekdays[1] === "TUE" &&
            auxEventRule.weekdays[2] === "WED" &&
            auxEventRule.weekdays[3] === "THU" &&
            auxEventRule.weekdays[4] === "FRI"
          ) {
            element.recurrence = t("basicWords.streaming.monToFri");
          }
          if (
            auxEventRule.weekdays &&
            auxEventRule.weekdays[0] === dayWeek &&
            auxEventRule.frecuencyValue === 1
          ) {
            element.recurrence =
              t("basicWords.streaming.every") +
              " " +
              t("basicWords.streaming.week") +
              ", " +
              t("basicWords.streaming.the") +
              " " +
              dayNameOfWeek;
          }
        }
        if (auxEventRule.frecuencyUnit === "MONTHS") {
          if (element.dateInit != null) {
            var relativeDay = this.ordinalDayOfMonth(element.dateInit);
            var dayNumber = relativeDay[0];
            var relativeDay = relativeDay[2];
          }
          element.recurrence =
            t("basicWords.streaming.every") +
            " " +
            auxEventRule.frecuencyValue +
            " " +
            t("basicWords.streaming.months");
          if (auxEventRule.relativeDayOfMonth) {
            if (auxEventRule.frecuencyValue > 1) {
              element.recurrence =
                t("basicWords.streaming.every") +
                " " +
                auxEventRule.frecuencyValue +
                " " +
                t("basicWords.streaming.months") +
                ", " +
                relativeDay +
                " " +
                dayNameOfWeek;
            } else {
              element.recurrence =
                relativeDay +
                " " +
                dayNameOfWeek +
                " " +
                t("basicWords.streaming.on") +
                " " +
                t("basicWords.streaming.every") +
                " " +
                t("basicWords.streaming.month");
            }
          }
          if (auxEventRule.specificDayOfMonth) {
            if (auxEventRule.frecuencyValue > 1) {
              element.recurrence =
                t("basicWords.streaming.the") +
                " " +
                t("basicWords.streaming.day") +
                " " +
                dayNumber +
                " " +
                t("basicWords.streaming.on") +
                " " +
                t("basicWords.streaming.every") +
                " " +
                t("basicWords.streaming.month") +
                ", " +
                t("basicWords.streaming.every") +
                " " +
                auxEventRule.frecuencyValue +
                " " +
                t("basicWords.streaming.months");
            } else {
              element.recurrence =
                t("basicWords.streaming.the") +
                " " +
                dayNumber +
                " " +
                t("basicWords.streaming.on") +
                " " +
                t("basicWords.streaming.every") +
                " " +
                t("basicWords.streaming.month");
            }
          }
        }
        if (auxEventRule.frecuencyUnit === "YEARS") {
          var dayNumber = moment(element.dateInit).format("D");
          var monthName = this.monthOfYear(element.dateInit);
          if (auxEventRule.frecuencyValue > 1) {
            element.recurrence =
              t("basicWords.streaming.the") +
              " " +
              dayNumber +
              " " +
              t("basicWords.streaming.on") +
              " " +
              monthName +
              " " +
              t("basicWords.streaming.every") +
              " " +
              auxEventRule.frecuencyValue +
              " " +
              t("basicWords.streaming.years");
          } else {
            element.recurrence =
              t("basicWords.streaming.the") +
              " " +
              dayNumber +
              " " +
              t("basicWords.streaming.on") +
              " " +
              monthName +
              " " +
              t("basicWords.streaming.every") +
              " " +
              t("basicWords.streaming.year");
          }
        }
        if (
          // If event repeat
          element.title !== "" &&
          element.recurrence !== t("basicWords.streaming.noRepeat")
        ) {
          element.checked = true;
        }
        auxEventRule = element.eventRule;
      });
    }

    return (
      <React.Fragment>

        <GeneralModal
          visible={openModalToChangeName}
          title={"Cambiar nombre"}
          body={[
            <div className="col-12" style={{ margin: "5px" }}>
              <div className="row">
                <div className="col-md-12 componentSpace">
                  <TextField
                    variant="outlined"
                    style={{ width: "100%", marginLeft: 10 }}
                    label={t("basicWords.section")}
                    onChange={(e) => this.OnChangeSectionCustomName(e)}
                    defaultValue={
                      customName ? decodeURIComponent(customName) : ""
                    }
                  />
                </div>
              </div>
            </div>,
          ]}
          footer={[
            <div className="row buttonAtTheBottom">
              <div>
                <ButtonComponent
                  onClick={(e) => this.handleClickOpenModalToChangeName()}
                  shape="round"
                  text={t("basicWords.cancel")}
                  className="buttonDiscard"
                />
              </div>
              <div className="col-6">
                <ButtonComponent
                  isDisabled={false}
                  onClick={() => this.saveChangeName()}
                  shape="round"
                  text={t("basicWords.ok")}
                />
              </div>
            </div>,
          ]}
          onClick={() => this.handleClickOpenModalToChangeName()}
        />

        <RecurrenceStreamingModal
          visible={isRecurrenceModal}
          newStreaming={streamings}
          handleCheck={this.onCheckEventModal}
          handleClose={this.onCloseRecurrenceModal}
          handleChange={this.handleChange}
          handleRadioGroup={this.onChangeRadioGroup}
          handleButtonListChange={this.handleButtonListChange}
          finishDate={this.OnchangeFinishDate}
          handleChangeNumberOcurrences={this.onChangeNumberOcurrences}
          handleFrecuencyUnit={this.onChangeFrecuencyUnit}
          handleFrecuencyValue={this.onChangeFrecuencyValue}
          handleWeekdays={this.onChangeWeekdays}
          handleMonthDay={this.onChangeMonthDay}
          dayNameofWeek={this.dayNameofWeek}
          ordinalDayOfMonth={this.ordinalDayOfMonth}
          eventRule={streamings.eventRule}
          isEditPreview={isEditPreview}
          lpId={this.state.landingPageId}
        />

        <EditRecurrenceStreamingModal
          visible={isEditRecurrenceModalVisible}
          scheduleEventInfo={streamings.scheduleEventInfo}
          loadingInfo={isLoadingSheduleInfoModal}
          isLoading={isLoadingButton}
          height={height}
          disabledSaveButton={disabledSaveButton}
          initDate={this.OnchangeInitDate}
          finalDate={this.OnchangeFinalDate}
          handleSave={this.onSaveEditRecurrenceModal}
          handleClose={this.onCloseEditRecurrenceModal}
          handleDisable={this.disableStreamingEvent}
          handleChange={this.handleChange}
          handleButtonListChange={this.handleButtonListChange}
          disableStreamingEvent={this.disableStreamingEvent}
          dateEvent={this.OnchangeDate}
          lpId={this.state.landingPageId}
          inputHandleEvent={this.inputHandleEvent}
          onChangeDelete={this.onChangeDeleteCheckboxModal}
        />

        {!!showWarning && (
          <SystemNotification
            setShowNotification={(e) => this.setShowWarning(e)}
            type="Warning"
            caption={contentNotificationModal}
          />
        )}
        {!!showSuccess && (
          <SystemNotification
            setShowNotification={(e) => this.setShowSuccess(e)}
            type="Success"
            caption={contentNotificationModal}
          />
        )}
        {!!showError && (
          <SystemNotification
            setShowNotification={(e) => this.setShowError(e)}
            type="Error"
            caption={contentNotificationModal}
            
            />
        )}

        <DeleteItemModal
          visible={openDeleteNews}
          onCancel={this.handleCloseDeleteNews}
          onClick={(e) => this.daisbleNewsEvent(elementToDeleteId)}
        />

        <div className="editStreamingModal">
          <EditStreamingModal
            visible={isEditModalVisible}
            isEditPreview={isEditPreview}
            newStreaming={streamings}
            isLoading={isLoadingButton}
            handleSave={this.onSaveEventModal}
            handleGoToEditScheduleStreamigs={
              this.handleGoToEditScheduleStreamigs
            }
            handleClose={this.onCloseModal}
            handleChange={this.handleChange}
            handleButtonListChange={this.handleButtonListChange}
            handleSwitch={this.handleSwitch}
            initDate={this.OnchangeInitDate}
            initTime={this.OnchangeInitTime}
            finalDate={this.OnchangeFinalDate}
            finalTime={this.OnchangeFinalTime}
            handleSelectScheduleEventType={this.onChangeSelectScheduleEventType}
            handleSelectOcurrency={this.onChangeSelectOcurrency}
            dayNameofWeek={this.dayNameofWeek}
            ordinalDayOfMonth={this.ordinalDayOfMonth}
            monthOfYear={this.monthOfYear}
            recurrenceSelect={recurrenceSelect}
            lpId={this.state.landingPageId}
            scheduleEventTypeOptions={scheduleEventTypeOptions}
          />
        </div>


        <section className="Title">
          <BackArrow className="ico pointerCursor" />
          <h3>Página Web</h3>
        </section>
        <section className="mail">
          <Card className="card">
            {isLoadingButton === true ? (
              <div
                className="col-xs-12 col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  transform: "translateY(800%)",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="row componentSpace spaceBreadcrumb">
                  <BreadcrumbSection
                    principal={t("sections.streamings")}
                    secondary={organizationName}
                    route="./manage/web"
                    propsComponent={{
                      organizationId,
                      sectionName: sectionName,
                      nameSubpage: name ? name : null,
                      lpId: landingPageId,
                      subpageId: subpageId ? subpageId : null,
                      lpUrl: lpUrl,
                      customName,
                    }}
                    handleClickOpenModalToChangeName={this.handleClickOpenModalToChangeName}
                    FlagclassName={FlagClassName}
                    onClick={(e) => this.saveAboutEvent(e)}
                    GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                    onCancelGotoWithoutMModal={(e) =>
                      this.onCancelGotoWithoutMModal(e)
                    }
                    goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                  />
                </div>

                <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                  <Title
                    title={t("basicWords.completeInformationOfYourSection")}
                  />
                </div>

                <Card
                  className="card"
                  style={{ margin: "2% 5.5%", height: "auto", padding: "20px" }}
                >
                  <div
                    className="row justify-content-center componentSpace"
                    style={{
                      display: "flex",
                      alignitems: "center",
                    }}
                  >
                    <TitleCard text={t("generalUser.sectionContent")} />
                  </div>

                  {/* Content of the section */}
                  <div className="accordionContent">
                    <div className="accordionCenterLine">
                      <div
                        style={{
                          width: "45%",
                          marginLeft: "1%",
                          marginRight: "0.7%",
                        }}
                      >
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("basicWords.enterTitle")}
                          id={
                            streamingContainerTitle
                              ? streamingContainerTitle.nodeType
                              : null
                          }
                          onChange={(e, id) => this.handleChange(e, id)}
                          value={
                            streamingContainerTitle
                              ? streamingContainerTitle.value
                              : ""
                          }
                        />
                      </div>
                      <div
                        style={{
                          width: "45%",
                          marginLeft: "1%",
                          marginRight: "0.7%",
                        }}
                      >
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("basicWords.enterStreamingPage")}
                          id={
                            streamingContainerUrlPage
                              ? streamingContainerUrlPage.nodeType
                              : ""
                          }
                          onChange={(e, id) => this.handleChange(e, id)}
                          value={
                            streamingContainerUrlPage
                              ? streamingContainerUrlPage.value
                              : ""
                          }
                        />
                      </div>
                      <div
                        style={{
                          width: "auto",
                          marginLeft: "0.7%",
                          marginRight: "1%",
                        }}
                      >
                        <ButtonComponent
                          onClick={(e) => this.addStreamingEvent(e)}
                          props={null}
                          shape="round"
                          className="buttonToAdd"
                          text={t("basicWords.add")}
                          icon={<Plus />}
                        />
                      </div>
                    </div>
                  </div>
                </Card>

                {isAddModalVisible ? (
                  <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                    <AddStreamingModal
                      visible={isAddModalVisible}
                      // isEditPreview={isEditPreview}
                      newStreaming={streamings}
                      isLoading={isLoadingButton}
                      handleSave={this.onSaveEventModal}
                      handleGoToEditScheduleStreamigs={
                        this.handleGoToEditScheduleStreamigs
                      }
                      handleClose={this.onCloseModal}
                      handleChange={this.handleChange}
                      handleButtonListChange={this.handleButtonListChange}
                      handleSwitch={this.handleSwitch}
                      initDate={this.OnchangeInitDate}
                      initTime={this.OnchangeInitTime}
                      finalDate={this.OnchangeFinalDate}
                      finalTime={this.OnchangeFinalTime}
                      handleSelectScheduleEventType={
                        this.onChangeSelectScheduleEventType
                      }
                      handleSelectOcurrency={this.onChangeSelectOcurrency}
                      dayNameofWeek={this.dayNameofWeek}
                      ordinalDayOfMonth={this.ordinalDayOfMonth}
                      monthOfYear={this.monthOfYear}
                      recurrenceSelect={recurrenceSelect}
                      lpId={this.state.landingPageId}
                      scheduleEventTypeOptions={scheduleEventTypeOptions}
                    />
                  </div>
                ) : null}

                <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                  <Title title={t("basicWords.listStreamings")} />
                </div>

                {/* Table */}
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth adjustPositionYBottom">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("basicWords.title")}</TableCell>
                          <TableCell>{t("basicWords.description")}</TableCell>
                          <TableCell>{t("basicWords.recurrence")}</TableCell>
                          <TableCell>{t("basicWords.streaming.typeEvent")}</TableCell>
                          <TableCell>{t("basicWords.streaming.initDate")}</TableCell>
                          <TableCell>{t("basicWords.streaming.endDate")}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {streamingsToShow.map((row) => {
                          return (
                            <TableRow key={row.title}>
                              <TableCell>{row.title}</TableCell>
                              <TableCell>{row.description}</TableCell>
                              <TableCell>{row.recurrence}</TableCell>
                              <TableCell>
                                {LocalizeScheduleEventType(
                                  row.scheduleEventType,
                                  t
                                )}
                              </TableCell>
                              <TableCell>{row.dateI}</TableCell>
                              <TableCell>{row.dateF}</TableCell>
                              <TableCell>
                                <div className="containerIconsTable">
                                  <div className="bgIconEditStreaming">
                                    <Edit
                                      className="iconEdit"
                                      onClick={(e) =>
                                        this.streamingSuccessfullyEdited(e, row)
                                      }
                                    />
                                  </div>
                                  <div className="bgIconEyeStreaming">
                                    <Eye
                                      className="iconEye"
                                      onClick={(e) =>
                                        this.editRecurrenceStreamingEvent(e, row)
                                      }
                                    />
                                  </div>
                                  <div className="bgIconTrash">
                                    <Trash2
                                      className="iconTrash"
                                      onClick={() =>
                                        this.disableStreamingEvent(row.id)
                                      }
                                    />
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            )}
          </Card>

        </section>
        {/* Buttons */}
        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName,
            name,
            landingPageId,
            subpageId,
            lpUrl,
          }}
          FlagclassName={FlagClassName}
          onClick={(e) => this.saveStreamingEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />

      </React.Fragment>

    );
  }
}
export default withTranslation()(StreamingSection);
