export const host =  process.env.REACT_APP_API_URL_CMS;
export const port = ':8086';
export const route= "/auracms";
export const datasource = "/app";
export const s3Bucket = "somosaura-cms";

export const loginRoute = '/authserver/auth'
export const clientId = 'ZuulGateway'
export const clientPass = 'zuulSecret'

export const imageQuality = 100