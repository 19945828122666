import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import { useTranslation } from 'react-i18next';
import { get as _get } from "lodash";
import "../../community/components/css/deleteUser.css";
import { updateUser } from "../../../actions/uhoo";
import { UsersCommunityContext } from "./../../community/contex/index";

import SystemNotification from "../SystemNotification.jsx";

function DeleteUser(props) {
    const { update, setUpdate } = useContext(UsersCommunityContext);
    const { t, i18n } = useTranslation(); //  Localization
    const [user, setUser] = useState({});
    let open = props.setOpenModal;

    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {

        const loadInfo = async () => {
            let _user = props.settingUserValue;
            setUser(_user);
        }
        loadInfo();
    }, []);

    function closeModal() { open(false); }

    const updateUserFunc = async () => {
        let tosend = {};
        tosend["uuid"] = _get(user, "uuid", null);
        var formData = new FormData();
        formData.append("uuid", _get(user, "uuid", null));
        formData.append("active", 0); // to soft delete user
        tosend["data"] = formData;
        try {
            let response = await updateUser(tosend, true); //save info user
            response = _get(response, "data[0]", {});
            setUpdate(!update);
            props.setDeletedUser(true);
        } catch (error) {
            console.log("error", error);
            setShowError(true);
        }
        //closeModal();
        setShowSuccess(true);
    }

    return (
        <React.Fragment>
            <div className="modalDeleteUser">
                <div className="titleModalDeleteUser">
                    <div className="textTitleModalDeleteUser">{t('uhoo.globalWords.confirmation')}</div>
                    <div className="closeModalDeleteUser" onClick={() => closeModal()} role="button"><Close /></div>
                </div>

                <hr className="lines" />

                <div className="contentModalDeleteUser">
                    {t('uhoo.community.deleteModal.caption') + (user.name === null ? "" : user.name) + " " + (user.middle_name === null ? "" : user.middle_name) + " " + (user.last_name === null ? "" : user.last_name) + "?"}
                </div>
                <div className="actionContentModalDeleteUser">
                    <div role="button" onClick={() => closeModal()}>
                        <SecondaryButton txt={t('uhoo.globalWords.cancel')} dark />
                    </div>
                    <div role="button" onClick={() => updateUserFunc()}>
                        <SecondaryButton txt={t('uhoo.globalWords.delete')} danger />
                    </div>
                </div>
            </div>

            {!!showError && (
                <SystemNotification
                    setShowNotification={setShowError}
                    type="Error"
                    caption={t('basicWords.internalServerError')}
                />
            )}

            {!!showSuccess && (
                <SystemNotification
                    setShowNotification={setShowSuccess}
                    type="Success"
                    caption={t('basicWords.successAction')}
                    setShowModal={open}
                />
            )}

        </React.Fragment>
    );
}

export default DeleteUser;
