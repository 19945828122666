import React, { Component, useState, useEffect } from "react";

import Modal from '../../../../uhoo-components/Modal.jsx'; //../global/Modal.jsx
import FromLibrary from '../../../post/modals/fromLibrary.jsx'; //fromLibrary.jsx

import Icon from '../../../../uhoo-components/iconLibrary.jsx';

//Localization
import { useTranslation } from 'react-i18next';

// Queries

function Attachments(props) {

  const { t, i18n } = useTranslation(); // Localization

  const [fileUploaded, setFileUpload] = useState([]); // array de objetos que almacena las imagenes cargadas
  const [deleteAttachments, setDeleteAttachments] = useState(""); // string id, elimina la imagen adjunta seleccioando

  const [openModalLibrary, setOpenModalLibrary] = useState(false); // Estado Modal Galeria 
  const [attachmentsFromLibrary, setAttachmentsFromLibrary] = useState([]); // array de objetos que almacena las imagenes traidas de la libreria (modal)

  /// ---------- Attachments ---------- ///   fileUploaded, setFileUpload  

  function uploadFileClick() {
    let inputFile = document.getElementById("attachmentsUpload"); // .files vector de archivos cargados
    inputFile.click();
  }

  function uploadFile() {
    let input = document.getElementById("attachmentsUpload"); // .files vector de archivos cargados

    let inputFiles = Array.from(input.files);

    let uploadedItems = [...fileUploaded];

    let index = 0;

    inputFiles.forEach(item => {

      let newItem = new Object();

      newItem.id = index + "-" + item.name;//item.name;
      newItem.name = item.name;
      newItem.url = URL.createObjectURL(item);
      newItem.date = item.lastModified; // lastModified

      uploadedItems.push(newItem);
      index++;
    });

    setFileUpload(uploadedItems);
  }
/*
  if (fileUploaded.length > 0) {
    props.setPreviewImage(fileUploaded[0].url);  // para visualizar primera imagen en post
    //setPreviewImage(fileUploaded[0].url);
  } else (props.setPreviewImage(""));
*/
  function selectFileClick() { // abre modal 
    setOpenModalLibrary(true);
  }

  /// ---------- End attachments ---------- /// 

  /// ---------- Delete attachments ---------- ///

  if (deleteAttachments != "") { // Elimina archivos cargados // estado --> deleteAttachments, setDeleteAttachments

    let files = fileUploaded;
    let filterFiles = [];

    filterFiles = files.filter((file) => file.id !== deleteAttachments);

    setFileUpload(filterFiles);
    setDeleteAttachments("");
  }

  /// ---------- End Delete attachments ---------- ///

  return (
    <React.Fragment>

      <div className="listAttachments">
        {fileUploaded.map(item => (
          <Attachments key={item.id}
            deleteAttachments={deleteAttachments}
            setDeleteAttachments={setDeleteAttachments}

            id={item.id}
            url={item.url}
            name={item.name}
            date={item.date}
          />
        ))}
      </div>

      <div className="attachments">
        <div className="contentAttachments" onClick={uploadFileClick}>
          <Icon.upload />
          {t('uhoo.createPost.form.uploadAttachments')}
          <input hidden
            id="attachmentsUpload"
            type="file"
            accept="image/*, .png, .jpeg, .jpg"
            onChange={uploadFile}
            multiple
          />
        </div>
        <div className="contentAttachments" onClick={selectFileClick}>
          <Icon.library />
          {t('uhoo.createPost.form.libraryAttachments')}
        </div>
      </div>

      {!!openModalLibrary && (
        <Modal>
          <FromLibrary setOpenModalLibrary={setOpenModalLibrary} />
        </Modal>
      )}

    </React.Fragment>
  );
};

export default Attachments;
