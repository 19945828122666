import { combineReducers } from 'redux';

import { userInfo } from './user.reducer'
import userSession from './privilege.reducer'
// import {lpTheme} from './theme.reducer'


const rootReducer = combineReducers({

   userInfo,
   userSession,
   // lpTheme

});

export default rootReducer;
