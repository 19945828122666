import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import "./css/GeneralModal.scss";
import { X } from "react-feather";

export function GeneralModal(props) {
  const { visible, title, body, footer, onClick } = props;
  return (
    <div>
      <Dialog open={visible}>
        <DialogTitle>
          <div className="headerDialog">
            {title}
            <X className="iconCloseDialog" onClick={onClick} />
          </div>
        </DialogTitle>
        <span className="borderBottomTitleDialog" />
        <DialogContent>
          <DialogContentText className="widthAndHeigthDialog">
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ marginRight: "3%" }}>{footer}</DialogActions>
      </Dialog>
    </div>
  );
}
