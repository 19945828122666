//Localization
import { useTranslation } from 'react-i18next';

export function LocalizeSection(sectionName, t) {
  let sectionNameLocal = sectionName;
  switch (sectionName) {
    case "HEADER":
      sectionNameLocal = t('sections.header');
      break;
    case "HOME":
      sectionNameLocal = t('sections.home');
      break;
    case "ABOUT":
      sectionNameLocal = t('sections.aboutUs');
      break;
    case "NEWS":
      sectionNameLocal = t('sections.news');
      break;
    case "EVENTS":
      sectionNameLocal = t('sections.events');
      break;
    case "GALLERY":
      sectionNameLocal = t('sections.gallery');
      break;
    case "TEAM":
      sectionNameLocal = t('sections.team');
      break;
    case "COURSES":
      sectionNameLocal = t('sections.courses');
      break;
    case "CONTACT":
      sectionNameLocal = t('sections.contactUs');
      break;
    case "FORMS":
      sectionNameLocal = t('sections.forms');
      break;
    case "DONATIONS":
      sectionNameLocal = t('sections.donations');
      break;
    case "SOCIALNETWORKS":
      sectionNameLocal = t('sections.socialnetworks');
      break;
    case "LINKTREE":
      sectionNameLocal = t('sections.linkTree');
      break;
    case "STREAMING":
      sectionNameLocal = t('sections.streamings');
      break;
    case "FOOTER":
      sectionNameLocal = t('sections.footer');
      break;
  }
  return sectionNameLocal;
}
// export default withTranslation()(LocalizeSection)
