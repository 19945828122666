import React, { useContext, useState } from "react";

// components
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import SystemNotification from "../../global/SystemNotification";
import { useTranslation } from 'react-i18next';

// request
import { deleteRole } from '../../../actions';
// css
import "./../css/deleteAudience.css";

// contexts
import { UsersCommunityContext } from '../contex';

function DeleteRole( props ) {
  /**
   * Props
   */
  const closeModal = () => props.setModal(false);
  const { t, i18n } = useTranslation(); //  Localization

  /**
   * Hooks
   */
   const [showModalSuccessful, setShowModalSuccessful] = useState(false); // Modal Seleccioanr Audiecias
   const [showModalFail, setShowModalFail] = useState(false); // Modal Seleccioanr Audiecias
  const { update, setUpdate } = useContext(UsersCommunityContext);

  /**
   * Methods
   */
  const DeleteSelectedRole = async () => {
    let result 
    try {
      result = await deleteRole(props.uuid);
      console.log(result)
      if (result.deleted === 0) {
        setShowModalFail(true);
      } else {
        setShowModalSuccessful(true);
        setUpdate(!update);
      }
    } catch(error) {
    setShowModalFail(true);
    setUpdate(!update);
    }
  }

  return (
    <React.Fragment>
    <div className="modalDeleteAudience">
      <div className="titleModalDeleteAudience">
        <div className="textTitleModalDeleteAudience">{t('uhoo.widget.formsDelete.message.areYouSure')}</div>
        <div className="closeModalDeleteAudience" onClick={closeModal} role="button"><Close/></div>
      </div>
      
      <hr className="lines"/>

      <div className="contentModalDeleteAudience" >
        <p className="textDescriptionDeleteAudience">{t('uhoo.widget.formsDelete.message.deleteRol')} "{ props.name }"</p>
      </div>
      <div className="actionContentModalDeleteAudience">
        <div onClick={closeModal} role="button">
          <SecondaryButton txt={t('uhoo.globalWords.cancel')} dark/>
        </div>
        <div onClick={DeleteSelectedRole} role="button">
          <SecondaryButton txt={t('uhoo.globalWords.delete')} danger />
        </div>
      </div>            
    </div>

{!!showModalSuccessful && (
  <SystemNotification
    setShowNotification={setShowModalSuccessful}
    type="Success"
    caption="Se ha guardado exitosamente"
  />
)}
{!!showModalFail && (
  <SystemNotification
    setShowNotification={setShowModalFail}
    type="Error"
    caption="No se pudo guardar. Intente nuevamente."
  />
)}

</React.Fragment>
  )
}

export default DeleteRole;
