import React, { useState, useEffect, useContext } from "react";
import CircularProgress from '@mui/material/CircularProgress';

import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import SystemNotification from "../../global/SystemNotification.jsx";

import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import AudienceSelect from "./audienceSelect.jsx";

import Avatar from '@mui/material/Avatar';

import "./css/addColumn.css";
import "./css/actionPost.css";

//Localization
import { useTranslation } from 'react-i18next';

import { ActivityProvider, ActivityContext } from '../contex';

// Queries
import { getAudience } from "../../../actions/uhoo/Audience.actions";
import { likePost, comentPost } from "../../../actions/uhoo/Channels.actions";

function ActionPost(props) {
    let actionType = props.modalAction.type;

    const { t, i18n } = useTranslation(); //  Localization 
    const [AudienceData, setAudienceData] = useState([]); // array de consulta, contiene las audiencias
    const [channelItems, setChannelItems] = useState([]);

    const { updateAction, setUpdateAction } = useContext(ActivityContext); // actualizar columna

    const [openModal, setOpenModal] = useState(true);
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    const uuidPost = props.modalAction.post.uuid;
    const channelPost = props.modalAction.post.channels;

    ///console.log("uuidPost", uuidPost)

    /// ---------- Queries ---------- ///

    const GetAudienceData = (query) => {
        useEffect(async () => {
            const info = await getAudience(query);

            let filtroActive = info.data.filter(audience => audience.active === 1)
            let filtroMedium = filtroActive.filter(audience => audience.properties.mediums !== undefined)

            setAudienceData(filtroMedium);

        }, []);
        return AudienceData;
    };

    GetAudienceData([{ key: 'page_size', value: '500' }])

    /// ---------- End Queries ---------- ///

    useEffect(() => {
        try {
            setChannelItems(channelPost.map(item => AudienceData.find(select => select.channel.uuid === item.uuid)))
        } catch (error) {
            console.warn(error);
        }
    }, [AudienceData]);

    /// autopublicar si solo hay una audiencia 
    /*
        useEffect(() => {
            if (channelItems.length === 1 && channelItems[0] !== undefined) {
                
                switch (actionType) {
                    case "comment":
                        if (channelItems[0].properties.comments_enabled === 1) {
                            comment(channelItems[0].channel.uuid);
                        } else {
                            console.log("La audiencia no tiene permisos para comentar");
                        }
                        break;
                    case "like":
                        if (channelItems[0].properties.likes_enabled === 1) {
                            like(channelItems[0].channel.uuid);
                        } else {
                            console.log("La audiencia no tiene permisos para like");
                        }
                        break;
                }
            }
        }, [channelItems]);
    */
    function closeModal() { props.setModalAction({ show: false, type: "", post: "" }); }

    useEffect(() => {

        setUpdateAction(!updateAction);

        if (openModal === false) {
            props.setModalAction({ show: false, type: "", post: "" });
        }
    }, [openModal]);

    function comment(channel) {

        let channelOptions = document.querySelectorAll('div.audienceOption > input');

        if (typeof (channel) !== "string") {
            channel = [];
            channelOptions.forEach(select => (select.checked === true)
                ? channel = select.value
                : null
            );
        }

        if (channel.length > 0) {
            try {
                const postComentPost = async (query) => {
                    const info = await comentPost(query);
                    //console.log("respuesta comment", info);

                    if (info.status === 200) {
                        setShowSuccess(true);
                    } else {
                        console.error("Error", info.status, info.statusText);
                        setShowError(true);
                    }
                }

                let finalComment = "";
                var arrayWords = props.modalAction.comment.split(" ");
                var haveUrL = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
                if(arrayWords.length){
                arrayWords.forEach(word => {
                    if(haveUrL.test(word)){
                    const regexHttps = /https?/g;
                    word = `<a href="${!(regexHttps.test(word)) ? 'https://' + word : word}" target="_blank" rel="nofollow">${word}</a>`
                    }
                    finalComment = finalComment + word + " ";
                });
                }
                postComentPost([{ key: "channel", value: channel }, { key: "post", value: uuidPost }, { key: "comment", value: finalComment }]);

            } catch (error) {
                console.error(error);
                setShowError(true);
            }
        } else {
            setShowWarning(true);
        }
    }

    function like(channel) {

        let channelOptions = document.querySelectorAll('div.audienceOption > input');

        if (typeof (channel) !== "string") {
            channel = [];
            channelOptions.forEach(select => (select.checked === true)
                ? channel = select.value
                : null
            );
        }

        if (channel.length > 0) {
            try {

                let valueLike = 1;

                if (channelPost[0].liked_by_user === 1) {
                    valueLike = 0;
                }

                const postLikePost = async (query) => {
                    const info = await likePost(query);
                    //console.log("respuesta like", info);

                    if (info.status === 200) {
                        setShowSuccess(true);
                    } else {
                        console.error("Error", info.status, info.statusText);
                        setShowError(true);
                    }
                }

                postLikePost([{ key: "channel", value: channel }, { key: "post", value: uuidPost }, { key: "value", value: valueLike }]);

            } catch (error) {
                console.error(error);
                setShowError(true);
            }
        } else {
            setShowWarning(true);
        }
    }

    function actionEnabled(audience) {

        function stringAvatar(name) {
            return {
                children: `${name.split('')[0][0].toUpperCase()}`,
            };
        }

        try {
            switch (actionType) {
                case "comment":
                    if (audience.properties.comments_enabled === 1) {
                        return (
                            <React.Fragment key={audience.uuid}>
                                <div className="audienceOption">
                                    <input type="radio" id={audience.uuid} value={audience.channel.uuid} name="audience" />
                                    <label htmlFor={audience.uuid} className="audienceOptionLabel">
                                        <Avatar
                                            {...stringAvatar(audience.name)}
                                            sx={{ width: 24, height: 24, bgcolor: '#FFCB96', }}
                                        />
                                        <div>{audience.name}</div>
                                    </label><br />
                                </div>
                            </React.Fragment>
                        );
                    } else {
                        return (
                            <React.Fragment key={audience.uuid}>
                                <div className="audienceOption" style={{ order: 1 }}>
                                    <input type="radio" id={audience.uuid} value={audience.channel.uuid} name="audience" disabled/>
                                    <label htmlFor={audience.uuid} className="audienceOptionLabel">
                                        <Avatar
                                            {...stringAvatar(audience.name)}
                                            sx={{ width: 24, height: 24, bgcolor: '#c4cace', }}
                                        />
                                        <div> {audience.name} <br />
                                            <div className="txt-NOTenabled">{t('uhoo.activity.post.commentsNOTenabled')}</div>
                                        </div>
                                    </label><br />
                                </div>
                            </React.Fragment>
                        );
                    }
                    break;                    
                case "like":
                    if (audience.properties.likes_enabled === 1) {
                        return (
                            <React.Fragment key={audience.uuid}>
                                <div className="audienceOption">
                                    <input type="radio" id={audience.uuid} value={audience.channel.uuid} name="audience" />
                                    <label htmlFor={audience.uuid} className="audienceOptionLabel">
                                        <Avatar
                                            {...stringAvatar(audience.name)}
                                            sx={{ width: 24, height: 24, bgcolor: '#FFCB96', }}
                                        />
                                        <div>{audience.name}</div>
                                    </label><br />
                                </div>
                            </React.Fragment>
                        );
                    } else {
                        return (
                            <React.Fragment key={audience.uuid}>
                                <div className="audienceOption" style={{ order: 1 }}>
                                    <input type="radio" id={audience.uuid} value={audience.channel.uuid} name="audience" disabled/>
                                    <label htmlFor={audience.uuid} className="audienceOptionLabel">
                                        <Avatar
                                            {...stringAvatar(audience.name)}
                                            sx={{ width: 24, height: 24, bgcolor: '#c4cace', }}
                                        />
                                        <div> {audience.name} <br />
                                            <div className="txt-NOTenabled">{t('uhoo.activity.post.likesNOTenabled')}</div>
                                        </div>
                                    </label><br />
                                </div>
                            </React.Fragment>
                        );
                    }
                    break;
            }
        } catch (error) {
            console.error(error);
            setShowError(true);
        }
    }

    return (
        <React.Fragment>
            <div className="modalAddColumn">
                <div className="titleModalAddColumn">
                    <div className="textTitleModalAddColumn">{t('uhoo.activity.action.title')}</div>
                    <div className="closeModalAddColumn" onClick={closeModal} role="button"><Close /></div>
                </div>

                <hr className="lines" />

                {channelItems.length > 0 && channelItems[0] !== undefined
                    ? (
                        <div className="contentModalAddColumn">
                            <div className="infoContentModalAddColumn">
                                <div className="status labelForm selectAudienceRadio">
                                    {channelItems.map(item => actionEnabled(item))}
                                </div>
                            </div>
                        </div>
                    )
                    : (
                        <div className="contentModalAddColumn">
                            <CircularProgress />
                        </div>
                    )
                }

                <div className="actionContentModalAddColumn">
                    <div onClick={actionType === "like" ? like : comment} >
                        <SecondaryButton txt={t('basicWords.publish')} />
                    </div>
                    <div onClick={closeModal}>
                        <SecondaryButton txt={t('uhoo.globalWords.cancel')} outline />
                    </div>
                </div>
            </div>

            {!!showError && (
                <SystemNotification
                    setShowNotification={setShowError}
                    type="Error"
                    caption={t('basicWords.internalServerError')}
                    setShowModal={setOpenModal}
                />
            )}

            {!!showSuccess && (
                <SystemNotification
                    setShowNotification={setShowSuccess}
                    type="Success"
                    caption={t('basicWords.successAction')}
                    setShowModal={setOpenModal}
                />
            )}

            {!!showWarning && (
                <SystemNotification
                    setShowNotification={setShowWarning}
                    type="Warning"
                    caption={t('basicWords.modal.AudienceCommentWarning')}
                />
            )}

        </React.Fragment>
    );
}

export default ActionPost;
