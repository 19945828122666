import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import { useTranslation } from 'react-i18next';
import { get as _get } from "lodash";
import "../../community/components/css/deleteUser.css";
import { Layers, Users, Codesandbox, FileText, CreditCard } from "react-feather";

function CantCreate(props) {
    const { t, i18n } = useTranslation(); //  Localization
    let open = props.setOpenModal;
    return (
        <React.Fragment>
            <div className="modalContainerCantCreate">
                <div className="containerLeft">
                    <span>{t('basicWords.CantCreate.maximumLength')} {props.text}</span>
                    <div className="titleCantCreate">
                        <h3>{t('basicWords.CantCreate.title')}</h3>
                    </div>
                    <div className="bodyCantCreate">
                        <p>{t('basicWords.CantCreate.content')}</p>
                        <p className="pBold">{t('basicWords.CantCreate.contentBold')}</p>
                        <div className="containerItemsCantCreate">
                            <p> <Layers className="iconCantCreate"/>{t('basicWords.CantCreate.audiences')}</p>
                            <p> <Codesandbox className="iconCantCreate"/>{t('basicWords.CantCreate.buttons')}</p>
                            <p> <Users className="iconCantCreate"/>{t('basicWords.CantCreate.users')}</p>
                            <p> <FileText className="iconCantCreate"/>{t('basicWords.CantCreate.data')}</p>
                            <p> <CreditCard className="iconCantCreate"/>{t('basicWords.CantCreate.buttonPayments')}</p>
                        </div>
                    </div>
                    <a className="buttonCantCreate" target="_blank" href={`https://web.whatsapp.com/send?phone=+573234856744&text=Hola, quiero saber más de AuraPro`} >
                        <button>{t('basicWords.CantCreate.buttonAction')}</button>
                    </a>
                </div>
                <div className="containerRigth">
                    <div className="closeModalCantCreate" onClick={() => open(false)} role="button"><Close /></div>
                    <img src="https://is3-ssl.mzstatic.com/image/thumb/Purple112/v4/06/38/d5/0638d5cd-325a-9a0b-4c1e-2967b472c20b/AppIcon-0-0-1x_U007emarketing-0-0-0-5-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/512x512bb.jpg" alt="" />
                </div>
                
            </div>
        </React.Fragment>
    );
}

export default CantCreate;