import axios from 'axios';

import { protocol, host, port, route, dataSource } from '../../reactUtils/systemVariables/UhooServerInformation';


export async function updateUsers(uuid_audience, data) {
  const userToken = "Bearer " + sessionStorage.access_token;
  const query = "/segment/" + uuid_audience + "/entity";
  let _url = protocol + host + port + route + dataSource + query
  try {
    let response = await axios({
      method: "POST",
      url: _url,
      headers: {
        "Content-Type": 'application/json',
        Authorization: userToken,
      },
      data: data
    });
    if(response) {
      _url = "";
      return response.data;
    }
    return null;
  } catch (error) {
    _url = "";
    console.log("Error: API Brilliant", error);
    return error;
  }
}

export async function updateSegment(uuid_audience, data) {
  const userToken = "Bearer " + sessionStorage.access_token;
  const query = "/segment/" + uuid_audience;
  try {
    let response = await axios({
      method: "PUT",
      url: protocol + host + port + route + dataSource + query,
      headers: {
        "Content-Type": 'application/json',
        Authorization: userToken,
      },
      data: data
    });
    if(response) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log("Error: API Brilliant", error);
    return error;
  }
}

