import React, { useState } from "react";
import Divider from '@mui/material/Divider';

import Comment from './comment.jsx';
import "./css/comment.css";

// Queries
import { likePost } from "../../../actions/uhoo/Post.actions";

//Localization
import { useTranslation } from 'react-i18next';

const ViewComments = (props) => {

  let comments = props.item;

  const { t, i18n } = useTranslation(); //  Localization    

  //console.log("CommentPost", props.item);

  return (
    <div className="viewPostComments">
      <Divider />
      {comments.map((item) => 
      <React.Fragment>
        <Comment key={item.uuid} item={item} />
        <Divider />
      </React.Fragment> 
      )}
    </div>
  );
};

export default ViewComments;






