import React, { useState, useEffect } from "react";
import ItemLibrary from '../../library/components/ItemLibrary.jsx';
import Pagination from '../../global/components/create/paginationLibrary.jsx';
import Icon from '../../../uhoo-components/iconLibrary.jsx';
import { CircularProgress } from "@material-ui/core";

import "./css/fromLibrary.css";

// Queries
import { getLibrary } from "../../../actions/uhoo";

function FromLibrary( Props ) {
    
    const [pageShow, setPageShow] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [itemData, setItemData] = useState([]);
    const [pageData, setPageData] = useState(0);
    const [pageEnd, setPageEnd] = useState(0);
    
    const [selectedImages, setSelectedImages] = useState([]);

    /// ---------- Queries ---------- ///

    useEffect(() => {
        setSelectedImages(Props.fileAttachments);
        const GetMediaData = async (query) => {
          const info = await getLibrary(query);
          setItemData(info.data)
          setPageData(info.page)
          setPageEnd( Math.ceil(info.page.total / info.page.page_size))
        }
        setLoaded(true);
        GetMediaData([{ key: 'page_size', value: '6' }, { key: 'page', value: pageShow }]);
        setLoaded(false);
    }, [pageShow]);
    
    /// ---------- End Queries ---------- ///

    function closeModal() {
        Props.setFileAttachments(selectedImages);
        Props.setOpenModalLibrary(false);
    }

    function select() {
        try {
            Props.setFileAttachments(selectedImages);
        } catch (error) {
        console.error("modal selectedImages",error);
        }
        closeModal();
    }

    return(
        <React.Fragment>
            <div className="modalContainer">
                <div className="modalTitle">
                    Elegir de bibloteca
                </div>
                <div className="modalClose" onClick={closeModal}>
                    <Icon.closeModal />
                </div>
                <div className="modalContent">
                    {itemData.map(item => (
                        <ItemLibrary
                            deleteAttachments    = {"null"}
                            setDeleteAttachments = {"null"}
                            item = {item}
                            selectedImages = {selectedImages}
                            setSelectedImages = {setSelectedImages}
                            id   = {item.uuid}
                            key   = {item.uuid}
                            url  = {item.thumbnail}
                            name = {item.mime_type}
                            date = {item.created_at}
                        />
                    ))}
                </div>
                <div className="alignRight">
                    <div className="modalAction" onClick={()=> select()}>
                        <div className="modalActionText">Seleccionar</div>
                    </div>
                </div>
                {itemData.length > 0
                ? <div className="fromLibraryPagination"> <Pagination pageShow={pageShow} setPageShow={setPageShow} pageEnd={pageEnd}/> </div>
                : ""
                } 
            </div>
                       

        </React.Fragment>
    );
}

export default FromLibrary;