import axios from "axios";

import {
  protocol,
  host,
  port,
  route,
  dataSource,
} from "../../reactUtils/systemVariables/UhooServerInformation";

const query = "/medium/";

export async function getMediums(Params) {
  let sendParams = "";

  if (Params !== undefined || null) {
    if (typeof Params !== "string") {
      Params.forEach((item) => item.key === "page" ? (sendParams += "page/" + item.value) : (sendParams += "") ); // Get Post Page --> Si Value no es definido por defecto va a ser 10
      Params = Params.filter((item) => item.key !== "page");

      Params.forEach((item) => item.key === "size" ? (sendParams += "size/" + item.value) : (sendParams += "") ); // Get Size Post Filter
      Params = Params.filter((item) => item.key !== "size");

      Params.forEach((item) =>
        item.key === "search" ? (sendParams += "search") : (sendParams += "") ); // Get Size Post Filter
      Params = Params.filter((item) => item.key !== "search");

      sendParams += "?";

      Params.forEach(
        (item) => (sendParams += item.key + "=" + item.value + "&")
      ); // Envia otros parametros adicionales Ej: Get Post Page Filter
    } else {
      sendParams = Params;
    } // Asigna el String como parametro de la consulta Ej: main o size
  } else {
    sendParams = "";
  }

  const userToken = "Bearer " + sessionStorage.access_token;

  try {
    let getMediumsData = await axios({
      method: "GET",
      url: protocol + host + port + route + dataSource + query + sendParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    });

    if (!getMediumsData.error) {
      //console.log("Consulta inicial: ", getMediumsData);
      return getMediumsData.data;
    }
  } catch (error) {
    return error;
  }
}
