import axios from 'axios';
import { protocol, host, port, route, dataSource } from '../../reactUtils/systemVariables/UhooServerInformation';
const query = '/config/';

export async function getConfigs() {
  
  let sendParams = "page/0?page_size=5000";
  
  const userToken = "Bearer " + sessionStorage.access_token;

  try {
    let response = await axios({
      method: "GET",
      url: protocol + host + port + route + dataSource + query + sendParams,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken,
      },
    });

      return response;
    //}
  } catch (error) {
    console.log("Error: API Brilliant", error);
    return error;
  }
}