import {useState, useEffect} from "react";

import axios from 'axios';

import { protocol, host, port, route, dataSource } from '../../reactUtils/systemVariables/UhooServerInformation';

const query = '/channel/publish';

export async function publishChannelPost(Params) {
  
    const userToken = "Bearer " + sessionStorage.access_token;
  
    try {
        let publish = await axios({
        method: 'POST',
        url: protocol + host + port + route + dataSource + query,      
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userToken,
          },
          data: Params
        })
      
      //if (!publish.error) {
 
        return publish;
        //console.log("Consulta inicial: ", publish);
  
      //} 
  
    } catch (error) {
      return error;
    };
  }