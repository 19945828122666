import React, { Component } from "react";
import Avatar from '@mui/material/Avatar';

import "./css/checkBox.css";

// Queries

function CheckBox(props) {

    //console.log(props.name);

    let id_component = ""

    if ( props.type !== null & props.type === "medium") {
      id_component = "-medium";
    }

    if ( props.type !== null & props.type === "audience") {
      id_component = "-audience";
    }

    function stringAvatar(name) {
      return {
        children: `${name.split('')[0][0].toUpperCase()}`,
      };
    }

    function handleChange() {
      //console.log("cambio", props.name);
    }

   return(    
      <div className={"checkBox"+id_component+" checkBox"} id={'div'+props.uuid}>
        <div className="select-checkBox">
          <input
            type="checkbox"
            className={"inputSelect"+id_component}
            id={props.uuid}
            onChange={handleChange}
          />
        </div>
        <div className="avatar-checkBox">
          <Avatar
            {...stringAvatar(props.name)}
            sx={{ width: 24, height: 24, bgcolor: '#FFCB96', }}
          />
        </div>
        <div className="text-checkBox">
          {props.name}
        </div>
      </div>
  );
};

export default CheckBox;
