import React, { useState, useEffect } from "react";

import CheckBox from './checkBox.jsx';
import Tag from './tag.jsx';

//Localization
import { useTranslation } from 'react-i18next';
import { ReactComponent as OpenDrop } from '../../../../assets/icons/navigation/openDropdown.svg';
import { ReactComponent as Search } from '../../../../assets/icons/createPost/search.svg';
import { ReactComponent as Add } from '../../../../assets/icons/createPost/add.svg';

import "./css/mediumSelect.css";

// Queries
import { getMediums } from "../../../../actions/uhoo/Mediums.actions.js";

var selectAudienceList = null;
let searcherAudience = [];
var selectedItems = [];

function AudienceSelect(props) {

  const { t, i18n } = useTranslation(); // Localization

  const [selectMedium, setMedium] = useState([]); // array de los uuid de las audiencias selecionadas 
  const [mediumData, setMediumData] = useState([]); // array de consulta, contiene las audiencias activas de la consulta GetAudienceData([{ key: 'active', value: '1' }])
  const [forSearch, setForSearch] = useState(""); // string buscador de audiencias
  const [deleteTag, setDeleteTag] = useState(""); // string id, elimina el tag seleccioando

  const [openDrop, setOpenDrop] = useState(false);

  /// ---------- Queries ---------- ///

  const GetMediumsData = (query) => {
    useEffect(async () => {
      const info = await getMediums(query);

      let filtroActive = info.data.filter(medium => medium.active === 1)
      let filtroProperties = filtroActive.filter(medium => medium.properties.accounting !== undefined)

      setMediumData(filtroProperties);

    }, []);
    return mediumData;
  };

  GetMediumsData([{ key: 'page_size', value: '500' }])

  /// ---------- End Queries ---------- ///

  function handleClick(event) {
    alert('clicked');
    event.preventDefault();
    event.stopPropagation();
  }

  function mediumDrop() { // despliega o cierra el dorp de audiencias 
    document.getElementById("mediumDropdown").classList.toggle("show");
    document.getElementById("actionDrop").classList.toggle("open");
    setOpenDrop(!openDrop);
  }



  function observerSelectMedium() { // verifica que audiencias estan seleccioandas y las guarda en array --> selectMedium
    let selectItems = [];
    let matches = document.querySelectorAll('input.inputSelect-medium');
    matches.forEach(select => (select.checked) ? selectItems.push(select.id) : null); // guarda uuid en array   
    setMedium(selectItems);
  }

  function observerSelectAll(event) {  // verifica el # de audiecias selecioandas para activar el check de selecioanr todas   

    // for Select All option  ---------------------------------------------------------
    let matches = document.querySelectorAll('input.inputSelect-medium');
    let selectAll = document.querySelectorAll('input.inputSelectAll-medium')[0];

    let selectItem = 0; // contador # de audiencias selecioandas 
    matches.forEach(select => (!select.checked) ? selectAll.checked = false : selectItem++);
    if (matches.length === selectItem) { selectAll.checked = true; }

    observerSelectMedium();

    event.stopPropagation();
  }

  function ChangeAudienceSelectAll() { // activa o desactiva todas las audiencias con el checkbox "Selecionar todas"

    let select = document.querySelectorAll('input.inputSelectAll-medium');
    let stateSelect = select[0].checked;

    if (stateSelect) {
      let matches = document.querySelectorAll('input.inputSelect-medium');
      matches.forEach(select => select.checked = true);
    }

    if (!stateSelect) {
      let matches = document.querySelectorAll('input.inputSelect-medium');
      matches.forEach(select => select.checked = false);
    }

    observerSelectMedium();
  }

  selectedItems = selectMedium.map(item => mediumData.find(select => select.uuid === item));  // cruce de los uui de las audiecnias selecioanadas con los valores de la cosulta 

  /// ---------- Delete Tag ---------- ///

  if (deleteTag != "") {

    let matches = document.querySelectorAll('input.inputSelect-medium');
    matches.forEach(select => (select.id === deleteTag) ? select.checked = false : null);

    document.querySelectorAll('input.inputSelectAll-medium')[0].checked = false;

    setDeleteTag("");
    observerSelectMedium();
  }
  /// ---------- End Delete Tag ---------- ///

  /// ---------- Searcher ---------- ///

  if (!forSearch.length > 0) {
    //document.querySelectorAll('div.audienceSelectAll')[0].style.display = "flex";

    let matches = document.querySelectorAll('div.checkBox-medium')
    matches.forEach(select => select.style.display = "flex");

  } else {
    //document.querySelectorAll('div.audienceSelectAll')[0].style.display = "none";       

    let matches = Array.from(document.querySelectorAll('div.checkBox-medium'));
    matches.forEach(select => select.style.display = "none");

    searcherAudience = mediumData.filter(medium => {
      const audienceName = medium.name.toLowerCase();
      const searchText = forSearch.toLowerCase();
      return audienceName.includes(searchText);
    });

    searcherAudience.forEach(found => {
      //console.log(found.uuid, found.name);
      let container = document.querySelector('#dropList-medium');
      //console.log("container", container);
      container.querySelectorAll('#div' + found.uuid)[0].style.display = "flex";
    });
  }

  const onSearchValueChange = (event) => {
    setForSearch(event.target.value);
  };

  /// ---------- End Searcher ---------- ///  

  document.addEventListener('click', function (event) { // cerrar drop al dar click por fuera del elementos

    event.stopPropagation();
  });

  return (
    <React.Fragment>

      {mediumData.length > 0
        ? (
          <div className="select">
            <div className="contentSelect">
              {selectMedium.length === 0
                ? <p>{t('uhoo.createPost.form.mediaSelect')}</p>
                : selectedItems.map(item => (<Tag key={item.uuid} name={item.name} uuid={item.uuid} deleteTag={deleteTag} setDeleteTag={setDeleteTag} />))
              }
            </div>
            <div className="actionDrop" id="actionDrop" onClick={mediumDrop}>
              <OpenDrop />
            </div>
          </div>
        )
        : (
          <div className="select">
            <div className="contentSelect">
              <p>{t('uhoo.createPost.form.mediaNoFound')}</p>
            </div>
            <div className="actionDrop" id="actionDrop">
              {/*<OpenDrop />*/}
            </div>
          </div>
        )
      }

      <div id="mediumDropdown" className="mediumDropdown-content" >

        {mediumData.length > 0
          ? (
            <React.Fragment>
              <div className="audienceSearch">
                <Search className="icoSearch" />
                <input type="search" className="inputSearch" placeholder={t('uhoo.createPost.form.mediaSearch')} onChange={onSearchValueChange} />
              </div>
              <div className="audienceList">
                <div className="audienceSelectAll" id="SelectAllOption">
                  <input className="inputSelectAll-medium" type="checkbox" onChange={ChangeAudienceSelectAll} />
                  <label>
                    {t('uhoo.createPost.form.audienceSelectAll')}
                  </label>
                </div>
                <div className="optionDrop" onChange={observerSelectAll} id="dropList-medium">
                  {mediumData.map(item => (<CheckBox key={item.uuid} name={item.name} uuid={item.uuid} type={"medium"} />))}
                </div>
              </div>
            </React.Fragment>
          )
          : null
        }

        {/*<hr className="lines"/>*/}

        <div className="audienceAdd">
          <Add className="ico" />
          <h5 className="text">{t('uhoo.createPost.form.mediaSelectAdd')}</h5>
        </div> 
      </div>
    </React.Fragment>
  );
};

export default AudienceSelect;