import { get as _get } from "lodash";
import axios from 'axios';
import { protocol, host, port, route, dataSource } from '../../reactUtils/systemVariables/UhooServerInformation';
const query = '/media/';
const query2 = '/media';


export async function getMedia(params) {
  
  let sendParams = params.post;
  
  const userToken = "Bearer " + sessionStorage.access_token;

  let media;

  try {
    media = await axios({
      method: "POST",
      url: protocol + host + port + route + dataSource + query + sendParams,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken,
      },
      data: params.image
    });

    //if (!publish.error) {
      return media.data;
      //media(publish.data, params.file);
    //}
  } catch (error) {
    console.log("Error: API Brilliant");
    return error;
  }
}

export async function addMedia(params) {
  const userToken = "Bearer " + sessionStorage.access_token;
  try {
    let addMedia = await axios({
      method: "POST",
      url: protocol + host + port + route + dataSource + query,
      headers: {
        Authorization: userToken,
      },
      data: params.image
    });

    //if (!PostLike.error) {
      return addMedia;
      //media(PostLike.data, params.file);
    //}
  } catch (error) {
    console.log("Error: API Brilliant");
    return error;
  }
}

export async function addMediaOnlyParams(params, onUploadProgress) {
  let addMedia;

  const userToken = "Bearer " + sessionStorage.access_token;
  console.log("params.getBoundary()", params);
  try {
    addMedia = await axios({
      method: "POST",
      url: protocol + host + port + route + dataSource + query2,
      headers: {
        "Content-Type": `multipart/form-data`,
        // "Content-Type": `multipart/form-data; boundary=${params.getBoundary()}`,
        Authorization: userToken,
      },
      data: params,
      onUploadProgress,
      // timeout: 10000000,
      // timeout: 2000,
    });

    //if (!PostLike.error) {
    return addMedia.data;
    //media(PostLike.data, params.file);
    //}
  } catch (error) {
    console.log("Error: API Brilliant");
    return error;
  }
}

/*

const GetMedia = (params) => {

    //const objSessionInformation = JSON.parse(sessionStorage.userInfo);
    //const userToken = "Bearer " + objSessionInformation.token;
    const userToken = "Bearer " + sessionStorage.access_token;

  //const [info, setInfo] = useState([]);

  //let info;

	useEffect(async() => {
		const response = await axios(
      {
        method: 'GET',
        url: protocol + host + port + route + dataSource + query,      
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userToken,
          },
        body: params,
      }
    );
		//setInfo(response.data);
    return response.data;
	}, []);

//    return response.data;
};

export { GetMedia };

*/



export async function postMediaArbitrary(data) {
  const userToken = "Bearer " + sessionStorage.access_token;
  try {
    let media = await axios({
      method: "POST",
      url: protocol + host + port + route + dataSource + query,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken,
      },
      data: data
    });
    if(media) {
      return media.data;
    }
    return null;
  } catch (error) {
    console.log("Error: API Brilliant");
    return error;
  }
}