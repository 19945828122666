import React, { createContext, useState, useEffect } from "react";
import { get as _get } from "lodash";

// Queries
//import './columnConstructor';
import { getConfigs } from '../../../actions/uhoo/Config.actions';

import Modal from "../../../uhoo-components/Modal.jsx";
import AddColumn from "../components/addColumn.jsx";
import EditColumn from "../components/editColumn.jsx";

// Queries
//import { getAudience } from "../../../actions/uhoo/Audience.actions";


const ActivityContext = createContext();

const ActivityProvider = (props) => {
  const [openModalAddColumn, setOpenModalAddColumn] = useState(false);
  const [columnProperties, setColumnProperties] = useState([]);

  const [modalEditColumn, setModalEditColumn] = useState({ state: false, id: "" });

  const [updateAction, setUpdateAction] = useState(false);

  //const [AudienceData, setAudienceData] = useState([]); // array de consulta, contiene las audiencias activas

  //const [activeAudiences, setActiveAudiences] = useState([]);
  //const [users, setUsers] = useState([]);

  /*
  useEffect(() => {
    localStorage.setItem('columnProperties', JSON.stringify(columnProperties));
    console.log("currentColumns se actualizo");
  }, [columnProperties]);

  function SavePreferences(data) {
    useEffect(() => {
      //localStorage.setItem('columnProperties', JSON.stringify(data));
      console.log("currentColumns se actualizo", data);
    }, []);
  }
*/

  /// ---------- Queries ---------- ///
/*
  const GetAudienceData = (query) => {
    useEffect(async () => {
      const info = await getAudience(query);

      let filtroActive = info.data.filter(audience => audience.active === 1)
      let filtroMedium = filtroActive.filter(audience => audience.properties.mediums !== undefined)

      setAudienceData(filtroMedium);

    }, []);
    return AudienceData;
  };

  GetAudienceData([{ key: 'page_size', value: '500' }])

*/
  /// ---------- End Queries ---------- ///

  useEffect(async () => {
    let own = JSON.parse(sessionStorage.own);
    let properties = _get(own, "properties", {});
    let _columnProperties = _get(properties, "columnProperties", []);
    if (_columnProperties !== null) {
      setColumnProperties(_columnProperties);
    }
  }, []);

  return (
    <ActivityContext.Provider
      value={{
        setColumnProperties: setColumnProperties,
        columnProperties: columnProperties,
        setOpenModalAddColumn,
        modalEditColumn, setModalEditColumn,
        updateAction, setUpdateAction
      }}>
      {props.children}
      {openModalAddColumn ? (
        <Modal>
          <AddColumn setOpenModalAddColumn={setOpenModalAddColumn} />
        </Modal>
      ) : (
        ""
      )}
      , 
      {modalEditColumn.state ? (
        <Modal>
          <EditColumn setModalEditColumn={setModalEditColumn} />
        </Modal>
      ) : (
        ""
      )}
    </ActivityContext.Provider>
  );
};

export { ActivityContext, ActivityProvider };
