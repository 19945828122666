import axios from 'axios';

export async function getInfoReport(params) {
  
  const userToken = "Bearer " + sessionStorage.access_token;
  const userUuid = sessionStorage.userInfo;
  try {
    let response = await axios({
      method: "POST",
      url: params.url,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken,
        userUuid: userUuid
      },
      data: {
        "user": params.quicksightUser,
        "dashboardId": params.dashboardId,
      },
    });

      return response;
    //}
  } catch (error) {
    return error;
  }
}