import React, { useState } from "react";

import { ReactComponent as OptionCard } from "../../../assets/icons/navigation/optionCard.svg";

//Localization
import { useTranslation } from "react-i18next";

//Context
import { UsersCommunityContext } from "../contex";

import "./css/communityCard.css";

import Modal from "../../../uhoo-components/Modal.jsx";
import ConfigureUser from "./configureUser";

import FromLibrary from "../../post/modals/fromLibrary";
import DeleteRole from "../modals/DeleteRole.jsx"

const UserCard = (props) => {
  let id = props.uuid;

  const [openDrop, setOpenDrop] = useState(false);
  const [deleteRole, setDeleteRole] = useState(false);


  const { t, i18n } = useTranslation();

  //let selectItem = document.querySelectorAll('input.checkboxCardUser')[0];

  let name, description, avatar;

  //console.log("avatar", props.avatar);

  function testImage(url) {
    // prevenir error de carga al visualizar en front
    if (url.naturalHeight == 0) {
      return name.charAt(0).toUpperCase();
    } else {
      return props.avatar.thumbnail;
    }
  }

  if (props.name === null) {
    name = "";
  } else {
    name = props.name;
  }
  if (props.description === null) {
    description = "";
  } else {
    description = props.description;
  }
  if (props.avatar === null) {
    avatar = name.charAt(0).toUpperCase();
  } else {
    avatar = null;
  }

  function selectItem() {
    //console.log("selectItem");

    let selectItem = document.querySelectorAll("input.checkboxCardUser");
    let cards = document.querySelectorAll(".cardUser");

    let actionView = false;
    selectItem.forEach((select) =>
      select.checked ? (actionView = true) : null
    );

    if (actionView) {
      //cards.forEach(select => select.classList.add("actionViewTrue"));
      //document.getElementById("massiveActions").classList.add("actionViewShow");
    }

    if (!actionView) {
      //cards.forEach(select => select.classList.remove("actionViewTrue"));
      //document.getElementById("massiveActions").classList.remove("actionViewShow");
    }
  }

  function dropCommunity() {
    // despliega o cierra el dorp de audiencias

    let elements = document.getElementsByClassName("dropCommunity-content");
    let element = "dropCommunity-" + id;

    for (let item of elements) {
      // verifica los drop de otros usuarios y los cierra antes de abrir el actual
      if (item.id == element) {
        document.getElementById(element).classList.toggle("show");
        setOpenDrop(!openDrop);
        element = "";
      } else {
        document.getElementById(item.id).classList.remove("show");
      }
    }
  }

  document.addEventListener("click", function (event) {
    // cerrar drop al dar click por fuera del elementos
    //console.log(event.target);
    if (
      event.target.className !== "dropCommunity" &&
      openDrop === true &&
      event.target.id !== "OptionCard"
    ) {
      if (
        event.target.className.baseVal !== "paginationICO" ||
        event.target.className.baseVal === null
      ) {
        // fix pagination Button
        let element = "dropCommunity-" + id;
        let get = document.getElementById(element);
        if (get !== null) {
          get.classList.remove("show");
        } // pevencion de errores
      }
    }
    event.stopPropagation();
  });

  const openModalDelete = () => {
    dropCommunity()
    setDeleteRole(true)
  }

  return (
    <React.Fragment>
      <div className="cardUser" id="cardUser">
        {/* <div className="cardUserSelect">
          <input
            type="checkbox"
            className="checkboxCardUser"
            onChange={selectItem}
          />
        </div> */}
        <div className="cardUserOption pointerCursor" onClick={dropCommunity}>
          <OptionCard id="OptionCard" />
        </div>

        {/* --- option --- */}
        <UsersCommunityContext.Consumer>
          {({ modalConfig, modalDelete }) => {
            function openModalConfig(event) {
              let id = event.target.id;
              modalConfig("role " + id.substring(7));
              dropCommunity();
              event.preventDefault();
            }

            // function openModalDelete(event) {
            //   let id = event.target.id;
            //   modalDelete(id.substring(7));
            //   dropCommunity();
            //   event.preventDefault();
            // }

            return (
              <div className="dropCommunity-content" id={"dropCommunity-" + id}>
                <div className="dropCommunity">
                  <div
                    className="dropOptionCommunity"
                    role="button"
                    id={"config-" + id}
                    onClick={openModalConfig}
                  >
                    {t("uhoo.globalWords.setting")}
                  </div>
                  {/* <div className="dropOptionCommunity" role="button">
                    Enviar notificación
                  </div> */}
                  <div
                    className="dropOptionCommunity"
                    role="button"
                    id={"delete-" + id}
                    onClick={openModalDelete}
                  >
                    {t("uhoo.globalWords.delete")}
                  </div>
                </div>
              </div>
            );
          }}
        </UsersCommunityContext.Consumer>
        {/* - end option - */}

        <div className="cardUserContent">
          <div className="cardUserImage">{avatar}</div>
          <div className="cardUserInfo">
            <div className="cardUserName">{name}</div>
            <div className="cardUserRole">{description}</div>
          </div>
        </div>
      </div>

      { deleteRole ?
        <Modal>
          <DeleteRole {...props} setModal={setDeleteRole} />
        </Modal>
      : null }


      <UsersCommunityContext.Consumer>
        {({ openModalCommunity, modalConfig }) => {
          if (openModalCommunity) {
            return modalConfig;
          }
        }}
      </UsersCommunityContext.Consumer>
    </React.Fragment>
  );
};

export default UserCard;
