import React, { Component } from "react";
import Avatar from '@mui/material/Avatar';

import { ReactComponent as CloseTag } from '../../../../assets/icons/navigation/closeTag.svg';

import "./css/tag.css";

// Queries

function Tag(props) {

    //console.log(props.name);

    let id_component = ""

    if ( props.type !== null & props.type === "medium") {
      id_component = "-medium";
    }

    if ( props.type !== null & props.type === "audience") {
      id_component = "-audience";
    }

    function handleClick(event) {
      //alert('delete Tag', props.uuid);

      props.setDeleteTag(props.uuid)

      event.preventDefault();
      event.stopPropagation();
    }

    function stringAvatar(name) {
      return {
        children: `${name.split('')[0][0].toUpperCase()}`,
      };
    }

   return(    
      <div className = "tagSelect" id={props.uuid}>
        <div className="avatarTag" >
        <Avatar
          {...stringAvatar(props.name)}
          sx={{ width: 24, height: 24, bgcolor: '#FFCB96', }}
        />
        </div>
        <div className = "textTag">
          {/*<h5>Xxxxx</h5>*/}
          <h5>{props.name}</h5>
        </div>
        <div className="close">
          <CloseTag onClick={handleClick} />
        </div>
      </div>
  );
};

export default Tag;