import axios from 'axios';

import { protocol, host, port, route, dataSource } from '../../reactUtils/systemVariables/UhooServerInformation';



export async function getUser(Params) {

  const query = "/user/";
  //  const query = "/user";
  
  let sendParams = "";

  if (Params !== undefined || Params !== null){

    if (typeof Params !== "string")
    {
      Params.forEach(item => (item.key === 'page') ? sendParams += "page/" + item.value : sendParams += ""); // Get Post Page --> Si Value no es definido por defecto va a ser 10
      Params = Params.filter((item) => item.key !== 'page');

      Params.forEach(item => (item.key === 'size') ? sendParams += "size/" + item.value : sendParams += ""); // Get Size Post Filter
      Params = Params.filter((item) => item.key !== 'size');

      sendParams += "?";

      Params.forEach(item => sendParams += item.key + "=" + item.value + "&");  // Envia otros parametros adicionales Ej: Get Post Page Filter

    } else {  sendParams = Params }   // Asigna el String como parametro de la consulta Ej: main o size
    
  } else { sendParams = ""; }
  
    //const objSessionInformation = JSON.parse(sessionStorage.userInfo);
    //const userToken = "Bearer " + objSessionInformation.token;
    const userToken = "Bearer " + sessionStorage.access_token;

    console.log({sendParams})
  
    try {
        let getUserData = await axios({
        method: 'GET',
        url: protocol + host + port + route + dataSource + query + sendParams,      
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userToken,
          }
        })
      
      if (!getUserData.error) {
 
        return getUserData.data;
        //console.log("Consulta inicial: ", getUserData);
  
      } 
  
    } catch (error) {
      return error;
    };
} 

export async function getUserSearch(tc, medium, type) {
  const query = "/user/search?";
  const userToken = "Bearer " + sessionStorage.access_token;
  try {
      let getUserData = await axios({
      method: 'GET',
      url: protocol + host + port + route + dataSource + query + 'properties.tc.uhoo.' + tc +'.medium;' + type +'=' +  medium,      
      headers: {
          'Content-Type': 'application/json',
          'Authorization': userToken,
        }
      })
    
    if (!getUserData.error) {
      return getUserData.data;
    } 

  } catch (error) {
    return error;
  };
} 


export async function getOwn() {
  const query = "/user/own";
  const userToken = "Bearer " + sessionStorage.access_token;
  try {
    let own = await axios({
      method: 'GET',
      url: protocol + host + port + route + dataSource + query,      
      headers: {
        'Content-Type': 'application/json',
        'Authorization': userToken,
      }
    })
    if(own) {
      return own.data
    }
    return null;
  } catch (error) {
    return error;
  };
}

export async function inviteAndCreateUser(payload){
  const query = "/user/onboarding";
  const userToken = "Bearer " + sessionStorage.access_token;
  try {
    let updateUser = await axios({
      method: 'POST',
      url: protocol + host + port + route + dataSource + query,      
      headers: {'Authorization': userToken, "Content-Type": "multipart/form-data"},
      data:  payload
    })
    if(updateUser) {
      return updateUser.data
    }
    return null;
  } catch (error) {
    console.log("error", error);
    return error;
  };
}


export async function updateUser(payload, isFormData, isProperties, uuid) {
  if (!payload.uuid && !isProperties) return;
  const query = isProperties ? "/user/" + uuid : "/user/" +  payload.uuid;
  const userToken = "Bearer " + sessionStorage.access_token;
  let _headers;
  if(isFormData){
    _headers = {'Authorization': userToken}
  }else{
    _headers = {'Authorization': userToken, 'Content-Type': 'application/json'}
  }
  try {
    let updateUser = await axios({
      method: 'PUT',
      url: protocol + host + port + route + dataSource + query,      
      headers: _headers,
      data: isFormData ? payload.data : payload
    })
    if(updateUser) {
      return updateUser.data
    }
    return null;
  } catch (error) {
    console.log("error", error);
    return error;
  };
}

export async function createUser(payload) {
  const query = "/user/create";
  const userToken = "Bearer " + sessionStorage.access_token;
  try {
    let createUser = await axios({
      method: 'POST',
      url: protocol + host + port + route + dataSource + query,      
      headers: {'Authorization': userToken},
      data: payload
    })
    return createUser
  } catch (error) {
    console.log("error", error);
    return error;
  };
}