import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AudienceSelect from "../global/components/create/audienceSelect.jsx";
import { get as _get } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';
//Localization
import { useTranslation } from "react-i18next";

// Queries
import { publishNotification, getConfigs, publishNotificationPast } from "../../actions/uhoo";
import { getChannels } from "../../actions/uhoo/Channels.actions.js";

import SystemNotification from "../global/SystemNotification.jsx";

function NotificationForm(props) {
  let history = useHistory(); //  from 'react-router-dom' -> la uso para avanzar o retroceder en el historial de navegacion
  const { t, i18n } = useTranslation(); // Localization
  const [showNotificationSuccessful, setShowNotificationSuccessful] = useState(false); // Modal Seleccioanr Audiecias
  const [showNotificationFail, setShowNotificationFail] = useState(false); // Modal Seleccioanr Audiecias
  const [showNotificationCheckAudiences, setShowNotificationCheckAudiences] = useState(false);
  const [audiencePublish, setAudiencePublish] = useState([]);
  const [channelPublish, setChannelPublish] = useState([]);
  const [audienceSelectAll, setAudienceSelectAll] = useState(false); // para confirmar si se seleccioanron todos  ---> VARIABLE PARA LAURA
  const [channelDataMain, setChannelDataMain] = useState([]);
  const [route, setRoute] = useState("Feed"); // Feed or webview
  const [ isLoadingPost, setIsLoadingPost ] = useState(false);
  const [readyAudiences, setReadyAudiences] = useState(false);

  useEffect(() => {
    const GetChannelData = async (query) => {
      const info = await getChannels(query);
      setChannelDataMain(info.data[0]);
      return info.data;
    };
    GetChannelData("main");
  }, []);

  function goToHandleChange(e) {
    let webviewButton = document.getElementById("Webview");
    let feedButton = document.getElementById("Feed");

    if (e.target.id === "Feed") {
      webviewButton.checked = false;
      feedButton.checked = true;
    }
    if (e.target.id === "Webview") {
      feedButton.checked = false;
      webviewButton.checked = true;
    }

    setRoute(e.target.id);
  }

  function timeout(ms) { return new Promise(resolve => setTimeout(resolve, ms)); }

  async function publishNotificationFunction() {
    setIsLoadingPost(true);
    await timeout(1000);
    if (channelPublish.length === 0) { // selectedAudiencesVerify
      setShowNotificationCheckAudiences(true);
      setIsLoadingPost(false);
      return;
    }
    var payload = {
      communityId: channelDataMain.community,
      notificationInfo: {
        type: audienceSelectAll ? "community" : "audience", // community si todas, audience si solo algunas
        endpoints: audienceSelectAll ? channelDataMain.community : channelPublish, // uuid Comunidad si son todas las audiencias o uuids de las audiencias seleccionadas (array)
        omitUser: [], // El usuario que publica
      },
      notificationVariables: {
        sender_name: "",
        receiver_name: "",
        community_name: "",
        audiences: "",
        sent_date: "",
        date_now: "",
        action_type: null,
        post_title: "",
        post_content: "",
        notification_content: props.previewInfo,
        notification_title: props.previewTitle, 
        router: {
          route: route, //Feed si muro, Webview complementos
          params: {
            appUuid: null,
          },
        },
        links: null,
      },
    };
    sendNotification(payload);
    var raw = {
      title: props.previewTitle,
      body: props.previewInfo,
      resourceType: "topic",
      topicArnList: channelPublish,
      router: {
        route: route,
        params: {}, //Default
      },
      id: uuidv4(),
      sound: true
    };
    sendNotificationPast(raw);
    setShowNotificationSuccessful(true);
    setTimeout(()=>{setShowNotificationSuccessful(false);}, 2000);
    setTimeout(()=>{history.goBack();}, 1000);
    setIsLoadingPost(false);
  }

  const sendNotification = async (payload) => {
    try {
      let notify = await publishNotification(payload);
      console.log("notify", notify);
    } catch (error) {
      setIsLoadingPost(false);
      console.log("error", error);
      console.log("error catch");
      setShowNotificationFail(true);
    }
  };

  const sendNotificationPast = async (raw) => {
    let _configs = await getConfigs();
    _configs = _get(_configs, "data", "");
    let notificationEndpoint = _configs.find((element) => {
      return element.name === "notifications.endpoint";
    });
    notificationEndpoint = _get(notificationEndpoint, "value", "")

    try {
      await publishNotificationPast(raw, notificationEndpoint);
    } catch (error) {
      setIsLoadingPost(false);
      setShowNotificationFail(true);
    }
  };

  let _user = JSON.parse(sessionStorage.getItem("own"));

  return (
    <React.Fragment>
      <div className="contentsPostform">
        {!readyAudiences?
          <div className="col-xs-12 col-md-12 circularProgressCenter" >
            <CircularProgress />
          </div>
        :
          null
        }
        <div className="audience">
          <div className="PostformTitles">
            {t("uhoo.notification.notifyTo")}
          </div>
          <AudienceSelect user={_user} setAudiencePublish={setAudiencePublish} setChannelPublish={setChannelPublish} setReadyAudiences={setReadyAudiences}/>
        </div>
        <div className="title">
          <div className="PostformTitles">{t("uhoo.globalWords.title")}</div>
          <input
            type="text"
            placeholder={t("uhoo.notification.titleInput")}
            className="inputForms"
            onChange={(e) => props.setPreviewTitle(e.currentTarget.value)}
          />
        </div>
        <div className="message">
          <div className="PostformTitles">
            {t("uhoo.createPost.form.messageTitle")}
          </div>
          <div
            className="editText"
            id="textarea"
            contentEditable="true"
            onInput={(e) => props.setPreviewInfo(e.currentTarget.textContent)}
          ></div>
        </div>

        <div className="title">
          <div className="PostformTitles">{t("uhoo.notification.goTo")}:</div>
          <div className="checkBox">
            <div className="select-checkBox">
              <input
                type="radio"
                className="inputSelect"
                id={"Feed"}
                onClick={goToHandleChange}
                defaultChecked
              />
            </div>

            <div className="text-checkBox">{t("uhoo.notification.wall")}</div>
          </div>

          <div className="checkBox">
            <div className="select-checkBox">
              <input
                type="radio"
                className="inputSelect"
                id={"Webview"}
                onClick={goToHandleChange}
              />
            </div>

            <div className="text-checkBox">
              {t("uhoo.notification.features")}
            </div>
          </div>
        </div>
        { isLoadingPost ?
            <div className="createPostActions">
              <div className="toPostDisabled" ><CircularProgress size={20} className='buttonLoading'/></div>
            </div>
            :
            <div className="createPostActions">
              <div className="toPost" role="button" onClick={() => publishNotificationFunction()}>{t("uhoo.globalWords.send")}</div>
            </div>
        }
        
      </div>
      {!!showNotificationCheckAudiences && (
        <SystemNotification
          setShowNotification={setShowNotificationCheckAudiences}
          type="Warning"
          caption={t('basicWords.mustSelectAudiences')} 
        />
      )}
      {!!showNotificationSuccessful && (
        <SystemNotification
          setShowNotification={setShowNotificationSuccessful}
          type="Success"
          caption="Se ha enviado la notificación"
        />
      )}
      {!!showNotificationFail && (
        <SystemNotification
          setShowNotification={setShowNotificationFail}
          type="Error"
          caption={t('basicWords.internalServerError')}
        />
      )}
    </React.Fragment>
  );
}

export default NotificationForm;
