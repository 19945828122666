import React, { useContext } from "react";

// components
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import { useTranslation } from 'react-i18next';

// request
import { editAudienceById } from './../../../actions';

// css
import "./../css/deleteAudience.css";

// contexts
import { UsersCommunityContext } from './../contex';

function RemoveAudience( props ) {
  /**
   * Props
   */
  const closeModal = () => props.setModal(false);

  /**
   * Hooks
   */
  const { update, setUpdate } = useContext(UsersCommunityContext);
  const { t, i18n } = useTranslation(); //  Localization

  /**
   * Methods
   */
  const deleteAudience = async () => {
    let result 
    try {
      result = await editAudienceById(props.uuid, { active: false });
    } catch(error) {}
    setUpdate(!update);
  }

  return (
    <div className="modalDeleteAudience">
      <div className="titleModalDeleteAudience">
        <div className="textTitleModalDeleteAudience">{t('uhoo.widget.formsDelete.message.areYouSure')}</div>
        <div className="closeModalDeleteAudience" onClick={closeModal} role="button"><Close/></div>
      </div>
      
      <hr className="lines"/>

      <div className="contentModalDeleteAudience" >
        <p className="textDescriptionDeleteAudience">{t('uhoo.widget.formsDelete.message.deleteAudience')} "{ props.name }"</p>
      </div>
      <div className="actionContentModalDeleteAudience">
        <div onClick={closeModal} role="button">
          <SecondaryButton txt={t('uhoo.globalWords.cancel')} dark/>
        </div>
        <div onClick={deleteAudience} role="button">
          <SecondaryButton txt={t('uhoo.globalWords.delete')} danger />
        </div>
      </div>            
    </div>
  )
}

export default RemoveAudience;
