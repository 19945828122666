import React, { Component } from "react";

import { ReactComponent as DeleteAttachment } from '../../../../assets/icons/navigation/deleteAttachment.svg';

import "./css/pictureAttachments.css";

// Queries

function Attachments(props) {
    
    function handleClick(event) {
      props.setDeleteAttachments(props.id)      
      event.preventDefault();
      event.stopPropagation();
    }
   let isImg = props.name.includes('png') || props.name.includes('jpeg') || props.name.includes('jpg');
    return(
        <div className = "pictureAttachments" id={props.id}>

            <div className="fileAttachments">
                {/*imagen*/}
                {
                    props.name.search('.mp4') != -1 ?
                    <video src={props.url} alt={props.name}></video>
                    :
                    <>
                        {
                            isImg ?
                            <img src={props.url} alt={props.name}></img>
                            :
                            <div class="module line-clamp">
                                <p>{props.name}</p>
                            </div>
                        }
                    </>
                }
            </div> 
            <div className="closeAttachments" onClick={handleClick}>
                {/*cerrar*/}
                <DeleteAttachment/>
            </div>
            <div className="nameAttachments">
                {/*Nombre*/} {props.name}
            </div>
            <div className="dateAttachments">
                {/*fecha*/} {props.date}
            </div>

        </div>
    );
};

export default Attachments;