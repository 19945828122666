import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import "./css/GeneralInput.scss";
// import AccountCircle from "@mui/icons-material/AccountCircle";
import { Youtube } from "react-feather";

export function GeneralInput(props) {
  let iconYoutube = props.iconYoutube ? props.iconYoutube : false;
  return iconYoutube ? (
    <TextField
      id={props.id}
      className={props.className ? props.className : "basic-single"}
      label={props.placeholder}
      onChange={props.onChange}
      value={props.value + ""}
      disabled={props.disabled}
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Youtube style={{color: "red"}}/>
          </InputAdornment>
        ),
      }}
    />
  ) : (
    <TextField
      id={props.id}
      className={props.className ? props.className : "basic-single"}
      label={props.placeholder}
      onChange={props.onChange}
      value={props.value + ""}
      disabled={props.disabled}
      variant="outlined"
      inputProps={{
        maxLength: props.maxLength
      }}
    />
  );
}
