import React from "react";
import ReactDOM from "react-dom";

import { ReactComponent as Close } from '../../assets/icons/systemNotification/close.svg';
import { ReactComponent as Success } from '../../assets/icons/systemNotification/success.svg';
import { ReactComponent as Warning } from '../../assets/icons/systemNotification/warning.svg';
import { ReactComponent as Error } from '../../assets/icons/systemNotification/error.svg';

import { useHistory } from "react-router-dom";

import "./css/modal.css";
import "./css/notification.css";

//Localization
import { useTranslation } from 'react-i18next';
import { isNull } from "lodash";

var notificationType, notificationIco, notificationTitle, notificationCaption;

function SystemNotification(props) {

    const { t, i18n } = useTranslation();

    let history = useHistory();

    notificationTitle = props.title;
    notificationCaption = props.caption;

    function closeNotification() {

        if (props.functionBack !== null && props.functionBack !== undefined && props.functionBack === true) {
            history.goBack();
        }

        if (props.setShowModal !== undefined) {
            props.setShowModal(false);
        }
        
        props.setShowNotification(false);
    }
        setTimeout(function(){
            closeNotification() ;
        }, 3000); //5 segundos

    switch (props.type) {
        case "Success":
            if (props.title === undefined) { notificationTitle = t('uhoo.systemNotification.success'); }
            notificationType = "systemSuccess systemNotification";
            notificationIco = <Success />;
            break;
        case "Warning":
            if (props.title === undefined) { notificationTitle = t('uhoo.systemNotification.warning'); }
            notificationType = "systemWarning systemNotification";
            notificationIco = <Warning />;
            break;
        case "Error":
            if (props.title === undefined) { notificationTitle = t('uhoo.systemNotification.error'); }
            notificationType = "systemError systemNotification";
            notificationIco = <Error />;
            break;

        default:
            console.log('undefined notification type');
    }

    return ReactDOM.createPortal(
        <div className="ModalForeground" id="SystemNotification">
            <div className={notificationType}>
                <div className="icoNotification">{notificationIco}</div>
                <div className="notificationTitle">{notificationTitle}</div>
                <div className="notificationCaption">{notificationCaption}</div>
                <div className="closeNotification pointerCursor" onClick={closeNotification}><Close /></div>
            </div>
        </div>,
        document.getElementById('modal')
    );
}

export default SystemNotification;