
// brand
import { ReactComponent as logoUhoo } from '../assets/brand/logo.svg';
import { ReactComponent as icoUhoo } from '../assets/brand/logoIco.svg';
import { ReactComponent as icoAura } from '../assets/brand/logoAura.svg';

// navigation
import { ReactComponent as fileUpload } from '../assets/icons/navigation/upload.svg';
import { ReactComponent as backArrow } from '../assets/icons/navigation/backArrow.svg';
import { ReactComponent as forwardArrow } from '../assets/icons/navigation/forwardArrow.svg';
import { ReactComponent as closeModal } from '../assets/icons/navigation/closeModal.svg';
import { ReactComponent as xClose } from '../assets/icons/home/close.svg';
import { ReactComponent as link } from '../assets/icons/manage/activity.svg';
import { ReactComponent as filter } from '../assets/icons/home/filter.svg';

//activity
import { ReactComponent as seeActivity1 } from '../assets/icons/activity/seeActivity1.svg';
import { ReactComponent as seeActivity2 } from '../assets/icons/activity/seeActivity2.svg';
import { ReactComponent as activityAdd } from '../assets/icons/activity/add.svg';
import { ReactComponent as options } from '../assets/icons/activity/options.svg';
import { ReactComponent as like } from '../assets/icons/activity/like.svg';
import { ReactComponent as comment } from '../assets/icons/activity/comment.svg';
import { ReactComponent as edit } from '../assets/icons/activity/edit.svg';
import { ReactComponent as trash } from '../assets/icons/activity/delete.svg';
import { ReactComponent as pincel } from '../assets/icons/activity/pincel.svg';

//manage
import { ReactComponent as manageWidgets } from '../assets/icons/manage/widgets.svg';
import { ReactComponent as manageEmail } from '../assets/icons/manage/email.svg';
import { ReactComponent as manageNewsletter } from '../assets/icons/manage/newsletter.svg';
import { ReactComponent as manageWeb } from '../assets/icons/manage/web.svg';
import { ReactComponent as manageLibrary } from '../assets/icons/manage/library.svg';

//profile
import { ReactComponent as profileEdit } from '../assets/icons/profile/profileEdit.svg';
import { ReactComponent as profileLogout } from '../assets/icons/profile/profileLogout.svg';
import { ReactComponent as profileAdd } from '../assets/icons/profile/profileAdd.svg';

// menu
import { ReactComponent as Home } from '../assets/icons/mainMenu/home.svg';
import { ReactComponent as Community} from '../assets/icons/mainMenu/community.svg'
import { ReactComponent as Manage} from '../assets/icons/mainMenu/manage.svg'
import { ReactComponent as Data} from '../assets/icons/mainMenu/data.svg'
import { ReactComponent as Plus} from '../assets/icons/mainMenu/plus.svg'
import { ReactComponent as Help} from '../assets/icons/mainMenu/help.svg'
import { ReactComponent as UserList} from '../assets/icons/home/listUsers.svg'
import { ReactComponent as Cards} from '../assets/icons/home/cards.svg'

// home
import { ReactComponent as Publication } from '../assets/icons/home/publication.svg';
import { ReactComponent as Email } from '../assets/icons/home/email.svg';
import { ReactComponent as Newsletter } from '../assets/icons/home/newsletter.svg';
import { ReactComponent as Widgets } from '../assets/icons/home/widgets.svg';
import { ReactComponent as Notification } from '../assets/icons/home/notification.svg';
import { ReactComponent as Wallet } from '../assets/icons/home/wallet.svg';
import { ReactComponent as WalletGray } from '../assets/icons/home/walletGray.svg';


import { ReactComponent as homeCommunity } from '../assets/icons/home/community.svg';
import { ReactComponent as homeManage } from '../assets/icons/home/manage.svg';
import { ReactComponent as homeData } from '../assets/icons/home/data.svg';

// createPost
import { ReactComponent as attach } from '../assets/icons/createPost/attach.svg';
import { ReactComponent as notify } from '../assets/icons/createPost/notify.svg';
import { ReactComponent as linkChain } from '../assets/icons/createPost/link-chain.svg';
import { ReactComponent as openDrop } from '../assets/icons/navigation/openDropdown.svg';
import { ReactComponent as library } from '../assets/icons/createPost/library.svg';
import { ReactComponent as upload } from '../assets/icons/createPost/upload.svg';

//medium
import { ReactComponent as bell } from '../assets/icons/medium/bell.svg';
import { ReactComponent as gmail } from '../assets/icons/medium/gmail.svg';
import { ReactComponent as linkedin } from '../assets/icons/medium/linkedin.svg';
import { ReactComponent as whatsapp } from '../assets/icons/medium/whatsapp.svg';

//mail
import { ReactComponent as eye } from '../assets/icons/mail/eye.svg';
import { ReactComponent as trashIcon } from '../assets/icons/mail/trash.svg';

// estados iconos menu
const active = "#183E55"
const inactive = "#748996"
const disabled = "#c5ced3"
const white = '#ffffff'
export default {
    logoUhoo, icoUhoo, icoAura, filter,

    // navigation
    backArrow, forwardArrow, closeModal, link,
    fileUpload, library, upload,

    //profile
    profileLogout, profileEdit, profileAdd,

    //manage
    manageWidgets, manageEmail, manageNewsletter, manageWeb, manageLibrary,

    //activity
    seeActivity1, seeActivity2, activityAdd, options, comment, like, edit, trash, pincel,

    // createPost
    openDrop, 
    attach, notify, linkChain,

    //medium
    bell, gmail, linkedin, whatsapp,

    // menu
    active, inactive, disabled, white,
    Home, Community, Manage, Data, Plus, Help, UserList, Cards,
    // home
    Publication, Email, Newsletter, Widgets, Notification, Wallet, WalletGray,
    homeCommunity, homeManage, homeData, xClose,

    //mail
    eye, trashIcon
};