
import axios from 'axios';
import moment from 'moment';

import { host, port, route, datasource } from '../../reactUtils/systemVariables/CmsServerInformation';

const resource = "/lpsectioncomponent/donations";
const resourceResponse = "/payment/forms";

export async function getDonationsFormsComponents(organizationId) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resource, {
            params: {
                organization_id: organizationId,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
            }
        })
        return response;
    } catch (error) {
        return error;
    }
}

export async function getPaymentsWithFormsRuleByLpComponent(lpcomponent_id, paystatus_id, page, size, attribute_name, order) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resourceResponse, {
            params: {
                lpcomponent_id,
                paystatus_id,
                page,
                size,
                attribute_name,
                order
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
            }
        })
        return response;
    } catch (error) {
        return error;
    }
}

export async function getFormsQuestionsByLpComponent(lpsectioncomponent_id) {
    try {
        let response = await axios.get(
            host + port + route + datasource + '/lpsectioncomponentnode', {
            params: {
                lpcomponent_id: lpsectioncomponent_id
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
            }
        })
        return response;
    } catch (error) {
        return error;
    }
}