import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../css/react-tabs.scss';

import "./css/newsletter.css";
import { ReactComponent as BackArrow } from '../../assets/icons/navigation/backArrow.svg';

import NewsletterAction from "./components/newsletterAction";
import CardRecord from "./components/cardRecord";

import { getConfigs } from '../../actions/uhoo/Config.actions'; // Singularity 

//Localization
import { useTranslation } from 'react-i18next';

const Newsletter = () => {

  const { t, i18n } = useTranslation(); //  Localization  
  let history = useHistory(); //  from 'react-router-dom' -> la uso para avanzar o retroceder en el historial de navegacion 

  /// ----- Singularity ----- ///

  const [newsletter, setNewsletter] = useState(false);

  const Singularity = () => {
    useEffect(async () => {
      const _configs = await getConfigs();
      let config = _configs.data.data;

      setNewsletter(
        config.find((element) => {
          return element.name === "newsletter.enabled";
        }).value
      );

    }, []);
  };

  Singularity();

  if (newsletter === 0) {
    history.replace("/")
  }

  /// --- end Singularity --- ///

  function tabAction() {

  }

  return (
    <React.Fragment>
      <section className="Title">
        <BackArrow className="ico pointerCursor" onClick={() => history.goBack()} />
        <h3>{t('uhoo.newsletter.title')}</h3>
      </section>

      <section className="newsletter">
        <Tabs>
          <TabList>
            <Tab>{t('uhoo.newsletter.tab1')}</Tab>
            <Tab>{t('uhoo.newsletter.tab2')}</Tab>
            <Tab>{t('uhoo.newsletter.tab3')}</Tab>
          </TabList>

          <TabPanel>
            <div className="newsletterContent" >
              <NewsletterAction />
              <div className="newsletterContentTab" onClick={tabAction}>

                <div className="newsletterContentSection">Inicio
                  <div className="newsletterContentPanel">
                    texto en el panel
                  </div>
                </div>

                <div className="newsletterContentSection">Noticias
                  <div className="newsletterContentPanel">
                    texto en el panel
                  </div>
                </div>

                <div className="newsletterContentSection">Galeria
                  <div className="newsletterContentPanel">
                    texto en el panel
                  </div>
                </div>

              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="newsletterContent">
              <NewsletterAction />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="newsletterContent">
              <div className="newsletterContentTabRecord">
                <CardRecord /> <CardRecord /> <CardRecord /> <CardRecord />
                <CardRecord /> <CardRecord /> <CardRecord /> <CardRecord />
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </section>


    </React.Fragment>
  );
};

export default Newsletter;





