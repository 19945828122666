import React, { useState } from 'react';
import { get as _get } from "lodash";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import Modal from '../../../uhoo-components/Modal.jsx';
import AddLink from '../modals/addLink.jsx';
import './css/react-draft-wysiwyg.css';
import "./css/richText.css";
import Icon from '../../../uhoo-components/iconLibrary.jsx';

//import icon from '../../../uhoo-components/iconLibrary.jsx';

//Localization
import { useTranslation } from 'react-i18next';

function RichText(props) {

    const { t, i18n } = useTranslation(); // Localization

    const [editorState, setEditorState] = useState({ editorState: EditorState.createEmpty() });
    const [openModalLink, setOpenModalLink] = useState(false);

    let content = props.previewInfo;

  
    const handleChange = (EditorState, url) => {
        if(url){
            props.setPreviewInfo(url);
        }else{
            props.setPreviewInfo(convertToHTML(EditorState.getCurrentContent()));
        }
    }

    const AddLinkIntoText = (link) => {
        let finalLink =` <a href="${link}" target="_blank" rel="nofollow">${link}</a> `
        let finalContent = content + ` ${finalLink} `;
        props.setPreviewInfo(finalContent);
      }

    const onContentStateChange = (e) => {
        let object = _get(e ,"entityMap", null);
        let length = 0;
        if(object){
            if(object[0]){
                let finalContent = content;
                for (const index in object) {
                    length = index;
                    let link = e.entityMap[length].data.url;
                    let text = e.blocks[0].text;
                    let offset = e.blocks[0].entityRanges[length].offset;
                    let offSetPlusLength = e.blocks[0].entityRanges[length].length;
                    let textToReplace =  text.substring(offset, offset + offSetPlusLength); 
                    finalContent = finalContent.replace(textToReplace, ` <a href="${link}" target="_blank" rel="nofollow">${textToReplace}</a> `);
                    // finalContent = finalContent.replace(' <p>', '');
                    // finalContent = finalContent.replace(' </p>', '');
                    props.setPreviewInfo(finalContent);
                }
                // handleChange(null, finalContent)
                
            }
        }
    }

      return (
        <React.Fragment>
            <Editor

                onEditorStateChange={editorState => {
                    setEditorState(editorState);
                    handleChange(editorState);
                    onContentStateChange();
                }}
                initialContentState={content}
                placeholder={t('uhoo.createPost.form.writeHere')}
                onContentStateChange={(e) => onContentStateChange(e)}
                editorClassName="editText"
                toolbarClassName="actionText"
                toolbar={{
                    options: ['inline', 'emoji', 'link'],
                    inline: {
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline'],
                        bold: { className: "fontStyle" },
                        italic: { className: "fontStyle" },
                        underline: { className: "fontStyle" },
                        strikethrough: { className: "fontStyle" },
                        link: { className: "fontStyle" },
                    },
                    emoji: {
                        //icon: "😀",
                        className: "fontStyle",
                    },
                }}
            />

            {/* <div className="fontStyleIconUrl" onClick={setOpenModalLink}>
              <Icon.linkChain />
            </div> */}

        {!!openModalLink && (
            <Modal>
                <AddLink setOpenModalLink={setOpenModalLink} onAddLink={(e) => AddLinkIntoText(e)}/>
            </Modal>
        )}
        </React.Fragment>
    );
};

export default RichText;
/*
<div className="editText" id="textarea" contentEditable="true"
    onInput={e => props.setPreviewInfo(e.currentTarget.textContent)} >
        Escribe acá 
</div>

<div className="actionText">
    <div className="fontStyle" onClick={textBold}><b>B</b></div>
    <div className="fontStyle"><i>I</i></div>
    <div className="fontStyle">😀</div>
</div>
*/