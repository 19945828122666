import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { get as _get, omit as _omit } from 'lodash';
import { cloneDeep as _cloneDeep } from "lodash";
import { Modal, Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { auraBasic, auraPro } from '../../reactUtils/generalUtils/auraPlans'
import { Link } from "react-feather";
import ModalUhoo from '../../uhoo-components/ModalUhoo.jsx';
import CantCreate from '../global/modals/cantCreate.jsx'

// icons
import { ReactComponent as BackArrow } from '../../assets/icons/navigation/backArrow.svg';
import { ReactComponent as Close } from '../../assets/icons/navigation/closeModal.svg';
import { ReactComponent as CloseNotification } from '../../assets/icons/systemNotification/close.svg';
import { ReactComponent as Success } from '../../assets/icons/systemNotification/success.svg';
import { ReactComponent as Warning } from '../../assets/icons/systemNotification/warning.svg';
import { ReactComponent as Error } from '../../assets/icons/systemNotification/error.svg';
// css
import "./css/widgetList.css";

// components
import Icon from './../../uhoo-components/iconLibrary';
import AudienceSelect from "../global/components/create/audienceSelect.jsx";
import SystemNotification from '../global/SystemNotification';
// components

import SecondaryButton from "../../uhoo-components/buttons/secondaryButton.jsx";

// backend
import { getConfigs, putConfigsById, getOwn, publishNotification, postMediaArbitrary } from "./../../actions/uhoo"
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

const ModalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  justifyContent: 'center',
  display: "flex",
  p: 4,
  'margin-top': '10px'
}

const WidgetList = () => {

  // states
  const [loading, setLoading] = useState(true);
  const [complements, setComplements] = useState([]);
  const [boards, setBoards] = useState([]);
  const [configs, setConfigs] = useState({});
  const [tabSelected, setTabSelected] = useState("complement")
  // notifys
  const [showNotificatioError, setShowNotificatioError] = useState(false);
  const [showNotificationWarning, setShowNotificationWarning] = useState(false);
  const [showNotificationSuccessfull, setShowNotificationSuccessfull] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState('');
  const [channelPublish, setChannelPublish] = useState([]);
  const [showNotificationCheckAudiences, setShowNotificationCheckAudiences] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  // modals
  const [modalPutWidget, setModalPutWidget] = useState(null);
  const [modalNotifyWidget, setModalNotifyWidget] = useState(null);
  const [modalDeleteWidget, setModalDeleteWidget] = useState(null);
  const [isAura, setIsAura] = useState(false);

  // modals notification
  const [titleNotify, setTitleNotify] = useState(null);
  const [bodyNotify, setBodyNotify] = useState(null);
  const [subjectNotify, setSubjectNotify] = useState(null);
  const [audienceToNotify, setAudienceToNotify] = useState([]);
  const [icoRender, setIcoRender] = useState(null);
  const [iconWidget, setIconWidget] = useState(null);
  const [routeWidget, setRouteWidget] = useState(null);

  // hooks
  const { t, i18n } = useTranslation(); // Localization
  const history = useHistory();

  /// ----- Singularity ----- ///

  const [widgetsSingularity, setWidgetsSingularity] = useState(false);
  const [board, setBoard] = useState(false);

  const [maxLengthComplements, setMaxLengthComplements] = useState(null);
  const [lengthComplementsCreated, setLengthComplementsCreated] = useState(0);
  const [maxLengthBoard, setMaxLengthBoard] = useState(null);
  const [lengthBoardCreated, setLengthBoardCreated] = useState(0);

  const [openModalCantCreate, setOpenModalCantCreate] = useState(false);
  const [textModalCantCreate, setTextModalCantCreate] = useState('');
  const [refresh, setRefresh] = useState(false);

  //modal edit
  const [ nameWidget, setNameWidget ] = useState("");
  const [ urlWidget, setUrlWidget ] = useState("");
  const [ typeWidget, setTypeWidget ] = useState("");
  const [ audiences, setAudiences ] = useState([]);
  const [ isLoadingPost, setIsLoadingPost ] = useState(false);
  const [imageRender, setImageRender] = useState(null);
  const [imageWidget, setImageWidget] = useState(null);
  const [videoRender, setVideoRender] = useState(null);
  const [videoWidget, setVideoWidget] = useState(null);
  const [ imageLoading, setImageLoading ] = useState(false);
  const [menuForm, setMenuForm] = useState("");
  const [savePostion, setSavePostiion] = useState(false);
  const [orderItems, setOrderItems] = useState(false);

  useEffect(async () => {
    const _configs = await getConfigs();
    let config = _configs.data;
    let _suscription = _get(config.find((element) => { return element.name === "suscription.plan"}), "value", 'basic');
    let currentConfigComplements = config.find(config => config.name == 'app.community.webTab.apps');
    currentConfigComplements = _get(currentConfigComplements, 'value', {});
    let currentConfigBoard = config.find(config => config.name == 'app.community.board.elements');
    currentConfigBoard = _get(currentConfigBoard, 'value', {});
    let lengthComplements = 0;
    let lengthBoard = 0;
    for (const element in currentConfigComplements) {
      lengthComplements++;
    }
    for (const element in currentConfigBoard) {
      lengthBoard++;
    }
    
    setLengthComplementsCreated(lengthComplements);
    setLengthBoardCreated(lengthBoard);
    if(_suscription == 'pro') {
      setMaxLengthComplements(_get(auraPro, 'maxLengthComplements', null));
      setMaxLengthBoard(_get(auraPro, 'maxLengthBoard', null));  
    }else{
      setMaxLengthComplements(_get(auraBasic, 'maxLengthComplements', null));
      setMaxLengthBoard(_get(auraBasic, 'maxLengthBoard', null));  
    }
  }, [refresh])

  useEffect(() => {
    switch (typeWidget) {
      // case "web_embedded":
      //   setMenuForm(
      //     <div className="title">          
      //       <div className="WidgetformTitles">{/*t('uhoo...')*/}{t('basicWords.url')}:</div>
      //       <input type="text" placeholder={t('uhoo.widget.url')} value={urlWidget} onChange={e => setUrlWidget(e.target.value)} className="inputForms"/> {/*t('uhoo...')*/}
      //     </div>
      //   );
      //   break;
      case "internal_link":
        setMenuForm(
          <div className="title">
            <div className="WidgetformTitles">{/*t('uhoo...')*/}{t('uhoo.widget.rute')}:</div>
            <input type="radio" id="wall" value="Feed" name="internal_link" checked={routeWidget == 'Feed'}  onClick={() =>setRouteWidget("Feed")}/>
            <label htmlFor="wall"> {t('uhoo.widget.wall')}</label><br />

            <input type="radio" id="complementos" value="Webview" checked={routeWidget == 'Webview'} name="internal_link" onClick={() =>setRouteWidget("Webview")}/>
            <label htmlFor="complementos"> {t('uhoo.widget.complement')}</label><br />
          </div>
        );
        break;
      case "zoom_image":
        setMenuForm("");
        break;
      case "other_image":
        setMenuForm(
          <div className="attachments">
            {
              imageRender ?
                <>
                  <div
                    className="contentAttachments"
                    onClick={() => document.getElementById("attachmentsUploadWidgetImage").click()}
                    style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                  >
                    <img src={imageRender} width="100%" height="100%" style={{ objectFit: 'contain' }} />
                  </div>
                </>
                :
                <>
                  <div
                    className="contentAttachments"
                    onClick={() => document.getElementById("attachmentsUploadWidgetImage").click()}
                    style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                  >
                    <div className="WidgetformTitles">{t('uhoo.widget.image')}</div>
                    <Icon.upload />
                    {t('uhoo.createPost.form.uploadAttachments')}
                  </div>
                </>
            }
            <input
              hidden
              id="attachmentsUploadWidgetImage"
              type="file"
              accept="image/*, .png, .jpeg, .jpg"
              onChange={fileImage}
            />
          </div>
        );
        break;
      case "video":
        setMenuForm(
          <div className="attachments">
            {
              videoRender ?
                <>
                  <div
                    className="contentAttachments"
                    onClick={() => document.getElementById("attachmentsUploadWidgetVideo").click()}
                    style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                  >
                    <video src={videoRender} width="100%" height="100%" style={{ objectFit: 'contain' }} />
                  </div>
                </>
                :
                <>
                  <div
                    className="contentAttachments"
                    onClick={() => document.getElementById("attachmentsUploadWidgetVideo").click()}
                    style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                  >
                    <div className="WidgetformTitles">{t('uhoo.widget.video')}</div>
                    <Icon.upload />
                    {t('uhoo.createPost.form.uploadAttachments')}
                  </div>
                </>
            }
            <input
              hidden
              id="attachmentsUploadWidgetVideo"
              type="file"
              accept="video/*"
              onChange={fileVideo}
            />
          </div>
        );
        break;
      default:
        setMenuForm("");
        break;
    }

  }, [typeWidget, imageRender, videoRender, routeWidget]);

  const Singularity = () => {
    useEffect(async () => {
      const _configs = await getConfigs();
      let config = _configs.data;

      setWidgetsSingularity(
        _get(config.find((element) => {
          return element.name === "app.community.webTab.edition.enabled";
        }), "value", 0)
      );

      setBoard(0);

      setBoard(
        _get(config.find((element) => {
          return element.name === "app.community.board.edition.enabled";
        }), "value", 0)
      );

    }, []);
  };

  Singularity();

  if (widgetsSingularity === 0) {
    history.replace("/manage")
  }

  /// --- end Singularity --- ///

  // life cicles
  useEffect(() => {
    const loadWidgets = async () => {
      try {
        let _configs = await getConfigs();
        let currentConfigComplements = _get(_configs, 'data', []).find(config => config.name == 'app.community.webTab.apps');
        let _complements = [];
        if(currentConfigComplements != undefined){
          _complements = Object.keys(currentConfigComplements.value).map(k => ({ key_current: k, ...currentConfigComplements.value[k] }));
        }
        let currentConfigBoard = _get(_configs, 'data', []).find(config => config.name == 'app.community.board.elements');
        let _boards = [];
        if(currentConfigBoard != undefined){
           _boards = Object.keys(currentConfigBoard.value).map(k => ({ key_current: k, ...currentConfigBoard.value[k] }));
        }
        _complements.map((element, index) => {
          element.id = index;
          element.order = index;
        });
        let configToSave;
        let result;
        try {
          configToSave = {
            "uuid": _get(currentConfigComplements, 'uuid', ''),
            "name": "app.community.webTab.apps",
            "value": JSON.stringify(_complements),
            "community": _get(currentConfigComplements, 'community', ''),
            "properties": {},
            "editable": true
          }
          result = await putConfigsById(_get(currentConfigComplements, 'uuid', ''), configToSave);
          
        } catch (error) {
          console.log("error", error);
        }
        _boards.map((element, index) => {
          element.id = index;
          element.order = index;
        });
        try {
          configToSave = {
            "uuid": _get(currentConfigBoard, 'uuid', ''),
            "name": "app.community.board.elements",
            "value": JSON.stringify(_boards),
            "community": _get(currentConfigBoard, 'community', ''),
            "properties": {},
            "editable": true
          }
          result = await putConfigsById(_get(currentConfigBoard, 'uuid', ''), configToSave);
          
        } catch (error) {
          console.log("error", error);
        }
        setConfigs(_configs);
        setComplements(_complements);
        setBoards(_boards);
        setLoading(false);
      } catch (error) {
        console.log(error)
      }
      const URLactual = window.location.href;
      setIsAura(URLactual.includes("aura"));
    }
    loadWidgets()
  }, [refresh])

  // functions
  const cleanNotify = () => {
    setShowNotificationMessage("")
    setShowNotificationWarning(false)
    setShowNotificatioError(false)
    setShowNotificationSuccessfull(false)
  }

  const configOpeModalCanCreate = () => {
    setTextModalCantCreate(isAura ? t('uhoo.widget.createButton') : t('uhoo.home.cards.widgetsCard.cardTitle'));
    setOpenModalCantCreate(true);
  };

  const openActions = (key) => {
    document.getElementById("dropWidgetAction-" + key).classList.toggle("showWidgetActions");
  }

  const openModalEditWigdet = (props) => {
    setModalPutWidget(props)
    if(props.route) setRouteWidget(props.route);
    setNameWidget(props.name);
    setUrlWidget(props.url);
    setImageRender(props.image);
    setIcoRender(props.icon);
    setChannelPublish(props.audiences)
    setTypeWidget(props.kind);
    setVideoRender(props.video);
  }

  const fileClick = (e) => {
    let file = e.target.files[0];
    var fr = new FileReader();
    fr.onload = () => {
      setIcoRender(fr.result)
    }
    fr.readAsDataURL(file);
    setImageWidget(file)
  }

  const fileCIco = (e) => {
    let file = e.target.files[0];

    var fr = new FileReader();
    fr.onload = () => {
      setIcoRender(fr.result)
    }
    fr.readAsDataURL(file);
    setIconWidget(file)
  }
  const fileImage = (e) => {
    let file = e.target.files[0];

    //props.setPreviewImage(URL.createObjectURL(file)); /// preview 

    var fr = new FileReader();
    fr.onload = () => {
      setImageRender(fr.result)
    }
    fr.readAsDataURL(file);
    setImageWidget(file)
  }
  const fileVideo = (e) => {
    let file = e.target.files[0];

    //props.setPreviewImage(URL.createObjectURL(file)); /// preview 

    var fr = new FileReader();
    fr.onload = () => {
      setVideoRender(fr.result)
    }
    fr.readAsDataURL(file);
    setVideoWidget(file)
  }

  const deleteWidget = async () => {
 
    cleanNotify();
    let _widget = modalDeleteWidget;
    let currentConfig;
    let _name;
    if(tabSelected == 'complement'){
      currentConfig = _get(configs, 'data', []).find(config => config.name == 'app.community.webTab.apps');
      _name = "app.community.webTab.apps";
    }else{
      currentConfig = _get(configs, 'data', []).find(config => config.name == 'app.community.board.elements');
      _name = "app.community.board.elements";
    }
    // update configs
    let widget = _omit(currentConfig.value, [_get(_widget, 'key_current', '')]);
    let valueWidget = Object.values(widget)
    if(currentConfig.value.length == valueWidget.length){
      widget = _omit(currentConfig.value, [_get(_widget, 'order', '')]);
      valueWidget = Object.values(widget);
    }
    valueWidget = Object.values(widget)
    // config update
    let configToSave = {
      "uuid": _get(currentConfig, 'uuid', ''),
      "name": _name,
      "value": JSON.stringify(widget),
      "community": _get(currentConfig, 'community', ''),
      "properties": {},
      "editable": false
    }
    let result;
    try {
      result = await putConfigsById(_get(currentConfig, 'uuid', ''), configToSave);
    } catch (error) {
      setShowNotificationMessage("Error to update widgets")
      setShowNotificatioError(true);
      return;
    }

    let widgetsFinal = Object.keys(widget).map(k => ({ key_current: k, ...currentConfig.value[k] }));
    if(tabSelected == 'complement'){
      setComplements(widgetsFinal);
    }else{
      setBoards(widgetsFinal);
    }
    setModalDeleteWidget(null);
    setShowNotificationMessage("Widget eliminado correctamente")
    setShowNotificationSuccessfull(true);
    setRefresh(!refresh);
  }

  const sendNotificationCloud = async () => {
    cleanNotify();

    let _own
    try {
      _own = await getOwn();
    } catch (error) {
      setShowNotificationMessage("Error to get own data")
      setShowNotificatioError(true);
      return;
    }
    let _notificationFinal = {
      "communityId": _get(_own, 'community.uuid', ''),
      "notificationInfo": {
        "type": "audience",
        "endpoints": audienceToNotify,
        "omitUser": [_get(_own, 'uuid', '')]
      },
      "notificationVariables": {
        "sender_name": "",
        "receiver_name": "",
        "community_name": "",
        "audiences": "",
        "sent_date": "",
        "date_now": "",
        "action_type": null,
        "post_title": "",
        "post_content": "",
        "notification_content": bodyNotify,
        "notification_title": titleNotify,
        "router": {
          "route": tabSelected == 'complement' ? "Webview" : "Board",
          "params": {
            "appUuid": _get(modalNotifyWidget, 'key_current', '')
          }
        },
        "links": null
      }
    }

    let result
    try {
      result = await publishNotification(_notificationFinal);
    } catch (error) {
      console.log("Error in send notification", error);
      return;
    }
    setModalNotifyWidget(null)
    setShowNotificationMessage("Notificación enviada correctamente")
    setShowNotificationSuccessfull(true);
  }

  const toSave = async () => {
    setIsLoadingPost(true);
    cleanNotify();
    // process
    let configs
    try {
      configs = await getConfigs();
    } catch (error) {
      setShowNotificationMessage("Error to get configs")
      setShowNotificatioError(true);
      return;
    }

    if(channelPublish.length == 0){
      setShowNotificationMessage(t('basicWords.mustSelectAudiences'))
      setShowNotificationWarning(true);
      setIsLoadingPost(false);
      return;
    }

    if(nameWidget == ''){
      setShowNotificationMessage(t('basicWords.modal.errorWIdget.name'))
      setShowNotificationWarning(true);
      setIsLoadingPost(false);
      return;
    }

    if(typeWidget == 'web_embedded'){
      if(urlWidget == ''){
        setShowNotificationMessage(t('basicWords.modal.errorWIdget.url'))
        setShowNotificationWarning(true);
        setIsLoadingPost(false);
        return;
      }
    }

    if(typeWidget == 'internal_link'){
      if(routeWidget == null){
        setShowNotificationMessage(t('basicWords.modal.errorWIdget.route'))
        setShowNotificationWarning(true);
        setIsLoadingPost(false);
        return;
      }
    }

    if(typeWidget == 'other_image'){
      if(imageRender == null){
        setShowNotificationMessage(t('basicWords.modal.errorWIdget.image'))
        setShowNotificationWarning(true);
        setIsLoadingPost(false);
        return;
      }
    }

    if(typeWidget == 'video'){
      if(videoRender == null){
        setShowNotificationMessage(t('basicWords.modal.errorWIdget.video'))
        setShowNotificationWarning(true);
        setIsLoadingPost(false);
        return;
      }
      if( (videoRender.includes('png') || videoRender.includes('jpeg') || videoRender.includes('jpg')) && !videoWidget){
        setShowNotificationMessage(t('basicWords.modal.errorWIdget.video'))
        setShowNotificationWarning(true);
        setIsLoadingPost(false);
        return;
      }
    }

    let currentConfig;
    let widges;
    
    if(tabSelected == 'complement'){
      currentConfig = _get(configs, 'data', []).find(config => config.name == 'app.community.webTab.apps');
      widges = currentConfig.value;
    }else{
      currentConfig = _get(configs, 'data', []).find(config => config.name == 'app.community.board.elements');
      widges = currentConfig.value;
    }

    let uuid = modalPutWidget.key_current;
    let newWidget = {};
    let configToSave = {}
    if(tabSelected == 'complement'){
      // upload media
      let mediaArbitrary 
      if(imageWidget != null){
        var fdata = new FormData();
        fdata.append("properties", "");
        fdata.append("image", imageWidget);
        fdata.append("thumbnail", imageWidget);
        try {
          mediaArbitrary = await postMediaArbitrary(fdata)
        } catch (error) {
          setShowNotificationMessage("Error to upload image")
          setShowNotificatioError(true);
          return;
        }
      }
      
      newWidget = {
        "id": modalPutWidget.id,
        "name": nameWidget ,
        "kind":typeWidget,
        "url": urlWidget,
        "icon": imageWidget != null ? _get(mediaArbitrary, 'data[0].url', '') : modalPutWidget.icon,
        "audiences": channelPublish,
        "enabled": true
      }
      widges[modalPutWidget.id] = newWidget;
      configToSave = {
        "uuid": _get(currentConfig, 'uuid', ''),
        "name": "app.community.webTab.apps",
        "value": JSON.stringify(widges),
        "community": _get(currentConfig, 'community', ''),
        "properties": {},
        "editable": true
      }
    }else{
      // upload media
      let iconMediaArbitrary;
      if(iconWidget){
        var fdata = new FormData();
        fdata.append("properties", "");
        fdata.append("image", iconWidget);
        fdata.append("thumbnail", iconWidget);
        try {
          iconMediaArbitrary = await postMediaArbitrary(fdata)
        } catch (error) {
          setShowNotificationMessage("Error to upload image")
          setShowNotificatioError(true);
          return;
        }
     }
      let imageMediaArbitrary; 
      if(imageWidget){
        // upload media
        var fdata = new FormData();
        fdata.append("properties", "");
        fdata.append("image", imageWidget);
        fdata.append("thumbnail", imageWidget);
        try {
          imageMediaArbitrary = await postMediaArbitrary(fdata)
        } catch (error) {
          setShowNotificationMessage("Error to upload image")
          setShowNotificatioError(true);
          return;
        }
      }
      let videoMediaArbitrary; 
      if(videoWidget){
          // upload media
        var fdata = new FormData();
        fdata.append("properties", "");
        fdata.append("image", videoWidget);
        fdata.append("thumbnail", videoWidget);
        try {
          videoMediaArbitrary = await postMediaArbitrary(fdata)
        } catch (error) {
          setShowNotificationMessage("Error to upload image")
          setShowNotificatioError(true);
          return;
        }
      }

      newWidget = {
      "id": modalPutWidget.id,
      "name": nameWidget ,
      "kind":typeWidget,
      "route": routeWidget,
      "url": urlWidget,
      "icon": iconWidget ? _get(iconMediaArbitrary, 'data[0].url', '') : modalPutWidget.icon,
      "image": imageWidget ? _get(imageMediaArbitrary, 'data[0].url', '') : modalPutWidget.imageWidget ? modalPutWidget.imageWidget : modalPutWidget.icon,
      "video": videoWidget ? _get(videoMediaArbitrary, 'data[0].url', '') :  modalPutWidget.video ? modalPutWidget.video : modalPutWidget.icon,
      "audiences": channelPublish,
      "enabled": true
      }
      // widges[uuid] = newWidget;
      widges.forEach((element, index) => {
        if(element.id == newWidget.id) widges[index] = newWidget;
      });
      configToSave = {
        "uuid": _get(currentConfig, 'uuid', ''),
        "name": "app.community.board.elements",
        "value": JSON.stringify(widges),
        "community": _get(currentConfig, 'community', ''),
        "properties": {},
        "editable": true
      }
    }

    // set data widget
    let result;
    try {
      result = await putConfigsById(_get(currentConfig, 'uuid', ''), configToSave);
      setRefresh(!refresh);
    } catch (error) {
      setShowNotificationMessage(t("basicWords.modal.internalServerErrorContactSupport"))
      setShowNotificatioError(true);
      return;
    }
    setShowNotificationMessage("Widget editado correctamente")
    setShowNotificationSuccessfull(true);
    setTimeout(()=>{setShowNotificationSuccessfull(false);}, 2000);
    setIsLoadingPost(false);
    setModalPutWidget(null);
    setImageWidget(null);
    setIconWidget(null);
    setVideoWidget(null);
  }

  const savePositionButtons = async () => {
    let currentConfigComplements = _get(configs, 'data', []).find(config => config.name == 'app.community.webTab.apps');
    let currentConfigBoard = _get(configs, 'data', []).find(config => config.name == 'app.community.board.elements');
    let configToSave;
    let result;
    if(tabSelected == 'complement'){
      let newComplements = _cloneDeep(complements);
      newComplements.forEach((complement, index) => {
        complement.id = index;
        complement.order = index;
      });
      try {
        configToSave = {
          "uuid": _get(currentConfigComplements, 'uuid', ''),
          "name": "app.community.webTab.apps",
          "value": JSON.stringify(newComplements),
          "community": _get(currentConfigComplements, 'community', ''),
          "properties": {},
          "editable": true
        }
        result = await putConfigsById(_get(currentConfigComplements, 'uuid', ''), configToSave);
        setRefresh(!refresh);
        setSavePostiion(false);
      } catch (error) {
        console.log("error", error);
        setShowNotificationMessage(t("basicWords.modal.internalServerErrorContactSupport"))
        setShowNotificatioError(true);
        return;
      }
      setSavePostiion(false);
      setOrderItems(false);
    }
    if(tabSelected == 'board'){
      let newBoards = _cloneDeep(boards);
      newBoards.forEach((board, index) => {
        board.id = index;
        board.order = index;
      });
      try {
        configToSave = {
          "uuid": _get(currentConfigBoard, 'uuid', ''),
          "name": "app.community.board.elements",
          "value": JSON.stringify(newBoards),
          "community": _get(currentConfigBoard, 'community', ''),
          "properties": {},
          "editable": true
        }
        result = await putConfigsById(_get(currentConfigBoard, 'uuid', ''), configToSave);
        setRefresh(!refresh);
        setSavePostiion(false);
      } catch (error) {
        console.log("error", error);
        setShowNotificationMessage(t("basicWords.modal.internalServerErrorContactSupport"))
        setShowNotificatioError(true);
        return;
      }
    }
    setShowNotificationMessage(t("basicWords.modal.positionSectionSuccessfullyModified"))
    setShowNotificationSuccessfull(true);
    setTimeout(()=>{setShowNotificationSuccessfull(false);}, 2000);
  }

  const cleanData = () => {
    setModalPutWidget(null); 
    setIsLoadingPost(false);
    setImageWidget(null);
    setIconWidget(null);
    setVideoWidget(null);
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    let data = [];
    let data2 = [];
    let finalData = [];
    if(tabSelected == 'complement') {data =  _cloneDeep(complements);  data2 = _cloneDeep(complements);}
    if(tabSelected == 'board') {data =  _cloneDeep(boards); data2 =  _cloneDeep(boards);}
    finalData = data;
    finalData[oldIndex] = data[newIndex];
    finalData[newIndex] = data2[oldIndex];
    if(tabSelected == 'complement') setComplements(finalData);
    if(tabSelected == 'board') setBoards(finalData);
    setSavePostiion(true);
  };
  

  // renders
  const Loading = () => (
    <section className="widgetListContainer">
      <CircularProgress />
    </section>
  )
  const WidgetCard = (props) => (
    <div className="widgetsContainer" >
      <img src={_get(props, 'icon', '')} className="widgetsIcon" style={{ objectFit: 'contain' }} />
      <div className="widgetsActions">
        <span>{_get(props, 'name', '')}</span>
        <div className="columnAction hover" onClick={() => {openActions(props.id)}} role="button">
          <Icon.options />
        </div>
        <div className="dropWidgetAction" id={"dropWidgetAction-" + props.id}>
          <div className="dropWidgetAction-content">
            <div className="itemDropColumnAction hover" onClick={() => openModalEditWigdet(props)} role="button">
              {/* <Icon.edit />  */}
              <div>{t('uhoo.widget.actions.actionsEdit')}</div>
            </div>
            <div className="itemDropColumnAction hover" onClick={() =>setModalNotifyWidget(props) } role="button">
              {/* <Icon.Notification /> */}
              <div>{t('uhoo.widget.actions.actionsNotify')}</div>
            </div>
            <div className="itemDropColumnAction hover" onClick={() => setModalDeleteWidget(props)} role="button">
              {/* <Icon.trash /> */}
              <div>{t('uhoo.widget.actions.actionsDelete')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const SortableItem = SortableElement(({widget}) => <WidgetCard {...widget} />);

  const SortableList = SortableContainer(({items}) => {
    return (
      <section className="widgetListContainer">
        {
          items.map((item, i) => (
              orderItems ? 
              <SortableItem key={item.key_current} index={i} widget={item} />
              :
              <WidgetCard {...item} />
          ))
        }
      </section>
    );
  });

  const Complements = () => (
    <div>
    <SortableList items={complements} onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex)} axis={'xy'} />
    {savePostion ? 
    <div className="containerSavePositionButton">
      <div className="toPost" role="button" onClick={() =>  savePositionButtons() } >
        {t('basicWords.save')}
      </div> 
    </div> 
    : null}
    </div>
  )

  const Boards = () => (
    <div>
      <SortableList items={boards} onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex)} axis={'xy'} />
      {savePostion ? 
      <div className="containerSavePositionButton">
        <div className="toPost" role="button" onClick={() =>  savePositionButtons() } >
          {t('basicWords.save')}
        </div> 
      </div> 
      : null}
    </div>
  )

  function timeout(ms) { return new Promise(resolve => setTimeout(resolve, ms)); }

  const  copyLink = async (route) => {
    let schema;
    if(isAura){
      schema = '&schema=aurapp&android=org.somosaura.aurapp&ios=aurapp/id1543076126';
    }else{
      schema = '&schema=uhoo&android=com.brilliant.brilliantapp&ios=uhoo/id1202020080';
    }
    let data = JSON.parse(sessionStorage.getItem("own"));
    let communityUuid = _get(data, "community.uuid", "");
    navigator.clipboard.writeText(`https://uhoo.io/redirect?path=api/${communityUuid}/${route}${schema}`);
    setShowSuccess(true);
    await timeout(500);
    setShowSuccess(false);
  }

  let enableCreateBoard = maxLengthBoard != null && maxLengthBoard <= lengthBoardCreated;
  let enableCreateComplement= maxLengthComplements != null && maxLengthComplements <= lengthComplementsCreated;

    return (
      <React.Fragment>
        <section className="TitleWigdet">
          <div className="titleArrow">
            <BackArrow className="ico pointerCursor" onClick={() => history.goBack()} />
            {isAura ? 
              <h3>{t('basicWords.buttons.buttons')}</h3>
              : 
              <h3>{t('uhoo.widget.listTitle')}</h3>
            }
            
          </div>
            <div className="createPostActions">
              {
              tabSelected == 'complement'  ?
                <div className="toPost" role="button" onClick={() => enableCreateComplement && isAura ? configOpeModalCanCreate() : history.replace("/createWidget") } >
                  {isAura ? t('uhoo.widget.createButton') : t('uhoo.widget.createTitle') }
                </div> 
              : null
              }
              {
              tabSelected == 'board'  ?
                <div className="toPost" role="button" onClick={() => enableCreateBoard  && isAura ? configOpeModalCanCreate() : history.replace("/createWidget") } >
                  {isAura ? t('uhoo.widget.createButton') : t('uhoo.widget.createTitle') }
                </div>
               : null
              }
            </div>
        </section>
        {board == 1 ? 
          <section className="widgetList">
            <Tabs>
              <TabList>
                <Tab onClick={() => {setTabSelected("complement"); setOrderItems(false)}} >{t('uhoo.widget.complement')}</Tab>
                <Tab onClick={() => {setTabSelected("board"); setOrderItems(false)}}>{t('uhoo.widget.board')}</Tab>
              </TabList>
              <div className="widgetContent">
                <TabPanel>
                  <div className="checkBoxOrerItems">
                    <div className="p-8">
                      <input type="checkbox" className="autoPublish" checked={orderItems} onClick={() => setOrderItems(!orderItems)}/> {t('basicWords.orderItems')}
                    </div>
                    {/* <div className="containerCopyLink p-8" onClick={() => copyLink('Webview')}>
                      <Link className="iconLink"/>
                      <p>{t("basicWords.copyLink")}</p>
                      <span>{t("basicWords.copyLinkApp")}</span>
                    </div> */}
                  </div>
                  {loading ? <Loading /> : <Complements />}
                </TabPanel>
                <TabPanel>
                  <div className="checkBoxOrerItems">
                    <div className="p-8">
                      <input type="checkbox" className="autoPublish" checked={orderItems} onClick={() => setOrderItems(!orderItems)}/> {t('basicWords.orderItems')}
                    </div>
                    {/* <div className="containerCopyLink p-8" onClick={() => copyLink('Board')}>
                      <Link className="iconLink"/>
                      <p>{t("basicWords.copyLink")}</p>
                      <span>{t("basicWords.copyLinkApp")}</span>
                    </div> */}
                  </div>
                  {loading ? <Loading /> : <Boards />}
                </TabPanel>
              </div>
            </Tabs>
          </section>
        : 
          <section className="widgetList">
            
            <div className="widgetContent">
              <div className="checkBoxOrerItems">
                <div className="p-8">
                  <input type="checkbox" className="autoPublish" checked={orderItems} onClick={() => setOrderItems(!orderItems)}/> {t('basicWords.orderItems')}
                </div>
                {/* <div className="containerCopyLink p-8" onClick={() => copyLink('Webview')}>
                  <Link className="iconLink"/>
                  <p>{t("basicWords.copyLink")}</p>
                  <span>{t("basicWords.copyLinkApp")}</span>
                </div> */}
              </div>
              {
                loading ? <Loading /> : <Complements />
              }
            </div>
          </section>

        }

        {/* Modal update complements */}
        <Modal open={modalPutWidget != null && tabSelected == 'complement'} onClose={() => setModalPutWidget(null)} >
          <Box sx={ModalBoxStyle}>
            <div className="modalEditWigdet">
              <div className="audience">          
                <div className="WidgetformTitles">{t('uhoo.createPost.form.audienceTitle')}</div>
                <AudienceSelect setAudiencePublish={setAudiences} fullAudience={true} setChannelPublish={setChannelPublish} channelPublish={channelPublish}/>
              </div>

              <div className="title">          
                <div className="WidgetformTitles">{t('uhoo.widget.type')}</div>
                  <select className="inputForms" value={typeWidget} onChange={e => setTypeWidget(e.target.value)}>
                    <option value="" selected disabled hidden> {t('basicWords.selectOption')} </option> 
                    <option value="web_embedded">{t('uhoo.widget.typeEmbedded')}</option>
                    <option value="web_external">{t('uhoo.widget.typeExternal')}</option>
                  </select>
              </div>
              <div className="title">          
                <div className="WidgetformTitles">{/*t('uhoo...')*/}{t('basicWords.name')}:</div>
                <input type="text" placeholder={t('uhoo.widget.name')}  value={nameWidget} onChange={e => setNameWidget(e.target.value)} className="inputForms"/> {/*t('uhoo...')*/}
              </div>
              <div className="title">          
                <div className="WidgetformTitles">{/*t('uhoo...')*/}{t('basicWords.url')}:</div>
                <input type="text" placeholder={t('uhoo.widget.url')} value={urlWidget} onChange={e => setUrlWidget(e.target.value)} className="inputForms"/> {/*t('uhoo...')*/}
              </div>
              <div className="title">          
                <div className="WidgetformTitles">{/*t('uhoo...')*/}{t('uhoo.widget.image')}:</div>
                <div className="attachments">
                  {
                    icoRender ?
                    <>
                      <div
                        className="contentAttachments"
                        onClick={() => document.getElementById("attachmentsUploadWidget").click()}
                        style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                      >
                        <img src={icoRender} width="100%" height="100%" style={{ objectFit: 'contain' }} />
                      </div>
                    </>
                    :
                    <>

                      <div
                        className="contentAttachments"
                        onClick={() => document.getElementById("attachmentsUploadWidget").click()}
                        style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                      >
                        <Icon.upload />{t('uhoo.createPost.form.uploadAttachments')}
                      </div>
                    </>
                  }
                  <input
                    hidden
                    id="attachmentsUploadWidget"
                    type="file"
                    accept="image/*, .png, .jpeg, .jpg"
                    onChange={fileClick}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                { isLoadingPost ?
                    <div className="toWidgetDisabled" >{t('basicWords.modal.update')}</div>
                    :
                    <div className="toWidget" onClick={()=> toSave()}>{t('basicWords.modal.update')}</div>
                }
                <div className="toWidget" onClick={() => cleanData()}>{t('uhoo.widget.formsNotification.actions.cancel')}</div>
              </div>
            </div>
          </Box>
        </Modal>

        {/* Modal update board */}
        <Modal open={modalPutWidget != null && tabSelected == 'board'} onClose={() => setModalPutWidget(null)} >
          <Box sx={ModalBoxStyle}>
            <div className="modalEditWigdet">
              <div className="contentsWidgetform">
                <div className="audience" style={{ marginTop: '15px' }}>
                  <div className="WidgetformTitles">{t('uhoo.createPost.form.audienceTitle')}</div>
                  <AudienceSelect setAudiencePublish={setAudiences} fullAudience={true} setChannelPublish={setChannelPublish} channelPublish={channelPublish}/>
                </div>

                <div className="title">
                  <div className="WidgetformTitles">{t('uhoo.widget.type')}</div>
                  <select className="inputForms" value={typeWidget} onChange={e => setTypeWidget(e.target.value)}>
                    <option value="" selected disabled hidden> {t('basicWords.selectOption')} </option>
                    <option value="web_embedded">{t('uhoo.widget.typeEmbedded')}</option>
                    <option value="internal_link">{t('uhoo.widget.typeInternal')}</option>
                    <option value="zoom_image">{t('uhoo.widget.typeExtendImage')}</option>
                    <option value="other_image">{t('uhoo.widget.typeOpenImage')}</option>
                    <option value="video">{t('uhoo.widget.typeVideo')}</option>
                  </select>
                </div>

                <div className="title">          
                  <div className="WidgetformTitles">{/*t('uhoo...')*/}{t('basicWords.name')}:</div>
                  <input type="text" placeholder={t('uhoo.widget.name')}  value={nameWidget} onChange={e => setNameWidget(e.target.value)} className="inputForms"/> {/*t('uhoo...')*/}
                </div>
                {
                  typeWidget == "web_embedded" ?
                  <div className="title">          
                    <div className="WidgetformTitles">{/*t('uhoo...')*/}{t('basicWords.url')}:</div>
                    <input type="text" placeholder={t('uhoo.widget.url')} value={urlWidget} onChange={e => setUrlWidget(e.target.value)} className="inputForms"/> {/*t('uhoo...')*/}
                  </div>
                  :
                  null
                }
                {menuForm}

                <div className="attachments">
                  {
                    icoRender ?
                      <>
                        <div
                          className="contentAttachments"
                          onClick={() => document.getElementById("attachmentsUploadWidgetIcon").click()}
                          style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                        >
                          <img src={icoRender} width="100%" height="100%" style={{ objectFit: 'contain' }} />
                        </div>
                      </>
                      :
                      <>
                        <div
                          className="contentAttachments"
                          onClick={() => document.getElementById("attachmentsUploadWidgetIcon").click()}
                          style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                        >
                          <div className="WidgetformTitles">{t('uhoo.widget.ico')}</div>
                          <Icon.upload />
                          {t('uhoo.createPost.form.uploadAttachments')}
                        </div>
                      </>
                  }
                  <input
                    hidden
                    id="attachmentsUploadWidgetIcon"
                    type="file"
                    accept="image/*, .png, .jpeg, .jpg"
                    onChange={fileCIco}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                  { isLoadingPost ?
                      <div className="toWidgetDisabled" >{t('basicWords.modal.update')}</div>
                      :
                      <div className="toWidget" onClick={()=> toSave()}>{t('basicWords.modal.update')}</div>
                  }
                  <div className="toWidget" onClick={() => cleanData()}>{t('uhoo.widget.formsNotification.actions.cancel')}</div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
  
        {/* Modal notify widget */}
        <Modal open={modalNotifyWidget != null} onClose={() => setModalNotifyWidget(null)} >
          <Box sx={ModalBoxStyle}>
            <div className="modalDeleteWigdet">
              <div className="audience">
                <div className="WidgetformTitles">{t('uhoo.createPost.form.audienceTitle')}</div>
                <AudienceSelect
                  fullAudience={true}
                  setAudienceUUID={setAudienceToNotify}
                  setChannelPublish={setChannelPublish}
                />
              </div>
              <div className="title">
                <div className="WidgetformTitles">{/*t('uhoo...')*/}Title:</div>
                <input type="text" placeholder={t('uhoo.widget.formsNotification.placeHolders.title')} onChange={e => setTitleNotify(e.target.value)} className="inputForms" /> {/*t('uhoo...')*/}
              </div>
              <div className="title">
                <div className="WidgetformTitles">{/*t('uhoo...')*/}Subject:</div>
                <input type="text" placeholder={t('uhoo.widget.formsNotification.placeHolders.subject')} onChange={e => setSubjectNotify(e.target.value)} className="inputForms" /> {/*t('uhoo...')*/}
              </div>
              <div className="title">
                <div className="WidgetformTitles">{/*t('uhoo...')*/}Body:</div>
                <input type="text" placeholder={t('uhoo.widget.formsNotification.placeHolders.content')} onChange={e => setBodyNotify(e.target.value)} className="inputForms" /> {/*t('uhoo...')*/}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <div className="toWidget" onClick={sendNotificationCloud}>{t('uhoo.widget.formsNotification.actions.send')}</div>
                <div className="toWidget" onClick={() => setModalNotifyWidget(null)}>{t('uhoo.widget.formsNotification.actions.cancel')}</div>
              </div>
            </div>
          </Box>
        </Modal>
  
        {/* Modal delete widget */}
        <Modal open={modalDeleteWidget != null} onClose={() => setModalDeleteWidget(null)} >
          <Box sx={ModalBoxStyle}>
            <div className="modalDeleteAudience">
              <div className="titleModalDeleteAudience">
                <div className="textTitleModalDeleteAudience">{t('uhoo.widget.formsDelete.message.text1')} {t('uhoo.widget.formsDelete.message.text2')}</div>
                <div className="closeModalDeleteAudienceWidget" onClick={() => setModalDeleteWidget(null)} role="button"><Close/></div>
              </div>
              
              <hr className="lines"/>
  
              <div className="contentModalDeleteAudience" >
                <p className="textDescriptionDeleteAudience">{t('uhoo.widget.formsDelete.message.text1')} "{_get(modalDeleteWidget, 'name', '')}" {t('uhoo.widget.formsDelete.message.text2')}</p>
              </div>
              <div className="actionContentModalDeleteAudience">
                <div onClick={() => setModalDeleteWidget(null)} role="button">
                  <SecondaryButton txt={t('uhoo.globalWords.cancel')} dark/>
                </div>
                <div onClick={() => deleteWidget()} role="button">
                  <SecondaryButton txt={t('uhoo.globalWords.delete')} danger />
                </div>
              </div>            
            </div>
          </Box>
        </Modal>

        {/* Notifications */}

        {/* Success */}
        <Modal open={showNotificationSuccessfull} onClose={() => setShowNotificationSuccessfull(false)} >
          <Box sx={ModalBoxStyle}>
            <div className="ModalForeground" id="SystemNotification">
              <div className="systemSuccess systemNotification2">
                <div className="icoNotification"><Success /></div>
                <div className="notificationTitle">{t('uhoo.systemNotification.success')}</div>
                <div className="notificationCaption">{showNotificationMessage}</div>
                <div className="closeNotification pointerCursor" onClick={() => setShowNotificationSuccessfull(false)}><CloseNotification /></div>
              </div>
            </div>
          </Box>
        </Modal>

        {/* Warning */}
        <Modal open={showNotificationWarning} onClose={() => setShowNotificationWarning(false)} >
          <Box sx={ModalBoxStyle}>
            <div className="ModalForeground" id="SystemNotification">
              <div className="systemWarning systemNotification2">
                <div className="icoNotification"><Warning /></div>
                <div className="notificationTitle">{t('uhoo.systemNotification.warning')}</div>
                <div className="notificationCaption">{showNotificationMessage}</div>
                <div className="closeNotification pointerCursor" onClick={() => setShowNotificationWarning(false)}><CloseNotification /></div>
              </div>
            </div>
          </Box>
        </Modal>

        {/* Error */}
        <Modal open={showNotificatioError} onClose={() => setShowNotificatioError(false)} >
          <Box sx={ModalBoxStyle}>
            <div className="ModalForeground" id="SystemNotification">
              <div className="systemError systemNotification2">
                <div className="icoNotification"><Error /></div>
                <div className="notificationTitle">{t('uhoo.systemNotification.error')}</div>
                <div className="notificationCaption">{showNotificationMessage}</div>
                <div className="closeNotification pointerCursor" onClick={() => setShowNotificatioError(false)}><CloseNotification /></div>
              </div>
            </div>
          </Box>
        </Modal>


        {!!showNotificationCheckAudiences && (
          <SystemNotification
            setShowNotification={setShowNotificationCheckAudiences}
            type="Warning"
            caption={t('basicWords.mustSelectAudiences')} 
          />
        )}

        {!!showSuccess && (
          <SystemNotification
            setShowNotification={(e) => setShowSuccess}
            type="Success"
            caption={t("basicWords.copiedLink")}
          />
        )}

        {openModalCantCreate ? (
        <ModalUhoo>
          <CantCreate
            setOpenModal={setOpenModalCantCreate}
            text={textModalCantCreate}
          />
        </ModalUhoo>
      ) : null}
      </React.Fragment>
    );
  };

export default WidgetList;
