import React, { useState, useEffect } from "react";
import "./css/fromLibrary.css";
import "../../community/components/css/deleteUser.css";
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import { useTranslation } from 'react-i18next';

function AddLink( Props ) {
    const { t, i18n } = useTranslation(); //  Localization
    const [link, setLink] = useState(false);

    function closeModal() {
        Props.setOpenModalLink(false);
    }

    const addLink = () => {
        Props.onAddLink(link);
        Props.setOpenModalLink(false);
    }

    return(
        <React.Fragment>
            <div className="modalDeleteUser">
                <div className="titleModalDeleteUser">
                    <div className="textTitleModalDeleteUser">{t('basicWords.linkToAdd')}</div>
                    <div className="closeModalDeleteUser" onClick={() => closeModal()} role="button"><Close /></div>
                </div>

                <hr className="lines" />

                <div className="addLink">
                    <input type="text" placeholder={t('basicWords.linkToAdd')} className="inputForms" id="titlePost"
                        onChange={e => setLink(e.currentTarget.value)}
                    /> {/*t('uhoo...')*/} 
                </div>
                <div className="actionContentModalDeleteUser">
                    <div role="button" onClick={() => closeModal()}>
                        <SecondaryButton txt={t('uhoo.globalWords.cancel')} dark />
                    </div>
                    <div role="button" onClick={() => addLink()}>
                        <SecondaryButton txt={t('basicWords.add')} danger />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AddLink;