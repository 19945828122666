import React, { useState, useEffect, useContext } from "react";

import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';

import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";

import "./css/deleteUser.css";

//Context
import { UsersCommunityProvider, UsersCommunityContext } from '../contex';

function DeleteUser( props ) {
    console.log(props)

    const { RolData } = useContext(UsersCommunityContext);

    let open = props.setOpenModalCommunityDelete;
    let user = props.settingUserValue[0];

    let avatar;
    if ( user.avatar === null ) { avatar = user.name.charAt(0).toUpperCase(); } else { avatar = user.avatar.thumbnail }

    let email = (user.emails.filter(data => data.uuid.includes(user.email)))[0].email;
    
    console.log(user);
    
    function closeModal() { open(false); }

    return(
        <UsersCommunityProvider>
        <div className="modalDeleteUser">
            <div className="titleModalDeleteUser">
                <div className="textTitleModalDeleteUser">¿Está seguro?</div>
                <div className="closeModalDeleteUser" onClick={closeModal} role="button"><Close/></div>
            </div>
            
            <hr className="lines"/>

            <div className="contentModalDeleteUser">
                {/*texto*/}
            </div>
            <div className="actionContentModalDeleteUser">
                <div onClick={closeModal} role="button">
                    <SecondaryButton txt="Cancelar" disabled={true} onClick={closeModal}/>
                </div>
                <SecondaryButton txt="Eliminar" danger/>
            </div>            
        </div>
        </UsersCommunityProvider>
    );
}

export default DeleteUser;
