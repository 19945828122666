import axios from 'axios';

import { host, port, route, datasource } from '../../reactUtils/systemVariables/CmsServerInformation';

const resource = "/lpsectionlayoutstyle";

export async function updateLpSectionLayout(lpSectionId, lpSectionLayoutId) {
    try {
       let response = await axios({
          method: 'PUT',
          url: host + port + route + datasource + resource,
          params: {
            lp_sectionid: lpSectionId,
            lpsectionlayoutstyle_id: lpSectionLayoutId,
          },
          headers: {
             'Content-Type': 'application/json',
             'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
          }
       })
       return response;
    } catch (error) {
       return error;
    }
 }