import React from "react";
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import { useTranslation } from 'react-i18next';
import { get as _get } from "lodash";
import "../../community/components/css/deleteUser.css";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function LinearProgressModal(props) {
    const { t, i18n } = useTranslation(); //  Localization
    let open = props.setOpenModal;

    function LinearProgressWithLabel(props) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" className="linearProgess" >{`${Math.round(
                props.value,
              )}%`}</Typography>
            </Box>
          </Box>
        );
    }
    

    return (
        <React.Fragment>
            <div className="modalContainerLinearProgress">
                <div className="closeModalCantCreate" onClick={() => open(false)} role="button"><Close /></div>
                <h1>{props.progress == 100 ? t('basicWords.postSuccesfullyUploaded') : t('basicWords.startingUploadYourPost')}</h1>
                <div className="closeModalCantCreate" onClick={() => open(false)} role="button"><Close /></div>
                <Box sx={{ width: '100%' }}>
                    <LinearProgressWithLabel value={props.progress} />
                </Box> 
            </div>
        </React.Fragment>
    );
}

export default LinearProgressModal;