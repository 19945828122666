import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AlertCircle, Eye, Video } from "react-feather";
import { get as _get } from "lodash";
import { ReactComponent as BackArrow } from '../../assets/icons/navigation/backArrow.svg';
import {
  Divider,
  Card,
  Grid,
  Button,
  Tooltip,
  TextField,
} from "@material-ui/core";

//Components
import {
  ButtonComponent,
  Breadcrumb,
  BreadcrumbSection,
  NotificationModal,
  ButtonsFooterComponent,
  GeneralModal,
} from "../../aura-components";

//Localization
import { withTranslation } from "react-i18next";
//Queries
import {
  getLandingPageSections,
  getLandingpageStrucureById,
  updateStructuresPositions,
  updateLpSubpageGroupByLpId,
  updateLpSubpageByLpId,
  createLpSubpageByLpId,
  disableSection,
  createNewLpSectionBySectionIdAndLpId,
} from "../../actions/aura";

import "../../aura-components/css/Button.scss";

import AddNewSubpageModal from "./AddNewSubpageModal.jsx";
import HorizontalDragDrop from "./HorizontalDragDrop.jsx";
import "./MyWebPage.scss";
import SystemNotification from "../global/SystemNotification.jsx";
let tableSize = 64 + 70 + 15 + 20 + 62 + 68 + 64 + 24 + 61 + 73;

class MyGroupSubpage extends Component {
  constructor(props) {
    super(props);

    this.disableSectionFunction = this.disableSectionFunction.bind(this);
    this.createLpSubpageByLpId = this.createLpSubpageByLpId.bind(this);
    this.updateNewSectionComponentFunction =
      this.updateNewSectionComponentFunction.bind(this);

    this.state = {
      height: 0,
      posFooter: null,

      organizationId: null,
      organizatioName: null,
      country: null,
      region: null,
      city: null,
      registers: [],
      sections: null,
      sectionToAdd: null,
      lpThemeName: null,

      loadingInfo: true,
      previousPath: false,
      columnSelected: null,

      //subpage management
      editSubpageModalVisible: false,
      selectedSectionSubpage: null,
      addNewModalVisible: false,

      // allSections: [],
      availableSections: [],

      //Themes
      themesModalVisible: false,

      //pagination
      registersAmount: 0,
      page: 1,
      valueRadioGroup: 0,
      indexInitialData: null,
      typeMove: [],
      sourceDataMoved: [],
      destinationDataMoved: [],
      dataVerticalMoved: [],
      flagMove: false,
      flagTitle: false,
      subpageMoved: null,
      goToWithoutSaveModalVisible: false,
      FlagclassName: false,

      //Modals
      openDialog: false,
      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",
      openModalToChangeName: false,
      // Modals
      showSuccess: false,
      showWarning: false,
      showError: false,
      contentNotificationModal: "",
    };
  }

  componentDidMount() {
    const organizationId = sessionStorage.getItem("organization_id");
    //If organization is Aura, get landing page with landing page id previos selected
    const { props } = this.props.location;
    if (parseInt(organizationId, 10) === 1) {
      if (props) {
        this.getLandingPageInfoFunction(props.organizationId);
        this.setState({
          previousPath: props.previousPath ? true : false,
          organizationId: props.organizationId,
          lpId: props.landingPageId,
          organizationName: props.organizationName,
          sectionName: props.sectionName,
          groupId: props.groupId,
          path: props.path,
          lpUrl: props.lpUrl,
        });
      } else {
        this.warning();
      }
    } else {
      try {
        this.getLandingPageInfoFunction(props.organizationId);
        this.setState({
          organizationId: props.organizationId,
          lpId:props.landingPageId,
          sectionName: props.sectionName,
          groupId: props.groupId,
          path: props.path,
          lpUrl: props.lpUrl,
        }); 
      } catch (error) {
        this.props.history.push("/manage/web")
      }
      
    }
  }

  componentWillMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  //Queries
  async getLandingPageInfoFunction(organizationId, lpUrl, flagNotState) {
    let sections = [];
    let subpagesToShow = [];
    const { t } = this.props;
    let response = await getLandingPageSections(organizationId);
    let responseDTO = await getLandingpageStrucureById(_get(response, "data.data.landingPageId", 1));
    if (String(responseDTO).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
      this.setState({ openNotificationModal: true });
    } else {
      let subpages = responseDTO.data.data.lpSubpages;
      let groupSubpages = responseDTO.data.data.lpSubpageGroups;

      subpages.forEach((subpage) => {
        //If subpage is not into group subpage
        if (subpage.isEnabled === 1) {
          subpagesToShow.push(subpage);
          let subpageExists = [];
          subpageExists = subpagesToShow.find((item) => item.id === subpage.id);
          if (!subpage.sections) {
            subpage.sections = [];
          }
          if (subpageExists && subpageExists.length === 0) {
            subpagesToShow.push(subpage);
          }
        }
      });

      groupSubpages.forEach((group, index) => {
        //Find subpage into group subpages
        var sectionsGroupSubpages = [];
        subpages.forEach((subpage) => {
          if (
            subpage.subpageGroupId != null &&
            subpage.subpageGroupId === group.id &&
            subpage.isEnabled === 1
          ) {
            subpage.positionInHeader = null;
            sectionsGroupSubpages[sectionsGroupSubpages.length] = subpage;
            groupSubpages[index].sections = sectionsGroupSubpages;
          }
        });
      });

      responseDTO.data.data.lpsections.forEach((section, indexSection) => {
        //Find sections in mainpage
        if (section.mustBeSubpage !== 1 && section.subpages.length === 0) {
          sections.push(section);
        } else {
          if (section.subpages.length > 0) {
            //   //If have a subpage asociated
            subpagesToShow.forEach((subpage, index) => {
              section.subpages.forEach((idAsociatedSection, index2) => {
                let sectionSubpages = [];
                if (subpage.id === idAsociatedSection) {
                  sectionSubpages.push(section);
                }
                if (sectionSubpages.length > 0) {
                  if (section.positionsInSubpage === null) {
                  } else {
                    section.positionsInSubpage =
                      section.positionsInSubpage[index2];
                  }
                  subpagesToShow[index].sections[
                    subpagesToShow[index].sections.length
                  ] = section;
                }
              });
            });
          }
        }
      });

      var positionSections = sections.map(
        (element) => element.positionInMainPage
      );
      const lastSectionPosition = Math.max.apply(null, positionSections);
      sections = sections.sort(compare);
      let ref = null;
      let indexRef = [];
      sections.forEach((element, index) => {
        sections[index].positionInMainPage = index;
        sections[index].ref = index;
        ref = index;
        indexRef.push(ref);
      });

      function compare(a, b) {
        if (a.positionInMainPage === null) {
          a.positionInMainPage = lastSectionPosition + 2;
        }
        if (a.name === "FOOTER") {
          a.positionInMainPage = lastSectionPosition + 2;
        }
        if (b.name === "FOOTER") {
          b.positionInMainPage = lastSectionPosition + 2;
        }
        if (a.name === "CONTACT") {
          a.positionInMainPage = lastSectionPosition + 1;
        }
        if (b.name === "CONTACT") {
          b.positionInMainPage = lastSectionPosition + 1;
        }
        if (a.positionInMainPage > b.positionInMainPage) {
          return 1;
        }
        if (b.positionInMainPage > a.positionInMainPage) {
          return -1;
        }
        return 0;
      }

      function compareSubpageAndGroupSubpage(a, b) {
        if (a.positionInHeader > b.positionInHeader) {
          return 1;
        }
        if (b.positionInHeader > a.positionInHeader) {
          return -1;
        }
        return 0;
      }

      function compareSubapageInGroupSubpage(a, b) {
        if (a.positionInSubpageGroup > b.positionInSubpageGroup) {
          return 1;
        }
        if (b.positionInSubpageGroup > a.positionInSubpageGroup) {
          return -1;
        }
        return 0;
      }

      function compareSectionsInSubpage(a, b) {
        if (a.positionsInSubpage > b.positionsInSubpage) {
          return 1;
        }
        if (b.positionsInSubpage > a.positionsInSubpage) {
          return -1;
        }
        return 0;
      }

      subpagesToShow = subpagesToShow.sort(compareSubpageAndGroupSubpage);
      subpagesToShow.forEach((element, index) => {
        //add subpages enable
        if (!subpagesToShow[index].sections) {
          subpagesToShow[index].sections = [];
        }
        subpagesToShow[index].sections.forEach((element, auxRef) => {
          subpagesToShow[index].sections[auxRef].ref = ref + auxRef;
          indexRef.push(subpagesToShow[index].sections[auxRef].ref);
        });
        subpagesToShow[index].style = "subpage";
        if (subpagesToShow[index].sections) {
          subpagesToShow[index].sections = subpagesToShow[index].sections.sort(
            compareSectionsInSubpage
          );
        } else {
          groupSubpages[index].sections = [];
        }
        ref = ref + 1;
      });

      let groupSubpageToShow = [];
      groupSubpages = groupSubpages.sort(compareSubpageAndGroupSubpage);
      groupSubpages.forEach((element, index) => {
        //add group subpages enable
        if (element.isEnabled === 1) {
          groupSubpages[index].style = "groupSubpage";
          if (groupSubpages[index].sections) {
            groupSubpages[index].sections = groupSubpages[index].sections.sort(
              compareSubapageInGroupSubpage
            );
          } else {
            groupSubpages[index].sections = [];
          }
          if (
            element.id ===
            parseInt(this.state.groupId.substring(7, this.state.groupId.length))
          ) {
            groupSubpageToShow = element;
          }
        }
      });

      if (groupSubpageToShow.sections) {
        groupSubpageToShow.sections = groupSubpageToShow.sections.sort(
          compareSubapageInGroupSubpage
        );
      } else {
        groupSubpageToShow.sections = [];
      }

      let structureSections = {};
      let sectionsIds = [];
      let columns = {};
      let columnOrder = [];

      sections.forEach((element, index) => {
        let aux = index;
        index = element.id;
        structureSections[index] = element;
        sectionsIds[aux] = index;
      });

      let lengthColumns = 1;

      groupSubpageToShow.sections.forEach((data, indexColumns) => {
        sectionsIds = [];
        indexColumns = "column-" + data.id;
        data.sections.forEach((section, index) => {
          let auxSection = index;
          index = section.id;
          structureSections[index] = section;
          sectionsIds[auxSection] = index;
        });
        if (data.style === "subpage") {
          columns[indexColumns] = {
            style: "subpage",
            isEnabled: data.isEnabled,
            isVisible: data.isVisible,
            sections: data.sections,
            id: indexColumns,
            sectionsIds: sectionsIds,
            name: data.name,
            positionInHeader: lengthColumns,
          };
        }
        if (data.style === "groupSubpage") {
          columns[indexColumns] = {
            style: "groupSubpage",
            id: indexColumns,
            sectionsIds: sectionsIds,
            name: data.name,
            isEnabled: data.isEnabled,
            sections: data.sections,
            positionInHeader: lengthColumns,
          };
        }

        columnOrder[lengthColumns] = indexColumns;
        lengthColumns = lengthColumns + 1;
      });

      groupSubpages.forEach((element, index) => {
        //add group subpages enable
        if (element.isEnabled === 1) {
          groupSubpages[index].style = "groupSubpage";
          if (
            element.id ===
            parseInt(this.state.groupId.substring(7, this.state.groupId.length))
          ) {
            sectionsIds = [];
            element.sections.forEach((section, index) => {
              let auxSection = index;
              index = section.id;
              structureSections[index] = section;
              sectionsIds[auxSection] = index;
            });
            columns[this.state.groupId] = {
              style: "groupSubpage",
              id: this.state.groupId,
              sectionsIds: sectionsIds,
              name: element.name,
              isEnabled: element.isEnabled,
              sections: element.sections,
              positionInHeader: lengthColumns,
            };
            lengthColumns = 0;
            // groupSubpageToShow.push(element)
          }
        }
      });

      columnOrder[lengthColumns] = this.state.groupId;

      if (groupSubpageToShow.sections.length === 0) {
        groupSubpageToShow.sections.push({});
      }

      if (flagNotState) {
        this.getLandingPageInfoFunction.bind(organizationId);

        window.location.href = "./manage/web";
        window.location.reload(true);
      } else {
        this.setState({
          organizationId: organizationId,
          organizationName: response.data.data.organizationName,
          lpId: response.data.data.landingPageId,
          country: response.data.data.country,
          region: response.data.data.region,
          city: response.data.data.city,
          loadingInfo: false,
          lpThemeName: response.data.data.lpThemeName,
          lpThemeId: response.data.data.lpThemeId,
          groupSubpage: groupSubpageToShow,
          registers: groupSubpageToShow.sections,
          initialData: groupSubpageToShow.sections,
          columnOrder,
          structureSections,
          columns,
        });
      }
    }
  }

  async createLpSubpageOrGroupSubpage(lpId, name, groupId) {
    let registers = this.state.registers;
    let subpageDTO = {
      name: name,
      id: null,
      positionInHeader: null,
      positionInSubpageGroup: registers.length,
      subpageGroupId: groupId,
      displayType: 0,
      isVisible: 1,
      isEnabled: 1,
    };
    var response = await createLpSubpageByLpId(lpId, subpageDTO);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
      this.setState({ openNotificationModal: true });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
      this.setState({ openNotificationModal: true });
      registers[registers.length] = subpageDTO;
      // registers[registers.length] = {
      //   style: "add",
      //   id: 1000,
      //   sections: null,
      //   name: "Add",
      //   positionInHeader: registers.length,
      // };
      this.setState({ registers });
      this.getLandingPageInfoFunction(this.state.organizationId);
    }
  }

  async updateLpSectionPositionFunction(dataMySite) {
    const {
      typeMove,
      sourceDataMoved,
      destinationDataMoved,
      subpageMoved,
      groupSubpage,
    } = this.state;
    let newposition = [];
    let flag = 0;
    typeMove.forEach((move, index) => {
      if (move === "vertical") {
        var type = 2; //Have only sections into
        var level = 2;
        for (var i = 0; i < subpageMoved.sections.length; i++) {
          newposition[newposition.length] = {
            level: level,
            parentId: subpageMoved.id,
            type: type,
            position: newposition.length,
            id: subpageMoved.sections[i].id,
          };
        }
        flag = 1;
      }
      if (move === "horizontal") {
        dataMySite.forEach((element, index) => {
          newposition[newposition.length] = {
            level: 1,
            parentId: groupSubpage.id,
            type: 1,
            position: newposition.length,
            id: element.id,
          };
        });
        flag = 1;
      }
      if (move === "anotherList") {
        var type = 2; //Have only sections into
        var level = 2;
        for (var i = 0; i < sourceDataMoved.sections.length; i++) {
          newposition[newposition.length] = {
            level: level,
            parentId: groupSubpage.id,
            type: type,
            position: newposition.length,
            id: sourceDataMoved.sections[i].id,
          };
        }
        for (var i = 0; i < destinationDataMoved.sections.length; i++) {
          newposition[newposition.length] = {
            level: level,
            parentId: destinationDataMoved.id,
            type: type,
            position: newposition.length,
            id: destinationDataMoved.sections[i].id,
          };
        }
        flag = 1;
      }
    });
    let newTypeMove = [];
    const { t } = this.props;
    this.setState({ typeMove: newTypeMove });
    if (flag === 1) {
      let response = await updateStructuresPositions(newposition);
      if (String(response).includes("Error:")) {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
        });
      } else {
        this.setState({
          showSuccess: true,
          contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
        });
        this.getLandingPageInfoFunction(this.state.organizationId);
      }
      this.setState({
        isLoadingButton: false,
        openNotificationModal: true,
      });
    }
  }

  async updateLpSubpageGroupByLpId(LpSubpageGroupDto) {
    let response = await updateLpSubpageGroupByLpId(LpSubpageGroupDto);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
    }
    this.setState({
      openNotificationModal: true,
      openModalToChangeName: false,
    });
  }

  async updateLpSubpageByLpId(LpSubpageDto) {
    let response = await updateLpSubpageByLpId(LpSubpageDto);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
    }
    this.setState({
      openNotificationModal: true,
      openModalToChangeName: false,
    });
  }

  async updateNewSectionComponentFunction(sectionId, lpId, subpageId) {
    let subpage_id = subpageId !== undefined ? subpageId : "";
    let response = await createNewLpSectionBySectionIdAndLpId(
      sectionId,
      lpId,
      subpage_id,
      sectionId //standard layoutid == sectionId
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      if (String(response).includes("Error:")) {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
        });
      } else {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.itemSuccesfullyCreated"),
        });
      }

      this.setState({ openNotificationModal: true });
      // this.setState({open: true});
    }
    this.getLandingPageInfoFunction(this.state.organizationId, this.state.lpUrl); //Add info sections

  }

  async createLpSubpageByLpId(lpId, name, groupId) {
    let registers = this.state.registers;
    let subpageDTO = {
      name: name,
      id: null,
      positionInHeader: null,
      positionInSubpageGroup: registers.length,
      subpageGroupId: parseInt(groupId.substring(7, groupId.length)),
      displayType: 0,
      isVisible: 1,
      isEnabled: 1,
    };
    var response = await createLpSubpageByLpId(lpId, subpageDTO);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      if (String(response).includes("Error:")) {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
        });
      } else {
        this.setState({
          showSuccess: true,
          contentNotificationModal: t("basicWords.modal.itemSuccessfullyCreated"),
        })
      }
      registers[registers.length] = subpageDTO;
      this.setState({ openNotificationModal: true, registers });
      this.getLandingPageInfoFunction(this.state.organizationId, null);
    }
  }

  async disableSectionFunction(LpSectionPutDto, lpsubpage_id) {
    this.setState({
      loadingInfo: true,
    });
    let response = await disableSection(LpSectionPutDto.id, lpsubpage_id);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.itemSuccessfullyCreated"),
      });
      this.getLandingPageInfoFunction(this.state.organizationId, this.state.lpUrl);
    }
    
    this.setState({
      loadingInfo: false,
      isLoadingButton: false,
      openNotificationModal: true,
    });
  }

  ongroupSubpageOrSubPageDelete = (id) => {
    let organizationId = this.state.organizationId;
    let registers = this.state.registers;
    let subpageOrgroupSubpageToUpdate = null;
    registers.forEach((element, index) => {
      if (element.name === id.name) {
        registers[index].isEnabled = 0;
        subpageOrgroupSubpageToUpdate = registers[index];
      }
    });
    this.updateLpSubpageByLpId(subpageOrgroupSubpageToUpdate);
    this.getLandingPageInfoFunction(organizationId);
    this.setState({
      openDialog: false,
      openNotificationModal: true,
    });
  };

  addNewSubpage = () => {
    const { t } = this.props;
    if (this.state.initialData.length > 8) {
      this.setState({
        titleNotificationModal: "Info header",
        contenNotificationModal: t("basicWords.cantMoreEigthSections"),
        typeNotificationModal: "warning",
      });
      this.setState({  openNotificationModal: true, });
      return;
    } else {
      this.setState({ addNewSubpageModalVisible: true, });
    }
    this.setState({
      addNewSubpageModalVisible: true,
    });
    this.getLandingPageInfoFunction(this.state.organizationId);
  };

  OnchangeIsVisible = (data) => {
    let organizationId = this.state.organizationId;
    let registers = this.state.registers;
    let subpageOrgroupSubpageToUpdate = null;
    let id = data.id;
    id = id.substring(7, id.length);
    id = parseInt(id);
    registers.forEach((element, index) => {
      if (element.id === id) {
        if (element.isVisible === 1) {
          registers[index].isVisible = 0;
        } else {
          registers[index].isVisible = 1;
        }
        subpageOrgroupSubpageToUpdate = registers[index];
      }
    });
    this.updateLpSubpageByLpId(subpageOrgroupSubpageToUpdate);
    this.getLandingPageInfoFunction(organizationId);
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      showWarning: true,
      contentNotificationModal: t("basicWords.modal.noSectionSelected"),
    });
    this.handleOkWarning();
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  SavePositionSection = () => {
    let {
      registers,
      groupSubpage,
      sectionName,
      flagMove,
      flagTitle,
      columnOrder,
    } = this.state;
    groupSubpage.name = sectionName;
    groupSubpage.sections = registers.slice(0, registers.length);
    columnOrder = columnOrder.slice(0, columnOrder.length);
    let orderColumns = [];
    columnOrder.forEach((element) => {
      if (element !== undefined) {
        orderColumns[orderColumns.length] = parseInt(
          element.substring(7, element.length)
        );
      }
    });
    let dataMySite = [];
    orderColumns.forEach((orderColumn) => {
      //reorder groupSubpage
      registers.forEach((data) => {
        if (data.id === orderColumn) {
          dataMySite[dataMySite.length] = data;
        }
      });
    });
    if (flagMove === true) {
      this.updateLpSectionPositionFunction(dataMySite);
    }
    if (flagTitle === true) {
      this.updateLpSubpageGroupByLpId(groupSubpage);
    }
    this.setState({ flagMove: false, flagTitle: false, FlagclassName: false });
  };

  AddNewSubpage = () => {
    this.setState({
      addNewSubpageModalVisible: true,
      nameNewSubpage: "",
    });
  };

  onCloseAddNewSubpageModal = () => {
    this.setState({
      addNewSubpageModalVisible: false,
      nameNewSubpage: "",
    });
  };

  onSaveAddNewSubpageModal = () => {
    const { lpId, groupSubpage, nameNewSubpage } = this.state;
    this.setState({
      isLoadingButton: true,
      addNewSubpageModalVisible: false,
    });
    this.createLpSubpageOrGroupSubpage(lpId, nameNewSubpage, groupSubpage.id);
  };

  OnChangeInput = (event) => {
    const { id, value } = event.target;
    this.setState({
      nameNewSubpage: value,
    });
  };

  getIDRegisters = () => {
    const { registers } = this.state;
    var Ids = [];
    registers.forEach((element) => {
      Ids.push(element.id);
    });
    return Ids;
  };

  getIdNewSubpage = (idRegisters) => {
    let flag = 1;
    let newId = Math.round(Math.random() * 100);
    idRegisters.forEach((element) => {
      if (element.id === newId) {
        flag = 0;
      }
    });
    if (flag === 1) {
      return newId;
    } else {
      this.getIdNewSubpage(idRegisters);
    }
  };

  handleChange = (event) => {
    const { id, value } = event.target;

    this.setState({
      sectionName: value,
      flagTitle: true,
      FlagclassName: true,
    });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result, provided) => {
    const { destination, source, draggableId, type } = result;
    var typeMove = this.state.typeMove;
    var initialData = this.state.registers;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "horizontal") {
      if (
        this.state.columnOrder[source.index] === "column-10001" ||
        this.state.columnOrder[destination.index] === "column-10001"
      ) {
        // notification["warning"]({
        //   message: t('basicWords.movementNotAllowedMainpage'),
        //   // description: t('basicWords.mainpageAtTheTop'),
        //   placement: "bottomRight",
        // });
        return;
      }
      if (
        this.state.columnOrder[destination.index] === "column-1000" ||
        this.state.columnOrder[source.index] === "column-1000"
      ) {
        // notification["warning"]({
        //   message: t('basicWords.movementNotAllowedAdd'),
        //   // description: t('basicWords.mainpageAtTheTop'),
        //   placement: "bottomRight",
        // });
        return;
      }
      const newColumnOrder = Array.from(this.state.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...this.state,
        columnOrder: newColumnOrder,
      };
      this.setState(newState);
      typeMove.push(type);
      this.setState({ typeMove, flagMove: true, FlagclassName: true });
      this.forceUpdate();
      return;
    }

    const home = this.state.columns[source.droppableId];
    const foreign = this.state.columns[destination.droppableId];

    if (home === foreign) {
      //if move is vertical
      const newSectionsIds = Array.from(home.sectionsIds);
      newSectionsIds.splice(source.index, 1);
      newSectionsIds.splice(destination.index, 0, parseInt(draggableId));

      const newHome = {
        ...home,
        sectionsIds: newSectionsIds,
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newHome.id]: newHome,
        },
      };
      var dataTarjet = initialData.find(
        (item) =>
          item.id ===
          parseInt(source.droppableId.substring(7, source.droppableId.length))
      );

      var indexInitialDataChange = null;
      initialData.forEach((element, index) => {
        if (
          element.id ===
          parseInt(source.droppableId.substring(7, source.droppableId.length))
        ) {
          indexInitialDataChange = index;
        }
      });

      var sourceData = dataTarjet.sections[source.index];
      var destinationData = dataTarjet.sections[destination.index];
      if (
        sourceData.name === "HEADER" ||
        destinationData.name === "HEADER" ||
        sourceData.name === "HOME" ||
        destinationData.name === "HOME" ||
        sourceData.name === "FOOTER" ||
        destinationData.name === "FOOTER" ||
        sourceData.name === "CONTACT" ||
        destinationData.name === "CONTACT"
      ) {
        // notification["warning"]({
        //   message: t('basicWords.movementNotAllowed'),
        //   description: t('basicWords.sectionsStructureMainPage'),
        //   placement: "bottomRight",
        // });
        return;
      }
      typeMove.push(type);
      var items = this.reorder(
        dataTarjet.sections,
        source.index,
        destination.index
      );
      items.forEach((item, index) => {
        items[index].positionInSubpageGroup = index;
      });
      initialData[indexInitialDataChange].sections = items;
      this.setState(newState);
      this.setState({
        typeMove,
        // indexInitialData,
        initialData,
        indexInitialDataChange,
        subpageMoved: initialData[indexInitialDataChange],
        flagMove: true,
        FlagclassName: true,
      });
      this.forceUpdate();
      return;
    }

    // moving from one list to another
    const homeSectionsId = Array.from(home.sectionsIds);
    homeSectionsId.splice(source.index, 1);
    const newHome = {
      ...home,
      sectionsIds: homeSectionsId,
    };

    const foreignSectionsIds = Array.from(foreign.sectionsIds);
    foreignSectionsIds.splice(destination.index, 0, parseInt(draggableId));
    const newForeign = {
      ...foreign,
      sectionsIds: foreignSectionsIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newHome.id]: newHome,
        [newForeign.id]: newForeign,
      },
    };

    const [sourceGroup] = initialData.filter(
      (item) =>
        item.id ===
        parseInt(source.droppableId.substring(7, source.droppableId.length))
    );

    const [destinationGroup] = destination
      ? initialData.filter(
          (item) =>
            item.id ===
            parseInt(
              destination.droppableId.substring(
                7,
                destination.droppableId.length
              )
            )
        )
      : { ...sourceGroup };

    if (
      (sourceGroup.style === "mainpage" || sourceGroup.style === "subpage") &&
      destinationGroup.style === "groupSubpage"
    ) {
      // notification["warning"]({
      //   message: t('basicWords.movementNotAllowed'),
      //   description: t('basicWords.sectionsCanOnlyInMainPageOrSubpage'),
      //   placement: "topRigth",
      // });
      return;
    }
    if (
      sourceGroup.style === "groupSubpage" &&
      (destinationGroup.style === "mainpage" ||
        destinationGroup.style === "subpage")
    ) {
      // notification["warning"]({
      //   message: t('basicWords.movementNotAllowed'),
      //   description: t('basicWords.subpagesCanOnlyInGroupSubpages'),
      //   placement: "topRigth",
      // });
      return;
    }

    const movingSection = sourceGroup.sections[source.index];
    if (movingSection.mustBeSubpage === 1) {
      // notification["warning"]({
      //   message: t('basicWords.movementNotAllowed'),
      //   description: t('basicWords.sectionMustBeInSubpage'),
      //   placement: "topRigth",
      // });
      return;
    }

    if (
      movingSection.name === "HEADER" ||
      movingSection.name === "HOME" ||
      movingSection.name === "CONTACT" ||
      movingSection.name === "FOOTER"
    ) {
      // notification["warning"]({
      //   message: t('basicWords.actionNotAllowed'),
      //   description: t('basicWords.cantMoveSectionsOfMainpage'),
      //   placement: "topRight",
      // });
      return;
    }

    movingSection.positionInMainPage = null;
    movingSection.positionsInSubpage = [];
    movingSection.positionsInSubpage[movingSection.positionsInSubpage.length] =
      null;

    if (!movingSection.subpages) {
      movingSection.subpages = [];
      movingSection.subpages[movingSection.subpages.length] =
        destinationGroup.id;
    } else {
      movingSection.subpages[movingSection.subpages.length] =
        destinationGroup.id;
    }
    sourceGroup.sections.forEach((element, index) => {
      sourceGroup.sections[index].positionsInSubpage = [index];
    });
    const newSourceGroup = sourceGroup.sections.splice(source.index, 1);
    sourceGroup.sections.forEach((item, index) => {
      sourceGroup.sections[index].positionInMainPage = index;
    });

    typeMove.push("anotherList");
    this.setState(newState);
    this.setState({
      typeMove,
      initialData,
      sourceDataMoved: sourceGroup,
      dataMoved: newSourceGroup[0],
      destinationDataMoved: destinationGroup,
      FlagclassName: true,
    });
    this.forceUpdate();
  };

  handleClickOpenDialog = (data) => {
    this.setState({ openDialog: true, columnSelected: data });
  };

  handleCloseDialog = (data) => {
    this.setState({ openDialog: false });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  saveChangeName = () => {
    if (this.state.columnToEditName.style === "subpage") {
      this.updateLpSubpageByLpId(this.state.columnToEditName);
    }
    if (this.state.columnToEditName.style === "groupSubpage") {
      this.updateLpSubpageGroupByLpId(this.state.columnToEditName);
    }
  };

  handleClickOpenModalToChangeName = (itemToChangeName) => {
    let open = this.state.openModalToChangeName;
    this.setState({
      openModalToChangeName: !open,
      columnToEditName: itemToChangeName,
    });
  };

  OnChangeNameSubpageOrGroupsubpage = (e) => {
    let columnToEditName = this.state.columnToEditName;
    if (columnToEditName.id.length > 7) {
      columnToEditName.id = parseInt(
        columnToEditName.id.substring(7, columnToEditName.id.length)
      );
    }
    columnToEditName.name = e.target.value;

    this.setState({
      columnToEditName,
      FlagclassName: true,
    });
  };

  setShowError = () => {
    this.setState({ showError: false });
  }

  setShowSuccess = () => {
    this.setState({ showSuccess: false });
  }

  setShowWarning = () => {
    this.setState({ showWarning: false });
  }

  //render
  render() {
    const {
      organizationId,
      organizationName,
      registers,
      addNewSubpageModalVisible,
      nameNewSubpage,
      sectionName,
      FlagclassName,
      lpId,
      lpUrl,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
      contentNotificationModal,
      showWarning,
      showSuccess,
      showError
    } = this.state;
    const { t } = this.props;

    if (this.state.redirectBack) {
      return <Redirect to="./manage/web" />;
    }

    return (
      <React.Fragment>
        {!!showWarning && (
          <SystemNotification
            setShowNotification={(e) => this.setShowWarning(e)}
            type="Warning"
            caption={contentNotificationModal}
          />
        )}
        {!!showSuccess && (
          <SystemNotification
            setShowNotification={(e) => this.setShowSuccess(e)}
            type="Success"
            caption={contentNotificationModal}
          />
        )}
        {!!showError && (
          <SystemNotification
            setShowNotification={(e) => this.setShowError(e)}
            type="Error"
            caption={contentNotificationModal}
          />
        )}

        <section className="Title">
          <BackArrow className="ico pointerCursor" onClick={() => this.props.history.goBack()} />
          <h3>Página Web</h3>
        </section>
        <section className="sectionContainer">
          <Card className="card">
            <Fragment>

              <AddNewSubpageModal
                visible={addNewSubpageModalVisible}
                nameNewSubpage={nameNewSubpage}
                handleClose={this.onCloseAddNewSubpageModal}
                handleSave={this.onSaveAddNewSubpageModal}
                OnChangeInput={this.OnChangeInput}
              />

              <GeneralModal
                visible={this.state.openModalToChangeName}
                title={"Cambiar nombre"}
                body={[
                  <div className="col-12" style={{ margin: "5px" }}>
                    <div className="row">
                      <div className="col-md-12 componentSpace">
                        <TextField
                          variant="outlined"
                          style={{ width: "100%", marginLeft: 10 }}
                          label={t("basicWords.nameOfPage")}
                          onChange={(e) =>
                            this.OnChangeNameSubpageOrGroupsubpage(e)
                          }
                          defaultValue={
                            this.state.columnToEditName
                              ? decodeURIComponent(this.state.columnToEditName.name)
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>,
                ]}
                footer={[
                  <div className="row buttonAtTheBottom">
                    <div>
                      <ButtonComponent
                        onClick={(e) => this.handleClickOpenModalToChangeName()}
                        shape="round"
                        text={t("basicWords.cancel")}
                        className="buttonDiscard"
                      />
                    </div>
                    <div>
                      <ButtonComponent
                        isDisabled={false}
                        onClick={() => this.saveChangeName()}
                        shape="round"
                        text={t("basicWords.ok")}
                      />
                    </div>
                  </div>,
                ]}
                onClick={(e) => this.handleClickOpenModalToChangeName()}
              />

              <div>
                {registers.length === 0 ? (
                  <div
                    className="col-xs-12 col-md-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      transform: "translateY(800%)",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div>
                    <div className="row componentSpace">
                      <div className="containerHeader">
                        <div className="breadcrumb">
                          <Breadcrumb
                            principal={organizationName}
                            secondary={t("basicWords.websites")}
                            third={t("basicWords.myWebSite")}
                          />
                        </div>
                        <div className="buttonsHeader">
                          {/*  <div style={{ marginRight: "10px" }}>
                            <ButtonComponent
                              icon={<Video />}
                              shape="round"
                              text={t('basicWords.showHelp')}
                              // onClick={() => this.getMyWebpage()}
                              className="viewMyWebSite"
                            />
                          </div>*/}
                          <div style={{ marginLeft: "10px" }}>
                            <ButtonComponent
                              icon={<Eye />}
                              shape="round"
                              text={t("basicWords.goToMyWebPage")}
                              onClick={() => this.getMyWebpage()}
                              className="viewMyWebSite"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div>
                        <BreadcrumbSection
                          principal={decodeURIComponent(sectionName)}
                          route="./manage/web"
                          propsComponent={{
                            organizationId,
                            sectionName: sectionName,
                            lpId: lpId,
                            lpUrl: lpUrl,
                          }}
                          className="nameGroupSubpage"
                        />
                        <br />
                        <Divider />
                      </div>
                      <div className="row componentSpace adjustPositionYTop">
                        <Grid container>
                          <Grid
                            item
                            style={{
                              alignSelf: "center",
                              width: "17%",
                              textAlign: "left",
                            }}
                          >
                            <Tooltip title={t("generalUser.tooltipGroupsubpage")}>
                              <Button className="tagGroupsubpage">
                                {t("basicWords.groupSubpages")}{" "}
                                <AlertCircle
                                  style={{ paddingLeft: "5%" }}
                                  size={18}
                                />{" "}
                              </Button>
                            </Tooltip>
                          </Grid>
                          <Grid
                            item
                            style={{
                              alignSelf: "center",
                              width: "10%",
                              textAlign: "left",
                            }}
                          >
                            <Tooltip title={t("generalUser.tooltipSubpage")}>
                              <Button
                                className="tagSubpage"
                                style={{ fontSize: "14px !important" }}
                              >
                                {t("basicWords.subpage")}{" "}
                                <AlertCircle
                                  style={{ paddingLeft: "5%" }}
                                  size={18}
                                />{" "}
                              </Button>
                            </Tooltip>
                          </Grid>
                          <Grid
                            item
                            style={{
                              alignSelf: "center",
                              width: "10%",
                              textAlign: "left",
                            }}
                          >
                            <Tooltip title={t("generalUser.tooltipGroupsubpage")}>
                              <Button
                                className="tagSection"
                                style={{ fontSize: "14px !important" }}
                              >
                                {t("basicWords.sections")}{" "}
                                <AlertCircle
                                  style={{ paddingLeft: "5%" }}
                                  size={18}
                                />{" "}
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="row componentSpace">
                        <div>
                          <div className="row componentSpace justify">
                            <DragDropContext
                              onDragStart={this.onDragStart}
                              onDragUpdate={this.onDragUpdate}
                              onDragEnd={this.onDragEnd}
                            >
                              <Droppable
                                droppableId="all-columns"
                                direction="horizontal"
                                type="horizontal"
                              >
                                {(provided) => (
                                  <div className="row" style={{ display: "flex" }}>
                                    <div
                                      className="col-xs-12 col-md-12"
                                      style={{
                                        display: "flex",
                                        width: "min-content",
                                      }}
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {this.state.columnOrder.map(
                                        (columnId, index) => {
                                          const column =
                                            this.state.columns[columnId];
                                          if (column !== undefined) {
                                            const sectionMap =
                                              this.state.structureSections;
                                            const state = this.state;
                                            const structureSections =
                                              column.sectionsIds.map(
                                                (sectionId) => sectionMap[sectionId]
                                              );
                                            return (
                                              <HorizontalDragDrop
                                                column={column}
                                                structureSections={
                                                  structureSections
                                                }
                                                lpUrl={lpUrl}
                                                index={index}
                                                key={index}
                                                state={state}
                                                openDialog={this.state.openDialog}
                                                columnSelected={
                                                  this.state.columnSelected
                                                }
                                                flagTarjet={
                                                  column.style === "subpage"
                                                    ? "subpage"
                                                    : null
                                                }
                                                Issubpage={
                                                  column.style === "subpage"
                                                    ? 1
                                                    : null
                                                }
                                                updateNewSectionComponentFunction={
                                                  this
                                                    .updateNewSectionComponentFunction
                                                }
                                                disableSectionFunction={
                                                  this.disableSectionFunction
                                                }
                                                createLpSubpageByLpId={
                                                  this.createLpSubpageByLpId
                                                }
                                                getLandingPageInfoFunction={
                                                  this.getLandingPageInfoFunction
                                                }
                                                ongroupSubpageOrSubPageDelete={
                                                  this.ongroupSubpageOrSubPageDelete
                                                }
                                                OnchangeIsVisible={
                                                  this.OnchangeIsVisible
                                                }
                                                handleClickOpenDialog={
                                                  this.handleClickOpenDialog
                                                }
                                                handleCloseDialog={
                                                  this.handleCloseDialog
                                                }
                                                changePageOrGroupSubpage={
                                                  this.changePageOrGroupSubpage
                                                }
                                                handleClickOpenModalToChangeName={
                                                  this
                                                    .handleClickOpenModalToChangeName
                                                }
                                                addNewSubpage={this.addNewSubpage}
                                              />
                                            );
                                          }
                                        }
                                      )}
                                      {provided.placeholder}
                                    </div>
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ButtonsFooterComponent
                      propsComponent={{
                        organizationId,
                        sectionName: sectionName,
                        lpId: lpId,
                        lpUrl: lpUrl,
                      }}
                      FlagclassName={FlagclassName}
                      onClick={(e) => this.SavePositionSection(e)}
                    />
                  </div>
                )}
              </div>
            </Fragment>
          </Card>
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    privileges: state.userSession.privileges,
    userSession: state.userSession.userSession,
  };
}

export default connect(mapStateToProps)(withTranslation()(MyGroupSubpage));
