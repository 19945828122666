import axios from 'axios';
import { get as _get } from "lodash";
import { protocol, host, port, route, dataSource } from '../../reactUtils/systemVariables/UhooServerInformation';

const query = "/audience/";

const query2 = "/audience/member/"

export async function getAudience(Params) {
  let URLactual = window.location;
  URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
  let isAura = URLactual.includes("aura");
  let sendParams = "";

  if (Params !== undefined || null){

    if (typeof Params !== "string")
    {
      //sendParams += "?"
      Params.forEach(item => (item.key === 'page') ? sendParams += "page/" + item.value + "?" : sendParams += ""); // Get Post Page --> Si Value no es definido por defecto va a ser 10
      Params = Params.filter((item) => item.key !== 'page');

      Params.forEach(item => (item.key === 'size') ? sendParams += "size/" + item.value + "?" : sendParams += ""); // Get Size Post Filter
      Params = Params.filter((item) => item.key !== 'size');

      sendParams += "?";

      Params.forEach(item => sendParams += item.key + "=" + item.value + "&");  // Envia otros parametros adicionales Ej: Get Post Page Filter

    } else {  sendParams = Params }   // Asigna el String como parametro de la consulta Ej: main o size
    
  } else { sendParams = ""; }
  
    //const objSessionInformation = JSON.parse(sessionStorage.userInfo);
    //const userToken = "Bearer " + objSessionInformation.token;
    const userToken = "Bearer " + sessionStorage.access_token;
  
    try {
        let getAudienceData = await axios({
        method: 'GET',
        url: protocol + host + port + route + dataSource + query + sendParams,      
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userToken,
          }
        })
      
      if (!getAudienceData.error) {
 
        return getAudienceData.data;
        //console.log("Consulta inicial: ", getAudienceData);
  
      } 
  
    } catch (error) {
      return error;
    };
  } 

export async function createAudience(data) {
  const userToken = "Bearer " + sessionStorage.access_token;
  try {
    let response = await axios({
      method: "POST",
      url: protocol + host + port + route + dataSource + query,
      headers: {
        "Content-Type": 'application/json',
        Authorization: userToken,
      },
      data: data
    });
    if(response) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log("Error: API Brilliant", error);
    return error;
  }
}

export async function editAudienceById(id, data) {
  const userToken = "Bearer " + sessionStorage.access_token;
  try {
    let response = await axios({
      method: "PUT",
      url: protocol + host + port + route + dataSource + query + id,
      headers: {
        "Content-Type": 'application/json',
        Authorization: userToken,
      },
      data: data
    });
    if(response) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log("Error: API Brilliant", error);
    return error;
  }
}


export async function usersByAudience(id){
  const userToken = "Bearer " + sessionStorage.access_token;
  try {
    let response = await axios({
      method: "GET",
      url: protocol + host + port + route + dataSource + query + id + "/entity/user?page=0&active=1&page_size=1000",
      headers: {
        "Content-Type": 'application/json',
        Authorization: userToken,
      },
    });
    if(response) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log("Error: API Brilliant", error);
    return error;
  }
}
