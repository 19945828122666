import React from "react";
// Styles
import "../pages/web/sections/css/sections.scss";
import { ButtonComponent } from "./index";
import { AlertCircle } from "react-feather";
import GoToWithoutSaveModal from "../pages/web/modalComponents/GoToWithoutSaveModal.jsx";
import { Button } from "@material-ui/core";

// Localization
import { useTranslation } from "react-i18next";

export function ButtonsFooterComponent(props) {
  const {
    propsComponent,
    FlagclassName,
    onClick,
    path,
    GoToWithoutSave,
    onCancelGotoWithoutMModal,
    goToWithoutSaveModalVisible,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <GoToWithoutSaveModal
        visible={goToWithoutSaveModalVisible}
        handleClose={onCancelGotoWithoutMModal}
        propsComponent={propsComponent}
        path={path ? path : "/manage/web"}
      />
      <div className="buttonsFixed">
        <div className="buttonsBottomInit">
          <AlertCircle
            style={{ color: "black", marginRight: "10px" }}
            size={18}
          />
          {t("basicWords.saveYourInformationCurrent")}
        </div>
        <div className=" buttonsBottomEnd">
          <div>
            {FlagclassName === true ? ( // if user changed some in component
              <div>
                <Button
                  className="buttonDiscard"
                  onClick={GoToWithoutSave}
                  shape="round"
                  size={"large"}
                  type="primary"
                >
                  {t("basicWords.discard")}
                </Button>
              </div>
            ) : (
              /* <Button>
            Text
          </Button> */
              <ButtonComponent
                path={path ? path : "/manage/web"}
                props={propsComponent}
                shape="round"
                text={t("basicWords.discard")}
                className="buttonDiscard"
              />
            )}
          </div>
          <div className="">
            {FlagclassName === true ? ( // if user changed some in component
              <ButtonComponent
                onClick={onClick}
                props={null}
                shape="round"
                className="buttonToSave"
                text={t("basicWords.save")}
              />
            ) : (
              <ButtonComponent
                id="disableFooterButton"
                onClick={onClick}
                props={null}
                shape="round"
                className="buttonDisabled"
                text={t("basicWords.save")}
                isDisabled={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
