import React, { useState, useEffect } from "react";
import { get as _get } from "lodash";
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

import "./css/manageContent.css";

import { getConfigs } from '../../actions/uhoo/Config.actions'; // Singularity 

import Icon from '../../uhoo-components/iconLibrary.jsx';

//Localization
import { useTranslation } from 'react-i18next';

const ManageContent = (props) => {

  const { t, i18n } = useTranslation();

  /// ----- Singularity ----- ///

  const [widgets, setWidgets] = useState(false);
  const [massiveEmail, setMassiveEmail] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [webpage, setWebpage] = useState(false);
  const [loading, setLoading]  = useState(true);
  const [isAura, setIsAura] = useState(false);
  
  sessionStorage.getItem('access_token')

  const Singularity = () => {
    useEffect(async () => {
      const _configs = await getConfigs();
      let config = _configs.data.data;
      let _webpage = sessionStorage.getItem("available_webpage");
      let access_cms = sessionStorage.getItem("access_cms");
      
      setWebpage(_webpage == 'true' && access_cms == 'true'? true : false)
      setWidgets(
        _get(config.find((element) => {
          return element.name === "app.community.webTab.edition.enabled";
        }), "value", false)
      );

      setMassiveEmail(
        _get(config.find((element) => {
          return element.name === "massiveEmail.enabled";
        }), "value", false)
      );
      let _newsletter = _get(config.find((element) => { return element.name === "newsletter.enabled"}), "value", 0)
      if(_newsletter == 0) _newsletter = false;
      if(_newsletter == 1) _newsletter = true;
      setNewsletter(_newsletter)
      
      setLoading(false);
      const URLactual = window.location.href;
      setIsAura(URLactual.includes("aura"));
    }, []);
  };

  Singularity();

  /// --- end Singularity --- ///

  if(loading){
    return (
      <div
        className="col-xs-12 col-md-12"
        style={{
          display: "flex",
          justifyContent: "center",
          transform: "translateY(500%)",
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className="manageContent">
      <div className="manageHeadline">
        <div className="manageTitle">
          <h1>{t('uhoo.manage.title')}</h1>
        </div>
        <div className="manageSubTitle">
          <h3>{t('uhoo.manage.subTitle')}</h3>
        </div>
      </div>

      <section className="CardsManageContainer">

        {widgets
          ? (
            <Link to="/manage/widget">
              <div className="manageCard widgetsCard" role="button">
                <div className="manageCardContent">
                  <Icon.manageWidgets className="icoManageCard" />
                  {isAura ? 
                    <div className="manageCardTitle"><h2>{t('basicWords.buttons.buttons')}</h2></div>
                    : 
                    <div className="manageCardTitle"><h2>{t('uhoo.manage.cards.widgetsCard')}</h2></div>
                  }
                </div>
              </div>
            </Link>
          )
          : (            
              <div className="manageCardDisabled widgetsCard" style={{ order: 1 }}>
                <div className="manageCardContent">
                  <Icon.manageWidgets className="icoManageCard" />
                  {isAura ? 
                    <div className="manageCardTitle"><h2>{t('basicWords.buttons.buttons')}</h2></div>
                    : 
                    <div className="manageCardTitle"><h2>{t('uhoo.manage.cards.widgetsCard')}</h2></div>
                  }
                </div>
              </div>            
          )
        }

        {massiveEmail
          ? (
            <Link to="/manage/mail">
              <div className="manageCard emailCard" role="button">
                <div className="manageCardContent">
                  <Icon.manageEmail className="icoManageCard" />
                  <h2 className="manageCardTitle">{t('uhoo.manage.cards.emailCard')}</h2>
                </div>
              </div>
            </Link>
          )
          : (
              <div className="manageCardDisabled emailCard" style={{ order: 1 }}>
                <div className="manageCardContent">
                  <Icon.manageEmail className="icoManageCard" />
                  <h2 className="manageCardTitle">{t('uhoo.manage.cards.emailCard')}</h2>
                </div>
              </div>
          )
        }

        {newsletter
          ? (
            <Link to="/newsletter">
              <div className="manageCard newsletterCard" role="button">
                <div className="manageCardContent">
                  <Icon.manageNewsletter className="icoManageCard" />
                  <h2 className="manageCardTitle">{t('uhoo.manage.cards.newsletterCard')}</h2>
                </div>
              </div>
            </Link>
          )
          : (
              <div className="manageCardDisabled newsletterCard" style={{ order: 1 }}>
                <div className="manageCardContent">
                  <Icon.manageNewsletter className="icoManageCard" />
                  <h2 className="manageCardTitle">{t('uhoo.manage.cards.newsletterCard')}</h2>
                </div>
              </div>
          )
        }
        {  webpage ? 
          <Link to="/manage/web">
            <div className="manageCard webCard" role="button">
              <div className="manageCardContent">
                <Icon.manageWeb className="icoManageCard" />
                <div className="manageCardTitle"><h2>{t('uhoo.manage.cards.webCard')}</h2></div>
              </div>
            </div>
          </Link>
        : 
          <div className="manageCardDisabled newsletterCard" style={{ order: 1 }}>
            <div className="manageCardContent">
              <Icon.manageWeb className="icoManageCard" />
              <div className="manageCardTitle"><h2>{t('uhoo.manage.cards.webCard')}</h2></div>
            </div>
          </div>
        }
        

        <Link to="/manage/library">
          <div className="manageCard libraryCard" role="button">
            <div className="manageCardContent">
              <Icon.manageLibrary className="icoManageCard" />
              <h2 className="manageCardTitle">{t('uhoo.manage.cards.libraryCard')}</h2>
            </div>
          </div>
        </Link>

      </section>

      <section>
        <div className="manageOtherSubTitle">
          <h3>{t('uhoo.manage.OtherSubTitle')}</h3>
        </div>

        <div className="activitySummary">

          <Link to="/manage/activity">
            <div className="otherCard" role="button">
              <Icon.seeActivity1 className="otherIcoCard" />
              <h2>{t('uhoo.manage.seeActivity')}</h2>
            </div>
          </Link>

        </div>

      </section>
    </div>
  );
};

export default ManageContent;

