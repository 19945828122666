import React, { Component } from "react";

import "./css/massiveActions.css";

// Queries

function MassiveActions(props) {

   return(    
    <div className="massiveActions">
        <div className="secondaryButton neutral" role="button">
            Duplicar
        </div>

        <div className="secondaryButton alert" role="button">
            Eliminar
        </div>

        <div className="secondaryButton neutral" role="button">
            Archivar
        </div>

        <div className="secondaryButton okay" role="button">
            Publicar
        </div>         
    </div>
  );
};

export default MassiveActions;