import React from "react";

// Queries


const UserMediums = () => (
  <div>
    <br />
    <p> Medios </p>    
  </div>
);
export default UserMediums;
