import React, { useEffect } from "react";
import { get as _get } from "lodash";
import { ReactComponent as DownloadIco } from '../../../assets/icons/library/download.svg';

import "./css/ItemLibrary.css";

// Queries



function ItemLibrary(props) {

    function downloadImage(event) {

        fetch(props.url)
            .then(response => response.blob())
            .then(blob => {
                // Create a new FileReader innstance
                const reader = new FileReader;

                // Add a listener to handle successful reading of the blob
                reader.addEventListener('load', () => {
                    const image = new Image;

                    // Set the src attribute of the image to be the resulting data URL
                    // obtained after reading the content of the blob


                    var link = document.createElement("a");
                    // If you don't know the name or want to use
                    // the webserver default set name = ''
                    link.setAttribute('download', 'image.jpeg');
                    link.href = reader.result;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                });

                // Start reading the content of the blob
                // The result should be a base64 data URL
                reader.readAsDataURL(blob);
            });

        try {
            event.preventDefault();
            event.stopPropagation();
        } catch (error) {
            console.error(error);
        }

    }

    function addImage() {
        let selectedItems = [...props.selectedImages];
        selectedItems.push(props.item);
        props.setSelectedImages(selectedItems);
    }

    function removeImage() {
        let selectedItems = [...props.selectedImages];
        selectedItems = selectedItems.filter((item) => item.uuid !== props.item ? props.item.uuid : props.id);
        props.setSelectedImages(selectedItems);
    }

    function clickImage() {
        let select = document.querySelector("#pictureItem-" + props.id);
        try {
            select.checked = !select.checked;
            if (select.checked === true) {
                addImage()
            } else if (select.checked === false) {
                removeImage()
            }
        } catch (error) {
            console.error("No se define la función clickImage", error);
        }
    }

    try {
        let inputSelect = document.querySelector("#pictureItem-" + props.id);
        let checkSelectItems = [...props.selectedImages];

        //inputSelect.checked = true;

        let selected = false;
        checkSelectItems.map((image) => (image.uuid === props.id) ? selected = true : selected = false);
        if (inputSelect && inputSelect.checked && selected === true) {
            inputSelect.checked = true;
        }

    } catch (error) {
        console.error("No se define la función checkSelectItems", error);
    }


    let isChecked = [];

    try {
        isChecked = props.selectedImages.filter(selectedImage => selectedImage.uuid == props.id);
      } catch (error) {
        console.error(error);
      }
      
    let isImg = props.name.includes('png') || props.name.includes('jpeg') || props.name.includes('jpg');
    return (
        <div className="pictureItem" id={props.item ? props.item.uuid: props.id} onClick={() => clickImage()}>

            <div className="fileItem">
                {/*imagen*/}
                
                {props.name.search('.mp4') != -1 ?
                    <video className="fileItem" src={props.item ? props.item.url : props.url} alt={props.name}></video>
                    :
                    <>
                        {
                            isImg ?
                            <img src={props.url} alt={props.name}></img>
                            :
                            <div class="module line-clamp">
                                <img  className="fileItemDoc" onClick={() => window.open(props.item ? props.item.url : props.url,'_blank')} key={_get(props, "id", "")} src='http://cdn.onlinewebfonts.com/svg/img_162799.png' alt='document' />
                            </div>
                        }
                    </>
                }
            </div>
            <div className="selectItem">    {/*seleccionar*/}
                {
                    isChecked.length > 0 ?
                        <input
                            type="checkbox"
                            id={"pictureItem-" + props.id}
                            onClick={() => clickImage()}
                            defaultChecked={true}
                        />
                        :
                        <input
                            type="checkbox"
                            id={"pictureItem-" + props.id}
                            onClick={() => clickImage()}
                        //- checked
                        />
                }
            </div>
            {/*<a href={props.url} download target="_blank">*/}
            <div className="downloadItem" onClick={() => downloadImage()}>
                {/*descargar*/} <DownloadIco />
            </div>
            {/*</a>*/}
            <div className="nameItem">
                {/*Nombre*/} {props.name}
            </div>
            <div className="dateItem">
                {/*fecha*/} {props.date}
            </div>

        </div>
    );
};

export default ItemLibrary;
