import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import "../../community/components/css/configureUser.css";
import { GetUserRol, updateUser, updateUsers, createUser, inviteAndCreateUser, getOwn, getAudience } from "../../../actions/uhoo";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual } from "lodash";
import { useTranslation } from 'react-i18next';
import Icon from '../../../uhoo-components/iconLibrary.jsx';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { UsersCommunityContext } from "./../../community/contex/index";
import SystemNotification from "../SystemNotification.jsx";
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from "react-router-dom";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';

// components
import Modal from "../../../uhoo-components/Modal.jsx";
import CantCreate from './cantCreate.jsx'

function ConfigureUser( props ) {
    const { t, i18n } = useTranslation(); //  Localization
    const UserContext = useContext(UsersCommunityContext);
    const [RolData, setRolData] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [userRolesSelected, setUserRolesSelected] = useState([]);
    const [user, setUser] = useState(null);
    const [avatar, setAvatar] = useState("");
    const [userBase, setUserBase] = useState({});
    const [email, setEmail] = useState("");
    const [medium, setMedium] = useState("");
    const [valueMedium, setValueMedium] = useState(null);
    const [changeMedia, setChangeMedia] = useState(null);
    const [valueRegex ,setValueRegex] = useState("");
    const emailRegex = /^[\w.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const phoneRegex = new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/) ;
    const [showMailWarning, setShowMailWarning] = useState(false);
    const [ textModalWarning, setTextModalWarning ] = useState('');
    const [rolesWarning, setRolesWarning] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showCaptionError, setShowCaptionError] = useState(t('basicWords.internalServerError'));
    const [openModalCantCreate, setOpenModalCantCreate] = useState(false);
    const [textModalCantCreate, setTextModalCantCreate] = useState('');
    const [audiencesToAdd, setAudiencesToAdd ] = useState([]);
    const [audiencesToAddName, setAudiencesToAddName ] = useState([]);
    const [AudienceData, setAudienceData] = useState([]); // array de consulta, contiene las audiencias

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = { PaperProps: { style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250,} } };
  
    let open = props.setOpenModal;
    let history = useHistory();
    let limitUsers = props.limitUsers;

    useEffect(() => {

        const GetAudienceData = async (query) => {
            const info = await getAudience(query);
            let filtroActive = info.data.filter(audience => audience.active === 1)
            let filtroMedium = filtroActive.filter(audience => audience.properties.mediums !== undefined)
            setAudienceData(filtroMedium);
        };

        const loadInfo = async () => {
            const info = await GetUserRol();
            setRolData(info.data);
            let _avatar;
            let _user = props.settingUserValue;
            if(_user == {}){
                _user = {
                    "avatar": {},
                    "avatar_thumbnail": {},
                    "email": "",
                    "name": "",
                    "last_name": "",
                    "main_activity": "",
                    "properties": {},
                    "birthday": "",
                    "gender": "",
                    "rol": "",
                }
            }
            if ( _user && _user.avatar === null ){ 
                _avatar =  _get(_user, "name", "") != null ? _get(_user, "name", "").charAt(0).toUpperCase() : "".charAt(0).toUpperCase();
            } else { 
                _avatar = _get(_user, "avatar.thumbnail", "")
            }
            setAvatar(_avatar);
            let _emails = _get(_user, "emails", []);
            let _email = (_emails.filter(data => data.uuid.includes(_get(_user, "email", ""))));
            _email =  _get(_user, "emails[0].email", []);
            _user.email = _email;
            let _mediums = _get(_user, "properties.mediums", "");
            let valueMediums;
            if(_mediums && _mediums.whatsapp && _mediums.whatsapp.priority == "1"){
                setMedium("Whatsapp");
                valueMediums = _mediums.whatsapp.value;
            }
            if(_mediums && _mediums.email && _mediums.email.priority == "1"){
                setMedium("Mail");
                valueMediums = _mediums.email.value;
            }
            if(!_mediums.email && !_mediums.whatsapp) setMedium("Whatsapp");
            setEmail(_email);
            let _userRoles = [];
            let _userRolesSelected = [];
            let arrayRolesUser = _get(_user, "roles", [])
            for (let index = 0; index < arrayRolesUser.length; index++) {
                let element = arrayRolesUser[index];
                info.data.forEach(rol => {
                    if(element && element.uuid && rol && rol.uuid && element.uuid == rol.uuid){
                        _userRoles.push(rol);
                    } 
                });
            }
            for (let index = 0; index < arrayRolesUser.length; index++) {
                let element = arrayRolesUser[index];
                if(element && element.uuid) _userRolesSelected.push(element.uuid);
            }
            setUserRolesSelected(_userRolesSelected);
            setUserRoles(_userRoles);
            setUser(_user);
            setUserBase(_user);
            setValueMedium(valueMediums);
        }

        GetAudienceData([{ key: 'page_size', value: '500' }])
        loadInfo();
      }, []);

    
    
    function closeModal() { open(false); }

    const onChangeValue = (value, key) => {
        let _updateUser = _cloneDeep(user);
        _updateUser[key] = value;
        setUser(_updateUser);
    }

    const fileClick = () => {
        let inputFile = document.getElementById("attachmentsUpload"); // .files vector de archivos cargados
        inputFile.click();
        
    }

    const uploadFile = async () => {
        let file = document.getElementById("attachmentsUpload"); // .files vector de archivos cargados
        file = file.files[0];
        var fr = new FileReader();
        fr.onload = () => {
            setAvatar(fr.result)
        }
        fr.readAsDataURL(file);
        setChangeMedia(file);
    }
    const checkInfo = () => {
        let mail = document.getElementById("email").value;
        let birthday = document.getElementById("birthday").value;
        let name = document.getElementById("name").value;
        const validateEmail = (email) => {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        };
        if(validateEmail(mail) == null){
            setShowMailWarning(true);
            setTextModalWarning(t('basicWords.modal.MailWarning'))
            return;
        }
        if(name == ''){
            setShowMailWarning(true);
            setTextModalWarning(t('basicWords.nameError'))
            return;
        }
        buttonAction();
    }

    const buttonAction = async () => {
        let _valueRegex = true;
        if (medium == "Mail") {
            _valueRegex = !validateEmail(valueMedium);
            setValueRegex(_valueRegex)
        }
        if (medium == "Whatsapp") {
            _valueRegex = !phoneRegex.test(valueMedium);
            setValueRegex(_valueRegex)
        }
        if(_valueRegex && valueMedium != null && valueMedium != ''){ // si no cumple con el regex
            setShowCaptionError(t('basicWords.errorFavoriteMedium'))
            setShowError(true);
            return;
        }

        // to find change in general inputs
        let tosend = {};
        for (const key in user) {
            const element = user[key];
            if ( (  element && !_isEqual(element, userBase[key]) ) ){
                tosend[key] = element;
            }
        }
        let rolesUser = [];
        RolData.forEach(rol => {
            userRolesSelected.forEach(rolSelected => {
                if(rolSelected == rol.uuid){
                    rolesUser.push(rol.uuid)
                }
            });
        })
        let rolAdmin;
        RolData.forEach(element => {
        if(element.name.includes('Admin') || element.name.includes('admin')){
            rolAdmin = element;
        }
        });
        
        if(rolesUser.length == 0) {
            setRolesWarning(true);
            setShowMailWarning(true);
            return;
        }
        let haveAdminRol = false;
        rolesUser.forEach(uuidRol => {
            if(uuidRol == rolAdmin.uuid && limitUsers){
                haveAdminRol = true;
            }
        });
        if(haveAdminRol){
            configOpeModalCanCreate(t('basicWords.userAdmins'))
            return;
        }
        let rolesToAdd = [];
        let rolesToRemove = [];
        let nowRolesUser = _get(user, "roles", []);
        nowRolesUser.forEach((element, index) => {
            if(element && element.uuid) nowRolesUser[index] = element.uuid;
        });
        if(nowRolesUser .length){
            nowRolesUser.forEach(userRol => {
                if(!(rolesUser.includes(userRol))){
                    rolesToRemove.push(userRol); //remove roles
                }
            });
        }
        rolesToAdd = rolesUser; // add roles
        if(rolesToAdd.length > 0) tosend["addRoles"] = rolesToAdd; 
        if(rolesToRemove.length > 0) tosend["removeRoles"] = rolesToRemove; 
        tosend["rol"] = rolesUser
        let _uuid = _get(user, "uuid", null);
        tosend["uuid"] = _uuid;
        var formData = new FormData();
        for (const key in tosend){
            const element = tosend[key];
            if(element && key != "uuid"){
                if(key == "rol" || key == "addRoles" || key == "removeRoles"){
                    element.forEach(ele => {
                        formData.append(key, ele);
                    });
                }else{
                    formData.append(key, element);
                }
            }
        }
        if(changeMedia){
          formData.append("avatar", changeMedia);
          formData.append("avatar_thumbnail", changeMedia);
        }
        let responseQuery;
        if(_uuid){
            tosend["data"] = formData;
        }
        try{
            if(_uuid){
                responseQuery = await updateUser(tosend, true); //update user
            }else{
                responseQuery = await createUser(formData); //create user
            }
            if(!props.outHeader) {
                let { setUpdate, update } = UserContext
                setUpdate(!update);
            }else{
                // let finalUser = Object.assign({}, _get(responseQuery, "data[0]", ""), user)
                // sessionStorage.setItem("own", JSON.stringify(finalUser));
                // setTimeout(()=>{history.push({pathname: '/community', state: {updateUsers: true}})}, 500)
            }
            setShowSuccess(true);
        }catch(error){
            console.log("error", error);
            setShowError(true);
        }
        if (_get(responseQuery, "message", "").includes("failed")) {
            setShowError(true);
            return;
        }

        // to find changes in favorite medium

        let mediumsToUpdate = {};
        let properties = user.properties && user.properties.mediums ? user.properties.mediums  : {}; 
        // mediums
        if (medium == "Mail" && valueMedium && valueMedium != null) {
            if ( user.properties && user.properties.mediums && user.properties.mediums.whatsapp ) {
                user.properties.mediums.whatsapp.priority = "2";
                user.properties.whatsapp = null;
            }
            _valueRegex = !validateEmail(valueMedium);
            setValueRegex(_valueRegex)
            mediumsToUpdate = {
                email: {
                id: "email",
                value: valueMedium,
                type: "email",
                icon: "email",
                priority: "1",
                },
            };
        }

        if (medium == "Whatsapp" && valueMedium && valueMedium != "" && valueMedium != null) {
            _valueRegex = !phoneRegex.test(valueMedium);
            setValueRegex(_valueRegex)
            if ( user.properties && user.properties.mediums &&user.properties.mediums.email ) {
                user.properties.mediums.email.priority = "2";
                user.properties.email = null;
            }
            mediumsToUpdate = {
                whatsapp: {
                type: "phone",
                id: "whatsapp",
                value: valueMedium.includes("+") ? valueMedium  : "+57" + valueMedium,
                icon: "phone",
                priority: "1",
                },
            };
        }
        
        if(properties){
            properties.mediums = mediumsToUpdate;
        }

        let data = {properties};
        try{
            let response = await updateUser(data, false, true, user && user.uuid ? _get(user, "uuid", "") : _get(responseQuery, "data.data.[0].uuid", "")); //save info user
            let usersToAddInSpecificAudience = [_get(response, "data[0].uuid", null)];
            if(audiencesToAdd){
                audiencesToAdd.forEach(async channel => {
                const entities = {};
                usersToAddInSpecificAudience.forEach(lst => {
                    entities[lst] = {};
                });
                let data ={
                    kind: {
                            "891f6801-19eb-4c3b-98c9-64400c2837f3": { entities }
                        }
                    };
                await updateUsers(_get(channel, "uuid", ""), data); //add users
                });
            }
        }catch(error){
            console.log("error", error);
            setShowError(true);
        }
        setTimeout(()=>{history.push({pathname: '/', state: {updateUsers: true}})}, 100)

    }

    function validateEmail(email){
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    
    const handleChange = (value) => {
        let include = false;
        userRoles.forEach(element => {
            if(element.uuid == value.uuid) include = true;
        });
        let _userRoles = _cloneDeep(userRoles);
        let _userRolesSelected = _cloneDeep(userRolesSelected);
        if(include){
            var _userRolesFiltered;
            var _userRolesSelectedFiltered;
            _userRolesFiltered = _userRoles.filter((item) => item.uuid !== value.uuid);
            _userRolesSelectedFiltered = _userRolesSelected.filter((item) => item !== value.uuid);
            setUserRoles(_userRolesFiltered);
            setUserRolesSelected(_userRolesSelectedFiltered)
        }else{
            _userRoles.push(value);
            _userRolesSelected.push(value.uuid);
            setUserRolesSelected(_userRolesSelected)
            setUserRoles(_userRoles);
        }
    }


    const inviteUser = async () => {
        await buttonAction();
        let _own = await getOwn();
        let formDataInvite = new FormData();
        formDataInvite.append("email",  _get(user, 'email', ''));
        formDataInvite.append("os",  "ios");
        formDataInvite.append("community", _get(_own, 'community.uuid', ''));
        try {
            await inviteAndCreateUser(formDataInvite); //update user
        }catch(error){
            console.log("error", error);
            setShowError(true);
        }
    }

    const configOpeModalCanCreate = (text) => {
        setTextModalCantCreate(text);
        setOpenModalCantCreate(true);
      };

    const onSelectAudienceUser = (event) => {
        const {target } = event;
        let audienceToAdd = target && target.value ? target.value : [];  
        audienceToAdd = audienceToAdd[audienceToAdd.length - 1];
        let _audiencesToAdd = _cloneDeep(audiencesToAdd);
        let _audiencesToAddName = _cloneDeep(audiencesToAddName);
        let haveYetAudience = false;
        if(_audiencesToAdd.length > 0){
            _audiencesToAdd.forEach(audience => {
                if(audienceToAdd && audienceToAdd.uuid){
                if(audience.uuid == audienceToAdd.uuid) haveYetAudience = true;
                }
            });
            if(haveYetAudience){
                let newAudiences = _audiencesToAdd.filter(x => x.uuid != audienceToAdd.uuid);
                _audiencesToAdd = newAudiences;
                let newAudiencesName = _audiencesToAddName.filter(x => x != audienceToAdd.name);
                _audiencesToAddName = newAudiencesName;
            }else{
                _audiencesToAdd.push(audienceToAdd);
                _audiencesToAddName.push(audienceToAdd.name);
            }
        }else{
            _audiencesToAdd.push(audienceToAdd);
            _audiencesToAddName.push(audienceToAdd.name);
        }

        setAudiencesToAdd(_audiencesToAdd)
        setAudiencesToAddName(_audiencesToAddName);
    }

    return(
        <React.Fragment>
            <div className="modalConfigureUser">
                {
                    user == null ?
                        <div
                        className="col-xs-12 col-md-12"
                        style={{
                        display: "flex",
                        justifyContent: "center",
                        transform: "translateY(40%)",
                        }}
                        >
                            <CircularProgress />
                        </div>
                    :
                    <div className="modalConfigureUser">
                        <div className="titleModalConfigureUser">
                        <div className="textTitleModalConfigureUser">{_get(user,"uuid", "") != "" ? t('uhoo.community.modalConfigureUser.editUser') : t('uhoo.community.modalConfigureUser.addUser')}</div>
                            <div className="closeModalConfigureUser" onClick={closeModal} role="button"><Close/></div>
                        </div>
                        <hr className="lines"/>
                        <div className="contentModalConfigureUser">
                            <div className="imageContentModalConfigureUser" >
                                {avatar.length > 1
                                ?
                                    <div className="imageContentModalConfigureUser">
                                        <img src={avatar} alt="avatar" />
                                        <div onClick={fileClick} style={{"cursor": "pointer"}}>
                                            <Icon.profileAdd className="iconPlus" />
                                            <input  hidden
                                                id="attachmentsUpload"
                                                type="file"
                                                accept="image/*, .png, .jpeg, .jpg"
                                                onChange={uploadFile}
                                                multiple
                                                className="iconPlus"
                                            />
                                        </div>
                                    </div>
                                : 
                                    <div>
                                        {avatar}
                                        <div onClick={fileClick} style={{"cursor": "pointer"}}>
                                            <Icon.profileAdd className="iconPlus" />
                                            <input  hidden
                                                id="attachmentsUpload"
                                                type="file"
                                                accept="image/*, .png, .jpeg, .jpg"
                                                onChange={uploadFile}
                                                multiple
                                                className="iconPlus"
                                            />
                                        </div>
                                        </div>
                                }
                            </div>
                            <div className="infoContentModalConfigureUser">
                                <div className="nameInfoContentModalConfigureUser half">
                                    <div className="labelForm">{t('basicWords.modal.name')}</div>
                                    <input type="text" name="name" id="name" defaultValue={user.name} className="inputContentModalConfigureUser" onChange={(e) => {onChangeValue(e.target.value, "name")}} />
                                </div>
                                <div className="last_nameInfoContentModalConfigureUser half">
                                    <div className="labelForm">{t('basicWords.modal.last_name')}</div>
                                    <input type="text" id="lastName" defaultValue={user.last_name} className="inputContentModalConfigureUser" onChange={(e) => {onChangeValue(e.target.value, "last_name")}}/>
                                </div>
                                <div className="mailInfoContentModalConfigureUser twoHalf">
                                    <div className="labelForm">{t('basicWords.modal.mail')}</div>
                                    {user.uuid ?
                                    <input type="mail" id="email" value={email} readOnly className="inputContentModalConfigureUser input_twoHalf" />
                                    :
                                    <input type="mail" id="email" value={user.email} className="inputContentModalConfigureUser input_twoHalf" onChange={(e) => {onChangeValue(e.target.value, "email")}} />
                                    }
                                </div>
                                <div className="birthdayInfoContentModalConfigureUser half">
                                    <div className="labelForm">{t('basicWords.modal.birthday')}</div>
                                    <input type="date" id="birthday" defaultValue={user.birthday} className="inputContentModalConfigureUser" onChange={(e) => {onChangeValue(e.target.value, "birthday")}}/>
                                </div>
                                <div className="genderInfoContentModalConfigureUser half">
                                    <div className="labelForm">{t('basicWords.modal.gender')}</div>
                                    <select id="gender" value={user.gender} className="inputContentModalConfigureUser" onChange={(e) => {onChangeValue(e.target.value, "gender")}} >
                                        <option value="M">{t('basicWords.modal.male')}</option>                                
                                        <option value="F">{t('basicWords.modal.female')}</option>
                                        <option value="O">{t('basicWords.modal.other')}</option>
                                    </select>
                                </div>
                                <div className="rolesInfoContentModalConfigureUser twoHalf">
                                    <div className="labelForm">{t('basicWords.modal.establish')}</div>
                                    <Select
                                    displayEmpty
                                    multiple
                                    value={userRoles}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    renderValue={(selected) => {
                                        let _data = [];
                                        selected.forEach(element => {
                                            _data.push(element.name);
                                        });
                                        return _data.join(", ")
                                    }}
                                    >
                                    {RolData.map((item) => (
                                        <MenuItem key={item.uuid} value={item.uuid} onClick={()=>handleChange(item)}>
                                            <Checkbox checked={userRolesSelected.includes(item.uuid)} />
                                            <ListItemText primary={item.name} />
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </div>
                                <div className="main_activityInfoContentModalConfigureUser twoHalf">
                                    <div className="labelForm">{t('basicWords.modal.activity')}</div>
                                    <input type="text" id="main_activity" defaultValue={user.main_activity} className="inputContentModalConfigureUser input_twoHalf" onChange={(e) => {onChangeValue(e.target.value, "main_activity")}} />
                                </div>
                                <div className="separeComponents">
                                    <div className="container2ContentModalConfigureUser">
                                        <div className="labelForm">{t('basicWords.modal.favoriteMedium')}</div>
                                        <select id="medium" value={medium} className="input2ContentModalConfigureUser" onChange={(e) => {setMedium(e.target.value)}} >
                                            <option value="Whatsapp">{t('basicWords.modal.whatsapp')}</option>                                
                                            <option value="Mail">{t('basicWords.modal.mail')}</option>
                                        </select>
                                    </div>
                                    <div className="container3ContentModalConfigureUser ">
                                        <div className="labelForm">{medium}</div>
                                        <input type="text" id="mediums" value={valueMedium} className="input3ContentModalConfigureUser" onChange={(e) => {setValueMedium(e.target.value)}}/>
                                    </div>
                                </div>
                                <div className="rolesInfoContentModalConfigureUser twoHalf">
                                    <div className="labelForm">{t('basicWords.audience')}
                                        {
                                        AudienceData ? 
                                            <FormControl sx={{ m: 1, width: 300 }}>
                                                <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={audiencesToAddName}
                                                onChange={(e)=> onSelectAudienceUser(e)}
                                                input={<OutlinedInput label="Tag" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                                >
                                                {AudienceData.map((audience) => (
                                                    <MenuItem key={audience.uuid} value={audience}>
                                                    <Checkbox checked={audiencesToAddName.indexOf(audience.name) > -1} />
                                                    <ListItemText primary={audience.name} />
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                            </FormControl>
                                        : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="buttonActions">
                            <div className="actionContentModalConfigureUser">
                                <div onClick={() => _get(user,"uuid", "") != "" ? buttonAction() : checkInfo()}>
                                    <SecondaryButton txt={_get(user, "uuid", "") != "" ? t('basicWords.modal.update') : t('basicWords.modal.create')}/>
                                </div>
                            </div>
                            {_get(user,"uuid", "") == "" ?
                                <div className="actionContentModalConfigureUser" onClick={ () => inviteUser()}>
                                    <SecondaryButton txt={t('basicWords.modal.createAndinvite')} />
                                </div> 
                                : null
                            }
                        </div>
                        
                    </div>
                }
            </div>


            {openModalCantCreate ? (
            <Modal>
            <CantCreate
                setOpenModal={setOpenModalCantCreate}
                text={textModalCantCreate}
            />
            </Modal>
        ) : null}

            
            {!!showMailWarning && (
                <SystemNotification
                    setShowNotification={setShowMailWarning}
                    type="Warning"
                    caption={rolesWarning ? t('basicWords.modal.RolesWarning') : textModalWarning}
                />
            )}
            {!!showSuccess && (
                <SystemNotification
                    setShowNotification={setShowSuccess}
                    setShowModal={open}
                    type="Success"
                    caption={_get(user,"uuid", "") != "" ? t('basicWords.modal.updateSuccess') : t('basicWords.modal.createSuccess')}
                />
            )}
            {!!showError && (
                <SystemNotification
                    setShowNotification={setShowError}
                    type="Error"
                    caption={showCaptionError}
                />
            )}
        </React.Fragment>
    );
}

export default ConfigureUser;
