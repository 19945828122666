import React, {useState, useEffect, useContext} from "react";

import { Link } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../css/react-tabs.scss';

//Context
import { UsersCommunityProvider, UsersCommunityContext } from './contex';

import "./css/community.css";

import CommunityActions from "./components/communityActions";
import UsersCommunity from "./usersCommunity";
import AudienceCommunity from "./audienceCommunity";
import RoleCommunity from "./roleCommunity";
import {  } from "./contex";

//Localization
import { useTranslation } from 'react-i18next';

const Community = (props) => {

  const { t, i18n } = useTranslation();
  const [pageShow, setPageShow] = useState(1);
  const [totalUsers, setTotalUsers] = useState([]);
  const [totalAudiences, setTotalAudiences] = useState([]);

  return (
    <UsersCommunityProvider>
    <div className="Content">
      <div className="headline">
        <div className="communityTitle">
          <h1>{t('uhoo.community.title')}</h1>
        </div>
        <div className="communitySubTitle">
          <h3>{t('uhoo.community.subTitle')}</h3>
        </div>
      </div>

      <div className="communityActions">
        <CommunityActions setPageShow={setPageShow} pageShow={pageShow} />
      </div>

      <section className="community">
        <Tabs>
            <TabList>
              <Tab >{t('uhoo.community.tab1') +  `(${totalUsers.length})`}</Tab>
              <Tab >{t('uhoo.community.tab2') +  `(${totalAudiences.length})`}</Tab>
              <Tab >{t('uhoo.community.tab3')}</Tab>
            </TabList>
            <TabPanel>
              <div className="usersCommunity communityContent">
                <UsersCommunity setPageShow={setPageShow} pageShow={pageShow}  updateUsers={props.location.state && props.location.state.updateUsers} 
                totalUsers={totalUsers} setTotalUsers={setTotalUsers} totalAudiences={totalAudiences} setTotalAudiences={setTotalAudiences}/>
              </div>              
            </TabPanel>
            <TabPanel>
              <div className="audienceCommunity communityContent">
                <AudienceCommunity setPageShow={setPageShow} pageShow={pageShow} />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="roleCommunity communityContent">
                <RoleCommunity/>
              </div>
            </TabPanel>
          </Tabs>   
      </section>
    </div>
      </UsersCommunityProvider>
  );  
};

export default Community;

