import React, {useState} from "react";

// Queries
import { GetPost } from "../../actions/uhoo/index";

var Params = []

const Post = () => {

  const [loaded, setLoaded] = useState(false);

  async function getData(query) {
    try {
      let info = await GetPost(query);               
      setLoaded(true);

    } catch (err) {
      console.error(err);
    }
  }

//let info = getData();

  return(
    <div>
      <br />
      <p> Post </p>    
    </div>
  );
};

export default Post;

function addParams(addParam){  // opcional 
  Params = { ...addParam}
}
