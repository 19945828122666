import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "./css/notification.css";
import { ReactComponent as BackArrow } from "../../assets/icons/navigation/backArrow.svg";

import NotificationForm from "./createNotificationForm";
import NotifyPreview from "../../uhoo-components/preview/notifyPostPreview.jsx";

//Localization
import { useTranslation } from "react-i18next";

const Notification = () => {
  const { t, i18n } = useTranslation(); // Localization
  let history = useHistory(); //  from 'react-router-dom' -> la uso para avanzar o retroceder en el historial de navegacion
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewInfo, setPreviewInfo] = useState("");
  const [previewImage, setPreviewImage] = useState("");

  return (
    <React.Fragment>
      <section className="Title">
        <BackArrow
          className="ico pointerCursor"
          onClick={() => history.goBack()}
        />
        <h3>{t("uhoo.notification.title")}</h3>
      </section>

      <section className="notification">
        <div className="notificationContent">
          <NotificationForm
            event={false}
            setPreviewTitle={setPreviewTitle}
            setPreviewInfo={setPreviewInfo}
            previewTitle={previewTitle}
            previewInfo={previewInfo}
            setPreviewImage={setPreviewImage}
          />
        </div>
      </section>

      <section className="previewNotify">
        <div className="previewContentNotify">
          <NotifyPreview
            previewTitle={previewTitle}
            previewInfo={previewInfo}
            previewImage={previewImage}
          />
        </div>
      </section>
    </React.Fragment>
  );
};

export default Notification;
