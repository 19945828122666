import React from "react";

import "./css/cleanColumns.css";
import { ReactComponent as ImgDemo } from '../../../assets/icons/activity/demo.svg';

//Localization
import { useTranslation } from 'react-i18next';

const CleanColumns = () => {

  const { t, i18n } = useTranslation(); //  Localization  
  
  return(    
    <React.Fragment>
      <section className="activityPlaceHolder">
      <div className="cleanColumns-title">
        {t('uhoo.activity.clean.title')}
      </div>
      <div className="cleanColumns-caption">
        {t('uhoo.activity.clean.caption')}
      </div>
      <ImgDemo className="demoImg" />      
      </section>
    </React.Fragment> 
  );
};

export default CleanColumns;





