import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from 'uuid';
import { get as _get } from "lodash";

import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';

import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";

import "./css/addColumn.css";

//Localization
import { useTranslation } from 'react-i18next';

// Queries
import { getConfigs, getAudience, updateUser } from "../../../actions/uhoo";

//Context
import { ActivityProvider, ActivityContext } from '../contex';

function AddColumn(props) {

    const { SavePreferences, columnProperties, setColumnProperties } = useContext(ActivityContext);
    const [queryColumn, setQueryColumn] = useState("");

    const [AudienceData, setAudienceData] = useState([]); // array de consulta, contiene las audiencias

    const { t, i18n } = useTranslation(); //  Localization 

    /// ---------- Queries ---------- ///

    const GetAudienceData = (query) => {
        useEffect(async () => {
            const info = await getAudience(query);

            let filtroActive = info.data.filter(audience => audience.active === 1)
            let filtroMedium = filtroActive.filter(audience => audience.properties.mediums !== undefined)

            setAudienceData(filtroMedium);

        }, []);
        return AudienceData;
    };

    GetAudienceData([{ key: 'page_size', value: '500' }])

    /// ---------- End Queries ---------- ///

    let open = props.setOpenModalAddColumn;
    function closeModal() { open(false); }

    async function actionColumn() {

        let filter = document.getElementById("queryColumn").value;
        let nameColumn = document.getElementById("nameColumn").value;
        let queryColumn = document.getElementById("queryColumn").value;

        let currentColumns = columnProperties;
        let idColumn = uuidv4();
        let indexColumn = (columnProperties.length + 1)

        function Column(id, position, name, query, filter, helper) { // Constructor
            this.id = id;
            this.position = position;
            this.name = name;
            this.query = query;
            this.filter = filter;
            this.helper = helper;
        }

        switch (filter) {

            case "state":
                let status = document.querySelectorAll('div.status > input');
                let stateFilter = [];

                status.forEach(select => (select.checked === true)
                    ? stateFilter.push(select.value)
                    : null
                );

                currentColumns.push(new Column(idColumn, indexColumn, nameColumn, queryColumn, stateFilter));
                break;
            case "audience":
                let audience = document.querySelectorAll('div.status > input');
                let audienceFilter = [];

                audience.forEach(select => (select.checked === true)
                    ? audienceFilter.push(select.value)
                    : null
                );

                currentColumns.push(new Column(idColumn, indexColumn, nameColumn, queryColumn, audienceFilter));
                break;
            case "date":
                let start = document.getElementById("seekerStartDate").value;
                let end = document.getElementById("seekerEndDate").value;
                let date = [{ key: 'search' }, { key: 'created_at;bt', value: end }, { key: 'created_at;bt', value: start }, { key: 'active', value: 1 }]
                currentColumns.push(new Column(idColumn, indexColumn, nameColumn, queryColumn, date));
                break;
            case "keyword":
                let key = document.getElementById("keywordQueryColumn").value;
                
                //let seeker = document.querySelectorAll('div.seeker > input');
                //let helper = [];
                //seeker.forEach(
                //    select => (select.checked === true) ? helper.push(select.value) : null
                //);

                let helper = ["text"];

                currentColumns.push(new Column(idColumn, indexColumn, nameColumn, queryColumn, key, helper));
                break;
            default:
                currentColumns.push(new Column(idColumn, indexColumn, nameColumn, queryColumn));
        }

        setColumnProperties(currentColumns);
        let own = JSON.parse(sessionStorage.own);
        let properties = {};
        properties.columnProperties = JSON.stringify(currentColumns);
        let data = {properties};
        let result = await updateUser(data, false, true, _get(own, "uuid", "")); //save info user
        if(result.data[0]) sessionStorage.setItem("own", JSON.stringify(result.data[0]));
        //SavePreferences(currentColumns);
        open(false);
    }


    function filters() {
        let filter = document.getElementById("queryColumn").value;

        let state = (
            <React.Fragment>
                <div className="labelForm">{t('uhoo.activity.addColumn.filter.state.caption')}</div>

                <div className="status labelForm">
                    <input type="radio" id="approved" value="approved" name="state" />
                    <label htmlFor="approved"> {t('uhoo.activity.addColumn.filter.state.approved')}</label><br />

                    <input type="radio" id="disapproved" value="disapproved" name="state" />
                    <label htmlFor="disapproved"> {t('uhoo.activity.addColumn.filter.state.disapproved')}</label><br />

                    <input type="radio" id="toApproved" value="toApproved" name="state" />
                    <label htmlFor="toApproved"> {t('uhoo.activity.addColumn.filter.state.toApproved')}</label><br />

                    <input type="radio" id="drafts" value="drafts" name="state" />
                    <label htmlFor="drafts"> {t('uhoo.activity.addColumn.filter.state.drafts')}</label><br />

                    <input type="radio" id="deleted" value="deleted" name="state" />
                    <label htmlFor="deleted"> {t('uhoo.activity.addColumn.filter.state.deleted')}</label><br />
                </div>
            </React.Fragment>
        );

        let audience = (
            <React.Fragment>
                <div className="labelForm">{t('uhoo.activity.addColumn.filter.audience.caption')}</div>

                {AudienceData.length > 0
                    ? (
                        <div className="status labelForm">
                            {AudienceData.map(item => (
                                <React.Fragment key={item.uuid}>
                                    <input type="checkbox" id={item.uuid} value={item.channel.uuid} />
                                    <label htmlFor={item.uuid}> {item.name}</label><br />
                                </React.Fragment>
                            ))}
                        </div>
                    )
                    : (
                        <div className="statusNoFound labelForm">
                            {t('uhoo.activity.addColumn.filter.audience.noFound')}
                        </div>
                    )
                }
            </React.Fragment>
        );

        let date = (
            <React.Fragment>
                <div className="infoContentModalAddColumn seeker">
                    <div className="nameInfoContentModalConfigureUser half">
                        <div className="labelForm">{t('uhoo.activity.addColumn.filter.date.start')}</div>
                        <input type="date" name="start" id="seekerStartDate" className="inputContentModalAddColumn" placeholder="dd/mm/aaaa"></input>
                    </div>
                    <div className="last_nameInfoContentModalConfigureUser half">
                        <div className="labelForm">{t('uhoo.activity.addColumn.filter.date.end')}</div>
                        <input type="date" name="end" id="seekerEndDate" className="inputContentModalAddColumn" placeholder="dd/mm/aaaa"></input>
                    </div>
                </div>
            </React.Fragment>
        );

        let keyword = (
            <React.Fragment>
                {/*
                <div className="labelForm seeker">
                    <div className="labelForm">{t('uhoo.activity.addColumn.filter.keyword.caption')}</div>
                    <input type="checkbox" id="title" value="title" className="keywordFilter" />    --> el value del campo corresponde al key del campo
                    <label htmlFor="title"> {t('uhoo.activity.addColumn.filter.keyword.inTitle')}</label><br />

                    <input type="checkbox" id="content" value="content" className="keywordFilter" />    --> el value del campo corresponde al key del campo
                    <label htmlFor="content"> {t('uhoo.activity.addColumn.filter.keyword.inContent')}</label><br /><br />
                </div>
                */}

                <div className="labelForm">{t('uhoo.activity.addColumn.filter.keyword.keyword')}</div>
                {/*<textarea rows="10" id="keywordQueryColumn" className="inputContentModalAddColumn input_twoHalf"/>*/}
                <input type="text" id="keywordQueryColumn" className="inputContentModalAddColumn input_twoHalf" />
            </React.Fragment>
        );

        switch (filter) {
            case "state":
                setQueryColumn(state);
                break;
            case "audience":
                setQueryColumn(audience);

                break;
            case "date":
                setQueryColumn(date);

                break;
            case "keyword":
                setQueryColumn(keyword);

                break;
            default:
                setQueryColumn("");
        }
    }

    return (
        <ActivityProvider>
            <div className="modalAddColumn">
                <div className="titleModalAddColumn">
                    <div className="textTitleModalAddColumn">{t('uhoo.activity.addColumn.title')}</div>
                    <div className="closeModalAddColumn" onClick={closeModal} role="button"><Close /></div>
                </div>

                <hr className="lines" />

                <div className="contentModalAddColumn">
                    <div className="infoContentModalAddColumn">
                        <div className="twoHalf">
                            <div className="labelForm">{t('uhoo.activity.addColumn.columnName')}</div>
                            <input type="text" id="nameColumn" className="inputContentModalAddColumn input_twoHalf" />
                        </div>
                        <div className="selectFilter twoHalf">
                            <div className="labelForm">{t('uhoo.activity.addColumn.filterType')}</div>
                            <select id="queryColumn" className="inputContentModalAddColumn input_twoHalf" onChange={filters}>
                                <option value="unfiltered">{t('uhoo.activity.addColumn.filter.unfiltered')}</option>
                                <option value="state">{t('uhoo.activity.addColumn.filter.state.title')}</option>
                                <option value="audience">{t('uhoo.activity.addColumn.filter.audience.title')}</option>
                                <option value="date">{t('uhoo.activity.addColumn.filter.date.title')}</option>
                                <option value="keyword">{t('uhoo.activity.addColumn.filter.keyword.title')}</option>
                            </select>
                        </div>
                        <div className="queryItem twoHalf">
                            {queryColumn}
                        </div>
                    </div>
                </div>

                <div className="actionContentModalAddColumn">
                    <div onClick={actionColumn}>
                        <SecondaryButton txt={t('uhoo.activity.addColumn.add')} />
                    </div>
                    <div onClick={closeModal}>
                        <SecondaryButton txt={t('uhoo.globalWords.cancel')} outline />
                    </div>
                </div>
            </div>
        </ActivityProvider>
    );
}
export default AddColumn;