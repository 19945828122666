import { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Language localization
import { withTranslation } from "react-i18next";
import LocalizeSelectorLayout from "../reactUtils/systemVariables/languageLocalization/selectorLayoutLocalization.js";

import "../pages/web/MyWebPage.scss";

class SelectLayoutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      newLayoutId: null,
      oldLayoutId: null,
      saveButtonDisabled: true,
      images: null,
      isLoadingInfo: true,
    };
  }

  // Info to render
  renderLayouts(layoutsData) {
    const { lpLayoutId } = this.props;
    const { t } = this.props
    let renderedComponents = [];
    if (layoutsData) {
      layoutsData.forEach((element) => {
        let data = LocalizeSelectorLayout(element.name, t);
        let name = data[0];
        let description = data[1];
        let image = element.img;
        if (image) {
          // isLoadingInfo= false,
          renderedComponents.push(
            <div
              className="col-xs-12 col-md-12 col-lg-4 themeThumbnail"
              style={{ marginRight: "10px", display: "flex" }}
              key={element.id}
            >
              <div className="cardSelectTheme">
              <Tooltip title={description}>
                <img
                  src={image}
                  alt="theme"
                  style={{ height: "160px", width: "230px" }}
                />
                </Tooltip>

                <RadioGroup
                  name="theme"
                  value={lpLayoutId}
                  onChange={(e) => this.props.handleChange(e, element.id)}
                  style={{ width: "230px" }}
                >

                  <FormControlLabel
                    value={element.id}
                    control={<Radio />}
                    label={name}
                    style={{ marginLeft: "3%", textAlign: "center" }}
                  />
                </RadioGroup>

              </div>
            </div>
          );
        }
      });
    }

    return renderedComponents;
  }

  render() {
    const { layoutData } = this.props;
    const { t } = this.props
    return (
      <>
        <div>
          <div className="row">
            <Accordion className="bgAccordion borderAccordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="textAccordion">
                  {t('basicWords.componentStyle')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="rowSection">
                  {this.renderLayouts(layoutData)}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          {/* )}  */}
        </div>
      </>
      // </Modal>
    );
  }
}

export default connect(null, {})(withTranslation()(SelectLayoutModal));
