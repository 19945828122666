import React, { useState, useEffect, useContext } from "react";

//Localization
import { useTranslation } from 'react-i18next';
//Context
import { UsersCommunityProvider, UsersCommunityContext } from './contex';

import "./css/contentCommunity.css";

//import UserCard from "./components/userCard.jsx";
import AudienceCard from "./components/audienceCard.jsx";
import Pagination from '../global/components/create/pagination.jsx';
import MassiveActions from "../global/components/massiveActions";

const AudienceCommunity = (props) => {

  const { AudienceData, AudiencePage, search, searcher, setTabActive, setSearch } = useContext(UsersCommunityContext);

  setTabActive("AudienceCommunity");

  const { t, i18n } = useTranslation();

  const [audiences, setAudiences] = useState([]);

  const numItemsShow = 8;

  const [counterCards, setCounterCards] = useState(0);

  const [pageShowAux, setPageShowAux] = useState(1);


  const AudienceContext = useContext(UsersCommunityContext);
  useEffect(() => {
    setSearch('');
    let { AudiencePage, AudienceData } = AudienceContext;
    let itemShow = [];
    if (search.length > 0) {
      let itemStart = props.pageShow ? (props.pageShow* numItemsShow - numItemsShow) : (pageShowAux* numItemsShow - numItemsShow);
      let itemsShow = (itemStart + numItemsShow);
      if (itemsShow > searcher.length) itemsShow = searcher.length;

      for (let i = itemStart; i < itemsShow; i++) {
        itemShow.push(searcher[i]);
      }
    } else {
      if (!AudienceData.length) { return ("Cargando"); }
      else {
        let itemStart = props.pageShow ? (props.pageShow * numItemsShow - numItemsShow) : (pageShowAux * numItemsShow - numItemsShow);
        let itemsShow = (itemStart + numItemsShow);
        if (itemsShow > AudienceData.length) itemsShow = AudienceData.length;

        for (let i = itemStart; i < itemsShow; i++) {
          if(AudienceData[i] !== undefined) {
            itemShow.push(AudienceData[i]);
          }
        }
      }
    }
    setAudiences(itemShow);
  }, [ AudienceContext, props.pageShow ? props.pageShow : pageShowAux ])

  

  function selectAll(event) {

    let selectAll = document.querySelectorAll('input.inputSelectAll')[0];
    let matches = document.querySelectorAll('input.checkboxCardUser');
    let cards = document.querySelectorAll(".cardUser");

    if (selectAll.checked) {
      matches.forEach(select => select.checked = true);
      cards.forEach(select => select.classList.add("actionViewTrue"));  // acciones masivas
      document.getElementById("massiveActions").classList.add("actionViewShow");
    }
    if (!selectAll.checked) {
      matches.forEach(select => select.checked = false);
      cards.forEach(select => select.classList.remove("actionViewTrue"));  // acciones masivas
      document.getElementById("massiveActions").classList.remove("actionViewShow");
    }

    observerSelectAll(event);

    event.stopPropagation();
  }

  function observerSelectAll(event) {  // verifica el # de audiecias selecioandas para activar el check de selecioanr todas   

    // for Select All option  ---------------------------------------------------------
    let _audiencesSelected = [];
    let matches = document.querySelectorAll('input.checkboxCardUser');
    let selectAll = document.querySelectorAll('input.inputSelectAll')[0];

    let selectItem = 0; // contador # de audiencias selecioandas 

    matches.forEach((select, index) => {
      (!select.checked) ? selectAll.checked = false : selectItem++;
      if(select.checked) _audiencesSelected.push(AudienceData[index].channel.uuid);
    });
    if (matches.length === selectItem) { selectAll.checked = true; }  

    setCounterCards(selectItem);
    if(props.setAudienceSelected !== undefined) {
      props.setAudienceSelected(_audiencesSelected) /// variable que usa Mauro en Mail
    }
    
    event.stopPropagation();
  }

  return (
    <React.Fragment>
      <div className="contentUsers">
        <div className="contentUsersSelectNoFilter">
        {props.showActions ? <input type="checkbox" className="inputSelectAll"/> : null}
          
        </div>

        {/*<div className="actions" id="massiveActions">
          <MassiveActions />
          <div className="counterText">
            {counterCards} item
          </div>
        </div>*/}

        <div className="contentUsersCards" style={{ justifyContent: audiences.length >= 4 ? 'space-arround' : 'flex-start' }} onChange={observerSelectAll}>
          { audiences.map(
              item =>
                <AudienceCard
                  data={item}
                  key={item.uuid}
                  uuid={item.uuid}
                  name={item.name}
                  last_name={item.last_name}
                  avatar={item.avatar}
                  roles={item.roles}
                  showActions={props.showActions}
                />
              )
          }

        </div>
      </div>

      <UsersCommunityContext.Consumer>
        {({ AudienceData, AudiencePage  }) => {
          let AudienceCount = AudienceData.length;
          if (AudiencePage === undefined) { return (""); }
          else {
            let pageEnd;
            if (search.length > 0) {
              pageEnd = Math.ceil(search.length / numItemsShow);
            }
            else {
              pageEnd = Math.ceil(AudienceCount / numItemsShow)
            }

            return (<Pagination pageShow={props.pageShow ? props.pageShow : pageShowAux} setPageShow={props.setPageShow ? props.setPageShow : setPageShowAux} pageEnd={pageEnd} />);
          }
        }}
      </UsersCommunityContext.Consumer>

    </React.Fragment>
  );
};

export default AudienceCommunity;
