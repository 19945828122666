import React from "react";
import { InputLabel, Select, MenuItem, FormControl } from "@mui/material/";
import "./css/Select.scss";

export function SelectSingle(props) {
  let options = props.options;
  return (
    <div className="row">
      <div className="col-12">
        {options !== null ? (
          <FormControl>
            <InputLabel id="demo-simple-select-label">{props.text}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.selectedOptions}
              onChange={props.onChange}
              label={props.text}
            >
              {options.map((op) => (
                <MenuItem key={op.label} value={op.value}>
                  {op.label + " " }
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </div>
    </div>
  );
}
