import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import { useTranslation } from 'react-i18next';
import { get as _get } from "lodash";
import "../../community/components/css/deleteUser.css";
import { updateUser } from "../../../actions/uhoo";
import SystemNotification from "../SystemNotification.jsx";
import { CircularProgress } from "@material-ui/core";
import Avatar from '@mui/material/Avatar';

// Queries
import { getUser } from "../../../actions/uhoo/Users.actions";
import { updateUsers } from "../../../actions/uhoo";

function AddUsers(props) {
    const { t, i18n } = useTranslation(); //  Localization
    const [userToAdd, setUserToAdd] = useState([]);
    let open = props.setOpenModal;

    const [showWarning, setShowWarning] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userPublish, setUserPublish] = useState([]);
    useEffect(() => {

        const loadInfo = async () => {
            let usersAlready = props.usersAlready;
            let userBackend = await getUser("?active=1&page_size=500");
            userBackend = _get(userBackend, "data", []);
            let _userToAdd = []
            userBackend.forEach(user => {
                let duplicatedUser = false
                usersAlready.forEach(_user => {
                    if(user.uuid == _user.uuid){
                        duplicatedUser = true;
                    }
                });
                if(duplicatedUser == false){
                    _userToAdd.push(user)
                }
            });
            const compareNames = (a, b) => {
                let nameA = a.name;
                let nameB = b.name;
                let result = nameA.localeCompare(nameB);
                return result;
              }
            _userToAdd.sort(compareNames);
            setUserToAdd(_userToAdd);
        }
        loadInfo();
    }, []);

    function closeModal() { open(false); }

    function actionEnabled(user) {

        function stringAvatar(name) {
            return {
                children: name ? `${name.split('')[0][0].toUpperCase()}`  : '',
            };
        }

        try {
            return (
                <React.Fragment key={user.uuid}>
                    <div className="audienceOption">
                        <input type="checkbox" id={user.uuid} value={user.uuid} name="audience" />
                        <label htmlFor={user.uuid} className="audienceOptionLabel">
                            <Avatar
                                {...stringAvatar(user.name)}
                                sx={{ width: 24, height: 24, bgcolor: '#FFCB96', }}
                            />
                            <div>{user.name + " " + user.last_name}</div>
                        </label><br />
                    </div>
                </React.Fragment>
            );
        } catch (error) {
            console.error(error);
            setShowError(true);
        }
    }

    function selectAudience() {

        let userOptions = document.querySelectorAll('div.audienceOption > input');
        let users = [];

        userOptions.forEach(select => (select.checked === true)
            ? users.push(select.value)
            : null
        );

        if ( users.length === 0 ) {
            setShowWarning(true);
        } else {
            setUserPublish(users);
            let responses = [];
            let haveError = false;
            let audience = [props.audience.uuid]
            audience.forEach(async channel => {
                const entities = {};
                users.forEach(lst => {
                    entities[lst] = {};
                });
                let data ={
                    kind: {
                            "891f6801-19eb-4c3b-98c9-64400c2837f3": { entities }
                        }
                    };
                let response = await updateUsers(channel, data); //add users
                responses.push(response);
            });
            responses.forEach(rsp => {
                if (_get(rsp, "message", "").includes("failed")) {
                    haveError = true;
                }
            });

            if (haveError) {
                setShowError(true);
                return;
            } else {
                props.setAdedUser(true);
                setShowSuccess(true);
            }
        }      
    }

    return (
        <React.Fragment>
            <div className="modalAddColumn">
                <div className="titleModalAddColumn">
                    <div className="textTitleModalAddColumn">{t('uhoo.activity.action.title')}</div>
                    <div className="closeModalAddColumn" onClick={closeModal} role="button"><Close /></div>
                </div>

                <hr className="lines" />

                {
                userToAdd.length == 0 ? 
                    <div className="contentModalAddColumn">
                        <div className="infoContentModalAddColumn">
                            <div className="status labelForm selectAudienceRadio">
                                <p>Todos los usuarios hacen parte de esta audiencia</p>
                            </div>
                        </div>
                    </div>
                :
                userToAdd.length > 0 ? 
                    (
                        <div className="contentModalAddColumn">
                            <div className="infoContentModalAddColumn">
                                <div className="status labelForm selectAudienceRadio">
                                    {userToAdd.map(item => actionEnabled(item))}
                                </div>
                            </div>
                        </div>
                    )
                    : (
                        <div className="contentModalAddColumn">
                            <CircularProgress />
                        </div>
                    )
                }

                <div className="actionContentModalAddColumn">
                    <div onClick={selectAudience} >
                        <SecondaryButton txt={t('basicWords.modal.update')} />
                    </div>
                    <div onClick={closeModal}>
                        <SecondaryButton txt={t('uhoo.globalWords.cancel')} outline />
                    </div>
                </div>
            </div>

            {!!showError && (
                <SystemNotification
                    setShowNotification={setShowError}
                    type="Error"
                    caption={t('basicWords.internalServerError')}
                />
            )}

            {!!showSuccess && (
                <SystemNotification
                    setShowNotification={setShowSuccess}
                    type="Success"
                    caption={t('basicWords.successAction')}
                    setShowModal={open}
                />
            )}

        </React.Fragment>
    );
}

export default AddUsers;
