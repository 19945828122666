import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';
import "./css/comment.css";

// Queries
import { getUser } from "../../../actions/uhoo/Users.actions";

//Localization
import { useTranslation } from 'react-i18next';

const Comment = (props) => {

  let comment = props.item;

  const [userInfo, setUserInfo] = useState([]);
  let user = false;

  /// ---------- Queries ---------- ///

  useEffect(() => {
    const getUserData = async (query) => {
      const info = await getUser(query);
      //console.log("userInfo", info.data[0]);
      setUserInfo(info.data[0]);
      user = true;
    };

    getUserData(comment.user);

  }, []);

  function parseHTML(html) {

    try {
      return (parse(html));
    } catch (error) {
      //console.error("ERROR parseHTML \n Post id: " + postInfo.uuid + "\n", error);

      if (html !== null) {
        return (html);
      } else {
        return (<div className="postError"> <br /> :( <br />{t('uhoo.activity.post.error')}</div>);
      }
    }
  }

  /// ---------- End Queries ---------- ///

  const { t, i18n } = useTranslation(); //  Localization    

  //console.log("CommentPost", props.item);

  return (
    <React.Fragment>
      <div className="headViewPostComments">
        <div className="imgUserViewPostComments">
          {user === true && userInfo.avatar !== null
            ? <img src={userInfo.avatar.thumbnail} alt="user" />
            : ""
          }
        </div>
        <div>
          <div className="userViewPostComments">
            {userInfo.name + " " + userInfo.last_name}
          </div>
          <div className="dateViewPostComments">
            {comment.created_at}
          </div>
        </div>
        {/*<div className="actionViewPostComments pointerCursor">
          <Icon.trash />
        </div>*/}
      </div>

      <div className="txtContentViewPostComments">
        {parseHTML(comment.comment)}
      </div>
    </React.Fragment>
  );
};

export default Comment;






