import React, { useState, useEffect, useContext } from "react";
import ConfigureUser from "../../global/modals/configureUser.jsx";
import { get as _get } from "lodash";
//Localization
import { useTranslation } from "react-i18next";
import Icon from "../../../uhoo-components/iconLibrary.jsx";
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import { auraBasic, auraPro } from '../../../reactUtils/generalUtils/auraPlans'
import { useHistory } from "react-router-dom";

// components
import Modal from "../../../uhoo-components/Modal.jsx";
import CreationAudience from "./../modals/CreationAudience";
import ConfigureRole from "./configureRole.jsx";
import CantCreate from '../../global/modals/cantCreate.jsx'

//Context
import { UsersCommunityContext } from "../contex";
import { ReactComponent as Search } from "../../../assets/icons/navigation/search.svg";
import "./css/communityActions.css";

//query 
import { getUser, getConfigs, getAudience, GetUserRol } from "../../../actions";

const CommunityActions = (props) => {
  const { tabActive, AudienceData, UserQuery } = useContext(UsersCommunityContext);
  let history = useHistory();

  const { t, i18n } = useTranslation(); // Localization

  // modals
  const [openModalCantCreate, setOpenModalCantCreate] = useState(false);
  const [textModalCantCreate, setTextModalCantCreate] = useState('');
  const [openModalCreateAudience, setOpenModalCreateAudience] = useState(false);
  const [openModalRoleConfig, setOpenModalRoleConfig] = useState(false);
  const [openModalCommunityConfig, setOpenModalCommunityConfig] =  useState(false);
  const [maxLengthUsersAdmin, setMaxLengthUsersAdmin] = useState(null);
  const [lengthUsersCreated, setLengthUsersCreated] = useState(0);
  const [maxLengthAudiences, setMaxLengthAudiences] = useState(null);
  const [lengthAudiencesCreated, setLengthAudiencesCreated] = useState(0);
  const [maxLengthRoles, setMaxLengthRoles] = useState(null);
  const [lengthRolesCreated, setLengthRolesCreated] = useState(0);
  const [canCreateUsers, setCanCreateUsers] = useState(true);
  const [canCreateAudience, setCanCreateAudience] = useState(true);

  useEffect(async () => {
    const _configs = await getConfigs();
    let config = _configs.data;
    let _suscription = _get(config.find((element) => { return element.name === "suscription.plan"}), "value", 'basic');
    let query = [{ key: "active", value: "1" }, { key: "page_size", value: 10000 }]
    // const users = await getUser(query);
    const roles = await GetUserRol([{ key: "active", value: "1" },{ key: "page_size", value: 500 }]);
    let rolAdmin;
    roles.data.forEach(element => {
      if(element.name.includes('Admin') || element.name.includes('admin')){
        rolAdmin = element;
      }
    });
    let lengthUsersAdmin = 0;
    UserQuery.forEach(user => {
      if(user.roles && user.roles.length){
        user.roles.forEach(data => {
          if(data.uuid = rolAdmin.uuid){
            lengthUsersAdmin++;
          }
        });
      }
    });
    setLengthUsersCreated(lengthUsersAdmin);
    const audiences = await getAudience(query);
    setLengthAudiencesCreated(audiences.page.total);
    setLengthRolesCreated(roles.page.total);

    if(_suscription == 'pro') {
      setMaxLengthUsersAdmin(_get(auraPro, 'maxLengthUsersAdmin', null));
      setMaxLengthAudiences(_get(auraPro, 'maxLengthAudiences', null));
      setMaxLengthRoles(_get(auraPro, 'maxLengthRoles', null));
    }else{
      setMaxLengthUsersAdmin(_get(auraBasic, 'maxLengthUsersAdmin', null));
      setMaxLengthAudiences(_get(auraBasic, 'maxLengthAudiences', null));
      setMaxLengthRoles(_get(auraBasic, 'maxLengthRoles', null));
    }
    setCanCreateAudience(sessionStorage.getItem("create_channel") == 'true' || sessionStorage.getItem("super_admin") == 'true')
    setCanCreateUsers(sessionStorage.getItem("create_user") == 'true' || sessionStorage.getItem("super_admin") == 'true')

  }, [])
  // }, [UserQuery])


  let URLactual = window.location;
  URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
  let isAura = URLactual.includes("aura");

  const viewAction = () => {
    if (tabActive === "UserCommunity") {
      return (
        <React.Fragment>
          <div onClick={() => configOpenMOdal()} >
            <SecondaryButton c txt={t('uhoo.community.modalConfigureUser.addUser')} />
          </div>
          <div  onClick={() => maxLengthUsersAdmin != null && maxLengthUsersAdmin <= lengthUsersCreated && isAura ? configOpeModalCanCreate(t('basicWords.users')) : history.push('/importUsers')}>
            <SecondaryButton txt='Importar usuarios' />
          </div>
        </React.Fragment>
      );
    }
    if (tabActive === "AudienceCommunity") {
      return (
        <React.Fragment>
          <div  onClick={() => maxLengthAudiences != null && maxLengthAudiences <= AudienceData.length  && isAura ? configOpeModalCanCreate(t('basicWords.audiences')) : setOpenModalCreateAudience(true)}>
            <SecondaryButton txt={t('uhoo.community.action.addAudience')} />
          </div>

          {openModalCreateAudience ? (
            <Modal>
              <CreationAudience
                setOpenModalCreateAudience={setOpenModalCreateAudience}
              />
            </Modal>
          ) : null}
        </React.Fragment>
      );
    }
    if (tabActive === "RoleCommunity") {
      return (
        <React.Fragment>
          <div onClick={() => maxLengthUsersAdmin != null && maxLengthUsersAdmin <= lengthUsersCreated  && isAura ? configOpeModalCanCreate(t('basicWords.roles')) : setOpenModalRoleConfig(true)}>
            <SecondaryButton txt={t('uhoo.community.action.addRol')} />
          </div>

          {openModalRoleConfig ? (
            <Modal>
              <ConfigureRole setOpenModalRoleConfig={setOpenModalRoleConfig} />
            </Modal>
          ) : null}
        </React.Fragment>
      );
    }
  };


  const configOpeModalCanCreate = (text) => {
    setTextModalCantCreate(text);
    setOpenModalCantCreate(true);
  };

  const configOpenMOdal = () => {
    setOpenModalCommunityConfig(true);
  };

  return (
    <div className="containerCommunityActions">
      {openModalCommunityConfig ? (
        <Modal>
          <ConfigureUser
            settingUserValue={{}}
            setOpenModal={setOpenModalCommunityConfig}
            limitUsers={maxLengthUsersAdmin != null && maxLengthUsersAdmin <= lengthUsersCreated && isAura}
          />
        </Modal>
      ) : null}
      <UsersCommunityContext.Consumer>
        {({ setSearch }) => {
          const onSearchValueChange = (event) => {
            setSearch(event.target.value);
            if (document.getElementById("CommunitySearch").value.length > 0) {
              document
                .getElementById("icoCommunitySearch")
                .classList.add("displayNone");
            } else {
              document
                .getElementById("icoCommunitySearch")
                .classList.remove("displayNone");
            }
            props.setPageShow(1)
          };
          return (
            <div className="communitySearch">
              {
                tabActive === "UserCommunity" || tabActive === "AudienceCommunity"?
                <>
                  <Search className="icoCommunitySearch" id="icoCommunitySearch" />
                  <input
                    type="search"
                    className="inputSearch"
                    placeholder={t("basicWords.searchAndFilter")}
                    onChange={(event) => onSearchValueChange(event)}
                    id="CommunitySearch"
                    autoComplete="false"
                    role="presentation"
                  />
                </>
                :
                null
              }
            </div>
          );
        }}
      </UsersCommunityContext.Consumer>
      {viewAction()}

      {openModalCantCreate ? (
        <Modal>
          <CantCreate
            setOpenModal={setOpenModalCantCreate}
            text={textModalCantCreate}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default CommunityActions;
