import React, { useState, useEffect } from "react";

import '../../reactUtils/systemVariables/firebase';
import {
  getAuth,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from "firebase/auth";

import { ReactComponent as UhooLogo } from '../../assets/brand/logotype.svg';
import { ReactComponent as IcoMail } from '../../assets/icons/login/mail.svg';
import logoAura from "../../assets/images/logo.png";
import logoUhoo from "../../assets/images/LogoUhoo.png";

import SystemNotification from "../global/SystemNotification.jsx";

import { Link, useLocation } from "react-router-dom";
import { get as _get } from "lodash";

// CSS
import "./css/SignIn.css";

// Queries
//import { loginValidateUser } from "../../actions/uhoo/Login.actions";
//import { getCustomToken } from "../../actions/uhoo/securityServer.actions";

//Localization
import { useTranslation } from 'react-i18next';

const ForgotPass = (props) => {

  const [showWarningMail, setShowWarningMail] = useState(false);

  const [showInvalidEmail, setShowInvalidEmail] = useState(false);
  const [showUserNotFound, setShowUserNotFound] = useState(false);
  const [showAuthError, setShowAuthError] = useState(false);

  const [showResetEmail, setShowResetEmail] = useState(false);

  const { t, i18n } = useTranslation(); // Localization

  const [email, setEmail] = useState('');
  const [activeLogo, setActiveLogo] = useState("");

  useEffect(() => {
    let URLactual = window.location;
    URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
    let isAura = URLactual.includes("aura");
    let urlParams = new URLSearchParams(window.location.search);
    let _email = urlParams.get('email');
    setEmail(_email)
    if (isAura) {
      setActiveLogo(logoAura);
      const favicon = document.getElementById("favicon"); //Change favicon in Uhoo CMS
      favicon.href = "/logo1.ico";
      document.title = "Somos Aura | CMS";
    } else {
      setActiveLogo(logoUhoo);
    }
  }, []);

  const passwordReset = () => {

    if (email.length === 0) {
      setShowWarningMail(true);
    } else {


      const auth = getAuth();

      sendPasswordResetEmail(auth, email)
        .then(() => {
          // Password reset email sent!
          console.log("Password reset email sent!")
          setShowResetEmail(true);
          // ..
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ...
          console.log("errorCode", errorCode);

          switch (errorCode) {
            case "auth/invalid-email":
              console.error(errorMessage);
              setShowInvalidEmail(true);
              break;
            case "auth/user-not-found":
              console.error(errorMessage);
              setShowUserNotFound(true);
              break;

            default:
              console.log(errorCode, errorMessage);
              setShowAuthError(true);
              break;
          }

        });

    }
  }

  return (
    <React.Fragment>
      <div className="login">
        <div className="containerLogin">
          <div className="logoLogin">
            <img style={{ "maxHeight": "100px" }} src={activeLogo} alt="Header Aura" />
          </div>
          <div className="titleLogin">{t('uhoo.login.recoveryPassword.title')}</div>
          <div className="subTitleLogin">{t('uhoo.login.recoveryPassword.subTitle')}</div>

          <div className="actionLogin">
            <div className="inputLogin">
              <IcoMail className="ico" />
              <input type="email" defaultValue={email} placeholder={t('uhoo.login.enterMail')} className="btnEmail" id="username" onChange={(ev) => setEmail(ev.target.value)} required />
            </div>

            <div className="actionLoginButton">
              <button type="button" onClick={passwordReset}>{t('uhoo.login.recoveryPassword.action')}</button>
            </div>

            <div className="optionActionLogin">
              <div className="left pointerCursor">
                <a href="/">{t('uhoo.login.recoveryPassword.back')}</a>
              </div>
            </div>
          </div>

        </div>
      </div>

      {!!showWarningMail && (
        <SystemNotification
          setShowNotification={setShowWarningMail}
          type="Warning"
          caption={t('uhoo.login.error.mail')}
        />
      )}

      {!!showInvalidEmail && (
        <SystemNotification
          setShowNotification={setShowInvalidEmail}
          type="Error"
          caption={t('uhoo.login.error.invalidMail')}
        />
      )}

      {!!showUserNotFound && (
        <SystemNotification
          setShowNotification={setShowUserNotFound}
          type="Error"
          caption={t('uhoo.login.error.invalidMail')}
        />
      )}

      {!!showResetEmail && (
        <SystemNotification
          setShowNotification={setShowResetEmail}
          type="Success"
          caption={t('uhoo.login.successfulProcess')}
          functionBack={true}
        />
      )}

      {!!showAuthError && (
        <SystemNotification
          setShowNotification={setShowAuthError}
          type="Error"
          caption={t('uhoo.login.failedProcess')}
        />
      )}

    </React.Fragment>

  );
}

export default ForgotPass;
