import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { get as _get } from 'lodash'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// components
import Icon from '../../uhoo-components/iconLibrary.jsx';
import AudienceSelect from "../global/components/create/audienceSelect.jsx";
import SystemNotification from '../global/SystemNotification';
import { auraBasic, auraPro } from '../../reactUtils/generalUtils/auraPlans'
import Modal from '../../uhoo-components/Modal.jsx';
import CantCreate from '../global/modals/cantCreate.jsx'

// css
import "./css/createWidgetForm.css";

// query
import { getConfigs, putConfigsById, postMediaArbitrary } from "./../../actions/uhoo"
import { useHistory } from "react-router-dom";

//Localization
import { useTranslation } from 'react-i18next';

function BillboardForm(props) {

  props.setActiveTab("Billboard");

  let history = useHistory();
  const { t, i18n } = useTranslation(); // Localization
  /**
   * States
   */
  const [nameWidget, setNameWidget] = useState("");
  const [urlWidget, setUrlWidget] = useState("");
  const [routeWidget, setRouteWidget] = useState(null);
  const [typeWidget, setTypeWidget] = useState("");
  const [audiences, setAudiences] = useState([]);

  const [menuForm, setMenuForm] = useState("");

  const [icoRender, setIcoRender] = useState(null);
  const [iconWidget, setIconWidget] = useState(null);
  const [imageRender, setImageRender] = useState(null);
  const [imageWidget, setImageWidget] = useState(null);
  const [videoRender, setVideoRender] = useState(null);
  const [videoWidget, setVideoWidget] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [maxLengthBoard, setMaxLengthBoard] = useState(null);
  const [lengthBoardCreated, setLengthBoardCreated] = useState(0);

  // notifys
  const [showNotificatioError, setShowNotificatioError] = useState(false);
  const [showNotificationWarning, setShowNotificationWarning] = useState(false);
  const [showNotificationSuccessfull, setShowNotificationSuccessfull] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState('');
  const [showNotificationCheckAudiences, setShowNotificationCheckAudiences] = useState(false);
  const [channelPublish, setChannelPublish] = useState([]);

  const [openModalCantCreate, setOpenModalCantCreate] = useState(false);
  const [textModalCantCreate, setTextModalCantCreate] = useState('');
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [progressUploaded, setProgressUploaded] = useState(0);
  const [readyAudiences, setReadyAudiences] = useState(false);

  props.setPreviewTitle(nameWidget); /// preview


  useEffect(async () => {
    const _configs = await getConfigs();
    let config = _configs.data;
    let _suscription = _get(config.find((element) => { return element.name === "suscription.plan"}), "value", 'basic');
    let currentConfigBoard = config.find(config => config.name == 'app.community.board.elements');
    currentConfigBoard = _get(currentConfigBoard, 'value', {});
    let lengthBoard = 0;
    for (const element in currentConfigBoard) {
      lengthBoard++;
    }
    setLengthBoardCreated(lengthBoard);
    if(_suscription == 'pro') {
      setMaxLengthBoard(_get(auraPro, 'maxLengthBoard', null));
    }else{
      setMaxLengthBoard(_get(auraBasic, 'maxLengthBoard', null));
    }
  }, [])

  let URLactual = window.location;
  URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
  let isAura = URLactual.includes("aura");

  /**
   * Methods
   */
  const fileCIco = (e) => {
    let file = e.target.files[0];

    props.setPreviewImage(URL.createObjectURL(file)); /// preview 

    var fr = new FileReader();
    fr.onload = () => {
      setIcoRender(fr.result)
    }
    fr.readAsDataURL(file);
    setIconWidget(file)
  }
  const fileImage = (e) => {
    let file = e.target.files[0];

    //props.setPreviewImage(URL.createObjectURL(file)); /// preview 

    var fr = new FileReader();
    fr.onload = () => {
      setImageRender(fr.result)
    }
    fr.readAsDataURL(file);
    setImageWidget(file)
  }
  const fileVideo = (e) => {
    let file = e.target.files[0];

    //props.setPreviewImage(URL.createObjectURL(file)); /// preview 

    var fr = new FileReader();
    fr.onload = () => {
      setVideoRender(fr.result)
    }
    fr.readAsDataURL(file);
    setVideoWidget(file)
  }
  const cleanNotify = () => {
    setShowNotificationMessage("")
    setShowNotificationWarning(false)
    setShowNotificatioError(false)
    setShowNotificationSuccessfull(false)
  }

  const toSave = async () => {
    setIsLoadingPost(true);
    cleanNotify();
    // check requeriments

    // process
    let configs
    try {
      configs = await getConfigs();
    } catch (error) {
      setShowNotificationMessage("Error to get configs")
      setShowNotificatioError(true);
      return;
    }

    if(channelPublish.length == 0){
      setShowNotificationCheckAudiences(true);
      setIsLoadingPost(false);
      return;
    }

    if(typeWidget == ''){
      setShowNotificationMessage(t('basicWords.modal.errorWIdget.type'))
      setShowNotificationWarning(true);
      setIsLoadingPost(false);
      return;
    }

    if(typeWidget == 'web_embedded'){
      if(urlWidget == ''){
        setShowNotificationMessage(t('basicWords.modal.errorWIdget.url'))
        setShowNotificationWarning(true);
        setIsLoadingPost(false);
        return;
      }
    }

    if(typeWidget == 'internal_link'){
      if(routeWidget == null){
        setShowNotificationMessage(t('basicWords.modal.errorWIdget.route'))
        setShowNotificationWarning(true);
        setIsLoadingPost(false);
        return;
      }
    }

    if(typeWidget == 'other_image'){
      if(imageRender == null){
        setShowNotificationMessage(t('basicWords.modal.errorWIdget.image'))
        setShowNotificationWarning(true);
        setIsLoadingPost(false);
        return;
      }
    }

    if(typeWidget == 'video'){
      if(videoRender == null){
        setShowNotificationMessage(t('basicWords.modal.errorWIdget.video'))
        setShowNotificationWarning(true);
        setIsLoadingPost(false);
        return;
      }
    }

    if(nameWidget == ''){
      setShowNotificationMessage(t('basicWords.modal.errorWIdget.name'))
      setShowNotificationWarning(true);
      setIsLoadingPost(false);
      return;
    }


    if(iconWidget == null){
      setShowNotificationMessage(t('basicWords.modal.errorWIdget.image'))
      setShowNotificationWarning(true);
      setIsLoadingPost(false);
      return;
    }

    
    
    

    let currentConfig = _get(configs, 'data', []).find(config => config.name == 'app.community.board.elements');
    let widges = _get(currentConfig, 'value', {});
    console.log("currentConfig", currentConfig);

    // upload media
    var fdata = new FormData();
    fdata.append("properties", "");
    fdata.append("image", iconWidget);
    fdata.append("thumbnail", iconWidget);
    let iconMediaArbitrary;
    try {
      iconMediaArbitrary = await postMediaArbitrary(fdata)
    } catch (error) {
      setShowNotificationMessage("Error to upload image")
      setShowNotificatioError(true);
      return;
    }

    console.log("iconMediaArbitrary", iconMediaArbitrary);
    console.log("imageWidget", imageWidget);

    let imageMediaArbitrary; 
    if(imageWidget){
      // upload media
    var fdata = new FormData();
    fdata.append("properties", "");
    fdata.append("image", imageWidget);
    fdata.append("thumbnail", imageWidget);
    try {
      imageMediaArbitrary = await postMediaArbitrary(fdata)
    } catch (error) {
      setShowNotificationMessage("Error to upload image")
      setShowNotificatioError(true);
      return;
    }
    }

    let videoMediaArbitrary; 
    if(videoWidget){
      // upload media
    var fdata = new FormData();
    fdata.append("properties", "");
    fdata.append("image", videoWidget);
    fdata.append("thumbnail", videoWidget);
    try {
      videoMediaArbitrary = await postMediaArbitrary(fdata)
    } catch (error) {
      setShowNotificationMessage("Error to upload image")
      setShowNotificatioError(true);
      return;
    }
    }

    let newWidget = {
      "id": Object.keys( _get(currentConfig, 'value', {}) ).length,
      "order": Object.keys( _get(currentConfig, 'value', {}) ).length,
      "key_current": uuidv4(),
      "name": nameWidget ,
      "kind":typeWidget,
      "route": routeWidget,
      "url": urlWidget,
      "icon": _get(iconMediaArbitrary, 'data[0].url', ''),
      "image": _get(imageMediaArbitrary, 'data[0].url', ''),
      "video": _get(videoMediaArbitrary, 'data[0].url', ''),
      "audiences": channelPublish,
      "enabled": true
    }

    // set data widget
    widges[Object.keys( _get(currentConfig, 'value', {}) ).length] = newWidget

    // payload
    // https://api.brilliant360.net/api/config/9a1b56cd-338c-4b4c-b7ec-45f4ce3f7deb
    let configToSave = {
      "uuid": _get(currentConfig, 'uuid', ''),
      "name": "app.community.board.elements",
      "value": JSON.stringify(widges),
      "community": _get(currentConfig, 'community', ''),
      "properties": {},
      "editable": true
    }
    const onUploadProgress = (event) => {
      const percentage = Math.round((100 * event.loaded) / event.total);
      setProgressUploaded(percentage)
    };
    let result;
    try {
      result = await putConfigsById(_get(currentConfig, 'uuid', ''), configToSave, onUploadProgress);
    } catch (error) {
      setShowNotificationMessage("Error in creation widget")
      setShowNotificatioError(true);
      return;
    }
    setShowNotificationMessage("Widget creado correctamente")
    setShowNotificationSuccessfull(true);
    setTimeout(()=>{setShowNotificationSuccessfull(false);}, 2000);
    setTimeout(()=>{history.goBack();}, 1000);
    setIsLoadingPost(false);
  }


  useEffect(() => {
    switch (typeWidget) {
      case "web_embedded":
        setMenuForm(
          <div className="title">
            <div className="WidgetformTitles">{/*t('uhoo...')*/}{t('basicWords.url')}:</div>
            <input type="text" placeholder={t('uhoo.widget.url')} onChange={e => setUrlWidget(e.target.value)} className="inputForms" /> {/*t('uhoo...')*/}
          </div>
        );
        break;
      case "internal_link":
        setMenuForm(
          <div className="title">
            <div className="WidgetformTitles">{/*t('uhoo...')*/}{t('uhoo.widget.rute')}:</div>

            <input type="radio" id="wall" value="wall" name="internal_link" onClick={() => setRouteWidget("Feed")}/>
            <label htmlFor="wall"> {t('uhoo.widget.wall')}</label><br />

            <input type="radio" id="complementos" value="complementos" name="internal_link" onClick={() => setRouteWidget("Webview")}/>
            <label htmlFor="complementos"> {t('uhoo.widget.complement')}</label><br />
          </div>
        );
        break;
      case "zoom_image":
        setMenuForm("");
        break;
      case "other_image":
        setMenuForm(
          <div className="attachments">
            {
              imageRender ?
                <>
                  <div
                    className="contentAttachments"
                    onClick={() => document.getElementById("attachmentsUploadWidgetImage").click()}
                    style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                  >
                    <img src={imageRender} width="100%" height="100%" style={{ objectFit: 'contain' }} />
                  </div>
                </>
                :
                <>
                  <div
                    className="contentAttachments"
                    onClick={() => document.getElementById("attachmentsUploadWidgetImage").click()}
                    style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                  >
                    <div className="WidgetformTitles">{t('uhoo.widget.image')}</div>
                    <Icon.upload />
                    {t('uhoo.createPost.form.uploadAttachments')}
                  </div>
                </>
            }
            <input
              hidden
              id="attachmentsUploadWidgetImage"
              type="file"
              accept="image/*, .png, .jpeg, .jpg"
              onChange={fileImage}
            />
          </div>
        );
        break;
      case "video":
        setMenuForm(
          <div className="attachments">
            {
              videoRender ?
                <>
                  <div
                    className="contentAttachments"
                    onClick={() => document.getElementById("attachmentsUploadWidgetVideo").click()}
                    style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                  >
                    <video src={videoRender} width="100%" height="100%" style={{ objectFit: 'contain' }} />
                  </div>
                </>
                :
                <>
                  <div
                    className="contentAttachments"
                    onClick={() => document.getElementById("attachmentsUploadWidgetVideo").click()}
                    style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                  >
                    <div className="WidgetformTitles">{t('uhoo.widget.video')}</div>
                    <Icon.upload />
                    {t('uhoo.createPost.form.uploadAttachments')}
                  </div>
                </>
            }
            <input
              hidden
              id="attachmentsUploadWidgetVideo"
              type="file"
              accept="video/*"
              onChange={fileVideo}
            />
          </div>
        );
        break;
      default:
        setMenuForm("");
        break;
    }

  }, [typeWidget, imageRender, videoRender]);

  const configOpeModalCanCreate = () => {
    setTextModalCantCreate(t('basicWords.buttons.buttons'));
    setOpenModalCantCreate(true);
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress className='buttonLoading' />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  let _user = JSON.parse(sessionStorage.getItem("own"));

  return (
    <React.Fragment>
      <div className="contentsWidgetform">
        {!readyAudiences?
          <div className="col-xs-12 col-md-12 circularProgressCenter" >
            <CircularProgress />
          </div>
        :
          null
        }
        <div className="audience">
          <div className="WidgetformTitles">{t('uhoo.createPost.form.audienceTitle')}</div>
            <AudienceSelect user={_user} setAudiencePublish={setAudiences} fullAudience={true} setChannelPublish={setChannelPublish} setReadyAudiences={setReadyAudiences}/>
          </div>
        <div className="title">
          <div className="WidgetformTitles">{t('uhoo.widget.type')}</div>
          <select className="inputForms" onChange={e => setTypeWidget(e.target.value)}>
            <option value="" selected disabled hidden> {t('basicWords.selectOption')} </option>
            <option value="web_embedded">{t('uhoo.widget.typeEmbedded')}</option>
            <option value="internal_link">{t('uhoo.widget.typeInternal')}</option>
            <option value="zoom_image">{t('uhoo.widget.typeExtendImage')}</option>
            <option value="other_image">{t('uhoo.widget.typeOpenImage')}</option>
            <option value="video">{t('uhoo.widget.typeVideo')}</option>
          </select>
        </div>

        {menuForm}

        <div className="title">
          <div className="WidgetformTitles">{/*t('uhoo...')*/}{t('basicWords.name')}:</div>
          <input type="text" placeholder={t('uhoo.widget.name')} onChange={e => setNameWidget(e.target.value)} className="inputForms" /> {/*t('uhoo...')*/}
        </div>

        <div className="attachments">
          {
            icoRender ?
              <>
                <div
                  className="contentAttachments"
                  onClick={() => document.getElementById("attachmentsUploadWidgetIcon").click()}
                  style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                >
                  <img src={icoRender} width="100%" height="100%" style={{ objectFit: 'contain' }} />
                </div>
              </>
              :
              <>
                <div
                  className="contentAttachments"
                  onClick={() => document.getElementById("attachmentsUploadWidgetIcon").click()}
                  style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                >
                  <div className="WidgetformTitles">{t('uhoo.widget.ico')}</div>
                  <Icon.upload />
                  {t('uhoo.createPost.form.uploadAttachments')}
                </div>
              </>
          }
          <input
            hidden
            id="attachmentsUploadWidgetIcon"
            type="file"
            accept="image/*, .png, .jpeg, .jpg"
            onChange={fileCIco}
          />
        </div>

        

        { isLoadingPost ?
            <div className="actionsWidget">
              <div className="toWidgetDisabled" ><CircularProgressWithLabel value={progressUploaded}/></div>
            </div>
            :
            <div className="actionsWidget">
          <div className="toWidget" onClick={() => maxLengthBoard != null && maxLengthBoard <= lengthBoardCreated && isAura ? configOpeModalCanCreate() : toSave()}> {t('uhoo.widget.create')} </div>
        </div>
        }

      </div>

      {/*  Notifications  */}
      {!!showNotificationSuccessfull && (
        <SystemNotification
          setShowNotification={setShowNotificationSuccessfull}
          type="Success"
          caption={showNotificationMessage}
        />
      )}

      {!!showNotificationWarning && (
        <SystemNotification
          setShowNotification={setShowNotificationWarning}
          type="Warning"
          caption={showNotificationMessage}
        />
      )}

      {!!showNotificatioError && (
        <SystemNotification
          setShowNotification={setShowNotificatioError}
          type="Error"
          caption={showNotificationMessage}
        />
      )}
      {!!showNotificationCheckAudiences && (
        <SystemNotification
          setShowNotification={setShowNotificationCheckAudiences}
          type="Warning"
          caption={t('basicWords.mustSelectAudiences')} 
        />
      )}
      {openModalCantCreate ? (
        <Modal>
          <CantCreate
            setOpenModal={setOpenModalCantCreate}
            text={textModalCantCreate}
          />
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default BillboardForm;