import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import "./css/GeneralModal.scss";

import { ButtonComponent } from "./index";

import { AlertCircle, CheckCircle, XCircle } from "react-feather";

//Localization
import { useTranslation } from "react-i18next";

export function NotificationModal(props) {
  const { visible, title, content, type, onClick } = props;
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Dialog open={visible}>
        {/* <DialogTitle>
          <div className="headerDialog">
            {title}
            <Button onClick={onClick}>
              <X className="iconCloseDialog" />
            </Button>
          </div>
        </DialogTitle> */}
        <span className="borderBottomTitleDialog" />
        <DialogContent>
          <DialogContentText className="widthAndHeigthDialog">
            <div className="contentModal">
              <div className="iconModal">
                {type === "warning" ? (
                  <div>
                    {" "}
                    <AlertCircle className="alertIcon" />{" "}
                  </div>
                ) : null}
                {type === "success" ? (
                  <div>
                    {" "}
                    <CheckCircle className="checkIcon" />{" "}
                  </div>
                ) : null}
                {type === "error" ? (
                  <div>
                    {" "}
                    <XCircle className="xIcon" />{" "}
                  </div>
                ) : null}
              </div>
              <div className="titleModal"> {title} </div>
            </div>
            <div className="row componentSpace">
              <div className="descriptionModal">{content}</div>
            </div>
            <div className="row componentSpace buttonAtTheBottom">
              <ButtonComponent
                onClick={onClick}
                props={null}
                shape="round"
                className="buttonToSave"
                text={t("basicWords.ok")}
              />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
