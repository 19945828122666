import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Card, CircularProgress } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { Tooltip } from "@mui/material";
import "../css/sections.scss";
import ButtonsContainer from "../../../../aura-components/ButtonsContainer.jsx";

// Queries
import {
  getLandingpageStrucureById,
  getInformationBySectionIdAndLPId,
  updateLpSectionComponentNode,
  getFile,
  getSectionLayoutStyle,
  updateLpSectionComponentNodeImage,
  updateLpSectionLayout,
  updateSectionComponent
} from "../../../../actions/aura";
// Components
import {
  Title,
  ButtonComponent,
  BreadcrumbSection,
  GeneralInput,
  GeneralModal,
  ButtonsFooterComponent,
} from "../../../../aura-components";

import SystemNotification from "../../../global/SystemNotification";

import SelectLayoutModal from "../../../../aura-components/SelectLayoutModal.jsx";
import { handleChangeImageFunction } from "../../../../reactUtils/generalUtils/handleImageChange.jsx";

//Localization
import { withTranslation } from "react-i18next";
import { Plus, X } from "react-feather";

class HeaderSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      landingPageId: null,
      organizationId: null,
      organizationName: null,
      lpSectionId: null,
      sectionName: null,
      country: null,
      region: null,
      city: null,
      headerTitleToUpdate: {
        id: null,
        value: null,
        nodeTypeName: "TEXT",
      },
      logoImg: {
        id: null,
        file: null,
        image: null,
        loading: false,
        modified: false,
      },
      lpSections: [],
      isLoadingButton: false,
      FlagClassName: false,
      goToWithoutSaveModalVisible: false,

      homeButtons: {
        buttons: [],
        edited: false,
      },

      //Modal notification
      showSuccess: false,
      showWarning: false,
      showError: false,
      contentNotificationModal: "",

      //Modal general
      openDialog: false,

      //LayoutSelector
      newLayoutId: null,
      oldLayoutId: null,
    };

    this.imgRef = React.createRef();
  }
  imgEl = React.createRef();
  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    const { location } = this.props;
    const { props } = location;
    if (props) {
      this.getSectionsOrder(props.landingPageId);
      this.getHeaderInformation(props.landingPageId, props.sectionId);
      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
      this.handleSelectLayout();
    } else {
      this.warning();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  // Queries

  async getSectionsOrder(lpId) {
    const response = await getLandingpageStrucureById(lpId);
    var lpSections = [];
    response.data.data.lpsections.forEach((section, indexSection) => {
      if (section.mustBeSubpage !== 1 && section.subpages.length === 0) {
        lpSections.push(section);
      }
    });

    var positionSections = lpSections.map(
      (element) => element.positionInMainPage
    );
    const lastSectionPosition = Math.max.apply(null, positionSections);

    function compare(a, b) {
      if (a.positionInMainPage === null) {
        a.positionInMainPage = lastSectionPosition + 2;
      }
      if (a.name === "FOOTER") {
        a.positionInMainPage = lastSectionPosition + 2;
      }
      if (b.name === "FOOTER") {
        b.positionInMainPage = lastSectionPosition + 2;
      }
      if (a.name === "CONTACT") {
        a.positionInMainPage = lastSectionPosition + 1;
      }
      if (b.name === "CONTACT") {
        b.positionInMainPage = lastSectionPosition + 1;
      }
      if (a.positionInMainPage > b.positionInMainPage) {
        return 1;
      }
      if (b.positionInMainPage > a.positionInMainPage) {
        return -1;
      }
      return 0;
    }

    lpSections.sort(compare);

    this.setState({
      lpSections,
    });
  }

  async getHeaderInformation(lpId, lpSectionId) {
    const response = await getInformationBySectionIdAndLPId(
      lpId,
      lpSectionId,
      "HEADER"
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      const { headerTitleToUpdate } = { ...this.state };
      const sectionInfo = response.data.data;
      const logoImg = { ...this.state.logoImg };
      const homeButtons = { ...this.state.homeButtons };

      const headerComponent = sectionInfo.components.find(
        (comp) => comp.name === "HeaderComponent"
      );
      headerTitleToUpdate.id = headerComponent.nodes.find(
        (node) => node.nodeType === "HeaderTitle"
      ).id;
      headerTitleToUpdate.value = headerComponent.nodes.find(
        (node) => node.nodeType === "HeaderTitle"
      ).value;
      headerTitleToUpdate.nodeTypeName = headerComponent.nodes.find(
        (node) => node.nodeType === "HeaderTitle"
      ).nodeType;
      headerTitleToUpdate.minLength = headerComponent.nodes.find(
        (node) => node.nodeType === "HeaderTitle"
      ).minLength;
      headerTitleToUpdate.maxLength = headerComponent.nodes.find(
        (node) => node.nodeType === "HeaderTitle"
      ).maxLength;
      headerTitleToUpdate.length = headerComponent.nodes.find(
        (node) => node.nodeType === "HeaderTitle"
      ).value.length;
      const headerFontSize = Math.round(
        headerTitleToUpdate.value.length * -0.78 + 42.85
      );

      logoImg.id = headerComponent.nodes.find(
        (node) => node.nodeType === "HeaderLogo"
      ).id;

      logoImg.file = headerComponent.nodes.find(
        (node) => node.nodeType === "HeaderLogo"
      ).value;
      logoImg.loading = true;

      if (logoImg.file !== "") {
        // Get logo image from s3
        const logoResponse = await getFile(
          "https://somosaura-cms.s3.amazonaws.com/" + logoImg.file
        );
        logoImg.image = logoResponse ? logoResponse : null;
        logoImg.loading = false;
      }

      //Get layout image
      let supgage = null;
      if (this.state.subpageId && this.state.subpageId !== 10001) {
        supgage = sectionInfo.subpages.find(
          (sp) => sp.id === this.state.subpageId
        );
      } else {
        supgage = sectionInfo.subpages.find((sp) => sp.id === null);
      }
      let subpageIndex = sectionInfo.subpages.indexOf(supgage);
      if (subpageIndex === -1) {
        subpageIndex = 0;
      }
      let layout =
        response.data.data.subpages[subpageIndex].lpSectionLayoutStyle
          .sectionLayoutId;

      homeButtons.buttons = sectionInfo.components[0].buttons;

      const lpSectionComponentId = sectionInfo.components[0].id;


      this.setState({
        homeButtons,
        headerTitleToUpdate,
        headerFontSize,
        lpSectionLayoutStyle: layout,
        lpSectionComponentId,
        logoImg,
      });
    }
  }
  async updateNode(nodesToUpdate) {
    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),

      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.headerSuccessfullyEdited"),
      });
      this.getHeaderInformation(
        this.state.landingPageId,
        this.state.lpSectionId
      );
    }
    this.setState({ isLoadingButton: false });
  }
  async updateSectionComponentFunction(componentToUpdate) {
    const { landingPageId, lpSectionId } = this.state;
    const response = await updateSectionComponent(componentToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      // Modal
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      // Modal
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.homeSuccessfullyEdited"),
        isLoadingButton: false,
      });
      this.getHeaderInformation(landingPageId, lpSectionId);
    }

    this.setState({
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  }

  async getLayoutFunction(lpId) {
    let response = await getSectionLayoutStyle(lpId, 1);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      let responseData = response.data.data;
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].sampleLayoutImageS3Path) {
          //Get image
          let img = await getFile(
            "https://somosaura-cms.s3.amazonaws.com/" +
            responseData[i].sampleLayoutImageS3Path
          );
          responseData[i].img = img;
        }
      }
      responseData = responseData.filter(data => data.name != "HEADER BUTTON LAYOUT");
      this.setState({
        layoutData: responseData,
      });
    }
  }

  async updateLpSectionLayoutFunction(lpSectionId, newLayoutId) {
    let response = await updateLpSectionLayout(lpSectionId, newLayoutId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),

      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
    }
    this.setState({ isLoadingButton: false });
  }

  async uploadFileToS3Bucket(imageFile, key) {
    await updateLpSectionComponentNodeImage(imageFile, key);
  }

  // Events
  handleChange = (event) => {
    const { id, value } = event.target;
    const { headerTitleToUpdate } = { ...this.state };
    if (value.length <= headerTitleToUpdate.maxLength) {
      if (id === "HeaderTitle") {
        headerTitleToUpdate.value = value;
        headerTitleToUpdate.modified = true;
        headerTitleToUpdate.length = value.length;
      }
      this.setState({
        headerTitleToUpdate,
        FlagClassName: true,
      });
    }
  };

  handleChangeLayout = (e, id) => {
    this.setState({
      // newLayoutId: id,
      saveButtonDisabled: false,
      FlagClassName: true,
      lpSectionLayoutStyle: id,
      haveChangeInLayout: true,
    });
  };

  handleButtonListChange = (buttonList) => {
    let { homeButtons } = this.state;
    homeButtons.buttons = buttonList;
    homeButtons.edited = true;

    this.setState({ homeButtons, FlagClassName: true });
  };

  handleChangeImage = async (event) => {
    const { country, city, organizationId } = this.state;
    var logoImg = { ...this.state.logoImg };
    var fileUpload = event.target.files[0];
    let extension;
    if (fileUpload !== undefined) {
      extension = fileUpload.type.replace("image/", "");
    }
    const random = uuidv4();
    const key = `content/${country}/${city}/${organizationId}/img${random}.${extension}`;

    if (!extension) {
      logoImg.loading = true;
      logoImg.file = null;
      logoImg.image = null;
      this.setState({
        logoImg,
        FlagClassName: true,
      });
      return;
    }

    if (key !== undefined) {
      // Function to resize and reduce image quality
      let flag = true;
      logoImg = await handleChangeImageFunction(
        logoImg,
        fileUpload,
        extension,
        key,
        400,
        400,
        10,
        10
      ).catch((res) => {
        const { t } = this.props;
        this.setState({
          showError: true,
          contentNotificationModal: `${t(`${res.content}`).replace("R1", res.params.R1).replace("R2", res.params.R2)}`,
        });
        flag = false;
      });
      if (flag !== false) {
        if (logoImg.loading === false) {
          // Save image in AWS
          this.uploadFileToS3Bucket(logoImg.file, key);
          this.setState({
            logoImg,
            FlagClassName: true,
          });
          this.forceUpdate();
        }
      }
    }
  };

  saveHeaderEvent = () => {
    const nodesToUpdate = [];
    const {
      headerTitleToUpdate,
      logoImg,
      haveChangeInLayout,
      lpSectionId,
      lpSectionLayoutStyle,
      homeButtons,
      landingPageId,
      lpSectionComponentId
    } = { ...this.state };

    const { t } = this.props;

    if (homeButtons.edited === true) {
      let hButtons = homeButtons.buttons
        ? homeButtons.buttons.map((b) => {
          const button = {
            id: b.id,
            name: b.name,
            externalLink: b.externalLink,
            internalLpSectionComponentId: b.component
              ? b.component.id
              : b.internalLpSectionComponentId
                ? b.internalLpSectionComponentId
                : null,
            isEnabled: b.isEnabled,
          };
          return button;
        })
        : [];
      const componentToUpdate = {
        // --- Con esto se crea el registro lpSectionComponent ---
        // Con estos dos valores se obtiene el lpSectionId
        lpId: landingPageId, // Este valor se obtiene de la vista anterior
        lpSectionId: lpSectionId,
        sectionId: 1, // Valor estático para la sección
        id: lpSectionComponentId,
        sectionComponentId: 1, // Valor estático de 20, valor para el DonationComponent

        parentLPSectionComponentId: "", // Valor estático de 4 puesto que el id 4 es el padre de este nuevo componente

        // --- Con el Id del lpSectionComponent creado, se crean los nodos de este componente
        nodesToAdd: [],
        buttons: hButtons,
      };

      this.updateSectionComponentFunction(componentToUpdate);
    }

    if (headerTitleToUpdate.value.length > 0) {
      headerTitleToUpdate.value = headerTitleToUpdate.value.trim();
      headerTitleToUpdate.value = headerTitleToUpdate.value.toUpperCase();
      if (headerTitleToUpdate.modified) {
        delete headerTitleToUpdate.modified;
        delete headerTitleToUpdate.minLength;
        delete headerTitleToUpdate.maxLength;
        delete headerTitleToUpdate.length;
        nodesToUpdate.push(headerTitleToUpdate);
      }
      if (logoImg.modified) {
        const node = {};
        node.id = logoImg.id;
        node.value = logoImg.value;
        node.nodeType = "IMAGE";
        nodesToUpdate.push(node);
      }
      if (haveChangeInLayout) {
        this.updateLpSectionLayoutFunction(lpSectionId, lpSectionLayoutStyle);
      }
      if (nodesToUpdate.length > 0) {
        this.updateNode(nodesToUpdate);
      }
      this.setState({
        FlagClassName: false,
        isLoadingButton: true,
      });
    } else {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.fieldEmpty"),
      });
    }
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      showWarning: true,
      contentNotificationModal: t("basicWords.modal.noSectionSelected"),
    });
    this.handleOkWarning();
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleSelectLayout() {
    this.getLayoutFunction(this.props.location.props.landingPageId);

    this.setState({ layoutModalVisible: true });
  }

  setLayoutModalVisible = (isVisible) => {
    this.setState({ layoutModalVisible: isVisible });
  };

  //-----------Update page after modal------------
  updatePage = (lpSectionId, previousPath) => {
    const { landingPageId } = this.state;
    this.getHeaderInformation(landingPageId, lpSectionId);
    this.setState({
      previousPath,
    });
    this.setState({ layoutModalVisible: false });
    this.forceUpdate();
  };

  disableImage = (element) => {
    this.setState({
      isLoadingButton: true,
      FlagClassName: true,
    });
    const nodesToUpdate = [];
    const node = {};
    node.id = element.id;
    node.value = "";
    node.nodeType = "IMAGE";

    nodesToUpdate.push(node);
    this.getHeaderInformation(this.state.landingPageId, this.state.lpSectionId);
    this.updateNode(nodesToUpdate);
    this.handleCloseDialog();
  };

  handleClickOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  setShowError = (open) => {
    this.setState({ showError: open });
  };

  setShowSuccess = (open) => {
    this.setState({ showSuccess: open });
  };

  setShowWarning = (open) => {
    this.setState({ showWarning: open });
  };

  render() {
    const {
      redirectBack,
      sectionName,
      headerTitleToUpdate,
      organizationId,
      isLoadingButton,
      FlagClassName,
      layoutData,
      lpSectionLayoutStyle,
      landingPageId,
      logoImg,
      lpUrl,
      openDialog,
      goToWithoutSaveModalVisible,
      homeButtons,

      // Modals
      showSuccess,
      showWarning,
      showError,
      contentNotificationModal
    } = this.state;

    const { t } = this.props;

    if (redirectBack) {
      return <Redirect to="/manage/web" />;
    }

    return (
      <Fragment>
        {!!showWarning && (
          <SystemNotification
            setShowNotification={(e) => this.setShowWarning(e)}
            type="Warning"
            caption={contentNotificationModal}
          />
        )}
        {!!showSuccess && (
          <SystemNotification
            setShowNotification={(e) => this.setShowSuccess(e)}
            type="Success"
            caption={contentNotificationModal}
          />
        )}
        {!!showError && (
          <SystemNotification
            setShowNotification={(e) => this.setShowError(e)}
            type="Error"
            caption={contentNotificationModal}
          />
        )}

        <section className="sectionContainer">
          <Card className="card">
            {isLoadingButton === true ? (
              <div
                className="col-xs-12 col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  transform: "translateY(800%)",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="row componentSpace spaceBreadcrumb">
                  <BreadcrumbSection
                    principal={t("sections.header")}
                    secondary={this.state.organizationName}
                    route="./manage/web"
                    propsComponent={{
                      organizationId,
                      sectionName: sectionName,
                      lpId: landingPageId,
                      lpUrl: lpUrl,
                    }}
                    FlagclassName={FlagClassName}
                    onClick={(e) => this.saveAboutEvent(e)}
                    GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                    onCancelGotoWithoutMModal={(e) =>
                      this.onCancelGotoWithoutMModal(e)
                    }
                    goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                  />
                </div>

                <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                  <Title
                    title={t("basicWords.completeInformationOfYourSection")}
                  />
                </div>
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <SelectLayoutModal
                    layoutData={layoutData}
                    lpLayoutId={
                      lpSectionLayoutStyle ? lpSectionLayoutStyle : null
                    }
                    handleChange={this.handleChangeLayout}
                  />
                  <br />
                </div>

                <Card
                  className="card"
                  style={{ margin: "2% 4.5%", height: "auto" }}
                >
                  <div className="row mainContent componentSpace">
                    <div
                      className="rowSection componentSpace"
                      style={{ margin: "2%" }}
                    >
                      <div style={{ width: "60vh" }}>
                        <GeneralInput
                          placeholder={t("basicWords.enterTitle")}
                          id={headerTitleToUpdate
                            ? headerTitleToUpdate.nodeTypeName
                            : null
                          }
                          onChange={(e, id) => this.handleChange(e, id)}
                          value={
                            headerTitleToUpdate ? headerTitleToUpdate.value : null
                          }
                        />
                        {headerTitleToUpdate.value &&
                          headerTitleToUpdate.maxLength ? (
                          <span>
                            {headerTitleToUpdate.length} /{" "}
                            {headerTitleToUpdate.maxLength}
                          </span>
                        ) : null}
                      </div>
                      <div className="textLogo">{t("basicWords.enterLogo")}</div>
                      {/* Tooltip image */}
                      <Tooltip title={t("generalMessages.imagesFormat")}>
                        <div>
                          <div className="cardImages">
                            <div className="row">
                              <div className="iconXCardImages">
                                <a>
                                  <X
                                    className="iconImageEditInSection"
                                    onClick={this.handleClickOpenDialog}
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="row">
                              <div className="centerImage">
                                <div>
                                  <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={(e) => this.handleChangeImage(e)}
                                    ref={this.imgRef}
                                    hidden
                                  />
                                </div>
                                <label htmlFor="contained-button-file">
                                  <div className="imageEdit">
                                    <div>
                                      {logoImg.file !== "" ? (
                                        <img
                                          src={logoImg.image}
                                          alt="avatar"
                                          className="widthAndHeightImageSection"
                                        />
                                      ) : (
                                        <Plus className="iconAddImage" />
                                      )}

                                      <GeneralModal
                                        visible={openDialog}
                                        title={t("basicWords.deleteItem")}
                                        footer={[
                                          <div
                                            className="row"
                                            key={1}
                                            style={{ display: "flex" }}
                                          >
                                            <div className="col-6">
                                              <ButtonComponent
                                                onClick={() =>
                                                  this.handleCloseDialog()
                                                }
                                                props={null}
                                                shape="round"
                                                text={t("basicWords.cancelText")}
                                              />
                                            </div>
                                            <div>
                                              <ButtonComponent
                                                onClick={() =>
                                                  this.disableImage(logoImg)
                                                }
                                                props={null}
                                                shape="round"
                                                text={t("basicWords.okText")}
                                              />
                                            </div>
                                          </div>,
                                        ]}
                                        onClick={() => this.handleCloseDialog()}
                                      />
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                    {/* Image render */}

                    <div className="accordionCenterLine">
                      <div className="accordionCompleteInputs">
                        {/* Fourth Line */}
                        <div className="rowSections componentSpace">
                          <div className="textLogo">
                            {t("basicWords.buttons.buttons")}
                          </div>
                        </div>
                        {/* Fifth Line */}

                        <div
                          className="rowSections componentSpace"
                          style={{
                            marginLeft: "2%",
                          }}
                        >

                          {landingPageId ? (
                            <ButtonsContainer
                              isModalVisible={true}
                              lpId={landingPageId}
                              buttonList={homeButtons.buttons}
                              handleButtonListChange={(buttonList) =>
                                this.handleButtonListChange(buttonList)
                              }
                              multiButton={(homeButtons.buttons.length == 0)}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
                <ButtonsFooterComponent
                  propsComponent={{
                    organizationId,
                    sectionName: sectionName,
                    lpId: landingPageId,
                    lpUrl: lpUrl,
                  }}
                  FlagclassName={FlagClassName}
                  onClick={(e) => this.saveHeaderEvent(e)}
                  GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                  onCancelGotoWithoutMModal={(e) =>
                    this.onCancelGotoWithoutMModal(e)
                  }
                  goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                />
              </>
            )}
          </Card>
        </section>
      </Fragment>
    );
  }
}
export default withTranslation()(HeaderSection);
