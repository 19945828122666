import axios from 'axios';
import { protocol, host, port, route, dataSource } from '../../reactUtils/systemVariables/UhooServerInformation';

export async function getConfigs(Params) {
  const userToken = "Bearer " + sessionStorage.access_token;
  const query = '/config/page/0?page_size=5000';
  try {
    let configs = await axios({
      method: 'GET',
      url: protocol + host + port + route + dataSource + query,      
      headers: {
        'Content-Type': 'application/json',
        'Authorization': userToken,
      },
      data: Params
    })
    if(configs) {
      return configs.data;
    }
    return null
  } catch (error) {
    return error;
  };
}

export async function putConfigsById(id, data, onUploadProgress) {
  
  const userToken = "Bearer " + sessionStorage.access_token;
  const query = `/config/${id}`;
  try {
    let config = await axios({
      method: 'PUT',
      url: protocol + host + port + route + dataSource + query,      
      headers: {
        'Content-Type': 'application/json',
        'Authorization': userToken,
      },
      data: data,
      onUploadProgress
    })
    if(config) {
      return config.data;
    }
    return null;
  } catch (error) {
    return error;
  };
}