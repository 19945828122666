import React from "react";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "@material-ui/core";

import "./css/Button.scss";

export function ButtonComponent({
  icon,
  text,
  path,
  props,
  isLoading,
  onClick,
  shape,
  tooltip,
  isDisabled,
  isHidden,
  FlagclassName,
  className,
}) {
  return isHidden ? (
    <div></div>
  ) : (
    <Link
      to={{
        pathname: path,
        props: props,
      }}
      className={ isDisabled ? "notAllowed" : ""}
    >
      {/* Disable */}
      {!tooltip && !FlagclassName && !className ? (
        <Button
          classes={{ label: "buttonLabel", root: "button" }}
          startIcon={icon}
          loading={isLoading}
          onClick={onClick}
          shape={shape}
          size={"large"}
          type="primary"
          disabled={isDisabled}
        >
          {text}
        </Button>
      ) : null}
      {tooltip && !FlagclassName ? (
        <Tooltip title={tooltip}>
          <Button
            classes={{ label: "buttonLabel", root: "button" }}
            icon={icon}
            loading={isLoading}
            onClick={onClick}
            shape={shape}
            size={"large"}
            type="primary"
            disabled={isDisabled}
          >
            {text}
          </Button>
        </Tooltip>
      ) : null}
      {FlagclassName ? (
        <Tooltip title={tooltip}>
          <Button
            className="buttonParpadeo"
            icon={icon}
            loading={isLoading}
            onClick={onClick}
            shape={shape}
            size={"large"}
            type="primary"
            disabled={isDisabled}
          >
            {text}
          </Button>
        </Tooltip>
      ) : null}
      {className ? (
        <Button
          className={className}
          startIcon={icon}
          loading={isLoading}
          onClick={onClick}
          shape={shape}
          size={"large"}
          type="primary"
          disabled={isDisabled}
        >
          {text}
        </Button>
      ) : null}
    </Link>
  );
}
