import React from "react";
import ReactDOM from "react-dom";

import "./css/modal.css";

function Overlap({ children }) {

    return ReactDOM.createPortal(
        <div className="ModalOverlap">
            {children}
        </div>,
        document.getElementById('modal')
    );

}

export default Overlap;