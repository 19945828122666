import React, { Component, useContext } from "react";

import "./css/BillboardUhooPreview.css";

function billboardUhooPreview(props) {

  let title = props.previewTitle;
  let image = props.previewImage;

  return (
    <React.Fragment>
      <div className="contentUhooBillboardPreview">
        <div className="billboardUhoo">
          <div className="billboardUhooContent">

            {image !== "" || title !== ""
              ?
              <React.Fragment>

                <div className="BillboardUhoo">

                  {image !== ""
                    ? <img className="imageBillboardUhoo" src={image} alt="image preview" />
                    : null
                  }

                  {title !== ""
                    ? <div className="titleBillboardUhoo">{title}</div>
                    : null
                  }

                </div>

              </React.Fragment>

              : null
            }

          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default billboardUhooPreview;


