import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@material-ui/core";

import "./css/GeneralModal.scss";

import { ButtonComponent } from "./index";

import { X } from "react-feather";

//Localization
import { useTranslation } from "react-i18next";

export function DeleteItemModal(props) {
  const { visible, onClick, onCancel } = props;
  const { t } = useTranslation();
  return (
    <div>
      <Dialog open={visible}>
        <span className="borderBottomTitleDialog" />
        <DialogTitle>
          <div className="headerDialog">
            {t("basicWords.deleteItem")}
            <Button onClick={onCancel}>
              <X className="iconCloseDialog" />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="widthAndHeigthDialog">
            <div className="row componentSpace buttonAtTheBottom" key={1}>
              <div>
                <ButtonComponent
                  onClick={onCancel}
                  props={null}
                  shape="round"
                  className="buttonDiscard"
                  text={t("basicWords.cancelText")}
                />
              </div>
              <div>
                <ButtonComponent
                  onClick={onClick}
                  props={null}
                  shape="round"
                  className="buttonToSave"
                  text={t("basicWords.okText")}
                />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
