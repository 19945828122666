let allowedTags = `div, iframe, del,a, p, b, i, q, br, ul, li, ol, img, h1, h2, h3, h4, h5, h6, hr,
blockquote, pre, code, em, strong, center, table, thead, tbody, tr, th, td, html, head, meta, body, title,
span, section, div, button, strike, sup, sub`.trim().split(/,\s*/);
let allowedAttributes = {
// "src" MUST pass a whitelist (below)
iframe: [ 'src', 'width', 'height', 'frameborder', 'allowfullscreen', 'webkitallowfullscreen', 'mozallowfullscreen',
    'sandbox', 'class',],
// class attribute is strictly whitelisted (below)
// and title is only set in the case of a phishing warning
div: ['class', 'style'], a: ['class', 'title', 'style'], p: ['class','style'], b: ['class','style'],
i: ['class', 'style'], q: ['class','style'], br: ['class','style'], ul: ['class','style'],
li: ['class', 'style'], lo: ['class','style'], h1: ['class','style'], h2: ['class','style'],
h3: ['class','style'], h4: ['class','style'], h5: ['class','style'], h6: ['class','style'],
hr: ['class', 'style'], blockquote: ['class', , 'style'], pre: ['class','style'], code: ['class','style'],
em: ['class','style'], strong: ['class','style'], center: ['class','style'], table: ['class','style'],
thead: ['class', 'style'], tbody: ['class', 'style'], tr: ['class','style'], th: ['class','style'],
td: ['class', 'style'], html: ['class', 'style', 'lang'], head: ['class','style'], 
meta: ['charset','http-equiv', 'content', 'name', ], body: ['class', 'style'], title: ['class', 'style'], 
span: ['class', 'style'], section: ['class','style'], button: ['class', 'title', 'style'], 
strike: ['class', 'style'], sup: ['class','style'], sub: ['class', 'style'],
img: ['src', 'srcset', 'title', 'alt', 'class', 'style'],
// title is only set in the case of an external link warning
a: ['href', 'title', 'rel','class', 'target']
};
let allowedSchemes = ['http', 'https'];

export default () => ({ allowedTags, allowedAttributes, allowedSchemes })