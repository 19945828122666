import React, { useContext, useState } from "react";
import { pick as _pick, get as _get, cloneDeep as _cloneDeep } from "lodash";
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { updateUsers } from "../../../actions/uhoo";

// components
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import SystemNotification from "../../global/SystemNotification";

// css
import "../../community/components/css/configureCommunity.css";

function DeleteUserFromAudience(props) {
  /**
   * Props
   */
  const { t, i18n } = useTranslation(); //  Localization
  const history = useHistory();
  let open = props.setOpenModal;
  let user = props.usersSelected
  /**
   * Hooks
   */
  const [currentAudience, seturrentAudience] = useState(props.audience);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  /**
   * Methods
   */
   function closeModal() { open(false); }


  const deleteUser = async () => {
      const entities = {};
      entities[user.uuid] = {};
      let data = {kind_remove: {"891f6801-19eb-4c3b-98c9-64400c2837f3": { entities }}};
      await updateUsers(currentAudience.uuid, data); //delete user from audeince
      setTimeout(()=>{history.push({pathname: '/community', state: {updateUsers: true}})}, 500)
  }


  return (
    <React.Fragment>
      <div className="modalDeleteUser">
        <div className="titleModalDeleteUser">
            <div className="textTitleModalDeleteUser">{t('uhoo.globalWords.confirmation')}</div>
            <div className="closeModalDeleteUser" onClick={() => closeModal()} role="button"><Close /></div>
        </div>
        <hr className="lines" />
        <div className="contentModalDeleteUser">
            {t('uhoo.community.deleteModal.caption') + (user.name === null ? "" : user.name) + " " + (user.middle_name === null ? "" : user.middle_name) + " " + (user.last_name === null ? "" : user.last_name) + "?"}
        </div>
        <div className="actionContentModalDeleteUser">
            <div role="button" onClick={() => closeModal()}>
                <SecondaryButton txt={t('uhoo.globalWords.cancel')} dark />
            </div>
            <div role="button" onClick={() => deleteUser()}>
                <SecondaryButton txt={t('uhoo.globalWords.delete')} danger />
            </div>
        </div>
      </div>

      {!!showSuccess && (
        <SystemNotification
          setShowNotification={setShowSuccess}
          type="Success"
          caption={t('basicWords.modal.updateSuccessAudience')}
        />
      )}
      {!!showError && (
        <SystemNotification
          setShowNotification={setShowError}
          type="Error"
          caption={t('uhoo.systemNotification.error')}
        />
      )}
      
    </React.Fragment>
  )
}

export default DeleteUserFromAudience;
