import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// Queries
import {
  getInformationBySectionIdAndLPId,
  updateLpSectionComponentNode,
  insertSectionComponent,
  disableLpSectionComponent,
  getFile,
  updateLpSectionComponentNodeImage,
  getSectionLayoutStyle,
  updateLpSectionLayout,
  updateLpSection,
} from "../../../../actions/aura";
import { ReactComponent as BackArrow } from '../../../../assets/icons/navigation/backArrow.svg';
import SelectLayoutModal from "../../../../aura-components/SelectLayoutModal.jsx";

// Components
import {
  Title,
  ButtonComponent,
  BreadcrumbSection,
  GeneralInput,
  DeleteItemModal,
  GeneralModal,
  AccordionComponent,
  ButtonsFooterComponent
} from "../../../../aura-components";

import SystemNotification from "../../../global/SystemNotification";
import { Card, CircularProgress, TextField, Tooltip } from "@material-ui/core";

import { handleChangeImageFunction } from "../../../../reactUtils/generalUtils/handleImageChange.jsx";

import { Edit2, Trash2, Plus, X } from "react-feather";

//Localization
import { withTranslation } from "react-i18next";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;

class TeamSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,

      lpSectionId: null,
      sectionName: null,
      landingPageId: null,
      organizationId: null,
      organizationName: null,
      country: null,
      region: null,
      city: null,

      loadingSection: true,

      teamContainerTitle: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: 0,
      },
      teamContainerText: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: 0,
      },
      teamMembersToShow: [],

      member: {
        id: null,
        name: "",
        position: "",
        text: "",
        image: null,
        imageLoading: false,
      },
      newMemberImage: {
        id: null,
        s3path: null,
        imageUrl: null,
        image: null,
        loading: false,
      },
      parentLPSectionComponentId: null,
      membersToAdd: [],
      isEditModalVisible: false,
      saveTitleDisabled: true,
      FlagClassName: false,
      goToWithoutSaveModalVisible: false,
      haveChangeInLayout: false,

      // Modals
      showSuccess: false,
      showWarning: false,
      showError: false,
      contentNotificationModal: "",

      //Modal general
      openDialog: false,

      //LayoutSelector
      newLayoutId: null,
      oldLayoutId: null,

      isReadyToEdit: false,
      addMember: false,

      //Name modal
      openModalToChangeName: false,
      customName: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { location } = this.props;
    const { props } = location;

    if (props) {
      this.getTeamInformation(props.landingPageId, props.sectionId);

      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
      this.handleSelectLayout();
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  getElementsToTeams = (components, nodeType, paramsToFind) => {
    let object = {};
    if (components.nodes.find((node) => node.nodeType === nodeType) !== undefined) {
      paramsToFind.forEach(element => {
        if (element === "length") {
          object[element] = components.nodes.find((node) => node.nodeType === nodeType).value.length;
        } else if (element === "nodeTypeName") {
          object[element] = components.nodes.find((node) => node.nodeType === nodeType).nodeType;
        } else {
          object[element] = components.nodes.find((node) => node.nodeType === nodeType)[element];
        }
      });
    } else {
      paramsToFind.forEach(element => {
        if (element === "nodeTypeName") {
          object[element] = nodeType;
        } else {
          object[element] = "";
        }
      });
    }
    return object;
  };

  // Queries
  async getTeamInformation(lpId, lpSectionId) {
    const response = await getInformationBySectionIdAndLPId(
      lpId,
      lpSectionId,
      "TEAM"
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      let teamContainerTitle = { ...this.state.teamContainerTitle };
      let teamContainerText = { ...this.state.teamContainerText };
      const sectionInfo = response.data.data;
      const teamContainerComponent = sectionInfo.components.find((comp) => comp.name === "TeamContainerComponent");

      const objectKeys = ["id", "value", "nodeTypeName", "minLength", "maxLength", "length"];
      teamContainerTitle = this.getElementsToTeams(teamContainerComponent, "TeamContainerTitle", objectKeys);
      teamContainerText = this.getElementsToTeams(teamContainerComponent, "TeamContainerText", objectKeys);

      const parentLPSectionComponentId = sectionInfo.id;

      const teamMembers = sectionInfo.components.filter(
        (comp) => comp.name === "TeamMemberComponent"
      );

      for (let i = 0; i < teamMembers.length; i++) {
        if (!teamMembers[i].img) {
          const imgNode = teamMembers[i].nodes.find((node) => node.nodeType === "TeamMemberImage");
          if (imgNode) {
            if (imgNode.value !== null && imgNode.value !== "") {
              // Get image
              let img = await getFile(
                "https://somosaura-cms.s3.amazonaws.com/" + imgNode.value
              );
              teamMembers[i].img = img;
            }
          }
        }
      }

      const teamMembersToShow = [];
      if (teamMembers) {
        teamMembers.forEach((element) => {
          const membersElement = {
            id: element.id,
            nameId: element.nodes.find((node) => node.nodeType === "TeamMemberName").id,
            name: element.nodes.find((node) => node.nodeType === "TeamMemberName").value,
            positionId: element.nodes.find((node) => node.nodeType === "TeamMemberPosition").id,
            position: element.nodes.find((node) => node.nodeType === "TeamMemberPosition").value,
            textId: element.nodes.find((node) => node.nodeType === "TeamMemberText")
              ? element.nodes.find((node) => node.nodeType === "TeamMemberText").id
              : null,
            text: element.nodes.find((node) => node.nodeType === "TeamMemberText")
              ? element.nodes.find((node) => node.nodeType === "TeamMemberText").value
              : null,
            imageId: element.nodes.find((node) => node.nodeType === "TeamMemberImage")
              ? element.nodes.find((node) => node.nodeType === "TeamMemberImage").id
              : null,
            image: element.img ? element.img : null,
            imageUri: element.img ? element.img : null,
            edited: false,
          };
          teamMembersToShow.push(membersElement);
        });
      }

      //Get layout image
      let supgage = null;
      if (this.state.subpageId && this.state.subpageId !== 10001) {
        supgage = sectionInfo.subpages.find(
          (sp) => sp.id === this.state.subpageId
        );
      } else {
        supgage = sectionInfo.subpages.find((sp) => sp.id === null);
      }
      let subpageIndex = sectionInfo.subpages.indexOf(supgage);
      if (subpageIndex === -1) {
        subpageIndex = 0;
      }
      let layout =
        response.data.data.subpages[subpageIndex].lpSectionLayoutStyle
          .sectionLayoutId;
      let layoutImg = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" + layout.s3ImagePath
      );

      let customName =
        response.data.data.customName !== null
          ? response.data.data.customName
          : this.state.sectionName;

      this.setState({
        customName,
        teamContainerTitle,
        teamContainerText,
        teamMembersToShow,
        loadingSection: false,
        parentLPSectionComponentId,
        lpSectionLayoutStyle: layout,
        lpLayoutImg: layoutImg,
      });
    }
  }

  async updateLpSection(LpSubpageDto) {
    let response = await updateLpSection(LpSubpageDto);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
    }
    this.setState({
      newCustomName: "",
      customName: LpSubpageDto.customName,
      openModalToChangeName: false,
    });
  }

  async uploadFileToS3Bucket(imageUrl, key) {
    await updateLpSectionComponentNodeImage(imageUrl, key);
  }

  async insertSectionComponentFunction(nodesToUpdate) {
    const { landingPageId, lpSectionId } = this.state;
    const response = await insertSectionComponent(nodesToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.memberSuccessfullyAdded"),
      });

      let member = { ...this.state.member };
      let newMemberImage = { ...this.state.newMemberImage };

      member = {
        id: null,
        name: "",
        position: "",
        text: "",
        image: null,
        imageLoading: false,
      };
      newMemberImage = {
        id: null,
        s3path: null,
        imageUrl: null,
        image: null,
        loading: false,
      };

      this.setState({
        member,
        newMemberImage,
      });
      this.getTeamInformation(landingPageId, lpSectionId);
    }
    this.setState({
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  }

  async updateNode(nodesToUpdate) {
    const { landingPageId, lpSectionId } = this.state;
    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.memberSuccessfullyEdited"),
      });
      let member = { ...this.state.member };
      let newMemberImage = { ...this.state.newMemberImage };

      member = {
        id: null,
        name: "",
        position: "",
        text: "",
        image: null,
        imageLoading: false,
      };
      newMemberImage = {
        id: null,
        s3path: null,
        imageUrl: null,
        image: null,
        loading: false,
      };

      this.setState({
        member,
        newMemberImage,
      });
      this.getTeamInformation(landingPageId, lpSectionId);
    }

    this.setState({
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
      saveTitleDisabled: true,
    });
  }

  async disableNodeFunction(id) {
    const { landingPageId, lpSectionId } = this.state;

    const response = await disableLpSectionComponent(id);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
        isLoadingButton: false,
        loadingInfo: false,
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.memberSuccessfullyDisabled"),
        isLoadingButton: false,
        loadingInfo: false,
      });

      this.getTeamInformation(landingPageId, lpSectionId);
    }
  }

  async getLayoutFunction(lpId) {
    let response = await getSectionLayoutStyle(lpId, 6);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      let responseData = response.data.data;
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].sampleLayoutImageS3Path) {
          //Get image
          let img = await getFile(
            "https://somosaura-cms.s3.amazonaws.com/" +
            responseData[i].sampleLayoutImageS3Path
          );
          responseData[i].img = img;
        }
      }
      this.setState({
        layoutData: responseData,
      });
    }
  }

  async updateLpSectionLayoutFunction(lpSectionId, newLayoutId) {
    let response = await updateLpSectionLayout(lpSectionId, newLayoutId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
    }
    this.setState({ isLoadingButton: false });
  }

  // Events
  handleChange = (event, dataIndex, data, flagRichText, memberId) => {
    let id = null;
    let value = null;

    if (event && event.target) {
      id = event.target.id;
      value = event.target.value;
    }

    if (flagRichText) {
      id = dataIndex;
      value = event;
    }

    const { member, teamMembersToShow, teamContainerTitle, teamContainerText } =
    {
      ...this.state,
    };

    if (id === "TeamContainerTitle") {
      if (value.length <= teamContainerTitle.maxLength) {
        teamContainerTitle.value = value;
        teamContainerTitle.modified = true;
        teamContainerTitle.length = value.length;
      }
    }
    if (id === "TeamContainerText") {
      if (value.length <= teamContainerText.maxLength) {
        teamContainerText.value = value;
        teamContainerText.modified = true;
        teamContainerText.length = value.length;
      }
    }

    teamMembersToShow.forEach((element, index) => {
      if (element.id === memberId) {
        if (id === "name") {
          teamMembersToShow[index].name = value;
        }
        if (id === "position") {
          teamMembersToShow[index].position = value;
        }
        if (id === "text") {
          teamMembersToShow[index].text = value;
        }
        teamMembersToShow[index].edited = true;
      }
    });

    if (memberId === null) {
      member[id] = value; // new member
    }

    this.setState({
      teamContainerTitle,
      teamContainerText,
      member,
      teamMembersToShow,
      saveTitleDisabled: false,
      FlagClassName: true,
    });
  };

  editMemberEvent = (e, register) => {
    const member = { ...register };
    const newMemberImage = {
      id: member.imageId,
      s3path: null,
      imageUrl: null,
      image: member.image,
      loading: false,
    };

    this.setState({
      isEditModalVisible: true,
      member,
      newMemberImage,
    });
  };

  // Add event modal events
  onCloseEventModal = () => {
    let newMemberImage = { ...this.state.newMemberImage };
    let member = { ...this.state.member };

    newMemberImage = {
      id: null,
      s3path: null,
      imageUrl: null,
      image: null,
      loading: false,
    };

    member = {
      id: null,
      name: null,
      position: null,
      text: null,
      image: null,
      imageLoading: false,
    };

    this.setState({
      isAddModalVisible: false,
      isEditModalVisible: false,
      newNewsImage: newMemberImage,
      member,
    });
  };

  handleChangeModal = (e) => {
    const { id, value } = e.target;
    const { member } = { ...this.state };

    member[id] = value;

    this.setState({
      member,
    });
  };

  handleChangeImage = async (event) => {
    const {
      teamMembersToShow,
      member,
      country,
      city,
      organizationId,
      imageIndex,
    } = this.state;
    var newMemberImage = { ...this.state.newMemberImage };
    var fileUpload = event.target.files[0];
    let extension;
    if (fileUpload !== undefined) {
      extension = fileUpload.type.replace("image/", "");
    }
    const random = uuidv4();
    const key = `content/${country}/${city}/${organizationId}/img${random}.${extension}`;
    if (!extension) {
      newMemberImage.loading = true;
      newMemberImage.file = null;
      newMemberImage.image = null;

      this.setState({
        newMemberImage,
        FlagClassName: true,
      });
      return;
    }

    if (key !== undefined) {
      // Function to resize and reduce image quality
      // Save image in AWS
      let flag = true;
      newMemberImage = await handleChangeImageFunction(
        newMemberImage,
        fileUpload,
        extension,
        key,
        400,
        400
      ).catch((res) => {
        const { t } = this.props;
        this.setState({
          showError: true,
          contentNotificationModal: `${t(`${res.content}`).replace("R1", res.params.R1).replace("R2", res.params.R2)}`,
        });
        flag = false;
      });
      if (flag !== false) {
        if (newMemberImage.loading === false) {
          if (imageIndex === null) {
            //new news
            newMemberImage.loading = false;
            newMemberImage.modified = true;
            newMemberImage.value = key;
            member.image = key;
            member.imageUri = newMemberImage.image;
          } else {
            // edited new
            teamMembersToShow[imageIndex].image = key;
            teamMembersToShow[imageIndex].imageUri = newMemberImage.image;
            teamMembersToShow[imageIndex].edited = true;
          }

          this.uploadFileToS3Bucket(newMemberImage.file, key);
          this.setState({
            newMemberImage,
            member,
            teamMembersToShow,
            FlagClassName: true,
          });
          this.forceUpdate();
        }
      }
    }
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  onSaveDataMembers = (e) => {
    const {
      parentLPSectionComponentId,
      landingPageId,
      teamMembersToShow,
      member,
      deleteFlag,
    } = this.state;
    this.setState({
      FlagClassName: false,
      isLoadingButton: true,
    });
    const { t } = this.props;

    var teamsToValidate = teamMembersToShow.filter((n) => n.edited === true);
    if (member.name !== "" && member.name !== null) {
      teamsToValidate = teamsToValidate.concat(member);
    }

    teamsToValidate.forEach((member) => {
      const memberToAdd = {
        // --- Con esto se crea el registro lpSectionComponent ---
        lpId: null, // Este valor se obtiene de la vista anterior
        lpSectionId: this.state.lpSectionId,
        sectionId: 6, // Valor estático para la sección de eventos
        sectionComponentId: 9, // Valor estático de 7, valor para el MemberComponent
        parentLPSectionComponentId, // Valor estático de 4 puesto que el id 4 es el padre de este nuevo componente
        // --- Con el Id del lpSectionComponent creado, se crean los nodos de este componente
        nodesToAdd: [],
        buttons: [],
      };

      const nodeMemberToAdd = [];
      let errorInput = false;
      if (member.name != null) {
        const memberDto = {};

        memberDto.id = member.nameId ? member.nameId : null;
        memberDto.sectionComponentNodeId = 31;
        if (member.name === "") {
          errorInput = true;
        }
        memberDto.value = member.name;
        memberDto.nodeType = "TeamMemberName";

        nodeMemberToAdd.push(memberDto);
      }
      if (member.position !== null) {
        const memberDto = {};
        memberDto.id = member.positionId ? member.positionId : null;
        memberDto.sectionComponentNodeId = 32;
        // if (member.position === "") {
        //   errorInput = true;
        // }
        memberDto.value = member.position;
        memberDto.nodeType = "TeamMemberPosition";
        nodeMemberToAdd.push(memberDto);
      }
      if (member.text != null) {
        const memberDto = {};

        memberDto.id = member.textId ? member.textId : null;
        memberDto.sectionComponentNodeId = 33;
        memberDto.value = member.text;
        memberDto.nodeType = "TeamMemberText";

        nodeMemberToAdd.push(memberDto);
      } else {
        const memberDto = {};
        memberDto.id = member.textId ? member.textId : null;
        memberDto.sectionComponentNodeId = 33;
        memberDto.value = "";
        memberDto.nodeType = "TeamMemberText";

        nodeMemberToAdd.push(memberDto);
      }
      if (member.image !== null) {
        const memberDto = {};

        memberDto.id = member.imageId ? member.imageId : null;
        memberDto.sectionComponentNodeId = 30;
        let haveHttp = false;
        var regex = /somosaura-cms.s3.amazonaws.com/;
        haveHttp = regex.test(member.image);
        if (haveHttp) {
          memberDto.value = member.image.slice(39, member.image.length); //Cut to only save image, not link
        } else {
          memberDto.value = member.image;
        }
        // memberDto.value = member.image;
        memberDto.nodeType = "TeamMemberImage";

        nodeMemberToAdd.push(memberDto);
      }
      if (member.image === null && deleteFlag === undefined) {
        const memberDto = {};

        memberDto.id = member.imageId ? member.imageId : null;
        memberDto.sectionComponentNodeId = 30;
        memberDto.value = "assets/defaultTeamMember.png"; //when not load image in team member
        memberDto.nodeType = "TeamMemberImage";

        nodeMemberToAdd.push(memberDto);
      }
      if (deleteFlag) {
        const memberDto = {};

        memberDto.id = member.imageId ? member.imageId : null;
        memberDto.sectionComponentNodeId = 30;
        memberDto.value = "assets/defaultTeamMember.png";
        memberDto.nodeType = "TeamMemberImage";

        nodeMemberToAdd.push(memberDto);
      }
      memberToAdd.id = member.id;
      memberToAdd.lpId = landingPageId;
      memberToAdd.nodesToAdd = nodeMemberToAdd;

      if (nodeMemberToAdd.length > 0) {
        if (!errorInput) {
          let memberName = false;
          let memberPosition = false;

          nodeMemberToAdd.forEach((obj) => {
            if (obj.nodeType === "TeamMemberName") {
              memberName = true;
            }
            if (obj.nodeType === "TeamMemberPosition") {
              memberPosition = true;
            }
          });

          if (memberName && memberPosition) {
            if (member.id) {
              this.updateNode(nodeMemberToAdd);
            } else {
              this.insertSectionComponentFunction(memberToAdd);
            }
          } else {
            this.setState({
              showError: true,
              contentNotificationModal: t("basicWords.modal.fieldEmpty"),
              isLoadingButton: false,
            });
          }
        } else {
          this.setState({
            showError: true,
            contentNotificationModal: t("basicWords.modal.fieldEmpty"),
            isLoadingButton: false,
          });
        }
      }
    });
  };

  handleClickOpenModalToChangeName = (itemToChangeName) => {
    let open = this.state.openModalToChangeName;
    this.setState({
      openModalToChangeName: !open,
      customName: itemToChangeName,
    });
  };

  OnChangeSectionCustomName = (e) => {
    let newCustomName = this.state.newCustomName;
    newCustomName = e.target.value;

    this.setState({
      newCustomName,
      FlagClassName: true,
    });
  };

  saveChangeName = () => {
    const { t } = this.props;
    var { teamContainerTitle, newCustomName, lpSectionId } = this.state;

    //Update lpsection custom name
    let dto = {
      id: lpSectionId,
      customName: newCustomName,
      isEnabled: 1,
    };
    this.updateLpSection(dto);

    //Update section title name
    const nodesToUpdate = [];
    let errorInput = false;

    if (teamContainerTitle) {
      if (newCustomName === "") {
        errorInput = true;
      } else {
        teamContainerTitle.value = newCustomName;
      }
      delete teamContainerTitle.edited;
      nodesToUpdate.push(teamContainerTitle);
    }

    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.fieldEmpty"),
          isLoadingButton: false,
        });
      }
    }
  };

  onSaveEventModal = (event, id, flag) => {
    var {
      haveChangeInLayout,
      teamContainerText,
      teamContainerTitle,
      lpSectionId,
      lpSectionLayoutStyle,
      member,
    } = this.state;
    const nodesToAdd = [];
    let errorInput = false;
    const { t } = this.props;

    if (teamContainerTitle.modified) {
      const titleDto = {};
      titleDto.id = teamContainerTitle.id ? teamContainerTitle.id : null;
      titleDto.sectionComponentNodeId = 28;
      if (teamContainerTitle.value === "") {
        errorInput = true;
      } else {
        //Update lpsection custom name
        let dto = {
          id: lpSectionId,
          customName: teamContainerTitle.value,
          isEnabled: 1,
        };
        this.updateLpSection(dto);

        titleDto.value = teamContainerTitle.value;
        titleDto.nodeType = "TeamContainerTitle";

        nodesToAdd.push(titleDto);
      }
    }
    if (teamContainerText.modified) {
      const textDto = {
        id: teamContainerText.id ? teamContainerText.id : null,
        sectionComponentNodeId: 29,
        value: teamContainerText.value,
        nodeType: "TeamContainerText",
      };

      nodesToAdd.push(textDto);
    }
    if (nodesToAdd.length > 0) {
      this.setState({
        isLoadingButton: true,
      });
      if (!errorInput) {
        this.updateNode(nodesToAdd);
      } else {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.fieldEmpty"),
          isLoadingButton: false,
        });
      }
    }
    if (haveChangeInLayout) {
      this.updateLpSectionLayoutFunction(lpSectionId, lpSectionLayoutStyle);
    }
    this.onSaveDataMembers();
    member = {
      id: null,
      name: "",
      position: "",
      text: "",
      image: null,
      imageLoading: false,
    };
    this.setState({ member, addMember: false });
  };

  disableImage(elementToChange) {
    const { teamMembersToShow, member, imageIndex } = {
      ...this.state,
    };
    if (elementToChange !== null && imageIndex) {
      teamMembersToShow[imageIndex].image = null;
      teamMembersToShow[imageIndex].edited = true;
    } else {
      member.image = null;
    }

    this.setState({
      isLoadingButton: false,
      FlagClassName: true,
      deleteFlag: true,
      teamMembersToShow,
      member,
    });
    this.handleCloseDeleteImage();
  }

  handleClickOpenDeleteImage = () => {
    this.setState({ openDeleteImage: true });
  };

  handleCloseDeleteImage = () => {
    this.setState({ openDeleteImage: false });
  };

  handleClickOpenDeleteNews = (id) => {
    this.setState({ openDeleteNews: true, elementToDeleteId: id });
  };

  handleCloseDeleteNews = () => {
    this.setState({ openDeleteNews: false });
  };

  disableMemberEvent = (id) => {
    this.setState({
      loadingInfo: true,
      openDeleteNews: false,
    });
    this.disableNodeFunction(id);
  };

  handleChangeLayout = (e, id) => {
    this.setState({
      saveButtonDisabled: false,
      FlagClassName: true,
      lpSectionLayoutStyle: id,
      haveChangeInLayout: true,
    });
  };

  addMember = (flag) => {
    if (flag !== this.state.addMember) {
      this.setState({ addMember: flag });
    }
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      titleNotificationModal: t("basicWords.modal.sectionUnselected"),
      contentNotificationModal: t("basicWords.modal.noSectionSelected"),
      typeNotificationModal: "warning",
    });
    this.handleOkWarning();
    this.setState({ openNotificationModal: true });
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  changeToEdit = (e) => {
    e.stopPropagation();
    const { isReadyToEdit } = this.state;
    this.setState({ isReadyToEdit: !isReadyToEdit });
  };

  handleImageIndex = (index) => {
    this.setState({
      imageIndex: index,
    });
  };


  // Render Info
  renderTeamMembersComponents = (teamMembersToShow) => {
    let renderedComponents = [];
    const { openDeleteImage, isReadyToEdit } = this.state;
    const { t } = this.props;
    if (teamMembersToShow) {
      teamMembersToShow.forEach((element, index) => {
        renderedComponents.push(
          <div key={element.id}>
            <AccordionComponent
              disabled={isReadyToEdit}
              iconEdit={<Edit2 />}
              onDeleteNews={() => this.handleClickOpenDeleteNews(element.id)}
              iconDelete={<Trash2 />}
              title={element.name}
              body={[
                <div className="accordionContent">
                  <div className="accordionCenterLine">
                    <div
                      style={{
                        width: "50%",
                        marginRight: "2%",
                        marginLeft: "1%",
                      }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("basicWords.name")}
                        id="name"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.name}
                      />
                    </div>
                    <div
                      style={{
                        width: "50%",
                        marginRight: "1%",
                        marginLeft: "2%",
                      }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("basicWords.position")}
                        id="position"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.position}
                      />
                    </div>
                  </div>

                  <br />
                  <div className="accordionCenterLine">
                    <div className="accordionCompleteInputs">
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("basicWords.text")}
                        id="text"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.text}
                      />
                    </div>
                  </div>

                  <br />
                  <div className="accordionCenterLine">
                    <div className="textLogo" style={{ marginLeft: "1%" }}>
                      {t("basicWords.image")}
                    </div>
                    <div className="cardImagesSquare">
                      <div className="row">
                        <div className="iconXCardImagesSquare">
                          <a
                            onClick={() =>
                              this.handleClickOpenDeleteImage(index)
                            }
                          >
                            <X className="iconImageEditInSectionSquare" />
                          </a>
                        </div>
                      </div>

                      <div className="row">
                        {/* Tooltip image */}
                        <Tooltip title={t("generalMessages.imagesFormat")}>
                          <div className="centerImage">
                            <input
                              accept="image/*"
                              id="contained-button-file"
                              multiple
                              type="file"
                              onChange={(e) => this.handleChangeImage(e, index)}
                              hidden
                            />
                            <label htmlFor="contained-button-file">
                              <div className="imageEdit">
                                <div>
                                  {element.image != null ? (
                                    <img
                                      src={element.imageUri}
                                      alt="avatar"
                                      className="widthAndHeightImageSectionSquare"
                                      onClick={() =>
                                        this.handleImageIndex(index)
                                      }
                                    />
                                  ) : (
                                    <Plus
                                      className="imageEdit"
                                      onClick={() =>
                                        this.handleImageIndex(index)
                                      }
                                    />
                                  )}

                                  <GeneralModal
                                    visible={openDeleteImage}
                                    title={t("basicWords.deleteItem")}
                                    footer={[
                                      <div
                                        className="row"
                                        key={1}
                                        style={{ display: "flex" }}
                                      >
                                        <div className="col-6">
                                          <ButtonComponent
                                            onClick={() =>
                                              this.handleCloseDeleteImage()
                                            }
                                            props={null}
                                            shape="round"
                                            text={t("basicWords.cancelText")}
                                          />
                                        </div>
                                        <div>
                                          <ButtonComponent
                                            onClick={() =>
                                              this.disableImage(element)
                                            }
                                            props={null}
                                            shape="round"
                                            text={t("basicWords.okText")}
                                          />
                                        </div>
                                      </div>,
                                    ]}
                                    onClick={() =>
                                      this.handleCloseDeleteImage()
                                    }
                                  />
                                </div>
                              </div>
                            </label>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>,
              ]}
            />
            <br />
          </div>
        );
      });
    }

    return renderedComponents;
  };

  renderAddMember() {
    const { member, openDeleteImage } = this.state;
    const { t } = this.props;
    return (
      <div>
        <AccordionComponent
          title={t("basicWords.newMember")}
          className="accordionAdd"
          body={[
            <div className="accordionContent">
              <div className="accordionCenterLine">
                {/* Name */}
                <div
                  style={{ width: "50%", marginRight: "2%", marginLeft: "1%" }}
                >
                  <GeneralInput
                    className="textInputWidth"
                    placeholder={t("basicWords.name")}
                    id="name"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={member.name}
                  />
                </div>
                <div
                  style={{ width: "50%", marginRight: "1%", marginLeft: "2%" }}
                >
                  <GeneralInput
                    className="textInputWidth"
                    placeholder={t("basicWords.position")}
                    id="position"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={member.position}
                  />
                </div>
              </div>

              <br />
              <div className="accordionCenterLine">
                <div className="accordionCompleteInputs">
                  <GeneralInput
                    className="textInputWidth"
                    placeholder={t("basicWords.text")}
                    id="text"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={member.text}
                  />
                </div>
              </div>

              <br />
              <div className="accordionCenterLine">
                <div className="textLogo" style={{ marginLeft: "1%" }}>
                  {t("basicWords.image")}
                </div>
                <div className="cardImagesSquare">
                  <div className="row">
                    <div className="iconXCardImagesSquare">
                      <a>
                        <X
                          className="iconImageEditInSectionSquare"
                          onClick={() => this.handleClickOpenDeleteImage()}
                        />
                      </a>
                    </div>
                  </div>

                  <div className="row">
                    {/* Tooltip image */}
                    <Tooltip title={t("generalMessages.imagesFormat")}>
                      <div className="centerImage">
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(e) => this.handleChangeImage(e, null)}
                          hidden
                        />
                        <label htmlFor="contained-button-file">
                          <div className="imageEdit">
                            <div>
                              {member.image != null ? (
                                <img
                                  src={member.imageUri}
                                  alt="avatar"
                                  className="widthAndHeightImageSectionSquare"
                                  onClick={() => this.handleImageIndex(null)}
                                />
                              ) : (
                                <Plus
                                  className="imageEdit"
                                  onClick={() => this.handleImageIndex(null)}
                                />
                              )}

                              <GeneralModal
                                visible={openDeleteImage}
                                title={t("basicWords.deleteItem")}
                                footer={[
                                  <div
                                    className="row"
                                    key={1}
                                    style={{ display: "flex" }}
                                  >
                                    <div className="col-6">
                                      <ButtonComponent
                                        onClick={() =>
                                          this.handleCloseDeleteImage()
                                        }
                                        props={null}
                                        shape="round"
                                        text={t("basicWords.cancelText")}
                                      />
                                    </div>
                                    <div>
                                      <ButtonComponent
                                        onClick={() => this.disableImage(null)}
                                        props={null}
                                        shape="round"
                                        text={t("basicWords.okText")}
                                      />
                                    </div>
                                  </div>,
                                ]}
                                onClick={() => this.handleCloseDeleteImage()}
                              />
                            </div>
                          </div>
                        </label>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <br />
            </div>,
          ]}
        />
        <br />
      </div>
    );
  }

  handleSelectLayout() {
    this.getLayoutFunction(this.props.location.props.landingPageId);

    this.setState({ layoutModalVisible: true });
  }

  //-----------Update page after modal------------
  updatePage = (lpSectionId, previousPath) => {
    const { landingPageId } = this.state;
    this.getTeamInformation(landingPageId, lpSectionId);
    this.setState({
      previousPath,
    });
    this.setState({ layoutModalVisible: false });
    this.forceUpdate();
  };

  setShowError = (open) => {
    this.setState({ showError: open });
  };

  setShowSuccess = (open) => {
    this.setState({ showSuccess: open });
  };

  setShowWarning = (open) => {
    this.setState({ showWarning: open });
  };

  render() {
    const {
      redirectBack,
      sectionName,
      teamContainerTitle,
      teamContainerText,
      teamMembersToShow,
      organizationId,
      organizationName,
      name,
      subpageId,
      landingPageId,
      FlagClassName,
      goToWithoutSaveModalVisible,
      layoutData,
      lpSectionLayoutStyle,
      lpUrl,

      // Modals
      showSuccess,
      showWarning,
      showError,
      contentNotificationModal,

      //Modal delete item
      openDeleteNews,
      elementToDeleteId,

      isLoadingButton,

      openModalToChangeName,
      customName,
    } = this.state;

    const { t } = this.props;

    if (redirectBack) {
      return <Redirect to="/manage/web" />;
    }

    return (
      <React.Fragment>
        {!!showWarning && (
          <SystemNotification
            setShowNotification={(e) => this.setShowWarning(e)}
            type="Warning"
            caption={contentNotificationModal}
          />
        )}
        {!!showSuccess && (
          <SystemNotification
            setShowNotification={(e) => this.setShowSuccess(e)}
            type="Success"
            caption={contentNotificationModal}
          />
        )}
        {!!showError && (
          <SystemNotification
            setShowNotification={(e) => this.setShowError(e)}
            type="Error"
            caption={contentNotificationModal}
          />
        )}

        <GeneralModal
          visible={openModalToChangeName}
          title={"Cambiar nombre"}
          body={[
            <div className="col-12" style={{ margin: "5px" }}>
              <div className="row">
                <div className="col-md-12 componentSpace">
                  <TextField
                    variant="outlined"
                    style={{ width: "100%", marginLeft: 10 }}
                    label={t("basicWords.section")}
                    onChange={(e) => this.OnChangeSectionCustomName(e)}
                    defaultValue={
                      customName ? decodeURIComponent(customName) : ""
                    }
                  />
                </div>
              </div>
            </div>,
          ]}
          footer={[
            <div className="row buttonAtTheBottom">
              <div>
                <ButtonComponent
                  onClick={(e) => this.handleClickOpenModalToChangeName()}
                  shape="round"
                  text={t("basicWords.cancel")}
                  className="buttonDiscard"
                />
              </div>
              <div className="col-6">
                <ButtonComponent
                  isDisabled={false}
                  onClick={() => this.saveChangeName()}
                  shape="round"
                  text={t("basicWords.ok")}
                />
              </div>
            </div>,
          ]}
          onClick={() => this.handleClickOpenModalToChangeName()}
        />

        <DeleteItemModal
          visible={openDeleteNews}
          onCancel={this.handleCloseDeleteNews}
          onClick={(e) => this.disableMemberEvent(elementToDeleteId)}
        />

        <section className="Title">
          <BackArrow className="ico pointerCursor" />
          <h3>Página Web</h3>
        </section>
        <section className="sectionContainer">
          <Card className="card">
            {isLoadingButton === true ? (
              <div
                className="col-xs-12 col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  transform: "translateY(800%)",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="row componentSpace spaceBreadcrumb">
                  <BreadcrumbSection
                    principal={t("sections.team")}
                    secondary={organizationName}
                    route="./manage/web"
                    propsComponent={{
                      organizationId,
                      sectionName: sectionName,
                      nameSubpage: name ? name : null,
                      lpId: landingPageId,
                      subpageId: subpageId ? subpageId : null,
                      lpUrl: lpUrl,
                      customName,
                    }}
                    handleClickOpenModalToChangeName={
                      this.handleClickOpenModalToChangeName
                    }
                    FlagclassName={FlagClassName}
                    onClick={(e) => this.saveAboutEvent(e)}
                    GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                    onCancelGotoWithoutMModal={(e) =>
                      this.onCancelGotoWithoutMModal(e)
                    }
                    goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                  />
                </div>

                <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                  <Title
                    title={t("basicWords.completeInformationOfYourSection")}
                  />
                </div>
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <SelectLayoutModal
                    layoutData={layoutData}
                    lpLayoutId={
                      lpSectionLayoutStyle ? lpSectionLayoutStyle : null
                    }
                    handleChange={this.handleChangeLayout}
                  />
                  <br />
                </div>

                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <AccordionComponent
                    title={t("generalUser.sectionContent")}
                    body={[
                      <div className="accordionContent">
                        {/* First Line */}
                        <div className="accordionCenterLine">
                          <div
                            style={{
                              width: "70%",
                              marginLeft: "1%",
                              marginRight: "0.7%",
                            }}
                          >
                            <GeneralInput
                              className="textInputWidth"
                              placeholder={t("basicWords.enterTitle")}
                              id={
                                teamContainerTitle
                                  ? teamContainerTitle.nodeTypeName
                                  : null
                              }
                              onChange={(e, id) => this.handleChange(e, id)}
                              value={
                                teamContainerTitle
                                  ? teamContainerTitle.value
                                  : null
                              }
                            />
                            {teamContainerTitle.value &&
                              teamContainerTitle.maxLength ? (
                              <span>
                                {teamContainerTitle.length} /{" "}
                                {teamContainerTitle.maxLength}
                              </span>
                            ) : null}
                          </div>
                          <div
                            style={{
                              width: "30%",
                              marginRight: "1%",
                              marginLeft: "0.7%",
                            }}
                          >
                            <GeneralInput
                              className="textInputWidth"
                              placeholder={t("basicWords.enterText")}
                              id={
                                teamContainerText
                                  ? teamContainerText.nodeTypeName
                                  : null
                              }
                              autoSize={{ minRows: 2, maxRows: 4 }}
                              onChange={(e, id) => this.handleChange(e, id)}
                              value={
                                teamContainerText ? teamContainerText.value : null
                              }
                            />
                          </div>
                        </div>
                      </div>,
                    ]}
                  />
                  <br />
                </div>

                {/* Accordion to add event */}
                {landingPageId ? (
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    {this.renderAddMember()}
                  </div>
                ) : null}

                <div>
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    <Title title={t("basicWords.listMember")} />
                  </div>
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    {this.renderTeamMembersComponents(teamMembersToShow)}
                  </div>
                </div>
              </>
            )}
          </Card>
        </section>

        {/* Buttons */}
        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName,
            name,
            landingPageId,
            subpageId,
            lpUrl,
          }}
          FlagclassName={FlagClassName}
          onClick={(e) => this.onSaveEventModal(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />

      </React.Fragment>

    );
  }
}
export default withTranslation()(TeamSection);
