import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./css/AccordionComponent.scss";

export function AccordionComponent(props) {
  const {
    title,
    secondTitle,
    body,
    disable,
    iconEdit,
    onEditNews,
    iconDelete,
    onDeleteNews,
    hidden,
    className,
    buttonsHeader
  } = props;
  return (
    // <Accordion className="bgAccordion">
    <Accordion hidden={hidden} className="borderAccordion">
      {secondTitle ? (
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={className ? className : ""}
        >
          <div className="headerAccordion">
            <div style={{ width: "70%" }}>
              <Typography className="textAccordion">{title === " " || title === " " ? "--" : title}</Typography>
            </div>
            <div className="headerAccordion" >
              <div style={{ width: "45%" }}>
                <Typography className="textAccordion">{secondTitle}</Typography>
              </div>
              {/* <Typography className="textAccordion">{secondTitle}</Typography> */}
            </div>
          </div>
        </AccordionSummary>
      ) : (
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={className ? className : ""}
        >
          <div className="headerAccordion">
            <div style={{ width: "100%" }}>
            <Typography className="textAccordion">{title === "" || title === " " ? "--" : title}</Typography>
            </div>
            <div className="headerAccordion">
              {buttonsHeader ?  buttonsHeader : null}
              {iconEdit ? (
                <div
                  className="iconEdit"
                  onClick={onEditNews}
                  style={{ marginRight: "5px" }}
                >
                  {iconEdit}
                </div>
              ) : null}
              {iconDelete ? (
                <div className="iconTrash" onClick={onDeleteNews}>
                  {iconDelete}
                </div>
              ) : null}
            </div>
          </div>
        </AccordionSummary>
      )}

      <AccordionDetails disabled={disable}>
        {/* <div style={{ display: "flex" }}>{body}</div> */}
        {body}
      </AccordionDetails>
    </Accordion>
  );
}
