import React, { useState, useEffect, useContext } from "react";
import { get as _get } from "lodash";
import { cloneDeep as _cloneDeep } from "lodash";
import moment from 'moment';
import RichText from './components/richText';
import Attachments from '../global/components/create/pictureAttachments.jsx';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { useHistory } from "react-router-dom";
import SectionAttachments from '../global/components/create/Attachments.jsx'; // new
import AudienceSelect from "../global/components/create/audienceSelect.jsx"; // new
import MediumSelect from "../global/components/create/mediumSelect.jsx"; // new

import SecondaryButton from "../../uhoo-components/buttons/secondaryButton.jsx";

import SystemNotification from "../global/SystemNotification.jsx";

import Modal from '../../uhoo-components/Modal.jsx';
import FromLibrary from './modals/fromLibrary.jsx';
import CantCreate from '../global/modals/cantCreate.jsx'
import LinearProgressModal from '../global/modals/linearProgressModal.jsx'

//generalUtils
import FileThumbnailer from '../../reactUtils/generalUtils/fileThumbnailer.jsx'

//Localization
import { useTranslation } from 'react-i18next';

import Icon from '../../uhoo-components/iconLibrary.jsx';
import { auraBasic, auraPro } from '../../reactUtils/generalUtils/auraPlans'

// Queries
import { getChannels } from '../../actions/uhoo/Channels.actions.js';


import { publishPost, addMedia, addMediaByURI, GetPost, addMediaOnlyParams } from "../../actions/uhoo";
import { publishChannelPost } from '../../actions/uhoo/Publish.actions.js';
import { getConfigs } from '../../actions/uhoo/Config.actions'; // Singularity

import "./css/createPostForm.css";
import { fileThumbnailer, compressImage } from "../../reactUtils/generalUtils/fileThumbnailer";

function Postform(props) {

  const { t, i18n } = useTranslation(); // Localization
  let history = useHistory();
  const he = require('he');

  //const [viewEvent, setViewEvent] = useState(props.event); // Publicacion de evento 

  const [fileUploaded, setFileUpload] = useState([]); // array de objetos que almacena las imagenes cargadas
  const [deleteAttachments, setDeleteAttachments] = useState(""); // string id, elimina la imagen adjunta seleccioando

  const [fileAttachments, setFileAttachments] = useState([]);

  const [openModalLibrary, setOpenModalLibrary] = useState(false); // Estado Modal Galeria 
  const [attachmentsFromLibrary, setAttachmentsFromLibrary] = useState([]); // array de objetos que almacena las imagenes traidas de la libreria (modal)

  const [showNotification, setShowNotification] = useState(false); // Estado Modal Notificaciones

  const [showNotificationCheckAudiences, setShowNotificationCheckAudiences] = useState(false);
  const [showNotificationVideo, setShowNotificationVideo] = useState(false);
  const [showNotificationSuccessfulPost, setShowNotificationSuccessfulPost] = useState(false);
  const [captionSuccessfulPost, setCaptionSuccessfulPost] = useState('');
  const [showNotificationWarning, setShowNotificationWarning] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState('');

  const [inputSend, setInputSend] = useState([]);
  const [audiencePublish, setAudiencePublish] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [channelPublish, setChannelPublish] = useState([]);

  const [notify, setNotify] = useState(true); // Enviar notifiaccion a audiencias
  const [ isLoadingPost, setIsLoadingPost ] = useState(false);

  const [maxLengthPost, setMaxLengthPost] = useState(null);
  const [lengthPostCreated, setLengthPostCreated] = useState(0);
  const [openModalCantCreate, setOpenModalCantCreate] = useState(false);
  const [openModalLinearProgress, setOpenModalLinearProgress] = useState(false);
  const [progressUploaded, setProgressUploaded] = useState(0);
  const [textModalCantCreate, setTextModalCantCreate] = useState('');
  const [showNotificationError, setShowNotificationError] = useState(false)
  const [captionError, setCaptionError] = useState('')
  const [channelDataMain, setChannelDataMain] = useState(null)
  const [readyAudiences, setReadyAudiences] = useState(false);
  
  useEffect(() => {
    selectFile(fileAttachments);  
  }, [fileAttachments])

  useEffect(async () => {
    const _configs = await getConfigs();
    let config = _configs.data.data;
    let _suscription = _get(config.find((element) => { return element.name === "suscription.plan"}), "value", 'basic');
    let query = [{ key: 'search', value: '', }, { key: 'page', value: '0', }, { key: 'active', value: '1', } ];
    const post = await GetPost(query);
    setLengthPostCreated(post.page.total);
    var _channelDataMain = await GetChannelData("main");
    setChannelDataMain(_channelDataMain);
    if(_suscription == 'pro') {
      setMaxLengthPost(_get(auraPro, 'maxLengthPost', null));
    }else{
      setMaxLengthPost(_get(auraBasic, 'maxLengthPost', null));
    }
  }, [])

  let URLactual = window.location;
  URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
  let isAura = URLactual.includes("aura");

  const GetChannelData = async (query) => {
    const info = await getChannels(query);
    return (info.data)
  }

  function uploadFileClick() {
    let inputFile = document.getElementById("attachmentsUpload"); // .files vector de archivos cargados
    inputFile.click();
  }

  function selectFile(fileAttachments) { //from library
    let uploadedItems = [...fileUploaded];
    fileAttachments.forEach(item => {
      let newItem = new Object();
      newItem.id = item.uuid;
      if (item.properties !== undefined || item.properties.originalname !== null) {
        newItem.name = item.properties.originalname;
      } else {
        newItem.name = item.mime_type;
      }
      newItem.url = item.url;
      newItem.date = item.created_at;
      uploadedItems.push(newItem);
    });
    let uploadedItemsFiltered = [];
    uploadedItems.forEach(item => {
      let exist = 0;
      uploadedItemsFiltered.forEach(element => {
        if(element && item.id == element.id){
          exist = 1;
        }
      });
      if(exist == 0) uploadedItemsFiltered.push(item);
    });
    setFileUpload(uploadedItemsFiltered);
  }

  function uploadFile() {
    let input = document.getElementById("attachmentsUpload"); // .files vector de archivos cargados
    if(input.files.length == 0) return;
    console.log("input.files[0].size", input.files[0].size);
    if(input.files[0].size > 261000000){
      setShowNotificationVideo(true);
      return;
    }
    let inputFiles = [input.files[0]];
    setInputSend(inputSend.concat(inputFiles));
    let uploadedItems = [...fileUploaded];
    let index = 0;
    inputFiles.forEach(item => {
      let newItem = new Object();
      newItem.id = index + "-" + item.name;//item.name;
      newItem.name = item.name;
      newItem.url = URL.createObjectURL(item);
      newItem.date = item.lastModified; // lastModified
      uploadedItems.push(newItem);
      index++;
    });
    setFileUpload(uploadedItems);
  }

  if (fileUploaded.length > 0) {
    props.setPreviewImage(fileUploaded[0]);  // para visualizar primera imagen en post
    //setPreviewImage(fileUploaded[0].url);
  } else (props.setPreviewImage(""));

  function selectFileClick() { // abre modal 
    setOpenModalLibrary(true);
  }

  /// ---------- End attachments ---------- /// 

  /// ---------- Delete attachments ---------- ///

  if (deleteAttachments != "") { // Elimina archivos cargados // estado --> deleteAttachments, setDeleteAttachments
    let filterFiles = [];
    let filterFilesAttachments = [];
    filterFiles = fileUploaded.filter((file) => file.id !== deleteAttachments);
    filterFilesAttachments = fileAttachments.filter((file) => file.uuid !== deleteAttachments);
    setFileAttachments(filterFilesAttachments)
    setFileUpload(filterFiles);
    setDeleteAttachments("");
  }

  /// ---------- End Delete attachments ---------- ///

  /// ---------- date - event Post ---------- ///
  let date = null;

  if (props.event) {
    date = true;
  }

  /// ---------- End date - event Post ---------- ///

  /// ---------- Notificar Audiencias ---------- ///

  function audiencesNotify() { // notify, setNotify
    setNotify(!notify);
    if (notify) {
      document.getElementById("postNotify").classList.add("notifyActive");
    } else {
      document.getElementById("postNotify").classList.remove("notifyActive");
    }
  }
  /// ---------- End Notificar Audiencias ---------- ///

  /// ---------- Publicar ---------- ///

  async function publish() {
    let title = document.getElementById("titlePost").value;
    let content = props.previewInfo; //document.getElementById("textarea").value; 
    content = he.decode(content);
    let type;
    let start_date_time = document.getElementById("start_date_time") ? document.getElementById("start_date_time").value : '';
    let end_date_time = document.getElementById("end_date_time") ? document.getElementById("end_date_time").value : '';     
    setIsLoadingPost(true);
    if(title == ''){
      setShowNotificationMessage("Debe agregar el título del post")
      setShowNotificationWarning(true);
      setIsLoadingPost(false);
      return;
    }
    if(content == ''){
      setShowNotificationMessage("Debe agregar el mensaje del post")
      setShowNotificationWarning(true);
      setIsLoadingPost(false);
      return;
    }
    if(props.event){
      if(start_date_time == ''){
        setShowNotificationMessage("Debe seleccionar la fecha de inicio del post")
        setShowNotificationWarning(true);
        setIsLoadingPost(false);
        return;
      }
      if(end_date_time == ''){
        setShowNotificationMessage("Debe seleccionar la fecha de finalización del post")
        setShowNotificationWarning(true);
        setIsLoadingPost(false);
        return;
      }
      
    }
    if (audiencePublish.length === 0) { // selectedAudiencesVerify
      setShowNotificationCheckAudiences(true);
      setIsLoadingPost(false);
    } else {
      
      console.log("channelDataMain", channelDataMain);     
      let channel_origin = channelDataMain[0].uuid;
      if (props.event) {
        type = "e";
      } else {
        type = "g";
      }
      let Post = {}
      if (type === "e"){
        Post = {
          channel_origin: channel_origin,
          content: content,
          start_date_time: moment(start_date_time).format("YYYY-MM-DD HH:mm"),
          end_date_time: moment(end_date_time).format("YYYY-MM-DD HH:mm"),
          hasMedia: fileUploaded.length,
          title: title,
          type: type,
          file: fileUploaded
        };
      } else {
        Post = {
          channel_origin: channel_origin,
          content: content,
          hasMedia: fileUploaded.length,
          title: title,
          type: type,
          file: fileUploaded
        };
      }
      const infoPost = await publishPost(Post);
      // imagenes cargadas
      for (let i = 0; i < inputSend.length; i++) {
        var formData = new FormData();
        let isImg = inputSend[i].name.includes('png') || inputSend[i].name.includes('jpeg') || inputSend[i].name.includes('jpg');
        if(isImg){
          let _compressImage = await compressImage(inputSend[i]);
          formData.append("order", i);
          formData.append("image", _compressImage);
          formData.append("thumbnail", _compressImage);
        }
        else if(inputSend[i].name.includes('mp4')){
          const thumbnail = await fileThumbnailer(inputSend[i], {width: 500, height: 500, quality: 0.8 });
          formData.append("thumbnail", thumbnail);
          formData.append("video", inputSend[i]);
        }else{
          formData.append("thumbnail", inputSend[i]);
          formData.append("file", inputSend[i]);
        }
        formData.append("properties", JSON.stringify({"origin_post": infoPost.data[0].uuid}));
        // let queryMedia =
        // {
        //   post: infoPost.data[0].uuid,
        //   image: formData
        // }
        let onUploadProgress;
        if(inputSend.length > 1){
          setProgressUploaded(i*(100 / inputSend.length));
        }else{
          onUploadProgress = (event) => {
            const percentage = Math.round((100 * event.loaded) / event.total);
            setProgressUploaded(percentage)
          };
        }
        
        setOpenModalLinearProgress(false);
        let mediaCreated;
        try{
          mediaCreated = await addMediaOnlyParams(formData, onUploadProgress);
          let MediaByURI = {
            uuidPost: infoPost.data[0].uuid,
            media: [{uuid: _get(mediaCreated, "data[0].uuid", "")}]
          }
          try{
            await addMediaByURI(MediaByURI);
          }catch(error){
            console.log("error 1", error);
            setShowNotificationError(true);
            setIsLoadingPost(false);
            setCaptionError(t('basicWords.errorUploadMedia'));
          }
        }catch(error){
          console.log("error 2", error);
          setShowNotificationError(true);
          setIsLoadingPost(false);
          setCaptionError(t('basicWords.errorUploadMedia'));
        }
        
      }
      setCaptionSuccessfulPost(t('basicWords.postSuccesfullyUploaded'))
      setShowNotificationSuccessfulPost(true);
      setTimeout(()=>{setShowNotificationSuccessfulPost(false);}, 1000);
      
      // imagenes de biblioteca
      for (let i = 0; i < fileAttachments.length; i++) {
          let MediaByURI = {
            uuidPost: infoPost.data[0].uuid,
            media: {uuid: fileAttachments[i].uuid}
          }
          await addMediaByURI(MediaByURI);
      }
      let publishObj = {};
      audiencePublish.forEach(item => {
        publishObj[item] = [infoPost.data[0].uuid];
      });
      const _configs = await getConfigs();
      let config = _get(_configs, "data.data", []);
      let notificationOmnichannel = config.find((element) => {
        return element.name === "app.features.omnichannel.enabled";
      })
      let publish = {
        mediums: mediums,
        notification: _get(notificationOmnichannel, "value", false), // to notify omnichannel
        pushNotification: !notify,
        publish: publishObj,
        unpublish: {}
      }
      await publishChannelPost(publish);
      setCaptionSuccessfulPost(t('basicWords.createdPost'))
      setShowNotificationSuccessfulPost(true);
      setTimeout(()=>{setShowNotificationSuccessfulPost(false);}, 2000);
      setTimeout(()=>{history.goBack();}, 1000);
      setIsLoadingPost(false);
    }

  }

  /// ---------- End Publicar ---------- ///

  const configOpeModalCanCreate = () => {
    setTextModalCantCreate(t('basicWords.post'));
    setOpenModalCantCreate(true);
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress className='buttonLoading' />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  let _user = JSON.parse(sessionStorage.getItem("own"));

  return (
    <React.Fragment>
      <div className="contentsPostform">
        {!readyAudiences?
          <div className="col-xs-12 col-md-12 circularProgressCenter" >
            <CircularProgress />
          </div>
        :
          null
        }
        {props.event
          ? (
            <div className="date">
              <div className="startDate">
                <div className="PostformTitles">{t('uhoo.createPost.form.start')}</div>
                <input type="datetime-local" className="inputFormsDate" id="start_date_time" /> {/*t('uhoo...')*/}
              </div>
              <div className="finalDate">
                <div className="PostformTitles">{t('uhoo.createPost.form.end')}</div>
                <input type="datetime-local" className="inputFormsDate" id="end_date_time" /> {/*t('uhoo...')*/}
              </div>
            </div>
          )
          : null
        }
        <div className="audience">
          <div className="PostformTitles">{t('uhoo.createPost.form.audienceTitle')}</div>
          <AudienceSelect user={_user} setAudiencePublish={setAudiencePublish} setChannelPublish={setChannelPublish} setReadyAudiences={setReadyAudiences}/>
        </div>
        <div className="title">
          <div className="PostformTitles">{t('uhoo.createPost.form.titleTitle')}</div>
          <input type="text" placeholder={t('uhoo.createPost.form.titleInput')} className="inputForms" id="titlePost"
            onChange={e => props.setPreviewTitle(e.currentTarget.value)}
          /> {/*t('uhoo...')*/} 
        </div>
        <div className="message">
          <div className="PostformTitles">{t('uhoo.createPost.form.messageTitle')}</div>
            <RichText setPreviewInfo={props.setPreviewInfo} previewInfo={props.previewInfo}/>
        </div>
        {/* div imagenes cargadas */}
        <div className="listAttachments">
          {fileUploaded.map(item => (
            <Attachments key={item.id}
              deleteAttachments={deleteAttachments}
              setDeleteAttachments={setDeleteAttachments}
              id={item.id}
              url={item.url}
              name={item.name}
              date={item.date}
            />
          ))}
        </div>
        <div className="attachments">
          <div className="contentAttachments postTooltip" onClick={uploadFileClick}>
            <span className="tooltiptextImage">{t('basicWords.maxHeightMedia')}</span>
            <Icon.upload />
            {t('uhoo.createPost.form.uploadAttachments')}
            <input hidden
              id="attachmentsUpload"
              type="file"
              accept="image/*, .png, .jpeg, .jpg, .mp4, .doc, .pdf, .xls, .csv"
              onChange={uploadFile}
              multiple
            />
          </div>
          <div className="contentAttachments" onClick={selectFileClick}>
            <Icon.library />
            {t('uhoo.createPost.form.libraryAttachments')}
          </div>
        </div>
        {/* <SectionAttachments/> */}
        <div className="media">
          <div className="PostformTitles">{t('uhoo.createPost.form.mediaTitle')}</div>
          <MediumSelect setMediums={setMediums}/>
        </div>
        <div className="createPostActions">
          <div id="postNotify" className="notify postTooltip" onClick={audiencesNotify}>
            <span className="tooltiptext">{t('uhoo.createPost.form.actionsNotify')}</span>
            <Icon.notify />
          </div>
          { isLoadingPost ?
            <div className="toPostDisabled" role="button">
              {/* {t('uhoo.createPost.form.actionsToPost')} */}
              <CircularProgressWithLabel value={progressUploaded}/>
            </div>
            :
            <div className="toPost" role="button" onClick={() =>  maxLengthPost != null && lengthPostCreated >= maxLengthPost && isAura ? configOpeModalCanCreate() : publish()}>
              {t('uhoo.createPost.form.actionsToPost')}
            </div>
          }

        </div>
      </div>
      {!!showNotificationError && (
        <SystemNotification
          setShowNotification={setShowNotificationError}
          type="Error"
          caption={captionError} 
        />
      )}
      {!!showNotificationWarning && (
        <SystemNotification
          setShowNotification={setShowNotificationWarning}
          type="Warning"
          caption={showNotificationMessage}
        />
      )}
      {!!showNotificationCheckAudiences && (
        <SystemNotification
          setShowNotification={setShowNotificationCheckAudiences}
          type="Warning"
          caption={t('basicWords.mustSelectAudiences')} 
        />
      )}
      {!!showNotificationVideo && (
        <SystemNotification
          setShowNotification={setShowNotificationVideo}
          type="Warning"
          caption={t('basicWords.videoSizeMax')} 
        />
      )}
      {!!showNotificationSuccessfulPost && (
        <SystemNotification
          setShowNotification={setShowNotificationSuccessfulPost}
          type="Success"
          caption={captionSuccessfulPost}
          // functionBack={true}
        />
      )}
      {!!openModalLibrary && (
        <Modal>
          <FromLibrary setOpenModalLibrary={setOpenModalLibrary} setFileAttachments={setFileAttachments} fileAttachments={fileAttachments}/>
        </Modal>
      )}

      {openModalCantCreate ? (
        <Modal>
          <CantCreate
            setOpenModal={setOpenModalCantCreate}
            text={textModalCantCreate}
          />
        </Modal>
      ) : null}

      {openModalLinearProgress ? (
        <Modal>
          <LinearProgressModal
            setOpenModal={setOpenModalLinearProgress}
            progress={progressUploaded}
          />
        </Modal>
      ) : null}
      
      
    </React.Fragment>
  );
};

export default Postform;
