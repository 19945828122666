import React, { Component, useContext } from "react";
import { get as _get } from "lodash";

import "./css/WidgetUhooPreview.css";

function widgetUhooPreview(props) {
  let URLactual = window.location;
  URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
  let isAura = URLactual.includes("aura")
  let title = props.previewTitle;
  let image = props.previewImage;

  return (
    <React.Fragment>
      <div className={isAura ? "contentAuraWidgetPreview" : "contentUhooWidgetPreview"}>
        <div className="widgetUhoo">
          <div className="widgetUhooContent">

            {image !== "" || title !== ""
              ?
              <React.Fragment>

                <div className="WidgetUhoo">

                  {image !== ""
                    ? <img className="imageWidgetUhoo" src={image} alt="image preview" />
                    : null
                  }

                  {title !== ""
                    ? <div className="titleWidgetUhoo">{title}</div>
                    : null
                  }

                </div>

              </React.Fragment>

              : null
            }

          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default widgetUhooPreview;


