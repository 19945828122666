import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { get as _get } from "lodash";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../css/react-tabs.scss';

import "./css/createWidget.css";
import { ReactComponent as BackArrow } from '../../assets/icons/navigation/backArrow.svg';

import WidgetForm from './createWidgetForm.jsx';
import BillboardForm from './createBillboardForm';

import WidgetPreview from "../../uhoo-components/preview/widgetPreview";
import BillboardPreview from "../../uhoo-components/preview/billboardPreview";

import { getConfigs } from '../../actions/uhoo/Config.actions'; // Singularity 

//Localization
import { useTranslation } from 'react-i18next';
import { connectAdvanced } from "react-redux";
import { auraBasic, auraPro } from '../../reactUtils/generalUtils/auraPlans'


const CreateWidget = () => {
  const { t, i18n } = useTranslation(); // Localization
  const history = useHistory();

  /// ----- Singularity ----- ///

  const [widgets, setWidgets] = useState(false);
  const [board, setBoard] = useState(false);
  const [maxLengthComplements, setMaxLengthComplements] = useState(null);
  const [lengthComplementsCreated, setLengthComplementsCreated] = useState(0);

  useEffect(async () => {
    const _configs = await getConfigs();
    let config = _configs.data.data;
    let _suscription = _get(config.find((element) => { return element.name === "suscription.plan"}), "value", 'basic');
    let currentConfigComplements = config.find(config => config.name == 'app.community.webTab.apps');
    currentConfigComplements = _get(currentConfigComplements, 'value', {});
    let lengthComplements = 0;
    for (const element in currentConfigComplements) {
      lengthComplements++;
    }
    setLengthComplementsCreated(lengthComplements);
    if(_suscription == 'pro') {
      setMaxLengthComplements(_get(auraPro, 'maxLengthComplements', null));
    }else{
      setMaxLengthComplements(_get(auraBasic, 'maxLengthComplements', null));
    }
  }, [])

  const Singularity = () => {
    useEffect(async () => {
      const _configs = await getConfigs();
      let config = _configs.data.data;

      setWidgets(
        _get(config.find((element) => {
          return element.name === "app.community.webTab.edition.enabled";
        }), "value", 0)
      );

      setBoard(0);

      setBoard(
        _get(config.find((element) => {
          return element.name === "app.community.board.edition.enabled";
        }), "value", 0)
      );

    }, []);
  };

  Singularity();

  if (widgets === 0) {
    history.replace("/")
  }

  /// --- end Singularity --- ///

  const [previewTitle, setPreviewTitle] = useState("");
  const [previewImage, setPreviewImage] = useState("");

  const [activeTab, setActiveTab] = useState("");

  if (board === 1) {
    return (
      <React.Fragment>
        <section className="Title">
          <BackArrow className="ico pointerCursor" onClick={() => history.goBack()} />
          <h3>{t('uhoo.widget.createTitle')}</h3>
        </section>

        <section className="widget">
          <Tabs>
            <TabList>
              <Tab>{t('uhoo.widget.complement')}</Tab>
              <Tab>{t('uhoo.widget.board')}</Tab>
            </TabList>

            <div className="widgetContent">
              <TabPanel>
                <WidgetForm
                  setActiveTab={setActiveTab}
                  setPreviewTitle={setPreviewTitle}
                  setPreviewImage={setPreviewImage}
                />
              </TabPanel>
              <TabPanel>
                <BillboardForm
                  setActiveTab={setActiveTab}
                  setPreviewTitle={setPreviewTitle}
                  setPreviewImage={setPreviewImage}
                />
              </TabPanel>
            </div>
          </Tabs>
        </section>

        <section className="previewWidgetTabs">
          <div className="previewContentWidget">

            {activeTab === "Widget" && (
              <WidgetPreview
                previewTitle={previewTitle}
                previewImage={previewImage}
              />
            )}

            {activeTab === "Billboard" && (
              <BillboardPreview
                previewTitle={previewTitle}
                previewImage={previewImage}
              />
            )}

          </div>
        </section>

      </React.Fragment>
    );
  } else /*if (board === 0)*/ {
    return (
      <React.Fragment>
        <section className="Title">
          <BackArrow className="ico pointerCursor" onClick={() => history.goBack()} />
          <h3>{t('uhoo.widget.createTitle')}</h3>
        </section>

        <section className="widget">
            <div className="widgetContent">
              <WidgetForm
                setActiveTab={setActiveTab}
                setPreviewTitle={setPreviewTitle}
                setPreviewImage={setPreviewImage}
              />
            </div>
        </section>

        <section className="previewWidget">
          <div className="previewContentWidget">
   
              <WidgetPreview
                previewTitle={previewTitle}
                previewImage={previewImage}
              />
            
          </div>
        </section>

      </React.Fragment>
    );
  } 
};

export default CreateWidget;





