import { get as _get } from "lodash";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as BackArrow } from '../../assets/icons/navigation/backArrow.svg';
import { ReactComponent as Close } from '../../assets/icons/navigation/closeModal.svg';
import TableUsers from './tableUsers.jsx'
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { UsersCommunityContext } from "./contex";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Modal, Box } from '@mui/material'
import SecondaryButton from "../../uhoo-components/buttons/secondaryButton.jsx";

//Localization
import { useTranslation } from 'react-i18next';
import { cloneDeep as _cloneDeep } from "lodash";
import SystemNotification from "../global/SystemNotification.jsx";
import CircularProgress from '@mui/material/CircularProgress';

//query
import { GetUserRol, getUser, getConfigs, updateUser, createUser, getAudience, updateUsers } from "../../actions/uhoo";
import "./css/importUsers.css"

const AudienceCommunity = () => {
  const [step, setStep] = useState(0);
  const [valueSelected, setValueSelected] = useState({});
  const [dataColumns, setDataColumns] = useState([]);
  const [titleColumns, setTitleColumns] = useState([]);
  const [AudienceData, setAudienceData] = useState([]); // array de consulta, contiene las audiencias
  const [rolData, setRolData] = useState([]); // array de consulta, contiene los roles
  const [optionsColumn , setOptionsColumn] = useState(["name","last_name", "middle_name","main_activity", "gender","birthday","active","email", "audiencie", "register1","register2","emailContact","whatsapp"]);
  const [ audienceToAdd, setAudienceToAdd] = useState(null);
  const [singularity, setSingularity] = useState({});
  const [ userpromise, setUserpromise ] = useState([]);

  const [ usersToAdd, setUsersToAdd ] = useState([]);
  const [ usersToAddSelected, setUsersToAddSelected ] = useState([]);
  const [ usersToDelete, setUsersToDelete ] = useState([]);
  const [ usersToDeleteSelected, setUsersToDeleteSelected ] = useState([]);
  const [ usersToUpdate, setUsersToUpdate ] = useState([]);
  const [ usersToUpdateSelected, setUsersToUpdateSelected ] = useState([]);
  const [ usersNotProcessed, setUsersNotProcessed ] = useState([]);
  // const [progress, setProgress] = useState(0);

  const [showNotificatioError, setShowNotificatioError] = useState(false);
  const [showNotificationWarning, setShowNotificationWarning] = useState(false);
  const [textNotificationWarning, setTextNotificationWarning] = useState('Debe seleccionar el correo de los usuarios');
  const [showNotificationSuccessfull, setShowNotificationSuccessfull] = useState(false);
  const [stepModal, setStepModal] = useState(0);

  const [onlyOneRol, setOnlyOneRol] = useState(false);
  const [onlyOneAudience, setOnlyOneAudience] = useState(false);
  const [audienceDefaultSelected, setAudienceDefaultSelected] = useState(null);
  const [audienceSelected, setAudienceSelected] = useState([]);
  const [rolDefaultSelected, setRolDefaultSelected] = useState([]);
  const [progress, setProgress] = React.useState(10);

  const UserContext = useContext(UsersCommunityContext);

  const { t, i18n } = useTranslation();
  let history = useHistory(); //  from 'react-router-dom' -> la uso para avanzar o retroceder en el historial de navegacion 
 
  useEffect(() => {
    const getRolData = async (query) => {
      const info = await GetUserRol(query);
      setRolData(info.data);
    };

    const singularity = async () => {
      const _configs = await getConfigs();
      let config = _configs.data;
      setSingularity(config);
    }

    const GetUserData = async (query) => {
      const info = await getUser(query);
      const compareNames = (a, b) => {
        let nameA = a.name;
        let nameB = b.name;
        if(nameA == null  || nameB == null) return 0
        let result = nameA.localeCompare(nameB);
        return result;
      }
      info.data.sort(compareNames);
      info.data.map(user => user.active = 1)
      setUserpromise(info.data);
    };

    const GetAudienceData = async (query) => {
      const info = await getAudience(query);
      let filtroActive = info.data.filter(audience => audience.active === 1)
      let filtroMedium = filtroActive.filter(audience => audience.properties.mediums !== undefined)
      setAudienceData(filtroMedium);
    };

    GetUserData([ { key: "page_size", value: 10000 }, ]);

    getRolData([ { key: "active", value: "1" },{ key: "page_size", value: 500 }, ]);

    GetAudienceData([{ key: 'page_size', value: '500' }])


    singularity();

  }, []);

  function uploadFileClick() {
    let inputFile = document.getElementById("importUsers"); // .files vector de archivos cargados
    inputFile.click();
  }

  function validMail(email) {
    // eslint-disable-next-line
    const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regexp.test(email);
  }
  
  function mailContatenator(mail = "", default_domain) {
    // eslint-disable-next-line no-control-regex
    return `${mail.replace(/[^\x00-\x7F]/g, "_")}@${default_domain}`;
  }

  const parserCSV = (mfile) => {
    return new Promise((resolve, reject) => {
      window.Papa.parse(mfile, {
        worker: true,
        // delimiter: ';',
        complete: results => {
          // let i = 0, globalLength;
          // Filtro para eliminar espacios en blanco y de 1 columna. Deben ser al menos 2 columnas
          // Elimina la cabecera y deja el título
          const filtrado = results.data.filter(x => x.length > 1);
          const obj = {
            titles: filtrado[0],
            data: filtrado.map(x => {
              const obj2 = {};
              for (let index = 0; index < x.length; index++) {
                const actual = x[index];
                obj2[filtrado[0][index]] = actual;
              }
              return obj2;
            })
          };
          obj.data.shift();
          resolve(obj);
        },
        error: err => reject(err)
      });
    });
  }

  const fileConversion = async (file) => {
    const converted = await parserCSV(file[0]);
    setDataColumns(converted.data);
    setTitleColumns(converted.titles);
    for (const iterator of converted.data) {
      for (const key in iterator) {
        if (iterator.hasOwnProperty(key)) {
          iterator[key] = iterator[key].trim();
        }
      }
    }
    setStep(step + 1);
    return converted;
  }

  const onDeleteChoose = (id) =>{
    let _valueSelected = _cloneDeep(valueSelected);
    let newOptions = _cloneDeep(optionsColumn);
    newOptions.push(valueSelected[id])
    delete _valueSelected[id];
    setOptionsColumn(newOptions);
    setValueSelected(_valueSelected);
  }

  const onClickSelectOption = (id) =>{
    let optSelected = document.getElementById(id).value;
    let newOptions = optionsColumn.filter(opt => opt != optSelected);
    let _valueSelected = _cloneDeep(valueSelected);
    setValueSelected(_valueSelected);
    _valueSelected[id] = optSelected;
    setOptionsColumn(newOptions);
  }

  const onClickSelectRoles = () =>{
    let _rolDefaultSelected= document.getElementById('rolSelect').value;
    _rolDefaultSelected = rolData.filter(x => x.uuid == _rolDefaultSelected);
    if(_rolDefaultSelected) _rolDefaultSelected = _rolDefaultSelected[0];
    setRolDefaultSelected(_rolDefaultSelected);
  }
  
  const onClickSelectAudience = () =>{
    let _audienceDefaultSelected = document.getElementById('audienceSelect').value;
    _audienceDefaultSelected = AudienceData.filter(x => x.uuid == _audienceDefaultSelected);
    setAudienceDefaultSelected(_audienceDefaultSelected);
  }

  const assignFields = () => {
    if (valueSelected && typeof valueSelected === "object" && Object.values(valueSelected).length && Object.values(valueSelected).find(key => key === "email") ) {
      let default_domain = _get(singularity.find(cf => cf.name === "default_domain"), "value");
      if (default_domain){
        default_domain = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(default_domain) ? default_domain : undefined;
      }
      let csvDatas = dataColumns.map(dt => {
        const newdt = {};
        for (const key in dt) {
          if (dt.hasOwnProperty(key)) {
            const elm = dt[key];
            if (valueSelected[key]) {
              if (
                valueSelected[key] === "email" &&
                default_domain &&
                !validMail(elm)
              ) {
                newdt[valueSelected[key]] = mailContatenator(elm, default_domain);
              } else {
                newdt[valueSelected[key]] = elm;
              }
            }
          }
        }
        if (typeof newdt.active === "undefined") newdt.active = true;
        newdt['audiences']= [];
        newdt['nameAudiences']= [];
        newdt['nameRoles']= [];
        newdt['rol']= {};
        return newdt;
      })
      try {
        const userWorker = new Worker(
          "./js/workers/UserBatchComparator.worker.js"
        );
        // Compare with worker
        userWorker.postMessage({
          users: userpromise,
          csv: csvDatas,
          singularity
        });
        userWorker.onmessage = (event) => {
          let data = _get(event, "data", {});
          data.update.forEach(user => {
            user.audiences = [];
            user.nameAudiences = [];
            user.nameRoles = [];
            user.rol = {};
          });
          data.delete.forEach(user => {
            user.audiences = [];
            user.nameAudiences = [];
            user.nameRoles = [];
            user.rol = {};
          });
          setUsersToAdd(_get(event, "data.add", []));
          setUsersToDelete(_get(event, "data.delete", []));
          setUsersToUpdate(_get(event, "data.update", []));
          setUsersNotProcessed(_get(event, "data.notProcessed", []));
          setStep(step + 1);
          userWorker.terminate();
        };
        userWorker.onerror = err => {
          userWorker.terminate();
          throw err;
        };
      } catch (error) {
        throw error;
      }
    }else{
      setShowNotificationWarning(true);
    }

  }

  const onSelectRolUser = (data, type, event) => {
    let _usersToAdd = _cloneDeep(usersToAdd);
    let _usersToUpdate = _cloneDeep(usersToUpdate);
    let _data = _cloneDeep(data);
    const {target } = event;
    let rolToAdd = target && target.value ? target.value : [];  
    _data.rol = rolToAdd;
    _data.nameRoles = rolToAdd.name ? [rolToAdd.name] : '';
    if(type == 'add'){
      _usersToAdd.forEach((user, i) => {
        if(user.email == _data.email){
          _usersToAdd[i] = _data;
        }
      });
    }else if(type == 'update'){
      _usersToUpdate.forEach((user, i) => {
        if(user.email == _data.email){
          _usersToUpdate[i] = _data;
        }
      });
    }
    
    setUsersToAdd(_usersToAdd);
    setUsersToUpdate(_usersToUpdate);
  }

  const onSelectAudienceUser = (data, type, event) => {
    let _usersToAdd = _cloneDeep(usersToAdd);
    let _usersToUpdate = _cloneDeep(usersToUpdate);
    let _data = _cloneDeep(data);
    const {target } = event;
    let audienceToAdd = target && target.value ? target.value : [];  
    audienceToAdd = audienceToAdd[audienceToAdd.length - 1];
    let haveYetAudience = false;
    if(_data.audiences.length > 0){
      _data.audiences.forEach(audience => {
        if(audienceToAdd && audienceToAdd.uuid){
          if(audience.uuid == audienceToAdd.uuid) haveYetAudience = true;
        }
      });
      if(haveYetAudience){
        let newAudiences = _data.audiences.filter(x => x.uuid != audienceToAdd.uuid);
        _data.audiences = newAudiences;
        let newAudiencesName = _data.nameAudiences.filter(x => x != audienceToAdd.name);
        _data.nameAudiences = newAudiencesName;
      }else{
        _data.audiences.push(audienceToAdd);
        _data.nameAudiences.push(audienceToAdd.name);
      }
    }else{
      _data.audiences.push(audienceToAdd);
      _data.nameAudiences.push(audienceToAdd.name);
    }
    if(type == 'add'){
      _usersToAdd.forEach((user, i) => {
        if(user.email == _data.email){
          _usersToAdd[i] = _data;
        }
      });
    }else if(type == 'update'){
      _usersToUpdate.forEach((user, i) => {
        if(user.email == _data.email){
          _usersToUpdate[i] = _data;
        }
      });
    }
    
    setUsersToAdd(_usersToAdd);
    setUsersToUpdate(_usersToUpdate);
  }

  const onChangeSelect = (data, type) => {
    if(data.length){ // if select all
      if(type == 'add'){
        if(usersToAddSelected.length == data.length){
          setUsersToAddSelected([])
        }else{
          let _usersToAddSelected = [];
          data.forEach(element => {
            _usersToAddSelected.push(element['email']);
            setUsersToAddSelected(_usersToAddSelected)
          });
        }
        
      }else if (type == 'delete'){
        if(usersToDeleteSelected.length == data.length){
          setUsersToDeleteSelected([])
        }else{
          let _usersToDeleteSelected = [];
          data.forEach(element => {
            _usersToDeleteSelected.push(element.uuid);
            setUsersToDeleteSelected(_usersToDeleteSelected)
          });
        }


      }else if(type == 'update'){
        if(usersToUpdateSelected.length == data.length){
          setUsersToUpdateSelected([])
        }else{
          let _usersToAddSelected = [];
          data.forEach(element => {
            _usersToAddSelected.push(element.uuid);
            setUsersToUpdateSelected(_usersToAddSelected)
          });
        }
      }
    }else{
      if(type == 'add'){
        if(!usersToAddSelected.includes(data['email'])){
          let _usersToAddSelected = _cloneDeep(usersToAddSelected);
          _usersToAddSelected.push(data['email']);
          setUsersToAddSelected(_usersToAddSelected)
        }else{
          let _usersToAddSelected = _cloneDeep(usersToAddSelected);
          let newUsers = _usersToAddSelected.filter(x => x != data.email);
          setUsersToAddSelected(newUsers)
        }
      }else if (type == 'delete'){
        if(!usersToDeleteSelected.includes(data.uuid)){
          let _usersToDeleteSelected = _cloneDeep(usersToDeleteSelected);
          _usersToDeleteSelected.push(data.uuid);
          setUsersToDeleteSelected(_usersToDeleteSelected)
        }else{
          let _usersToDeleteSelected = _cloneDeep(usersToDeleteSelected);
          let newUsers = _usersToDeleteSelected.filter(x => x !== data.uuid);
          setUsersToDeleteSelected(newUsers)
        }
      }else if(type == 'update'){
        if(!usersToUpdateSelected.includes(data.uuid)){
          let _usersToUpdateSelected = _cloneDeep(usersToUpdateSelected);
          _usersToUpdateSelected.push(data.uuid);
          setUsersToUpdateSelected(_usersToUpdateSelected)
        }else{
          let _usersToUpdateSelected = _cloneDeep(usersToUpdateSelected);
          let newUsers = _usersToUpdateSelected.filter(x => x != data.uuid);
          setUsersToUpdateSelected(newUsers)
        }
      }
    }
    
  }

  const cleanInfo = () => {
    setUsersToAdd([])
    setUsersToDelete([])
    setUsersToUpdate([])
    setUsersToAddSelected([])
    setUsersNotProcessed([])
    setUsersToDeleteSelected([])
    setUsersToUpdateSelected([])
    setStep(step - 1)
  } 

  function timeout(ms) { return new Promise(resolve => setTimeout(resolve, ms)); }

  const saveUsers = async() => {
    let audiences = null;
    try {
      setStep(step + 1);
      setProgress(10);
      if(usersToDeleteSelected.length){
        usersToDeleteSelected.forEach(async uuid  => {
          let tosend = {};
          tosend["uuid"] = uuid;
          var formData = new FormData();
          formData.append("uuid", uuid);
          formData.append("active", 0); // to soft delete user
          tosend["data"] = formData;
          try {
              let response = await updateUser(tosend, true); //save info user
              response = _get(response, "data[0]", {});
          } catch (error) {
              setShowNotificatioError(true);
              setShowNotificationSuccessfull(false);
              setTimeout(()=>{history.push({pathname: '/community', state: {updateUsers: true}})}, 1000);
              return;
          }
        });
      }
      await timeout(500);
      setProgress(30);
      if(usersToAddSelected.length){
        let _usersToAdd = [];
        usersToAdd.forEach(user => {
          usersToAddSelected.forEach(selectedUser => {
            if(user.email == selectedUser){
              _usersToAdd.push(user);
            }
          });
        });
        let stopAddUsers = false;
        _usersToAdd.forEach(user => {
          user.rol = onlyOneRol ? rolDefaultSelected.uuid : user.rol && user.rol.uuid ? user.rol.uuid : rolDefaultSelected.uuid;
          console.log("user.rol", user.rol);
          if(user.rol == undefined){
            stopAddUsers = true;
          }
        })
        if(stopAddUsers){
          setStep(step - 1);
          setTextNotificationWarning('Todos los usuarios deben tener un rol asignado');
          setShowNotificationWarning(true);
          return;
        }
        await _usersToAdd.forEach(async user => {
          // user.rol = onlyOneRol ? rolDefaultSelected.uuid : user.rol && user.rol.uuid ? user.rol.uuid : rolDefaultSelected.uuid;
          user.audiences = onlyOneAudience ? audienceDefaultSelected : user.audiences ? user.audiences : [];
          if(user.birthday){
            user.birthday = moment(user.birthday).format("YYYY-MM-DD");
          }
          delete user.nameAudiences;
          delete user.nameRoles;
          let response = await createUser(user);
          let usersToAddInSpecificAudience = [_get(response, "data.data[0].uuid", null)];
          if(user.audiences){
            user.audiences.forEach(async channel => {
              const entities = {};
              usersToAddInSpecificAudience.forEach(lst => {
                  entities[lst] = {};
              });
              let data ={
                  kind: {
                          "891f6801-19eb-4c3b-98c9-64400c2837f3": { entities }
                      }
                  };
              await updateUsers(_get(channel, "uuid", ""), data); //add users
            });
          }
          
          let isUserToUpdate = userpromise.find((userBase)=> userBase.emails[0].email == user.email);
          if(!response.data){
            if(isUserToUpdate){
              var formData = new FormData();
              for (const index in  isUserToUpdate) {
                if(index == 'birthday'){
                  formData.append(index, moment(isUserToUpdate[index]).format("YYYY-MM-DD"));
                }else{
                  if(typeof isUserToUpdate[index] === 'object'){
                    formData.append(index, JSON.stringify(isUserToUpdate[index]));
                  }else{
                    formData.append(index, isUserToUpdate[index]);
                  }
                }
              }
              formData.append('active', 1);
              let response = await updateUser(formData, false, true, _get(isUserToUpdate, "uuid", "")); //save info user
              
            }else{
              setShowNotificatioError(true);
              setShowNotificationSuccessfull(false);
              setTimeout(()=>{history.push({pathname: '/community', state: {updateUsers: true}})}, 1000);
              return;
            }
          }
        });
      }
      await timeout(500);
      setProgress(60);
      console.log("usersToUpdateSelected", usersToUpdateSelected);
      if(usersToUpdateSelected.length){
        let _usersToUpdate = [];
        usersToUpdate.forEach(user => {
          usersToUpdateSelected.forEach(selectedUser => {
            if(user.uuid == selectedUser){
              _usersToUpdate.push(user)
            } 
          });
        });
        await _usersToUpdate.forEach(async user => {
          dataColumns.forEach(async dataColumn => {
            for (const col in  dataColumn) {
            if(dataColumn[col] == user.emails[0].email){
                var formData = new FormData();
                for (const col in  dataColumn) {
                  for (const value in  valueSelected) {
                    if(col == value){
                      if(valueSelected[value] == 'birthday'){
                        formData.append(valueSelected[value], moment(dataColumn[col]).format("YYYY-MM-DD"));
                      }else{
                        formData.append(valueSelected[value], dataColumn[col]);
                      }
                    }
                  }
                }
                formData.append('active', 1);
                let response = await updateUser(formData, false, true, _get(user, "uuid", "")); //save info user
                if(!response.data){
                  setShowNotificatioError(true);
                  setShowNotificationSuccessfull(false);
                  setTimeout(()=>{history.push({pathname: '/community', state: {updateUsers: true}})}, 1000);
                  return;
                }
                let usersToAddInSpecificAudience = [_get(response, "data[0].uuid", null)];
                user.audiences = onlyOneAudience ? audienceDefaultSelected : user.audiences ? user.audiences : [];
                if(user.audiences){
                  user.audiences.forEach(async channel => {
                    const entities = {};
                    usersToAddInSpecificAudience.forEach(lst => {
                        entities[lst] = {};
                    });
                    let data ={
                        kind: {
                                "891f6801-19eb-4c3b-98c9-64400c2837f3": { entities }
                            }
                        };
                        await updateUsers(_get(channel, "uuid", ""), data); //add users
                  });
                }
              }
            }
          });
        });
      }
      await timeout(500);
      setProgress(90);
      setProgress(100);
      setShowNotificationSuccessfull(true);
      await timeout(1500);
      setShowNotificationSuccessfull(false);
      setTimeout(()=>{history.push({pathname: '/community', state: {updateUsers: true}})}, 1000)
    } catch (error) {
      console.log("error", error);
      setShowNotificatioError(true);
    }
  }

  // renders

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };


  return (
    <React.Fragment>
      <section className="TitleManageEmail">
        <div className="titleArrow">
          <BackArrow className="ico pointerCursor" onClick={() => history.goBack()} />
          <h3>{t('uhoo.importUsers.title')}</h3>
        </div>
      </section>
      <section className="previewDataImportUsers">
        {
          userpromise.length == 0 ? 
          <div className="containerImportUsersFiles">
            <div className="centerFlex">
              <CircularProgress />
            </div>
          </div>
          :
          <div className="containerImportUsersFiles">
              {
                  step == 0 ? 
                  <div className="containerImportUsers"> 
                      <div className="centerFlex">
                          <h4>{t('uhoo.importUsers.setp0.content')}</h4>
                          <div className="containerImportUsersFile" onClick={uploadFileClick}>
                              {t('uhoo.createPost.form.uploadAttachments')}
                              <input hidden type="file" accept=".csv" id='importUsers' onChange={(e) => fileConversion(e.target.files)} />
                          </div>
                      </div>
                  </div>
                  : null
              }
              {
                  step == 1  && titleColumns.length > 0? 
                  <>
                  <div className="containerImportUsers"> 
                      {
                          titleColumns.map((title, i) => {
                              return (
                                  <div className="containerColumn">
                                    {
                                      valueSelected[title] ?
                                      <div onClick={() => onDeleteChoose(title)} className="containerSelected">
                                        <p className="valueSelected">{t(`basicWords.${valueSelected[title]}`)}</p>
                                        <div className="CloseItemSelected" role="button"><Close/></div>
                                      </div>
                                      :
                                      <>
                                        <p>{t('uhoo.importUsers.step1.asignRoles')}</p>
                                        <select name={title} id={title} className="inputSelectOptionsImporUser" onChange={()=> {onClickSelectOption(title)}}>
                                            <option value="" selected disabled hidden> {t('basicWords.selectOption')} </option> 
                                            {
                                                optionsColumn.map((opt) => {
                                                    return (<option value={opt}>{t(`basicWords.${opt}`)}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                      </>
                                    }
                                      
                                    {
                                        title == '' ? 
                                        <h4>{t('uhoo.importUsers.emptyTitle')}</h4>
                                        : 
                                        <h4>{title}</h4>
                                    }
                                      <ul>
                                          {
                                              dataColumns.map((dt) => {
                                                  return(
                                                      <div>
                                                          {dt[title] ? 
                                                          <li>{dt[title]}</li>
                                                          : null}
                                                      </div>
                                                  )
                                              })
                                          }
                                      </ul>
                                  </div>

                              )
                          })
                      }
                  </div>
                  <div className="createPostActions">
                      <div className="schedule" role="button" onClick={() => setStep(step - 1)}>{t('basicWords.back')} </div>
                      <div className="toPost" role="button" onClick={() =>assignFields()}>{t('basicWords.next')}</div>
                  </div>
                  </>
                  : null
              }
              {
                  step == 2 ? 
                  <>
                    <div className="containerImportUsers"> 
                        <div className="leftFlex">
                          <>
                            <div className="containerInputsCheckbox">
                              <input type="checkbox" className="filterUsersDeleted" checked={onlyOneRol}  onChange={(e) => setOnlyOneRol(e.target.checked)} /> {t('basicWords.generalRol')}
                            </div>
                            {
                              onlyOneRol ? 
                              <div className="containerRolesImportUser">
                                <select id='rolSelect' className="inputSelectOptionsImporUser" onChange={()=> {onClickSelectRoles()}}>
                                    <option value="" selected disabled hidden> {t('basicWords.selectOption')} </option> 
                                    {
                                        rolData.map((rol) => {
                                            return (<option value={rol.uuid}>{rol.name}</option>
                                            )
                                        })
                                    }
                                </select>
                              </div>
                              : 
                              null
                            }
                            <div className="containerInputsCheckbox">
                              <input type="checkbox" className="filterUsersDeleted" checked={onlyOneAudience}  onChange={(e) => setOnlyOneAudience(e.target.checked)} /> {t('basicWords.generalAudience')}
                            </div>
                            {
                              onlyOneAudience ? 
                              <div className="containerRolesImportUser">
                                <select id="audienceSelect" className="inputSelectOptionsImporUser" onChange={(item) => onClickSelectAudience(item.target.value)}>
                                  <option value="" selected disabled hidden> {t('basicWords.selectOption')} </option> 
                                  {AudienceData.map((item) => <option key={item.uuid} value={item.uuid} > {item.name} </option> )}
                                  <option key={"all"} value={"All"} > {t("basicWords.allUsers")} </option>
                                </select>
                              </div>
                              : 
                              null
                            }
                            
                            <Tabs className="tabsImportUsers">
                              <TabList>
                                <Tab>{t('uhoo.importUsers.tab1')} {usersToAddSelected.length} / {usersToAdd.length}</Tab>
                                <Tab>{t('uhoo.importUsers.tab2')} {usersToUpdateSelected.length} / {usersToUpdate.length}</Tab>
                                <Tab>{t('uhoo.importUsers.tab3')} {usersToDeleteSelected.length} / {usersToDelete.length}</Tab>
                                <Tab>{t('uhoo.importUsers.tab4')}</Tab>
                              </TabList>

                              <TabPanel>
                                <div className="userTableImportUsers">
                                  <TableUsers users={usersToAdd} usersSelected={usersToAddSelected} columns={valueSelected} 
                                  type='add' AudienceData={AudienceData} rolData={rolData} audienceSelected={audienceSelected} 
                                  onSelectAudienceUser={(data, type, e) => onSelectAudienceUser(data, type, e)} 
                                  onSelectRolUser={(data, type, e) => onSelectRolUser(data, type, e)} 
                                  onChange={(uuid, e)=> onChangeSelect(uuid, e)}
                                  onlyOneAudience={onlyOneAudience}
                                  onlyOneRol={onlyOneRol}
                                  />
                                </div>              
                              </TabPanel>
                              <TabPanel>
                                <div className="userTableImportUsers">
                                  <TableUsers users={usersToUpdate} usersSelected={usersToUpdateSelected} columns={valueSelected} 
                                  type='update' AudienceData={AudienceData} rolData={rolData} audienceSelected={audienceSelected} 
                                  onSelectAudienceUser={(data, type, e) => onSelectAudienceUser(data, type, e)} 
                                  onSelectRolUser={(data, type, e) => onSelectRolUser(data, type, e)} 
                                  onChange={(uuid, e)=> onChangeSelect(uuid, e)}
                                  onlyOneAudience={onlyOneAudience}
                                  onlyOneRol={onlyOneRol}
                                  />
                                </div>
                              </TabPanel>
                              <TabPanel>
                                <div className="userTableImportUsers">
                                  <TableUsers users={usersToDelete} usersSelected={usersToDeleteSelected} columns={valueSelected} 
                                  type='delete' onChange={(uuid, e)=> onChangeSelect(uuid, e)}/>
                                </div>
                              </TabPanel>
                              <TabPanel>
                                <div className="userTableImportUsers">
                                  <TableUsers users={usersNotProcessed} usersSelected={[]} columns={valueSelected} type='notProccesed' disableInput={true} onChange={(uuid, e) => onChangeSelect(uuid, e)}/>
                                </div>
                              </TabPanel>
                            </Tabs>
                          </>
                        </div>
                    </div>
                    <div className="createPostActions">
                      <div className="schedule" role="button" onClick={() => cleanInfo()}>{t('basicWords.back')} </div>
                      <div className="toPost" role="button" onClick={() => saveUsers()}>{t('basicWords.next')}</div>
                    </div>
                  </>
                  : null
              }
              {
                step == 3 ? 
                <div className="containerImportUsers"> 
                    <div className="lastContainer">
                        <h4>{t('uhoo.importUsers.process')}</h4>
                        <LinearProgressWithLabel variant="determinate" value={progress} />
                    </div>
                </div>
                :
                null
              }
          </div>
        }
      </section>

      {
        !!showNotificationWarning && (
        <SystemNotification
            setShowNotification={setShowNotificationWarning}
            type="Warning"
            caption={textNotificationWarning}
        />
        )
      }
      {
          !!showNotificationSuccessfull && (
          <SystemNotification
              setShowNotification={setShowNotificationSuccessfull}
              type="Success"
              caption="Importación exitosa"
          />
          )
      }
      {
          !!showNotificatioError && (
          <SystemNotification
              setShowNotification={setShowNotificatioError}
              type="Error"
              caption="Errores al importar"
          />
          )
      }
        

    </React.Fragment>
  );
};

export default AudienceCommunity;
