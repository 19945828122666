import React, { Fragment } from "react";

import "./css/Title.scss";

export function TitleCard({ text }) {
  return (
    <Fragment>
      <h2 className="titleCard">{text}</h2>
    </Fragment>
  );
}
