import React, { useState, useEffect } from "react";


import '../../reactUtils/systemVariables/firebase';
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";

import { ReactComponent as UhooLogo } from '../../assets/brand/logotype.svg';
import { ReactComponent as IcoMail } from '../../assets/icons/login/mail.svg';
import { ReactComponent as IcoPass } from '../../assets/icons/login/password.svg';
// import { ReactComponent as Google } from '../../assets/icons/login/google.svg';
// import { ReactComponent as Facebook } from '../../assets/icons/login/facebook.svg';
import logoAura from "../../assets/images/logo.png";
import logoUhoo from "../../assets/images/LogoUhoo.png";
import CircularProgress from '@mui/material/CircularProgress';
import SystemNotification from "../global/SystemNotification.jsx";
import i18n from "i18next";

// CSS
import "./css/SignIn.css";

// Queries
import { getCustomToken, getOwn } from "../../actions/uhoo"

//Localization
import { useTranslation, withTranslation } from 'react-i18next';
import { get as _get } from "lodash";
import { Link } from "react-router-dom";

const SignIn = () => {

  const { t, i18n } = useTranslation(); // Localization
  if(i18n.language != 'en' && i18n.language != 'es'){
    i18n.changeLanguage('en')
  }
  const [showWarningMail, setShowWarningMail] = useState(false);
  const [showWarningPass, setShowWarningPass] = useState(false);

  const [showInvalidEmail, setShowInvalidEmail] = useState(false);
  const [captionInvalidEmail, setCaptionInvalidEmail] = useState(t('uhoo.login.error.invalidMail'))
  const [showWrongPassword, setShowWrongPassword] = useState(false);
  const [showAuthError, setShowAuthError] = useState(false);

  const [showCmsAccessFalse, setShowCmsAccessFalse] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [activeLogo, setActiveLogo] = useState("");
  const [loading, setLoading] = useState();

  let rememberMe;

  if (email.length > 0 & password.length > 0) {
    rememberMe = true;
  } else { rememberMe = false; }

  useEffect(() => {
    let URLactual = window.location;
    URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
    let isAura = URLactual.includes("aura");
    if (isAura) {
      setActiveLogo(<img style={{ "maxHeight": "100px" }} src={logoAura} alt="Header Aura" />);
      const favicon = document.getElementById("favicon"); //Change favicon in Uhoo CMS
      favicon.href = "/logo1.ico";
      document.title = "Somos Aura | CMS";
    } else {
      setActiveLogo(<img style={{ "maxHeight": "100px" }} src={logoUhoo} alt="Header Aura" />);
    }
  }, []);

  const submitMail = () => {
    setLoading(true);
    if (email.length === 0) {
      setShowWarningMail(true);
      setLoading(false);
    } else if (password.length === 0) {
      setShowWarningPass(true);
      setLoading(false);
    } else {
      setLoading(true);
      const auth = getAuth();

      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          const securityServer = async (query) => {
            let URLactual = window.location;
            let bundleName;
            if(_get(URLactual, "host", "uhoo.io")  == "shaio.uhoo.io"){ //config Shaio
              bundleName = "io.uhoo.shaio";
            } else if(_get(URLactual, "host", "uhoo.io")  == "vanti.uhoo.io"){ //config Vanti
              bundleName = "io.uhoo.vanti";
            } else if(_get(URLactual, "host", "uhoo.io")  == "porvenir.uhoo.io"){ //config Porvenir
              bundleName = "io.uhoo.porvenir";
            } else if(_get(URLactual, "host", "uhoo.io")  == "tulia.uhoo.io"){ //config Tulia
              bundleName = "io.uhoo.tulia";
            } else if(_get(URLactual, "host", "uhoo.io")  == "cedej.aurapp.org"){ //config Cedej
              bundleName = "org.somosaura.cedej";
            } else if(_get(URLactual, "host", "uhoo")  == "rcenvivo.aurapp.org"){ //config RC
              bundleName = "org.somosaura.regnumchristi";
            } else if(_get(URLactual, "host", "uhoo")  == "aurapp.org"){ //config Aurapp
              bundleName = "org.somosaura.aurapp";
            }else { 
              if(process.env.REACT_APP_DEFAULT_CONFIG_FB == 'Aura'){
                //config Aurapp
                bundleName = "org.somosaura.aurapp";
              }
              else if(process.env.REACT_APP_DEFAULT_CONFIG_FB == 'RC'){
                bundleName = "org.somosaura.regnumchristi";
              }
              else if(process.env.REACT_APP_DEFAULT_CONFIG_FB == 'Porvenir'){
                bundleName = "io.uhoo.porvenir";
              }
              else{
                // default config Uhoo
                bundleName = "com.brilliant.brilliantapp";
              }              
            }
            const CustomToken = await getCustomToken(query, bundleName);
            if (_get(CustomToken, "decodedToken.cms_access", true) === false) {
              setShowCmsAccessFalse(true);
              setLoading(false);
            } else {
              sessionStorage.setItem("userInfo", JSON.stringify(user));
              sessionStorage.setItem("access_token", CustomToken.token);
              let _own = await getOwn();
              _own.token = CustomToken.token;
              sessionStorage.setItem("own", JSON.stringify(_own));
              let URLactual = window.location;
              URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
              window.location.href = window.location.href;
            }
          }
          securityServer(user);
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ...
          console.log("errorCode", errorCode);
          setLoading(false);
          switch (errorCode) {
            case "auth/invalid-email":
              console.error(errorMessage);
              setCaptionInvalidEmail(t('uhoo.login.error.invalidMail'));
              setShowInvalidEmail(true);
              break;
            case "auth/user-not-found":
              console.error(errorMessage);
              setCaptionInvalidEmail(t('uhoo.login.error.userNotFound'));
              setShowInvalidEmail(true);
              break;
            case "auth/wrong-password":
              console.error(errorMessage);
              setCaptionInvalidEmail(t('uhoo.login.error.wrongPassword'));
              setShowInvalidEmail(true);
              break;
            case "auth/too-many-requests":
              console.error(errorMessage);
              setCaptionInvalidEmail(t('uhoo.login.error.tooManyRequest'));
              setShowInvalidEmail(true);
              break;
            case "auth/network-request-failed":
              console.error(errorMessage);
              setCaptionInvalidEmail(t('basicWords.networkFailed'));
              setShowInvalidEmail(true);
              break;
            default:
              console.log(errorCode, errorMessage);
              setShowAuthError(true);
              break;
          }

        });

    }
  }

  return (
    <React.Fragment>
      
      <div className="login">
        <div className={loading ? "containerLoginNotAllowed" : "containerLogin"}>
          <div className="logoLogin">
            {activeLogo}
          </div>
          <div className="titleLogin">{t('uhoo.login.title')}</div>
          <div className="subTitleLogin">{t('uhoo.login.subTitle')}</div>

          <div className="actionLogin">
            <div className="inputLogin">
              <IcoMail className="ico" />
              <input type="email" placeholder={t('uhoo.login.enterMail')} className="btnEmail" id="username" onChange={(ev) => setEmail(ev.target.value)} required />
            </div>
            <div className="inputLogin">
              <IcoPass className="ico" />
              <input type="password" placeholder={t('uhoo.login.enterPassword')} className="btnPassword" id="passwordOut" onChange={(ev) => setPassword(ev.target.value)} required />
            </div>

            {rememberMe
              ? (
                <div className="rememberMe">
                  <input type="checkbox" value="lsRememberMe" id="rememberMe" /> {t('uhoo.login.rememberMe')}
                </div>
              )
              : null
            }

            <div className={loading ? "actionLoginButtonNotAllowed" : "actionLoginButton"}>
              <button type="button" onClick={submitMail}>{t('uhoo.login.logIn')}</button>
            </div>

            <div className="optionActionLogin">
              <div className="left pointerCursor">
                <a href={`forgotpass?email=${email}`}>{t('uhoo.login.forgotMyPassword')}</a>
              </div>
              {/* <div className="right pointerCursor">{t('uhoo.login.logOn')}</div> */}
            </div>
          </div>
        </div>
        {loading ? 
        <div style={{ display: "flex", "justify-content": "center", width: "100%", height: "100vh", "align-items": "center", cursor: "not-allowed"}}>
          <CircularProgress />
        </div>
        : null}
      </div>

      {!!showWarningMail && (
        <SystemNotification
          setShowNotification={setShowWarningMail}
          type="Warning"
          caption={t('uhoo.login.error.mail')}
        />
      )}

      {!!showWarningPass && (
        <SystemNotification
          setShowNotification={setShowWarningPass}
          type="Warning"
          caption={t('uhoo.login.error.password')}
        />
      )}

      {!!showInvalidEmail && (
        <SystemNotification
          setShowNotification={setShowInvalidEmail}
          type="Error"
          caption={captionInvalidEmail}
        />
      )}

      {/* {!!showWrongPassword && (
        <SystemNotification
          setShowNotification={setShowWrongPassword}
          type="Error"
          caption={t('uhoo.login.error.invalidPassword')}
        />
      )} */}

      {!!showAuthError && (
        <SystemNotification
          setShowNotification={setShowAuthError}
          type="Error"
          caption={t('uhoo.login.error.AuthError')}
        />
      )}

      {!!showCmsAccessFalse && (
        <SystemNotification
          setShowNotification={setShowCmsAccessFalse}
          type="Error"
          caption={t('uhoo.login.error.CmsAccessFalse')}
        />
      )}

    </React.Fragment>

  );
}

export default withTranslation()(SignIn);
