import React, { Component, useContext } from "react";
import { get as _get } from "lodash";

import "./css/PostLinkPreview.css";

function LinkedinPreview( props ) {
    let URLactual = window.location;
    URLactual = _get(URLactual, "host", "hub.bcodesolutions.com");
    let isAura = URLactual.includes("aura");
    let title = props.previewTitle;
    let text = props.previewInfo;
    let image = props.previewImage;
    let isImg = true;
    if(image){
      isImg = image.name.includes('png') || image.name.includes('jpeg') || image.name.includes('jpg');
    }
   return(    
    <React.Fragment>
      <div className="contentLinkPreview">
        <div className="linkedin">
          <div className="linkedinContent">

            {image !== "" || text !== "" || title !== ""
              ? 
              <React.Fragment>

              <div className="link">

                {title !== ""
                  ? <div className={isAura ? "titleLinkAura" : "titleLink"}><div className="titleTextLink">{title}</div></div>
                  : null
                } 

                {text !== ""
                  ? <div className="textLink">{text}</div>
                  : null
                }
                <div className="contentImage">
  
                {image.url !== "" ?
                  <>
                  {
                    image.name ? 
                    <>
                      {
                        image.name.search('.mp4') != -1 ?
                        <video className="imageUhoo" src={image.url} alt="image preview"></video>
                        :
                        <>
                        {
                            isImg ?
                            <img  className="imageUhoo" src={image.url} key={image.uuid} alt="image preview"/>
                            :
                            <img  className="imageUhoo" onClick={() => window.open(image.url,'_blank')} key={image.uuid} src='http://cdn.onlinewebfonts.com/svg/img_162799.png' alt='document' />

                        }
                      </>
                      }
                      
                    </>
                    :  null
                  }
                  </>
                  : null
                }          
                </div>

                <div className="socialMediaActions"/>
              </div>

              </React.Fragment>
              
              : null
            }
            
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LinkedinPreview;


