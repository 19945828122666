import React, { useState, useContext, useEffect } from "react";
//import * as React from 'react';
import { get as _get, omit as _omit } from 'lodash';
import { useHistory } from "react-router-dom";

import "./css/activity.css";
import Icon from '../../uhoo-components/iconLibrary.jsx';
import { Link } from "react-feather";

//Context
import { ActivityProvider, ActivityContext } from './contex';

import CleanColumns from './components/cleanColumns.jsx';
import Column from './components/column.jsx';

//Localization
import { useTranslation } from 'react-i18next';

import SystemNotification from '../global/SystemNotification.jsx';


const Activity = () => {

  const { setOpenModalAddColumn, columnProperties } = useContext(ActivityContext);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isAura, setIsAura] = useState(false);

  //console.log("columnProperties", columnProperties);

  const { t, i18n } = useTranslation(); //  Localization  
  let history = useHistory(); //  from 'react-router-dom' -> la uso para avanzar o retroceder en el historial de navegacion  

  // life cicles
  useEffect(() => {
    const verifyAurapp = async () => {
      const URLactual = window.location.href;
      setIsAura(URLactual.includes("aura"));
    }
    verifyAurapp()
  }, [])

  function addColumn() {
    setOpenModalAddColumn(true);
  }

  function timeout(ms) { return new Promise(resolve => setTimeout(resolve, ms)); }

  const  copyLink = async (route) => {
    let URLactual = window.location;
    let bundleName;
    if(_get(URLactual, "host", "uhoo.io")  == "shaio.uhoo.io"){ //config Shaio
      bundleName = "io.uhoo.shaio";
    } else if(_get(URLactual, "host", "uhoo.io")  == "vanti.uhoo.io"){ //config Vanti
      bundleName = "io.uhoo.vanti";
    } else if(_get(URLactual, "host", "uhoo.io")  == "porvenir.uhoo.io"){ //config Porvenir
      bundleName = "io.uhoo.porvenir";
    } else if(_get(URLactual, "host", "uhoo.io")  == "tulia.uhoo.io"){ //config Tulia
      bundleName = "io.uhoo.tulia";
    } else if(_get(URLactual, "host", "uhoo.io")  == "cedej.aurapp.org"){ //config Cedej
      bundleName = "org.somosaura.cedej";
    } else if(_get(URLactual, "host", "uhoo")  == "rcenvivo.aurapp.org"){ //config RC
      bundleName = "org.somosaura.regnumchristi";
    } else if(_get(URLactual, "host", "uhoo")  == "aurapp.org"){ //config Aurapp
      bundleName = "org.somosaura.aurapp";
    }else { // default config Uhoo
        bundleName = "com.brilliant.brilliantapp";
    }
    let schema;
    if(isAura){
      schema = `&schema=aurapp&android=${bundleName}&ios=aurapp/id1543076126`;
    }else{
      schema = `&schema=uhoo&android=${bundleName}&ios=uhoo/id1202020080`;
    }
    let data = JSON.parse(sessionStorage.getItem("own"));
    let communityUuid = _get(data, "community.uuid", "");
    navigator.clipboard.writeText(`https://uhoo.io/redirect?path=api/${communityUuid}/${route}${schema}`);
    setShowSuccess(true);
    await timeout(500);
    setShowSuccess(false);
  }

  return (
    <React.Fragment>
      <section className="TitleManageActivity">
        <div className="titleArrow">
          <Icon.backArrow className="ico pointerCursor" onClick={() => history.goBack()} />
          <h3>{t('uhoo.activity.title')}</h3>
        </div>
        <div className="createPostActions">
          <div className="toPost" role="button" onClick={() => history.replace("/createPost")}>{t('uhoo.createPost.title')}</div>
        </div>
      </section>

      <div className="activityAction">
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div id="addColumn" className="itemActivityAction" onClick={addColumn}>
              <Icon.activityAdd />
              <p>{t('uhoo.activity.addColumn.title')}</p>
            </div>
            {
              columnProperties.length > 0
              ? (
                <div className="columnCounter">{columnProperties.length} {t('uhoo.activity.column.label')}
                {
                  columnProperties.length > 1
                  ? ("s") // identificador en plural
                  : (null)
                }            
                </div>
              )
              :("")            
            }
          </div>
          {/* <div className="containerCopyLink" style={{justifyContent: 'flex-start', width:'100%'}} onClick={() => copyLink('Feed')}>
            <Link className="iconLink"/>
            <p>{t("basicWords.copyLink")}</p>
            <h6>{t("basicWords.copyLinkApp")}</h6>
          </div> */}
        </div>
      </div>

      <ActivityContext.Consumer>
        {({ columnProperties }) => {

          //console.log("en consumer", columnProperties);
          if (columnProperties.length > 0) {
            return (
              <section className="activity">
                {columnProperties.map((item) => <Column key={item.id} item={item} />)}
              </section>
            );
          } else { return (<CleanColumns />); }
        }}
      </ActivityContext.Consumer>

      {!!showSuccess && (
        <SystemNotification
          setShowNotification={(e) => setShowSuccess}
          type="Success"
          caption={t("basicWords.copiedLink")}
        />
      )}
    </React.Fragment>
  );
};

export default () => {
  return (
    <ActivityProvider>
      <Activity />
    </ActivityProvider>
  )
};





