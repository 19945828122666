import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//Localization
import { useTranslation } from 'react-i18next';

import "./css/manageLibrary.css";
import { ReactComponent as BackArrow } from '../../assets/icons/navigation/backArrow.svg';

import ItemLibrary from './components/ItemLibrary.jsx';

import Pagination from '../global/components/create/pagination.jsx';

// Queries
import { getLibrary } from "../../actions/uhoo/Library.actions";

//thumbnail
var itemData = []

const ManageLibrary = () => {

  const { t, i18n } = useTranslation(); //  Localization  
  let history = useHistory(); //  from 'react-router-dom' -> la uso para avanzar o retroceder en el historial de navegacion

  const [pageShow, setPageShow] = useState(1); // curent páge

  const [library, setLibrary] = useState([]); // data
  const [pageEnd, setPageEnd] = useState(1); // total pages

  const [loaded, setLoaded] = useState(false);

  /// ---------- Queries ---------- ///

  useEffect(() => {
    const GetMediaData = async (query) => {
      const info = await getLibrary(query);
      setLibrary(info.data);
      setPageEnd(Math.ceil(info.page.total / info.page.page_size));
      setLoaded(true);
    }
    GetMediaData([{ key: 'page_size', value: '10' }, { key: 'page', value: pageShow - 1 }]);
  }, [pageShow]);

  return (
    <React.Fragment>
      <section className="Title">
        <BackArrow className="ico pointerCursor" onClick={() => history.goBack()} />
        <h3>{t('uhoo.library.title')}</h3>
      </section>

      <section className="libraryContent">
        {
          library.map(item => (
            <ItemLibrary key={item.uuid}

              deleteAttachments={"null"}
              setDeleteAttachments={"null"}

              id={item.uuid}
              url={item.thumbnail}
              name={item.mime_type}
              date={item.created_at}
            />
          ))
        }
        <Pagination pageShow={pageShow} setPageShow={setPageShow} pageEnd={pageEnd} />
      </section>
    </React.Fragment>
  );
};

export default ManageLibrary;