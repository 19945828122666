import React from "react";
import moment from "moment";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import {
  ButtonComponent,
  DateTimePickerComponent,
} from "../../../../aura-components";

//Localization
import { useTranslation } from 'react-i18next';

const RecurrenceStreamingModal = (props) => {
  var { visible, onOk, newStreaming } = props;
  const { t } = useTranslation();

  function disabledDateFromNow(current) {
    // Can not select days before today and today
    return current && current < moment().subtract(1, "days").endOf("day");
  }

  var occurrenceSelect = null;
  var disabledDate = true;
  var disabledNumberOccurrences = true;

  newStreaming.dateFinish = newStreaming.eventRule.finalDateOccurrences;
  if (
    newStreaming.dateFinish === undefined ||
    newStreaming.dateFinish === null
  ) {
    newStreaming.dateFinish = moment(newStreaming.dateInit).add(1, "years");
  }
  var relativeDay = props.ordinalDayOfMonth(newStreaming.dateInit);
  var dayNumber = relativeDay[0];
  var relativeDayValue = relativeDay[1];
  var relativeDay = relativeDay[2];

  var day = props.dayNameofWeek(newStreaming.dateInit);
  var dayNameOfWeek = day[0];
  var dayWeek = day[1];
  var auxEventRule = newStreaming.eventRule;
  var weekdays;

  if (auxEventRule !== undefined) {
    if (auxEventRule.frecuencyUnit && auxEventRule.frecuencyUnit != null) {
      var frecuencyUnit = auxEventRule.frecuencyUnit;
    }
    if (auxEventRule.frecuencyValue) {
      var frecuencyValue = auxEventRule.frecuencyValue;
    }
    if (auxEventRule.weekdays) {
      weekdays = auxEventRule.weekdays;
    }
    if (auxEventRule.relativeDayOfMonth) {
      var relativeDayOfMonth = auxEventRule.relativeDayOfMonth;
    }
    if (auxEventRule.specificDayOfMonth) {
      var specificDayOfMonth = auxEventRule.specificDayOfMonth;
    }
    if (auxEventRule.numberOccurrences) {
      var numberOccurrences = auxEventRule.numberOccurrences;
    }
    if (frecuencyUnit === "WEEKS") {
      weekdays = auxEventRule.weekdays;
    }
    if (
      newStreaming.valueRadioGroup === null ||
      newStreaming.valueRadioGroup === undefined
    ) {
      if (!auxEventRule.numberOccurrences) {
        newStreaming.valueRadioGroup = 1;
      }

      if (auxEventRule.finalDateOccurrences) {
        newStreaming.valueRadioGroup = 2;
        newStreaming.dateFinish = auxEventRule.finalDateOccurrences;
      }

      if (auxEventRule.numberOccurrences) {
        newStreaming.valueRadioGroup = 3;
        numberOccurrences = auxEventRule.numberOccurrences;
      }
    }
  }
  if (auxEventRule.relativeDayOfMonth) {
    occurrenceSelect = relativeDayValue + " " + dayWeek;
  }
  if (auxEventRule.specificDayOfMonth) {
    occurrenceSelect = dayNumber;
  }
  if (occurrenceSelect === null) {
    occurrenceSelect = relativeDayValue + " " + dayWeek;
  }

  return (
    <>
      <Dialog
        open={visible}
        onClose={(e) => props.handleClose(e)}
        style={{ width: "100%", height: "809px" }}
      >
        <DialogTitle> {t('generalUser.editStreaming')}</DialogTitle>
        <DialogContent style={{ maxWidth: "890px" }}>
          <div
            className="row justify-content-center"
            style={{
              display: "flex",
              alignitems: "center",
            }}
          >
            <div style={{ marginLeft: "2%" }}>
              <p>{t('basicWords.streaming.repeatBy') + ": "}</p>
            </div>
            <div style={{ marginLeft: "2%", marginRight: "1.5%" }}>
              <TextField
                style={{ width: 55, textAlign: "center" }}
                id="outlined-number"
                label={t('basicWords.streaming.repeatBy') + ": "}
                type="number"
                min={1}
                max={100}
                value={frecuencyValue}
                onChange={(e) => props.handleFrecuencyValue(e)}
              />
            </div>
            <div style={{ height: "35px" }}>
              <Select
                value={frecuencyUnit}
                style={{ width: 100, textAlign: "center" }}
                placeholder={t('basicWords.streaming.selectOcurrence')}
                optionFilterProp="children"
                onChange={(e) => props.handleFrecuencyUnit(e)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <MenuItem value="MINUTES">{t('basicWords.streaming.minutes')}</MenuItem>
                <MenuItem value="HOURS">{t('basicWords.streaming.hours')}</MenuItem>
                <MenuItem value="DAYS">{t('basicWords.streaming.days')}</MenuItem>
                <MenuItem value="WEEKS">{t('basicWords.streaming.weeks')}</MenuItem>
                <MenuItem value="MONTHS">{t('basicWords.streaming.months')}</MenuItem>
                <MenuItem value="YEARS">{t('basicWords.streaming.years')}</MenuItem>
              </Select>
            </div>
          </div>
          <div
            className="row justify-content-center componentSpace adjustPositionYTop"
            style={{
              display: "flex",
              alignitems: "center",
              marginLeft: "2%",
            }}
          >
            {frecuencyUnit === "WEEKS" ? (
              <div>
                <div
                  className="row justify-content-center componentSpace adjustPositionYTop"
                  style={{
                    display: "flex",
                    alignitems: "center",
                  }}
                >
                  <FormControl
                    component="fieldset"
                    onChange={(e) => props.handleWeekdays(e)}
                    defaultValue={weekdays}
                  >
                    <FormLabel component="legend">
                      {t('basicWords.streaming.theDays') + ": "}
                    </FormLabel>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="MON"
                        control={<Checkbox />}
                        label={t('basicWords.streaming.monday')}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="TUE"
                        control={<Checkbox />}
                        label={t('basicWords.streaming.tuesday')}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="WED"
                        control={<Checkbox />}
                        label={t('basicWords.streaming.wednesday')}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="THU"
                        control={<Checkbox />}
                        label={t('basicWords.streaming.thursday')}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="FRI"
                        control={<Checkbox />}
                        label={t('basicWords.streaming.friday')}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="SAT"
                        control={<Checkbox />}
                        label={t('basicWords.streaming.saturday')}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="SUN"
                        control={<Checkbox />}
                        label={t('basicWords.streaming.sunday')}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
            ) : null}
            {frecuencyUnit === "MONTHS" ? (
              <div className="col -12 text-center">
                <Select
                  style={{ width: "100%", textAlign: "center" }}
                  placeholder={t('basicWords.streaming.selectOcurrence')}
                  optionFilterProp="children"
                  value={occurrenceSelect}
                  onChange={(e) => props.handleMonthDay(e)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <MenuItem value={dayNumber}>
                    {t('basicWords.streaming.allMonths') +
                      ", " +
                      t('basicWords.streaming.theDay') +
                      " " +
                      dayNumber}
                  </MenuItem>
                  <MenuItem value={relativeDayValue + " " + dayWeek}>
                    {t('basicWords.streaming.allMonths') +
                      ", " +
                      relativeDay +
                      " " +
                      dayNameOfWeek}
                  </MenuItem>
                </Select>
              </div>
            ) : null}
          </div>
          {/* Finish event                */}
          <div
            className="row justify-content-center"
            style={{
              display: "flex",
              alignitems: "center",
              marginLeft: "2%",
            }}
          >
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="radiogroup"
                defaultValue={newStreaming.valueRadioGroup}
                onChange={(value) => props.handleRadioGroup(value)}
                name="radiogroup"
              >
                <FormControlLabel
                  value={"1"}
                  control={<Radio />}
                  label={t('basicWords.streaming.never')}
                />
                <div
                  style={{
                    display: "flex",
                    alignitems: "center",
                  }}
                >
                  <FormControlLabel
                    value={"2"}
                    control={<Radio />}
                    label={t('basicWords.streaming.the')}
                  />
                  {newStreaming.valueRadioGroup === "2" ? (
                    <DateTimePickerComponent
                      style={{ width: "40%", marginRight: 5 }}
                      disabledDate={disabledDateFromNow}
                      onChange={(e) => props.finishDate(e)}
                      onOk={onOk}
                      defaultValue={moment(newStreaming.dateFinish)}
                      disabled={!(newStreaming.valueRadioGroup === "2")}
                    />
                  ) : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignitems: "center",
                  }}
                >
                  <FormControlLabel
                    value={"3"}
                    control={<Radio />}
                    label={t('basicWords.streaming.after')}
                  />
                  {newStreaming.valueRadioGroup === "3" ? (
                    <div
                      style={{
                        display: "flex",
                        alignitems: "center",
                      }}
                    >
                      <div>
                        <TextField
                          style={{ width: 50, marginRight: 5 }}
                          id="outlined-number-2"
                          label={t('basicWords.streaming.timesOcurred')}
                          type="number"
                          min={1}
                          max={200}
                          value={numberOccurrences}
                          onChange={(e) =>
                            props.handleChangeNumberOccurrences(e)
                          }
                          disabled={!(newStreaming.valueRadioGroup === "3")}
                        />
                      </div>
                      <div>{t('basicWords.streaming.timesOcurred')}</div>
                    </div>
                  ) : null}
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>

        <DialogActions>
          <div>
            <ButtonComponent
              onClick={(e) => props.handleClose(e)}
              shape="round"
              className="buttonDiscard"
              text={t('basicWords.discard')}
            />
          </div>
          <div className="col-6">
            <ButtonComponent
              onClick={(e) => props.handleCheck(e)}
              shape="round"
              text={t('basicWords.save')}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecurrenceStreamingModal;
