import React, {useState} from "react";
import { DataGrid } from '@mui/x-data-grid';

import { GetUserRol } from "../../actions/uhoo/Role.actions";

var rows = [
  { uuid: '', name: null, description: null},
];

const UserRole = () => { 

  const [loaded, setLoaded] = useState(false);

  async function getData() {
    try {
      let info = await GetUserRol();    
  
      if( info.length !== 0) {
        rows = info.data;
        setLoaded(true);
      }

    } catch (err) {
      console.error(err);
    }
  }

  const info = getData();

  const columns = [
    { field: 'name', headerName: 'Nombre', width: 200 },
    { field: 'description', headerName: 'Descripcion', width: 200 },
  ];

  return(
    <div>
      <br />
      <p> Roles </p>
      <br />

      <div style={{ height: 600, width: '100%' }}>
        {loaded && <DataGrid
          getRowId={(rows) => rows.uuid}
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[15]}
          
        />}
      </div>
    </div>
  );
};


export default UserRole;


