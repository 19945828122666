export function resizeImage(uri, type, extension) {

   let imageFile;
   var byteString = atob(uri.split(',')[1]);
   var ab = new ArrayBuffer(byteString.length);
   var ia = new Uint8Array(ab);
   for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
   }
   var blob = new Blob([ia], { type: {type} });
   var file = new File([blob], `image.${extension}`);

   imageFile = file

   return imageFile
}