import React, {useState} from "react";

// Queries
//import { GetChannels } from "../../actions/uhoo/index";
//import { GetAllChannels } from "../../actions/uhoo/index";

// import { GetChannelMain } from "../../actions/uhoo/index";


import './css/home.css';



const TestPage = () => {
  
  const [loaded, setLoaded] = useState(false);
/*
  async function getData(query) {
    try {
      let info = await GetAllChannels(query);               
      setLoaded(true);
      console.log("front response", info);

    } catch (err) {
      console.error(err);
    }
  }

  let goData = "size";

  let info = getData();

*/

  return(

    <React.Fragment>
    <div>
      <br />
      <p> Test </p>
    </div>



  <div className="cardHome">
    <div className="contCard" />
    <p className="textFirst">Email</p>
    <p className="textSecond">Crea campañas</p>
    <img src="" />
  </div>

  </React.Fragment>

  );

};
export default TestPage;



