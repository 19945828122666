import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Card, CircularProgress } from "@material-ui/core";
import { Instagram, Facebook, Twitter, Youtube } from "react-feather";

import "../css/sections.scss";

// Queries
import {
  getInformationBySectionIdAndLPId,
  updateLpSectionComponentNode,
} from "../../../../actions/aura";

// Components
import {
  GeneralInput,
  BreadcrumbSection,
  TitleCard,
  ButtonsFooterComponent,
} from "../../../../aura-components";

import SystemNotification from "../../../global/SystemNotification";

//Localization
import { withTranslation } from "react-i18next";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;
const initialObject = {
  id: null,
  value: null,
  nodeTypeName: null,
  modified: false,
};
class ContactSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,

      landingPageId: null,
      organizationId: null,
      organizationName: null,
      sectionId: null,
      sectionName: null,
      country: null,
      region: null,
      city: null,

      loadingInfo: true,

      contactUsTitle: initialObject,
      contactUsText: initialObject,
      contactUsAddress: initialObject,
      contactUsEmail: initialObject,
      contactUsPhone: initialObject,
      contactUsTwitter: initialObject,
      contactUsFacebook: initialObject,
      contactUsInstagram: initialObject,
      contactUsYoutube: initialObject,
      FlagClassName: false,
      goToWithoutSaveModalVisible: false,

      // Modals
      showSuccess: false,
      showWarning: false,
      showError: false,
      contentNotificationModal: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { props } = this.props.location;

    if (props) {
      this.getContactInformation(props.landingPageId, props.sectionId);

      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  getElementsToContactUs = (components, nodeType, paramsToFind) => {
    let object = {};
    if (components.nodes.find((node) => node.nodeType === nodeType) !== undefined) {
      paramsToFind.forEach(element => {
        if (element === "length") {
          object[element] = components.nodes.find((node) => node.nodeType === nodeType).value.length;
        } else if (element === "nodeTypeName") {
          object[element] = components.nodes.find((node) => node.nodeType === nodeType).nodeType;
        } else {
          object[element] = components.nodes.find((node) => node.nodeType === nodeType)[element];
        }
      });
    } else {
      paramsToFind.forEach(element => {
        if (element === "nodeTypeName") {
          object[element] = nodeType;
        } else {
          object[element] = "";
        }
      });
    }
    return object;
  };

  // Queries
  async getContactInformation(lpId, lpSectionId) {
    const response = await getInformationBySectionIdAndLPId(
      lpId,
      lpSectionId,
      "CONTACT"
    );
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({ showError: true, contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport") });
    } else {
      // Get all the information about the section components
      const sectionInfo = response.data.data;
      const contactComponent = sectionInfo.components.find(
        (comp) => comp.name === "ContactContainerComponent"
      );
      const objectKeys = ["id", "value", "nodeTypeName", "minLength", "maxLength", "length"];
      const contactUsTitle = this.getElementsToContactUs(contactComponent, "ContactUsTitle", objectKeys);
      const contactUsText = this.getElementsToContactUs(contactComponent, "ContactUsText", objectKeys);
      const contactUsAddress = this.getElementsToContactUs(contactComponent, "ContactUsAddress", objectKeys);
      const contactUsEmail = this.getElementsToContactUs(contactComponent, "ContactUsEmail", objectKeys);
      const contactUsPhone = this.getElementsToContactUs(contactComponent, "ContactUsPhone", objectKeys);
      const contactUsWhastapp = this.getElementsToContactUs(contactComponent, "ContactUsWhastapp", objectKeys);

      const objectKeysSocialMedia = ["id", "value", "nodeTypeName"];
      const contactUsTwitter = this.getElementsToContactUs(contactComponent, "ContactUsTwitter", objectKeysSocialMedia);
      const contactUsFacebook = this.getElementsToContactUs(contactComponent, "ContactUsFacebook", objectKeysSocialMedia);
      const contactUsInstagram = this.getElementsToContactUs(contactComponent, "ContactUsInstagram", objectKeysSocialMedia);
      const contactUsYoutube = this.getElementsToContactUs(contactComponent, "ContactUsYoutube", objectKeysSocialMedia);


      this.setState({
        contactUsTitle,
        contactUsText,
        contactUsAddress,
        contactUsEmail,
        contactUsPhone,
        contactUsTwitter,
        contactUsFacebook,
        contactUsInstagram,
        contactUsYoutube,
        contactUsWhastapp,
        loadingInfo: false,
      });
    }
  }

  async updateNode(nodesToUpdate) {
    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({ showError: true, contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport") });
    } else {
      this.setState({ showSuccess: true, contentNotificationModal: t("basicWords.modal.contactSuccessfullyEdited") });
    }
    this.setState({
      isLoadingButton: false,
    });
  }

  // Events
  handleChange = (event) => {
    const { id, value } = event.target;
    const { contactUsTitle, contactUsText, contactUsAddress, contactUsEmail, contactUsPhone, contactUsWhastapp, contactUsTwitter, contactUsFacebook, contactUsInstagram, contactUsYoutube} = { ...this.state };

    if (id === "ContactUsTitle") {
      if (value.length <= contactUsTitle.maxLength) {
        contactUsTitle.value = value;
        contactUsTitle.modified = true;
        contactUsTitle.length = value.length;
      }
    }
    if (id === "ContactUsText") {
      if (value.length <= contactUsText.maxLength) {
        contactUsText.value = value;
        contactUsText.modified = true;
        contactUsText.length = value.length;
      }
    }
    if (id === "ContactUsAddress") {
      if (value.length <= contactUsAddress.maxLength) {
        contactUsAddress.value = value;
        contactUsAddress.modified = true;
        contactUsAddress.length = value.length;
      }
    }
    if (id === "ContactUsEmail") {
      if (value.length <= contactUsEmail.maxLength) {
        contactUsEmail.value = value;
        contactUsEmail.modified = true;
        contactUsEmail.length = value.length;
      }
    }
    if (id === "ContactUsPhone") {
      if (value.length <= contactUsPhone.maxLength) {
        contactUsPhone.value = value;
        contactUsPhone.modified = true;
        contactUsPhone.length = value.length;
      }
    }
    if (id === "ContactUsWhastapp") {
      if (value.length <= contactUsWhastapp.maxLength) {
        contactUsWhastapp.value = value;
        contactUsWhastapp.modified = true;
        contactUsWhastapp.length = value.length;
      }
    }
    if (id === "ContactUsTwitter") {
      contactUsTwitter.value = value;
      contactUsTwitter.modified = true;
    }
    if (id === "ContactUsFacebook") {
      contactUsFacebook.value = value;
      contactUsFacebook.modified = true;
    }
    if (id === "ContactUsInstagram") {
      contactUsInstagram.value = value;
      contactUsInstagram.modified = true;
    }
    if (id === "ContactUsYoutube") {
      contactUsYoutube.value = value;
      contactUsYoutube.modified = true;
    }

    this.setState({
      contactUsTitle,
      contactUsText,
      contactUsAddress,
      contactUsEmail,
      contactUsPhone,
      contactUsWhastapp,
      contactUsTwitter,
      contactUsFacebook,
      contactUsInstagram,
      contactUsYoutube,
      FlagClassName: true,
    });
  };

  saveContactEvent = () => {
    this.setState({
      isLoadingButton: true,
      FlagClassName: false,
    });
    const nodesToUpdate = [];
    const { contactUsTitle, contactUsText, contactUsAddress, contactUsEmail, contactUsPhone, contactUsWhastapp, contactUsTwitter, contactUsFacebook, contactUsInstagram, contactUsYoutube} = { ...this.state };
    let errorInput = false;

    const { t } = this.props;

    // Verify obligatory values of the component to add
    if (contactUsTitle.modified) {
      if (contactUsTitle.value === "") {
        errorInput = true;
      }
      nodesToUpdate.push(contactUsTitle);
    }
    if (contactUsText.modified) {
      // if (contactUsText.value === "") {
      //   errorInput = true;
      // }
      nodesToUpdate.push(contactUsText);
    }
    if (contactUsAddress.modified) {
      nodesToUpdate.push(contactUsAddress);
    }
    if (contactUsEmail.modified) {
      nodesToUpdate.push(contactUsEmail);
    }
    if (contactUsPhone.modified) {
      nodesToUpdate.push(contactUsPhone);
    }
    if (contactUsWhastapp.modified) {
      nodesToUpdate.push(contactUsWhastapp);
    }
    if (contactUsTwitter.modified) {
      nodesToUpdate.push(contactUsTwitter);
    }
    if (contactUsFacebook.modified) {
      nodesToUpdate.push(contactUsFacebook);
    }
    if (contactUsInstagram.modified) {
      nodesToUpdate.push(contactUsInstagram);
    }
    if (contactUsYoutube.modified) {
      nodesToUpdate.push(contactUsYoutube);
    }
    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({ showError: true, isLoadingButton: false, contentNotificationModal: t("basicWords.modal.fieldEmpty") });
      }
    }
  };

  warning = () => {
    const { t } = this.props;
    this.setState({ showWarning: true, contentNotificationModal: t("basicWords.modal.noSectionSelected") });
    this.handleOkWarning();
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  setShowError = (open) => {
    this.setState({ showError: open });
  };

  setShowSuccess = (open) => {
    this.setState({ showSuccess: open });
  };

  setShowWarning = (open) => {
    this.setState({ showWarning: open });
  };

  // Render Info
  render() {
    const {
      redirectBack,
      sectionName,
      contactUsTitle,
      contactUsText,
      contactUsTwitter,
      contactUsFacebook,
      contactUsInstagram,
      contactUsYoutube,
      contactUsAddress,
      contactUsEmail,
      contactUsPhone,
      contactUsWhastapp,
      organizationId,
      landingPageId,
      FlagClassName,
      lpUrl,
      isLoadingButton,
      goToWithoutSaveModalVisible,

      // Modals
      showSuccess,
      showWarning,
      showError,
      contentNotificationModal
    } = this.state;

    const { t } = this.props;
    if (redirectBack) {
      return <Redirect to="/manage/web" />;
    }

    return (
      <>
        {!!showWarning && (
          <SystemNotification
            setShowNotification={(e) => this.setShowWarning(e)}
            type="Warning"
            caption={contentNotificationModal}
          />
        )}
        {!!showSuccess && (
          <SystemNotification
            setShowNotification={(e) => this.setShowSuccess(e)}
            type="Success"
            caption={contentNotificationModal}
          />
        )}
        {!!showError && (
          <SystemNotification
            setShowNotification={(e) => this.setShowError(e)}
            type="Error"
            caption={contentNotificationModal}
          />
        )}
        <section className="sectionContainer">
          <Card className="card">
            {isLoadingButton === true ? (
              <div
                className="col-xs-12 col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  transform: "translateY(800%)",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="row spaceBreadcrumb">
                  <BreadcrumbSection
                    principal={t("sections.contactUs")}
                    secondary={this.state.organizationName}
                    route="./manage/web"
                    propsComponent={{
                      organizationId,
                      sectionName: sectionName,
                      lpId: landingPageId,
                      lpUrl: lpUrl,
                    }}
                    FlagclassName={FlagClassName}
                    onClick={(e) => this.saveButtonEvent(e)}
                    GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                    onCancelGotoWithoutMModal={(e) =>
                      this.onCancelGotoWithoutMModal(e)
                    }
                    goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                  />
                </div>

                <Card
                  className="card"
                  style={{ margin: "2% 5.5% 1% 5.5% ", height: "auto" }}
                >
                  <div className="row mainContent">
                    <div
                      className="rowSection adjustPositionYTop"
                      style={{
                        margin: "1%",
                      }}
                    >
                      <TitleCard text={t("generalUser.contentSection")} />
                    </div>
                    <div className="accordionCenterLine">
                      <div
                        style={{
                          width: "50%",
                          marginLeft: "1%",
                          marginRight: "0.7%",
                        }}
                      >
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("generalMessages.sectionTitle")}
                          id={contactUsTitle ? contactUsTitle.nodeTypeName : null}
                          onChange={(e, id) => this.handleChange(e, id)}
                          value={contactUsTitle ? contactUsTitle.value : null}
                        />
                        {contactUsTitle.value && contactUsTitle.maxLength ? (
                          <span>
                            {contactUsTitle.length} / {contactUsTitle.maxLength}
                          </span>
                        ) : null}
                      </div>
                      <div
                        style={{
                          width: "50%",
                          marginLeft: "1%",
                          marginRight: "0.7%",
                        }}
                      >
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("basicWords.description")}
                          id={contactUsText ? contactUsText.nodeTypeName : null}
                          onChange={(e, id) => this.handleChange(e, id)}
                          value={contactUsText ? contactUsText.value : null}
                        />
                        {contactUsText.value && contactUsText.maxLength ? (
                          <span>
                            {contactUsText.length} / {contactUsText.maxLength}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <br />
                </Card>

                <Card
                  className="card"
                  style={{ margin: "2% 5.5% 1% 5.5% ", height: "auto" }}
                >
                  <div className="row mainContent">
                    <div
                      className="rowSection"
                      style={{
                        margin: "1%",
                      }}
                    >
                      <TitleCard text={t("generalUser.contactData")} />
                    </div>
                    <div className="accordionCenterLine">
                      <div
                        style={{
                          width: "32%",
                          marginLeft: "1.2%",
                          marginRight: "0.7%",
                        }}
                      >
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("basicWords.address")}
                          id={
                            contactUsAddress
                              ? contactUsAddress.nodeTypeName
                              : null
                          }
                          onChange={(e, id) => this.handleChange(e, id)}
                          value={contactUsAddress ? contactUsAddress.value : null}
                        />
                        {contactUsAddress.value && contactUsAddress.maxLength ? (
                          <span>
                            {contactUsAddress.length} /{" "}
                            {contactUsAddress.maxLength}
                          </span>
                        ) : null}
                      </div>
                      <div
                        style={{
                          width: "32%",
                          marginLeft: "1%",
                          marginRight: "0.7%",
                        }}
                      >
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("generalUser.userEmail")}
                          id={contactUsEmail ? contactUsEmail.nodeTypeName : null}
                          onChange={(e, id) => this.handleChange(e, id)}
                          value={contactUsEmail ? contactUsEmail.value : null}
                        />
                        {contactUsEmail.value && contactUsEmail.maxLength ? (
                          <span>
                            {contactUsEmail.length} / {contactUsEmail.maxLength}
                          </span>
                        ) : null}
                      </div>

                      <div
                        style={{
                          width: "32%",
                          marginLeft: "0.7%",
                          marginRight: "1%",
                        }}
                      >
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("basicWords.telephone")}
                          id={contactUsPhone ? contactUsPhone.nodeTypeName : null}
                          onChange={(e, id) => this.handleChange(e, id)}
                          value={contactUsPhone ? contactUsPhone.value : null}
                        />
                        {contactUsPhone.value && contactUsPhone.maxLength ? (
                          <span>
                            {contactUsPhone.length} / {contactUsPhone.maxLength}
                          </span>
                        ) : null}
                      </div>

                      <div
                        style={{
                          width: "32%",
                          marginLeft: "0.7%",
                          marginRight: "1%",
                        }}
                      >
                        <GeneralInput
                          className="textInputWidth"
                          placeholder={t("basicWords.modal.whatsapp")}
                          id={contactUsWhastapp ? contactUsWhastapp.nodeTypeName : null}
                          onChange={(e, id) => this.handleChange(e, id)}
                          value={contactUsWhastapp ? contactUsWhastapp.value : null}
                        />
                        {contactUsWhastapp && contactUsWhastapp.value && contactUsWhastapp.maxLength ? (
                          <span>
                            {contactUsWhastapp.length} / {contactUsWhastapp.maxLength}
                          </span>
                        ) : null}
                      </div>

                    </div>
                  </div>
                  <br />
                </Card>

                {/* Social Networks */}
                <Card
                  className="card"
                  style={{ margin: "3% 5.5% 2% 5.5%", height: "auto" }}
                >
                  <div className="row mainContent">
                    <div
                      className="rowSection"
                      style={{
                        margin: "1%",
                      }}
                    >
                      <TitleCard text={t("sections.socialnetworks")} />
                    </div>
                    <div
                      className="adjustPositionYTop"
                      style={{
                        margin: "2%",
                      }}
                    >
                      <div className="containerSocialNetWorkInContact">
                        <div className="containerIconContact">
                          <Twitter className="iconContact" />
                        </div>
                        <div className="urlContact">
                          <GeneralInput
                            className="textInputWidth"
                            placeholder={t("basicWords.urlProfile")}
                            id={
                              contactUsTwitter
                                ? contactUsTwitter.nodeTypeName
                                : null
                            }
                            onChange={(e, id) => this.handleChange(e, id)}
                            value={
                              contactUsTwitter ? contactUsTwitter.value : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="adjustPositionYTop"
                      style={{
                        margin: "2%",
                      }}
                    >
                      <div className="containerSocialNetWorkInContact">
                        <div className="containerIconContact">
                          <Facebook className="iconContact" />
                        </div>
                        <div className="urlContact">
                          <GeneralInput
                            className="textInputWidth"
                            placeholder={t("basicWords.urlProfile")}
                            id={
                              contactUsFacebook
                                ? contactUsFacebook.nodeTypeName
                                : null
                            }
                            onChange={(e, id) => this.handleChange(e, id)}
                            value={
                              contactUsFacebook ? contactUsFacebook.value : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="adjustPositionYTop"
                      style={{
                        margin: "2%",
                      }}
                    >
                      <div className="containerSocialNetWorkInContact">
                        <div className="containerIconContact">
                          <Instagram className="iconContact" />
                        </div>
                        <div className="urlContact">
                          <GeneralInput
                            className="textInputWidth"
                            placeholder={t("basicWords.urlProfile")}
                            id={
                              contactUsInstagram
                                ? contactUsInstagram.nodeTypeName
                                : null
                            }
                            onChange={(e, id) => this.handleChange(e, id)}
                            value={
                              contactUsInstagram ? contactUsInstagram.value : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="adjustPositionYTop"
                      style={{
                        margin: "2%",
                      }}
                    >
                      <div className="containerSocialNetWorkInContact">
                        <div className="containerIconContact">
                          <Youtube className="iconContact" />
                        </div>
                        <div className="urlContact">
                          <GeneralInput
                            className="textInputWidth"
                            placeholder={t("basicWords.urlProfile")}
                            id={
                              contactUsYoutube
                                ? contactUsYoutube.nodeTypeName
                                : null
                            }
                            onChange={(e, id) => this.handleChange(e, id)}
                            value={
                              contactUsYoutube ? contactUsYoutube.value : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </>
            )}
          </Card>
        </section>
        {/* Buttons */}
        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName,
            landingPageId,
            lpUrl,
          }}
          FlagclassName={FlagClassName}
          onClick={(e) => this.saveContactEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </>
    );
  }
}
export default withTranslation()(ContactSection);
