// Dependencies
import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router";

// Components
import CMS from "../../CMS.jsx";
import ForgotPass from "../../pages/login/ForgotPass.jsx";

/// --- // --- Hub 3.0 --- // --- ///
import Home from "../../pages/global/HomeComponent.jsx";

import { getConfigs } from "../../actions/uhoo/Config.actions"; // Singularity
import { get as _get } from "lodash";

// CardsHome
import CreatePost from "../../pages/post/createPost.jsx";
import Email from "../../pages/mail/Email.jsx";
import Newsletter from "../../pages/newsletter/newsletter.jsx";
import CreateWidget from "../../pages/widget/createWidget.jsx";
import Notification from "../../pages/notification/notification.jsx";
import Donations from "../../pages/donations/donations.jsx";

// --- Functions --- //

//  manage community
import Community from "../../pages/community/community.jsx";
import ImprotUsers from "../../pages/community/importUsers.jsx"
import SpecificAudience from "../../pages/community/specificAudience.jsx"
//manage content
import ManageContent from "../../pages/manage/manageContent.jsx";

import WidgetList from "../../pages/widget/widgetList.jsx";
import ManageLibrary from "../../pages/library/manageLibrary.jsx";
import ManageMail from "../../pages/mail/manageMail.jsx";
import ManageWeb from "../../pages/web/manageWeb.jsx";
import MyGroupSubpage from "../../pages/web/MyGroupSubpage.jsx";

import ManageActivity from "../../pages/activity/manageActivity.jsx";

// data and metrics
import Data from "../../pages/data/data.jsx";

// --- WebPage Aura --- //

import AboutUsSection from "../../pages/web/sections/about/aboutUsSection.jsx";
import ContactSection from "../../pages/web/sections/contact/contactSection.jsx";
import CoursesSection from "../../pages/web/sections/courses/coursesSection.jsx";
import DonationsSection from "../../pages/web/sections/donations/donationsSection.jsx";
import EventsSection from "../../pages/web/sections/events/eventsSection.jsx";
import FooterSection from "../../pages/web/sections/footer/footerSection.jsx";
import GallerySection from "../../pages/web/sections/gallery/gallerySection.jsx";
import HeaderSection from "../../pages/web/sections/header/headerSection.jsx";
import HomeSection from "../../pages/web/sections/home/homeSection.jsx";
import LinkTreeSection from "../../pages/web/sections/linktree/linktreeSection.jsx";
import NewsSection from "../../pages/web/sections/news/newsSection.jsx";
import SocialNetworksSection from "../../pages/web/sections/socialNetworks/socialNetworksSection.jsx";
import StreamingSection from "../../pages/web/sections/streaming/streamingSections.jsx";
import TeamSection from "../../pages/web/sections/teams/teamSection.jsx";
// --- end WebPage Aura --- //

// Heredados ó Pruebas
import Post from "../../pages/post/Post.jsx";
import UserLibrary from "../../pages/library/UserLibrary.jsx";
import UserUsers from "../../pages/user/UserUsers.jsx";
import UserRole from "../../pages/role/UserRole.jsx";
import UserAudience from "../../pages/audience/UserAudience.jsx";
import UserMediums from "../../pages/mediums/UserMediums.jsx";

import TestPage from "../../pages/test/Test";

/// ----- Singularity ----- ///

let widgets = false;
let massiveEmail = false;
let newsletter = false;
let report = false;

async function Singularity() {
  let config = [];
  try {
    const _configs = await getConfigs();
    config = _configs.data.data;
  } catch (error) {
    console.log("error", error);
  }

  if(config.length){
    widgets = _get(config.find((element) => {
      return element.name === "app.community.webTab.edition.enabled";
    }), "value", false);
  
    massiveEmail = _get(config.find((element) => {
      return element.name === "massiveEmail.enabled";
    }), "value", false);
  
    newsletter = _get(config.find((element) => {
      return element.name === "newsletter.enabled";
    }), "value", false);
  
    report = _get(config.find((element) => {
      return element.name === "report.enabled";
    }), "value", false);
  }
  
  
}

Singularity();

/// --- end Singularity --- ///

export const AppRoutes = () => (
  <CMS>
    <Switch>
      <Route exact path='/forgotpass' component={ForgotPass} />
      <Route exact path='/' component={Home} />
      <Route exact path='/createPost' component={CreatePost} />
      <Route exact path='/createWidget' component={CreateWidget} />
      <Route exact path='/notification' component={Notification} />
      <Route exact path='/donations' component={Donations} />

      <Route exact path='/community' component={Community} />
      <Route exact path='/importUsers' component={ImprotUsers} />
      <Route exact path='/specificAudience' component={SpecificAudience} />
      <Route exact path='/manage' component={ManageContent} />
      <Route exact path='/manage/widget' component={WidgetList} />
      <Route exact path='/manage/mail' component={ManageMail} />
      <Route exact path='/manage/web' component={ManageWeb} />
      <Route exact path='/manage/activity' component={ManageActivity} />
      {/* <Route exact path="/manage/newsletter" component={} /> */}
      <Route exact path='/manage/library' component={ManageLibrary} />
      
      {/*/ ----- Singularity ----- /*/}
      <Route exact path='/data' component={Data} />
      <Route exact path='/newsletter' component={Newsletter} />
      <Route exact path='/manage/mail' component={ManageMail} />,
      <Route exact path='/createMail' component={Email} />
      {/*/ --- end Singularity --- /*/}
      
      
      {/* --- WebPage Aura ---  */}

     <Route exact path="/editMyGroupSubpage" component={MyGroupSubpage} />
      
      <Route exact path="/editHome" component={HomeSection} />
      <Route exact path="/editHeader" component={HeaderSection} />
      <Route exact path="/editAbout" component={AboutUsSection} />
      <Route exact path="/editEvents" component={EventsSection} />
      <Route exact path="/editNews" component={NewsSection} />
      <Route exact path="/editCourses" component={CoursesSection} />
      <Route exact path="/editTeam" component={TeamSection} />
      <Route exact path="/editStreaming" component={StreamingSection} />
      <Route exact path="/editGallery" component={GallerySection} />
      <Route exact path="/editContact" component={ContactSection} />
      <Route exact path="/editFooter" component={FooterSection} />
      <Route exact path="/editSocialNetworks" component={SocialNetworksSection} />
      <Route exact path="/editDonations" component={DonationsSection} />
      <Route exact path="/editLinkTree" component={LinkTreeSection} />
    

       {/* --- end WebPage Aura --- */}
      {/* --- 2.0 --- */}
      <Route exact path='/manage/library2' component={UserLibrary} />
      <Route exact path='/post' component={Post} />
      <Route exact path='/user' component={UserUsers} />
      <Route exact path='/role' component={UserRole} />
      <Route exact path='/audience' component={UserAudience} />
      <Route exact path='/mediums' component={UserMediums} />
      <Route exact path='/test' component={TestPage} />
    </Switch>
  </CMS>
);
