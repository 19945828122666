import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../css/react-tabs.scss';

import "./css/createPost.css";
import Icon from '../../uhoo-components/iconLibrary.jsx';

import Postform from './createPostForm.jsx';
import PostPreview from '../../uhoo-components/preview/postPreview.jsx';

//Localization
import { useTranslation } from 'react-i18next';

const CreatePost = () => {

  const { t, i18n } = useTranslation(); //  Localization  
  let history = useHistory(); //  from 'react-router-dom' -> la uso para avanzar o retroceder en el historial de navegacion 

  const [previewTitle, setPreviewTitle] = useState('');
  const [previewInfo, setPreviewInfo] = useState('');
  const [previewImage, setPreviewImage] = useState('');

  // console.log("previewInfo", previewInfo);

  return (
    <React.Fragment>
      <section className="Title">
        <Icon.backArrow className="ico pointerCursor" onClick={() => history.goBack()} />
        <h3>{t('uhoo.createPost.title')}</h3>

      </section>

      <section className="publication">
        <Tabs>
          <TabList>
            <Tab>{t('uhoo.createPost.tabGeneral')}</Tab>
            <Tab>{t('uhoo.createPost.tabEvent')}</Tab>
          </TabList>

          <TabPanel>
            <div className="publicationContent">
              <Postform event={false}
                setPreviewTitle={setPreviewTitle}
                setPreviewInfo={setPreviewInfo}
                previewInfo={previewInfo}
                setPreviewImage={setPreviewImage}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="publicationContent">
              <Postform event={true}
                setPreviewTitle={setPreviewTitle}
                previewInfo={previewInfo}
                setPreviewInfo={setPreviewInfo}
                setPreviewImage={setPreviewImage}
              />
            </div>
          </TabPanel>
        </Tabs>
      </section>

      <section className="preview">
        <div className="previewContent">

          <PostPreview
            previewTitle={previewTitle}
            previewInfo={previewInfo}
            previewImage={previewImage}
          />

        </div>
      </section>

    </React.Fragment>
  );
};

export default CreatePost;





