import { useState, useEffect } from "react";

import axios from "axios";

import {
  protocol,
  host,
  port,
  route,
  dataSource,
} from "../../reactUtils/systemVariables/UhooServerInformation";

const resource = "/media/";

export async function getLibrary(Params) {
  let sendParams = "";

  if (Params !== undefined || null) {
    if (typeof Params !== "string") {
      //console.log(Params);
      //sendParams += "?"
      Params.forEach((item) =>
        item.key === "page"
          ? (sendParams += "page/" + item.value)
          : (sendParams += "")
      ); // Get Post Page --> Si Value no es definido por defecto va a ser 10
      Params = Params.filter((item) => item.key !== "page");
      //console.log(Params);

      Params.forEach((item) =>
        item.key === "size"
          ? (sendParams += "size/" + item.value)
          : (sendParams += "")
      ); // Get Size Post Filter
      Params = Params.filter((item) => item.key !== "size");
      //console.log(Params);

      Params.forEach(
        (item) => (sendParams += "?" + item.key + "=" + item.value + "&")
      ); // Envia otros parametros adicionales Ej: Get Post Page Filter
    } else {
      sendParams = Params;
    } // Asigna el String como parametro de la consulta Ej: main o size
  } else {
    sendParams = "";
  }

    //const objSessionInformation = JSON.parse(sessionStorage.userInfo);
    //const userToken = "Bearer " + objSessionInformation.token;
    const userToken = "Bearer " + sessionStorage.access_token;

  let GetMedia;

  try {
    GetMedia = await axios({
      method: "GET",
      url: protocol + host + port + route + dataSource + resource + sendParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    });

    if (!GetMedia.error) {
      return GetMedia.data;
    }
  } catch (error) {
    console.log("Error: API Brilliant");
    return error;
  }
}






/// ---------------------------------------------------------------------------------------------- for test


const resource2 = "/media/page/1?page_size=";

export async function getLibrary_v1() {
  const objSessionInformation = JSON.parse(sessionStorage.userInfo);

    //const objSessionInformation = JSON.parse(sessionStorage.userInfo);
    //const userToken = "Bearer " + objSessionInformation.token;
    const userToken = "Bearer " + sessionStorage.access_token;

  let finishGetMedia;
  let page_size = 1;

  try {
    let startGetMedia = await axios({
      method: "GET",
      url: protocol + host + port + route + dataSource + resource2 + page_size,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    });

    if (!startGetMedia.error) {
      //console.log("Consulta inicial: ", startGetMedia);
      //console.log("Tamaño consulta: ", startGetMedia.data.page.total);

      //page_size = startGetMedia.data.page.total;
      page_size = 50;

      getLibraryTamaño(page_size);

      try {
        finishGetMedia = await axios({
          method: "GET",
          url:
            protocol + host + port + route + dataSource + resource2 + page_size,
          headers: {
            "Content-Type": "application/json",
            Authorization: userToken,
          },
        });
        if (!finishGetMedia.error) {
          //console.log("Consulta final: ", startGetMedia);
        }
      } catch (error) {
        console.log("Error: API Brilliant");
        return error;
      }
    }

    return finishGetMedia.data;
  } catch (error) {
    return error;
  }
}

/// ----------------------------------------------------------------------------------------------

export async function getLibraryTamaño(dato) {
  //console.log("funcion2 ", dato);
}

/// ----------------------------------------------------------------------------------------------

