import axios from "axios";

import jwt_decode from "jwt-decode";
import { protocol, host, port, route } from "../../reactUtils/systemVariables/UhooServerInformation";
import { get as _get } from "lodash";

const query = "/security/getCustomToken";

export async function getCustomToken(user, bundleName, idToken,comm ) {
    
    let sendParams;
    if(idToken  && idToken != null && comm && comm != null){
        sendParams = "?idToken=" + idToken + "&community=" + comm.uuid;
    }else{
        sendParams = "?idToken=" + user.accessToken;
    }
    try {
        let GetToken = await axios({
        method: "GET",
        url: protocol + host + port + route + query + sendParams,
        headers: {
            "Content-Type": "application/json",
            'bundlename': bundleName,
        },
        });

        if (!GetToken.error) {
            let decodedToken = jwt_decode(GetToken.data);
            return {
                "decodedToken": decodedToken,
                "token": GetToken.data
            };
        }
    } catch (error) {
        return error;
    }
}

