import React, { useState } from "react";

import { ReactComponent as View } from '../../../assets/icons/navigation/view.svg';

import "./css/newsletterAction.css";


//Localization
import { useTranslation } from 'react-i18next';

const NewsletterAction = () => {

  const { t, i18n } = useTranslation();

  return (
    <div className="containerNewsletterAction">
      <div className="contentNewsletterAction">
        <View className="pointerCursor" />
        <div className="containerNewsletterActionText">
          {t('uhoo.newsletter.action.view')}
        </div>
      </div>
      <div className="contentNewsletterAction">
        <input type="checkbox" />
        <div className="containerNewsletterActionText">
          {t('uhoo.newsletter.action.edit')}
        </div>
      </div>
    </div>
  );
};

export default NewsletterAction;

