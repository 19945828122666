import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import { useTranslation } from 'react-i18next';
import { get as _get } from "lodash";
import "../../community/components/css/deleteUser.css";
import { updateUser } from "../../../actions/uhoo";
import { UsersCommunityContext } from "./../../community/contex/index";

import SystemNotification from "../SystemNotification.jsx";

function DeleteMasiveUser(props) {
    const { update, setUpdate } = useContext(UsersCommunityContext);
    const { t, i18n } = useTranslation(); //  Localization
    const [userList, setUserList] = useState([]);

    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    let open = props.setOpenModal;

    //usersSelected

    useEffect(() => {

        const loadInfo = async () => {
            let _user = props.usersSelected;
            setUserList(_user);
        }
        loadInfo();

    }, []);

    //console.log("user list efect", userList)

    function closeModal() { open(false); }

    const updateUserFunc = async () => {
        userList.forEach(async (uuidUser) => {
            let tosend = {};
            //tosend["uuid"] = _get(user, "uuid", null);
            tosend["uuid"] = uuidUser;
            var formData = new FormData();
            //formData.append("uuid", _get(user, "uuid", null));
            formData.append("uuid", uuidUser);
            formData.append("active", 0); // to soft delete user
            tosend["data"] = formData;
            try {
                let response = await updateUser(tosend, true); //save info user
                response = _get(response, "data[0]", {});
                setUpdate(!update);
            } catch (error) {
                console.log("error", error);
                setShowError(true);
            }
        });

        //closeModal();
        props.setUserSelect([]);
        setShowSuccess(true);
    }

    return (
        <React.Fragment>
            <div className="modalDeleteUser">
                <div className="titleModalDeleteUser">
                    <div className="textTitleModalDeleteUser">{t('uhoo.globalWords.confirmation')}</div>
                    <div className="closeModalDeleteUser" onClick={() => closeModal()} role="button"><Close /></div>
                </div>

                <hr className="lines" />

                <div className="contentModalDeleteUser">
                    {t('uhoo.community.deleteModal.caption2') + props.usersSelected.length + t('uhoo.community.deleteModal.caption3')}
                </div>
                <div className="actionContentModalDeleteUser">
                    <div role="button" onClick={() => closeModal()}>
                        <SecondaryButton txt={t('uhoo.globalWords.cancel')} dark />
                    </div>
                    <div role="button" onClick={() => updateUserFunc()}>
                        <SecondaryButton txt={t('uhoo.globalWords.delete')} danger />
                    </div>
                </div>
            </div>

            {!!showError && (
                <SystemNotification
                    setShowNotification={setShowError}
                    type="Error"
                    caption={t('basicWords.internalServerError')}
                />
            )}

            {!!showSuccess && (
                <SystemNotification
                    setShowNotification={setShowSuccess}
                    type="Success"
                    caption={t('basicWords.successAction')}
                    setShowModal={open}
                />
            )}

        </React.Fragment>
    );
}

export default DeleteMasiveUser;
