import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import "./css/Breadcrumb.scss";

export function Breadcrumb(props) {
  let className = props.className;

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {props.principal && props.icon ? (
        <Typography className="principalBreadcrumb">
          <div className="inlineIconWithTextBreadcrumb">
            <div className="iconBreadcrumb">{props.icon}</div>
            <div>{props.principal}</div>
          </div>
        </Typography>
      ) : null}
      {props.principal && !props.icon ? (
        <Typography
          className={className ? className : "principalBreadcrumbWithOutIcon"}
        >
          {props.principal}
        </Typography>
      ) : null}
      {props.secondary ? (
        <Typography className="otherLayoutBreadcrumbs">
          {props.secondary}
        </Typography>
      ) : null}

      {props.third ? (
        <Typography className="otherLayoutBreadcrumbs">
          {props.third}
        </Typography>
      ) : null}

      {props.fourth ? (
        <Typography className="otherLayoutBreadcrumbs">
          {props.fourth}
        </Typography>
      ) : null}
    </Breadcrumbs>
  );
}
