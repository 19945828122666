import React from "react";

// Components
import {
  ButtonComponent,
  SelectSingle,
  GeneralModal,
} from "../../aura-components";

//Localization
import { useTranslation } from "react-i18next";

const AddNewSectionModal = (props) => {
  const { visible, isLoading, sections, sectionToAdd } = props;
  const { t, i18n } = useTranslation();
  return (
    <GeneralModal
      visible={visible}
      title={t("generalMessages.addSectionToAPage")}
      body={[
        <div className="col-12" style={{ margin: "5px" }}>
          <div className="col-md-12 componentSpace">
            <SelectSingle
              selectedOptions={sectionToAdd}
              text={t("generalMessages.addSectionPosibilities")}
              name="sections"
              onChange={(e) => props.handleChange(e, "sections")}
              options={sections}
            />
          </div>
        </div>,
      ]}
      footer={[
        <div className="row buttonAtTheBottom" key={1}>
          <div>
            <ButtonComponent
              isDisabled={isLoading}
              onClick={(e) => props.handleClose(e)}
              shape="round"
              text={t("basicWords.cancel")}
              className="buttonDiscard"
            />
          </div>
          <div>
            <ButtonComponent
              isDisabled={false}
              isLoading={isLoading}
              onClick={() => props.handleSave()}
              shape="round"
              text={t("basicWords.create")}
            />
          </div>
        </div>,
      ]}
      onClick={(e) => props.handleClose(e)}
    />
  );
};

export default AddNewSectionModal;
