import React, { useEffect, useState } from "react";
import { pick as _pick, get as _get, cloneDeep as _cloneDeep } from "lodash";
import { putCommunity } from "./../../../actions/uhoo/Community.actions"
import { getOwn } from "./../../../actions/uhoo/Users.actions"
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import { useTranslation } from 'react-i18next';

// components
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import SystemNotification from "../SystemNotification.jsx";

// css
import "../../community/components/css/configureCommunity.css";


function ConfigureCommunity({ setOpenModal }) {
  const { t, i18n } = useTranslation(); //  Localization

  // states
  const [ baseCommunity, setBaseCommunity ] = useState({})
  const [ currentCommunity, setCurrentCommunity ] = useState({})
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const loadInit = async () => {
      try {
        let result = await getOwn();
        let community = _pick(_get(result, "community", {}), [ "name", "description" ])
        setCurrentCommunity(community)
        setBaseCommunity(community)
      } catch(err) {
        console.log(err)
      }
    }
    loadInit()
  }, [])

  const updateCommunity = async () => {
    if(JSON.stringify(baseCommunity) != JSON.stringify(currentCommunity)) {
      try {
        await putCommunity(currentCommunity)
        setShowSuccess(true);
        let ownerResult = await getOwn();
        sessionStorage.setItem("own", JSON.stringify(ownerResult))
        setTimeout(() => setOpenModal(false), 3000)
      } catch (error) {
        console.log(error)
        setShowError(true);
      }
    }
  }

  const onChangeBase = (key, value) => {
    let _base = _cloneDeep(currentCommunity);
    _base[key] = value;
    setCurrentCommunity(_base)
  }

  return (
    <React.Fragment>
      <div className="modalConfigureCommunity">

        <div className="textTitleModalConfigureCommunity">
          <span> {t('uhoo.community.edit')} </span>
          <div className="closeModalConfigureCommunity" onClick={() => setOpenModal(false)} role="button">
            <Close/>
          </div>
        </div>
        <hr className="linesCommunity"/>

        <div style={{ width: "calc(100% - 20px)", padding: "10px", height: "62px" }}>
          <div className="labelForm">{t('basicWords.modal.name')}</div>
          <input
            type="text"
            name="name"
            defaultValue={_get(currentCommunity, 'name', '')}
            onChange={(e) => onChangeBase("name", e.target.value)}
            className="inputContentModalConfigureCommunity"
          />
        </div>

        <div style={{ width: "calc(100% - 20px)", padding: "10px", height: "62px" }}>
          <div className="labelForm">{t('basicWords.description')}</div>
          <input
            type="text"
            name="description"
            defaultValue={_get(currentCommunity, 'description', '')}
            onChange={(e) => onChangeBase("description", e.target.value)}
            className="inputContentModalConfigureCommunity"
          />
        </div>

        <div className="actionContentModalConfigureUser" onClick={() => updateCommunity()} style={{ marginTop: "20px" }}>
          <SecondaryButton
            txt={t('basicWords.modal.update')}
          />
        </div>

      </div>

      {!!showSuccess && (
        <SystemNotification
          setShowNotification={setShowSuccess}
          type="Success"
          caption={t('basicWords.modal.updateSuccessCommunity')}
        />
      )}
      {!!showError && (
        <SystemNotification
          setShowNotification={setShowError}
          type="Error"
          caption={t('uhoo.systemNotification.error')}
        />
      )}
      
    </React.Fragment>
  )
}

export default ConfigureCommunity;