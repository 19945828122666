import axios from 'axios';
import { get as _get } from "lodash";

let userToken;
let _own;
let userUuid;
if(sessionStorage.length != 0){
  userToken = sessionStorage.access_token;
  _own =sessionStorage.own;
  userUuid = _get(_own, "uuid", "");
}

export async function publishNotification(payload) {
try {
  let response = await axios({
    method: "POST",
    headers: {
      Authorization: "Bearer " + userToken,
      userUuid: userUuid
    },
    url: process.env.REACT_APP_NOTIFICATION + "/publishNotification",
    data: payload
  });
  return response
} catch (error) {
  return error;
}
}

export async function publishNotificationPast(payload, endpoint){
  try {
    let response = await axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken,
        "Content-Type": "application/json",
      },
      url: endpoint + "/publish",
      data: payload
    });
    if(response) {
      return response.data
    }
    return null;
  } catch (error) {
    return error;
  }
}
