import axios from 'axios';
import { get as _get } from "lodash";

import { protocol, host, port, route, dataSource } from '../../reactUtils/systemVariables/UhooServerInformation';

export async function getCommunity() {
  const userToken = "Bearer " + sessionStorage.access_token;
  const query = '/community';
  try {
    let configs = await axios({
      method: 'GET',
      url: protocol + host + port + route + dataSource + query,      
      headers: {
        'Content-Type': 'application/json',
        'Authorization': userToken,
      }
    })
    if(configs) {
      return configs.data;
    }
    return null
  } catch (error) {
    return error;
  };
}


export async function putCommunity(data) {
  const userToken = "Bearer " + sessionStorage.access_token;
  const query = '/community';
  try {
    let configs = await axios({
      method: 'PUT',
      url: protocol + host + port + route + dataSource + query,      
      headers: {
        'Content-Type': 'application/json',
        'Authorization': userToken,
      },
      data: data
    })
    if(configs) {
      return configs.data;
    }
    return null
  } catch (error) {
    return error;
  };
}