import axios from "axios";

import {
  host,
  port,
  route,
  datasource,
} from "../../reactUtils/systemVariables/CmsServerInformation";

const resource = "/section";

export async function getSectionsAvailableByLp(lp_id) {
  try {
    let response = await axios.get(
      host + port + route + datasource + resource,
      {
        params: {
          lp_id: lp_id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
}