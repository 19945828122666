import React from "react";
import { get as _get } from "lodash";
import "./css/PostNotifyPreview.css";

function NotifyPreview(props) {
  let URLactual = window.location;
  URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
  let isAura = URLactual.includes("aura")
  let title = props.previewTitle;
  let text = props.previewInfo;

  return (
    <React.Fragment>
      <div className="contentNotifyPreview">
        <div className="NotifyPrew">


          {text !== "" || title !== ""
            ?
            <React.Fragment>
              <div className="notificationPrewContent">

                <div className="NotifyPrewAppInfo">
                  <div className="NotifyPrewAppInfoName">
                    <div className={isAura ? "NotifyPrewAppInfoLogoAura" : "NotifyPrewAppInfoLogo" }></div>
                    {isAura ? 'AURAPP': 'UHOO'}
                  </div>
                  <div className="NotifyPrewAppInfoNow">now</div>
                </div>

                <div className="notifyInfo">

                  {title !== ""
                    ? <div className="titleNotify">{title}</div>
                    : null
                  }

                  {text !== ""
                    ? <div className="textNotify">{text}</div>
                    : null
                  }

                </div>
              </div>

            </React.Fragment>

            : null
          }

        </div>

      </div>
    </React.Fragment>
  );
};

export default NotifyPreview;


