import React, {useState, useEffect} from "react";
import { useHistory  } from "react-router-dom";
import '../../css/react-tabs.scss';
import SystemNotification from "../global/SystemNotification.jsx";
import { ReactComponent as BackArrow } from '../../assets/icons/navigation/backArrow.svg';
import { get as _get } from "lodash";
import Icon from '../../uhoo-components/iconLibrary.jsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from "@material-ui/core";
import { ReactComponent as Edited } from '../../assets/icons/activity/edit.svg';


//Context
import { UsersCommunityProvider } from './contex';
import "./css/community.css";
//Localization
import { useTranslation } from 'react-i18next';

//querys
import {usersByAudience} from "../../actions/uhoo"

import Modal from "../../uhoo-components/Modal.jsx";
import DeleteUserFromAudience from "./modals/DeleteUserFromAudience.jsx";
import AddUsers from "../global/modals/AddUsers.jsx";
import EditAudience from "./modals/EditAudience";

const SpecificAudience = (props) => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [initialLoading, setInitialLoading] = useState(false);
  const [autoPublish, setAutoPublish] = useState(false);
  const [allowUserLeave, setAllowUserLeave] = useState(false);
  const [commentsEnabled, setCommentsEnabled] = useState(false);
  const [likesEnabled, setLikesEnabled] = useState(false);
  const [showNotificatioError, setShowNotificatioError] = useState(false);
  const [showNotificationWarning, setShowNotificationWarning] = useState(false);
  const [showNotificationSuccessfull, setShowNotificationSuccessfull] = useState(false);
  const [openModalDeleted, setOpenModalDeleted] = useState(false);
  const [openModalAddUsers, setOpenModalAddUsers] = useState(false);
  const [deletedUser, setDeletedUser] = useState(false);
  const [adedUser, setAdedUser] = useState(false);
  const [editAudience, setEditAudience] = useState(false);
  
  const { t, i18n } = useTranslation();
  let history = useHistory();
  let dataAudience = _get(history, "location.state.dataAudience", {})
  if(_get(dataAudience, "uuid", "") == "" || deletedUser == true || adedUser == true){
    history.goBack();
  }
  useEffect(async ()=>{
    let dataAudience = _get(props, "location.state.dataAudience", {})
    let { properties } = dataAudience;
    if(properties.autopublish == 1) setAutoPublish(true);
    if(properties.allow_user_leave == 1) setAllowUserLeave(true);
    if(properties.comments_enabled == 1) setCommentsEnabled(true);
    if(properties.likes_enabled == 1) setLikesEnabled(true);
    const getUsers = async () => {
        let _users = await usersByAudience(dataAudience.uuid)
        setUsers(_users.data);
    }
    getUsers()
    setInitialLoading(true);

    }, []);

  const deleteUser = (_user) => {
    setUser(_user)
    setOpenModalDeleted(true);
  }





  return (
    <UsersCommunityProvider>
        <div className="Content">
            <section className="TitleEmail">
                <div className="titleArrow" style={{ display: "flex", alignItems: "baseline" }}>
                <BackArrow className="ico pointerCursor" onClick={() => history.goBack()} />
                <div style={{ display: "flex", alignItems: "center" }} onClick={() => setEditAudience(true)}>
                    <h3>{t('basicWords.audience')}: {dataAudience.name}</h3>
                    <Edited style={{ marginLeft: "10px", height: '35px', width: '35px' }} className="ico pointerCursor" />
                </div>
                </div>
            </section>
            <section className="previewEmail">
                <div className="containerRows">
                    <div className="spaceLeft">
                        <h3>{t('basicWords.configExistingText')}</h3>
                        <div className="secondRow">
                            <div className="spaceIntoCheckbox">
                                <input type="checkbox" className="autoPublish"  checked={autoPublish} onClick={() => setAutoPublish(!autoPublish)}/> {t('basicWords.autopub')}
                            </div>
                            <div className="spaceIntoCheckbox">
                                <input type="checkbox" className="commentsEnabled"  checked={commentsEnabled} onClick={() => setCommentsEnabled(!commentsEnabled)}/> {t('basicWords.comments')}
                            </div>
                            <div className="spaceIntoCheckbox">
                                <input type="checkbox" className="likesEnabled"  checked={likesEnabled} onClick={() => setLikesEnabled(!likesEnabled)}/> {t('basicWords.likes')}
                            </div>
                            {/* <div className="spaceIntoCheckbox">
                                <input type="checkbox" className="allowUserLeave"  checked={allowUserLeave} onClick={() => setAllowUserLeave(!allowUserLeave)}/> {t('basicWords.allowExit')}
                            </div> */}
                        </div>
                        <div className="componentSpaceTable">
                            <div className="rowAddUsers">
                                <h3>{t('basicWords.listUsers')} {' '} {'('}{users.length}{')'} </h3>
                                <div className="massiveActions">
                                    <div className="secondaryButton okay" role="button" onClick={() => setOpenModalAddUsers(true)}>{t('uhoo.community.add')}</div>
                                </div>
                            </div>
                            {initialLoading == false ?
                                <div
                                className="col-xs-12 col-md-12"
                                style={{
                                display: "flex",
                                justifyContent: "center",
                                transform: "translateY(500%)",
                                }}
                                >
                                    <CircularProgress />
                                </div>
                            :
                            <>
                                {users.length > 0 ?
                                    <div>
                                        <TableContainer style={{ maxHeight: "440" }}>
                                        <Table>
                                            <TableHead>
                                            <TableRow>
                                                <TableCell className="headerTableTh">{t('basicWords.modal.mail')}</TableCell>
                                                <TableCell className="headerTableTh">{t('basicWords.name')}</TableCell>
                                                <TableCell className="headerTableTh">{t('basicWords.modal.last_name')}</TableCell>
                                                <TableCell className="headerTableTh">{t('uhoo.globalWords.delete')}</TableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {users.map((user) => {
                                                return (
                                                <>
                                                    <div className="spaceIntoRows" />
                                                    <TableRow key={user.uuid}>
                                                        <TableCell className="tdTable">{_get(user, "emails[0].email", "")}</TableCell>
                                                        <TableCell className="tdTable">{user.name}</TableCell>
                                                        <TableCell className="tdTable">{user.last_name}</TableCell>
                                                        <TableCell className="tdTable">
                                                            <div className="spaceLeftTable">
                                                                <Icon.trashIcon className="ico pointerCursor" onClick={() => deleteUser(user)} />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </>

                                                );
                                            })}
                                            </TableBody>
                                        </Table>
                                        </TableContainer>
                                    </div>
                                :
                                    <h3>No tiene usuarios esta comunidades</h3>
                                }
                            </>
                            }
                            
                        </div>
                    </div>
                </div>
            </section>

            {editAudience ? (
                <Modal>
                    <EditAudience
                        audience={dataAudience}
                        setOpenModal={setEditAudience}
                    />
                </Modal>
            ) :  null}

            {
                !!showNotificationWarning && (
                <EditAudience
                    setShowNotification={setShowNotificationWarning}
                    type="Warning"
                    caption={t('basicWords.mustSelectAudiences')} 
                />
                )
            }
            {
                !!showNotificationSuccessfull && (
                <SystemNotification
                    setShowNotification={setShowNotificationSuccessfull}
                    type="Success"
                    caption="Se ha publicado el post"
                />
                )
            }
            {
                !!showNotificatioError && (
                <SystemNotification
                    setShowNotification={setShowNotificatioError}
                    type="Error"
                    caption="No se ha publicado el post"
                />
                )
            }
            {/* eder.lobo@brilliant360.net	 */}
            {openModalDeleted ? (
                <Modal>
                    <DeleteUserFromAudience
                        audience={dataAudience}
                        usersSelected={user}
                        setOpenModal={setOpenModalDeleted}
                        setDeletedUser={setDeletedUser}
                    />
                </Modal>
            ) : (
                null
            )}

            {openModalAddUsers ? (
                <Modal>
                    <AddUsers
                        usersAlready={users}
                        setOpenModal={setOpenModalAddUsers}
                        audience={dataAudience}
                        setAdedUser={setAdedUser}
                    />
                </Modal>
            ) : (
                null
            )}
        </div>
    </UsersCommunityProvider>
  );  
};

export default SpecificAudience;

