import React, { Component } from "react";
import moment from "moment";
// import Carousel from "react-material-ui-carousel";

const dateOnlyFormat = "YYYY / MM / DD";

export class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title : props.title,
      description : props.description,
      date : moment(props.date).format(dateOnlyFormat),
      name : props.name,
      lastName : props.lastName,
      images : props.image,
      regexMP4 : /mp4/,
      approved : props.approved,
      active : props.active,
      banned : props.banned,
      show : null,
      key : props.key
    };
  }

  componentDidMount() {
    if (
      this.state.active === 1 &&
      this.state.approved === 1 &&
      this.state.banned === 0
    ) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
    if (this.state.lastName === undefined) {
      this.setState({ lastName: "" });
    }
  }

  renderImgAndVideos(images) {
    let renderedComponents = [];
    if (images) {
      images.forEach((obj) => {
        if (this.state.regexMP4.test(obj.url) === true) {
          renderedComponents.push(
            <video width="100%" height="500" controls>
              <source key={obj.id} src={obj.url} type="video/mp4"></source>
            </video>
          );
        } else {
          renderedComponents.push(
            <img key={obj.id} src={obj.url} width="100%" height="100%" alt={obj.url}/>
          );
        }
      });
    }
    return renderedComponents;
  }

  render() {
    return (
      <div className="post">
        {this.state.show === true ? (
          <div>
            <div className="borderpost_title text-center">
              <h3>
                <strong>{this.state.title}</strong>
              </h3>
            </div>
            <h6 className="text-center">{this.state.date}</h6>
            {/* <Carousel>{this.renderImgAndVideos(this.state.images)}</Carousel> */}

            <h4 className="post_description">
              <strong>{this.state.name + "" + this.state.lastName}. </strong>
              {this.state.description}
            </h4>
          </div>
        ) : null}
      </div>
    );
  }
}
