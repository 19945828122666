import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../css/react-tabs.scss';
import { ReactComponent as BackArrow } from '../../assets/icons/navigation/backArrow.svg';
import './css/mail.css';
import { get as _get } from "lodash";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from "@material-ui/core";
import moment from "moment";
import { auraBasic, auraPro } from '../../reactUtils/generalUtils/auraPlans'
import { ReactComponent as PaginationBack } from '../../assets/icons/navigation/paginationBack.svg';
import { ReactComponent as PaginationForward } from '../../assets/icons/navigation/paginationForward.svg';
import Modal from '../../uhoo-components/Modal.jsx';
import CantCreate from '../global/modals/cantCreate.jsx'

// Queries
import { GetPost } from "../../actions/uhoo/Post.actions";
import { getConfigs } from '../../actions/uhoo/Config.actions'; // Singularity 

//Localization
import { useTranslation } from 'react-i18next';
import Icon from '../../uhoo-components/iconLibrary.jsx';

const Mail = () => {

  const [search, setSearch] = useState("");
  const [postEmail, setPostEmail] = useState([]);
  
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  const [step, setStep] = useState(0);
  const [post, setPost] = useState({});

  const [openModalCantCreate, setOpenModalCantCreate] = useState(false);
  const [textModalCantCreate, setTextModalCantCreate] = useState('');

  const [lengthEmail, setMaxLengthEmail] = useState(null);
  const [lengthEmailCreated, setLengthEmailCreated] = useState(0);

  const { t, i18n } = useTranslation(); //  Localization  
  let history = useHistory(); //  from 'react-router-dom' -> la uso para avanzar o retroceder en el historial de navegacion 

  /// ----- Singularity ----- ///

  const [massiveEmail, setMassiveEmail] = useState(false);

  useEffect(async () => {
    const _configs = await getConfigs();
    let config = _configs.data.data;
    let _suscription = _get(config.find((element) => { return element.name === "suscription.plan"}), "value", 'basic');
    let query = [{ key: 'search', value: '', }, { key: 'properties.type;eq', value: 'm', }];
    const _postEmail = await GetPost(query);
    setLengthEmailCreated(_postEmail.page.total);
    if(_suscription == 'pro') {
      setMaxLengthEmail(_get(auraPro, 'maxLengthEmail', null));
    }else{
      setMaxLengthEmail(_get(auraBasic, 'maxLengthEmail', null));
    }
  }, [])

  const Singularity = () => {
    useEffect(async () => {
      const _configs = await getConfigs();
      let config = _configs.data.data;

      setMassiveEmail(
        config.find((element) => {
          return element.name === "massiveEmail.enabled";
        }).value
      );

    }, []);
  };

  Singularity();

  if (massiveEmail === 0) {
    history.replace("/manage")
  }

  /// --- end Singularity --- ///

  useEffect(async () => {
    const getPostData = async () => {
      let query = [{ key: 'search', value: '' }, { key: 'properties.type;eq', value: 'm', }, { key: 'page', value: page - 1 }];
      const _postEmail = await GetPost(query);
      if(_postEmail.data.length > 0){
        _postEmail.data.forEach((element, index) => {
          let date = moment(element.created_at).subtract(5, 'hours').format("YYYY/MM/DD - HH:mm");
          _postEmail.data[index].created_at = date;
        });
      }
      setPostEmail(_get(_postEmail, "data", []))
      setTotalPages(_get(_postEmail, "page.page_size", 1))

    };
    getPostData(); //  get post type email
  }, [page]);

  const selectPost = (_post, _step) => {
    setPost(_post)
    setStep(_step + 1);
  }


  /*
    <div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
    <div className="communitySearch">
      <Search className="icoCommunitySearch" id="icoCommunitySearch"/>
      <input type="search" className="inputSearch" placeholder="Busca y filtra" onChange={(event) => setSearch(event.target.value)} id="CommunitySearch"/>            
    </div>
    <div className="createPostActions">
      <div className="toPost" role="button">{t('basicWords.search')} </div>
    </div> 
  </div>
  */
 const configOpeModalCanCreate = () => {
  setTextModalCantCreate(t('uhoo.home.cards.emailCard.cardTitle'));
  setOpenModalCantCreate(true);
};

let URLactual = window.location;
URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
let isAura = URLactual.includes("aura");

  return (
    <React.Fragment>
      <section className="TitleManageEmail">
        <div className="titleArrow">
          <BackArrow className="ico pointerCursor" onClick={() => step == 0 ? history.goBack() : setStep(0)} />
          <h3>Email</h3>
        </div>
        <div className="createPostActions">
          <div className="toPost" role="button" onClick={() => lengthEmail != null && lengthEmailCreated >= lengthEmail && isAura ? configOpeModalCanCreate() : history.replace("/createMail")}>{t('uhoo.mail.title')}</div>
        </div>
      </section>

      <section className="mail">
        {step == 0 ?
          <Tabs>
            <TabList>
              <Tab>Email</Tab>
            </TabList>
            <TabPanel>
              <div className="mailContent" >
                {postEmail.length > 0 ?
                  <div className="componentSpaceTableEmail">
                    <TableContainer style={{ maxHeight: "440" }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ textAlign: 'left !important' }}>{t('basicWords.name')}</TableCell>
                            <TableCell style={{ textAlign: 'left !important' }}>{t('basicWords.date')}</TableCell>
                            <TableCell style={{ textAlign: 'left !important' }}>{t('basicWords.state')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {postEmail.map((post) => {
                            return (
                              <>
                                <div className="spaceIntoRows" />
                                <TableRow key={post.uuid}>
                                  <div className="contentEmailSelect">
                                    <input type="hidden" className="inputSelectTable" /> {post.title}
                                  </div>
                                  <TableCell>{post.created_at}</TableCell>
                                  <TableCell>{post.active == 1 ? t('basicWords.active') : t('basicWords.unpublish')}</TableCell>
                                  <TableCell>{<Icon.eye className="ico pointerCursor" onClick={() => selectPost(post, step)} />}</TableCell>
                                </TableRow>
                              </>

                            );
                          })}
                        </TableBody>
                        <div className="containerButton">
                          <div className="pBack" onClick={() => page == 1 ? null : setPage(page - 1)} role="button">
                            <PaginationBack className="paginationICO" role="button" />
                          </div>
                          <div className="textDataPagination">
                            {page} / {totalPages > 0 ? totalPages : 1}
                          </div>
                          <div className="pForward" onClick={() => page >= totalPages ? null :setPage(page + 1)} role="button">
                            <PaginationForward className="paginationICO" role="button" />
                          </div>
                        </div>
                      </Table>
                    </TableContainer>
                    
                  </div>
                  :
                  <div
                    className="col-xs-12 col-md-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      transform: "translateY(500%)",
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              </div>
            </TabPanel>
          </Tabs>
          :
          <div className="containerHtml">
            <div className="createPostActionsEmail">
              <div className="toPost" role="button" onClick={() => lengthEmail != null && lengthEmailCreated >= lengthEmail && isAura ? configOpeModalCanCreate() : history.push({pathname: '/createMail', state: {template: _get(post, "content", "")}}) }>{t('basicWords.useTemplate')}</div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: _get(post, "content", "")
              }}>
            </div>
          </div>
        }

      </section>

      {openModalCantCreate ? (
        <Modal>
          <CantCreate
            setOpenModal={setOpenModalCantCreate}
            text={textModalCantCreate}
          />
        </Modal>
      ) : null}


    </React.Fragment>
  );

};
export default Mail;
