// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { get as _get } from "lodash";

let URLactual = window.location;
let configFb;
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

if(_get(URLactual, "host", "uhoo.io")  == "shaio.uhoo.io"){ //config Shaio
  configFb = {
    apiKey: "AIzaSyB54jFD-5G6kJqkzsHo1MjXN2KXjOHgJzI",
    authDomain: "uhoo-shaio.firebaseapp.com",
    projectId: "uhoo-shaio",
    storageBucket: "uhoo-shaio.appspot.com",
    messagingSenderId: "125494291099",
    appId: "1:125494291099:web:64110d031808ba8189343e",
    measurementId: "G-DJJJHPF6PJ"
  }
} else if(_get(URLactual, "host", "uhoo.io")  == "vanti.uhoo.io"){ //config Vanti
  configFb = {
    apiKey: "AIzaSyCvCVCRfhbXrVY89VBQcH9KUvioiLRfbn0",
    authDomain: "uhoo-vanti.firebaseapp.com",
    projectId: "uhoo-vanti",
    storageBucket: "uhoo-vanti.appspot.com",
    messagingSenderId: "1089441947101",
    appId: "1:1089441947101:web:92db5672c07d2f8ce5ea34",
    measurementId: "G-C0TDCPZCVH"
  }
} else if(_get(URLactual, "host", "uhoo.io")  == "porvenir.uhoo.io"){ //config Porvenir
  configFb = {
    apiKey: "AIzaSyBZVx06l0w-sth_6wcaCv9rJWluC8JKzSg",
    authDomain: "uhoo-porvenir.firebaseapp.com",
    projectId: "uhoo-porvenir",
    storageBucket: "uhoo-porvenir.appspot.com",
    messagingSenderId: "344860295609",
    appId: "1:344860295609:web:814b8380a2ea2c652eb668",
    measurementId: "G-GNXM4QR7L6"
  }
} else if(_get(URLactual, "host", "uhoo.io")  == "tulia.uhoo.io"){ //config Tulia
  configFb = {
    apiKey: "AIzaSyD22vyhU7IxTcWICF37hlKhSPU759x_9rU",
    authDomain: "uhoo-tulia.firebaseapp.com",
    projectId: "uhoo-tulia",
    storageBucket: "uhoo-tulia.appspot.com",
    messagingSenderId: "704967982841",
    appId: "1:704967982841:web:da8257a5d9e2d05496ec28",
    measurementId: "G-QYFM56PGTE"
  }
} else if(_get(URLactual, "host", "uhoo.io")  == "cedej.aurapp.org"){ //config Cedej
  configFb = {
    apiKey: "AIzaSyCn6ykUj54Q227re1jsEys0bS0UOMtxHPQ",
    authDomain: "aura-cedej.firebaseapp.com",
    projectId: "aura-cedej",
    storageBucket: "aura-cedej.appspot.com",
    messagingSenderId: "151120913480",
    appId: "1:151120913480:web:c246c92cbfd4754168247d",
    measurementId: "G-R6WXGK0Q3E"
  }
} else if(_get(URLactual, "host", "uhoo")  == "rcenvivo.aurapp.org"){ //config RC
  configFb = {
    apiKey: "AIzaSyBU4Kys7tDFsLSxZLrCzfNCDr3_h-cWNvg",
    authDomain: "aura-regnumchristi.firebaseapp.com",
    projectId: "aura-regnumchristi",
    storageBucket: "aura-regnumchristi.appspot.com",
    messagingSenderId: "1028265461540",
    appId: "1:1028265461540:web:328cc679bac244f537d5b1",
    measurementId: "G-E37QFF7GGV"
  }
} else if(_get(URLactual, "host", "uhoo")  == "aurapp.org"){ //config Aurapp
  configFb = {
    apiKey: "AIzaSyATWwJL0Zlaqqj0ZGuWZeEexC6H26m5QUE",
    authDomain: "brilliant-app-151815.firebaseapp.com",
    databaseURL: "https://brilliant-app-151815.firebaseio.com",
    projectId: "brilliant-app-151815",
    storageBucket: "brilliant-app-151815.appspot.com",
    messagingSenderId: "360422630814",
    appId:"1:360422630814:web:6e51535de634237801a28f"
  }
}
else { 
  if(process.env.REACT_APP_DEFAULT_CONFIG_FB == 'Aura'){
    // config Aurapp
    configFb = {
      apiKey: "AIzaSyATWwJL0Zlaqqj0ZGuWZeEexC6H26m5QUE",
      authDomain: "brilliant-app-151815.firebaseapp.com",
      databaseURL: "https://brilliant-app-151815.firebaseio.com",
      projectId: "brilliant-app-151815",
      storageBucket: "brilliant-app-151815.appspot.com",
      messagingSenderId: "360422630814",
      appId:"1:360422630814:web:6e51535de634237801a28f"
    }
  }
  else if(process.env.REACT_APP_DEFAULT_CONFIG_FB == 'RC'){
    // config Aurapp
    configFb = {
      apiKey: "AIzaSyBU4Kys7tDFsLSxZLrCzfNCDr3_h-cWNvg",
      authDomain: "aura-regnumchristi.firebaseapp.com",
      projectId: "aura-regnumchristi",
      storageBucket: "aura-regnumchristi.appspot.com",
      messagingSenderId: "1028265461540",
      appId: "1:1028265461540:web:328cc679bac244f537d5b1",
      measurementId: "G-E37QFF7GGV"
    }
  }
  else if(process.env.REACT_APP_DEFAULT_CONFIG_FB == 'Porvenir'){
    // config Aurapp
    configFb = {
      apiKey: "AIzaSyBZVx06l0w-sth_6wcaCv9rJWluC8JKzSg",
      authDomain: "uhoo-porvenir.firebaseapp.com",
      projectId: "uhoo-porvenir",
      storageBucket: "uhoo-porvenir.appspot.com",
      messagingSenderId: "344860295609",
      appId: "1:344860295609:web:814b8380a2ea2c652eb668",
      measurementId: "G-GNXM4QR7L6"
    }
  }
  else{
    // default config Uhoo
    configFb = {
      apiKey: "AIzaSyC2qlbg7xkOWTiSNbU3ncJlLtC8Kw2mDpU",
      authDomain: "uhoo-uhoo.firebaseapp.com",
      projectId: "uhoo-uhoo",
      storageBucket: "uhoo-uhoo.appspot.com",
      messagingSenderId: "812141158426",
      appId: "1:812141158426:web:db7a4080d3e598cad331a3",
      measurementId: "G-B0ME2R9YTV"
    }
  }
}

export default initializeApp(configFb);


/*
Prod

const firebaseConfig = {
  apiKey: "AIzaSyC2qlbg7xkOWTiSNbU3ncJlLtC8Kw2mDpU",
  authDomain: "uhoo-uhoo.firebaseapp.com",
  projectId: "uhoo-uhoo",
  storageBucket: "uhoo-uhoo.appspot.com",
  messagingSenderId: "812141158426",
  appId: "1:812141158426:web:db7a4080d3e598cad331a3",
  measurementId: "G-B0ME2R9YTV"
};
*/