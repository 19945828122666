import axios from "axios";

import {
  host,
  port,
  route,
  datasource,
} from "../../reactUtils/systemVariables/CmsServerInformation";

const resource = "/lpstructure";

export async function getLandingpageStrucureById(lpId) {
  try {
    let response = await axios.get(
      host + port + route + datasource + resource,
      {
        params: {
          lpId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateStructuresPositions(LpStructurePositionGeneralDto) {
  try {
    const data = JSON.stringify(LpStructurePositionGeneralDto);
    let response = await axios({
      method: "PUT",
      url: host + port + route + datasource + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}
