import React from "react";
import Select from "react-select";
import "./css/Select.scss";

export function SelectSingleStreamings(props) {
  let defVal,
    size = "default";
  if (props.options) {
    defVal = props.options.filter(
      (option) => option.value === props.defaultValue
    );
  } else {
    defVal = null;
  }
  if (props.size) {
    size = props.size;
  }

  return (
    <div className="row">
      <div className="col-12">
        <Select
          className="basic-single"
          classNamePrefix={props.text}
          isDisabled={props.isDisabled}
          isLoading={props.isLoading}
          isSearchable={true}
          name={props.name}
          onChange={props.onChange}
          options={props.options}
          size={size}
          placeholder={props.text}
          value={defVal}
        />
      </div>
    </div>
  );
}
