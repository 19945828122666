import React, { useContext, useState } from "react";
import { pick as _pick, get as _get, cloneDeep as _cloneDeep } from "lodash";
import { updateSegment } from "./../../../actions/uhoo/Segment.actions"
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

// components
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import SystemNotification from "../../global/SystemNotification";

// css
import "../../community/components/css/configureCommunity.css";

function EditAudience({ setOpenModal, audience }) {
  /**
   * Props
   */
  const { t, i18n } = useTranslation(); //  Localization
  const history = useHistory();

  /**
   * Hooks
   */
  const [currentAudience, seturrentAudience] = useState(audience);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  /**
   * Methods
   */
  const updateNameAudience = async () => {
    if(JSON.stringify(audience) != JSON.stringify(currentAudience)) {

      try {
        await updateSegment(_get(currentAudience, "uuid", ""), _pick(currentAudience, [ "name" ]))
        setShowSuccess(true);
        history.location.state.dataAudience = currentAudience;
        setTimeout(() => setOpenModal(false), 3000)
      } catch (error) {
        console.log(error)
        setShowError(true);
      }
    }
  }
  const onChangeAudience = (key, value) => {
    let _base = _cloneDeep(currentAudience);
    _base[key] = value;
    seturrentAudience(_base)
  }


  return (
    <React.Fragment>
      <div className="modalConfigureCommunity">

        <div className="textTitleModalConfigureCommunity">
          <span> {t('uhoo.community.editAudience')} </span>
          <div className="closeModalConfigureCommunity" onClick={() => setOpenModal(false)} role="button">
            <Close/>
          </div>
        </div>
        <hr className="linesCommunity"/>

        <div style={{ width: "calc(100% - 20px)", padding: "10px", height: "62px" }}>
          <div className="labelForm">{t('basicWords.modal.name')}</div>
          <input
            type="text"
            name="name"
            defaultValue={_get(currentAudience, 'name', '')}
            onChange={(e) => onChangeAudience("name", e.target.value)}
            className="inputContentModalConfigureCommunity"
          />
        </div>

        <div className="actionContentModalConfigureUser" onClick={() => updateNameAudience()} style={{ marginTop: "20px" }}>
          <SecondaryButton
            disabled={!!(JSON.stringify(audience) == JSON.stringify(currentAudience)) || currentAudience.name.length == 0}
            txt={t('basicWords.modal.update')}
          />
        </div>

      </div>

      {!!showSuccess && (
        <SystemNotification
          setShowNotification={setShowSuccess}
          type="Success"
          caption={t('basicWords.modal.updateSuccessAudience')}
        />
      )}
      {!!showError && (
        <SystemNotification
          setShowNotification={setShowError}
          type="Error"
          caption={t('uhoo.systemNotification.error')}
        />
      )}
      
    </React.Fragment>
  )
}

export default EditAudience;
