import React, {useState} from "react";

// components
import { ReactComponent as OptionCard } from '../../../assets/icons/navigation/optionCard.svg';
import DeleteAudience from "./../modals/DeleteAudience";
import Modal from "../../../uhoo-components/Modal.jsx";
import { Link } from "react-router-dom";
//Localization
import { useTranslation } from 'react-i18next';

import "./css/communityCard.css";

const AudienceCard = ( props ) => {

  /**
   * Hooks
   */
  const { t, i18n } = useTranslation();

  /**
   * props
   */
  let name, firstLetter = "";
  if ( props.name === null ) { name = "" } else { name = props.name }
  firstLetter = name.charAt(0).toUpperCase();

  /**
   * States
   */
  const [openDrop, setOpenDrop] = useState(false);

  // modals
  const [deleteAudience, setDeleteAudience] = useState(false);
  

  /**
   * Functions
   */
  function dropAudience() { // despliega o cierra el dorp de audiencias     
    let elements = document.getElementsByClassName("dropCommunity-content")
    let element = "dropAudience-" + props.uuid;
    for (let item of elements) {  // verifica los drop de otros usuarios y los cierra antes de abrir el actual
      if (item.id == element) {
        document.getElementById(element).classList.toggle("show");
        setOpenDrop(!openDrop); element = "";
      } else {
        document.getElementById(item.id).classList.remove("show");
      }
    }
  }

  function selectItem() {
    let selectItem = document.querySelectorAll('input.checkboxCardUser');    
    let cards = document.querySelectorAll(".cardUser");
    let actionView = false;    
    selectItem.forEach(select => (select.checked) ? actionView = true : null);
    if (actionView)
    {
      //cards.forEach(select => select.classList.add("actionViewTrue"));
      //document.getElementById("massiveActions").classList.add("actionViewShow");
    } 
    if (!actionView)
    {
      //cards.forEach(select => select.classList.remove("actionViewTrue"));
      //document.getElementById("massiveActions").classList.remove("actionViewShow");
    }
  }

  const openModalDelete = () => {
    dropAudience()
    setDeleteAudience(true)
  }

  let can_delete_channel = sessionStorage.getItem("delete_channel") == 'true'
  let can_edit_channel = sessionStorage.getItem("edit_channel") == 'true'
  let super_admin = sessionStorage.getItem("super_admin") == 'true'

  return (
    <React.Fragment>

      <div className="cardUser" id="cardUser">
        <div className="cardUserSelect">
          {props.showActions ? <input type="checkbox" className="checkboxCardUser" onChange={selectItem}/> : null}
        </div>      
        <div className="cardUserOption pointerCursor" onClick={dropAudience}>
          <OptionCard/>
        </div>

        <div className="dropCommunity-content" id={"dropAudience-" + props.uuid}>
          <div className="dropCommunity">
            <Link to={{pathname: "/specificAudience", state: {dataAudience: props.data}}}> 
              <div className={"dropOptionCommunity"} role="button" id={"config-audience-" + props.uuid}>
                {t("uhoo.globalWords.setting")}
              </div>
            </Link>

            {/* <div className="dropOptionCommunity" role="button" id={"notification-audience-" + props.uuid}>
              Enviar notificación
            </div> */}
            <div className={"dropOptionCommunity"} 
                  role="button" onClick={openModalDelete} id={"delete-audience-" + props.uuid}
                  style={{paddingLeft: '23px'}}>
              {t("uhoo.globalWords.delete")}
            </div>
          </div>
        </div>
        
        <div className="cardUserContent">
          <div className="cardUserImage">
            {
              firstLetter
            }
          </div>
          <div className="cardUserInfo">
            <div className="cardUserName">
              { 
                name
              }
            </div>
            <div className="cardUserRole">

              Usuarios

            </div>
          </div>
        </div>


      </div>
      {/* Modals */}

      { deleteAudience ?
        <Modal>
          <DeleteAudience {...props} setModal={setDeleteAudience} />
        </Modal>
      : null }

    </React.Fragment>
  );  
};

export default AudienceCard;
