import axios from 'axios';

import { host, port, route, datasource } from '../../reactUtils/systemVariables/CmsServerInformation';

const resource = "/scheduleeventinfo";

export async function getScheduleEventInfoByLpSectionComponentId(id) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            lpsectioncomponent_id: id
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function putScheduleEventInfo(scheduleInfoToUpdate) {
   try {
      const data = JSON.stringify(scheduleInfoToUpdate)
      let response = await axios({
         method: 'PUT',
         url: host + port + route + datasource + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function disableScheduleEvents(scheduleInfoToDisable) {
   try {
      const data = JSON.stringify(scheduleInfoToDisable)
      let response = await axios({
         method: 'DELETE',
         url: host + port + route + datasource + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}