import React from "react";
// Components
import { Title, ButtonsFooterComponent, GeneralInput, DateTimePickerComponent } from "../../../../aura-components";

//Localization
import { useTranslation } from 'react-i18next';
import { get as _get } from "lodash";

import {
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
} from "@material-ui/core";


// Icons
import AurappLogo from "../../../../assets/images/logo.png";
import UhooLogo from "../../../../assets/images/LogoUhooColor.png";
import { X } from "react-feather";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditRecurrenceStreamingModal = (props) => {
  const { scheduleEventInfo } = props;
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    props.handleClose();
  };

  let URLactual = window.location;
  URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
  let isAura = URLactual.includes("aura");

  return (
    <>
      <Dialog
        fullScreen
        open={props.visible}
        onClose={(e) => handleClose(e)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar style={{ display: "flex" }}>
            {!isAura ? (
              <img
                style={{ width: "3%", margin: "1%" }}
                id="user"
                src={AurappLogo}
                alt="user"
              />
            ) : (
              <img
                style={{ width: "3%", margin: "1%" }}
                id="user"
                src={UhooLogo}
                alt="user"
              />
            )}
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              style={{ marginLeft: "85%", color: "black" }}
            >
              <X />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Card>
          <div>
            <div
              className="row componentSpace"
              style={{ marginTop: "5%", marginLeft: "2%" }}
            >
              <Title title={t('basicWords.listStreamingsEvents')} />
            </div>
            <br />
            <div className="row componentSpace">
              <div className="col-12">
                {scheduleEventInfo != null ? (
                  <TableContainer
                    style={{
                      width: "95%",
                      marginRight: "2%",
                      marginLeft: "2%",
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('basicWords.title')}</TableCell>
                          <TableCell>
                            {t('basicWords.description')}
                          </TableCell>
                          <TableCell>{t('generalMessages.url')}</TableCell>
                          <TableCell>{t('basicWords.streaming.initDate')}</TableCell>
                          <TableCell>{t('basicWords.streaming.endDate')}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {scheduleEventInfo.map((row, index) => {
                          return (
                            <TableRow key={row.description}>
                              <TableCell>
                                <GeneralInput
                                  className="textInputWidth"
                                  id="StreamingComponentTitle"
                                  value={row.title}
                                  onChange={(e) => props.handleChange(e, index)}
                                />
                              </TableCell>
                              <TableCell>
                                <GeneralInput
                                  className="textInputWidth"
                                  id="StreamingComponentDescription"
                                  value={row.description}
                                  onChange={(e) => props.handleChange(e, index)}
                                />
                              </TableCell>
                              <TableCell>
                                <GeneralInput
                                  className="textInputWidth"
                                  id="StreamingComponentUrl"
                                  value={row.url !== null ? row.url : ""}
                                  onChange={(e) => props.handleChange(e, index)}
                                />
                              </TableCell>
                              <TableCell>
                                <DateTimePickerComponent
                                  onChange={(e) => props.initDate(e, index)}
                                  string={t('basicWords.streaming.initDate')}
                                  defaultValue={
                                    row.initialDate
                                      ? moment.utc(row.initialDate)
                                      : null
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <DateTimePickerComponent
                                  onChange={(e) => props.finalDate(e, index)}
                                  string={t('basicWords.streaming.finalDate')}
                                  defaultValue={
                                    row.finalDate ? moment.utc(row.finalDate) : null
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  ""
                )}
              </div>
              {/* Buttons */}
              <ButtonsFooterComponent
                FlagclassName={true}
                onClick={(e) => props.handleSave(e)}
              />
            </div>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default EditRecurrenceStreamingModal;
