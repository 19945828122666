import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AudienceCommunity from "../community/audienceCommunity.jsx";
import 'grapesjs/dist/css/grapes.min.css';
import './css/grapeCSS.scss';
import "./css/createMail.css";
import grapesjs from 'grapesjs';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ReactComponent as BackArrow } from '../../assets/icons/navigation/backArrow.svg';
import CircularProgress from '@mui/material/CircularProgress';
import { auraBasic, auraPro } from '../../reactUtils/generalUtils/auraPlans'
import Modal from '../../uhoo-components/Modal.jsx';
import CantCreate from '../global/modals/cantCreate.jsx'
import { generateUUID } from "../../reactUtils/generalUtils/generateUuid";

//Context
import { UsersCommunityProvider } from '../community/contex';

//Localization
import { useTranslation } from 'react-i18next';

//query
import { getChannels } from '../../actions/uhoo/Channels.actions';
import { publishPost, publishChannelPost, postMediaArbitrary } from "./../../actions/uhoo"
import SystemNotification from "../global/SystemNotification.jsx";
import { getConfigs } from '../../actions/uhoo/Config.actions'; // Singularity 
import { GetPost } from "../../actions/uhoo/Post.actions.js";

//helpers
import { get as _get, random } from "lodash";

const CreateMail = (props) => {

  const { t, i18n } = useTranslation(); //  Localization  
  const [editor, setEditor] = useState(null);
  const [subjectEmail, setSubjectEmail] = useState("");
  const [email, setEmail] = useState("");
  const [audienceSelected, setAudienceSelected] = useState([]);
  const [step, setStep] = useState(0);
  const [showNotificatioError, setShowNotificatioError] = useState(false);
  const [showNotificationWarning, setShowNotificationWarning] = useState(false);
  const [showNotificationSuccessfull, setShowNotificationSuccessfull] = useState(false);
  const [channelPublish, setChannelPublish] = useState([]);
  const [lengthEmail, setMaxLengthEmail] = useState(null);
  const [lengthEmailCreated, setLengthEmailCreated] = useState(0);
  const [openModalCantCreate, setOpenModalCantCreate] = useState(false);
  const [textModalCantCreate, setTextModalCantCreate] = useState('');
  const [ isLoadingPost, setIsLoadingPost ] = useState(false);

  const [ baseHtml, setBaseHtml ] = useState("");
  const [ baseCss, setBaseCss ] = useState("");

  let history = useHistory();
  var juice = require("juice");

  /// ----- Singularity ----- ///

  const [massiveEmail, setMassiveEmail] = useState(false);

  const svgDesktop = `<svg class="svg-icon" viewBox="0 0 20 20" ">
							<path d="M17.237,3.056H2.93c-0.694,0-1.263,0.568-1.263,1.263v8.837c0,0.694,0.568,1.263,1.263,1.263h4.629v0.879c-0.015,0.086-0.183,0.306-0.273,0.423c-0.223,0.293-0.455,0.592-0.293,0.92c0.07,0.139,0.226,0.303,0.577,0.303h4.819c0.208,0,0.696,0,0.862-0.379c0.162-0.37-0.124-0.682-0.374-0.955c-0.089-0.097-0.231-0.252-0.268-0.328v-0.862h4.629c0.694,0,1.263-0.568,1.263-1.263V4.319C18.5,3.625,17.932,3.056,17.237,3.056 M8.053,16.102C8.232,15.862,8.4,15.597,8.4,15.309v-0.89h3.366v0.89c0,0.303,0.211,0.562,0.419,0.793H8.053z M17.658,13.156c0,0.228-0.193,0.421-0.421,0.421H2.93c-0.228,0-0.421-0.193-0.421-0.421v-1.263h15.149V13.156z M17.658,11.052H2.509V4.319c0-0.228,0.193-0.421,0.421-0.421h14.308c0.228,0,0.421,0.193,0.421,0.421V11.052z"></path>
						</svg>`;
  const svgMobile = `<svg class="svg-icon" viewBox="0 0 20 20" ">
                <path d="M13.372,1.781H6.628c-0.696,0-1.265,0.569-1.265,1.265v13.91c0,0.695,0.569,1.265,1.265,1.265h6.744c0.695,0,1.265-0.569,1.265-1.265V3.045C14.637,2.35,14.067,1.781,13.372,1.781 M13.794,16.955c0,0.228-0.194,0.421-0.422,0.421H6.628c-0.228,0-0.421-0.193-0.421-0.421v-0.843h7.587V16.955z M13.794,15.269H6.207V4.731h7.587V15.269z M13.794,3.888H6.207V3.045c0-0.228,0.194-0.421,0.421-0.421h6.744c0.228,0,0.422,0.194,0.422,0.421V3.888z"></path>
              </svg>`;

  useEffect(async () => {
    const _configs = await getConfigs();
    let config = _configs.data.data;
    let _suscription = _get(config.find((element) => { return element.name === "suscription.plan"}), "value", 'basic');
    let query = [{ key: 'search', value: '', }, { key: 'properties.type;eq', value: 'm', }];
    const _postEmail = await GetPost(query);
    setLengthEmailCreated(_postEmail.page.total);
    if(_suscription == 'pro') {
      setMaxLengthEmail(_get(auraPro, 'maxLengthEmail', null));
    }else{
      setMaxLengthEmail(_get(auraBasic, 'maxLengthEmail', null));
    }
    let template = _get(props, "location.state.template", "");
    if(template != ""){
      let style = template.split('<style>');
      style = style[1].split('</style>');
      setBaseHtml(template);
      setBaseCss(style[0]);
    }
    
  }, [])

  const Singularity = () => {
    useEffect(async () => {
      const _configs = await getConfigs();
      let config = _configs.data.data;

      setMassiveEmail(
        config.find((element) => {
          return element.name === "massiveEmail.enabled";
        }).value
      );

    }, []);
  };

  Singularity();

  if (massiveEmail === 0) {
    history.replace("/")
  }

  /// --- end Singularity --- ///

  useEffect(() => {

    const getPost = async () => {
      // let query = [{ key: 'search', value: '', }, { key: 'page', value: '0', }, { key: 'active', value: '1', }];
      // const info = await GetPost(query);
      // setPostsQuery(_get(info, "data", []));
      // if(info.data.length >4){
      //   initGrapeJs(_get(info, "data", []))
      // }else{
      //   initGrapeJsOutPost(_get(info, "data", []))
      // }
      
      initGrapeJsOutPost();
    }
      getPost();
  }, [step === 1])

  const initGrapeJsOutPost = () => {
    const _editor = grapesjs.init({
      height: "calc(100vh - 120px)",
      container: '#gjs',
      fromElement: true,
      width: 'auto',
      storageManager: false,
      deviceManager: {
        devices: 
        [
          {
            name: 'Desktop',
            width: '', // default size
          }, {
            name: 'Mobile',
            width: '320px', // this value will be used on canvas width
            widthMedia: '480px', // this value will be used in CSS @media
          }
        ]
      },
      assetManager: {
        storageType: '',
        storeOnChange: true,
        storeAfterUpload: true,
        uploadFile: async (e) => {
          let arrayImages = [];
          var files = e.dataTransfer && e.dataTransfer.files ? e.dataTransfer.files[0] :  e.target.files;
          let file = files[0] ? files[0] : files;
          var formData = new FormData();
          formData.append("properties", "");
          formData.append("image", file);
          formData.append("thumbnail", file);
          let img = await postMediaArbitrary(formData);
          let image = { src: _get(img, "data[0].url", "") };
          arrayImages.push(image);
          _editor.AssetManager.add(arrayImages);
        }
      },
      panels: { 
        defaults: 
        [
          {
            id: 'panel-switcher',
            el: '.panel__switcher',
            buttons: [

              {
                id: 'show-style',
                active: true,
                label: 'Styles',
                command: 'show-styles',
                togglable: false,
              },
              {
                id: 'show-traits',
                active: true,
                label: 'Traits',
                command: 'show-traits',
                togglable: false,
              }
            ],
          },
          {
            id: 'panel-devices',
            el: '.panel__devices',
            buttons: [{
                id: 'device-desktop',
                label: svgDesktop,
                command: 'set-device-desktop',
                active: true,
                togglable: false,
              }, {
                id: 'device-mobile',
                label: svgMobile,
                command: 'set-device-mobile',
                togglable: false,
            }],
          }
        ]
      },
      traitManager: {
        appendTo: '.traits-container',
      },
      selectorManager: {
        appendTo: '.styles-container'
      },
      styleManager: {
        appendTo: '.styles-container',
        sectors: [
          {
            name: 'Basic',
            open: true,
            buildProps: [ "font-family", "font-size", "font-weight",  "letter-spacing", 
            "color", "text-align", "align-items" , "align-content", "text-decoration", "display", 'width', 'height' ],
          },
          {
            name: 'Extra',
            open: false,
            buildProps: ['background-color', 'padding', 'margin', 'border', 'border-radius', 'text-shadow'],
          }]
      },
      blockManager: {
        appendTo: '#blocks',
        blocks: [
          //Basic
          {
            id: "divider",
            label: `
            <div style="display: flex; flex-direction: column; align-items: center;">
              <b>${t('basicWords.divider')}</b>
              <img  style="margin-top: 6px; width: 25px; height: 22px" src="https://cdn-icons-png.flaticon.com/512/59/59549.png"/>
            </div>
            `,
            category: "Basic",
            activate: true,
            content: {
              content: `<hr class="rounded">`,
            },
          },
          {
            id: "cell",
            label: `
            <div style="display: flex; flex-direction: column; align-items: center;">
              <b>${t('basicWords.oneColumn')}</b>
              <img  style="width: 28px; height: 22px" src="https://cdn-icons-png.flaticon.com/512/8901/8901272.png" />
            </div>
            `,
            category: "Basic",
            activate: true,
            type: "cell",
            tagName: "td",
            content: `<table class="table" style="box-sizing: border-box; min-height: 60px; height: auto; margin: 0 auto; padding: 5px; min-width:100%;">
                        <tbody class="table" style="box-sizing: border-box; margin: 0 auto; text-align: center">
                          <tr class="table" style="box-sizing: border-box; text-align: center">
                            <td class="table" id="ivxq" style="box-sizing: border-box;font-weight: 300; vertical-align: top; margin: 0; padding: 0; width: 50%; border: 1px dotted rgb(111, 119, 125)">
                            </td>
                          </tr>
                        </tbody>
                      </table>`,
          },
          {
            id: "2cell",
            label: `
            <div style="display: flex; flex-direction: column; align-items: center;">
              <b>${t('basicWords.twoColumn')}</b>
              <img  style="margin-top: 6px; width: 28px; height: 22px" src="https://cdn-icons-png.flaticon.com/512/7530/7530072.png" />
            </div>
            `,
            category: "Basic",
            activate: true,
            type: "cell",
            tagName: "td",
            content: `<table class="table" style="text-align: left; box-sizing: border-box; min-height: 60px; height: auto; margin: 0 auto; padding: 5px; min-width:100%;">
                        <tbody class="table" style="box-sizing: border-box; margin: 0 auto; text-align: center;">
                          <tr class="table" style="box-sizing: border-box; margin: 0 auto; text-align: center;">
                            <td class="table2" style="box-sizing: border-box;  margin: 0; padding: 0; max-width: 50%; width: 50%; border: 1px dotted rgb(111, 119, 125);">
                            </td>
                            <td class="table2" style="box-sizing: border-box;  margin: 0; padding: 0; max-width: 50%; width: 50%; border: 1px dotted rgb(111, 119, 125);">
                            </td>
                          </tr>
                        </tbody>
                      </table>`,
          },
          //Tipography
          {
            id: "title",
            label: `
            <div style="display: flex; flex-direction: column; align-items: center;">
              <b>${t('basicWords.title')}</b>
              <img style="margin-top: 6px;  width: 25px; height: 22px" src="https://cdn-icons-png.flaticon.com/512/8089/8089962.png" />
            </div>
            `,
            category: "Typography",
            content: `<h1>${t('uhoo.mail.content.title')}</h1>`,
            style: {
              "text-align": "center",
              "margin": "0px 10px",
              "font-family": "Arial",
              "font-size": "30px",
              "width": "100%"
            }
          },
          {
            id: "subtitle",
            label: `
            <div style="display: flex; flex-direction: column; align-items: center;">
              <b>${t('basicWords.subtitle')}</b>
              <img style="margin-top: 6px;  width: 25px; height: 22px" src="https://cdn-icons-png.flaticon.com/512/8089/8089962.png" />
            </div>
            `,
            category: "Typography",
            content: `<h2>${t('uhoo.mail.content.subtitle')}</h2>`,
            style: {
              "text-align": "center",
              "margin": "0px 10px",
              "font-family": "Arial",
              "font-size": "25px",
              "width": "100%"
            }
          },
          {
            id: "text",
            category: "Typography",
            label: `
            <div style="display: flex; flex-direction: column; align-items: center;">
              <b>${t('basicWords.text')}</b>
              <img  style="margin-top: 6px; width: 25px; height: 22px" src="https://cdn-icons-png.flaticon.com/512/4021/4021596.png" />
            </div>
            `,
            content: `<h4>${t('uhoo.mail.content.text')}</h4>`,
            style: {
              "text-align": "center",
              "margin": "0px 10px",
              "font-family": "Arial",
              "font-size": "20px",
              "width": "100%"
            },
          },
          {
            id: 'section', 
            label: `
            <div style="display: flex; flex-direction: column; align-items: center;">
              <b>${t('basicWords.section')}</b>
              <img  style="margin-top: 6px; width: 28px; height: 22px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAbFBMVEX///8AAAA+Pj7i4uLo6OhLS0swMDDc3Nz19fW+vr7Pz8+urq74+Piqqqrx8fGZmZmSkpJ5eXmGhoZsbGyioqIZGRlZWVnExMRQUFCMjIwLCwu3t7fX19c2NjaUlJQqKipYWFgUFBRmZmYdHR1NfJ0KAAADjElEQVR4nO3d61LbMBCGYXIAEttJSGISciqn+7/HNtAOI8ktZGf1yWXe5wLW/nyQZP/QXl0BAAAAAAAAAAD8/+bb2fJheLrJ6TR8WM62kxLxqt3zQOd5V2njzdsbYbx3p3YuyzdZ7eX5zvYr0dM6KxLv3UyQb3xdMOBg8DDOHfBQNN/ZIW/ARel8vyxyBlyWTvdmmS/gunS239a5AvbjDp5luot9eAf/yPIuTkunCkz9A45LZ4r4z4vdE/16MT2OxvmMjtNF9/h27R2w7jpIhkel07Tr8ta+x5ikRxgefQ/xT8dhegK+y/C7pP6da/3iZzBPPpdUD+iHZCzfe97ENq4u/uJ+U8Un0ToWj7/o9XfwLP6uOfmVPkald36lL7KLzuPWrXL0ljteuwudwhP54Vb4MSysnCZC0av46FU3mgzdVxMXiKZ+r9F024dh5l002HgN6dGKzamqSROeitfKLfzyfXGqavMSnMvKqeomqOo3gFmEw/rGqWr4emf+m/eJ8EX0GvTCdX2JBduHcL4YOlUNE/otJCxuBQlHTlVtRiQ0IaESCW1IqERCGxIqkdCGhEoktCGhEgltSKhEQhsSKpHQhoRKJLQhoRIJbUioREIbEiqR0IaESiS0IaESCW1IqERCGxIqkdCGhEoktCGhEgltSKhEQhsSKpHQhoRKJLQhoRIJbUioREIbEiqR0IaESiS0IaESCW1IqERCGxIqkdCGhEoktCGhEgltSKikSPj99038jntf9mn/0nDHX6/9S8M9aNUtA0LhHrReO/6uslS1Ca+21z7C0V7QjVNZi2gvaK83pspT1iLTft7RhfMawCyijfXdHqeo+cPWq+7Fot3h/VoYRL2s3Dbsv1jUwsCvr1W4kPAbwi4Vt0VzXEDGnXqcOxF9UdyRyXNASBpzluiPkLTS8WzZObmPq+vvYtJT69W14VPaNy9jm8UuTdqa0Ld/XpP2x71XThrb5CEaPDuvrbp6A55qTX/eSX3qOLr7ULDpOMhgsFnV1W1OVb36y5G9A3Y0Jitqn6GFddIUrKgsfxpKdquOZZqs0gZ9pWTrdbP6/NgSGdfF/biLWbsV9eFdzNxBvio9aeyz/6+ddE/AKi+KZdQ0XSOq3Iu+2pr2tUi+11b3I7OpO/rzZjasxT9qx60y5LD178P9BU112K2frvN6Wu8OVcnf7AAAAAAAAAAAAAAAAADQJz8BhUU3u8ExoNYAAAAASUVORK5CYII=" />
            </div>
            `,
            category: "Typography",
            content: `<section>
              <h1>${t('uhoo.mail.content.sectionTitle')}</h1>
              <p>${t('uhoo.mail.content.sectionText')}</p>
            </section>`,
          },
          {
            id: "list",
            label: `
            <div style="display: flex; flex-direction: column; align-items: center;">
              <b>${t('basicWords.listWithNumber')}</b>
              <img  style="margin-top: 6px; width: 25px; height: 20px" src="https://cdn-icons-png.flaticon.com/512/901/901533.png"/>
            </div>
            `,
            category: "Typography",
            activate: true,
            content:` <div>
                <ol>
                  <li>${t('uhoo.mail.content.list1')}</li>
                  <li>
                  ${t('uhoo.mail.content.list2')}
                  </li>
                  <li>${t('uhoo.mail.content.list3')}</li>
                </ol>
              </div> `,
            style: {
              "text-align": "center",
              margin: "0px 10px",
              "font-size": "16px",
              "font-family": "Arial",
              padding: "5px",
            }
          },
          {
            id: "listTwo",
            label: `
            <div style="display: flex; flex-direction: column; align-items: center;">
              <b>${t('basicWords.list')}</b>
              <img  style="margin-top: 6px; width: 25px; height: 20px" src="https://cdn-icons-png.flaticon.com/512/901/901533.png"/>
            </div>
            `,
            category: "Typography",
            activate: true,
            content:` <div>
                <ul>
                  <li>${t('uhoo.mail.content.list1')}</li>
                  <li>
                  ${t('uhoo.mail.content.list2')}
                  </li>
                  <li>${t('uhoo.mail.content.list3')}</li>
                </ul>
              </div> `,
            style: {
              "text-align": "center",
              margin: "0px 10px",
              "font-size": "16px",
              "font-family": "Arial",
              padding: "5px",
            }
          },
          {
            id: "link",
            label: `
            <div style="display: flex; flex-direction: column; align-items: center;">
              <b>${t('basicWords.link')}</b>
              <img style="margin-top: 6px; width: 25px; height: 20px" src="https://cdn-icons-png.flaticon.com/512/2990/2990159.png" />
            </div>
            `,
            category: "Typography",
            editable: false,
            droppable: true,
            attributes:{href:'http://google.com'},
            content: {
              type: "link",
              content:  `<a>${t('uhoo.mail.content.link')}</a>`,
              style: {
                "display": "flex",
                "align-items": "center",
                "justify-content": "center",
                "text-align": "center",
                "margin": "0px 10px",
                "font-size": "16px",
                "font-family": "Arial",
                "padding": "5px",
              }
            },
          },
          // {
          //   id: "button",
          //   label: `
          //   <div style="display: flex; flex-direction: column; align-items: center;">
          //     <b>${t('basicWords.button')}</b>
          //     <img  style="margin-top: 6px; width: 25px; height: 22px" src="https://www.nicepng.com/png/detail/208-2084310_black-web-button-png.png"/>
          //   </div>
          //   `,
          //   category: "Typography",
          //   activate: true,
          //   content: {
          //     type: "link",
          //     content: `I am button `,
          //     style: {
          //       "text-decoration": "none", "display": "inline-block", "padding": "10px", "margin": "10px",
          //       "border-radius": "8px",
          //     }
          //   },
          // },
          //Media
          {
            id: "image",
            label: `
            <div style="display: flex; flex-direction: column; align-items: center;">
              <b>${t('basicWords.image')}</b>
              <img style="margin-top: 6px; width: 25px; height: 22px" src="https://cdn-icons-png.flaticon.com/512/739/739249.png"/>
            </div>
            `,
            activate: true,
            category: "Media",
            type: "link",
            content: [
              `<table class="table" style="box-sizing: border-box; min-height: 60px; height: auto; margin: 0 auto; padding: 5px; min-width:100%;">
                <tbody class="table" style="box-sizing: border-box; margin: 0 auto; text-align: center">
                  <tr class="table" style="box-sizing: border-box; text-align: center">
                    <td class="table" id="ivxq" style="box-sizing: border-box;font-weight: 300; vertical-align: top; margin: 0; padding: 0; width: 50%; border: 1px dotted rgb(111, 119, 125)">
                      <a href=""><img src="" href="" /></a>
                    </td>
                  </tr>
                </tbody>
              </table>`,
            ],
          },
        ]
      },
    });

    _editor.Panels.addPanel({
      id: 'panel-top',
      el: '.panel__top',
    });

    _editor.Commands.add('show-traits', {
      getTraitsEl(editor) {
        const row = editor.getContainer().closest('.editor-row');
        return row.querySelector('.traits-container');
      },
      run(editor, sender) {
        this.getTraitsEl(editor).style.display = '';
      },
      stop(editor, sender) {
        this.getTraitsEl(editor).style.display = 'none';
      },
    });

    _editor.Commands.add('show-layers', {
      getRowEl(editor) { return editor.getContainer().closest('.editor-row'); },
      getLayersEl(row) { return row.querySelector('.layers-container') },

      run(editor, sender) {
        const lmEl = this.getLayersEl(this.getRowEl(editor));
        lmEl.style.display = '';
      },
      stop(editor, sender) {
        const lmEl = this.getLayersEl(this.getRowEl(editor));
        lmEl.style.display = 'none';
      },
    });

    _editor.Commands.add('show-styles', {
      getRowEl(editor) { return editor.getContainer().closest('.editor-row'); },
      getStyleEl(row) { return row.querySelector('.styles-container') },

      run(editor, sender) {
        const smEl = this.getStyleEl(this.getRowEl(editor));
        smEl.style.display = '';
      },
      stop(editor, sender) {
        const smEl = this.getStyleEl(this.getRowEl(editor));
        smEl.style.display = 'none';
      },
    });

    _editor.Commands.add('set-device-desktop', {
      run: editor => editor.setDevice('Desktop')
    });

    _editor.Commands.add('set-device-mobile', {
      run: editor => editor.setDevice('Mobile')
    });

    if(_editor){
      _editor.setComponents(baseHtml);
      _editor.setStyle(baseCss);
      if(baseHtml == ''){
        let baseHtmlDafultText = `<h1>${t('basicWords.textDefaultBlock')}</h1>`;
        _editor.setComponents(baseHtmlDafultText);
      }
     }

    _editor.on('component:mount', (model) => { 
      let nodeName = model.views[0].$el[0].nodeName;
      // currentSrc
      if(nodeName != 'TD' && nodeName != 'TR' && nodeName != 'TBODY' && nodeName != 'TABLE'){
        const randomUUID = generateUUID();
        model.setClass(`style-${randomUUID}`);
      }
     });
     
    setEditor(_editor)
  }

  const GetChannelData = async (query) => {
    const info = await getChannels(query);
    return (_get(info, "data", []))
  }

  const toBackPage = () => {
    // let css = editor.getCss().replace('max-width', 'max-device-width');
    let css = editor.getCss();
    let classTable;
    if(step == 2){
      classTable = `
      .table { 
        text-align: center;
        box-sizing: border-box; 
        min-height: 60px; 
        height: auto; 
        margin-left: auto;
        margin-right: auto;
        padding: 5px; 
        width:100%; 
      }
      .table2 {
        max-width: 50%;
        width: 50%;
      }
      body {
        background: white;
      }
      `
    }else{
      classTable = `
      .table { 
        text-align: center;
        box-sizing: border-box; 
        min-height: 60px; 
        height: auto; 
        margin-left: auto;
        margin-right: auto;
        padding: 5px; 
        width:100%; 
       }
       .table2 {
        width: 50%;
        max-width: 50%;
      }
       .body {
        background: #F1F1F2;
       }
      `
    }
    
    let finalCss = `${css}${classTable}`
    setBaseCss(finalCss);
    setBaseHtml(editor.getHtml());
    setStep(step - 1)
  }

  const saveEmail = () => {
    let css = editor.getCss().replace('max-width', 'max-device-width');
    let classTable = `
    .table { 
      text-align: center;
      box-sizing: border-box; 
      min-height: 60px; 
      height: auto; 
      margin-left: auto;
      margin-right: auto;
      padding: 5px; 
      width:100%; 
     }
    .table2 {
      max-width: 50%;
      width: 50%;
    }
    body {
      background: #F1F1F2;
     }
    `
    let finalCss = `${css}${classTable}`
    setBaseCss(finalCss);

    let htmlToExport = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <title>Email Sample</title>
      <meta http–equiv=“Content-Type” content=“text/html; charset=UTF-8” />
      <meta http–equiv=“X-UA-Compatible” content=“IE=edge” />
      <meta name=“viewport” content=“width=device-width, initial-scale=1.0 “ />
      <style>${finalCss}</style>
    </head>
    <body>
      ${editor.getHtml()}
    </body>
    </html>
    `;
    setBaseHtml(editor.getHtml());
    setEmail(htmlToExport);
    setStep(step + 1);
  }

  const sendEmail = async () => {
    setIsLoadingPost(true);
    if (audienceSelected.length === 0) { // selectedAudiencesVerify
      setShowNotificationWarning(true);
      setIsLoadingPost(false);
    } else {
      try {
        var channelDataMain = await GetChannelData("main");
        let dataToSend = {
          channel_origin: channelDataMain[0].uuid,
          content: email,
          hasMedia: 0,
          title: subjectEmail,
          type: "m",
          files: [],
          enableOmnichannel: true,
        };
        let infoPost = await publishPost(dataToSend);
        setShowNotificationSuccessfull(true);
        let publishObj = {};
        audienceSelected.forEach(item => {
          publishObj[item] = [infoPost.data[0].uuid];
        });
        let publish = {
          mediums: [],
          notification: true, //omnichannel
          publish: publishObj,
          unpublish: {},
          pushNotification: false
        }
        await publishChannelPost(publish);
        setTimeout(()=>{setShowNotificationSuccessfull(false);}, 2000);
        setIsLoadingPost(false);
        setTimeout(()=>{history.goBack();}, 1000);
      } catch (error) {
        setShowNotificationSuccessfull(false);
        setShowNotificatioError(true);
        setIsLoadingPost(false);
      }
    }
  }

  const configOpeModalCanCreate = () => {
    setTextModalCantCreate(t('uhoo.home.cards.emailCard.cardTitle'));
    setOpenModalCantCreate(true);
  };

  let URLactual = window.location;
  URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
  let isAura = URLactual.includes("aura");

  return (
    <React.Fragment>
      <section className="TitleEmail">
        <div className="titleArrow">
          <BackArrow className="ico pointerCursor" onClick={() => history.goBack()} />
          <h3>{t('uhoo.mail.title')}</h3>
        </div>
        <div className="createPostActions">
          <div className="schedule" role="button" onClick={() => step == 0 ? history.goBack() : toBackPage()}>{t('basicWords.back')} </div>
          {
            step != 3 ?
              <div className="toPost" role="button" onClick={() => step == 1 ? lengthEmail != null && lengthEmailCreated >= lengthEmail && isAura ? configOpeModalCanCreate() : saveEmail() 
                                                                    : lengthEmail != null && lengthEmailCreated >= lengthEmail && isAura ? configOpeModalCanCreate() : setStep(step + 1)}>{t('basicWords.next')}</div>
              : null
          }
          {
            step == 3 ?
            <>
            { isLoadingPost ?
            <div className="toPostDisabled" role="button">
              <CircularProgress size={20} className='buttonLoading'/>
            </div>
            :
            <div className="toPost" role="button" onClick={() => sendEmail()}>{t('basicWords.publish')}</div>
          }
            </>
              : null
          }
        </div>
      </section>
      <div>
        {/* {postsQuery.length == 0 ?
          <section className="widgetListContainer">
            <CircularProgress />
          </section>
        : */}
          <section className="previewEmail">
            {step == 0 ?
              <div className="containerRowsEmail">
                <div className="secondRow">
                  <div className="PostformTitles">{t('uhoo.mail.subjectPlaceholder')}</div>
                  <input type="text" placeholder={t('uhoo.mail.subjectPlaceholder')} className="inputForms" id="subjectEmail"  defaultValue={subjectEmail} onChange={(e) => setSubjectEmail(e.target.value)} />
                </div>
              </div>
              : null}
            {step == 1 ?
              <div className="containerRowsEmail">
                <div className="panel__top">
                  <div className="panel__switcher"></div>
                  <div className="panel__devices"></div>
                  <div className="panel__basic-actions"></div>
                </div>
                <div className="editor-row">
                  <div className="panel__right">
                    <div className="layers-container"></div>
                    <div className="styles-container"></div>
                    <div className="traits-container"></div>
                  </div>
                  <div className="editor-canvas">
                    <div id="gjs"/>
                  </div>
                  <div id="blocks" />
                </div>
              </div>
              : null}
            {step == 2 ?
              <div className="containerRows3">
                <div className="containerHtml">
                  <div dangerouslySetInnerHTML={{ __html: email  }} />
                </div>
              </div>
            : null}
            {step == 3 ?
              <div className="containerRows3">
                <UsersCommunityProvider>
                  <Tabs>
                    <TabList>
                      <Tab>{t('uhoo.community.tab2')}</Tab>
                    </TabList>
                    <TabPanel>
                      <div className="audienceCommunity communityContent">
                        <AudienceCommunity showActions={true} setAudienceSelected={setAudienceSelected} setChannelPublish={setChannelPublish} />
                      </div>
                    </TabPanel>
                  </Tabs>
                </UsersCommunityProvider>
              </div>
            : null}
          </section>
        {/* } */}
      </div>
      
      

      {
        !!showNotificationWarning && (
          <SystemNotification
            setShowNotification={setShowNotificationWarning}
            type="Warning"
            caption={t('basicWords.mustSelectAudiences')} 
          />
        )
      }
      {
        !!showNotificationSuccessfull && (
          <SystemNotification
            setShowNotification={setShowNotificationSuccessfull}
            type="Success"
            caption="Se ha publicado el post"
          />
        )
      }
      {
        !!showNotificatioError && (
          <SystemNotification
            setShowNotification={setShowNotificatioError}
            type="Error"
            caption="No se ha publicado el post"
          />
        )
      }
      {openModalCantCreate ? (
        <Modal>
          <CantCreate
            setOpenModal={setOpenModalCantCreate}
            text={textModalCantCreate}
          />
        </Modal>
      ) : null}


    </React.Fragment >
  );
};

export default CreateMail;