import * as React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import moment from "moment";

// Localization
import { useTranslation } from "react-i18next";

export function DatePickerComponent(props) {
  const date = moment(props.defaultValue).format("YYYY-MM-DD");
  const { t, i18n } = useTranslation();
  const stringDate = props.string ? props.string : t("basicWords.date");
  return (
    <Stack component="form" noValidate spacing={3}>
      <TextField
        id="date"
        label={stringDate}
        disabled={props.disabled}
        type="date"
        defaultValue={date}
        onChange={props.onChange}
        sx={{ width: 250 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Stack>
  );
}
