import React from "react";
// import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
//Localization
import { LocalizeSection } from "../../reactUtils/systemVariables/languageLocalization/SectionsLocalization.jsx";
import { Link } from "react-router-dom";
import { Trash2 } from "react-feather";
import { withTranslation } from "react-i18next";

class InternalDragDrop extends React.Component {
  render() {
    var className = "containerTask";
    // var isDragDisabledValue = false;
    // var subpage = "";
    let isGroupSubapge = null;
    let flagHiddeIconDelete = true;
    if (
      this.props.section.name === "HEADER" ||
      this.props.section.name === "HOME" ||
      this.props.section.name === "CONTACT" ||
      this.props.section.name === "FOOTER"
    ) {
      // isDragDisabledValue = true;
      className = "containerTask-Disabled";
      flagHiddeIconDelete = false;
    }

    let subpageId = null;
    let subpageIntoGroupSubpage = null;
    // let sectionId = `column-${this.props.section.id}`;
    if (this.props.subpage) {
      if (this.props.subpage.id.length > 7) {
        subpageId = parseInt(
          this.props.subpage.id.substring(7, this.props.subpage.id.length)
        );
      } else {
        subpageId = parseInt(this.props.subpage.id);
      }

      if (this.props.subpage.style === "subpage") {
        className = "containerTaskIntoSubpage";
      }
      if (this.props.subpage.style === "groupSubpage") {
        isGroupSubapge = true;
        className = "containerTaskIntoGroupSubpage";
      }
    }
    if (this.props.section) {
      subpageIntoGroupSubpage = this.props.section;
      subpageIntoGroupSubpage.style = "subpage";
      subpageIntoGroupSubpage.sectionsIds = [];
      if (subpageIntoGroupSubpage.sections) {
        subpageIntoGroupSubpage.sections.forEach((element) => {
          subpageIntoGroupSubpage.sectionsIds.push(element.id);
        });
      }
    }

    const { t } = this.props;

    return (
      <Draggable
        draggableId={`${this.props.section.id}`}
        index={this.props.index}
      >
        {(provided, snapshot) => (
          <div
            className={className}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isdraggingover={
              snapshot.isDraggingOver
                ? snapshot.isDraggingOver.toString()
                : null
            }
          >
            {isGroupSubapge ? (
              <span style={{ color: "#11AB5B" }}>
                {this.props.section.customName && this.props.section.customName !== " "
                  ? this.props.section.customName
                  : decodeURIComponent(
                      LocalizeSection(this.props.section.name, t)
                    )}
              </span>
            ) : null}
            {this.props.subpage.style === "mainpage" ? (
              <div>
                <div
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <div>
                    <Link
                      to={{
                        pathname: "/edit" + this.props.section.name,
                        props: {
                          id: this.props.section.id,
                          sectionName: this.props.section.name,
                          sectionId: this.props.section.id,
                          registers: this.props.registers,
                          landingPageId: this.props.state.lpId,
                          organizationId: this.props.state.organizationId,
                          organizationName: this.props.state.organizationName,
                          country: this.props.state.country,
                          region: this.props.state.region,
                          city: this.props.state.city,
                          subpageId: this.props.subpage ? subpageId : null,
                          subpageFather: this.props.subpage,
                          previousPath: this.props.previousPath,
                          breadcrumb: this.props.breadcrumb,
                          lpUrl: this.props.lpUrl,
                        },
                      }}
                    >
                      {this.props.section.customName && this.props.section.customName !== " "
                        ? this.props.section.customName
                        : decodeURIComponent(
                            LocalizeSection(this.props.section.name, t)
                          )}
                    </Link>
                  </div>
                  <div className="containerIconDeleteSection">
                    {flagHiddeIconDelete ? (
                      <div className="iconDeleteSectionMainpage">
                        <Trash2
                          size={18}
                          onClick={(e) =>
                            this.props.handleClickOpenDeleteSection(
                              this.props.section
                            )
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            {this.props.subpage.style === "subpage" ? (
              <div
                style={{
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <Link
                  to={{
                    pathname: "/edit" + this.props.section.name,
                    props: {
                      id: this.props.section.id,
                      sectionName: this.props.section.customName,
                      sectionId: this.props.section.id,
                      registers: this.props.registers,
                      landingPageId: this.props.state.lpId,
                      organizationId: this.props.state.organizationId,
                      organizationName: this.props.state.organizationName,
                      country: this.props.state.country,
                      region: this.props.state.region,
                      city: this.props.state.city,
                      subpageId: this.props.subpage ? subpageId : null,
                      subpageFather: this.props.subpage,
                      previousPath: this.props.previousPath,
                      breadcrumb: this.props.breadcrumb,
                      lpUrl: this.props.lpUrl,
                    },
                  }}
                >
                  {this.props.section.customName && this.props.section.customName !== " "
                    ? this.props.section.customName
                    : decodeURIComponent(
                        LocalizeSection(this.props.section.name, t)
                      )}
                </Link>
                <div className="containerIconDeleteSection">
                  <div className="iconDeleteSectionSubpage">
                    <Trash2
                      size={18}
                      onClick={(e) =>
                        this.props.handleClickOpenDeleteSection(
                          this.props.section
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </Draggable>
    );
  }
}

export default withTranslation()(InternalDragDrop);
