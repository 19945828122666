import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
//import CheckBox from './aura-components/checkBox.jsx';
import CheckBox from './checkBox.jsx';

//import Tag from './aura-components/tag.jsx';
import Tag from './tag.jsx';


//Localization
import { useTranslation } from 'react-i18next';

import { ReactComponent as OpenDrop } from '../../../../assets/icons/navigation/openDropdown.svg';

import { ReactComponent as Search } from '../../../../assets/icons/createPost/search.svg';
import { ReactComponent as Add } from '../../../../assets/icons/createPost/add.svg';

import "./css/audienceSelect.css";

// Queries
import { getAudience, usersByAudience } from "../../../../actions/uhoo/Audience.actions";
import { forEach } from "lodash";

//var selectAudience = [];
var selectAudienceList = null;
let searcherAudience = [];
var selectedItems = [];

function AudienceSelect(props) {

  //let setAudience = props.setAudiencePublish;

  const { t, i18n } = useTranslation(); // Localization

  const [selectAudience, setAudience] = useState([]); // array de los uuid de las audiencias selecionadas 
  const [AudienceData, setAudienceData] = useState([]); // array de consulta, contiene las audiencias activas de la consulta GetAudienceData([{ key: 'active', value: '1' }])
  const [forSearch, setForSearch] = useState(""); // string buscador de audiencias
  const [deleteTag, setDeleteTag] = useState(""); // string id, elimina el tag seleccioando

  const [audienceSelectAll, setAudienceSelectAll] = useState("");

  const [openDrop, setOpenDrop] = useState(false);
  const [readyQuery, setReadyQuery] = useState(false);

  useEffect(() => {
    try {
      if (props.setAudienceSelectAll !== undefined) {
        props.setAudienceSelectAll(audienceSelectAll);      
      }
    } catch (error) {
      //console.warn(error);
    }
  }, [audienceSelectAll]);

  /// ---------- Queries ---------- ///

  const getUsersByAudience = async (audience) => {
    let _users = await usersByAudience(audience.uuid)
    return _users.data;
  }

  function timeout(ms) { return new Promise(resolve => setTimeout(resolve, ms)); }

  const GetAudienceData = (query) => {
    useEffect(async () => {
      const info = await getAudience(query);
      let filtroActive = info.data.filter(audience => audience.active === 1)
      let filtroMedium = filtroActive.filter(audience => audience.properties.mediums !== undefined);
      let finalAudience = [];
      let usersInAudience;
      filtroMedium.forEach(async audience => {
        usersInAudience = await getUsersByAudience(audience);
        if(usersInAudience && props.user){
          usersInAudience.forEach(userInAudience => {
            if(userInAudience.uuid == props.user.uuid) finalAudience.push(audience);
          });
        }
      });
      let super_admin = JSON.parse(sessionStorage.getItem("super_admin"));
      setAudienceData(props.user && !!super_admin ? finalAudience : filtroMedium);
      if(props.dataAudiences) props.dataAudiences(finalAudience);
      if(props.setReadyAudiences){
        await timeout(1500);
        props.setReadyAudiences(true);
      }

      if (props.channelPublish || selectAudience.length > 0) {
        setAudience(props.channelPublish.length > 0 ? props.channelPublish : selectAudience);      
        let selectItems = props.channelPublish.length > 0 ? props.channelPublish : selectAudience;
        let matches = document.querySelectorAll('input.inputSelect-audience');
        selectItems.forEach(itemSelected => {
          matches.forEach(select => {
            if(select.id == itemSelected){
              select.checked = true;
            }
          });
        });
        observerSelectAudience();
      }

    }, []);
    
    return AudienceData;
  };

  GetAudienceData([{ key: 'page_size', value: '500' }])

  /// ---------- End Queries ---------- ///

  function SelectAllState(state) {
    try {
      if (!props.setAudienceSelectAll !== null) {
        props.setAudienceSelectAll(state)
      }
    } catch (error) {
      //console.warn(error);
    }
  }

  function handleClick(event) {
    alert('clicked');
    event.preventDefault();
    event.stopPropagation();
  }

  function audienceDrop() { // despliega o cierra el dorp de audiencias 
    document.getElementById("audienceDropdown").classList.toggle("show");
    document.getElementById("actionDrop-audience").classList.toggle("open");
    setOpenDrop(!openDrop);
  }

  function findAudienceChanel(uuidAudience) {
    const audience = AudienceData.filter(item => item.uuid === uuidAudience);
    if (audience.length > 0) {
      return (audience[0].channel.uuid);
    } else {
      console.log("La audiencia no tiene canales", uuidAudience)
      return ("");
    }
  }

  function observerSelectAudience() { // verifica que audiencias estan seleccioandas y las guarda en array --> selectAudience
    let selectItems = [];
    let matches = document.querySelectorAll('input.inputSelect-audience');
    matches.forEach(select => (select.checked) ? selectItems.push(select.id) : null); // guarda uuid en array   
    let audienceChannel = [];
    try {
      props.setAudienceUUID(selectItems)
    } catch (error) {
      console.log(error)
    }
    selectItems.forEach(audience => audienceChannel.push(findAudienceChanel(audience)));
    if(props.setChannelPublish) props.setChannelPublish(selectItems);
    if(props.setAudiencePublish) props.setAudiencePublish(audienceChannel);
    setAudience(selectItems);
  }

  function observerSelectAll(event) {  // verifica el # de audiecias selecioandas para activar el check de selecioanr todas   
    // for Select All option  ---------------------------------------------------------
    let matches = document.querySelectorAll('input.inputSelect-audience');
    let selectAll = document.querySelectorAll('input.inputSelectAll-audience')[0];
    let selectItem = 0; // contador # de audiencias selecioandas 
    matches.forEach(select => (!select.checked) ? selectAll.checked = false : selectItem++);
    if (matches.length === selectItem) { selectAll.checked = true; setAudienceSelectAll(true) } else {
      setAudienceSelectAll(false)
    }

    observerSelectAudience();

    event.stopPropagation();
  }

  function ChangeAudienceSelectAll() { // activa o desactiva todas las audiencias con el checkbox "Selecionar todas"
    let select = document.querySelectorAll('input.inputSelectAll-audience');
    let stateSelect = select[0].checked;

    SelectAllState(stateSelect);

    if (stateSelect) {
      let matches = document.querySelectorAll('input.inputSelect-audience');
      matches.forEach(select => select.checked = true);
      setAudienceSelectAll(true)
    }

    if (!stateSelect) {
      let matches = document.querySelectorAll('input.inputSelect-audience');
      matches.forEach(select => select.checked = false);
      setAudienceSelectAll(false)
    }

    observerSelectAudience();
  }

  selectedItems = selectAudience.map(item => AudienceData.find(select => select.uuid === item));  // cruce de los uui de las audiecnias selecioanadas con los valores de la cosulta 
  selectedItems.map((item, index)=>  {
    if(!item){
      delete selectedItems[index];
    } 
  })
  /// ---------- Delete Tag ---------- ///

  if (deleteTag != "") {

    let matches = document.querySelectorAll('input.inputSelect-audience');
    matches.forEach(select => (select.id === deleteTag) ? select.checked = false : null);

    document.querySelectorAll('input.inputSelectAll-audience')[0].checked = false;

    setAudienceSelectAll(false)
    setDeleteTag("");
    observerSelectAudience();
  }
  /// ---------- End Delete Tag ---------- ///

  /// ---------- Searcher ---------- ///

  if (!forSearch.length > 0) {
    //document.querySelectorAll('div.audienceSelectAll')[0].style.display = "flex";

    let matches = document.querySelectorAll('div.checkBox-audience')
    matches.forEach(select => select.style.display = "flex");

  } else {
    //document.querySelectorAll('div.audienceSelectAll')[0].style.display = "none";       

    let matches = Array.from(document.querySelectorAll('div.checkBox-audience'));
    matches.forEach(select => select.style.display = "none");

    searcherAudience = AudienceData.filter(audience => {
      const audienceName = audience.name.toLowerCase();
      const searchText = forSearch.toLowerCase();
      return audienceName.includes(searchText);
    });

    searcherAudience.forEach(found => {
      //console.log(found.uuid, found.name);
      let container = document.querySelector('#dropList-audience');
      //console.log("container", container);
      container.querySelectorAll('#div' + found.uuid)[0].style.display = "flex";
    });
  }

  const onSearchValueChange = (event) => {
    setForSearch(event.target.value);
  };

  /// ---------- End Searcher ---------- ///  

  document.addEventListener('click', function (event) { // cerrar drop al dar click por fuera del elementos

    if (event.target.className !== "audienceSearch" && event.target.className !== "inputSearch" && event.target.className !== "audienceList" && event.target.className !== "optionDrop" && event.target.className !== "checkBox" && event.target.className !== "audienceAdd" && event.target.className !== "checkBox-audience checkBox" && event.target.className !== "actionDrop open" && event.target.className !== "audienceSelectAll" && event.target.className.baseVal !== "openDropAudience") {
      let get = document.getElementById("audienceDropdown");
      try {
        if (get !== null) { // pevencion de errores // get.classList.remove("show");
          get.classList.remove("show");
          document.getElementById("actionDrop-audience").classList.remove("open");
        } 
      } catch (error) {
        console.error(error);
      }
  } 
    event.stopPropagation();
  });

  return (
    <React.Fragment>

      <div className="select">
        <div className="contentSelect">
          {selectAudience.length === 0
            ? <p>{t('uhoo.createPost.form.audienceSelect')}</p>
            : selectedItems.map(item => (<Tag key={item.uuid} name={item.name} uuid={item.uuid} deleteTag={deleteTag} setDeleteTag={setDeleteTag} />))
          }
        </div>
        <div className="actionDrop" id="actionDrop-audience" onClick={audienceDrop}>
          <OpenDrop className="openDropAudience"/>
        </div>
      </div>

      <div id="audienceDropdown" className="audienceDropdown-content" >
        <div className="audienceSearch">
          <Search className="icoSearch" />
          <input type="search" className="inputSearch" placeholder={t('uhoo.createPost.form.audienceSearch')} onChange={onSearchValueChange} />
        </div>
        <div className="audienceList">
          <div className="audienceSelectAll" id="SelectAllOption">
            <input className="inputSelectAll-audience" type="checkbox" onChange={ChangeAudienceSelectAll} />
            <label>
              {t('uhoo.createPost.form.audienceSelectAll')}
            </label>
          </div>
          <div className="optionDrop" onChange={observerSelectAll} id="dropList-audience">
            {AudienceData.length > 0 ? 
              AudienceData.map(item => (<CheckBox key={item.uuid} name={item.name} uuid={item.uuid} type={"audience"} />))
            : 
              <>
              {AudienceData.length == 0 ?
                <div style={{'width': '90%', 'display': 'flex', 'justify-content': 'center',  'align-items': 'center'}}>
                  <p>{t('uhoo.activity.addColumn.filter.audience.noFound')}</p>
                </div>
                :
                <div style={{ position: 'absolute', left: '155px'}}><CircularProgress /></div>
              }
              </>
            }
          </div>
        </div>

        {/*<hr className="lines"/>*/}

        {/* <div className="audienceAdd">
          <Add className="ico" />
          <h5 className="text">{t('uhoo.createPost.form.audienceSelectAdd')}</h5>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default AudienceSelect;