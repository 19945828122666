import axios from 'axios';

import { host, port, route, datasource } from '../../reactUtils/systemVariables/CmsServerInformation';

const resource = "/landingpage";

export async function getLandingPageSections(organizationId) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            OrganizationId: organizationId,
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error
   }
}

export async function getLandingPageInfoByUrl(lpUrl) 
{
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            url: lpUrl,
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error
   }
}

export async function getAllLandingPagesPaged(
   page,
   size,
   attribute,
   order
) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            page: page,
            size: size,
            attribute: attribute,
            order: order
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error
   }
}

export async function getLandingPagesByOrganizationIdPaged(
   organizationId,
   page,
   size,
   attribute,
   order
) {
   try {
      let response = await axios.get(
         host + port + route + datasource + resource, {
         params: {
            OrganizationId: organizationId,
            page: page,
            size: size,
            attribute: attribute,
            order: order
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error
   }
}