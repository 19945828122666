import axios from "axios";

import {
  host,
  port,
  route,
  datasource,
} from "../../reactUtils/systemVariables/CmsServerInformation";

const resource = "/lpsubpagegroup";

export async function createLpSubpageGroupByLpId(lpId, LpSubpageGroupDto) {
  try {
    const data = JSON.stringify(LpSubpageGroupDto);
    let response = await axios({
      method: "POST",
      url: host + port + route + datasource + resource,
      params: {
        lpId: lpId,
      },
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}


export async function updateLpSubpageGroupByLpId(LpSubpageGroupDto) {
  try {
    const data = JSON.stringify(LpSubpageGroupDto);
    let response = await axios({
      method: "PUT",
      url: host + port + route + datasource + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}