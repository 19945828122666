import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import es from "./es.json";
import en from "./en.json";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: en,
      es: es,
    },
    // fallbackLng: 'en',
    /* debugger For Development environment */
    debug: true,
    //  Add the Namespace: to separate translations
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    // It enables to integrate dynamic values into translations
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    // Handle rendering while translations are not yet loaded
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
      useSuspense: false
    },
  });
export default i18n;
