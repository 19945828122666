import React, { Fragment } from "react";
import { Tooltip } from "@material-ui/core";
import { Info } from "react-feather";

import "./css/Title.scss";

export function Title({ title, tooltip }) {
  return tooltip ? (
    <Fragment>
      <h2 className="title">{title}</h2>
      <Tooltip title={tooltip}>
        <Info />
      </Tooltip>
    </Fragment>
  ) : (
    <h2 className="title">{title}</h2>
  );
}
