import axios from 'axios';
import moment from 'moment';

import { host, port, route, datasource } from '../../reactUtils/systemVariables/CmsServerInformation';

const resource = "/payment";

export async function getPaymentByOrganizationId(_params) {
    try {
        console.log(_params)
        let response = await axios.get(
            host + port + route + datasource + resource, {
            params: _params,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
            }
        })
      console.log(response)

        return response;
    } catch (error) {
        return error;
    }
}