import React, {useState} from "react";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';

// Queries
import { getLibrary_v1 } from "../../actions/uhoo/index";

//thumbnail
var itemData = [
  {
    thumbnail: '',
    uuid: '',
  },
];

const UserLibrary = () => {

  const [loaded, setLoaded] = useState(false);

  async function getData() {
    try {
      let info = await getLibrary_v1();   

      //itemData = info.data;       
  
      if( info.length != 0) {
        itemData = info.data;

        //console.log("consulta > 0");

        console.log(info.data);
        setLoaded(true);
      }

    } catch (err) {
      console.error(err);
    }
  }

  const info = getData();
  //console.log(itemData);
  //window.alert("Hello world!");
  /// ///sx={{ width: 500, height: 450 }}

  return (

    <div>
      <br />
      <p> Biblioteca </p>
      <br />

      

    {loaded && <ImageList cols={6} rowHeight={164}>
      <ImageListItem>
      </ImageListItem>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.thumbnail}?w=248&fit=crop&auto=format`}
            srcSet={`${item.thumbnail}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.uuid}
            onerror="this.src='../../assets/images/NotAvailableImg.png';"
            loading="lazy"
          />
          <ImageListItemBar
            
            actionIcon={
              <IconButton 
              onClick={() => window.alert(item.uuid)}
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.uuid}`}
                
              >
                <GetAppIcon  />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
    }
    </div>


  );
  
};

export default UserLibrary;

