import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { ChromePicker } from "react-color";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as BackArrow } from '../../../../assets/icons/navigation/backArrow.svg';
import ButtonsContainer from "../../../../aura-components/ButtonsContainer.jsx";
// Queries
import {
  getInformationBySectionIdAndLPId,
  updateSectionComponent,
  getFile,
  updateLpSectionComponentNodeImage,
  getSectionLayoutStyle,
  updateLpSectionLayout,
  updateLpSection,
} from "../../../../actions/aura";

// Components
import {
  Title,
  BreadcrumbSection,
  GeneralInput,
  AccordionComponent,
  ButtonComponent,
  GeneralModal,
  ButtonsFooterComponent
} from "../../../../aura-components";
import SelectLayoutModal from "../../../../aura-components/SelectLayoutModal.jsx";

import SystemNotification from "../../../global/SystemNotification";
import { handleChangeImageFunction } from "../../../../reactUtils/generalUtils/handleImageChange.jsx";

//Localization
import { withTranslation } from "react-i18next";

import { Plus, X } from "react-feather";
import {
  Card,
  CircularProgress,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  Tooltip
} from "@material-ui/core";

import "../css/sections.scss";
import "../css/linkTreeSection.css";

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;

class LinkTreeSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,

      containerTitle: { id: null, name: "", maxLength: null },
      backImg: {
        id: null,
        s3path: null,
        imageUrl: null,
        image: null,
        file: null,
        loading: false,
      },
      backColor: null,
      buttonsColor: null,
      linksShown: 0,
      openInNewTab: true,
      buttons: [],

      displayBackColorPicker: false,
      displayButtonsColorPicker: false,

      landingPageId: null,
      organizationId: null,
      organizationName: null,
      country: null,
      region: null,
      city: null,
      sectionId: null,
      sectionName: null,

      loadingSection: true,

      parentLPSectionComponentId: null,
      isLoadingButton: false,
      buttonContainerLoading: true,
      FlagClassName: false,
      goToWithoutSaveModalVisible: false,
      lpLayoutImg: null,
      lpSectionId: null,
      haveChangeInLayout: false,

      // Modals
      showSuccess: false,
      showWarning: false,
      showError: false,
      contentNotificationModal: "",

      //Modal general
      openDeleteImage: false,
      openDeleteNews: false,
      elementToDeleteId: null,

      //LayoutSelector
      newLayoutId: null,
      oldLayoutId: null,

      isReadyToEdit: false,
      addNotice: false,

      //Name modal
      openModalToChangeName: false,
      customName: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { location } = this.props;
    const { props } = location;

    if (props) {
      this.getLinkTreeInformation(props.landingPageId, props.sectionId);
      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
      this.handleSelectLayout();
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  async updateLpSection(LpSubpageDto) {
    let response = await updateLpSection(LpSubpageDto);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
    }
    this.setState({
      newCustomName: "",
      customName: LpSubpageDto.customName,
      openModalToChangeName: false,
    });
  }

  // Queries
  async getLinkTreeInformation(lpId, lpSectionId) {
    this.setState({
      loadingSection: true,
    });
    const response = await getInformationBySectionIdAndLPId(
      lpId,
      lpSectionId,
      "LINKTREE"
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      const { backImg } = { ...this.state };
      const sectionInfo = response.data.data;

      const containerComponent = sectionInfo.components.find(
        (comp) => comp.name === "LinkTreeContainer"
      );
      const parentLPSectionComponentId = containerComponent.id;
      const containerTitle = containerComponent.nodes.find(
        (node) => node.nodeType === "LinkTreeContainerTitle"
      );
      const backColor = containerComponent.nodes.find(
        (node) => node.nodeType === "LinkTreeContainerBackgroundColor"
      );
      const buttonsColor = containerComponent.nodes.find(
        (node) => node.nodeType === "LinkTreeButtonsColor"
      );
      const linksShown = containerComponent.nodes.find(
        (node) => node.nodeType === "LinkTreeContainerMaxButtonsShown"
      );
      const openInNewTab = containerComponent.nodes.find(
        (node) => node.nodeType === "LinkTreeNewTabFlag"
      );
      openInNewTab.value =
        containerComponent.nodes.find(
          (node) => node.nodeType === "LinkTreeNewTabFlag"
        ).value === "1"
          ? true
          : false;

      const lpSectionComponentIdPinnedButtons = sectionInfo.components.find(
        (comp) => comp.name === "LinkTreePinnedComponent"
      ).id;

      const lpSectionComponentIdTempButtons = sectionInfo.components.find(
        (comp) => comp.name === "LinkTreeTempComponent"
      ).id;

      const pinnedButtonsList = sectionInfo.components.find(
        (comp) => comp.name === "LinkTreePinnedComponent"
      ).buttons
        ? sectionInfo.components.find(
          (comp) => comp.name === "LinkTreePinnedComponent"
        ).buttons
        : [];

      const tempButtonsList = sectionInfo.components.find(
        (comp) => comp.name === "LinkTreeTempComponent"
      ).buttons
        ? sectionInfo.components.find(
          (comp) => comp.name === "LinkTreeTempComponent"
        ).buttons
        : [];

      backImg.id = containerComponent.nodes.find(
        (node) => node.nodeType === "LinkTreeContainerBackgroundImage"
      )
        ? containerComponent.nodes.find(
          (node) => node.nodeType === "LinkTreeContainerBackgroundImage"
        ).id
        : null;
      backImg.s3path = containerComponent.nodes.find(
        (node) => node.nodeType === "LinkTreeContainerBackgroundImage"
      )
        ? containerComponent.nodes.find(
          (node) => node.nodeType === "LinkTreeContainerBackgroundImage"
        ).value
        : null;
      backImg.imageUrl = null;
      backImg.image = null;
      backImg.loading = true;

      // Get images
      if (backImg.s3path !== "") {
        const imgResponse = await getFile(
          "https://somosaura-cms.s3.amazonaws.com/" + backImg.s3path
        );
        backImg.imageUrl = imgResponse;
        backImg.image = imgResponse;
        backImg.file = imgResponse;
        backImg.loading = false;
      } else {
        backImg.s3path = null;
        backImg.image = null;
        backImg.imageUrl = null;
        backImg.imageLoading = false;
      }

      let pinnedButtons = [];
      let tempButtons = [];

      if (pinnedButtonsList.length > 0) {
        //map pinned buttons with isPinned:1 so they can be shown as well
        pinnedButtonsList.forEach((b) => {
          const button = {
            id: b.id,
            name: b.name,
            externalLink: b.externalLink,
            internalLpSectionComponentId: b.internalLpSectionComponentId,
            internalLpSectionComponentName: b.internalLpSectionComponentName,
            internalLpSectionId: b.internalLpSectionId,
            internalLpSectionName: b.internalLpSectionName,
            lpSectionComponentId: b.lpSectionComponentId,
            isEnabled: b.isEnabled,
            isPinned: 1,
            pinnedChanged: 0,
          };
          pinnedButtons.push(button);
        });
      }

      if (tempButtonsList.length > 0) {
        //map temp buttons with isPinned:0 so they can be shown as well
        tempButtonsList.forEach((b) => {
          const button = {
            id: b.id,
            name: b.name,
            externalLink: b.externalLink,
            internalLpSectionComponentId: b.internalLpSectionComponentId,
            internalLpSectionComponentName: b.internalLpSectionComponentName,
            internalLpSectionId: b.internalLpSectionId,
            internalLpSectionName: b.internalLpSectionName,
            lpSectionComponentId: b.lpSectionComponentId,
            isEnabled: b.isEnabled,
            isPinned: 0,
            pinnedChanged: 0,
          };
          tempButtons.push(button);
        });
      }

      //Get layout image
      let supgage = null;
      if (this.state.subpageId && this.state.subpageId !== 10001) {
        supgage = sectionInfo.subpages.find(
          (sp) => sp.id === this.state.subpageId
        );
      } else {
        supgage = sectionInfo.subpages.find((sp) => sp.id === null);
      }
      let subpageIndex = sectionInfo.subpages.indexOf(supgage);
      if (subpageIndex === -1) {
        subpageIndex = 0;
      }
      let layout =
        response.data.data.subpages[subpageIndex].lpSectionLayoutStyle;
      let layoutImg = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" + layout.s3ImagePath
      );

      let customName =
        response.data.data.customName !== null
          ? response.data.data.customName
          : this.state.sectionName;

      this.setState({
        customName,
        containerTitle: {
          id: containerTitle.id,
          name: containerTitle.value,
          maxLength: parseInt(containerTitle.maxLength, 10),
        },
        backImg,
        backColor: { id: backColor.id, value: backColor.value },
        buttonsColor: { id: buttonsColor.id, value: buttonsColor.value },
        linksShown: {
          id: linksShown.id,
          value: parseInt(linksShown.value, 10),
        },
        openInNewTab,
        buttons: pinnedButtons.concat(tempButtons).slice(0, linksShown.value),
        buttonContainerLoading: true,
        parentLPSectionComponentId,
        loadingSection: false,
        lpSectionComponentIdPinnedButtons,
        lpSectionComponentIdTempButtons,
        lpLayoutImg: layoutImg,
        lpSectionLayoutStyle: layout,
      });
    }
  }

  async uploadFileToS3Bucket(imageUrl, key) {
    await updateLpSectionComponentNodeImage(imageUrl, key);
  }

  async updateSectionComponentFunction(
    containerNodesToAdd,
    pinnedButtonsToAdd,
    tempButtonsToAdd
  ) {
    const { landingPageId, lpSectionId } = this.state;

    const response = await updateSectionComponent(containerNodesToAdd);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
        isLoadingButton: false,
      });
    } else {
      const response = await updateSectionComponent(pinnedButtonsToAdd);
      if (String(response).includes("Error:")) {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
          isLoadingButton: false,
        });
      } else {
        const response = await updateSectionComponent(tempButtonsToAdd);
        if (String(response).includes("Error:")) {
          this.setState({
            showError: true,
            contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
            isLoadingButton: false,
          });
        } else {
          this.setState({
            showSuccess: true,
            contentNotificationModal: t("basicWords.modal.newsSuccessfullyUpdate"),
            containerTitle: { id: null, name: "", maxLength: null },
            backImg: {
              id: null,
              s3path: null,
              imageUrl: null,
              image: null,
              loading: false,
            },
            backColor: null,
            buttonsColor: null,
            linksShown: 0,
            buttons: [],
            buttonContainerLoading: false,
          });
          this.forceUpdate();
          this.getLinkTreeInformation(landingPageId, lpSectionId);
        }
      }
    }
  }

  async getLayoutFunction(lpId) {
    let response = await getSectionLayoutStyle(lpId, 13);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.fieldEmpty"),
        isLoadingButton: false,
      });
    } else {
      let responseData = response.data.data;
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].sampleLayoutImageS3Path) {
          //Get image
          let img = await getFile(
            "https://somosaura-cms.s3.amazonaws.com/" +
            responseData[i].sampleLayoutImageS3Path
          );
          responseData[i].img = img;
        }
      }
      this.setState({
        layoutData: responseData,
      });
    }
  }

  // Linktree events
  linksShownAmount = (e) => {
    let { linksShown } = { ...this.state };

    linksShown.value = e.target.value;

    this.setState({
      linksShown,
      FlagClassName: true,
    });
  };

  // Color picker events
  handleColorClick = (e, dataIndex) => {
    if (dataIndex === "backColor") {
      this.setState({
        displayBackColorPicker: !this.state.displayBackColorPicker,
      });
    } else {
      this.setState({
        displayButtonsColorPicker: !this.state.displayButtonsColorPicker,
      });
    }
  };

  handleColorClose = () => {
    this.setState({
      displayBackColorPicker: false,
      displayButtonsColorPicker: false,
    });
  };

  handleSelectLayout() {
    this.getLayoutFunction(this.props.location.props.landingPageId);

    this.setState({ layoutModalVisible: true });
  }

  setLayoutModalVisible = (isVisible) => {
    this.setState({ layoutModalVisible: isVisible });
  };

  setColors = (color, dataIndex) => {
    let { backColor } = { ...this.state };
    let { buttonsColor } = { ...this.state };

    if (dataIndex === "backColor") {
      backColor.value = color.hex;
    } else {
      buttonsColor.value = color.hex;
    }

    this.setState({
      backColor,
      buttonsColor,
      FlagClassName: true,
    });
  };

  handleButtonListChange = (buttonList) => {
    let { buttons } = { ...this.state };
    buttons = buttonList;
    this.setState({ FlagClassName: true, buttons });
  };

  async updateLpSectionLayoutFunction(lpSectionId, newLayoutId) {
    let response = await updateLpSectionLayout(lpSectionId, newLayoutId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
    }
    this.setState({ isLoadingButton: false });
  }

  warning = () => {
    const { t } = this.props;
    this.setState({
      showWarning: true,
      contentNotificationModal: t("basicWords.modal.noSectionSelected"),
    });
    this.handleOkWarning();
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleChange = (event) => {
    const { value } = event.target;
    const { containerTitle } = { ...this.state };

    if (value.length <= containerTitle.maxLength) {
      containerTitle.name = value;
    }

    this.setState({
      containerTitle,
      FlagClassName: true,
    });
  };

  onCheckboxChange = (e) => {
    const { openInNewTab } = { ...this.state };
    openInNewTab.value = e.target.checked;
    this.setState({
      openInNewTab,
      FlagClassName: true,
    });
  };

  handleChangeImage = async (event) => {
    const { country, city, organizationId } = this.state;
    var backImg = { ...this.state.backImg };
    var fileUpload = event.target.files[0];
    let extension;
    if (fileUpload !== undefined) {
      extension = fileUpload.type.replace("image/", "");
    }
    const random = uuidv4();
    const key = `content/${country}/${city}/${organizationId}/img${random}.${extension}`;
    if (!extension) {
      backImg.loading = true;
      backImg.file = null;
      backImg.image = null;

      this.setState({
        backImg,
        FlagClassName: true,
      });
      return;
    }

    if (key !== undefined) {
      // Function to resize and reduce image quality
      // Save image in AWS

      let flag = true;
      backImg = await handleChangeImageFunction(
        backImg,
        fileUpload,
        extension,
        key,
        1800,
        1000,
        600,
        400
      ).catch((res) => {
        const { t } = this.props;
        this.setState({
          showError: true,
          contentNotificationModal: `${t(`${res.content}`).replace("R1", res.params.R1).replace("R2", res.params.R2)}`
        });
        flag = false;
      });

      if (flag !== false) {
        if (backImg.loading === false) {
          this.uploadFileToS3Bucket(backImg.file, key);
          this.setState({
            backImg,
            FlagClassName: true,
          });
          this.forceUpdate();
        }
      }
    }
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  saveLinkTreeEvent = (e, flag) => {
    const {
      parentLPSectionComponentId,
      landingPageId,
      containerTitle,
      buttons,
      backImg,
      backColor,
      buttonsColor,
      lpSectionComponentIdPinnedButtons,
      lpSectionComponentIdTempButtons,
      linksShown,
      openInNewTab,
      haveChangeInLayout,
      lpSectionId,
      lpSectionLayoutStyle,
    } = this.state;
    const { t } = this.props;

    this.setState({
      loadingSection: true,
      FlagClassName: false,
      openDeleteImage: false,
    });

    let pinnedButtons = buttons.filter((b) => b.isPinned === 1);
    if (pinnedButtons.length > 0) {
      pinnedButtons = pinnedButtons.map((b) => {
        const button = {
          id: b.id ? b.id : null,
          name: b.name,
          externalLink: b.externalLink,
          internalLpSectionComponentId: b.component
            ? b.component.id
            : b.internalLpSectionComponentId
              ? b.internalLpSectionComponentId
              : null,
          isEnabled: b.isEnabled,
        };
        return button;
      });
    }

    let tempButtons = buttons.filter((b) => b.isPinned === 0);
    if (tempButtons.length > 0) {
      tempButtons = tempButtons.map((b) => {
        const button = {
          id: b.id ? b.id : null,
          name: b.name,
          externalLink: b.externalLink,
          internalLpSectionComponentId: b.component
            ? b.component.id
            : b.internalLpSectionComponentId
              ? b.internalLpSectionComponentId
              : null,
          isEnabled: b.isEnabled,
        };
        return button;
      });
    }

    //if()

    const nodesToAdd = [];

    const containerNodesToAdd = {
      // --- Con esto se crea el registro lpSectionComponent ---
      lpId: landingPageId, // Este valor se obtiene de la vista anterior
      lpSectionId: this.state.lpSectionId,
      sectionId: 13, // Valor estático para la sección de linktree
      sectionComponentId: 21, // Valor estático para el LinkTreeContainer
      id: parentLPSectionComponentId,
      // --- Con el Id del lpSectionComponent creado, se crean los nodos de este componente
      nodesToAdd,
      buttons: [],
    };

    const pinnedButtonsToAdd = {
      // --- Con esto se crea el registro lpSectionComponent ---
      lpId: landingPageId, // Este valor se obtiene de la vista anterior
      lpSectionId: this.state.lpSectionId,
      sectionId: 13, // Valor estático para la sección de linktree
      sectionComponentId: 22, // Valor estático para el LinkTreePinnedComponent
      id: lpSectionComponentIdPinnedButtons,
      parentLPSectionComponentId, //
      nodesToAdd: [],

      buttons: pinnedButtons,
    };

    const tempButtonsToAdd = {
      // --- Con esto se crea el registro lpSectionComponent ---
      lpId: landingPageId, // Este valor se obtiene de la vista anterior
      lpSectionId: this.state.lpSectionId,
      sectionId: 13, // Valor estático para la sección de linktree
      sectionComponentId: 23, // Valor estático para el LinkTreeTempComponent
      id: lpSectionComponentIdTempButtons,
      parentLPSectionComponentId,
      nodesToAdd: [],

      buttons: tempButtons,
    };

    if (haveChangeInLayout) {
      this.updateLpSectionLayoutFunction(lpSectionId, lpSectionLayoutStyle);
    }

    if (containerTitle.name != null) {
      const titleNode = {};
      titleNode.id = containerTitle.id ? containerTitle.id : null;
      titleNode.sectionComponentNodeId = 74;
      titleNode.value = containerTitle.name;
      titleNode.nodeType = "LinkTreeContainerTitle";

      nodesToAdd.push(titleNode);

      let dto = {
        id: lpSectionId,
        customName: containerTitle.name,
        isEnabled: 1,
      };
      this.updateLpSection(dto);
    }
    if (backImg.modified) {
      const backImageNode = {};

      backImageNode.id = backImg.id ? backImg.id : null;
      backImageNode.sectionComponentNodeId = 75;
      backImageNode.value = backImg.value;
      backImageNode.nodeType = "LinkTreeContainerBackgroundImage";

      nodesToAdd.push(backImageNode);
    }
    if (flag) {
      const backImageNode = {};

      backImageNode.id = backImg.id ? backImg.id : null;
      backImageNode.sectionComponentNodeId = 75;
      backImageNode.value = "";
      backImageNode.nodeType = "LinkTreeContainerBackgroundImage";

      nodesToAdd.push(backImageNode);
    }
    if (backColor != null) {
      const backColorNode = {};

      backColorNode.id = backColor.id ? backColor.id : null;
      backColorNode.sectionComponentNodeId = 76;
      backColorNode.value = backColor.value;
      backColorNode.nodeType = "LinkTreeContainerBackgroundColor";

      nodesToAdd.push(backColorNode);
    }
    if (linksShown) {
      const linksShownNode = {};

      linksShownNode.id = linksShown.id ? linksShown.id : null;
      linksShownNode.sectionComponentNodeId = 77;
      linksShownNode.value = linksShown.value;
      linksShownNode.nodeType = "LinkTreeContainerMaxButtonsShown";

      nodesToAdd.push(linksShownNode);
    }
    if (openInNewTab) {
      const openInNewTabNode = {};

      openInNewTabNode.id = openInNewTab.id;
      openInNewTabNode.sectionComponentNodeId = 97;
      openInNewTabNode.value = openInNewTab.value === true ? 1 : 0;
      openInNewTabNode.nodeType = "LinkTreeNewTabFlag";

      nodesToAdd.push(openInNewTabNode);
    }
    if (buttonsColor != null) {
      const buttonsColorNode = {};

      buttonsColorNode.id = buttonsColor.id ? buttonsColor.id : null;
      buttonsColorNode.sectionComponentNodeId = 78;
      buttonsColorNode.value = buttonsColor.value;
      buttonsColorNode.nodeType = "LinkTreeButtonsColor";

      nodesToAdd.push(buttonsColorNode);
    }

    if (nodesToAdd.length > 0) {
      this.updateSectionComponentFunction(
        containerNodesToAdd,
        pinnedButtonsToAdd,
        tempButtonsToAdd
      );
    } else {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.fieldEmpty"),
        isLoadingButton: false,
      });
    }
  };

  handleClickOpenModalToChangeName = (itemToChangeName) => {
    let open = this.state.openModalToChangeName;
    this.setState({
      openModalToChangeName: !open,
      customName: itemToChangeName,
    });
  };

  OnChangeSectionCustomName = (e) => {
    let newCustomName = this.state.newCustomName;
    newCustomName = e.target.value;

    this.setState({
      newCustomName,
      FlagClassName: true,
    });
  };

  saveChangeName = () => {
    const { t } = this.props;
    var { containerTitle, newCustomName, lpSectionId } = this.state;

    //Update lpsection custom name
    let dto = {
      id: lpSectionId,
      customName: newCustomName,
      isEnabled: 1,
    };
    this.updateLpSection(dto);

    //Update section title name
    const nodesToUpdate = [];
    let errorInput = false;

    if (containerTitle) {
      if (newCustomName === "") {
        errorInput = true;
      } else {
        containerTitle.name = newCustomName;
        containerTitle.value = newCustomName;
      }
      delete containerTitle.edited;
      nodesToUpdate.push(containerTitle);
    }

    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateSectionComponentFunction(nodesToUpdate, null, null);
      } else {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.fieldEmpty"),
          isLoadingButton: false,
        });
      }
    }
  };

  //-----------Update page after modal------------
  updatePage = (lpSectionId, previousPath) => {
    const { landingPageId, startDate, endDate } = this.state;
    this.getEventsInformation(landingPageId, lpSectionId, startDate, endDate);
    this.setState({
      previousPath,
    });
    this.setState({ layoutModalVisible: false });
    this.forceUpdate();
  };

  handleChangeLayout = (e, id) => {
    this.setState({
      // newLayoutId: id,
      saveButtonDisabled: false,
      FlagClassName: true,
      lpSectionLayoutStyle: id,
      haveChangeInLayout: true,
    });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  handleClickOpenDeleteImage = () => {
    this.setState({ openDeleteImage: true });
  };

  handleCloseDeleteImage = () => {
    this.setState({ openDeleteImage: false });
  };

  handleClickOpenDeleteNews = (id) => {
    this.setState({ openDeleteNews: true, elementToDeleteId: id });
  };

  handleCloseDeleteNews = () => {
    this.setState({ openDeleteNews: false });
  };

  setShowError = (open) => {
    this.setState({ showError: open });
  };

  setShowSuccess = (open) => {
    this.setState({ showSuccess: open });
  };

  setShowWarning = (open) => {
    this.setState({ showWarning: open });
  };

  render() {
    const {
      landingPageId,
      redirectBack,
      isLoadingButton,
      organizationId,
      containerTitle,
      backImg,
      backColor,
      buttonsColor,
      buttons,
      linksShown,
      buttonContainerLoading,
      name,
      subpageId,
      organizationName,
      sectionName,
      FlagClassName,
      openInNewTab,
      lpUrl,
      lpSectionLayoutStyle,
      layoutData,

      openNotificationModal,
      isReadyToEdit,
      openDeleteImage,

      openModalToChangeName,
      customName,
      goToWithoutSaveModalVisible,

      // Modals
      showSuccess,
      showWarning,
      showError,
      contentNotificationModal
    } = this.state;

    const { t } = this.props;

    if (redirectBack) {
      return <Redirect to="/manage/web" />;
    }

    const cover = {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    };

    return (
      <>
        {!!showWarning && (
          <SystemNotification
            setShowNotification={(e) => this.setShowWarning(e)}
            type="Warning"
            caption={contentNotificationModal}
          />
        )}
        {!!showSuccess && (
          <SystemNotification
            setShowNotification={(e) => this.setShowSuccess(e)}
            type="Success"
            caption={contentNotificationModal}
          />
        )}
        {!!showError && (
          <SystemNotification
            setShowNotification={(e) => this.setShowError(e)}
            type="Error"
            caption={contentNotificationModal}
          />
        )}

        <GeneralModal
          visible={openModalToChangeName}
          title={"Cambiar nombre"}
          body={[
            <div className="col-12" style={{ margin: "5px" }}>
              <div className="row">
                <div className="col-md-12 componentSpace">
                  <TextField
                    variant="outlined"
                    style={{ width: "100%", marginLeft: 10 }}
                    label={t("basicWords.section")}
                    onChange={(e) => this.OnChangeSectionCustomName(e)}
                    defaultValue={
                      customName ? decodeURIComponent(customName) : ""
                    }
                  />
                </div>
              </div>
            </div>,
          ]}
          footer={[
            <div className="row buttonAtTheBottom">
              <div>
                <ButtonComponent
                  onClick={(e) => this.handleClickOpenModalToChangeName()}
                  shape="round"
                  text={t("basicWords.cancel")}
                  className="buttonDiscard"
                />
              </div>
              <div className="col-6">
                <ButtonComponent
                  isDisabled={false}
                  onClick={() => this.saveChangeName()}
                  shape="round"
                  text={t("basicWords.ok")}
                />
              </div>
            </div>,
          ]}
          onClick={() => this.handleClickOpenModalToChangeName()}
        />

        <section className="Title">
          <BackArrow className="ico pointerCursor" />
          <h3>Página Web</h3>
        </section>
        <section className="sectionContainer">
          <Card className="card">
            {isLoadingButton === true ? (
              <div
                className="col-xs-12 col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  transform: "translateY(800%)",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="row componentSpace spaceBreadcrumb">
                  <BreadcrumbSection
                    principal={t("sections.linkTree")}
                    secondary={organizationName}
                    route="./manage/web"
                    propsComponent={{
                      organizationId,
                      sectionName: sectionName,
                      nameSubpage: name ? name : null,
                      lpId: landingPageId,
                      subpageId: subpageId ? subpageId : null,
                      lpUrl: lpUrl,
                      customName,
                    }}
                    handleClickOpenModalToChangeName={
                      this.handleClickOpenModalToChangeName
                    }
                    FlagclassName={FlagClassName}
                    onClick={(e) => this.saveAboutEvent(e)}
                    GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                    onCancelGotoWithoutMModal={(e) =>
                      this.onCancelGotoWithoutMModal(e)
                    }
                    goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                  />

                </div>

                <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                  <Title
                    title={t("basicWords.completeInformationOfYourSection")}
                  />
                </div>

                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <SelectLayoutModal
                    layoutData={layoutData}
                    lpLayoutId={lpSectionLayoutStyle ? lpSectionLayoutStyle : null}
                    handleChange={this.handleChangeLayout}
                  />
                </div>

                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <AccordionComponent
                    title={t("generalUser.sectionContent")}
                    body={[
                      <div className="accordionContent">
                        {/* First Line */}
                        <div className="accordionCenterLine">
                          <div
                            style={{
                              width: "40vw",
                              marginRight: "5%",
                              marginLeft: "1%",
                            }}
                          >
                            <GeneralInput
                              disabled={isReadyToEdit}
                              className="textInputWidth"
                              placeholder={t("basicWords.enterTitle")}
                              id="title"
                              onChange={(e, id) => this.handleChange(e, id)}
                              value={
                                containerTitle.name ? containerTitle.name : null
                              }
                            />
                            {containerTitle.value && containerTitle.maxLength ? (
                              <span>
                                {containerTitle.length} /{" "}
                                {containerTitle.maxLength}
                              </span>
                            ) : null}
                          </div>
                          <div style={{ width: "20vw" }}>
                            <GeneralInput
                              disabled={isReadyToEdit}
                              className="textInputWidth"
                              placeholder={t("linktree.linksShown")}
                              id={linksShown ? linksShown.nodeTypeName : null}
                              onChange={(e) => this.linksShownAmount(e)}
                              value={linksShown.value}
                            />
                          </div>
                        </div>

                        {/* Second Line */}
                        <br />
                        <div className="accordionCenterLine">
                          {/* <div style={{ width: "12vw", marginLeft: "1%" }}>
                            <Button
                              style={{
                                background: backColor ? backColor.value : null,
                              }}
                              shape="round"
                              onClick={(e) =>
                                this.handleColorClick(e, "backColor")
                              }
                            >
                              {t("basicWords.colorBackground")}{" "}
                            </Button>
                            {this.state.displayBackColorPicker && backColor ? (
                              <div className="popover">
                                <div
                                  style={cover}
                                  onClick={this.handleColorClose}
                                />
                                <ChromePicker
                                  onChangeComplete={(e) =>
                                    this.setColors(e, "backColor")
                                  }
                                  color={backColor.value}
                                />
                              </div>
                            ) : null}
                          </div> */}

                          {/* <div
                            style={{
                              width: "15vw",
                              display: "flex",
                              alignitems: "center",
                            }}
                          >
                            <div
                              className="textLogo"
                              style={{ marginRight: "10%" }}
                            >
                              {t("basicWords.image")}
                            </div>
                            <div className="cardImagesSquare">
                              <div className="row">
                                <div className="iconXCardImagesSquare">
                                  <a
                                    onClick={() =>
                                      this.handleClickOpenDeleteImage()
                                    }
                                  >
                                    <X className="iconImageEditInSectionSquare" />
                                  </a>
                                </div>
                              </div>
                              <div className="row">
                                
                                <Tooltip
                                  title={t("generalMessages.imagesFormat")}
                                >
                                  <div className="centerImage">
                                    <input
                                      accept="image/*"
                                      id="contained-button-file"
                                      multiple
                                      type="file"
                                      onChange={(e) => this.handleChangeImage(e)}
                                      hidden
                                    />
                                    <label htmlFor="contained-button-file">
                                      <div className="imageEdit">
                                        <div>
                                          {backImg.file !== "" ? (
                                            <img
                                              src={backImg.file}
                                              alt="avatar"
                                              className="widthAndHeightImageSectionSquare"
                                              onChange={(e) =>
                                                this.handleChangeImage(e)
                                              }
                                            />
                                          ) : (
                                            <Plus
                                              className="imageEdit"
                                              onChange={(e) =>
                                                this.handleChangeImage(e)
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </Tooltip>
                              </div>

                              <GeneralModal
                                visible={openDeleteImage}
                                title={t("basicWords.deleteItem")}
                                footer={[
                                  <div
                                    className="row"
                                    key={1}
                                    style={{ display: "flex" }}
                                  >
                                    <div className="col-6">
                                      <ButtonComponent
                                        onClick={() =>
                                          this.handleCloseDeleteImage()
                                        }
                                        props={null}
                                        shape="round"
                                        text={t("basicWords.cancelText")}
                                      />
                                    </div>
                                    <div>
                                      <ButtonComponent
                                        onClick={() =>
                                          this.saveLinkTreeEvent(true, true)
                                        }
                                        props={null}
                                        shape="round"
                                        text={t("basicWords.okText")}
                                      />
                                    </div>
                                  </div>,
                                ]}
                                onClick={() => this.handleCloseDeleteImage()}
                              />
                            </div>
                          </div> */}

                          {/* <div style={{ width: "15vw" }}>
                            <Button
                              style={{
                                background: buttonsColor
                                  ? buttonsColor.value
                                  : null,
                              }}
                              shape="round"
                              onClick={(e) =>
                                this.handleColorClick(e, "buttonsColor")
                              }
                            >
                              {t("basicWords.buttonColor")}{" "}
                            </Button>
                            {this.state.displayButtonsColorPicker &&
                              buttonsColor ? (
                              <div className="popover">
                                <div
                                  style={cover}
                                  onClick={this.handleColorClose}
                                />
                                <ChromePicker
                                  onChangeComplete={(e) =>
                                    this.setColors(e, "buttonsColor")
                                  }
                                  color={buttonsColor.value}
                                />
                              </div>
                            ) : null}
                          </div> */}

                          <div style={{ width: "25vw" }}>
                            <FormControlLabel
                              value={openInNewTab.value}
                              control={<Checkbox />}
                              onChange={(e) => this.onCheckboxChange(e)}
                              label={t("linktree.openInNewTab")}
                              labelPlacement="end"
                            />
                          </div>
                        </div>
                      </div>,
                    ]}
                  />
                  <br />
                  <div style={{ marginBottom: "4%" }}>
                    <AccordionComponent
                      title={t("linktree.links")}
                      body={[
                        <div className="col-xs-12 col-md-12">
                          {landingPageId && buttons ? (
                            <ButtonsContainer
                              isModalVisible={buttonContainerLoading}
                              lpId={landingPageId}
                              buttonList={buttons}
                              handleButtonListChange={(buttonList) =>
                                this.handleButtonListChange(buttonList)
                              }
                              linkTreeFlag={true}
                            />
                          ) : (
                            ""
                          )}
                        </div>,
                      ]}
                    />
                  </div>
                </div>
              </>
            )}
          </Card>
        </section>

        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName: sectionName,
            nameSubpage: name ? name : null,
            lpId: landingPageId,
            subpageId: subpageId ? subpageId : null,
            lpUrl: lpUrl,
          }}
          FlagclassName={FlagClassName}
          onClick={(e) => this.saveLinkTreeEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />


      </>
    );
  }
}
export default withTranslation()(LinkTreeSection);
