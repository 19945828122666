import React, { Component, useState } from "react";

import { ReactComponent as PaginationBack } from '../../../../assets/icons/navigation/paginationBack.svg';
import { ReactComponent as PaginationForward } from '../../../../assets/icons/navigation/paginationForward.svg';

import "./css/pagination.css";

// Queries

const Pagination = (props) => {

  const setPageShow = props.setPageShow;

  const pageShow = props.pageShow;
  const pageEnd = props.pageEnd;

  function back() {
    if (pageShow > 1) {
      setPageShow(pageShow - 1);
    }
  }

  function forward() {
    if (pageShow < pageEnd) {
      setPageShow(pageShow + 1);
    }
  }

  return (
    <div className="paginationContainer2">
      <div className="paginationBack paginationActions" onClick={back} role="button" id="paginationBack">
        <PaginationBack className="paginationICO" role="button" />
      </div>
      <div className="paginationText">
        {pageShow} / {pageEnd}
      </div>
      <div className="paginationForward paginationActions" onClick={forward} role="button" id="paginationForward">
        <PaginationForward className="paginationICO" role="button" />
      </div>
    </div>
  );
};

export default Pagination;