import React, { useEffect, useState } from "react";
import Modal from "../../uhoo-components/Modal.jsx";
import ConfigureUser from "./modals/configureUser.jsx";
import ConfigureCommunity from "./modals/configureCommunity";
import Overlap from '../../uhoo-components/overlap.jsx';
import "./css/header.css";
import Icon from '../../uhoo-components/iconLibrary.jsx';
import { ReactComponent as Logo } from '../../assets/brand/logo.svg';
import { get as _get } from "lodash";
import { getAuth } from "firebase/auth";
// import { loginURL } from "../../reactUtils/systemVariables/CmsServerInformation";
import CircularProgress from '@mui/material/CircularProgress';
//Localization
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { ReactComponent as Search } from "../../assets/icons/navigation/search.svg";

import logoHorAura from "../../assets/images/logo-aura-hor.png";

// Queries
import { getCustomToken, getOwn, getUser } from "../../actions/uhoo"

const HeaderComponent = ({setLoading}) => {

  const { t, i18n } = useTranslation(); //  Localization

  const [openDropdownProfile, setOpenDropdownProfile] = useState(false);
  const [valueLanguage, setValueLanguage] = useState(i18n.language);

  const [openModalCommunityConfig, setOpenModalCommunityConfig] = useState(false);
  const [openModalPerfilConfig, setOpenModalPerfilConfig] = useState(false);

  const [activeLogo, setActiveLogo] = useState("");
  const [UserQuery, setUserQuery] = useState([]);
  const [userLogged, setUserLogged] = useState({});
  const [finalConmmunities, setFinalConmmunities] = useState([]);
  const [baseFinalConmmunities, setBaseFinalConmmunities] = useState([]);
  let history = useHistory(); //  from 'react-router-dom' -> la uso para avanzar o retroceder en el historial de navegacion 

  if(i18n.language != 'en' && i18n.language != 'es'){
    i18n.changeLanguage('en')
  }

  useEffect(() => {
    const GetUserData = async (query) => {
      try {
        const info = await getUser(query);
        setUserQuery(info.data);
        let _user = JSON.parse(sessionStorage.getItem("own"));
        let _userLogged
        if(Array.isArray(UserQuery)) {
          _userLogged = UserQuery.filter((user) => user.uuid == _user.uuid);
        }
        // sessionStorage.setItem("own", JSON.stringify(_userLogged[0]));
        setUserLogged(_userLogged[0]);
      } catch(err) {
        console.log(err)
      }
    };

    GetUserData([
      { key: "active", value: "1" },
      { key: "page_size", value: 500 },
    ]);

    let _communities = _get(_user, "communities", []);
    _communities.sort(compareNames);
    let _finalConmmunities = [];
    _communities.map(comm => {
      if(comm.active == 1 && _user.active == 1 && comm.uuid != "9f4a816e-3e89-4e5d-ba09-eac9b45ade98") _finalConmmunities.push(comm);
    })

    setFinalConmmunities(_finalConmmunities)
    setBaseFinalConmmunities(_finalConmmunities)

  }, [openDropdownProfile]);
  let _user = JSON.parse(sessionStorage.getItem("own"));
  var language; // para visualizacion 
  const compareNames = (a, b) => {
    let nameA = a.name;
    let nameB = b.name;
    if(nameA == null  || nameB == null) return 0
    let result = nameA.localeCompare(nameB);
    return result;
  }
  
  let community = _get(_user, "community", {});

  useEffect(() => {
    let URLactual = window.location;
    URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
    let isAura = URLactual.includes("aura");
    if (isAura) {
      setActiveLogo(<img style={{"maxHeight": "30px"}} src={logoHorAura} alt="Header Aura" />);
      const favicon = document.getElementById("favicon"); //Change favicon in Uhoo CMS
      favicon.href = "/logo1.ico";
      document.title = "Somos Aura | CMS";
    } else {
      setActiveLogo(<Logo />);
    }
  }, []);

  const changeToSpanish = (e) => {
    let newLang = "es";
    setValueLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  const changeToEnglish = (e) => {
    let newLang = "en";
    setValueLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  const changeLanguage = (function () {
    if (valueLanguage === 'es') {
      language = (
        <div className="changeLanguage" onClick={changeToEnglish}>
          {t('uhoo.language.spanish')}
        </div>
      );
    }

    if (valueLanguage === 'en') {
      language = (
        <div className="changeLanguage" onClick={changeToSpanish}>
          {t('uhoo.language.english')}
        </div>
      );
    }
  })();

  document.addEventListener('click', function (event) { // cerrar drop al dar click por fuera
    if (event.target.className === "ModalOverlap") {
      setOpenDropdownProfile(!openDropdownProfile);
    }
    event.stopPropagation();
  });

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  };

  const changeComm = async (comm) => {
    setLoading(true);
    setOpenDropdownProfile(false);
    history.push('/');
    if (comm.uuid == community.uuid) return;
    const auth = getAuth();
    let idToken;
    if (auth.currentUser) {
      idToken = await auth.currentUser.getIdToken(true); // refresh id token from firebase
    } else {
      idToken = JSON.parse(sessionStorage.getItem("userInfo"));
      idToken = _get(idToken, "stsTokenManager.accessToken", "");
    }
    let URLactual = window.location;
    let bundleName;
    if(_get(URLactual, "host", "uhoo.io")  == "shaio.uhoo.io"){ //config Shaio
      bundleName = "io.uhoo.shaio";
    } else if(_get(URLactual, "host", "uhoo.io")  == "vanti.uhoo.io"){ //config Vanti
      bundleName = "io.uhoo.vanti";
    } else if(_get(URLactual, "host", "uhoo.io")  == "porvenir.uhoo.io"){ //config Porvenir
      bundleName = "io.uhoo.porvenir";
    } else if(_get(URLactual, "host", "uhoo.io")  == "tulia.uhoo.io"){ //config Tulia
      bundleName = "io.uhoo.tulia";
    } else if(_get(URLactual, "host", "uhoo.io")  == "cedej.aurapp.org"){ //config Cedej
      bundleName = "org.somosaura.cedej";
    } else if(_get(URLactual, "host", "uhoo")  == "rcenvivo.aurapp.org"){ //config RC
      bundleName = "org.somosaura.regnumchristi";
    } else if(_get(URLactual, "host", "uhoo")  == "aurapp.org"){ //config Aurapp
      bundleName = "org.somosaura.aurapp";
    }else { // default config Uhoo
      if(process.env.REACT_APP_DEFAULT_CONFIG_FB == 'Aura'){
        //config Aurapp
        bundleName = "org.somosaura.aurapp";
      }
      else if(process.env.REACT_APP_DEFAULT_CONFIG_FB == 'RC'){
        console.log("es RC");
        bundleName = "org.somosaura.regnumchristi";
      }
      else if(process.env.REACT_APP_DEFAULT_CONFIG_FB == 'Porvenir'){
        console.log("es Porvenir");
        bundleName = "io.uhoo.porvenir";
      }
      else{
        console.log("es uhoo");
        // default config Uhoo
      bundleName = "com.brilliant.brilliantapp";
      } 
    }
    let customToken = await getCustomToken(_user, bundleName,idToken, comm);
    if (!customToken || customToken == '') return;
    sessionStorage.setItem("access_token", _get(customToken, "token", ""));
    let _own = await getOwn();
    _own.token = _get(customToken, "token", "");
    sessionStorage.setItem("own", JSON.stringify(_own));
    setLoading(true);
    window.location.href = window.location.href;
  }

  const onSearchValueChange = (event) => {
    let communitiesFound = [];
    let _search = event.target.value;
    if(_search && _search == ''){
      setFinalConmmunities(baseFinalConmmunities);
    }else{
      baseFinalConmmunities.forEach(element => {
        if(element.name.toLowerCase().includes(_search.toLowerCase())){
          communitiesFound.push(element);
        }
      });
    }
    if(communitiesFound.length > 0){
      setFinalConmmunities(communitiesFound);
    }else{
      setFinalConmmunities(baseFinalConmmunities);
    }
  };
  let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));


  return (
    <React.Fragment>
      {openModalPerfilConfig ? (
        <Modal>
          <ConfigureUser
            outHeader={true}
            settingUserValue={userLogged ? userLogged : {}}
            setOpenModal={setOpenModalPerfilConfig}
          />
        </Modal>
      ) : (
        null
      )}
      {openModalCommunityConfig ? (
        <Modal>
          <ConfigureCommunity
            setOpenModal={setOpenModalCommunityConfig}
          />
        </Modal>
      ) : (
        null
      )}

      
      <header className="header">
        <div className="header-left">
          {activeLogo}
        </div>

        <div className="header-right" >
          <p className="">{language}</p>
          <p className="nameCommunity" onClick={() => { setOpenDropdownProfile(!openDropdownProfile) }}>{community.name}</p>
          <div className="userProfile" onClick={() => { setOpenDropdownProfile(!openDropdownProfile) }} />
        </div>

        {openDropdownProfile ? (
          <Overlap onClick={() => console.log("!!!!1")}>
            <div id="profileDropdown" className="profileDropdown-content">

              <div className="itemProfile">
                <div role="button" className="profileEdit profileDropdownItem" onClick={() => setOpenModalPerfilConfig(true)}>
                  <Icon.profileEdit />
                  {t('uhoo.profile.edit')}
                </div>
              </div>

              <div className="itemProfile">
                <div role="button" className="profileEdit profileDropdownItem" onClick={() => setOpenModalCommunityConfig(true)}>
                  <Icon.profileEdit />
                  {t('uhoo.community.edit')}
                </div>
              </div>
              {
                _get(userInfo, "email", "") ==  "aura-admin@somosaura.org" ? 
                <div className="containerSearchHeader">
                  <Search className="icoSearch" />
                  <input type="search" className="inputSearchHeader" placeholder={t('uhoo.createPost.form.communitySearch')} onChange={(e) => onSearchValueChange(e)} />
                </div>
                : null
              }
              
              <div className="containerComm">
                {
                  finalConmmunities.map(comm => {
                    return (
                      <div className="itemComm">
                        <div role="button" className="profileEdit profileCommunityItem" onClick={() => changeComm(comm)}>
                          <li className={comm.uuid == community.uuid ? "bold" : ""}>
                            {comm.name}
                          </li>
                        </div>
                      </div>
                    )
                  })
                }
              </div>

              <div className="itemProfile">
                <div onClick={logout} role="button" className="profileDropdownItem">
                  <Icon.profileLogout />
                  {t('uhoo.profile.logout')}
                </div>
              </div>
            </div>
          </Overlap>

        ) : (
          ""
        )}

      </header>
    </React.Fragment>
  );
};

//export default HeaderComponent;
export default withTranslation()(HeaderComponent);
