import axios from "axios";

import {
  host,
  port,
  route,
  datasource,
} from "../../reactUtils/systemVariables/CmsServerInformation";

const resource = "/lpsection";

export async function getLpSections(lpId) {
  try {
    let response = await axios.get(
      host + port + route + datasource + resource,
      {
        params: {
          lp_id: lpId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateLpSectionPosition(sections) {
  try {
    const data = JSON.stringify(sections);
    let response = await axios({
      method: "PUT",
      url: host + port + route + datasource + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateLpSection(LpSectionPutDto) {
  try {
    const data = JSON.stringify(LpSectionPutDto);
    let response = await axios({
      method: "PUT",
      url: host + port + route + datasource + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function createNewLpSectionBySectionIdAndLpId(sectionId, lpId, subpageId, layoutId) {
  try {
     let response = await axios({
        method: 'POST',
        url: host + port + route + datasource + resource,
        params: {
          "section_id": sectionId,
          "lp_id": lpId,
          "subpage_id": subpageId,
          "layout_id": layoutId
       },
        headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
        }
     })
     return response;
  } catch (error) {
     return error;
  }
}

export async function disableSection(lpsection_id, lpsubpage_id) {
  try {
    let response = await axios({
      method: "DELETE",
      url: host + port + route + datasource + resource,
      params: {
        lpsection_id,
        lpsubpage_id
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function getInformationBySectionIdAndLPId(lpId, lpSectionId, lpSectionName) {
  try {
    let response = await axios.get(
      host + port + route + datasource + resource,
      {
        params: {
          lp_id: lpId,
          lpsection_id: lpSectionId,
          // lpsection_name: lpSectionName,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getDonationSectionByActiveState(lpId, lpSectionId, activeState) {
  try {
    let response = await axios.get(
      host + port + route + datasource + resource + "/donations",
      {
        params: {
          lp_id: lpId,
          lpsection_id: lpSectionId,
          active_state: activeState,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getNewsSectionInformationByTopLimit(lpSectionId, topLimit) {

  try {
      let response = await axios.get(
          host + port + route + datasource + resource + "/news", {
          params: {
              lpsection_id: lpSectionId,
              top_limit: topLimit
          },
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
          }
      })
      return response;
  } catch (error) {
      return(error)
  }
}

export async function getSectionInformationBetweenDates(lpId, lpSectionId, startDate, endDate) {
    try {
      let response = await axios.get(
          host + port + route + datasource + resource, {
          params: {
              lp_id: lpId,
              lpsection_id: lpSectionId,
              start_date: startDate,
              end_date: endDate
          },
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
          }
      })
      return response;
  } catch (error) {
      return(error)
  }
}

