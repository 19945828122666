import React, { useState, useEffect, useContext } from "react";

import Icon from '../../../uhoo-components/iconLibrary.jsx';
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";

import "./css/contactModal.css";

//Localization
import { useTranslation } from 'react-i18next';

function Contact( props ) {

    const { t, i18n } = useTranslation();

    const contactMail = "soporte@uhoo.io"; // Correo de Contacto

    let open = props.setOpenModal;    
    function closeModal() { open(false); }

    function Mailto(props) {
        return (
          <a href={"mailto:"+contactMail} className="txtMailto">
            {props.children}
          </a>
        );
      }

    return(
        <div className="modalContact">
            <div className="titleModalContact">
                <div className="textTitleModalContact">{t('uhoo.globalWords.help')}</div>
                <div className="closeModalContact" onClick={closeModal} role="button"><Icon.closeModal/></div>
            </div>
            
            <hr className="lines"/>

            <div className="contentModalContact">
                <p>{t('uhoo.globalWords.contactText')} <a href={"mailto:"+contactMail}>{contactMail}</a></p>  <br/>
            </div>
            <div className="actionContentModalContact">
                <Mailto>
                    <SecondaryButton txt={t('uhoo.globalWords.support')}/>  
                </Mailto>
            </div>            
        </div>
    );
}

export default Contact;
