import React, { Component } from "react";
import "./css/button.css";
import { useTranslation } from 'react-i18next';

function SecondaryButton( props ) {
  const { t, i18n } = useTranslation(); //  Localization  
  let button;

  if(props.disabled === true)
  {
    button = (
      <div className={props.className ? props.className : "containerSecondaryButton buttonCommunityTooltip"} role="button">
        { props.tooltip ? 
          <>
            <span className="tooltiptext">{t('basicWords.cantPublishMoreItems') + ' ' + t('basicWords.modal.mail')} </span>
            <button disabled="true">{props.txt}</button>
          </>
          :
          <button disabled="true">{props.txt}</button>
        }
      </div>
    );    
  }else // filled
  {
    button = (
      <div className={props.className ? props.className : "containerSecondaryButton"} role="button">
        <button onClick={props.click}>{props.txt}</button>
      </div>
    );
  }

  if(props.outline)
  {
    button = (
      <div className="containerOutlineSecondaryButton" role="button">
        <button onClick={props.click}>{props.txt}</button>
      </div>
    );
  }

  if(props.danger)
  {
    button = (
      <div className="containerDangerSecondaryButton" role="button">
        <button onClick={props.click}>{props.txt}</button>
      </div>
    );
  }

  if(props.dark)
  {
    button = (
      <div className="containerDarkSecondaryButton" role="button">
        {props.txt}
      </div>
    );
  }
  

  return( button );
};

export default SecondaryButton;