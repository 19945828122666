import React, { Component, Fragment } from "react";
import Resizer from "react-image-file-resizer";
import { Redirect } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as BackArrow } from '../../../../assets/icons/navigation/backArrow.svg';
import "../css/sections.scss";

// Queries
import {
  getInformationBySectionIdAndLPId,
  updateLpSectionComponentNode,
  getFile,
  updateLpSectionComponentNodeImage,
  getSectionLayoutStyle,
  updateLpSectionLayout,
  updateSectionComponent,
} from "../../../../actions/aura";

import SelectLayoutModal from "../../../../aura-components/SelectLayoutModal.jsx";
import ButtonsContainer from "../../../../aura-components/ButtonsContainer.jsx";

// Components
import {
  BreadcrumbSection,
  Title,
  AccordionComponent,
  GeneralInput,
  ButtonComponent,
  GeneralModal,
  ButtonsFooterComponent
} from "../../../../aura-components";

import {
  Card,
  CircularProgress,
  ImageList,
  ImageListItem,
  Tooltip,
  Button
} from "@material-ui/core";

// Icons
import { Plus, X } from "react-feather";

import SystemNotification from "../../../global/SystemNotification";
import { generateUUID } from "../../../../reactUtils/generalUtils/generateUuid";
import { resizeImage } from "../../../../reactUtils/generalUtils/resizeImages";
import { imageQuality } from "../../../../reactUtils/systemVariables/CmsServerInformation";
import { handleChangeImageFunction } from "../../../../reactUtils/generalUtils/handleImageChange.jsx";

//Localization
import { withTranslation } from "react-i18next";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;
const maxWidth = 1200;
const maxHeight = 1200;

const initialObject = {
  id: null,
  position: 0,
  s3path: null,
  file: null,
  image: null,
  loading: false,
  modified: false,
};

class HomeSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,

      landingPageId: null,
      organizationId: null,
      organizationName: null,
      sectionId: null,
      sectionName: null,
      country: null,
      region: null,
      city: null,

      title: {
        id: null,
        value: null,
        nodeTypeName: null,
      },
      description: {
        id: null,
        value: null,
        nodeTypeName: null,
      },
      imgBack: initialObject,
      videoBack: {
        id: null,
        position: 0,
        s3path: null,
        videoUrl: null,
        video: null,
        loading: false,
        modified: false,
      },
      img1: initialObject,
      img2: initialObject,
      img3: initialObject,
      lpLayoutImg: null,
      informationToUpdate: [],
      FlagClassName: false,
      goToWithoutSaveModalVisible: false,

      //Layout changed
      haveChangeInLayout: false,

      loadingInfo: false,
      isLoadingButton: false,

      // Modals
      showSuccess: false,
      showWarning: false,
      showError: false,
      contentNotificationModal: "",

      homeButtons: {
        buttons: [],
        edited: false,
      },

      //Modal general
      openDialog: false,
    };
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    const { location } = this.props;
    const { props } = location;

    if (props) {
      this.getHomeInformation(props.landingPageId, props.sectionId);

      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
      this.handleSelectLayout();
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getHomeInformation(lpId, lpSectionId) {
    const response = await getInformationBySectionIdAndLPId(
      lpId,
      lpSectionId,
      "HOME"
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      // Modal
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      const title = { ...this.state.title };
      const description = { ...this.state.description };
      const img1 = { ...this.state.img1 };
      const img2 = { ...this.state.img2 };
      const img3 = { ...this.state.img3 };
      const imgBack = { ...this.state.imgBack };
      const videoBack = { ...this.state.videoBack };
      const homeButtons = { ...this.state.homeButtons };

      const sectionInfo = response.data.data;
      const homeComponent = sectionInfo.components.find(
        (comp) => comp.name === "HomeComponent"
      );

      title.id = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeTitle"
      ).id;
      title.value = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeTitle"
      ).value;
      title.nodeTypeName = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeTitle"
      ).nodeType;
      title.minLength = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeTitle"
      ).minLength;
      title.maxLength = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeTitle"
      ).maxLength;
      title.length = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeTitle"
      ).value.length;

      description.id = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeDescription"
      ).id;
      description.value = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeDescription"
      ).value;
      description.nodeTypeName = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeDescription"
      ).nodeType;
      description.minLength = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeDescription"
      ).minLength;
      description.maxLength = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeDescription"
      ).maxLength;
      description.length = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeDescription"
      ).value.length;

      img1.id = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeImg1"
      ).id;
      img1.position = 1;
      img1.s3path = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeImg1"
      ).value;
      img1.file = null;
      img1.image = null;
      img1.loading = true;

      img2.id = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeImg2"
      ).id;
      img2.position = 2;
      img2.s3path = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeImg2"
      ).value;
      img2.file = null;
      img2.image = null;
      img2.loading = true;

      img3.id = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeImg3"
      ).id;
      img3.position = 3;
      img3.s3path = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeImg3"
      ).value;
      img3.file = null;
      img3.image = null;
      img3.loading = true;

      imgBack.id = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeImgBackground"
      ).id;
      imgBack.position = 0;
      imgBack.s3path = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeImgBackground"
      ).value;
      imgBack.file = null;
      imgBack.image = null;
      imgBack.loading = true;

      videoBack.id = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeVideo"
      ).id;
      videoBack.videoUrl = homeComponent.nodes.find(
        (node) => node.nodeType === "HomeVideo"
      ).value;
      videoBack.image = null;
      videoBack.loading = true;
      //  videoBack.videoUrl = videoBack.s3path;
      // Get video background image
      // const response = await getFile(videoBack.s3path);
      // videoBack.video = response;
      // videoBack.loading = false;

      homeButtons.buttons = sectionInfo.components[0].buttons;

      const lpSectionComponentId = sectionInfo.components[0].id;

      this.setState({
        lpSectionComponentId,
        title,
        description,
        img1,
        img2,
        img3,
        imgBack,
        videoBack,
        loadingInfo: false,
        homeButtons,
      });

      // Get images
      const img1Response = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" + img1.s3path
      );
      img1.file = img1Response !== "https://somosaura-cms.s3.amazonaws.com/"
        ? img1Response : null;
      img1.image = img1Response !== "https://somosaura-cms.s3.amazonaws.com/"
        ? img1Response : null;
      img1.loading = false;

      const img2Response = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" + img2.s3path
      );
      img2.file = img2Response !== "https://somosaura-cms.s3.amazonaws.com/"
        ? img2Response : null;
      img2.image = img2Response !== "https://somosaura-cms.s3.amazonaws.com/"
        ? img2Response : null;
      img2.loading = false;

      const img3Response = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" + img3.s3path
      );
      img3.file = img3Response !== "https://somosaura-cms.s3.amazonaws.com/"
        ? img3Response : null;
      img3.image = img3Response !== "https://somosaura-cms.s3.amazonaws.com/"
        ? img3Response : null;
      img3.loading = false;

      const imgBackResponse = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" + imgBack.s3path
      );
      imgBack.file = imgBackResponse !== "https://somosaura-cms.s3.amazonaws.com/"
        ? imgBackResponse : null;
      imgBack.image = imgBackResponse !== "https://somosaura-cms.s3.amazonaws.com/"
        ? imgBackResponse : null;
      imgBack.loading = false;

      //Get layout image
      let subPage = null;
      if (this.state.subpageId && this.state.subpageId !== 10001) {
        subPage = sectionInfo.subpages.find(
          (sp) => sp.id === this.state.subpageId
        );
      } else {
        subPage = sectionInfo.subpages.find((sp) => sp.id === null);
      }
      let subpageIndex = sectionInfo.subpages.indexOf(subPage);
      if (subpageIndex === -1) {
        subpageIndex = 0;
      }
      let layout =
        response.data.data.subpages[subpageIndex].lpSectionLayoutStyle
          .sectionLayoutId;
      let layoutImg = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" + layout.s3ImagePath
      );

      this.setState({
        title,
        description,
        img1,
        img2,
        img3,
        imgBack,
        lpSectionLayoutStyle: layout,
        lpLayoutImg: layoutImg,
      });
    }
  }

  async uploadFileToS3Bucket(file, key) {
    await updateLpSectionComponentNodeImage(file, key);
  }

  async getLayoutFunction(lpId) {
    let response = await getSectionLayoutStyle(lpId, 2);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      // Modal
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
        isLoadingButton: false,
      });
    } else {
      let responseData = response.data.data;
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].sampleLayoutImageS3Path) {
          //Get image
          let img = await getFile(
            "https://somosaura-cms.s3.amazonaws.com/" +
            responseData[i].sampleLayoutImageS3Path
          );
          responseData[i].img = img;
        }
      }
      this.setState({
        layoutData: responseData,
      });
    }
  }

  async updateSectionComponentFunction(componentToUpdate) {
    const { landingPageId, lpSectionId } = this.state;
    const response = await updateSectionComponent(componentToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      // Modal
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      // Modal
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.homeSuccessfullyEdited"),
        isLoadingButton: false,
      });
      this.getHomeInformation(landingPageId, lpSectionId);
    }

    this.setState({
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  }

  async updateNode(nodesToUpdate) {
    const { landingPageId, lpSectionId } = this.state;

    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      // Modal
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      // Modal
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.homeSuccessfullyEdited"),
        isLoadingButton: false,
      });
      this.getHomeInformation(landingPageId, lpSectionId);
    }
  }

  async updateLpSectionLayoutFunction(lpSectionId, newLayoutId) {
    let response = await updateLpSectionLayout(lpSectionId, newLayoutId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
    }
    this.setState({ isLoadingButton: false });
  }

  // Events
  handleChange = (event) => {
    const { id, value } = event.target;
    const { title, description, videoBack } = { ...this.state };

    if (id === "HomeTitle") {
      if (value.length <= title.maxLength) {
        title.value = value;
        title.modified = true;
        title.length = value.length;
      }
    }
    if (id === "HomeDescription") {
      if (value.length <= description.maxLength) {
        description.value = value;
        description.modified = true;
        description.length = value.length;
      }
    }
    if (id === "HomeVideo") {
      videoBack.videoUrl = value;
      videoBack.modified = true;
    }
    this.setState({
      title,
      description,
      videoBack,
      FlagClassName: true,
    });
  };

  // Change
  handleChangeLayout = (e, id) => {
    this.setState({
      // newLayoutId: id,
      saveButtonDisabled: false,
      FlagClassName: true,
      lpSectionLayoutStyle: id,
      haveChangeInLayout: true,
    });
  };

  handleIndexImageChange = (pos) => {
    this.setState({
      positionImageToChange: pos,
    });
  };

  handleChangeImage = async (event) => {
    const { country, city, organizationId, positionImageToChange } = this.state;
    var fileUpload = event.target.files[0];
    let extension;
    if (fileUpload !== undefined) {
      extension = fileUpload.type.replace("image/", "");
    }
    const random = uuidv4();
    const key = `content/${country}/${city}/${organizationId}/img${random}.jpg`;
    var imgBack = { ...this.state.imgBack };

    if (!extension) {
      return;
    }
    if (key !== undefined) {
      if (positionImageToChange === 0) {
        let flag = true;
        imgBack = await handleChangeImageFunction(
          imgBack,
          fileUpload,
          extension,
          key,
          1800,
          1000,
          600,
          400
        ).catch((res) => {
          const { t } = this.props;
          this.setState({
            showError: true,
            contentNotificationModal: `${t(`${res.content}`).replace("R1", res.params.R1).replace("R2", res.params.R2)}`,
          });
          flag = false;
        });
        if (flag !== false) {
          this.uploadFileToS3Bucket(imgBack.file, key);
          this.setState({
            imgBack,
            FlagClassName: true,
          });
        }
      }
      if (positionImageToChange === 1) {
        var img1 = { ...this.state.img1 };
        let flag = true;
        img1 = await handleChangeImageFunction(
          img1,
          fileUpload,
          extension,
          key,
          1800,
          1000,
          600,
          400
        ).catch((res) => {
          const { t } = this.props;
          this.setState({
            showError: true,
            contentNotificationModal: `${t(`${res.content}`).replace("R1", res.params.R1).replace("R2", res.params.R2)}`,
          });
          flag = false;
        });
        if (flag !== false) {
          this.uploadFileToS3Bucket(img1.file, key);
          this.setState({
            img1,
            FlagClassName: true,
          });
        }
      }

      if (positionImageToChange === 2) {
        var img2 = { ...this.state.img2 };

        let flag = true;
        img2 = await handleChangeImageFunction(
          img2,
          fileUpload,
          extension,
          key,
          1800,
          1000,
          600,
          400
        ).catch((res) => {
          const { t } = this.props;
          this.setState({
            showError: true,
            contentNotificationModal: `${t(`${res.content}`).replace("R1", res.params.R1).replace("R2", res.params.R2)}`,
          });
          flag = false;
        });
        if (flag !== false) {
          this.uploadFileToS3Bucket(img2.file, key);
          this.setState({
            img2,
            FlagClassName: true,
          });
        }
      }

      if (positionImageToChange === 3) {
        var img3 = { ...this.state.img3 };

        let flag = true;
        img3 = await handleChangeImageFunction(
          img3,
          fileUpload,
          extension,
          key,
          1800,
          1000,
          600,
          400
        ).catch((res) => {
          const { t } = this.props;
          this.setState({
            showError: true,
            contentNotificationModal: `${t(`${res.content}`).replace("R1", res.params.R1).replace("R2", res.params.R2)}px`,
          });
          flag = false;
        });
        if (flag !== false) {
          this.uploadFileToS3Bucket(img3.file, key);
          this.setState({
            img3,
            FlagClassName: true,
          });
        }
      }
      this.forceUpdate();
    }
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  disableImage = (elements) => {
    const { imgBack, img1, img2, img3, positionImageToChange } = this.state;
    let index = positionImageToChange;
    let element;
    if (index === 0) {
      element = imgBack;
    }
    if (index === 1) {
      element = img1;
    }
    if (index === 2) {
      element = img2;
    }
    if (index === 3) {
      element = img3;
    }
    this.setState({
      isLoadingButton: true,
      FlagClassName: false,
    });
    const nodesToUpdate = [];
    const node = {};
    node.id = element.id;
    node.value = "";
    node.nodeType = "IMAGE";

    nodesToUpdate.push(node);
    this.updateNode(nodesToUpdate);
    this.handleCloseDialog();
  };

  saveHomeEvent = (e, flag, image) => {
    const {
      title,
      description,
      imgBack,
      img1,
      img2,
      img3,
      videoBack,
      haveChangeInLayout,
      lpSectionId,
      lpSectionLayoutStyle,
      homeButtons,
      landingPageId,
      lpSectionComponentId,
    } = this.state;
    const { t } = this.props;
    this.setState({
      isLoadingButton: true,
      FlagClassName: false,
    });
    const nodesToUpdate = [];
    let errorInput = false;

    if (title.modified) {
      const node = {};
      node.id = title.id;
      if (title.value === "") {
        errorInput = true;
      }
      node.value = title.value;
      node.nodeType = "TEXT";

      nodesToUpdate.push(node);
    }
    if (description.modified) {
      const node = {};
      node.id = description.id;
      node.value = description.value;
      node.nodeType = "TEXT";
      nodesToUpdate.push(node);
    }

    if (flag && image.position === 0) {
      const node = {};
      node.id = imgBack.id;
      node.value = "";
      node.nodeType = "IMAGE";

      nodesToUpdate.push(node);
    }
    if (imgBack.modified) {
      const node = {};

      node.id = imgBack.id;
      node.value = imgBack.value;
      node.nodeType = "IMAGE";

      nodesToUpdate.push(node);
    }
    if (flag && image.position === 1) {
      const node = {};
      node.id = img1.id;
      node.value = "";
      node.nodeType = "IMAGE";

      nodesToUpdate.push(node);
    }
    if (img1.modified) {
      const node = {};

      node.id = img1.id;
      node.value = img1.value;
      node.nodeType = "IMAGE";

      nodesToUpdate.push(node);
    }
    if (flag && image.position === 2) {
      const node = {};
      node.id = img2.id;
      node.value = "";
      node.nodeType = "IMAGE";

      nodesToUpdate.push(node);
    }
    if (img2.modified) {
      const node = {};

      node.id = img2.id;
      node.value = img2.value;
      node.nodeType = "IMAGE";

      nodesToUpdate.push(node);
    }
    if (flag && image.position === 3) {
      const node = {};
      node.id = img3.id;
      node.value = "";
      node.nodeType = "IMAGE";

      nodesToUpdate.push(node);
    }
    if (img3.modified) {
      const node = {};

      node.id = img3.id;
      node.value = img3.value;
      node.nodeType = "IMAGE";

      nodesToUpdate.push(node);
    }

    if (videoBack.modified) {
      const node = {};
      // var regex1 = /www.youtube.com/;
      // var resultYT = regex1.test(videoBack.videoUrl);
      // if (resultYT === true) {
      node.id = videoBack.id;
      node.value = videoBack.videoUrl;
      node.nodeType = "VIDEO";
      // node.sectionComponentNodeId = 96;
      // node.nodeType = "HomeVideo";
      nodesToUpdate.push(node);
      // } else {
      //   errorInput = true;
      // }
    }

    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        // Modal
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.fieldEmpty"),
          isLoadingButton: false,
        });
      }
    }

    if (haveChangeInLayout) {
      this.updateLpSectionLayoutFunction(lpSectionId, lpSectionLayoutStyle);
    }
    if (homeButtons.edited === true) {
      let hButtons = homeButtons.buttons
        ? homeButtons.buttons.map((b) => {
          const button = {
            id: b.id,
            name: b.name,
            externalLink: b.externalLink,
            internalLpSectionComponentId: b.component
              ? b.component.id
              : b.internalLpSectionComponentId
                ? b.internalLpSectionComponentId
                : null,
            isEnabled: b.isEnabled,
          };
          return button;
        })
        : [];
      const componentToUpdate = {
        // --- Con esto se crea el registro lpSectionComponent ---
        // Con estos dos valores se obtiene el lpSectionId
        lpId: landingPageId, // Este valor se obtiene de la vista anterior
        lpSectionId: lpSectionId,
        sectionId: 2, // Valor estático para la sección
        id: lpSectionComponentId,
        sectionComponentId: 2, // Valor estático de 20, valor para el DonationComponent

        parentLPSectionComponentId: "", // Valor estático de 4 puesto que el id 4 es el padre de este nuevo componente

        // --- Con el Id del lpSectionComponent creado, se crean los nodos de este componente
        nodesToAdd: [],
        buttons: hButtons,
      };

      this.updateSectionComponentFunction(componentToUpdate);
    }
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      showWarning: true,
      contentNotificationModal: t("basicWords.modal.noSectionSelected"),
    });
    // Open modal
    this.handleOkWarning();
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleClickOpenDialog = (index) => {
    if (index) {
      this.setState({ openDialog: true });
      this.handleIndexImageChange(index);
    } else {
      this.setState({ openDialog: true });
    }
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleButtonListChange = (buttonList) => {
    let { homeButtons } = this.state;
    homeButtons.buttons = buttonList;
    homeButtons.edited = true;

    this.setState({ homeButtons, FlagClassName: true });
  };

  // Render
  renderUploadImage = (image, openDialog) => {
    let element;
    const { t } = this.props;
    if (image.id != null) {
      element = (
        // {/* Tooltip image */}
        <Tooltip title={t("generalMessages.imagesFormat")}>
          <div className="cardImagesSquare">
            <div className="row">
              <div className="iconXCardImagesSquare">
                <a>
                  <X
                    className="iconImageEditInSectionSquare"
                    onClick={() => this.handleClickOpenDialog(image.position)}
                  />
                </a>
              </div>
            </div>

            <div className="row">
              <div className="centerImage">
                <input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(e) => this.handleChangeImage(e, image)}
                  hidden
                />
                <label htmlFor="contained-button-file">
                  <div className="imageEdit">
                    <div>
                      {image.file != null ? (
                        <img
                          src={image.image}
                          alt="avatar"
                          className="widthAndHeightImageSectionSquare"
                          onClick={() =>
                            this.handleIndexImageChange(image.position)
                          }
                        />
                      ) : (
                        <Plus
                          className="imageEdit"
                          onClick={() =>
                            this.handleIndexImageChange(image.position)
                          }
                        />
                      )}

                      <GeneralModal
                        visible={openDialog}
                        title={t("basicWords.deleteItem")}
                        footer={[
                          <div
                            className="row"
                            key={1}
                            style={{ display: "flex" }}
                          >
                            <div className="col-6">
                              <ButtonComponent
                                onClick={() => this.handleCloseDialog()}
                                props={null}
                                shape="round"
                                text={t("basicWords.cancelText")}
                              />
                            </div>
                            <div>
                              <ButtonComponent
                                onClick={() => this.disableImage(image)}
                                props={null}
                                shape="round"
                                text={t("basicWords.okText")}
                              />
                            </div>
                          </div>,
                        ]}
                        onClick={() => this.handleCloseDialog()}
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </Tooltip>
      );
    }
    return element;
  };

  handleSelectLayout = () => {
    this.getLayoutFunction(this.props.location.props.landingPageId);

    this.setState({ layoutModalVisible: true });
  };

  setLayoutModalVisible = (isVisible) => {
    this.setState({ layoutModalVisible: isVisible });
  };

  //-----------Update page after modal------------
  updatePage = (lpSectionId, previousPath) => {
    const { landingPageId } = this.state;
    this.getHomeInformation(landingPageId, lpSectionId);
    this.setState({
      previousPath,
    });
    this.setState({ layoutModalVisible: false });
    this.forceUpdate();
  };

  handleChangeVideo = (info) => {
    const { country, city, organizationId } = this.state;

    const videoBack = { ...this.state.videoBack };
    let extension = info.file.type.replace("video/", "");
    Resizer.imageFileResizer(
      info.file.originFileObj,
      maxWidth,
      maxHeight,
      extension,
      imageQuality,
      0,
      (uri) => {
        const file = resizeImage(uri, info.file.type, extension);

        videoBack.videoUrl = file;
        videoBack.video = uri;
        videoBack.loading = false;
        videoBack.status = 1;
        videoBack.modified = true;

        const random = generateUUID();
        const key = `content/${country}/${city}/${organizationId}/video${random}`;

        this.uploadFileToS3Bucket(file, key);

        this.setState({
          videoBack,
        });
      },
      "base64"
    );
  };

  handleButtonListChange = (buttonList) => {
    let { homeButtons } = this.state;
    homeButtons.buttons = buttonList;
    homeButtons.edited = true;
    this.setState({ homeButtons, FlagClassName: true });
  };

  setShowError = (open) => {
    this.setState({ showError: open });
  };

  setShowSuccess = (open) => {
    this.setState({ showSuccess: open });
  };

  setShowWarning = (open) => {
    this.setState({ showWarning: open });
  };

  render() {
    const {
      redirectBack,
      description,
      img1,
      img2,
      img3,
      imgBack,
      videoBack,
      isLoadingButton,
      breadcrumb,
      layoutData,
      lpSectionLayoutStyle,
      organizationId,
      landingPageId,
      lpUrl,
      sectionName,
      name,
      subpageId,
      FlagClassName,

      openDialog,
      homeButtons,
      goToWithoutSaveModalVisible,

      // Modals
      showSuccess,
      showWarning,
      showError,
      contentNotificationModal
    } = this.state;
    const { t } = this.props;

    let { title } = this.state;

    if (breadcrumb) {
      title =
        breadcrumb +
        "/" +
        "/" +
        t("basicWords.section") +
        " " +
        t("sections.home") +
        ": " +
        title.value;
    }
    if (redirectBack) {
      return <Redirect to="/manage/web" />;
    }
    return (
      <React.Fragment>

        <section className="Title">
          <BackArrow className="ico pointerCursor" />
          <h3>Página Web</h3>
        </section>
            {!!showWarning && (
              <SystemNotification
                setShowNotification={(e) => this.setShowWarning(e)}
                type="Warning"
                caption={contentNotificationModal}
              />
            )}
            {!!showSuccess && (
              <SystemNotification
                setShowNotification={(e) => this.setShowSuccess(e)}
                type="Success"
                caption={contentNotificationModal}
              />
            )}
            {!!showError && (
              <SystemNotification
                setShowNotification={(e) => this.setShowError(e)}
                type="Error"
                caption={contentNotificationModal}
              />
            )}
        <section className="sectionContainer">
            <Card>
              {isLoadingButton === true ? (
                <div
                  className="col-xs-12 col-md-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    transform: "translateY(800%)",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div className="row componentSpace spaceBreadcrumb">
                    <BreadcrumbSection
                      principal={t("sections.home")}
                      secondary={this.state.organizationName}
                      route="./manage/web"
                      propsComponent={{
                        organizationId,
                        sectionName: sectionName,
                        nameSubpage: name ? name : null,
                        lpId: landingPageId,
                        subpageId: subpageId ? subpageId : null,
                        lpUrl: lpUrl,
                      }}
                      FlagclassName={FlagClassName}
                      onClick={(e) => this.saveAboutEvent(e)}
                      GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                      onCancelGotoWithoutMModal={(e) =>
                        this.onCancelGotoWithoutMModal(e)
                      }
                      goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                    />
                  </div>

                  {/* Title */}
                  <div className="row adjustPositionXLeft adjustPositionYTop">
                    <Title
                      title={t("basicWords.completeInformationOfYourSection")}
                    />
                  </div>

                  {/* Layout */}
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    <SelectLayoutModal
                    layoutData={layoutData}
                    lpLayoutId={
                      lpSectionLayoutStyle ? lpSectionLayoutStyle : null
                    }
                    handleChange={this.handleChangeLayout}
                  />
                  </div>

                  {/* Accordions */}
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    {/* First */}
                    <div className="row">
                      <AccordionComponent
                        title={t("generalUser.contentSection")}
                        body={[
                          <div className="accordionContent">
                            <div className="accordionCenterLine">
                              <div className="accordionCompleteInputs">
                                <GeneralInput
                                  text={t("basicWords.enterTitle")}
                                  id={title ? title.nodeTypeName : null}
                                  value={title ? title.value : null}
                                  placeholder={t("basicWords.enterTitle")}
                                  isReq
                                  onChange={(e, id) => this.handleChange(e, id)}
                                />
                                <span>
                                  {title.length} / {title.maxLength}
                                </span>
                              </div>
                            </div>
                            <br />
                            <div className="accordionCenterLine">
                              <div className="accordionCompleteInputs">
                                <GeneralInput
                                  text={t("generalUser.contentSection")}
                                  id={description ? description.nodeTypeName : null}
                                  value={description ? description.value : null}
                                  placeholder={t("generalUser.contentSection")}
                                  isReq
                                  onChange={(e, id) => this.handleChange(e, id)}
                                />
                                <span>
                                  {description.length} / {description.maxLength}
                                </span>
                              </div>
                            </div>
                            <br />

                            <div className="accordionCenterLine">
                              <div className="accordionCompleteInputs">
                                {/* Fourth Line */}
                                <div className="rowSections componentSpace">
                                  <div className="textLogo">
                                    {t("basicWords.buttons.buttons")}
                                  </div>
                                </div>
                                {/* Fifth Line */}

                                <div
                                  className="rowSections componentSpace"
                                  style={{
                                    marginLeft: "2%",
                                  }}
                                >
                                  {landingPageId ? (
                                    <ButtonsContainer
                                      isModalVisible={true}
                                      lpId={landingPageId}
                                      buttonList={homeButtons.buttons}
                                      handleButtonListChange={(buttonList) =>
                                        this.handleButtonListChange(buttonList)
                                      }
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>,
                        ]}
                      />
                      {/* Second */}
                      <br />
                      <div className="row">
                        <AccordionComponent
                          title={t("generalUser.sectionImage")}
                          secondTitle={t("generalMessages.imageBg")}
                          body={[
                            <div
                              className="inlineSomeItems"
                              style={{
                                flexBasis: "70%",
                                flexShrink: 0,
                                marginLeft: "1%",
                              }}
                            >
                              <div>
                                <ImageList
                                  style={{
                                    flexWrap: "nowrap",
                                    transform: "translateZ(0)",
                                  }}
                                  cols={3}
                                >
                                  <ImageListItem
                                    style={{ height: "80px" }}
                                    key="image1"
                                  >
                                    {this.renderUploadImage(img1, openDialog)}
                                  </ImageListItem>
                                  <ImageListItem
                                    style={{ height: "80px" }}
                                    key="image2"
                                  >
                                    {this.renderUploadImage(img2, openDialog)}
                                  </ImageListItem>
                                  <ImageListItem
                                    style={{ height: "80px" }}
                                    key="image3"
                                  >
                                    {this.renderUploadImage(img3, openDialog)}
                                  </ImageListItem>
                                </ImageList>
                              </div>
                              <br />
                            </div>,
                            <div
                              style={{
                                flexBasis: "30%",
                                flexShrink: 0,
                              }}
                            >
                              <div style={{ marginRight: "10%" }}>
                                {this.renderUploadImage(imgBack, openDialog)}
                              </div>
                            </div>,
                          ]}
                        />
                      </div>
                      <br />
                      {/* Third */}
                      <div className="row adjustPositionYBottom">
                        <AccordionComponent
                          title={t("generalUser.urlContentSection")}
                          body={[
                            <div className="accordionContent">
                              <div>
                                <div className="accordionCompleteInputs">
                                  <span style={{ marginLeft: "2px" }}>
                                    {t("basicWords.tooltipLayoutVideo")}
                                  </span>
                                </div>
                                <div className="accordionCompleteInputs">
                                  <br />
                                  <GeneralInput
                                    text={t("basicWords.enterUrlVideo")}
                                    id={"HomeVideo"}
                                    value={videoBack ? videoBack.videoUrl : null}
                                    placeholder={t("basicWords.enterUrlVideo")}
                                    isReq
                                    onChange={(e, id) => this.handleChange(e, id)}
                                    iconYoutube={true}
                                  />
                                </div>
                              </div>
                            </div>,
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Card>
        </section>
        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName,
            name,
            landingPageId,
            subpageId,
            lpUrl,
          }}
          FlagclassName={FlagClassName}
          onClick={(e) => this.saveHomeEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </React.Fragment>

    );
  }
}
export default withTranslation()(HomeSection);
