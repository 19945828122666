import axios from 'axios';

import { host, port, route, datasource } from '../../reactUtils/systemVariables/CmsServerInformation';

const resource = "/lpsectioncomponent";

export async function updateSectionComponent(sectionComponentToUpdate) {
   try {
      const data = JSON.stringify(sectionComponentToUpdate)
      let response = await axios({
         method: 'PUT',
         url: host + port + route + datasource + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function insertSectionComponent(sectionComponentToAdd) {
   try {
      const data = JSON.stringify(sectionComponentToAdd)
      let response = await axios({
         method: 'POST',
         url: host + port + route + datasource + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function disableLpSectionComponent(id) {
   try {
      let response = await axios({
         method: 'DELETE',
         url: host + port + route + datasource + resource,
         params: {
            component_id: id
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}

export async function getLpSectionComponentsByLpSectionId(lpSectionId) {
   try {
      let response = await axios({
         method: 'GET',
         url: host + port + route + datasource + resource,
         params: {
            lpsection_id: lpSectionId
         },
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
         }
      })
      return response;
   } catch (error) {
      return error;
   }
}