export let auraBasic = {
        maxLengthPost: null,
        maxLengthComplements: 3,
        maxLengthBoard: 3,
        maxLengthEmail: null,
        maxLengthAudiences: 1,
        maxLengthUsersAdmin: 3,
        maxLengthRoles: null,
        // --------------------
        // maxLengthPost: null,
        // maxLengthComplements: null,
        // maxLengthBoard: null,
        // maxLengthEmail: null,
        // maxLengthAudiences: null,
        // maxLengthUsersAdmin: null,
        // maxLengthRoles: null,
    }
    
export let auraPro = {
    maxLengthPost: null,
    maxLengthComplements: null,
    maxLengthBoard: null,
    maxLengthEmail: null,
    maxLengthAudiences: null,
    maxLengthUsersAdmin: null,
    maxLengthRoles: null,
}
