import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { ReactComponent as BackArrow } from '../../../../assets/icons/navigation/backArrow.svg';
import {
  Card,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Button
} from "@material-ui/core";

// Components
import {
  Title,
  BreadcrumbSection,
  GeneralInput,
  ButtonComponent,
  ButtonsFooterComponent
} from "../../../../aura-components";

import "../css/socialNetwork.scss";

// Queries
import { getInfoUhoo } from "../../../../actions/aura/dataUhoo.actions";
import {
  getInformationBySectionIdAndLPId,
  updateLpSectionComponentNode,
} from "../../../../actions/aura";

import SystemNotification from "../../../global/SystemNotification";
// Icons
import { Facebook, Instagram, Twitter } from "react-feather";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

//Localization
import { withTranslation } from "react-i18next";

import { Post } from "./Post";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;

const initialObject = {
  id: null,
  value: null,
  nodeTypeName: null,
  modified: false,
};
class SocialNetworksSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationId: null,
      checkedTwitter: true,
      checkedFacebook: true,
      checkedInstagram: true,
      checkedAurapp: true,
      SocialNetworksTwitterUsername: null,
      SocialNetworksFacebookUrl: null,
      SocialNetworksAurappToken: "",
      SocialNetworksInstagramPost: null,
      pageFacebook: "",
      textCheckboxTwitter: "Usuario de Twitter correcto",
      textCheckboxFacebook: "Link de la página de Facebook correcto",
      textCheckboxInstagram: "Link del post de Instagram correcto",
      textCheckboxAurapp: "Tienes habilitado Aurapp",

      loadingInfo: true,

      SocialNetworksTitle: initialObject,
      SocialNetworksAurapp: {
        id: null,
        data: null,
        nodeTypeName: null,
      },
      SocialNetworksTwitter: initialObject,
      SocialNetworksFacebook: initialObject,
      SocialNetworksInstagram: initialObject,
      FlagClassName: false,
      goToWithoutSaveModalVisible: false,

      // Modals
      showSuccess: false,
      showWarning: false,
      showError: false,
      contentNotificationModal: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { props } = this.props.location;

    if (props) {
      this.getSocialNetworksInformation(props.landingPageId, props.sectionId);
      this.setState({
        sectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  getElementsToSocialNetworks = (components, nodeType, paramsToFind) => {
    let object = {};
    if (components.nodes.find((node) => node.nodeType === nodeType) !== undefined) {
      paramsToFind.forEach(element => {
        if (element === "length") {
          object[element] = components.nodes.find((node) => node.nodeType === nodeType).value.length;
        } else if (element === "nodeTypeName") {
          object[element] = components.nodes.find((node) => node.nodeType === nodeType).nodeType;
        } else {
          object[element] = components.nodes.find((node) => node.nodeType === nodeType)[element];
        }
      });
    } else {
      paramsToFind.forEach(element => {
        if (element === "nodeTypeName") {
          object[element] = nodeType;
        } else {
          object[element] = "";
        }
      });
    }
    return object;
  };

  // Queries
  async getSocialNetworksInformation(lpId, lpSectionId) {
    const response = await getInformationBySectionIdAndLPId(
      lpId,
      lpSectionId,
      "SOCIALNETWORKS"
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      // Get all the information about the section components
      const sectionInfo = response.data.data;
      const SocialNetworksComponent = sectionInfo.components.find(
        (comp) => comp.name === "SocialNetworksContainer"
      );

      const principalObjectKeys = ["id", "value", "nodeTypeName", "minLength", "maxLength", "length"];
      const secondaryObjectKeys = ["id", "value", "nodeTypeName"];
      const SocialNetworksContainerTitle = this.getElementsToSocialNetworks(SocialNetworksComponent, "SocialNetworksContainerTitle", principalObjectKeys);
      const SocialNetworksTwitter = this.getElementsToSocialNetworks(SocialNetworksComponent, "SocialNetworksTwitterUsername", secondaryObjectKeys);
      const SocialNetworksAurapp = this.getElementsToSocialNetworks(SocialNetworksComponent, "SocialNetworksUhooToken", secondaryObjectKeys);
      const SocialNetworksFacebook = this.getElementsToSocialNetworks(SocialNetworksComponent, "SocialNetworksFacebookUrl", secondaryObjectKeys);
      const SocialNetworksInstagram = this.getElementsToSocialNetworks(SocialNetworksComponent, "SocialNetworksInstagramPost", secondaryObjectKeys);

      if (SocialNetworksAurapp.value !== "") {
        const dataAurApp = await getInfoUhoo(SocialNetworksAurapp.value);
        this.setState({ SocialNetworksAurapp: dataAurApp });
      } else {
        this.setState({ SocialNetworksAurapp: "" });
      }
      if (
        SocialNetworksFacebook.value[
        SocialNetworksFacebook.value.length - 1
        ] === "/"
      ) {
        SocialNetworksFacebook.value = SocialNetworksFacebook.value.substring(
          0,
          SocialNetworksFacebook.value.length - 1
        );
      }

      this.setState({
        SocialNetworksContainerTitle,
        SocialNetworksTwitter: SocialNetworksTwitter,
        SocialNetworksFacebook,
        SocialNetworksInstagram,
        loadingInfo: false,
        SocialNetworksTwitterUsername: SocialNetworksTwitter.value,
        SocialNetworksFacebookUrl: `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2F${SocialNetworksFacebook.value}&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1027397741079968`,
        SocialNetworksInstagramPost: `${SocialNetworksInstagram.value}embed`,
      });
      if (this.state.SocialNetworksAurapp === "") {
        this.setState({
          SocialNetworksAurapp: "",
          checkedAurapp: false,
          textCheckboxAurapp: "No tienes habilitado Aurapp",
        });
      }

      if (SocialNetworksTwitter.value === "") {
        this.setState({
          SocialNetworksTwitterUsername: "",
          checkedTwitter: false,
          textCheckboxTwitter: "Link incorrecto",
        });
      }

      var regex1 = /www.instagram.com/;
      var result = regex1.test(SocialNetworksInstagram.value);
      if (result === false) {
        this.setState({
          SocialNetworksInstagramPost: "",
          checkedInstagram: false,
          textCheckboxInstagram: "Link incorrecto",
        });
      }

      var regex2 = /www.facebook.com/;
      result = regex2.test(SocialNetworksFacebook.value);
      if (result === false) {
        this.setState({
          SocialNetworksFacebookUrl: "",
          checkedFacebook: false,
          textCheckboxFacebook: "Link incorrecto",
        });
      }
    }
  }

  async updateNode(nodesToUpdate) {
    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.contactSuccessfullyEdited"),
      });
    }
    this.setState({
      isLoadingButton: false,
    });
  }

  // Events
  handleChange = (event) => {
    const { id, value } = event.target;
    const { SocialNetworksContainerTitle } = { ...this.state };
    const { SocialNetworksTwitter } = { ...this.state };
    const { SocialNetworksFacebook } = { ...this.state };
    const { SocialNetworksInstagram } = { ...this.state };

    if (id === "SocialNetworksContainerTitle") {
      if (value.length <= SocialNetworksContainerTitle.maxLength) {
        SocialNetworksContainerTitle.value = value;
        SocialNetworksContainerTitle.modified = true;
        SocialNetworksContainerTitle.length = value.length;
      }
    }
    if (id === "SocialNetworksTwitterUsername") {
      SocialNetworksTwitter.value = value;
      SocialNetworksTwitter.modified = true;
    }

    if (id === "SocialNetworksFacebookUrl") {
      SocialNetworksFacebook.value = value;
      SocialNetworksFacebook.modified = true;
    }

    if (id === "SocialNetworksInstagramPost") {
      SocialNetworksInstagram.value = value;
      SocialNetworksInstagram.modified = true;
    }

    this.setState({
      SocialNetworksContainerTitle,
      SocialNetworksTwitter,
      SocialNetworksFacebook,
      SocialNetworksInstagram,
      FlagClassName: true,
    });
  };

  saveSocialNetworksEvent = () => {
    this.setState({
      isLoadingButton: true,
      FlagClassName: false,
    });
    const { t } = this.props;
    const nodesToUpdate = [];
    const { SocialNetworksContainerTitle } = { ...this.state };
    let SocialNetworksTwitter = this.state.SocialNetworksTwitter;
    const { SocialNetworksFacebook } = { ...this.state };
    const { SocialNetworksInstagram } = { ...this.state };
    let saveValidation = true;

    // Verify each one of the values of the component to add
    if (SocialNetworksContainerTitle.modified) {
      if (SocialNetworksContainerTitle.value === "") {
        saveValidation = false;
        // Modal
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.fieldEmpty"),
        });
      }
      delete SocialNetworksContainerTitle.minLength;
      delete SocialNetworksContainerTitle.maxLength;
      delete SocialNetworksContainerTitle.length;
      nodesToUpdate.push(SocialNetworksContainerTitle);
    }
    if (SocialNetworksTwitter.modified) {
      let SocialNetworksTwitter = this.state.SocialNetworksTwitter;
      this.setState({
        SocialNetworksTwitterUsername: SocialNetworksTwitter.value,
      });
      delete SocialNetworksTwitter.modified;
      if (SocialNetworksTwitter.value === "") {
        this.setState({
          SocialNetworksTwitterUsername: "",
          checkedTwitter: false,
          textCheckboxTwitter: "Link incorrecto",
        });
        saveValidation = false;
      } else {
        this.setState({
          SocialNetworksTwitterUsername: SocialNetworksTwitter.value,
          checkedTwitter: true,
          textCheckboxTwitter: "Usuario de Twitter correcto",
        });
        saveValidation = true;
      }
      nodesToUpdate.push(SocialNetworksTwitter);
    }
    if (SocialNetworksFacebook.modified) {
      delete SocialNetworksFacebook.modified;

      var regex = /www.facebook.com/;
      var result = regex.test(SocialNetworksFacebook.value);
      if (result === false) {
        this.setState({
          SocialNetworksFacebookUrl: "",
          checkedFacebook: false,
          textCheckboxFacebook: "Link incorrecto",
        });
        saveValidation = false;
      } else {
        if (
          SocialNetworksFacebook.value[
          SocialNetworksFacebook.value.length - 1
          ] === "/"
        ) {
          SocialNetworksFacebook.value = SocialNetworksFacebook.value.substring(
            0,
            SocialNetworksFacebook.value.length - 1
          );
        }

        this.setState({
          SocialNetworksFacebookUrl: `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2F${SocialNetworksFacebook.value}&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1027397741079968`,
          checkedFacebook: true,
          textCheckboxFacebook: "Link de la página de Facebook correcto",
        });
      }
      nodesToUpdate.push(SocialNetworksFacebook);
    }
    if (SocialNetworksInstagram.modified) {
      delete SocialNetworksInstagram.modified;
      var regex = /www.instagram.com/;
      var result = regex.test(SocialNetworksInstagram.value);
      if (result === false) {
        this.setState({
          SocialNetworksInstagramPost: "",
          checkedInstagram: false,
          textCheckboxInstagram: "Link incorrecto",
        });
        saveValidation = false;
      } else {
        this.setState({
          SocialNetworksInstagramPost: `${SocialNetworksInstagram.value}embed`,
          checkedInstagram: true,
          textCheckboxInstagram: "Link del post de Instagram correcto",
        });
        saveValidation = true;
      }
      nodesToUpdate.push(SocialNetworksInstagram);
    }

    if (nodesToUpdate.length > 0) {
      if (saveValidation) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          isLoadingButton: false,
        });
        this.updateNode(nodesToUpdate);
      }
    }
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      showWarning: true,
      contentNotificationModal: t("basicWords.modal.noSectionSelected"),
    });
    // Open modal
    this.handleOkWarning();
  };

  handleOkWarning = () => {
    this.setState({ redirectBack: true });
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({ goToWithoutSaveModalVisible: false });
  };

  renderPostComponents(posts) {
    let postsComponents = [];
    if (posts) {
      posts.forEach((element, index) => {
        postsComponents.push(
          <Post
            title={element.title}
            description={element.content}
            date={element.created_at}
            image={element.media}
            id={index}
            key={Math.random() * 1000}
            name={element.user.name}
            lastName={element.user.lastname}
            approved={element.approved}
            active={element.active}
            banned={element.banned}
          />
        );
      });
    }
    return postsComponents;
  };

  setShowError = (open) => {
    this.setState({ showError: open });
  };

  setShowSuccess = (open) => {
    this.setState({ showSuccess: open });
  };

  setShowWarning = (open) => {
    this.setState({ showWarning: open });
  };

  // Render Info
  render() {
    let count = 3;
    if (this.state.SocialNetworksTwitterUsername === "") count = count + 1;
    if (this.state.SocialNetworksInstagramPost === "") count = count + 1;
    if (this.state.SocialNetworksFacebookUrl === "") count = count + 1;
    if (count === 6) count = 12;
    if (count === 5) count = 6;
    const {
      redirectBack,
      sectionName,
      height,
      SocialNetworksTwitter,
      SocialNetworksFacebook,
      SocialNetworksInstagram,
      SocialNetworksAurapp,
      organizationId,
      isLoadingButton,
      name,
      subpageId,
      landingPageId,
      FlagClassName,
      goToWithoutSaveModalVisible,
      lpUrl,

      // Modals
      showSuccess,
      showWarning,
      showError,
      contentNotificationModal
    } = this.state;
    const { t } = this.props;

    if (redirectBack) {
      return <Redirect to="/manage/web" />;
    }

    return (
      <React.Fragment>
        {!!showWarning && (
          <SystemNotification
            setShowNotification={(e) => this.setShowWarning(e)}
            type="Warning"
            caption={contentNotificationModal}
          />
        )}
        {!!showSuccess && (
          <SystemNotification
            setShowNotification={(e) => this.setShowSuccess(e)}
            type="Success"
            caption={contentNotificationModal}
          />
        )}
        {!!showError && (
          <SystemNotification
            setShowNotification={(e) => this.setShowError(e)}
            type="Error"
            caption={contentNotificationModal}
          />
        )}
        <section className="Title">
          <BackArrow className="ico pointerCursor" />
          <h3>Página Web</h3>
        </section>
        <section className="mail">
          <Card>
            {isLoadingButton === true ? (
              <div
                className="col-xs-12 col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  transform: "translateY(800%)",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="row componentSpace">
                  <BreadcrumbSection
                    principal={t("sections.socialnetworks")}
                    secondary={this.state.organizationName}
                    route="./manage/web"
                    propsComponent={{
                      organizationId,
                      sectionName: sectionName,
                      nameSubpage: name ? name : null,
                      lpId: landingPageId,
                      subpageId: subpageId ? subpageId : null,
                      lpUrl: lpUrl,
                    }}
                    FlagclassName={FlagClassName}
                    onClick={(e) => this.saveAboutEvent(e)}
                    GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                    onCancelGotoWithoutMModal={(e) =>
                      this.onCancelGotoWithoutMModal(e)
                    }
                    goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                  />
                </div>

                <div className="row adjustPositionXLeft adjustPositionYTop">
                  <Title
                    title={t("basicWords.completeInformationOfYourSection")}
                  />
                </div>

                {/* Social media */}
                <div
                  className="row adjustPositionXLeft adjustPositionXRigth"
                  style={{ minHeight: height }}
                >
                  <div className="col lpSection" style={{"margin-bottom": "100px"}}>
                    <div className="row socialNetworkSubtitle">
                      {t("generalUser.sectionContent")}
                    </div>
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col socialNetworkIcons">
                        <Twitter className="iconContact" />
                      </div>
                      <div className="col socialNetworkInput">
                        <GeneralInput
                          text={t("sections.UsernameTwitter")}
                          id={
                            SocialNetworksTwitter
                              ? SocialNetworksTwitter.nodeTypeName
                              : null
                          }
                          value={
                            SocialNetworksTwitter
                              ? SocialNetworksTwitter.value
                              : null
                          }
                          placeholder={t("sections.UsernameTwitter")}
                          isReq
                          onChange={(e, id, value) =>
                            this.handleChange(e, id, value)
                          }
                        />
                        <span className="socialNetworkCheckbox">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="default"
                                style={{ textAlign: "center" }}
                                checked={this.state.checkedTwitter}
                                disabled
                              />
                            }
                            label={this.state.textCheckboxTwitter}
                          />
                        </span>
                      </div>

                      <div className="col socialNetworkDrag">
                        <DragIndicatorIcon />
                      </div>
                    </div>

                    {/* Facebook */}
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col socialNetworkIcons">
                        <Facebook className="iconContact" />
                      </div>
                      <div className="col socialNetworkInput">
                        <GeneralInput
                          text={t("sections.linkFacebook")}
                          id={
                            SocialNetworksFacebook
                              ? SocialNetworksFacebook.nodeTypeName
                              : null
                          }
                          value={
                            SocialNetworksFacebook
                              ? SocialNetworksFacebook.value
                              : null
                          }
                          placeholder={t("sections.linkFacebook")}
                          isReq
                          onChange={(e, id, value) =>
                            this.handleChange(e, id, value)
                          }
                        />
                        <span className="socialNetworkCheckbox">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="default"
                                style={{ textAlign: "center" }}
                                checked={this.state.checkedFacebook}
                                disabled
                              />
                            }
                            label={this.state.textCheckboxFacebook}
                          />
                        </span>
                      </div>
                      <div className="col socialNetworkDrag">
                        <DragIndicatorIcon />
                      </div>
                    </div>

                    {/* Instagram */}
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col socialNetworkIcons">
                        <Instagram className="iconContact" />
                      </div>
                      <div className="col socialNetworkInput">
                        <GeneralInput
                          text={t("sections.linkPostInstagram")}
                          id={
                            SocialNetworksInstagram
                              ? SocialNetworksInstagram.nodeTypeName
                              : null
                          }
                          value={
                            SocialNetworksInstagram
                              ? SocialNetworksInstagram.value
                              : null
                          }
                          placeholder={t("sections.linkPostInstagram")}
                          isReq
                          onChange={(e, id, value) =>
                            this.handleChange(e, id, value)
                          }
                        />
                        <span className="socialNetworkCheckbox">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="default"
                                style={{ textAlign: "center" }}
                                checked={this.state.checkedInstagram}
                                disabled
                              />
                            }
                            label={this.state.textCheckboxInstagram}
                          />
                        </span>
                      </div>
                      <div className="col socialNetworkDrag">
                        <DragIndicatorIcon />
                      </div>
                    </div>

                    {/* AurApp */}
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col socialNetworkIcons">
                        {/* <img id="user" src={AurappLogo} alt="user" className="aurappLogo"/> */}
                      </div>
                      <div className="col socialNetworkInput">
                        <GeneralInput
                          text={t("basicWords.urlProfile")}
                          id={
                            SocialNetworksAurapp
                              ? SocialNetworksAurapp.nodeTypeName
                              : null
                          }
                          value={
                            SocialNetworksAurapp ? SocialNetworksAurapp.value : ""
                          }
                          placeholder={t("basicWords.urlProfile")}
                          isReq
                          onChange={(e, id, value) =>
                            this.handleChange(e, id, value)
                          }
                        />
                        <span className="socialNetworkCheckbox">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="default"
                                style={{ textAlign: "center" }}
                                checked={this.state.checkedAurapp}
                                disabled
                              />
                            }
                            label={this.state.textCheckboxAurapp}
                          />
                        </span>
                      </div>
                      <div className="col socialNetworkDrag">
                        <DragIndicatorIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Card>
        </section>
        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName,
            name,
            landingPageId,
            subpageId,
            lpUrl,
          }}
          FlagclassName={FlagClassName}
          onClick={(e) => this.saveSocialNetworksEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </React.Fragment>

    );
  }
}
export default withTranslation()(SocialNetworksSection);
