import React, { createContext, useState, useEffect } from "react";
import { get as _get } from "lodash";
// Queries
import { getUser, GetUserRol, getAudience, usersByAudience, getConfigs, getUserSearch } from "../../../actions/uhoo"

import Modal from "../../../uhoo-components/Modal.jsx";
import ConfigureUser from "../components/configureUser";
import DeleteUser from "../components/deleteUser";

import ConfigureRole from "../components/configureRole";

const UsersCommunityContext = createContext();

function UsersCommunityProvider(props) {
  const [UsersSelectItem, setUsersSelectItem] = useState("");

  const [tabActive, setTabActive] = useState("");

  const [openModalCommunityConfig, setOpenModalCommunityConfig] =
    useState(false);
  const [openModalCommunityDelete, setOpenModalCommunityDelete] =
    useState(false);

  const [openModalRoleConfig, setOpenModalRoleConfig] = useState(false);

  const [settingUserValue, setSettingUserValue] = useState();

  const [RolSelect, setRolSelect] = useState([]);
  const [RolData, setRolData] = useState([]);
  const [RolPage, setRolPage] = useState([]);

  const [AudienceSelect, setAudienceSelect] = useState([]);
  const [AudienceData, setAudienceData] = useState([]);
  const [AudiencePage, setAudiencePage] = useState([]);

  //const [UserSelect, setUserSelect] = useState([]);
  const [UserQuery, setUserQuery] = useState([]);
  const [UserQueryBase, setUserQueryBase] = useState([]);
  const [UserPage, setUserPage] = useState([]);

  const [search, setSearch] = useState("");
  const [searcher, setSearcher] = useState([]);
  const [update, setUpdate] = useState(false);
  const [filterUsersPending, setFilterUsersPending]  = useState(false);
  const [filterUsersDeleted, setFilterUsersDeleted] = useState(false);
  const [filterUsersFirstLogin, setFilterUsersFirstLogin] = useState(false);
  const [filterUsersNoFirstLogin, setFilterUsersNoFirstLogin] = useState(false);
  const [audienceSelected, setAudienceSelected] = useState(null);
  const [showCards, setShowCards] = useState(false);
  const [loadingData, setLoadingData] = useState(true);


  /// ---------- Queries ---------- ///

  useEffect(() => {
    const getRolData = async (query) => {
      const info = await GetUserRol(query);
      setRolData(info.data);
      setRolPage(info.page);
    };

    getRolData([
      { key: "active", value: "1" },
      { key: "page_size", value: 500 },
    ]);

  }, [update]); //rol

  useEffect(async () => {
    const GetUserData = async (query) => {
      const info = await getUser(query);
      let data = info.data;
      setUserPage(info.page);
      const compareNames = (a, b) => {
        let nameA = a.name;
        let nameB = b.name;
        if(nameA == null  || nameB == null) return 0
        let result = nameA.localeCompare(nameB);
        return result;
      }
      let dataFiltered = data.filter((user, index) => {
        return index === data.findIndex(o => user.uuid == o.uuid);
      });
      dataFiltered.sort(compareNames);
      let usersFiltered = []
      setUserQueryBase(dataFiltered);
      if(dataFiltered.length > 0){
        dataFiltered.forEach(user => {
          if(user.active && user.active == 1){
            usersFiltered.push(user);
          }
        })
      }
      setUserQuery(usersFiltered);
    };

    await GetUserData([
      // { key: "active", value: "1" },
      { key: "page_size", value: 10000 },
    ]);
    setLoadingData(false);
  }, [update]); // user

  useEffect(() => {
    const GetUserByAudienceData = async (audienceSelected) => {
      let info;
      if(audienceSelected == null){
        info = await getUser([ { key: "page_size", value: 10000 } ]);
      }else{
        info = await usersByAudience(_get(audienceSelected, 'uuid', null));
      }
      setUserPage(info.page);
      const compareNames = (a, b) => {
        let nameA = a.name;
        let nameB = b.name;
        if(nameA == null  || nameB == null) return 0
        let result = nameA.localeCompare(nameB);
        return result;
      }
      info.data = info.results ? info.results : info.data ? info.data : [];
      // info.data.sort(compareNames);
      let usersFiltered = []
      setUserQueryBase(info.data);
      if(info.data.length > 0){
        info.data.forEach(user => {
          if(user.active && user.active == 1){
            usersFiltered.push(user);
          }
        })
      }
      setUserQuery(usersFiltered);
    };
    GetUserByAudienceData(audienceSelected);
  }, [audienceSelected]); //filter audience

  useEffect(() => {
    if(filterUsersDeleted){
      setUserQuery(UserQueryBase);
      let usersFiltered = [];
      if(UserQueryBase.length > 0){
        UserQueryBase.forEach(user => {
          if(user.active == 0){
            usersFiltered.push(user);
          }
        })
      }
      setUserQuery(usersFiltered);
    }
    if(!filterUsersDeleted){
      let usersFiltered = []
      if(UserQueryBase.length > 0){
        UserQueryBase.forEach(user => {
          if(user.active && user.active == 1){
            usersFiltered.push(user);
          }
        })
      }
      setUserQuery(usersFiltered);
    }
  }, [filterUsersDeleted]);

  useEffect(() => {
    setUserQuery(UserQueryBase);
    let usersFiltered = [];
    if(UserQueryBase.length > 0){
      UserQueryBase.forEach(user => {
        if(user.active == null){
          usersFiltered.push(user);
        }
      })
    }
    setUserQuery(usersFiltered);
  }, [filterUsersPending]);

  //from front
  useEffect(async () => {
    setUserQuery(UserQueryBase);
    let usersFiltered = [];
    if(UserQueryBase.length > 0){
      UserQueryBase.forEach(user => {
        if(user.properties && user.properties.tc){
          usersFiltered.push(user);
        }
      })
    }
    setUserQuery(usersFiltered);
  }, [filterUsersFirstLogin]);

  useEffect(async () => {
    setUserQuery(UserQueryBase);
    let usersLoginFirstApp = [];
    if(UserQueryBase.length > 0){
      UserQueryBase.forEach(user => {
        if(user.properties && !user.properties.tc){
          usersLoginFirstApp.push(user);
        }
      })
    }
    setUserQuery(usersLoginFirstApp);
  }, [filterUsersNoFirstLogin]);

  //from back 
//   useEffect(async () => {
//       setUserQuery(UserQueryBase);
//       const _configs = await getConfigs();
//       let config = _configs.data;
//       let tc = _get(config.find((element) => { return element.name.includes("tc.")}), "name", '');  
//       let appDefaultMedium = _get(config.find((element) => { return element.name === "app.default_medium"}), "value", '');  
//       tc = tc.split('.');
//       let usersLoginFirstApp = await getUserSearch(tc.length ? tc[2] : '', appDefaultMedium, 'eq');
//       usersLoginFirstApp = _get(usersLoginFirstApp, 'data', []);
//       let usersFiltered = [];
//       if(usersLoginFirstApp.length > 0){
//         usersLoginFirstApp.forEach(user => {
//           usersFiltered.push(user);
//         })
//       }
//       setUserQuery(usersFiltered);
//   }, [filterUsersFirstLogin]);

//   useEffect(async () => {
//     setUserQuery(UserQueryBase);
//     const _configs = await getConfigs();
//     let config = _configs.data;
//     let tc = _get(config.find((element) => { return element.name.includes("tc.")}), "name", '');  
//     let appDefaultMedium = _get(config.find((element) => { return element.name === "app.default_medium"}), "value", '');  
//     tc = tc.split('.');
//     let usersLoginNoFirstApp = await getUserSearch(tc.length ? tc[2] : '', appDefaultMedium, 'eq');
//     usersLoginNoFirstApp = _get(usersLoginNoFirstApp, 'data', []);
//     let usersFiltered = [];
//     if(usersLoginNoFirstApp.length > 0 && UserQueryBase.length > 0){
//       UserQueryBase.forEach(user => {
//         let userLogged = false
//         usersLoginNoFirstApp.forEach(userloggedApp => {
//           if(user.active &&  user.active == 1 && user.uuid == userloggedApp.uuid) userLogged = true;
//         })
//         if(!userLogged) usersFiltered.push(user);
//       });
//     }
//     setUserQuery(usersFiltered);
// }, [filterUsersNoFirstLogin]);

  useEffect(() => {
    const GetAudienceData = async (query) => {
      const info = await getAudience(query);
      let filtroActive = _get(info, "data", []).filter((audience) => audience.active === 1);
      const compareNames = (a, b) => {
        let nameA = a.name;
        let nameB = b.name;
        let result = nameA.localeCompare(nameB);
        return result;
      }
      filtroActive.sort(compareNames);
      setAudienceData(filtroActive);
      setAudiencePage(info.page);
    };
    GetAudienceData([{ key: "page_size", value: 500 }]);
  }, [update]); //audiences
  /// ---------- End Queries ---------- ///

  /// ---------- Search User ---------- ///


  useEffect(() => {
    if (search.length > 0) {
      let dataToSearch = UserQuery;
      if(tabActive == "AudienceCommunity") dataToSearch = AudienceData;
      let searcherFilter = dataToSearch.filter((data) => {
      let dataSearched = "", searchText;
        switch (tabActive) {
          case "UserCommunity":
            if (data.name != null || data.name != undefined) {
              dataSearched += data.name.toLowerCase();
            } else {
              dataSearched += "";
            }
            dataSearched += " ";

            if (data.middle_name != null || data.middle_name != undefined) {
              dataSearched += data.middle_name.toLowerCase();
            } else {
              dataSearched += "";
            }
            dataSearched += " ";

            if (data.last_name != null || data.last_name != undefined) {
              dataSearched += data.last_name.toLowerCase();
            } else {
              dataSearched += "";
            }
            dataSearched += " ";

            if (data.emails[0].email != null || data.emails[0].email != undefined) {
              dataSearched += data.emails[0].email.toLowerCase();
            } else {
              dataSearched += "";
            }
            dataSearched += " ";

            break;
          case "AudienceCommunity":
            if (data.name != null || data.name != undefined) {
              dataSearched += data.name.toLowerCase();
            } else {
              dataSearched += "";
            }
            break;
          default:
            break;
        }

        searchText = search.toLowerCase();

        return dataSearched.includes(searchText);
      });
      setSearcher(searcherFilter);
    }else{
      setSearcher(UserQuery);
    }
  }, [search.length]);

  /// ---------- End Search User ---------- ///

  function modalConfig(value) {
    if (value.includes("role")) {
      // Configurar role
      let roleUuid = value.substring(5);

      setRolSelect(
        RolData.filter((role) => {
          let idRole = role.uuid;
          return idRole.includes(roleUuid);
        })
      );

      setOpenModalRoleConfig(true);
    } else {

      // Configurar Usuario
      setSettingUserValue(
        UserQuery.filter((user) => {
          let idUser = user.uuid;
          return idUser.includes(value);
        })
      );
      setOpenModalCommunityConfig(true);
    }
  }

  function modalDelete(value) {
    // Borrar Usuario

    setSettingUserValue(
      UserQuery.filter((user) => {
        let idUser = user.uuid;
        return idUser.includes(value);
      })
    );

    setOpenModalCommunityDelete(true);
  }

  return (
    <UsersCommunityContext.Provider
      value={{
        setTabActive,
        tabActive,
        UsersSelectItem,
        setUsersSelectItem,
        RolSelect,
        setRolSelect,
        AudienceSelect,
        setAudienceSelect,
        //UserSelect, setUserSelect,
        search,
        setSearch,
        searcher,
        RolData,
        RolPage,
        AudienceData,
        AudiencePage,
        UserQuery,
        UserPage,
        modalConfig,
        modalDelete,
        update,
        setUpdate,
        filterUsersDeleted,
        setFilterUsersDeleted,
        filterUsersPending,
        setFilterUsersPending,
        filterUsersFirstLogin,
        setFilterUsersFirstLogin,
        filterUsersNoFirstLogin,
        setFilterUsersNoFirstLogin,
        audienceSelected,
        setAudienceSelected,
        showCards, 
        setShowCards,
        loadingData,
        setLoadingData
      }}
    >
      {props.children}

      {openModalCommunityConfig ? (
        <Modal>
          <ConfigureUser
            settingUserValue={settingUserValue}
            setOpenModalCommunityConfig={setOpenModalCommunityConfig}
          />
        </Modal>
      ) : (
        ""
      )}

      {openModalCommunityDelete ? (
        <Modal>
          <DeleteUser
            RolSelect={RolSelect}
            settingUserValue={settingUserValue}
            setOpenModalCommunityDelete={setOpenModalCommunityDelete}
          />
        </Modal>
      ) : (
        ""
      )}

      {openModalRoleConfig ? (
        <Modal>
          <ConfigureRole
            RolSelect={RolSelect}
            setOpenModalRoleConfig={setOpenModalRoleConfig}
          />
        </Modal>
      ) : null}
    </UsersCommunityContext.Provider>
  );
}

export { UsersCommunityContext, UsersCommunityProvider };
