//Localization
// import { useTranslation, withTranslation  } from 'react-i18next';

function LocalizeSelectorLayout(layoutName, t) {
  let data = [];
  switch (layoutName) {
    case "STANDARD HEADER LAYOUT":
      data[0] = t('layout.standarHeaderLayoutTitle');
      data[1] = t('layout.standarHeaderLayoutDescription');
      break;
    case "HEADER BUTTON LAYOUT":
      data[0] = t('layout.headerButton');
      data[1] = t('layout.headerButtonDescription');
      break;
    case "HEADER LOGO LAYOUT":
      data[0] = t('layout.headerLogoTitle');
      data[1] = t('layout.headerLogoDescription');
      break;
    case "LEFT MENU HEADER LAYOUT":
      data[0] = t('layout.headerMenuLeft');
      data[1] = t('layout.headerMenuLeftLayoutDescription');
      break;
    case "STANDARD HOME LAYOUT":
      data[0] = t('layout.homeSlideImagesLayoutTitle');
      data[1] = t('layout.homeOneImageLayoutTitle');
      break;
    case "HOME ONE IMAGE LAYOUT":
      data[0] = t('layout.homeOneImageLayoutTitle');
      data[1] = t('layout.homeOneImageLayoutTitle');
      break;
    case "HOME BANNER LAYOUT":
      data[0] = t('layout.galleryCarouselTitle');
      data[1] = t('layout.galleryCarouselDescription');
      break;
    case "HOME BACKGROUND IMAGE LAYOUT":
      data[0] = t('layout.homeOneImageLayoutTitle');
      data[1] = t('layout.homeOneImageLayoutDescription');
      break;
    case "HOME SLIDE IMAGES LAYOUT":
      data[0] = t('layout.homeSlideImagesLayoutTitle');
      data[1] = t('layout.homeSlideImagesLayoutDescription');
      break;
    case "HOME VIDEO LAYOUT":
      data[0] = t('layout.homeVideoLayoutTitle');
      data[1] = t('layout.homeVideoLayoutDescription');
      break;
    case "STANDARD ABOUT LAYOUT":
      data[0] = t('layout.standarAboutUsLayoutTitle');
      data[1] = t('layout.standarAboutUsLayoutDescription');
      break;
    case "ABOUT US SIDE BY SIDE LAYOUT":
      data[0] = t('layout.bySideAboutUsLayoutTitle');
      data[1] = t('layout.bySideAboutUsLayoutDescription');
      break;
    case "STANDARD EVENTS LAYOUT":
      data[0] = t('layout.standarEventsLayoutTitle');
      data[1] = t('layout.standarEventsLayoutDescription');
      break;
    case "EVENTS IMAGE LEFT LAYOUT":
      data[0] = t('layout.eventsImageLeftLayoutTitle');
      data[1] = t('layout.eventsImageLeftLayoutDescription');
      break;
    case "EVENTS IMAGE RIGTH LAYOUT":
      data[0] = t('layout.eventsImageRigthLayoutTitle');
      data[1] = t('layout.eventsImageRigthLayoutDescription');
      break;
    case "EVENTS CARDS LAYOUT":
      data[0] = t('layout.eventsCardsLayoutTitle');
      data[1] = t('layout.eventsCardsLayoutDescription');
      break;
    case "EVENTS CAROUSEL LAYOUT":
      data[0] = t('layout.eventsCarouselLayoutTitle');
      data[1] = t('layout.eventsCarouselLayoutDescription');
      break;
    case "EVENT CALENDAR LAYOUT":
      data[0] = t('layout.eventsCalendarlLayoutTitle');
      data[1] = t('layout.eventsCalendarlLayoutTitle');
      break;
    case "STANDARD NEWS LAYOUT":
      data[0] = t('layout.standarNewsLayoutTitle');
      data[1] = t('layout.standarNewsLayoutDescription');
      break;
    case "NEWS IMAGE LEFT LAYOUT":
      data[0] = t('layout.newsImageLeftLayoutTitle');
      data[1] = t('layout.newsImageLeftLayoutDescription');
      break;
    case "NEWS DISPLAY GRID LAYOUT":
      data[0] = t('layout.newsDisplayGridLayoutTitle');
      data[1] = t('layout.newsDisplayGridLayoutDescription');
      break;
    case "NEWS CARDS LAYOUT":
      data[0] = t('layout.newsCardsLayoutTitle');
      data[1] = t('layout.newsCardsLayoutDescription');
      break;
    case "NEWS CAROUSEL LAYOUT":
      data[0] = t('layout.newsCarouselLayoutTitle');
      data[1] = t('layout.newsCarouselLayoutDescription');
      break;
    case "NEWS BACKGROUND IMAGE LAYOUT":
      data[0] = t('layout.newsTextOverImagesLayoutTitle');
      data[1] = t('layout.newsTextOverLayoutDescription');
      break;
    case "EVENTS BACKGROUND IMAGE LAYOUT":
      data[0] = t('layout.newsTextOverImagesLayoutTitle');
      data[1] = t('layout.newsTextOverLayoutDescription');
      break;
    case "COURSES BACKGROUND IMAGE LAYOUT":
      data[0] = t('layout.newsTextOverImagesLayoutTitle');
      data[1] = t('layout.newsTextOverLayoutDescription');
      break;      
    case "STANDARD COURSES LAYOUT":
      data[0] = t('layout.standarCoursesLayoutTitle');
      data[1] = t('layout.standarCoursesLayoutDescription');
      break;
    case "COURSES IMAGE LEFT LAYOUT":
      data[0] = t('layout.coursesImageLeftLayoutTitle');
      data[1] = t('layout.coursesImageLeftLayoutDescription');
      break;
    case "COURSES DISPLAY GRID":
      data[0] = t('layout.coursesDisplayGridLayoutTitle');
      data[1] = t('layout.coursesDisplayGridLayoutDescription');
      break;
    case "COURSES CARDS LAYOUT":
      data[0] = t('layout.coursesTitleCardsLayoutTitle');
      data[1] = t('layout.coursesTitleCardsLayoutDescription');
      break;
    case "COURSES CAROUSEL LAYOUT":
      data[0] = t('layout.coursesCarouselLayoutTitle');
      data[1] = t('layout.coursesCarouselLayoutDescription');
      break;
    case "STANDARD DONATIONS LAYOUT":
      data[0] = t('layout.standarDonationsLayoutTitle');
      data[1] = t('layout.standarDonationsLayoutDescription');
      break;
    case "DONATIONS IMAGE LEFT LAYOUT":
      data[0] = t('layout.donationsImageLeftLayoutTitle');
      data[1] = t('layout.donationsImageLeftLayoutDescription');
      break;
    case "DONATIONS DISPLAY GRID":
      data[0] = t('layout.donationsDisplayGridLayoutTitle');
      data[1] = t('layout.donationsDisplayGridLayoutDescription');
      break;
    case "STANDARD GALLERY LAYOUT":
      data[0] = t('layout.standarGalleryLayoutTitle');
      data[1] = t('layout.standarGalleryLayoutDescription');
      break;
    case "GALLERY MOVE IMAGE LAYOUT":
      data[0] = t('layout.galleryMoveImageTitle');
      data[1] = t('layout.galleryMoveImageDescription');
      break;
    case "GALLERY BLACK AND WHITE IMAGE":
      data[0] = t('layout.galleryBlackAndWhiteTitle');
      data[1] = t('layout.galleryBlackAndWhiteDescription');
      break;
    case "GALLERY CAROUSEL LAYOUT":
      data[0] = t('layout.galleryCarouselTitle');
      data[1] = t('layout.galleryCarouselDescription');
      break;
    case "STANDARD TEAM LAYOUT":
      data[0] = t('layout.standarTeamLayoutTitle');
      data[1] = t('layout.standarTeamLayoutDescription');
      break;
    case "TEAM PHOTO AND NAME LAYOUT":
      data[0] = t('layout.teamLayoutCarouselImageTitle');
      data[1] = t('layout.teamLayoutCarouselImageDescription');
      break;
    case "TEAM LAYOUT SCROLL DOWN":
      data[0] = t('layout.teamLayoutScrollDownTitle');
      data[1] = t('layout.teamLayoutScrollDownDescription');
      break;
    case "TEAM LAYOUT SCROLL UP":
      data[0] = t('layout.teamLayoutScrollUpTitle');
      data[1] = t('layout.teamLayoutScrollUpDescription');
      break;
    case "TEAM LAYOUT DIAGONALLY LAYOUT":
      data[0] = t('layout.teamLayoutDiagonallyTitle');
      data[1] = t('layout.teamLayoutDiagonallyDescription');
      break;
    case "TEAM FLIP CARD LAYOUT":
      data[0] = t('layout.teamFlipCardLayoutTitle');
      data[1] = t('layout.teamFlipCardLayoutDescription');
      break;
    case "TEAM CARD LAYOUT":
      data[0] = t('layout.galleryCarouselTitle');
      data[1] = t('layout.galleryCarouselDescription');
      break;
    case "STANDARD STREAMINGS LAYOUT":
      data[0] = t('layout.standarStreamingsLayoutTitle');
      data[1] = t('layout.standarStreamingsLayoutDescription');
      break;
    case "ONE COLUMN LINKTREE LAYOUT":
      data[0] = t('layout.standarLinktreeLayoutTitle');
      data[1] = t('layout.standarLinktreeLayoutDescription');
      break;
    case "TWO COLUMN LINKTREE LAYOUT":
      data[0] = t('layout.twoColumnsLinktreeLayoutTitle');
      data[1] = t('layout.twoColumnsLinktreeLayoutDescription');
      break;
      
    case "LINKTREE BUTTONS TOGETHER LAYOUT":
      data[0] = t('layout.buttonsTogetherLinktreeLayoutTitle');
      data[1] = t('layout.buttonsTogetherLinktreeLayoutDescription');
      break;
    case "STANDARD SOCIAL NETWORKS LAYOUT":
      data[0] = t('layout.standarSocialNetworksLayoutTitle');
      data[1] = t('layout.standarSocialNetworksLayoutDescription');
      break;
      case "HOME BUTTONS LAYOUT":
        data[0] = t('layout.homeButtonsLayout');
        data[1] = t('layout.homeButtonsLayoutDescription');
        break;
      default:
        data[0] = t('layout.newLayout');
        data[1] = t('layout.newLayoutDescription');
  }
  return data;
}
export default (LocalizeSelectorLayout)