import React, { useState, useEffect, useContext } from "react";

import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';

import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";

import "./css/configureUser.css";

//Context
import { UsersCommunityProvider, UsersCommunityContext } from '../contex';

function ConfigureUser( props ) {

    const { RolData } = useContext(UsersCommunityContext);

    let open = props.setOpenModalCommunityConfig;
    let user = props.settingUserValue[0];

    let avatar;
    if ( user.avatar === null ) { avatar = user.name.charAt(0).toUpperCase(); } else { avatar = user.avatar.thumbnail }

    let email = (user.emails.filter(data => data.uuid.includes(user.email)))[0].email;
    
    //console.log(user);
    
    function closeModal() { open(false); }
/*
    document.addEventListener('click', function(event) {
        if(event.target.className === "ModalBackground"){
            open(false);
        }
      });
*/
    return(
        <UsersCommunityProvider>
        <div className="modalConfigureUser">
            <div className="titleModalConfigureUser">
                <div className="textTitleModalConfigureUser">Configurar usuario</div>
                <div className="closeModalConfigureUser" onClick={closeModal} role="button"><Close/></div>
            </div>
            
            <hr className="lines"/>

            <div className="contentModalConfigureUser">

                <div className="imageContentModalConfigureUser">
                    {avatar.length > 1
                    ? <img src={avatar} alt="avatar"/>
                    : avatar
                    }
                </div>
                <div className="infoContentModalConfigureUser">
                    <div className="nameInfoContentModalConfigureUser half">
                        <div className="labelForm">Nombre</div>
                        <input type="text" name="name" defaultValue={user.name} className="inputContentModalConfigureUser"></input>
                    </div>
                    <div className="last_nameInfoContentModalConfigureUser half">
                        <div className="labelForm">Apellido</div>
                        <input type="text" id="last_name" defaultValue={user.last_name} className="inputContentModalConfigureUser"></input>
                    </div>
                    <div className="mailInfoContentModalConfigureUser twoHalf">
                        <div className="labelForm">Correo electrónico</div>
                        <input type="mail" id="email" value={email} readOnly className="inputContentModalConfigureUser input_twoHalf"></input>
                    </div>
                    <div className="birthdayInfoContentModalConfigureUser half">
                        <div className="labelForm">Fecha de nacimiento</div>
                        <input type="date" id="birthday" defaultValue={user.birthday} className="inputContentModalConfigureUser"></input>
                    </div>
                    <div className="genderInfoContentModalConfigureUser half">
                        <div className="labelForm">Género</div>
                        <select id="gender" defaultValue={user.gender} className="inputContentModalConfigureUser">
                            <option value="H">Hombre</option>                                
                            <option value="F">Mujer</option>
                            <option value="O">Otro</option>
                        </select>
                    </div>
                    <div className="rolesInfoContentModalConfigureUser twoHalf">
                        <div className="labelForm">Tipo de rol</div>
                        <select id="roles" className="inputContentModalConfigureUser input_twoHalf">
                            {RolData.map(item => (
                                <option key={item.uuid} value={item.uuid}>{item.name}</option>                                
                            ))}
                        </select>
                    </div>
                    <div className="main_activityInfoContentModalConfigureUser twoHalf">
                        <div className="labelForm">Cargo en la empresa</div>
                        <input type="text" id="main_activity" defaultValue={user.main_activity} className="inputContentModalConfigureUser input_twoHalf"/>
                    </div>
                    <div className="mediumsInfoContentModalConfigureUser twoHalf">
                        <div className="labelForm">Canal favorito</div>
                        <input type="text" id="mediums" className="inputContentModalConfigureUser input_twoHalf"/>
                    </div>
                </div>

            </div>
            <div className="actionContentModalConfigureUser">
                <SecondaryButton txt="Actualizar"/>
            </div>            
        </div>
        </UsersCommunityProvider>
    );
}

export default ConfigureUser;
