import React from "react";
import { get as _get } from "lodash";

// components
import Avatar from '@mui/material/Avatar';

// css
import "./css/checkUserSelect.css";

// Queries

function CheckUserSelect(props) {
  const email = _get(props.user, 'emails', []).find(_email => _email.uuid == _get(props.user, 'email', ''))
  return(    
    <div className="checkBoxContainer">
      <input
        type="checkbox"
        checked={props.checked}
        onChange={(e) => props.change(props.user)}
      />
      <div className="user-container">
        <Avatar
          src={_get(props.user, 'avatar.url', '')}
          sx={{ width: 40, height: 40, bgcolor: '#FFCB96', }}
        />
        <div className="perfil-container">
          <span className="name-perfil">{ _get(props.user, 'name', 'Nombre no definido') ?? 'Nombre no definido' }</span>
          <span className="email-perfil">{ _get(email, 'email', 'Email no definido') }</span>
        </div>
      </div>
    </div>
  );
};

export default CheckUserSelect;