import React, { useState, useEffect, useContext } from "react";
import { get as _get } from "lodash";

import "./css/column.css";
import Icon from '../../../uhoo-components/iconLibrary.jsx';

import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

// Queries
import { GetPost, updateUser } from "../../../actions/uhoo";

//Context
import { ActivityProvider, ActivityContext } from '../contex';

import Post from "./post.jsx";

//Localization
import { useTranslation } from 'react-i18next';

const Columns = (props) => {

  const { t, i18n } = useTranslation(); //  Localization    
  const { columnProperties, setColumnProperties, setModalEditColumn, updateAction } = useContext(ActivityContext);
  const [queriesData, setQueriesData] = useState([]);
  const [columnInfo, setColumnInfo] = useState({});
  const [pageData, setPageData] = useState(0);
  const [lastQueryPage, setLastQueryPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(true);

  const [queryError, setQueryError] = useState(false);

  //let indexColumn = columnProperties.findIndex((item) => item.id === props.item.id);

  /// ---------- Queries ---------- ///
  useEffect(() => {
    const getPostData = async (query) => {
      const info = await GetPost(query);

      try {

        let addQuery = [];
        let itemsInfo = {};

        if (pageData > lastQueryPage) { // evita que se cargue una consulta repetida en el array
          addQuery = [...queriesData, ...info.data];
          itemsInfo = { view: addQuery.length, total: info.page.total };
        } else /*if ( pageData === lastQueryPage )*/ {
          addQuery = info.data;
          itemsInfo = { view: addQuery.length, total: info.page.total };
        }

        if (addQuery.length >= info.page.total) {
          setLoadingMore(false); // no hay mas contenido
        } else { setLoadingMore(true); }

        setColumnInfo(itemsInfo);
        //console.log("addQuery", addQuery);
        //setQueriesData(...(new Set(addQuery))); /// filtro post repetidos en cunsulta
        setQueriesData(addQuery);
        //console.log("queriesData", queriesData);
      } catch (error) {
        //console.error(error);
        console.error("Error in column: " + props.item.name + " (\nid:" + props.item.id + ")\n\n", info);
        setQueryError(true);
      }
    };

    /// filtro post repetidos en cunsulta

    let query = []

    function stateFilter(filter) {
      //console.log("en Columna", filter)
      switch (filter) {
        case "approved":
          query.push({ key: "active", value: 1 })
          query.push({ key: "approved", value: 1 })
          break;
        case "disapproved":
          query.push({ key: "active", value: 1 })
          query.push({ key: "approved", value: 0 })
          break;
        case "toApproved":
          query.push({ key: "active", value: 1 })
          query.push({ key: "approved;nl", value: "" })
          break;
        case "drafts":
          query.push({ key: "active;nl", value: "" })
          // query.push({ key: "approved;nl", value: "" })
          break;
        case "deleted":
          // query.push({ key: "approved;nl", value: "" })
          query.push({ key: "active", value: 0 })
          break;
      }
    }

    switch (props.item.query) {
      case "state":
        query.push({ key: 'search' })
        props.item.filter.map((filter) => stateFilter(filter));
        query.push({ key: "page", value: pageData });
        query.push({ key: "page_size", value: 34 });
        getPostData(query);
        break;
      case "unfiltered":
        query.push({ key: "search" }, { key: "page", value: pageData }, { key: "active", value: 1 });
        getPostData(query);
        break;
      case "audience":
        props.item.filter.map((filter) => query.push({ key: "publishing.channel;in", value: filter }));
        query.push({ key: "search" }, { key: "active", value: "1" }, { key: "page", value: pageData });
        getPostData(query);
        break;
      case "date":
        query = props.item.filter;
        query.push({ key: "page", value: pageData });
        getPostData(query);
        break
      case "keyword":
        props.item.helper.map((helper) => query.push({ key: helper, value: ("%~"+props.item.filter+"%~") }));
        //props.item.helper.map((helper) => query.push({ key: helper, value: props.item.filter }));
        query.push({ key: "search" },{ key: "type;neq", value: "m"},{ key: "page", value: pageData });
        getPostData(query);
        break;
    }

  }, [pageData, columnProperties, updateAction]);

  /// ---------- End Queries ---------- ///
  /*
    useEffect(() => {
      //setPageData(0);
  }, [columnProperties]);
  */

  function loadMore() {
    setLoadingMore("load");
    setLastQueryPage(pageData);
    setPageData(pageData + 1);
  }

  function dropColumnAction(event) {
    document.getElementById("dropColumnAction-" + props.item.id).classList.toggle("show");
    event.stopPropagation();
  }

  async function deleteColumn() {
    let newCurrentColumns = columnProperties.filter((item) => item.id !== props.item.id);
    document.getElementById("dropColumnAction-" + props.item.id).classList.toggle("show");

    setColumnProperties(newCurrentColumns);
    // localStorage.setItem('columnProperties', JSON.stringify(newCurrentColumns)); //to context
    let own = JSON.parse(sessionStorage.own);
    let properties = {};
    properties.columnProperties = JSON.stringify(newCurrentColumns);
    let data = {properties};
    let result = await updateUser(data, false, true, _get(own, "uuid", "")); //save info user
    if(result.data[0]) sessionStorage.setItem("own", JSON.stringify(result.data[0]));
  }

  function editColumn() {
    document.getElementById("dropColumnAction-" + props.item.id).classList.toggle("show");
    setModalEditColumn({ state: true, id: props.item.id });
  }

  document.addEventListener('click', function (event) { // cerrar drop al dar click por fuera del elementos
    if (event.target.className !== "dropColumnAction-content" && event.target.id !== "columnAction" && event.target.className.baseVal !== "optionsICO") {
      let get = document.getElementById("dropColumnAction-" + props.item.id);
      if (get !== null) { get.classList.remove("show"); } // pevencion de errores 
    }
    event.stopPropagation();
  });

  return (
    <React.Fragment>
      <div draggable="true" className="columnContainer">
        <section className="columnHeader">
          {/*<div className="columnIco"></div>*/}
          <div className="columnTitle">{props.item.name}</div>
          <div className="rightColumnHeader">
            <div className="columnAction hover" onClick={dropColumnAction} role="button"><Icon.options className="optionsICO" /></div>
            {
              !queryError
                ? <div className="columnInfo">{columnInfo.view} de {columnInfo.total} Items</div>
                : ""
            }
          </div>

          <div className="dropColumnAction" id={"dropColumnAction-" + props.item.id}>
            <div className="dropColumnAction-content">
              <div className="itemDropColumnAction hover" onClick={editColumn} role="button">
                <Icon.edit />
                <div>{t('uhoo.activity.column.edit')}</div>
              </div>
              <div className="itemDropColumnAction hover" onClick={deleteColumn} role="button">
                <Icon.trash />
                <div>{t('uhoo.activity.column.trash')}</div>
              </div>
            </div>
          </div>

        </section>
        {
          !queryError
            ? (queriesData.length > 0
              ? (
                <section className="columnBody">
                  {queriesData.map((item) => <Post key={item.uuid + "-/-" + props.item.id} item={item} column={props.item.id} />)}
                  {
                    loadingMore === true
                      ? (
                        <div onClick={loadMore} className="loadMore pointerCursor" role="button" >
                          {t('uhoo.activity.column.loadMore')}
                        </div>
                      )
                      : null
                  }
                  {
                    loadingMore === false
                      ? (
                        <div className="loadMore">
                          {t('uhoo.activity.column.endContent')}
                        </div>
                      )
                      : null
                  }
                  {
                    loadingMore === "load"
                      ? (
                        <div className="load">
                            <LinearProgress color="inherit" />
                        </div>
                      )
                      : null
                  }
                </section>
              )
              : (
                <div className="notContent">{t('uhoo.activity.column.notContent')}</div>
              ))
            : (<div className="notContent"> :( <br /><br /> {t('uhoo.activity.column.error')} <br /> {t('uhoo.activity.column.warning')} </div>)
        }
      </div>
    </React.Fragment>
  );
};

export default Columns;

