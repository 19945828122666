import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import "../css/sections.scss";

import SelectLayoutModal from "../../../../aura-components/SelectLayoutModal.jsx";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// Queries
import {
  getSectionInformationBetweenDates,
  updateLpSectionComponentNode,
  insertSectionComponent,
  disableLpSectionComponent,
  updateSectionComponent,
  getFile,
  updateLpSectionComponentNodeImage,
  getSectionLayoutStyle,
  updateLpSectionLayout,
  updateLpSection,
} from "../../../../actions/aura";

import SystemNotification from "../../../global/SystemNotification";

import { Trash2, Plus, Edit2, X } from "react-feather";

// Components
import {
  Title,
  ButtonComponent,
  BreadcrumbSection,
  GeneralInput,
  DeleteItemModal,
  GeneralModal,
  AccordionComponent,
  DateTimePickerComponent,
  ButtonsFooterComponent
} from "../../../../aura-components";
import editorConfiguration from "../../../../aura-components/textEditorConfig";

import ButtonsContainer from "../../../../aura-components/ButtonsContainer.jsx";

import { Card, CircularProgress, TextField, Tooltip } from "@material-ui/core";

import { backendDateFormat } from "../../../../reactUtils/systemVariables/generalVariables";
import { handleChangeImageFunction } from "../../../../reactUtils/generalUtils/handleImageChange.jsx";

//Localization
import { withTranslation } from "react-i18next";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;

class CoursesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,

      lpSectionId: null,
      sectionName: null,
      landingPageId: null,
      organizationId: null,
      organizationName: null,
      country: null,
      region: null,
      city: null,

      startDate: moment().startOf("month"),
      endDate: moment().startOf("month").add(1, "months"),
      pageIdx: 1,
      pageSize: 4,
      totalElements: 0,

      loadingSection: true,
      loadingInfo: true,

      isAddModalVisible: false,
      isEditModalVisible: false,

      courseContainerTitle: {
        id: null,
        value: null,
        nodeTypeName: null,
        modified: false,
      },

      courseToAdd: {
        id: null,
        title: "",
        text: "",
        date: "",
        dateToShow: "",
        place: "",
        responsible: "",
        image: null,
        imageLoading: false,
      },
      coursesToShow: [],
      newMultimedia: {
        id: null,
        s3path: null,
        imageUrl: null,
        image: null,
        loading: false,
        modified: false,
      },
      lpLayoutImg: null,
      FlagClassName: false,

      parentLPSectionComponentId: null,
      isLoadingButton: false,
      goToWithoutSaveModalVisible: false,

      //Modal notification
      haveChangeInLayout: false,
      openNotificationModal: false,
      titleNotificationModal: "",
      contentNotificationModal: "",
      typeNotificationModal: "",

      //Modal general
      openDeleteImage: false,
      openDeleteNews: false,
      elementToDeleteId: null,

      //LayoutSelector
      newLayoutId: null,
      oldLayoutId: null,

      isReadyToEdit: false,
      deleteFlag: false,

      //Name modal
      openModalToChangeName: false,
      customName: "",

      //Get image or video
      elementToEditMultimedia: {
        id: null,
        s3path: null,
        file: null,
        image: null,
        loading: false,
        value: null,
        imageUrl: null,
        videoUrl: null,
        edited: false,
      },

      //Select image or video modals edit
      openImageOrVideoEditModal: false,
      openImageFileOrLinkEditModal: false,
      openImageFileEditModal: false,
      openImageLinkEditModal: false,
      openSelectVideoEditModal: false,

      //Select image or video modals edit
      openImageOrVideoAddModal: false,
      openImageFileOrLinkAddModal: false,
      openImageFileAddModal: false,
      openImageLinkAddModal: false,
      openSelectVideoAddModal: false,

      // Modals
      showSuccess: false,
      showWarning: false,
      showError: false,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { props } = this.props.location;
    const { startDate, endDate } = this.state;
    if (props) {
      this.getCoursesInformation(
        props.landingPageId,
        props.sectionId,
        startDate,
        endDate
      );

      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
      this.handleSelectLayout();
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getCoursesInformation(lpId, lpSectionId, startDate, endDate) {
    const { pageIdx, pageSize } = this.state;
    const response = await getSectionInformationBetweenDates(
      lpId,
      lpSectionId,
      startDate.format(),
      endDate.format()
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      const sectionInfo = response.data.data;
      // Get all the information about the section components
      const courseContainerComponent = sectionInfo.components.find(
        (comp) => comp.name === "CoursesContainerComponent"
      );
      const courseContainerTitle = {
        id: courseContainerComponent.nodes.find((node) => node.nodeType === "CourseContainerTitle").id,
        value: courseContainerComponent.nodes.find((node) => node.nodeType === "CourseContainerTitle").value,
        nodeTypeName: courseContainerComponent.nodes.find((node) => node.nodeType === "CourseContainerTitle").nodeType,
        minLength: courseContainerComponent.nodes.find((node) => node.nodeType === "CourseContainerTitle").minLength,
        maxLength: courseContainerComponent.nodes.find((node) => node.nodeType === "CourseContainerTitle").maxLength,
        length: courseContainerComponent.nodes.find((node) => node.nodeType === "CourseContainerTitle").value.length,
      };
      let courses = sectionInfo.components.filter(
        (comp) => comp.name === "CoursesComponent"
      );
      courses = courses.sort(this.compareEventDates);

      const parentLPSectionComponentId = sectionInfo.id;

      const totalElements = courses.length;

      this.setChildComponentsInfoPaged(courses, pageIdx, pageSize);

      //Get layout image
      let subPage = null;
      if (this.state.subpageId && this.state.subpageId !== 10001) {
        subPage = sectionInfo.subpages.find(
          (sp) => sp.id === this.state.subpageId
        );
      } else {
        subPage = sectionInfo.subpages.find((sp) => sp.id === null);
      }
      let subpageIndex = sectionInfo.subpages.indexOf(subPage);
      if (subpageIndex === -1) {
        subpageIndex = 0;
      }
      let layout =
        response.data.data.subpages[subpageIndex].lpSectionLayoutStyle
          .sectionLayoutId;
      let layoutImg = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" + layout.s3ImagePath
      );

      let customName =
        response.data.data.customName !== null
          ? response.data.data.customName
          : this.state.sectionName;

      this.setState({
        customName,
        courseContainerTitle,
        courses,
        totalElements,
        parentLPSectionComponentId,
        lpSectionLayoutStyle: layout,
        lpLayoutImg: layoutImg,
      });
    }
  }

  async updateLpSection(LpSubpageDto) {
    let response = await updateLpSection(LpSubpageDto);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
    }
    this.setState({
      newCustomName: "",
      customName: LpSubpageDto.customName,
      openModalToChangeName: false,
    });
  }

  async setChildComponentsInfoPaged(courses, pageIdx, pageSize) {
    // Get information of each of the courses
    const coursesList = [];
    for (let i = 0; i < courses.length; i++) {
      if (!courses[i].multimedia) {
        const multimediaNode = courses[i].nodes.find((node) => node.nodeType === "CourseImage");
        if (multimediaNode) {
          if (multimediaNode.value != null && multimediaNode.value !== "") {
            // Get image or video
            var responseMultimedia = null;
            if (!multimediaNode.value.includes("https://")) {
              // checks if it's an uploaded image
              responseMultimedia = await getFile(
                "https://somosaura-cms.s3.amazonaws.com/" + multimediaNode.value
              );
              multimediaNode.image = responseMultimedia;
              multimediaNode.loading = false;
            } else if (
              !multimediaNode.value.includes("https://www.youtube.com/watch?")
            ) {
              // checks if it's an image link
              responseMultimedia = await getFile(multimediaNode.value);
              multimediaNode.imageUrl = responseMultimedia;
              multimediaNode.loading = false;
            } else if (
              multimediaNode.value.includes("https://www.youtube.com/watch?")
            ) {
              // checks if it's video link
              multimediaNode.videoUrl = multimediaNode.value;
            } else {
              multimediaNode.image = null;
              multimediaNode.loading = false;
            }
            courses[i].multimedia = multimediaNode;
          }
        }
      }
      coursesList.push(courses[i]);
    }
    const coursesToShow = [];
    coursesList.forEach((element) => {
      const course = {
        id: element.id,
        titleId: element.nodes.find((node) => node.nodeType === "CourseTitle")
          ? element.nodes.find((node) => node.nodeType === "CourseTitle").id
          : null,
        title: element.nodes.find((node) => node.nodeType === "CourseTitle")
          ? element.nodes.find((node) => node.nodeType === "CourseTitle").value
          : null,
        titleMinLength: element.nodes.find((node) => node.nodeType === "CourseTitle")
          ? element.nodes.find((node) => node.nodeType === "CourseTitle")
            .minLength
          : null,
        titleMaxLength: element.nodes.find((node) => node.nodeType === "CourseTitle")
          ? element.nodes.find((node) => node.nodeType === "CourseTitle")
            .maxLength
          : null,
        titleLength: element.nodes.find((node) => node.nodeType === "CourseTitle")
          ? element.nodes.find((node) => node.nodeType === "CourseTitle").value
            .length
          : null,

        textId: element.nodes.find((node) => node.nodeType === "CourseText")
          ? element.nodes.find((node) => node.nodeType === "CourseText").id
          : null,
        text: element.nodes.find((node) => node.nodeType === "CourseText")
          ? element.nodes.find((node) => node.nodeType === "CourseText").value
          : null,
        textMinLength: element.nodes.find((node) => node.nodeType === "CourseText")
          ? element.nodes.find((node) => node.nodeType === "CourseText")
            .minLength
          : null,
        textMaxLength: element.nodes.find((node) => node.nodeType === "CourseText")
          ? element.nodes.find((node) => node.nodeType === "CourseText")
            .maxLength
          : null,
        textLength: element.nodes.find((node) => node.nodeType === "CourseText")
          ? element.nodes.find((node) => node.nodeType === "CourseText").value
            .length
          : null,

        dateId: element.nodes.find((node) => node.nodeType === "CourseDate")
          ? element.nodes.find((node) => node.nodeType === "CourseDate").id
          : null,
        date: element.nodes.find((node) => node.nodeType === "CourseDate")
          ? element.nodes.find((node) => node.nodeType === "CourseDate").value
          : null,

        placeId: element.nodes.find((node) => node.nodeType === "CoursePlace")
          ? element.nodes.find((node) => node.nodeType === "CoursePlace").id
          : null,
        place: element.nodes.find((node) => node.nodeType === "CoursePlace")
          ? element.nodes.find((node) => node.nodeType === "CoursePlace").value
          : null,
        placeMinLength: element.nodes.find((node) => node.nodeType === "CoursePlace")
          ? element.nodes.find((node) => node.nodeType === "CoursePlace")
            .minLength
          : null,
        placeMaxLength: element.nodes.find((node) => node.nodeType === "CoursePlace")
          ? element.nodes.find((node) => node.nodeType === "CoursePlace")
            .maxLength
          : null,
        placeLength: element.nodes.find((node) => node.nodeType === "CoursePlace")
          ? element.nodes.find((node) => node.nodeType === "CoursePlace").value
            .length
          : null,

        responsibleId: element.nodes.find((node) => node.nodeType === "CourseResponsible")
          ? element.nodes.find((node) => node.nodeType === "CourseResponsible")
            .id
          : null,
        responsible: element.nodes.find((node) => node.nodeType === "CourseResponsible")
          ? element.nodes.find((node) => node.nodeType === "CourseResponsible")
            .value
          : null,
        responsibleMinLength: element.nodes.find((node) => node.nodeType === "CourseResponsible")
          ? element.nodes.find((node) => node.nodeType === "CourseResponsible")
            .minLength
          : null,
        responsibleMaxLength: element.nodes.find((node) => node.nodeType === "CourseResponsible")
          ? element.nodes.find((node) => node.nodeType === "CourseResponsible")
            .maxLength
          : null,
        responsibleLength: element.nodes.find((node) => node.nodeType === "CourseResponsible")
          ? element.nodes.find((node) => node.nodeType === "CourseResponsible")
            .value.length
          : null,

        multimedia: element.multimedia,
        buttons: element.buttons,
        edited: false,
      };
      coursesToShow.push(course);
    });

    coursesToShow.forEach((element, index) => {
      coursesToShow[index].date = moment(element.date).subtract(5, "hours");
      coursesToShow[index].date = moment(coursesToShow[index].date._d);
    });

    this.setState({
      coursesToShow,
      loadingInfo: false,
      loadingSection: false,
    });
    this.cleanStateVariables();
  }

  async uploadFileToS3Bucket(imageUrl, key) {
    await updateLpSectionComponentNodeImage(imageUrl, key);
  }

  async insertSectionComponentFunction(nodesToUpdate) {
    const { landingPageId, lpSectionId, startDate, endDate } = this.state;
    const response = await insertSectionComponent(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.courseSuccessfullyCreated"),
      });
      this.cleanStateVariables();

      this.getCoursesInformation(
        landingPageId,
        lpSectionId,
        startDate,
        endDate
      );
    }

    this.setState({
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  }

  async updateSectionComponentFunction(componentToUpdate) {
    const { landingPageId, lpSectionId, startDate, endDate } = this.state;
    const response = await updateSectionComponent(componentToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),

      });
    } else {
      if (this.state.openNotificationModal === false) {
        this.setState({
          showSuccess: true,
          contentNotificationModal: t("basicWords.modal.eventSuccessfullyEdited"),
        });
      }

      this.cleanStateVariables();

      this.getCoursesInformation(
        landingPageId,
        lpSectionId,
        startDate,
        endDate
      );
    }

    this.setState({
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  }

  async disableNodeFunction(id) {
    const { landingPageId, lpSectionId, startDate, endDate } = this.state;
    const response = await disableLpSectionComponent(id);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
      this.setState({
        isLoadingButton: false,
        loadingInfo: false,
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.eventSuccessfullyDisabled"),
      });

      this.getCoursesInformation(
        landingPageId,
        lpSectionId,
        startDate,
        endDate
      );
      this.setState({
        isLoadingButton: false,
        loadingInfo: false,
      });
    }
  }

  compareCourseDates(a, b) {
    const aDateStr = a.nodes.find((node) => node.nodeType === "CourseDate").value;
    const bDateStr = b.nodes.find((node) => node.nodeType === "CourseDate").value;
    const aDate = moment(aDateStr, backendDateFormat);
    const bDate = moment(bDateStr, backendDateFormat);
    if (bDate.isAfter(aDate)) {
      return -1;
    }
    if (aDate.isAfter(bDate)) {
      return 1;
    }
    return 0;
  }

  async getLayoutFunction(lpId) {
    let response = await getSectionLayoutStyle(lpId, 10);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      let responseData = response.data.data;
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].sampleLayoutImageS3Path) {
          //Get image
          let img = await getFile(
            "https://somosaura-cms.s3.amazonaws.com/" +
            responseData[i].sampleLayoutImageS3Path
          );
          responseData[i].img = img;
        }
      }
      this.setState({
        layoutData: responseData,
      });
    }
  }

  async updateNode(nodesToUpdate) {
    const { landingPageId, lpSectionId, startDate, endDate } = this.state;

    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.courseSuccessfullyEdited"),
      });
      this.getCoursesInformation(
        landingPageId,
        lpSectionId,
        startDate,
        endDate
      );
    }
    this.setState({ isLoadingButton: false });
  }

  async updateLpSectionLayoutFunction(lpSectionId, newLayoutId) {
    let response = await updateLpSectionLayout(lpSectionId, newLayoutId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
    }
    this.setState({ isLoadingButton: false });
  }

  // Events
  dateOnChangeEvent = (date) => {
    const { landingPageId, lpSectionId } = this.state;
    if (date == null) {
      this.setState({
        startDate: moment().startOf("month"),
        endDate: moment().startOf("month").add(1, "months"),
        loadingInfo: true,
      });
      this.getCoursesInformation(
        landingPageId,
        lpSectionId,
        moment().startOf("month"),
        moment().startOf("month").add(1, "months")
      );
    } else {
      this.setState({
        startDate: date[0],
        endDate: date[1],
        loadingInfo: true,
      });
      this.getCoursesInformation(
        landingPageId,
        lpSectionId,
        date[0],
        date[1].add(1, "months")
      );
    }
  };

  addCourseEvent = () => {
    this.setState({
      isAddModalVisible: true,
    });
  };

  // editCourseEvent = (e, row) => {
  //   // Make a copy of the state to send it to the modal to edit
  //   const course = { ...row };

  //   const newMultimedia = {
  //     id: course.imageId,
  //     s3path: null,
  //     imageUrl: null,
  //     image: course.image,
  //     loading: false,
  //   };
  //   this.setState({
  //     isEditModalVisible: true,
  //     course,
  //     newMultimedia,
  //   });
  // };

  handleChangeLayout = (e, id) => {
    this.setState({
      saveButtonDisabled: false,
      FlagClassName: true,
      lpSectionLayoutStyle: id,
      haveChangeInLayout: true,
    });
  };

  handleClickOpenDeleteImage = (index) => {
    if (index) {
      this.handleImageIndex(index);
      this.setState({ openDeleteImage: true });
    } else {
      this.setState({ openDeleteImage: true });
    }
  };

  handleImageIndex = (multimediaToEdit) => {
    const { coursesToShow } = { ...this.state };
    let elemToEdit = null;
    let imageIndex = null;
    if (multimediaToEdit && multimediaToEdit.id !== null) {
      elemToEdit = coursesToShow.find(
        (e) => e.multimedia.id === multimediaToEdit.id
      );
      if (coursesToShow.indexOf(elemToEdit) !== -1) {
        imageIndex = coursesToShow.indexOf(elemToEdit);
      }
    }
    this.setState({
      imageIndex,
    });
    this.handleOpenImageOrVideoModal(multimediaToEdit);
  };

  handleCloseDeleteImage = () => {
    this.setState({ openDeleteImage: false });
  };

  handleClickOpenDeleteNews = (id) => {
    this.setState({ openDeleteNews: true, elementToDeleteId: id });
  };

  handleCloseDeleteNews = () => {
    this.setState({ openDeleteNews: false });
  };

  changeToEdit = (e) => {
    e.stopPropagation();
    const { isReadyToEdit } = this.state;
    this.setState({ isReadyToEdit: !isReadyToEdit });
  };

  disableCourseEvent = (id) => {
    this.setState({
      loadingInfo: true,
      openDeleteNews: false,
    });
    this.disableNodeFunction(id);
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      showWarning: true,
      contentNotificationModal: t("basicWords.modal.noSectionSelected"),
    });
    this.handleOkWarning();
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleChangeDescription(html, element) {
    if (element != null) {
      this.handleChange(html, "text", "", true, element.id);
    } else {
      this.handleChange(html, "text", "", true, null);
    }
  }

  handleChange = (event, dataIndex, data, flagRichText, courseId) => {
    var id = null;
    var value = null;

    if (event && event.target) {
      id = event.target.id;
      value = event.target.value;
    }
    if (flagRichText) {
      id = dataIndex;
      value = event;
    }

    const {
      courseContainerTitle,
      courseToAdd,
      coursesToShow,
      imageIndex,
      newMultimedia,
    } = {
      ...this.state,
    };

    var { elementToEditMultimedia } = { ...this.state };

    if (courseId === null && id !== "videoUrl") {
      courseToAdd[id] = value;
    }
    if (id === "CourseContainerTitle") {
      if (value.length <= courseContainerTitle.maxLength) {
        courseContainerTitle.value = value;
        courseContainerTitle.modified = true;
        courseContainerTitle.length = value.length;
      }
    }
    coursesToShow.forEach((element, index) => {
      if (element.id === courseId) {
        if (id === "title") {
          coursesToShow[index].title = value;
        }
        if (id === "place") {
          coursesToShow[index].place = value;
        }
        if (id === "responsible") {
          coursesToShow[index].responsible = value;
        }
        if (id === "text") {
          coursesToShow[index].text = value;
        }
        coursesToShow[index].edited = true;
      }
    });

    if (id === "videoUrl") {
      if (imageIndex !== null) {
        coursesToShow[imageIndex].multimedia.videoUrl = value;
        coursesToShow[imageIndex].edited = true;
        coursesToShow[imageIndex].multimedia.edited = true;
        elementToEditMultimedia.videoUrl = value;
      } else {
        courseToAdd.multimedia = value;
        newMultimedia.videoUrl = value;
        newMultimedia.edited = true;
      }
    }

    this.setState({
      newMultimedia,
      courseToAdd,
      coursesToShow,
      courseContainerTitle,
      FlagClassName: true,
    });
  };

  changeDateHandleEvent = (e, courseId) => {
    const { courseToAdd, coursesToShow } = { ...this.state };
    const date = e.target.value;
    let dateToSave = date;
    courseToAdd.date = dateToSave;
    courseToAdd.dateToShow = dateToSave;
    coursesToShow.forEach((element, index) => {
      if (element.id === courseId) {
        coursesToShow[index].dateToShow = dateToSave;
        coursesToShow[index].date = dateToSave;
        coursesToShow[index].edited = true;
      }
    });
    this.setState({
      FlagClassName: true,
      coursesToShow,
      courseToAdd,
    });
  };

  handleChangeImage = async (e, isAdd) => {
    const {
      courseToAdd,
      coursesToShow,
      country,
      city,
      organizationId,
      imageIndex,
      elementToEditMultimedia,
    } = this.state;
    var newMultimedia = { ...this.state.newMultimedia };

    if (!e.target.value.includes("fakepath") && !e.target.files) {
      //ImageLink

      if (isAdd !== "add") {
        elementToEditMultimedia.imageUrl = e.target.value;
        elementToEditMultimedia.image = null;
        elementToEditMultimedia.value = e.target.value;
        elementToEditMultimedia.edited = true;
      } else {
        newMultimedia.imageUrl = e.target.value;
        newMultimedia.image = null;
        newMultimedia.value = e.target.value;
        courseToAdd.multimedia = e.target.value;
      }

      this.setState({
        newMultimedia,
        courseToAdd,
        coursesToShow,
        elementToEditMultimedia,
        FlagClassName: true,
      });
    }

    if (e.target.files) {
      // Image file
      var fileUpload = e.target.files[0];
      let extension;
      if (fileUpload !== undefined) {
        extension = fileUpload.type.replace("image/", "");
      }
      const random = uuidv4();
      const key = `content/${country}/${city}/${organizationId}/img${random}.${extension}`;
      if (!extension) {
        newMultimedia.loading = true;
        newMultimedia.file = null;
        newMultimedia.image = null;

        this.setState({
          newMultimedia,
          FlagClassName: true,
        });
        return;
      }

      if (key !== undefined) {
        // Function to resize and reduce image quality
        // Save image in AWS

        let flag = true;
        newMultimedia = await handleChangeImageFunction(
          newMultimedia,
          fileUpload,
          extension,
          key,
          400,
          400
        ).catch((res) => {
          const { t } = this.props;
          this.setState({
            showError: true,
            contentNotificationModal: `${t(`${res.content}`).replace("R1", res.params.R1).replace("R2", res.params.R2)}`,
          });
          flag = false;
        });
        if (flag !== false) {
          if (newMultimedia.loading === false) {
            if (imageIndex === null) {
              //new news
              newMultimedia.loading = false;
              newMultimedia.modified = true;
              newMultimedia.value = key;
              courseToAdd.multimedia = key;
            } else {
              // edited new
              coursesToShow[imageIndex].multimedia.image = key;
              coursesToShow[imageIndex].multimedia.value = key;
              coursesToShow[imageIndex].multimedia.edited = true;
              elementToEditMultimedia.image = newMultimedia.image;
              elementToEditMultimedia.imageUrl = null;
            }

            this.uploadFileToS3Bucket(newMultimedia.file, key);
            this.setState({
              courseToAdd,
              newMultimedia,
              coursesToShow,
              FlagClassName: true,
              elementToEditMultimedia,
            });
            this.forceUpdate();
          }
        }
      }
    }
  };

  disableImage(elementToChange) {
    const { coursesToShow, courseToAdd, imageIndex } = {
      ...this.state,
    };
    if (elementToChange !== null && imageIndex) {
      coursesToShow[imageIndex].image = null;
      coursesToShow[imageIndex].edited = true;
    } else {
      courseToAdd.image = null;
    }
    this.setState({
      isLoadingButton: false,
      FlagClassName: true,
      deleteFlag: true,
      coursesToShow,
      courseToAdd,
    });
    this.handleCloseDeleteImage();
  }

  handleButtonListChange = (buttonList, courseId) => {
    let { courseToAdd, coursesToShow } = this.state;
    coursesToShow.forEach((element, index) => {
      if (element.id === courseId) {
        coursesToShow[index].buttons = buttonList;
        coursesToShow[index].edited = true;
      }
    });
    courseToAdd.buttons = buttonList;
    this.setState({ courseToAdd, coursesToShow, FlagClassName: true });
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  handleClickOpenModalToChangeName = (itemToChangeName) => {
    let open = this.state.openModalToChangeName;
    this.setState({
      openModalToChangeName: !open,
      customName: itemToChangeName,
    });
  };

  OnChangeSectionCustomName = (e) => {
    let newCustomName = this.state.newCustomName;
    newCustomName = e.target.value;

    this.setState({
      newCustomName,
      FlagClassName: true,
    });
  };

  saveChangeName = () => {
    const { t } = this.props;
    var { courseContainerTitle, newCustomName, lpSectionId } = this.state;

    //Update lpsection custom name
    let dto = {
      id: lpSectionId,
      customName: newCustomName,
      isEnabled: 1,
    };
    this.updateLpSection(dto);

    //Update section title name
    const nodesToUpdate = [];
    let errorInput = false;

    if (courseContainerTitle) {
      if (newCustomName === "") {
        errorInput = true;
      } else {
        courseContainerTitle.value = newCustomName;
      }
      delete courseContainerTitle.edited;
      nodesToUpdate.push(courseContainerTitle);
    }

    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.fieldEmpty"),
          isLoadingButton: false,
        });
      }
    }
  };

  saveButtonEvent = () => {
    this.setState({
      isLoadingButton: true,
      FlagClassName: false,
    });
    const nodesToUpdate = [];
    var {
      courseContainerTitle,
      courseToAdd,
      coursesToShow,
      haveChangeInLayout,
      lpSectionId,
      lpSectionLayoutStyle,
    } = { ...this.state };

    let errorInput = false;

    const { t } = this.props;

    // Verify each one of the values of the component to add
    if (courseContainerTitle) {
      if (courseContainerTitle.value === "") {
        errorInput = true;
      } else {
        //Update lpsection custom name
        let dto = {
          id: lpSectionId,
          customName: courseContainerTitle.value,
          isEnabled: 1,
        };
        this.updateLpSection(dto);
        delete courseContainerTitle.modified;
        nodesToUpdate.push(courseContainerTitle);
      }
    }
    if (haveChangeInLayout) {
      this.updateLpSectionLayoutFunction(lpSectionId, lpSectionLayoutStyle);
    }
    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.fieldEmpty"),
          isLoadingButton: false,
        });
      }
    }

    if (courseToAdd.title !== "") {
      coursesToShow.push(courseToAdd);
      this.setState({ coursesToShow });
    }
    this.onSaveEvent();

    courseToAdd = {
      id: null,
      title: "",
      text: "",
      date: moment(),
      dateToShow: moment(),
      author: "",
      image: null,
      buttons: [],
    };
    this.setState({ courseToAdd, addCourse: false });
  };

  onSaveEvent = (e) => {
    const {
      parentLPSectionComponentId,
      landingPageId,
      coursesToShow,
      courseToAdd,
      deleteFlag,
    } = this.state;
    this.setState({
      isLoadingButton: true,
      FlagClassName: false,
    });

    const { t } = this.props;

    var coursesToValidate = coursesToShow.filter((n) => n.edited === true);
    if (courseToAdd.title !== "" && courseToAdd.title !== null) {
      coursesToValidate = coursesToValidate.concat(courseToAdd);
    }
    coursesToValidate.forEach((newCourse) => {
      const nodeCoursesToAdd = [];
      let errorInput = false;
      let courseButtons = newCourse.buttons
        ? newCourse.buttons.map((b) => {
          const button = {
            id: b.id,
            name: b.name,
            externalLink: b.externalLink,
            internalLpSectionComponentId: b.component
              ? b.component.id
              : b.internalLpSectionComponentId
                ? b.internalLpSectionComponentId
                : null,
            isEnabled: b.isEnabled,
          };
          return button;
        })
        : [];

      const finalDto = {
        // --- With this object the lpSectionComponent is created ---
        // With these two values, the lpsectionId is obtained
        lpId: null, // this value is filled with the information passed from the parent
        lpSectionId: this.state.lpSectionId,
        sectionId: 10, // Static value to the courses section

        sectionComponentId: 15, // Static value to the CourseComponent 15 in sectionComponentId

        parentLPSectionComponentId, // Static value to the CourseContainer 14 in sectionComponentId

        // --- With the created lpSectionComponent id, the nodes are created
        nodesToAdd: [],
        buttons: courseButtons,
      };

      // Course nodes array to add to the db
      if (newCourse.title != null) {
        const courseNode = {};

        courseNode.id = newCourse.titleId ? newCourse.titleId : null;
        courseNode.sectionComponentNodeId = 50;
        // Required
        if (newCourse.title === "") {
          errorInput = true;
        }
        courseNode.value = newCourse.title;
        courseNode.nodeType = "CourseTitle";

        nodeCoursesToAdd.push(courseNode);
      }
      if (newCourse.text != null) {
        const courseNode = {};

        courseNode.id = newCourse.textId ? newCourse.textId : null;
        courseNode.sectionComponentNodeId = 51;
        // if (newCourse.text === "") {
        //   errorInput = true;
        // }
        courseNode.value = newCourse.text;
        courseNode.nodeType = "CourseText";

        nodeCoursesToAdd.push(courseNode);
      }
      if (newCourse.date != null) {
        const courseNode = {};

        courseNode.id = newCourse.dateId ? newCourse.dateId : null;
        courseNode.sectionComponentNodeId = 53;
        courseNode.value = moment(newCourse.date, "YYYY-MM-DD HH:mm");
        if (courseNode.value._isValid === false) {
          courseNode.value = "";
        }
        courseNode.nodeType = "CourseDate";

        nodeCoursesToAdd.push(courseNode);
      }
      if (newCourse.place != null) {
        const courseNode = {};

        courseNode.id = newCourse.placeId ? newCourse.placeId : null;
        courseNode.sectionComponentNodeId = 54;
        // if (newCourse.place === "") {
        //   errorInput = true;
        // }
        courseNode.value = newCourse.place;
        courseNode.nodeType = "CoursePlace";

        nodeCoursesToAdd.push(courseNode);
      }
      if (newCourse.responsible != null) {
        const courseNode = {};

        courseNode.id = newCourse.responsibleId
          ? newCourse.responsibleId
          : null;
        courseNode.sectionComponentNodeId = 55;
        // if (newCourse.responsible === "") {
        //   errorInput = true;
        // }
        courseNode.value = newCourse.responsible;
        courseNode.nodeType = "CourseResponsible";

        nodeCoursesToAdd.push(courseNode);
      }
      // if (newCourse.image !== null) {
      //   const courseNode = {};
      //   courseNode.id = newCourse.imageId ? newCourse.imageId : null;
      //   courseNode.sectionComponentNodeId = 52;
      //   let haveHttp = false;
      //   var regex = /somosaura-cms.s3.amazonaws.com/;
      //   haveHttp = regex.test(newCourse.image);
      //   if (haveHttp) {
      //     courseNode.value = newCourse.image.slice(39, newCourse.image.length); //Cut to only save image, not link
      //   } else {
      //     courseNode.value = newCourse.image;
      //   }
      //   courseNode.nodeType = "CourseImage";

      //   nodeCoursesToAdd.push(courseNode);
      // }
      if (newCourse && newCourse.multimedia && (newCourse.multimedia.edited || newCourse.multimedia.edited === true)) {
        const courseNode = {};
        let multimedia = newCourse.multimedia;
        if (multimedia.image) {
          courseNode.value = multimedia.value;
        } else if (multimedia.imageUrl) {
          courseNode.value = multimedia.imageUrl;
        } else if (multimedia.videoUrl) {
          courseNode.value = multimedia.videoUrl;
        }
        courseNode.id = newCourse.multimedia.id
          ? newCourse.multimedia.id
          : null;
        courseNode.sectionComponentNodeId = 52;
        courseNode.nodeType = "CourseImage";

        nodeCoursesToAdd.push(courseNode);
      } else if (newCourse.multimedia && !newCourse.multimedia.edited && !newCourse.multimedia.id) {
        //new
        const courseNode = {};
        courseNode.value = newCourse.multimedia;
        courseNode.id = null;
        courseNode.sectionComponentNodeId = 52;
        courseNode.nodeType = "CourseImage";

        nodeCoursesToAdd.push(courseNode);
      } else if (!newCourse.multimedia) {
        // Course without image
        const courseNode = {};
        courseNode.value = "";
        courseNode.id = null;
        courseNode.sectionComponentNodeId = 52;
        courseNode.nodeType = "CourseImage";

        nodeCoursesToAdd.push(courseNode);
      }
      if (deleteFlag || (newCourse.multimedia && newCourse.multimedia == null)) {
        const courseNode = {};

        courseNode.id = newCourse.imageId ? newCourse.imageId : null;
        courseNode.sectionComponentNodeId = 52;
        courseNode.value = "";
        courseNode.nodeType = "CourseImage";

        nodeCoursesToAdd.push(courseNode);
      }

      finalDto.id = newCourse.id;
      finalDto.lpId = landingPageId;
      finalDto.nodesToAdd = nodeCoursesToAdd;

      if (nodeCoursesToAdd.length > 0) {
        if (!errorInput) {
          let courseTitle = false;
          let courseText = false;
          let coursePlace = false;

          nodeCoursesToAdd.forEach((obj) => {
            if (obj.nodeType === "CourseTitle") {
              courseTitle = true;
            }
            if (obj.nodeType === "CourseText") {
              courseText = true;
            }
            if (obj.nodeType === "CoursePlace") {
              coursePlace = true;
            }
          });

          if (courseTitle && courseText && coursePlace) {
            if (newCourse.id) {
              this.updateSectionComponentFunction(finalDto);
            } else {
              this.insertSectionComponentFunction(finalDto);
            }
          } else {
            this.setState({
              showError: true,
              contentNotificationModal: t("basicWords.modal.fieldEmpty"),
              isLoadingButton: false,
            });
          }
        } else {
          this.setState({
            showError: true,
            contentNotificationModal: t("basicWords.modal.fieldEmpty"),
            isLoadingButton: false,
          });
        }
      }
    });
  };

  cleanStateVariables = () => {
    let newMultimedia = {
      id: null,
      s3path: null,
      imageUrl: null,
      image: null,
      loading: false,
      buttons: [],
      file: null,
      value: null,
      videoUrl: null,
      edited: false,
    };
    let courseToAdd = {
      id: null,
      title: "",
      text: "",
      date: moment(),
      dateToShow: moment(),
      place: "",
      responsible: "",
      buttons: [],
      multimedia: null,
    };
    let imageIndex = null;

    this.setState({
      newMultimedia,
      courseToAdd,
      imageIndex,
    });
  };

  renderCourses(coursesToShow) {
    let renderedComponents = [];
    const {
      openDeleteImage,
      landingPageId,
      isReadyToEdit,
      openImageOrVideoEditModal,
      openImageFileOrLinkEditModal,
      openDialog,
      openImageFileEditModal,
      elementToEditMultimedia,
      openImageLinkEditModal,
      openSelectVideoEditModal,
    } = this.state;
    const { t } = this.props;
    if (coursesToShow && coursesToShow.length) {
      coursesToShow.forEach((element, index) => {
        const multimedia = element.multimedia;
        renderedComponents.push(
          <div key={element.id}>
            <AccordionComponent
              disabled={isReadyToEdit}
              iconEdit={<Edit2 />}
              // onEditNews={(e) => this.changeToEdit(e)}
              onDeleteNews={(e) => this.handleClickOpenDeleteNews(element.id)}
              iconDelete={<Trash2 />}
              title={element.title}
              body={[
                <div className="accordionContent">
                  <div className="rowSection componentSpace">
                    <div
                      className="accordionCompleteInputs"
                      style={{ width: "100%" }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("basicWords.enterTitle")}
                        id="title"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.title}
                      />
                      {element.title.value && element.title.maxLength ? (
                        <span>
                          {element.title.length} / {element.title.maxLength}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="rowSection componentSpace">
                    <div
                      className="accordionCompleteInputs"
                      style={{ width: "100%" }}
                    >
                      <ReactQuill
                        readOnly={isReadyToEdit}
                        onChange={(e) =>
                          this.handleChangeDescription(e, element)
                        }
                        value={element.text}
                        modules={editorConfiguration.modules}
                        formats={editorConfiguration.formats}
                        placeholder={t("basicWords.description")}
                      />
                    </div>
                  </div>

                  <div className="rowSection componentSpace">
                    <div
                      className="accordionCompleteInputs"
                      style={{ width: "32%" }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("basicWords.place")}
                        id="place"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.place}
                      />
                    </div>
                    <div
                      className="accordionCompleteInputs"
                      style={{ width: "32%" }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("basicWords.responsible")}
                        id="responsible"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.responsible}
                      />
                    </div>
                    <div
                      className="accordionCompleteInputs"
                      style={{ width: "34%" }}
                    >
                      <DateTimePickerComponent
                        disabled={isReadyToEdit}
                        string={t("basicWords.dateEvent")}
                        defaultValue={element.date}
                        onChange={(e) =>
                          this.changeDateHandleEvent(e, element.id)
                        }
                      />
                    </div>
                  </div>

                  {/* New feature image or video */}

                  <div
                    className="rowSections componentSpace"
                    style={{
                      margin: "2%",
                      display: "flex",
                    }}
                  >
                    {multimedia &&
                      multimedia.imageUrl &&
                      !multimedia.image &&
                      !multimedia.videoUrl ? (
                      <img
                        style={{ objectFit: "contain" }}
                        src={multimedia.imageUrl}
                        alt="imagen"
                        width="100px"
                        height="100px"
                        alignitems="center"
                      ></img>
                    ) : null}

                    {multimedia &&
                      multimedia.image &&
                      !multimedia.imageUrl &&
                      !multimedia.videoUrl ? (
                      <img
                        style={{ objectFit: "contain" }}
                        src={multimedia.image}
                        alt="imagen"
                        width="100px"
                        height="100px"
                        alignitems="center"
                      ></img>
                    ) : null}

                    {multimedia &&
                      multimedia.videoUrl &&
                      !multimedia.image &&
                      !multimedia.imageUrl ? (
                      <img
                        style={{ objectFit: "contain" }}
                        src={
                          "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png"
                        }
                        alt="imagen"
                        width="100px"
                        height="100px"
                        alignitems="center"
                      ></img>
                    ) : null}
                    <div
                      style={{
                        margin: "2%",
                        display: "flex",
                        alignitems: "center",
                      }}
                    >
                      <ButtonComponent
                        onClick={() => this.handleImageIndex(multimedia)}
                        props={null}
                        shape="round"
                        text={t("basicWords.imageOrVideo")}
                      />
                    </div>
                  </div>

                  <GeneralModal //Select image or video modal
                    visible={openImageOrVideoEditModal}
                    title={t("generalMessages.selectFile")}
                    body={[
                      <div key={1}>
                        <div className="col-6">
                          <ButtonComponent
                            onClick={() =>
                              this.handleSelectImageOrVideoModal("image")
                            }
                            props={null}
                            shape="round"
                            text={t("basicWords.image")}
                          />
                        </div>
                        <br />
                        <div>
                          <ButtonComponent
                            onClick={() =>
                              this.handleSelectImageOrVideoModal("video")
                            }
                            props={null}
                            shape="round"
                            text={t("sections.video")}
                          />
                        </div>
                      </div>,
                    ]}
                    footer={[
                      <div className="row" key={1} style={{ display: "flex" }}>
                        <div className="col-6">
                          <ButtonComponent
                            onClick={() => this.handleCloseDialog()}
                            props={null}
                            shape="round"
                            text={t("basicWords.cancelText")}
                          />
                        </div>
                      </div>,
                    ]}
                    onClick={() => this.handleCloseDialog()}
                  />

                  <GeneralModal //Select image file or image link modal
                    visible={openImageFileOrLinkEditModal}
                    title={t("generalMessages.selectFile")}
                    body={[
                      <div key={1}>
                        <div className="col-6">
                          <ButtonComponent
                            onClick={() => this.handleImageFileOrLink("file")}
                            props={null}
                            shape="round"
                            text={t("generalMessages.uploadFile")}
                          />
                        </div>
                        <br />
                        <div>
                          <ButtonComponent
                            onClick={() => this.handleImageFileOrLink("link")}
                            props={null}
                            shape="round"
                            text={t("basicWords.buttons.externalLink")}
                          />
                        </div>
                      </div>,
                    ]}
                    footer={[
                      <div className="row" key={1} style={{ display: "flex" }}>
                        <div className="col-6">
                          <ButtonComponent
                            onClick={() => this.handleCloseDialog()}
                            props={null}
                            shape="round"
                            text={t("basicWords.cancelText")}
                          />
                        </div>
                      </div>,
                    ]}
                    onClick={() => this.handleCloseDialog()}
                  />

                  <GeneralModal // Select file image modal
                    visible={openImageFileEditModal}
                    title={t("generalMessages.uploadFile")}
                    body={[
                      <div key={1}>
                        <div className="col-12">
                          {/* Tooltip image */}
                          <Tooltip title={t("generalMessages.imagesFormat")}>
                            <div className="cardImagesSquare">
                              <div className="row">
                                <div className="iconXCardImagesSquare">
                                  <a>
                                    <X
                                      className="iconImageEditInSectionSquare"
                                      onClick={this.handleClickOpenDialog}
                                    />
                                  </a>
                                </div>
                              </div>

                              <div className="row">
                                <div className="centerImage">
                                  <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={(e) =>
                                      this.handleChangeImage(e, index)
                                    }
                                    hidden
                                  />
                                  <label htmlFor="contained-button-file">
                                    <div className="imageEdit">
                                      <div>
                                        {elementToEditMultimedia && elementToEditMultimedia.image !==
                                          null ? (
                                          <img
                                            src={elementToEditMultimedia && elementToEditMultimedia.image ? elementToEditMultimedia.image : ''}
                                            alt=""
                                            className="widthAndHeightImageSectionSquare"
                                          />
                                        ) : (
                                          <Plus className="imageEdit" />
                                        )}

                                        <GeneralModal
                                          visible={openDialog}
                                          title={t("basicWords.deleteItem")}
                                          footer={[
                                            <div
                                              className="row"
                                              key={1}
                                              style={{
                                                display: "flex",
                                              }}
                                            >
                                              <div className="col-6">
                                                <ButtonComponent
                                                  onClick={() =>
                                                    this.handleCloseDialog()
                                                  }
                                                  props={null}
                                                  shape="round"
                                                  text={t(
                                                    "basicWords.cancelText"
                                                  )}
                                                />
                                              </div>
                                              <div>
                                                <ButtonComponent
                                                  onClick={() =>
                                                    this.disableImage(
                                                      multimedia
                                                    )
                                                  }
                                                  props={null}
                                                  shape="round"
                                                  text={t("basicWords.okText")}
                                                />
                                              </div>
                                            </div>,
                                          ]}
                                          onClick={() =>
                                            this.handleCloseDialog()
                                          }
                                        />
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      </div>,
                    ]}
                    footer={[
                      <div className="row buttonAtTheBottom">
                        <div className="col-6">
                          <ButtonComponent
                            onClick={() => this.handleCloseDialog()}
                            shape="round"
                            text={t("basicWords.cancel")}
                            className="buttonDiscard"
                          />
                        </div>
                        <div className="col-6">
                          <ButtonComponent
                            isDisabled={false}
                            onClick={(e) =>
                              this.handleOkChangeImageType(e, "imageFile")
                            }
                            shape="round"
                            text={t("basicWords.ok")}
                          />
                        </div>
                      </div>,
                    ]}
                    onClick={() => this.handleCloseDialog()}
                  />

                  <GeneralModal // Select link image modal
                    visible={openImageLinkEditModal}
                    title={t("basicWords.buttons.externalLink")}
                    body={[
                      <div key={1}>
                        <div className="col-6">
                          <GeneralInput
                            text={t("basicWords.buttons.externalLink")}
                            id={"ImageLink"}
                            value={
                              elementToEditMultimedia && elementToEditMultimedia.imageUrl
                                ? elementToEditMultimedia.imageUrl
                                : ""
                            }
                            placeholder={t("basicWords.modal.addImageLink")}
                            isReq
                            onChange={(e, id) => this.handleChangeImage(e, id)}
                            iconYoutube={false}
                          />
                        </div>
                        <div
                          className="col-6"
                          style={{
                            alignitems: "center",
                            display: "flex",
                            justifyContent: "center",
                            margin: "5px",
                          }}
                        >
                          <p>{t("basicWords.modal.addImageLinkTooltip")}</p>
                          {elementToEditMultimedia && elementToEditMultimedia.imageUrl ? (
                            <img
                              style={{ objectFit: "contain" }}
                              src={elementToEditMultimedia.imageUrl}
                              alt="imagen"
                              width="100px"
                              height="100px"
                            ></img>
                          ) : null}
                        </div>
                      </div>,
                    ]}
                    footer={[
                      <div className="row buttonAtTheBottom">
                        <div>
                          <ButtonComponent
                            onClick={() => this.handleCloseDialog()}
                            shape="round"
                            text={t("basicWords.cancel")}
                            className="buttonDiscard"
                          />
                        </div>
                        <div className="col-6">
                          <ButtonComponent
                            isDisabled={false}
                            onClick={(e) =>
                              this.handleOkChangeImageType(e, "imageLink")
                            }
                            shape="round"
                            text={t("basicWords.ok")}
                          />
                        </div>
                      </div>,
                    ]}
                    onClick={() => this.handleCloseDialog()}
                  />

                  <GeneralModal // Select link video modal
                    visible={openSelectVideoEditModal}
                    title={t("sections.video")}
                    body={[
                      <div key={1}>
                        <div className="col-6">
                          <GeneralInput
                            text={t("basicWords.enterUrlVideo")}
                            id={"videoUrl"}
                            value={
                              elementToEditMultimedia && elementToEditMultimedia.videoUrl
                                ? elementToEditMultimedia.videoUrl
                                : ""
                            }
                            placeholder={t("basicWords.enterUrlVideo")}
                            isReq
                            onChange={(e, id) =>
                              this.handleChange(e, "videoUrl")
                            }
                            iconYoutube={true}
                          />
                        </div>
                      </div>,
                    ]}
                    footer={[
                      <div className="row buttonAtTheBottom">
                        <div>
                          <ButtonComponent
                            onClick={() => this.handleCloseDialog()}
                            shape="round"
                            text={t("basicWords.cancel")}
                            className="buttonDiscard"
                          />
                        </div>
                        <div className="col-6">
                          <ButtonComponent
                            isDisabled={false}
                            onClick={(e) =>
                              this.handleOkChangeImageType(e, "video")
                            }
                            shape="round"
                            text={t("basicWords.ok")}
                          />
                        </div>
                      </div>,
                    ]}
                    onClick={() => this.handleCloseDialog()}
                  />

                  <br />

                  <div className="rowSection componentSpace">
                    <div className="textLogo">{t("basicWords.buttons.buttons")}</div>
                  </div>
                  <div
                    className="rowSection componentSpace"
                    style={{
                      display: "flex",
                      alignitems: "center",
                      marginLeft: "2%",
                    }}
                  >
                    <ButtonsContainer
                      disabled={isReadyToEdit}
                      isModalVisible={true}
                      lpId={landingPageId}
                      buttonList={element.buttons}
                      handleButtonListChange={(buttonList) =>
                        this.handleButtonListChange(buttonList, element.id)
                      }
                    />
                  </div>
                </div>,
              ]}
            />
            <br />
          </div>
        );
      });
    }
    return renderedComponents;
  }

  renderAddCourse() {
    const {
      courseToAdd,
      openDeleteImage,
      landingPageId,
      openImageOrVideoAddModal,
      openImageFileOrLinkAddModal,
      openDialog,
      openImageFileAddModal,
      openImageLinkAddModal,
      openSelectVideoAddModal,
      newMultimedia,
    } = this.state;
    const { t } = this.props;

    return (
      <div>
        <AccordionComponent
          title={t("basicWords.newCourse")}
          className="accordionAdd"
          body={[
            <div className="accordionContent">
              <div className="rowSection componentSpace">
                <div style={{ width: "100%" }}>
                  <GeneralInput
                    placeholder={t("basicWords.enterTitle")}
                    id="title"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={courseToAdd.title}
                  />
                  {courseToAdd.value && courseToAdd.maxLength ? (
                    <span>
                      {courseToAdd.length} / {courseToAdd.maxLength}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="rowSection componentSpace">
                <div style={{ width: "100%" }}>
                  <ReactQuill
                    onChange={(e) => this.handleChangeDescription(e, null)}
                    value={courseToAdd.text}
                    modules={editorConfiguration.modules}
                    formats={editorConfiguration.formats}
                    placeholder={t("basicWords.description")}
                  />
                </div>
              </div>
              <div className="rowSection componentSpace">
                <div
                  className="accordionCompleteInputs"
                  style={{ marginLeft: "0%", width: "32%" }}
                >
                  <GeneralInput
                    placeholder={t("basicWords.place")}
                    id="place"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={courseToAdd.place}
                  />
                </div>
                <div
                  className="accordionCompleteInputs"
                  style={{ marginLeft: "0%", width: "32%" }}
                >
                  <GeneralInput
                    placeholder={t("basicWords.responsible")}
                    id="responsible"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={courseToAdd.responsible}
                  />
                </div>
                <div style={{ width: "34%" }}>
                  <DateTimePickerComponent
                    string={t("basicWords.dateEvent")}
                    defaultValue={courseToAdd.date}
                    onChange={(e) => this.changeDateHandleEvent(e, null)}
                  />
                </div>
              </div>

              <div
                className="rowSections componentSpace"
                style={{
                  margin: "2%",
                  display: "flex",
                }}
              >
                {newMultimedia.imageUrl &&
                  !newMultimedia.image &&
                  !newMultimedia.videoUrl ? (
                  <img
                    style={{ objectFit: "contain" }}
                    src={newMultimedia.imageUrl}
                    alt="imagen"
                    width="100px"
                    height="100px"
                    alignitems="center"
                  ></img>
                ) : null}

                {newMultimedia.image &&
                  !newMultimedia.imageUrl &&
                  !newMultimedia.videoUrl ? (
                  <img
                    style={{ objectFit: "contain" }}
                    src={newMultimedia.image}
                    alt="imagen"
                    width="100px"
                    height="100px"
                    alignitems="center"
                  ></img>
                ) : null}

                {newMultimedia.videoUrl &&
                  !newMultimedia.image &&
                  !newMultimedia.imageUrl ? (
                  <img
                    style={{ objectFit: "contain" }}
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png"
                    }
                    alt="imagen"
                    width="100px"
                    height="100px"
                    alignitems="center"
                  ></img>
                ) : null}
                <div
                  style={{
                    margin: "2%",
                    display: "flex",
                    alignitems: "center",
                  }}
                >
                  <ButtonComponent
                    onClick={() => this.handleImageIndex(courseToAdd)}
                    props={null}
                    shape="round"
                    text={t("basicWords.imageOrVideo")}
                  />
                </div>
              </div>

              <GeneralModal //Select image or video modal
                visible={openImageOrVideoAddModal}
                title={t("generalMessages.selectFile")}
                body={[
                  <div key={1}>
                    <div className="col-6">
                      <ButtonComponent
                        onClick={() =>
                          this.handleSelectImageOrVideoModal("image")
                        }
                        props={null}
                        shape="round"
                        text={t("basicWords.image")}
                      />
                    </div>
                    <br />
                    <div>
                      <ButtonComponent
                        onClick={() =>
                          this.handleSelectImageOrVideoModal("video")
                        }
                        props={null}
                        shape="round"
                        text={t("sections.video")}
                      />
                    </div>
                  </div>,
                ]}
                footer={[
                  <div className="row" key={1} style={{ display: "flex" }}>
                    <div className="col-6">
                      <ButtonComponent
                        onClick={() => this.handleCloseDialog()}
                        props={null}
                        shape="round"
                        text={t("basicWords.cancelText")}
                      />
                    </div>
                  </div>,
                ]}
                onClick={() => this.handleCloseDialog()}
              />

              <GeneralModal //Select image file or image link modal
                visible={openImageFileOrLinkAddModal}
                title={t("generalMessages.selectFile")}
                body={[
                  <div key={1}>
                    <div className="col-6">
                      <ButtonComponent
                        onClick={() => this.handleImageFileOrLink("file")}
                        props={null}
                        shape="round"
                        text={t("generalMessages.uploadFile")}
                      />
                    </div>
                    <br />
                    <div>
                      <ButtonComponent
                        onClick={() => this.handleImageFileOrLink("link")}
                        props={null}
                        shape="round"
                        text={t("basicWords.buttons.externalLink")}
                      />
                    </div>
                  </div>,
                ]}
                footer={[
                  <div className="row" key={1} style={{ display: "flex" }}>
                    <div className="col-6">
                      <ButtonComponent
                        onClick={() => this.handleCloseDialog()}
                        props={null}
                        shape="round"
                        text={t("basicWords.cancelText")}
                      />
                    </div>
                  </div>,
                ]}
                onClick={() => this.handleCloseDialog()}
              />

              <GeneralModal // Select file image modal
                visible={openImageFileAddModal}
                title={t("generalMessages.uploadFile")}
                body={[
                  <div key={1}>
                    <div className="col-12">
                      {/* Tooltip image */}
                      <Tooltip title={t("generalMessages.imagesFormat")}>
                        <div className="cardImagesSquare">
                          <div className="row">
                            <div className="iconXCardImagesSquare">
                              <a>
                                <X
                                  className="iconImageEditInSectionSquare"
                                  onClick={this.handleClickOpenDialog}
                                />
                              </a>
                            </div>
                          </div>

                          <div className="row">
                            <div className="centerImage">
                              <input
                                accept="image/*"
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={(e) =>
                                  this.handleChangeImage(e, "add")
                                }
                                hidden
                              />
                              <label htmlFor="contained-button-file">
                                <div className="imageEdit">
                                  <div>
                                    {newMultimedia.image ? (
                                      <img
                                        src={newMultimedia.image}
                                        alt=""
                                        className="widthAndHeightImageSectionSquare"
                                      />
                                    ) : (
                                      <Plus className="imageEdit" />
                                    )}

                                    <GeneralModal
                                      visible={openDialog}
                                      title={t("basicWords.deleteItem")}
                                      footer={[
                                        <div
                                          className="row"
                                          key={1}
                                          style={{
                                            display: "flex",
                                          }}
                                        >
                                          <div className="col-6">
                                            <ButtonComponent
                                              onClick={() =>
                                                this.handleCloseDialog()
                                              }
                                              props={null}
                                              shape="round"
                                              text={t("basicWords.cancelText")}
                                            />
                                          </div>
                                          <div>
                                            <ButtonComponent
                                              onClick={() =>
                                                this.disableImage(courseToAdd)
                                              }
                                              props={null}
                                              shape="round"
                                              text={t("basicWords.okText")}
                                            />
                                          </div>
                                        </div>,
                                      ]}
                                      onClick={() => this.handleCloseDialog()}
                                    />
                                  </div>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  </div>,
                ]}
                footer={[
                  <div className="row buttonAtTheBottom">
                    <div className="col-6">
                      <ButtonComponent
                        onClick={() => this.handleCloseDialog()}
                        shape="round"
                        text={t("basicWords.cancel")}
                        className="buttonDiscard"
                      />
                    </div>
                    <div className="col-6">
                      <ButtonComponent
                        isDisabled={false}
                        onClick={(e) =>
                          this.handleOkChangeImageType(e, "imageFile")
                        }
                        shape="round"
                        text={t("basicWords.ok")}
                      />
                    </div>
                  </div>,
                ]}
                onClick={() => this.handleCloseDialog()}
              />

              <GeneralModal // Select link image modal
                visible={openImageLinkAddModal}
                title={t("basicWords.buttons.externalLink")}
                body={[
                  <div key={1}>
                    <div className="col-6">
                      <GeneralInput
                        text={t("basicWords.buttons.externalLink")}
                        id={"ImageLink"}
                        value={
                          newMultimedia.imageUrl ? newMultimedia.imageUrl : ""
                        }
                        placeholder={t("basicWords.modal.addImageLink")}
                        isReq
                        onChange={(e) => this.handleChangeImage(e, "add")}
                        iconYoutube={false}
                      />
                    </div>
                    <div
                      className="col-6"
                      style={{
                        alignitems: "center",
                        display: "flex",
                        justifyContent: "center",
                        margin: "5px",
                      }}
                    >
                      <p>{t("basicWords.modal.addImageLinkTooltip")}</p>
                      {newMultimedia.imageUrl ? (
                        <img
                          style={{ objectFit: "contain" }}
                          src={newMultimedia.imageUrl}
                          alt="imagen"
                          width="100px"
                          height="100px"
                        ></img>
                      ) : null}
                    </div>
                  </div>,
                ]}
                footer={[
                  <div className="row buttonAtTheBottom">
                    <div>
                      <ButtonComponent
                        onClick={() => this.handleCloseDialog()}
                        shape="round"
                        text={t("basicWords.cancel")}
                        className="buttonDiscard"
                      />
                    </div>
                    <div className="col-6">
                      <ButtonComponent
                        isDisabled={false}
                        onClick={(e) =>
                          this.handleOkChangeImageType(e, "imageLink")
                        }
                        shape="round"
                        text={t("basicWords.ok")}
                      />
                    </div>
                  </div>,
                ]}
                onClick={() => this.handleCloseDialog()}
              />

              <GeneralModal // Select link video modal
                visible={openSelectVideoAddModal}
                title={t("sections.video")}
                body={[
                  <div key={1}>
                    <div className="col-6">
                      <GeneralInput
                        text={t("basicWords.enterUrlVideo")}
                        id={"videoUrl"}
                        value={
                          newMultimedia.videoUrl ? newMultimedia.videoUrl : ""
                        }
                        placeholder={t("basicWords.enterUrlVideo")}
                        isReq
                        onChange={(e) => this.handleChange(e, "videoUrl")}
                        iconYoutube={true}
                      />
                    </div>
                  </div>,
                ]}
                footer={[
                  <div className="row buttonAtTheBottom">
                    <div>
                      <ButtonComponent
                        onClick={() => this.handleCloseDialog()}
                        shape="round"
                        text={t("basicWords.cancel")}
                        className="buttonDiscard"
                      />
                    </div>
                    <div className="col-6">
                      <ButtonComponent
                        isDisabled={false}
                        onClick={(e) =>
                          this.handleOkChangeImageType(e, "video")
                        }
                        shape="round"
                        text={t("basicWords.ok")}
                      />
                    </div>
                  </div>,
                ]}
                onClick={() => this.handleCloseDialog()}
              />

              <br />

              <div
                className="rowSection componentSpace"
                style={{
                  display: "flex",
                  alignitems: "center",
                }}
              >
                <div className="textLogo">{t("basicWords.buttons.buttons")}</div>
              </div>
              <div
                className="rowSection componentSpace"
                style={{
                  display: "flex",
                  alignitems: "center",
                  marginLeft: "2%",
                }}
              >
                <ButtonsContainer
                  isModalVisible={true}
                  lpId={landingPageId}
                  buttonList={courseToAdd.buttons}
                  handleButtonListChange={(buttonList) =>
                    this.handleButtonListChange(buttonList, courseToAdd.id)
                  }
                />
              </div>
            </div>,
          ]}
        />
        <br />
      </div>
    );
  }

  handleSelectLayout() {
    this.getLayoutFunction(this.props.location.props.landingPageId);
    this.setState({ layoutModalVisible: true });
  }

  setLayoutModalVisible = (isVisible) => {
    this.setState({ layoutModalVisible: isVisible });
  };

  addCourse = (flag) => {
    if (flag !== this.state.addCourse) {
      this.setState({ addCourse: flag });
    }
  };

  //-----------Get image or video modals------------

  handleCloseDialog = (index) => {
    this.setState({
      openDialog: false,
      openImageOrVideoEditModal: false,
      openImageFileOrLinkEditModal: false,
      openImageFileEditModal: false,
      openImageLinkEditModal: false,
      openSelectVideoEditModal: false,
      openImageOrVideoAddModal: false,
      openImageFileOrLinkAddModal: false,
      openImageFileAddModal: false,
      openImageLinkAddModal: false,
      openSelectVideoAddModal: false,
    });
  };

  handleOpenImageOrVideoModal = (elementToEditMultimedia) => {
    const { imageIndex } = { ...this.state };
    if (!imageIndex) {
      this.setState({
        elementToEditMultimedia,
        openImageOrVideoAddModal: true,
      });
    } else {
      this.setState({
        elementToEditMultimedia,
        openImageOrVideoEditModal: true,
      });
    }
  };

  handleSelectImageOrVideoModal = (imageOrVideo) => {
    const { imageIndex } = { ...this.state };
    if (!imageIndex) {
      this.setState({ openImageOrVideoAddModal: false });

      if (imageOrVideo === "image") {
        this.setState({
          openImageFileOrLinkAddModal: true,
          openSelectVideoAddModal: false,
        });
      } else if (imageOrVideo === "video") {
        this.setState({
          openSelectVideoAddModal: true,
          openImageFileOrLinkAddModal: false,
        });
      } else {
        this.setState({ openImageOrVideoAddModal: false });
      }
    } else {
      this.setState({ openImageOrVideoEditModal: false });

      if (imageOrVideo === "image") {
        this.setState({
          openImageFileOrLinkEditModal: true,
          openSelectVideoEditModal: false,
        });
      } else if (imageOrVideo === "video") {
        this.setState({
          openSelectVideoEditModal: true,
          openImageFileOrLinkEditModal: false,
        });
      } else {
        this.setState({ openImageOrVideoEditModal: false });
      }
    }
  };

  handleImageFileOrLink = (fileOrLink) => {
    const { imageIndex } = { ...this.state };
    if (!imageIndex) {
      this.setState({ openImageFileOrLinkAddModal: false });

      if (fileOrLink === "file") {
        this.setState({
          openImageFileAddModal: true,
          openImageLinkAddModal: false,
        });
      } else if (fileOrLink === "link") {
        this.setState({
          openImageLinkAddModal: true,
          openImageFileAddModal: false,
        });
      } else {
        this.setState({ openImageFileOrLinkAddModal: false });
      }
    } else {
      this.setState({ openImageFileOrLinkEditModal: false });

      if (fileOrLink === "file") {
        this.setState({
          openImageFileEditModal: true,
          openImageLinkEditModal: false,
        });
      } else if (fileOrLink === "link") {
        this.setState({
          openImageLinkEditModal: true,
          openImageFileEditModal: false,
        });
      } else {
        this.setState({ openImageFileOrLinkEditModal: false });
      }
    }
  };

  handleOkChangeImageType = (e, tag) => {
    const {
      newMultimedia,
      coursesToShow,
      elementToEditMultimedia,
      imageIndex,
    } = this.state;
    if (imageIndex !== null) {
      let elemToEdit = coursesToShow.find(
        (e) => e.multimedia.id === elementToEditMultimedia.id
      );
      let elemIndex = coursesToShow.indexOf(elemToEdit);

      coursesToShow[elemIndex].edited = true;
      coursesToShow[elemIndex].multimedia.edited = true;

      switch (tag) {
        case "imageFile":
          coursesToShow[elemIndex].multimedia.imageUrl = null;
          coursesToShow[elemIndex].multimedia.videoUrl = null;
          break;
        case "imageLink":
          coursesToShow[elemIndex].multimedia.image = null;
          coursesToShow[elemIndex].multimedia.file = null;
          coursesToShow[elemIndex].multimedia.videoUrl = null;
          break;
        case "video":
          coursesToShow[elemIndex].multimedia.imageUrl = null;
          coursesToShow[elemIndex].multimedia.image = null;
          coursesToShow[elemIndex].multimedia.file = null;
          break;
        default:
          break;
      }
    } else {
      switch (tag) {
        case "imageFile":
          newMultimedia.imageUrl = null;
          newMultimedia.videoUrl = null;
          break;
        case "imageLink":
          newMultimedia.image = null;
          newMultimedia.file = null;
          newMultimedia.videoUrl = null;
          break;
        case "video":
          newMultimedia.imageUrl = null;
          newMultimedia.image = null;
          newMultimedia.file = null;
          break;
        default:
          break;
      }
    }
    this.setState({
      coursesToShow,
      //Select image or video modals edit
      openImageOrVideoEditModal: false,
      openImageFileOrLinkEditModal: false,
      openImageFileEditModal: false,
      openImageLinkEditModal: false,
      openSelectVideoEditModal: false,

      //Select image or video modals edit
      openImageOrVideoAddModal: false,
      openImageFileOrLinkAddModal: false,
      openImageFileAddModal: false,
      openImageLinkAddModal: false,
      openSelectVideoAddModal: false,
    });
  };
layoutModalVisible
  //---------------------------------------------

  //-----------Update page after modal------------
  updatePage = (lpSectionId, lpUrl) => {
    const { landingPageId, startDate, endDate } = this.state;
    this.getCoursesInformation(landingPageId, lpSectionId, startDate, endDate);
    this.setState({
      // previousPath,
      lpUrl,
    });
    this.setState({ layoutModalVisible: false });
    this.forceUpdate();
  };

  setShowError = (open) => {
    this.setState({ showError: open });
  };

  setShowSuccess = (open) => {
    this.setState({ showSuccess: open });
  };

  setShowWarning = (open) => {
    this.setState({ showWarning: open });
  };

  // Render Info
  render() {
    const {
      redirectBack,
      sectionName,
      coursesToShow,
      organizationId,
      organizationName,
      name,
      subpageId,
      landingPageId,
      FlagClassName,
      courseContainerTitle,
      lpSectionLayoutStyle,
      layoutData,
      lpUrl,

      //Notification Modal
      contentNotificationModal,
      showSuccess,
      showWarning,
      showError,

      //Modal delete item
      openDeleteNews,
      elementToDeleteId,
      isLoadingButton,

      openModalToChangeName,
      customName,
      goToWithoutSaveModalVisible,
    } = this.state;

    if (redirectBack) {
      return <Redirect to="/manage/web" />;
    }

    const { t } = this.props;

    return (
      <>
        {!!showWarning && (
          <SystemNotification
            setShowNotification={(e) => this.setShowWarning(e)}
            type="Warning"
            caption={contentNotificationModal}
          />
        )}
        {!!showSuccess && (
          <SystemNotification
            setShowNotification={(e) => this.setShowSuccess(e)}
            type="Success"
            caption={contentNotificationModal}
          />
        )}
        {!!showError && (
          <SystemNotification
            setShowNotification={(e) => this.setShowError(e)}
            type="Error"
            caption={contentNotificationModal}
          />
        )}

        <GeneralModal
          visible={openModalToChangeName}
          title={"Cambiar nombre"}
          body={[
            <div className="col-12" style={{ margin: "5px" }}>
              <div className="row">
                <div className="col-md-12 componentSpace">
                  <TextField
                    variant="outlined"
                    style={{ width: "100%", marginLeft: 10 }}
                    label={t("basicWords.section")}
                    onChange={(e) => this.OnChangeSectionCustomName(e)}
                    defaultValue={
                      customName ? decodeURIComponent(customName) : ""
                    }
                  />
                </div>
              </div>
            </div>,
          ]}
          footer={[
            <div className="row buttonAtTheBottom">
              <div>
                <ButtonComponent
                  onClick={(e) => this.handleClickOpenModalToChangeName()}
                  shape="round"
                  text={t("basicWords.cancel")}
                  className="buttonDiscard"
                />
              </div>
              <div className="col-6">
                <ButtonComponent
                  isDisabled={false}
                  onClick={() => this.saveChangeName()}
                  shape="round"
                  text={t("basicWords.ok")}
                />
              </div>
            </div>,
          ]}
          onClick={() => this.handleClickOpenModalToChangeName()}
        />

        <DeleteItemModal
          visible={openDeleteNews}
          onCancel={this.handleCloseDeleteNews}
          onClick={(e) => this.disableCourseEvent(elementToDeleteId)}
        />

        <section className="sectionContainer">
          <Card className="card">
            {isLoadingButton === true ? (
              <div
                className="col-xs-12 col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  transform: "translateY(800%)",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="row componentSpace spaceBreadcrumb">
                  <BreadcrumbSection
                    principal={t("sections.courses")}
                    secondary={organizationName}
                    route="./manage/web"
                    propsComponent={{
                      organizationId,
                      sectionName: sectionName,
                      nameSubpage: name ? name : null,
                      lpId: landingPageId,
                      subpageId: subpageId ? subpageId : null,
                      lpUrl: lpUrl,
                      customName,
                    }}
                    handleClickOpenModalToChangeName={
                      this.handleClickOpenModalToChangeName
                    }
                    FlagclassName={FlagClassName}
                    onClick={(e) => this.saveButtonEvent(e)}
                    GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                    onCancelGotoWithoutMModal={(e) =>
                      this.onCancelGotoWithoutMModal(e)
                    }
                    goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                  />
                </div>

                <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                  <Title
                    title={t("basicWords.completeInformationOfYourSection")}
                  />
                </div>
                <div className="componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <SelectLayoutModal
                    layoutData={layoutData}
                    lpLayoutId={
                      lpSectionLayoutStyle ? lpSectionLayoutStyle : null
                    }
                    handleChange={this.handleChangeLayout}
                  />
                </div>
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <AccordionComponent
                    title={t("generalUser.sectionContent")}
                    body={[
                      <div className="accordionContent">
                        <div style={{ width: "60%" }}>
                          <GeneralInput
                            placeholder={t("basicWords.enterTitle")}
                            id={
                              courseContainerTitle
                                ? courseContainerTitle.nodeTypeName
                                : null
                            }
                            onChange={(e, id) =>
                              this.handleChange(e, "", "", "", id)
                            }
                            value={
                              courseContainerTitle
                                ? courseContainerTitle.value
                                : ""
                            }
                          />
                        </div>
                      </div>,
                    ]}
                  />
                </div>
                {/* Accordion to add course */}
                {landingPageId ? (
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    {this.renderAddCourse()}
                  </div>
                ) : null}

                <div>
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    <Title title={t("basicWords.listCourses")} />
                  </div>
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    {this.renderCourses(coursesToShow)}
                  </div>
                </div>
              </>
            )}
          </Card>
        </section>
        {/* Buttons */}
        <br />
        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName,
            name,
            landingPageId,
            subpageId,
            lpUrl,
          }}
          FlagclassName={FlagClassName}
          onClick={(e) => this.saveButtonEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </>
    );
  }
}
export default withTranslation()(CoursesSection);
