import React, { useState, useEffect } from "react";
import "./css/data.css";

import { useHistory } from "react-router-dom";
import { getConfigs } from '../../actions/uhoo/Config.actions';
import { getInfoReport } from '../../actions/uhoo/Report.actions';
import { get as _get } from "lodash";
import { CircularProgress } from "@material-ui/core";
//Localization
import { useTranslation } from 'react-i18next';

//import { getConfigs } from '../../actions/uhoo/Config.actions'; // Singularity 
import { Redirect } from "react-router";

const Data = () => {
  let history = useHistory();
  const [dataReport, setDataReport] = useState("");
  const [showDataReport, setShowDataReport] = useState(false);
  const { t, i18n } = useTranslation();

  /// ----- Singularity ----- ///

  const [report, setReport] = useState(false);
 
  const Singularity = () => {
    useEffect(async () => {
      const _configs = await getConfigs();
      let config = _configs.data.data;

      setReport(
        config.find((element) => {
          return element.name === "report.enabled";
        }).value
      );

    }, []);
  };

  Singularity();

  if (report === 0){
    history.replace("/")
  }

  /// --- end Singularity --- ///


  useEffect( async () => {

    const loadDashboard = async (_dashboardId, _quicksightUser) => {
      let dataTosend = {
        url: "https://0fqpkw3whf.execute-api.us-east-1.amazonaws.com/dev/dashboardLink",
        quicksightUser: _quicksightUser,
        dashboardId: _dashboardId
      }
      let _dataReport = await getInfoReport(dataTosend);
      let messageError = _get(_dataReport, "message", "");
      let messageErrorValue = messageError.includes("Error");
      if (messageErrorValue) {
        setShowDataReport(false);
        setDataReport("error");
      } else {
        setDataReport(_get(_dataReport, "data.url", ""));
        setTimeout(()=> setShowDataReport(true), 3000);
      }
    }

    let _configs = await getConfigs();
    _configs = _get(_configs, "data.data", [])
    let _dashboardId = _configs.find((element) => {
      return element.name === "report.dashboard";
    });
    _dashboardId = _get(_dashboardId, "value", "")
    let _quicksightUser = _configs.find((element) => {
      return element.name === "report.quicksightUser";
    });
    _quicksightUser = _get(_quicksightUser, "value", "")
    if (_dashboardId != "" && _quicksightUser != "") loadDashboard(_dashboardId, _quicksightUser)
  }, [])

  if (dataReport == "error") {
    return (
      <React.Fragment>
        <div className="Content">
          <div className="headline">
            <div className="dataTitle">
              <h1>{t('uhoo.menu.data')}</h1>
            </div>
            {/*<div className="dataSubTitle">
            <h3>Subtitulo Data</h3>
            </div>*/}
          </div>
        </div>
        <section className="previewData">
          <div className="containerRows">
            <h2>{t('basicWords.internalServerError')}</h2>
          </div>
        </section>
      </React.Fragment>

    )
  }

  return (
    <React.Fragment>
      <div className="Content">
        <div className="headline">
          <div className="dataTitle">
            <h1>{t('uhoo.menu.data')}</h1>
          </div>
          {/*<div className="dataSubTitle">
            <h3>Subtitulo Data</h3>
          </div>*/}
        </div>
      </div>
      <section className="previewData">
        <div className="containerRows">
          {showDataReport ?
            <iframe
              src={dataReport}
              width="100%"
              height="700px"
              frameBorder="0"
              Allow-CSP="script-src https://hub.uhoo.io/"
              Content-Security-Policy="default-src 'self'"
              http-equiv="Content-Security-Policy"
              content="default-src 'self'"
              frame-ancestors="https://uhoo.io https://*.uhoo.io"
            />
            :
            <div
              className="col-xs-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                transform: "translateY(500%)",
              }}
            >
              <CircularProgress />
            </div>
          }
        </div>
      </section>
    </React.Fragment>

  );
};

export default Data;



