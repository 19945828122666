import React from "react";

import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import { render } from "react-dom";
import { AppRoutes } from "./reactUtils/routes/routes";
import { store } from "./reactUtils/helpers/store";

import { I18nextProvider } from "react-i18next";
import i18n from "./reactUtils/systemVariables/languageLocalization/global/language";

import * as serviceWorker from "./serviceWorker";

//import "./index.css";
import "./assets/fonts/fonts.scss";

window.onload = function () {
  // detecta el idioma del usuario
  var ln = navigator.language || navigator.userLanguage;
  let userLanguage = ln.substring(0, 2);
  if (i18n.language !== userLanguage) {
    i18n.changeLanguage(userLanguage);
  }
};

render(
  <Provider store={store}>
    <CookiesProvider>
      <I18nextProvider i18n={i18n}>
        <Router>
          <AppRoutes />
        </Router>
      </I18nextProvider>
    </CookiesProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
