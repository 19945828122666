import React, { useState, useEffect } from "react";
import "./css/donations.css";
import { useHistory } from "react-router-dom";
import {
  getPaymentByOrganizationId,
  getDonationsFormsComponents,
  getPaymentsWithFormsRuleByLpComponent,
  getFormsQuestionsByLpComponent,
} from "../../actions/aura";
import { get as _get, cloneDeep as _cloneDeep } from "lodash";
import { CircularProgress, Popover, TextField, InputAdornment,  Box } from "@material-ui/core";
//Localization
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ReactComponent as PaginationBack } from "../../assets/icons/navigation/paginationBack.svg";
import { ReactComponent as PaginationForward } from "../../assets/icons/navigation/paginationForward.svg";
import SystemNotification from "../global/SystemNotification.jsx";
import { CSVLink } from "react-csv";
import Modal from "../../uhoo-components/Modal.jsx";
import { Search as SearchIcon, FilterList } from "@material-ui/icons";


const Donations = () => {
  let history = useHistory();
  const [payments, setPayments] = useState([]);
  const [reportData, setReportData] = useState([]);
  // const [allPayments, setAllPayments] = useState([]);
  // const [allPaymentsBase, setAllPaymentsBase] = useState([]);
  // const [paymentsBase, setPaymentsBase] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [paymentPlatformId, setPaymentPlatformId] = useState(0);
  const [paymentStatusId, setPaymentStatusId] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [attribute, setAttribute] = useState("id");
  const [order, setOrder] = useState("DSC");
  const [initialDateFilter, setInitialDateFilter] = useState(null);
  const [finalDateFilter, setFinalDateFilter] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filterApply, setFilterApply] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [filterSelected, setFilterSelected] = useState(2);
  const [showError, setShowError] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(400);
  const [headers, setHeaders] = useState([]);
  const [paymentRefSelected, setPaymentRefSelected] = useState(null);
  const [dataFilterRefPay, setDataFilterRefPay] = useState([]);

  // final
  const [queryParam, setQueryParam] = useState({});

  const { t, i18n } = useTranslation();
  let _user = JSON.parse(sessionStorage.getItem("own"));

  useEffect(async () => {
    const getData = async () => {
      let _organizationId = _get(_user, "community.uuid", "");
      let queryInit = {
        organization_id: _organizationId,
        payplatform_id: paymentPlatformId,
        paystatus_id: paymentStatusId,
        page: page,
        size: pageSize,
        attribute_name: attribute,
        order: order,
        filter_name: "",
        filter_value: ""
      }
      let response = await getPaymentByOrganizationId(queryInit);
      setQueryParam(queryInit)
      setPageSizeTotal(_get(response, "data.data.totalElements", 500));
      let getDonationForms = await getDonationsFormsComponents(_organizationId);
      setDataFilterRefPay(_get(getDonationForms, "data.data", []));
      let _payments = [];
      if (String(response).includes("Error:")) {
        setShowError(true);
      } else {
        setShowError(false);
        _payments = _get(response, "data.data.content", []);
        if (_payments.length) {
          _payments = await parsedPlatforms(_payments)
          _payments.forEach((element, index) => {
            let payment = {
              label: element.paymentName,
              key: element.lpComponentId,
            };
            delete _payments[index].id;
            delete _payments[index].lpId;
            delete _payments[index].lpComponentId;
            _payments[index].startDate = moment(
              _payments[index].startDate
            ).format("YYYY-MM-DD HH:mm");
            _payments[index].confirmationDate = moment(
              _payments[index].confirmationDate
            ).format("YYYY-MM-DD HH:mm");
          });
        }
      }
      setPayments(_payments);
      updateReport(_payments);
      setTotalPages(_get(response, "data.data.totalPages", 1));
    };

    // const getAllPayments = async () => {
    //   let _community = _get(_user, "community", []);
    //   let _organizationId = _get(_community, "uuid", '');
    //   setPayments([]);
    //   setLoading(true);
    //   let response;
    //   try {
    //     response = await getPaymentByOrganizationId(_organizationId, paymentPlatformId, paymentStatusId, page, 350, attribute, order, searchText);
    //     let _allPayments = _get(response, "data.data.content", []);
    //     let _base = _cloneDeep(_allPayments);
    //     setAllPaymentsBase(_base);
    //     _allPayments.forEach((element, index) => {
    //       let payment = { label: element.paymentName, key: element.lpComponentId };
    //       delete _allPayments[index].id;
    //       delete _allPayments[index].lpId;
    //       delete _allPayments[index].lpComponentId;
    //       _allPayments[index].startDate = moment(_allPayments[index].startDate).format("YYYY-MM-DD HH:mm");
    //       _allPayments[index].confirmationDate = moment(_allPayments[index].confirmationDate).format("YYYY-MM-DD HH:mm");
    //     });
    //     setPayments(_allPayments);
    //     setLoading(false);
    //   } catch (error) {
    //     console.log("error", error);
    //     setLoading(false);
    //   }
    // }

    const getAllData = async () => {
      await getData();
      // await getAllPayments();
    };
    getAllData();
    let _headers = [
      {
        label: t("basicWords.donations.paymentPlatform"),
        key: t("basicWords.donations.paymentPlatform"),
      },
      { label: t("basicWords.status"), key: t("basicWords.status") },
      { label: t("basicWords.refPay"), key: t("basicWords.refPay") },
      { label: t("basicWords.value"), key: t("basicWords.value") },
      { label: t("basicWords.fee"), key: t("basicWords.fee") },
      {
        label: t("basicWords.payerDocumentType"),
        key: t("basicWords.payerDocumentType"),
      },
      {
        label: t("basicWords.payerDocumentNumber"),
        key: t("basicWords.payerDocumentNumber"),
        filterEnable: true, filterName: "payerDocumentNumber"
      },
      {
        label: t("generalUser.username"), key: t("generalUser.username"),
        filterEnable: true, filterName: "buyerFullName"
      },
      {
        label: t("generalUser.email"), key: t("generalUser.email"),
        filterEnable: true, filterName: "buyerEmail"
      },
      { label: t("generalUser.adress"), key: t("generalUser.adress") },
      {
        label: t("basicWords.phoneContact"),
        key: t("basicWords.phoneContact"),
        filterEnable: true, filterName: "buyerTelephone"
      },
      { label: t("basicWords.date"), key: t("basicWords.date") },
    ];
    setHeaders(_headers);
    setLoading(false);
  }, [page]);

  const updateReport = (_payments, extraHeaders = []) => {
    let FinalReport = [];
    let _headers = [
      t("basicWords.donations.paymentPlatform"),
      t("basicWords.status"),
      t("basicWords.refPay"),
      t("basicWords.value"),
      t("basicWords.fee"),
      t("basicWords.payerDocumentType"),
      t("basicWords.payerDocumentNumber"),
      t("generalUser.username"),
      t("generalUser.email"),
      t("generalUser.adress"),
      t("basicWords.phoneContact"),
      t("basicWords.date")
    ]
    if(extraHeaders.length) {
      for (let index = 0; index < extraHeaders.length; index++) {
        let question = extraHeaders[index];
        _headers.push(question.label)
      }
    }
    FinalReport.push(_headers);

    for (let index = 0; index < _payments.length; index++) {
      let payment = _payments[index];
      let finalRow = [
        _get(payment, "paymentPlatformName", "--"),
        _get(payment, "paymentStatus", "--"),
        _get(payment, "paymentName", "--"),
        _get(payment, "paymentValue", "--"),
        _get(payment, "fee", "--"),
        _get(payment, "payerDocumentType", "--"),
        _get(payment, "payerDocumentNumber", "--"),
        _get(payment, "buyerFullName", "--"),
        _get(payment, "buyerEmail", "--"),
        _get(payment, "buyerAddress", "--"),
        _get(payment, "buyerTelephone", "--"),
        _get(payment, "startDate", "--")
      ]

      let _forms = []
      try {
        _forms = JSON.parse(payment.formAns);
      } catch (error) {
        console.log(error)
      }
      if(extraHeaders.length) {
        console.log("Extra")
        console.log(_forms, extraHeaders)
        for (let index = 0; index < extraHeaders.length; index++) {
          let qs = extraHeaders[index];
          let finalResponse = "--"
          let _form = _forms.find(form => form.uuid == qs.key);
          if(_form) {
            let response = _get(_form, "response", "")
            console.log(response)
            if(Array.isArray(response)) {
              finalResponse = response.join(",");
            } else {
              finalResponse = response;
            }
          }
          finalRow.push(finalResponse)
        }
      }
      FinalReport.push(finalRow)
    }
    console.log("FinalReport", FinalReport)
    setReportData(FinalReport);
  }


  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // filter By Text
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickFilter = (name, event) => {
    if(filterValue && filterName != name) {
      setFilterValue("");
    }
    setFilterName(name);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };  
  const handleChange = (event) => {
    setFilterValue(event.target.value);
  };

  const getPaymentStatusCatalog = () => {
    return [
      { value: 0, label: t("basicWords.all") },
      { value: 1, label: t("basicWords.approved") },
      { value: 2, label: t("basicWords.pending") },
      { value: 3, label: t("basicWords.rejected") },
      { value: 4, label: t("basicWords.expired") },
    ];
  };

  // const getOptionFilter = () => {
  //   return [
  //     // { value: 0, label: t("basicWords.searchByName") },
  //     // { value: 1, label: t("basicWords.donations.paymentPlatform") },
  //     { value: 2, label: t("basicWords.status") },
  //   ];
  // };

  // const getPaymentPlatformCatalog = () => {
  //   return [
  //     { value: 0, label: t("basicWords.all") },
  //     { value: 1, label: 'PAYU' },
  //     { value: 2, label: 'NEQUI' },
  //     { value: 3, label: 'PSE' },
  //     { value: 4, label: 'BANCOLOMBIA' },
  //     { value: 5, label: 'DAVIPLATA' },
  //     { value: 6, label: 'CREDITCARD' },
  //   ];
  // };


  const parsedPlatforms = (_payments) => {
    return _payments.map((payment) => {
      if (
        payment.paymentStatus &&
        payment.paymentStatus.toLowerCase() == "all"
      ) {
        payment.paymentStatusValue = 0;
      } else if (
        payment.paymentStatus &&
        payment.paymentStatus.toLowerCase() == "approved"
      ) {
        payment.paymentStatusValue = 1;
      } else if (
        payment.paymentStatus &&
        payment.paymentStatus.toLowerCase() == "pending"
      ) {
        payment.paymentStatusValue = 2;
      } else if (
        payment.paymentStatus &&
        payment.paymentStatus.toLowerCase() == "rejected"
      ) {
        payment.paymentStatusValue = 3;
      } else if (
        payment.paymentStatus &&
        payment.paymentStatus.toLowerCase() == "expired"
      ) {
        payment.paymentStatusValue = 4;
      }

      if (
        payment.paymentPlatformName &&
        payment.paymentPlatformName.toUpperCase() == "PAYU"
      ) {
        payment.paymentPlatformNameValue = 1;
      } else if (
        payment.paymentPlatformName &&
        payment.paymentPlatformName.toUpperCase() == "NEQUI"
      ) {
        payment.paymentPlatformNameValue = 2;
      } else if (
        payment.paymentPlatformName &&
        payment.paymentPlatformName.toUpperCase() == "PSE"
      ) {
        payment.paymentPlatformNameValue = 3;
      } else if (
        payment.paymentPlatformName &&
        payment.paymentPlatformName.toUpperCase() == "BANCOLOMBIA"
      ) {
        payment.paymentPlatformNameValue = 4;
      } else if (
        payment.paymentPlatformName &&
        payment.paymentPlatformName.toUpperCase() == "DAVIPLATA"
      ) {
        payment.paymentPlatformNameValue = 5;
      } else if (
        payment.paymentPlatformName &&
        payment.paymentPlatformName.toUpperCase() == "CREDITCARD"
      ) {
        payment.paymentPlatformNameValue = 6;
      }
      return payment;
    });
  }
  const applyFilterRefPayment = async () => {
    setPayments([]);
    setLoading(true);

    let _payments = [];
    if (paymentRefSelected === "all") {
      window.location.reload(true);
    } else {
      let respPayment = await getPaymentsWithFormsRuleByLpComponent(
        paymentRefSelected,
        0,
        1,
        10,
        "id",
        "ASC"
      );
      _payments = _get(respPayment, "data.data.content", []);
      
      let _headers = _cloneDeep(headers);
      let _extraHeaders = [];
      for (let index = 0; index < _payments.length; index++) {
        let payment = _payments[index];
        let questions = await getFormsQuestionsByLpComponent(
          paymentRefSelected
        );
        questions = _get(questions, "data.data.value", "");
        questions = JSON.parse(questions);
        if(Array.isArray(questions)) {
          questions.map((qst) => {
            let newQst = { label: qst.question, key: qst.uuid };
            _extraHeaders.push(newQst);
            _headers.push(newQst);
          });
        }
      }      
      let finalHeaders = _headers.filter(
        (item, index) =>
          index === _headers.findIndex((_item) => _item.key === item.key)
      );
      for (let index = 0; index < _payments.length; index++) {
        let answers;
        _payments.forEach((data, indexPayment) => {
          for (const property in data) {
            if (property === "formAns") {
              answers = data[property];
            }
          }
          answers = JSON.parse(answers);
          if(Array.isArray(answers)) {
            answers.forEach((ans) => {
              finalHeaders.forEach((head) => {
                if (head.key === ans.uuid) {
                  _payments[indexPayment][ans.uuid] = ans.response;
                }
              });
            });
          }
        });
      }
      let finalExtraHeaders = _extraHeaders.filter(
        (item, index) => index === _extraHeaders.findIndex((_item) => _item.key === item.key)
      )
      setHeaders(finalHeaders);
      setPayments(_payments);
      updateReport(_payments, finalExtraHeaders)
      setLoading(false);
      setTotalPages(1);
    }
  };
  const applyFilter = async () => {
    setFilterApply(true)
    if (paymentRefSelected) {
      applyFilterRefPayment();
    } else {
      let _user = {}
      try {
        _user = JSON.parse(sessionStorage.getItem("own"));
      } catch (error) {
        console.log(error);
        return;
      }
      let _startDate = initialDateFilter ? moment(initialDateFilter).startOf('day').toISOString(new Date ()) : moment().startOf('day').toISOString(new Date ())
      let _endDate = finalDateFilter ? moment(finalDateFilter).endOf('day').toISOString(new Date ()) : moment().endOf('day').toISOString(new Date ())
      let response = await getPaymentByOrganizationId({
        organization_id: _get(_user, "community.uuid", ""),
        payplatform_id: paymentPlatformId,
        paystatus_id: paymentStatusId,
        page: page,
        size: pageSize,
        attribute_name: attribute,
        order: order,
        filter_name: filterName,
        filter_value: filterValue,
        start_date: _startDate,
        end_date: _endDate
      });
      let _payments = [];
      if (String(response).includes("Error:")) {
        setShowError(true);
      } else {
        setShowError(false);
        _payments = _get(response, "data.data.content", []);
        if (_payments.length) {
          _payments = parsedPlatforms(_payments)
        }
      }
      setPayments(_payments);
      updateReport(_payments);
      setTotalPages(_get(response, "data.data.totalPages", 1));
    }
  };

  return (
    <React.Fragment>
      <div className="Content">
        <div className="headline">
          <div className="dataTitle">
            <h1>{t("uhoo.home.otherCard.donations")}</h1>
          </div>
        </div>
      </div>
      <section className="previewPayments">
        <div className="containerRowsDonations">
          {loading ? (
            <div
              className="col-xs-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                transform: "translateY(500%)",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              {/* FIlters */}
              <div className="containerGeneral">
                <div>
                  <label className="titleInputGeneral">
                    {t("basicWords.managePayments")}
                  </label>
                </div>
                <div className="containerFiltersGeneral">
                  <div
                    className={
                      !showFilters
                        ? "containerFiltersGeneralInline"
                        : " containerFiltersGeneral"
                    }
                  >
                    <div className="containerInputs">
                      <div
                        className={
                          !showFilters
                            ? "containerFiltersGeneralInline"
                            : " containerGeneralInputs"
                        }
                      >
                        <div className="firstRowFiter">
                        <div className="containerInputs">
                          <label className="titleInput">{t("basicWords.streaming.from")}</label>
                          <input
                            type="date"
                            id="searchDate"
                            data-open="picker1"
                            onChange={(e) => setInitialDateFilter(e.target.value)}
                          />
                        </div>
                        <div className="containerInputs">
                          <label className="titleInput">{t("basicWords.streaming.to")}</label>
                          <input
                            type="date"
                            id="searchDate"
                            data-open="picker1"
                            onChange={(e) => setFinalDateFilter(e.target.value)}
                          />
                        </div>
                      </div>
                        <div
                          className={
                            !showFilters ? "dNone" : " secondRowFilter"
                          }
                        >
                          {/* <div className="containerInputs">
                          <label className="titleInput">{t("basicWords.filters")}</label>
                          <select id="paymentStatus" onChange={(item) => setFilterSelected(item.target.value)}>
                            <option value="" selected disabled hidden> {t('basicWords.selectOption')} </option> 
                            {getOptionFilter().map((item) => <option key={item.label} value={item.value} > {item.label} </option> )}
                          </select>
                        </div> */}
                          {/* <div className={filterSelected == 0 ? "containerInputs" : " dNone"}>
                          <label className="titleInput">{t("basicWords.searchByName")}</label>
                          <input type="text" id='searchText' placeholder={'Ej: Carlos D'} onChange={(e) => setSearchText(e.target.value)}></input>
                        </div>
                        <div className={filterSelected == 1 ? "containerInputs" : "dNone"}>
                          <label className="titleInput">{t("basicWords.donations.paymentPlatform")}</label>
                          <select id="paymentPlatform" onChange={(item) => setPaymentPlatformId(item.target.value)}>
                            <option value="" selected disabled hidden> {t('basicWords.selectOption')} </option> 
                            {getPaymentPlatformCatalog().map((item) => <option key={item.label} value={item.value} > {item.label} </option> )}
                          </select>
                        </div> */}
                          <div
                            className={
                              filterSelected === 2 ? "containerInputs" : "dNone"
                            }
                          >
                            <label className="titleInput">
                              {t("basicWords.status")}
                            </label>
                            <select
                              id="paymentStatus"
                              onChange={(item) => setPaymentStatusId(item.target.value)}
                            >
                              <option value="" selected disabled hidden>
                                {" "}
                                {t("basicWords.selectOption")}{" "}
                              </option>
                              {getPaymentStatusCatalog().map((item) => (
                                <option key={item.label} value={item.value}>
                                  {" "}
                                  {item.label}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div
                            className={
                              filterSelected === 2 ? "containerInputs" : "dNone"
                            }
                          >
                            <label className="titleInput">
                              {t("basicWords.refPay")}
                            </label>
                            <select
                              id="paymentStatus"
                              onChange={(item) => setPaymentRefSelected(item.target.value)}
                              defaultValue={
                                paymentRefSelected ? paymentRefSelected : null
                              }
                            >
                              <option value="" selected disabled hidden>
                                {" "}
                                {t("basicWords.selectOption")}{" "}
                              </option>
                              <option value="all"> {"Todo"} </option>
                              {dataFilterRefPay.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {" "}
                                  {item.name}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!showFilters ? (
                    <div className="containerButtonsSearch">
                      {/* <div className="containerFilters toPost" role="button" onClick={() => setShowFilters(true)}><Icon.filter style={{ color: Icon.white }} className="iconButtonFilter" /></div>  */}
                      <div
                        className="containerFilters toPost"
                        role="button"
                        onClick={() => applyFilter()}
                      >
                        <p>{t("basicWords.filterAction")}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="containerButtonsSearch">
                      {/* <div className="containerFilters toPost" role="button" onClick={() => setShowFilters(false)}><Icon.xClose style={{ color: Icon.white }} className="iconButtonFilter" /></div>  */}
                      <div
                        className="containerFilters toPost"
                        role="button"
                        onClick={() => applyFilter()}
                      >
                        <p>{t("basicWords.filterAction")}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {payments.length > 0 ? (
                <table id="payments__table">
                  <tr>
                    {headers.map((column) => {
                      if(column.filterEnable) {
                        return (
                          <>
                            <th
                              aria-describedby={(Boolean(anchorEl) ? `popover-${column.filterName}` : undefined)}
                              variant="contained"
                              onClick={(event) => handleClickFilter(column.filterName, event)}
                            >
                              <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                {column.label}
                                <FilterList htmlColor={ filterApply && filterValue && filterName == column.filterName ? "#FF8614" : "inherit" } />
                              </Box>
                            </th>
                            <Popover
                              id={`popover-${column.filterName}`}
                              open={Boolean(anchorEl)}
                              anchorEl={anchorEl}
                              onClose={handleCloseFilter}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              elevation={1}
                            >
                              <Box sx={{ p: 2 }}>
                                <TextField
                                  id="search"
                                  type="search"
                                  label="Search"
                                  value={filterValue}
                                  onChange={(event) => handleChange(event)}
                                  sx={{ width: 600 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end" onClick={() => applyFilter()}>
                                        <SearchIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            </Popover>
                          </>
                        );
                      }
                      return (<th>{column.label}</th>)
                    })}
                  </tr>
                  {payments.map((row) => {
                    let paymentWithFormat = "--";
                    paymentWithFormat =
                      typeof row.paymentValue === "string"
                        ? row.paymentValue
                            .replace(",00", "")
                            .toLocaleString("es")
                        : row.paymentValue.toLocaleString("es");

                    let feeWithFormat = "--";
                    feeWithFormat =
                      typeof row.fee === "string"
                        ? row.fee.replace(",00", "").toLocaleString("es")
                        : row.fee.toLocaleString("es");

                    let arrayRow = [];
                    for (const key in row) {
                      let item = { key, value: row[key] };
                      arrayRow.push(item);
                    }
                    let ret = [];
                    headers.forEach((head) => {
                      arrayRow.forEach((item, index) => {
                        if (head.key == item.key) {
                          ret.push(item);
                        }
                      });
                    });
                    return (
                      <tr key={row.id}>
                        <td>
                          {row.paymentPlatformName
                            ? row.paymentPlatformName
                            : "--"}
                        </td>
                        <td>{row.paymentStatus ? row.paymentStatus : "--"}</td>
                        <td>{row.paymentName ? row.paymentName : "--"}</td>
                        <td>
                          {row.paymentValue ? "$" + paymentWithFormat : "--"}
                        </td>
                        <td>{row.fee ? "$" + feeWithFormat : "--"}</td>
                        <td>
                          {row.payerDocumentType ? row.payerDocumentType : "--"}
                        </td>
                        <td>
                          {row.payerDocumentNumber
                            ? row.payerDocumentNumber
                            : "--"}
                        </td>
                        <td>{row.buyerFullName ? row.buyerFullName : "--"}</td>
                        <td>{row.buyerEmail ? row.buyerEmail : "--"}</td>
                        <td>{row.buyerAddress ? row.buyerAddress : "--"}</td>
                        <td>
                          {row.buyerTelephone ? row.buyerTelephone : "--"}
                        </td>
                        <td>
                          {moment(row.startDate).format("YYYY-MM-DD HH:mm")
                            ? moment(row.startDate).format("YYYY-MM-DD HH:mm")
                            : "--"}
                        </td>
                        {ret.length > 0
                          ? ret.map((item) => {
                              return (
                                <td>
                                  {item.value
                                    ? typeof item.value == "object"
                                      ? item.value.map((data, i) => {
                                          let index = i + 2;
                                          return index <= item.value.length
                                            ? data + "-"
                                            : data;
                                        })
                                      : item.value
                                    : "--"}
                                </td>
                              );
                            })
                          : null}
                      </tr>
                    );
                  })}
                </table>
              ) : (
                <div className="center">
                  <h1 className="titleInputGeneral">
                    {t("basicWords.noData")}
                  </h1>
                </div>
              )}
              <div style={{ marginLeft: "5px" }}>
                <CSVLink
                  className="containerFilters toPost"
                  filename={
                    t("uhoo.home.otherCard.donations") +
                    " - " +
                    _get(_user, "community.name", "")
                  }
                  data={reportData}
                  asyncOnClick
                >
                  {t("basicWords.download")}
                </CSVLink>
              </div>

              <div className="containerPaginationDonation">
                <div
                  className="pBack"
                  onClick={() => (page == 1 ? null : setPage(page - 1))}
                  role="button"
                >
                  <PaginationBack className="paginationICO" role="button" />
                </div>
                <div className="textDataPagination">
                  {page} / {totalPages > 0 ? totalPages : 1}
                </div>
                <div
                  className="pForward"
                  onClick={() =>
                    page >= totalPages ? null : setPage(page + 1)
                  }
                  role="button"
                >
                  <PaginationForward className="paginationICO" role="button" />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      {!!showError && (
        <SystemNotification
          setShowNotification={setShowError}
          type="Error"
          caption={t("basicWords.internalServerError")}
        />
      )}
    </React.Fragment>
  );
};

export default Donations;
