import React, { Component, Fragment } from "react";
import { get as _get } from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Collapse from "@kunukn/react-collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ChevronDown, ChevronUp, AlertCircle, Eye, Plus } from "react-feather";
import { ReactComponent as BackArrow } from '../../assets/icons/navigation/backArrow.svg';
import {
  Divider,
  Card,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Button,
  Tooltip,
  TextField,
} from "@material-ui/core";

//Localization
import { withTranslation } from "react-i18next";

//Queries
import {
  getLandingPageSections,
  getThemes,
  getLandingpageStrucureById,
  updateStructuresPositions,
  updateLpSubpageByLpId,
  updateLpSubpageGroupByLpId,
  createLpSubpageByLpId,
  createLpSubpageGroupByLpId,
  updateLpSection,
  getFile,
  updateLpTheme,
  disableSection,
  createNewLpSectionBySectionIdAndLpId,
} from "../../actions/aura";

//Components
import {
  ButtonComponent,
  Breadcrumb,
  ButtonsFooterComponent,
  GeneralModal,
} from "../../aura-components";
import AddNewModal from "./AddNewModal.jsx";
import "./MyWebPage.scss";
import "./sections/css/sections.scss";
import SystemNotification from "../global/SystemNotification.jsx";
import HorizontalDragDrop from "./HorizontalDragDrop.jsx";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

// Images
import AuraLogin from "../../assets/images/loginLogo.png";
import UhooLogin from "../../assets/images/LogoUhooColor.png";
let tableSize = 64 + 70 + 15 + 20 + 62 + 68 + 64 + 24 + 61 + 73;
class MyWebPage extends Component {
  constructor(props) {
    super(props);

    this.disableSectionFunction = this.disableSectionFunction.bind(this);
    this.createLpSubpageByLpId = this.createLpSubpageByLpId.bind(this);
    this.updateNewSectionComponentFunction =
      this.updateNewSectionComponentFunction.bind(this);
    this.state = {
      height: 0,
      posFooter: null,
      organizationId: null,
      country: null,
      region: null,
      city: null,
      registers: [],
      sections: null,
      sectionToAdd: null,
      lpThemeName: null,
      columnSelected: null,

      loadingInfo: true,
      previousPath: false,

      //subpage management
      selectedSectionSubpage: null,
      addNewModalVisible: false,
      indexInitialDataChange: null,

      availableSections: [],

      //Themes
      themesModalVisible: false,
      goToWithoutSaveModalVisible: false,
      valueRadioGroup: 1,
      initialData: [],
      indexInitialData: null,
      typeMove: [],
      sourceDataMoved: [],
      destinationDataMoved: [],
      FlagClassName: false,
      isOpenConfigStyle: false,
      openDialog: false,
      openNotificationModal: false,
      newThemeId: null,
      oldThemeId: null,
      haveChangeInTheme: false,

      // Modals
      showSuccess: false,
      showWarning: false,
      showError: false,
      contentNotificationModal: "",
      showOpenImageTheme: false,
      themeSelected: null,
      openModalToChangeName: false,
      openViewHelpModal: false,
      notAvailableWebpage: false,
      openNotificationMovePosition: false,
    };
  }

  componentDidMount() {
    let _user = JSON.parse(sessionStorage.getItem("own"));
    let _community = _get(_user, "community", []);
    const organizationId = _get(_community, "uuid", 1);
    this.getLandingPageInfoFunction(organizationId, null);
    this.setState({
      organizationId: organizationId,
    });
  }

  componentWillMount() {
    this._isMounted = true;
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };

  componentWillUnmount() {
    this._isMounted = false;
  };

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  //Queries
  async getLandingPageInfoFunction(organizationId) {
    let sections = [];
    let subpagesToShow = [];
    let subpagesWithSections = [];
    let groupSubpagesToShow = [];
    const { t } = this.props;
    let response = await getLandingPageSections(organizationId);
    if (response.data.description.includes("not found")) {
      this.setState({
        notAvailableWebpage: true,
      });
      return;
    }
    let responseDTO = await getLandingpageStrucureById(_get(response, "data.data.landingPageId", 1));

    if (String(responseDTO).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      let subpages = responseDTO.data.data.lpSubpages;
      let groupSubpages = responseDTO.data.data.lpSubpageGroups;
      subpages.forEach((subpage) => {
        //If subpage is not into group subpage
        if (subpage.isEnabled === 1 && subpage.subpageGroupId === null) {
          subpagesToShow.push(subpage);
          let subpageExists = [];
          subpageExists = subpagesToShow.find((item) => item.id === subpage.id);
          if (!subpage.sections) {
            subpage.sections = [];
          }
          if (subpageExists && subpageExists.length === 0) {
            subpagesToShow.push(subpage);
          }
        } else {
          if (subpage.isEnabled === 1) {
            subpagesWithSections.push(subpage);
            let subpageExists = [];
            subpageExists = subpagesWithSections.find(
              (item) => item.id === subpage.id
            );
            if (!subpage.sections) {
              subpage.sections = [];
            }
            if (subpageExists && subpageExists.length === 0) {
              subpagesWithSections.push(subpage);
            }
          }
        }
      });

      responseDTO.data.data.lpsections.forEach((section) => {
        //Find sections in mainpage
        if (
          section.mustBeSubpage !== 1 &&
          section.subpages.length === 0 &&
          section.positionInMainPage !== null
        ) {
          sections.push(section);
        } else {
          if (section.subpages.length > 0) {
            //   //If have a subpage asociated
            subpagesWithSections.forEach((subpage, index) => {
              section.subpages.forEach((idAsociatedSection, index2) => {
                let sectionSubpages = [];
                if (subpage.id === idAsociatedSection) {
                  sectionSubpages.push(section);
                }
                if (sectionSubpages.length > 0) {
                  if (section.positionsInSubpage === null) {
                  } else {
                    section.positionsInSubpage =
                      section.positionsInSubpage[index2];
                  }
                  subpagesWithSections[index].sections[
                    subpagesWithSections[index].sections.length
                  ] = section;
                }
              });
            });
            subpagesToShow.forEach((subpage, index) => {
              section.subpages.forEach((idAsociatedSection, index2) => {
                let sectionSubpages = [];
                if (subpage.id === idAsociatedSection) {
                  sectionSubpages.push(section);
                }
                if (sectionSubpages.length > 0) {
                  if (section.positionsInSubpage === null) {
                  } else {
                    section.positionsInSubpage =
                      section.positionsInSubpage[index2];
                  }
                  subpagesToShow[index].sections[
                    subpagesToShow[index].sections.length
                  ] = section;
                }
              });
            });
          }
        }
      });

      groupSubpages.forEach((group, index) => {
        //Find subpage into group subpages
        var sectionsGroupSubpages = [];
        subpages.forEach((subpage) => {
          if (
            subpage.subpageGroupId != null &&
            subpage.subpageGroupId === group.id &&
            subpage.isEnabled === 1
          ) {
            subpage.positionInHeader = null;
            sectionsGroupSubpages[sectionsGroupSubpages.length] = subpage;
            groupSubpages[index].sections = sectionsGroupSubpages;
          }
        });
      });

      var positionSections = sections.map(
        (element) => element.positionInMainPage
      );
      const lastSectionPosition = Math.max.apply(null, positionSections);
      // this.setState({ posFooter: lastSectionPosition });
      sections = sections.sort(compare);

      sections.forEach((element, index) => sections[index].positionInMainPage = index);

      function compare(a, b) {
        if (a.positionInMainPage === null) a.positionInMainPage = lastSectionPosition + 2;
        if (a.name === "FOOTER") a.positionInMainPage = lastSectionPosition + 2;
        if (b.name === "FOOTER") b.positionInMainPage = lastSectionPosition + 2;
        if (a.name === "CONTACT") a.positionInMainPage = lastSectionPosition + 1;
        if (b.name === "CONTACT") b.positionInMainPage = lastSectionPosition + 1;
        if (a.positionInMainPage > b.positionInMainPage) return 1;
        if (b.positionInMainPage > a.positionInMainPage) return -1;
        return 0;
      }

      function compareSubpageAndGroupSubpage(a, b) {
        if (a.positionInHeader > b.positionInHeader) return 1;
        if (b.positionInHeader > a.positionInHeader) return -1;
        return 0;
      }

      function compareSubapageInGroupSubpage(a, b) {
        if (a.positionInSubpageGroup > b.positionInSubpageGroup) return 1;
        if (b.positionInSubpageGroup > a.positionInSubpageGroup) return -1;
        return 0;
      }

      function compareSectionsInSubpage(a, b) {
        if (a.positionsInSubpage > b.positionsInSubpage) return 1;
        if (b.positionsInSubpage > a.positionsInSubpage) return -1;
        return 0;
      }

      var initialData = [
        {
          style: "mainpage",
          id: 10001,
          sections: sections,
          name: t("basicWords.mainpage"),
          positionInHeader: 0,
        },
      ];

      subpagesToShow = subpagesToShow.sort(compareSubpageAndGroupSubpage);
      subpagesToShow.forEach((element, index) => {
        //add subpages enable
        if (!subpagesToShow[index].sections) {
          subpagesToShow[index].sections = [];
        }

        subpagesToShow[index].style = "subpage";
        if (subpagesToShow[index].sections) {
          subpagesToShow[index].sections = subpagesToShow[index].sections.sort(
            compareSectionsInSubpage
          );
        } else {
          groupSubpages[index].sections = [];
        }
        initialData[initialData.length] = subpagesToShow[index];
      });
      groupSubpages = groupSubpages.sort(compareSubpageAndGroupSubpage);
      groupSubpages.forEach((element, index) => {
        //add group subpages enable
        if (element.isEnabled === 1) {
          groupSubpages[index].style = "groupSubpage";
          if (groupSubpages[index].sections) {
            groupSubpages[index].sections = groupSubpages[index].sections.sort(
              compareSubapageInGroupSubpage
            );
          } else {
            groupSubpages[index].sections = [];
          }
          groupSubpagesToShow[groupSubpagesToShow.length] =
            groupSubpages[index];
          initialData[initialData.length] = groupSubpages[index];
        }
      });
      initialData = initialData.sort(compareSubpageAndGroupSubpage);
      let structureSections = {};
      let sectionsIds = [];
      let columns = {};
      let columnOrder = [];

      sections.forEach((element, index) => {
        let aux = index;
        index = element.id;
        structureSections[index] = element;
        sectionsIds[aux] = index;
      });

      columns = {
        "column-10001": {
          style: "mainpage",
          id: "column-10001",
          sectionsIds: sectionsIds,
          name: t("basicWords.mainpage"),
          positionInHeader: 0,
        },
      };
      let lengthColumns = 1;
      initialData.forEach((data, indexColumns) => {
        sectionsIds = [];
        indexColumns = "column-" + data.id;
        data.sections.forEach((section, index) => {
          let auxSection = index;
          index = section.id;
          structureSections[index] = section;
          sectionsIds[auxSection] = index;
        });

        if (data.style === "subpage") {
          columns[indexColumns] = {
            style: "subpage",
            isEnabled: data.isEnabled,
            isVisible: data.isVisible,
            sections: data.sections,
            id: indexColumns,
            sectionsIds: sectionsIds,
            name: data.name,
            positionInHeader: lengthColumns,
          };
        }

        if (data.style === "groupSubpage") {
          columns[indexColumns] = {
            style: "groupSubpage",
            id: indexColumns,
            sectionsIds: sectionsIds,
            name: data.name,
            isEnabled: data.isEnabled,
            sections: data.sections,
            positionInHeader: lengthColumns,
          };
        }

        columnOrder[lengthColumns] = indexColumns;
        lengthColumns = lengthColumns + 1;
      });

      this.setState({
        organizationId: organizationId,
        organizationName: response.data.data.organizationName,
        lpId: response.data.data.landingPageId,
        country: response.data.data.country,
        region: response.data.data.region,
        city: response.data.data.city,
        registers: sections,
        loadingInfo: false,
        lpThemeName: response.data.data.lpThemeName,
        lpThemeId: response.data.data.lpThemeId,
        initialData,
        columnOrder,
        structureSections,
        columns,
        urlPage: response.data.data.lpUrl,
      });
      
      //Get image
      let img = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" +
        response.data.data.lpThemeThumbnailUrl
      );
      this.setState({
        lpThemeImg: img,
      });
      // if (!flagNotState) {
      //   this.handleSelectTheme();
      // }
      // else{
      //   window.location.reload(true);
      // }
      this.handleSelectTheme();
    }
  }

  async createLpSubpageOrGroupSubpage(
    lpId,
    organizationId,
    sectionToAdd,
    valueRadioGroup
  ) {
    let initialData = this.state.initialData;
    switch (valueRadioGroup) {
      case 1:
        let subpageDTO = {
          name: sectionToAdd,
          id: null,
          positionInHeader: initialData.length - 1,
          positionInSubpageGroup: null,
          subpageGroupId: null,
          displayType: 0,
          isVisible: 0,
          isEnabled: 1,
        };
        var response = await createLpSubpageByLpId(lpId, subpageDTO);
        break;
      case 2:
        let groupSubpageDTO = {
          name: sectionToAdd,
          id: null,
          positionInHeader: initialData.length - 1,
          isEnabled: 1,
        };
        var response = await createLpSubpageGroupByLpId(lpId, groupSubpageDTO);
        break;
    }
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.itemSuccessfullyCreated"),
      });
      this.getLandingPageInfoFunction(organizationId, this.state.urlPage);
    }
  };

  async createLpSubpageByLpId(lpId, name, groupId) {
    let registers = this.state.registers;
    let subpageDTO = {
      name: name,
      id: null,
      positionInHeader: null,
      positionInSubpageGroup: registers.length,
      subpageGroupId: parseInt(groupId.substring(7, groupId.length)),
      displayType: 0,
      isVisible: 1,
      isEnabled: 1,
    };
    var response = await createLpSubpageByLpId(lpId, subpageDTO);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      registers[registers.length] = subpageDTO;
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
        registers
      });
      this.getLandingPageInfoFunction(this.state.organizationId, null);
    }
  }

  async disableSectionFunction(LpSectionPutDto, lpsubpage_id) {
    this.setState({ loadingInfo: true });
    let response = await disableSection(LpSectionPutDto.id, lpsubpage_id);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.sectionDisabledSuccessful"),
      });
      this.getLandingPageInfoFunction(
        this.state.organizationId,
        this.state.urlPage
      );
    }
    this.setState({
      loadingInfo: false,
      isLoadingButton: false,
    });
  }

  async getThemesFunction(lpId, organizationId) {
    let response = await getThemes(lpId, organizationId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      let responseData = response.data.data;
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].thumbnailPath) {
          //Get image
          let img = await getFile(
            "https://somosaura-cms.s3.amazonaws.com/" +
            responseData[i].thumbnailPath
          );
          responseData[i].img = img;
        }
      }
      this.setState({
        themesData: responseData,
      });
    }
  }

  async updateLpSectionPositionFunction(dataMySite) {
    const {
      typeMove,
      sourceDataMoved,
      destinationDataMoved,
      dataMoved,
      initialData,
    } = this.state;
    let newPosition = [];
    let flag = 0;
    const { t } = this.props;
    typeMove.forEach(async (move, index) => {
      newPosition = []; // to empty newPosition in every case
      if (move.type === "vertical") {
        let data = initialData[move.index];
        var type = "";
        var level = "";
        var parentId = "";
        if (data.style === "mainpage") {
          type = 2; //Have only sections into
          level = 3;
          parentId = null;
        }
        if (data.style === "subpage") {
          type = 2; //Have only sections into
          level = 2;
          parentId = data.id;
        }
        if (data.style === "groupSubpage") {
          type = 1; //Have only subpages into
          level = 1;
          parentId = data.id;
        }
        for (var i = 0; i < data.sections.length; i++) {
          newPosition[newPosition.length] = {
            level: level,
            parentId: parentId,
            type: type,
            position: newPosition.length,
            id: data.sections[i].id,
          };
        }
        flag = 1;
      }
      if (move.type === "horizontal") {
        dataMySite.forEach((element, index) => {
          switch (element.style) {
            case "subpage":
              newPosition[newPosition.length] = {
                level: 0,
                parentId: element.id,
                type: 1,
                position: newPosition.length + 1,
                id: element.id,
              };
              break;
            case "groupSubpage":
              newPosition[newPosition.length] = {
                level: 0,
                parentId: element.id,
                type: 0,
                position: newPosition.length + 1,
                id: element.id,
              };
              break;
          }
        });
        flag = 1;
      }
      if (move.type === "anotherList") {
        var type = "";
        var level = "";
        var parentId = "";

        if (sourceDataMoved.style === "mainpage") {
          type = 2; //Have only sections into
          level = 3;
          parentId = null;
        }
        if (sourceDataMoved.style === "subpage") {
          type = 2; //Have only sections into
          level = 2;
          parentId = sourceDataMoved.id;
        }
        if (sourceDataMoved.style === "groupSubpage") {
          type = 1; //Have only subpages into
          level = 1;
          parentId = sourceDataMoved.id;
        }
        for (var i = 0; i < sourceDataMoved.sections.length; i++) {
          newPosition[newPosition.length] = {
            level: level,
            parentId: parentId,
            type: type,
            position: newPosition.length,
            id: sourceDataMoved.sections[i].id,
          };
        }
        if (destinationDataMoved.style === "mainpage") {
          type = 2; //Have only sections into
          level = 0;
          parentId = null;
        }
        if (destinationDataMoved.style === "subpage") {
          type = 2; //Have only sections into
          level = 2;
          parentId = destinationDataMoved.id;
        }
        if (destinationDataMoved.style === "groupSubpage") {
          type = 1; //Have only subpages into
          level = 1;
          parentId = destinationDataMoved.id;
        }
        for (var i = 0; i < destinationDataMoved.sections.length; i++) {
          newPosition[newPosition.length] = {
            level: level,
            parentId: parentId,
            type: type,
            position: newPosition.length,
            id: destinationDataMoved.sections[i].id,
          };
        }
        flag = 1;
      }

      if (
        (flag === 1 &&
          // (sourceDataMoved.style === "groupSubpage" ||
          //   destinationDataMoved.style === "groupSubpage") //If move is from group to group
          !(sourceDataMoved.style === "mainpage" || destinationDataMoved.style === "subpage" ) &&
          !( sourceDataMoved.style === "subpage" || destinationDataMoved.style === "mainpage" )) ||
        (flag === 1 && move.type === "horizontal") || //If move is horizontal or vertical
        move.type === "vertical"
      ) {
        let newTypeMove = [];
        this.setState({ typeMove: newTypeMove });
        if (flag === 1) {
          let response = await updateStructuresPositions(newPosition);
          if (String(response).includes("Error:")) {
            this.setState({
              showError: true,
              contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
            });
          } else {
            this.setState({
              showSuccess: true,
              contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
            });
            this.getLandingPageInfoFunction(
              this.state.organizationId,
              this.state.urlPage
            );
          }
          this.setState({ isLoadingButton: false });
        }
      }

      if (
        sourceDataMoved.style === "mainpage" &&
        destinationDataMoved.style === "subpage"
      ) {
        let DTOMoved = {
          id: dataMoved.id,
          customName: dataMoved.customName,
          name: dataMoved.name,
          positionMainPage: null,
          positionInSubpages: dataMoved.positionsInSubpage,
          subpagesIds: dataMoved.subpages,
          isEnabled: 1,
          layoutStyleId: dataMoved.layoutStyleId,
        };

        let newTypeMove = [];
        this.setState({ typeMove: newTypeMove });
        let response = await updateLpSection(DTOMoved);
        const { t } = this.props;
        if (String(response).includes("Error:")) {
          this.setState({
            showError: true,
            contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
          });
        } else {
          this.setState({
            showSuccess: true,
            contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
            sourceDataMoved: [],
            destinationDataMoved: [],
          });
          this.getLandingPageInfoFunction(
            this.state.organizationId,
            this.state.urlPage
          );
        }
        this.setState({
          isLoadingButton: false,
        });
      }

      if (sourceDataMoved.style === "subpage" && destinationDataMoved.style === "mainpage") {
        let DTOMoved = {
          id: dataMoved.id,
          customName: dataMoved.customName,
          name: dataMoved.name,
          positionMainPage: initialData[0].sections.length,
          positionInSubpages: [],
          subpagesIds: [],
          isEnabled: 1,
          layoutStyleId: dataMoved.layoutStyleId,
        };
        let newTypeMove = [];
        const { t } = this.props;
        this.setState({ typeMove: newTypeMove });
        let response = await updateLpSection(DTOMoved);
        if (String(response).includes("Error:")) {
          this.setState({
            showError: true,
            contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
          });
        } else {
          this.setState({
            showSuccess: true,
            contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
            sourceDataMoved: [],
            destinationDataMoved: [],
          });
          this.getLandingPageInfoFunction(
            this.state.organizationId,
            this.state.urlPage
          );
        }
        this.setState({
          isLoadingButton: false,
        });
      }

      if (sourceDataMoved.style === "subpage" && destinationDataMoved.style === "subpage") {
        delete dataMoved.positionInMainPage;
        delete dataMoved.subpagesIds;
        delete dataMoved.positionsInSubpage;
        delete dataMoved.subpages;
        delete dataMoved.sectionsIds;
        delete dataMoved.positionMainPage;
        dataMoved.isEnabled = 1;
        dataMoved.positionMainPage = null;
        dataMoved.positionInSubpages = [destinationDataMoved.sections.length];
        dataMoved.subpagesIds = [destinationDataMoved.id];
        let DTOSectionMoved = {
          customName: dataMoved.customName,
          name: dataMoved.name,
          id: dataMoved.id,
          layoutStyleId: dataMoved.layoutStyleId,
          mustBeSubpage: dataMoved.mustBeSubpage,
          style: dataMoved.style,
          isEnabled: 1,
          positionMainPage: null,
          positionInSubpages: [destinationDataMoved.sections.length],
          subpagesIds: [destinationDataMoved.id],
        };
        let newTypeMove = [];
        this.setState({ typeMove: newTypeMove });
        let response = await updateLpSection(DTOSectionMoved);
        const { t } = this.props;
        if (String(response).includes("Error:")) {
          this.setState({
            showError: true,
            contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
          });
        } else {
          this.setState({
            showSuccess: true,
            contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
            sourceDataMoved: [],
            destinationDataMoved: [],
          });
          this.getLandingPageInfoFunction(
            this.state.organizationId,
            this.state.urlPage
          );
        }
        this.setState({
          isLoadingButton: false,
        });
      }
    });
  }

  async updateLpSubpageGroupByLpId(LpSubpageGroupDto) {
    const { t } = this.props;
    let response = await updateLpSubpageGroupByLpId(LpSubpageGroupDto);
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
    }
    this.setState({ openModalToChangeName: false });
  }

  async updateLpSubpageByLpId(LpSubpageDto, flagEditVisibilityPage) {
    const { t } = this.props;
    let response = await updateLpSubpageByLpId(LpSubpageDto);
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      if (flagEditVisibilityPage) {
        this.setState({
          showSuccess: true,
          contentNotificationModal: t("basicWords.modal.visibilitySubpage"),
        });
      } else {
        this.setState({
          showSuccess: true,
          contentNotificationModal: t("basicWords.modal.deleteElementSuccessful"),
        });
      }
    }
    this.setState({ openModalToChangeName: false });
  }

  async updateLpThemeFunction(lpId, lpThemeId) {
    const { previousPath, organizationId, urlPage } = this.state;
    const { t } = this.props;
    let response = await updateLpTheme(lpId, lpThemeId);
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.generalStyleSuccessfullyModified"),
      });
      this.updatePage(organizationId, urlPage, previousPath);
    }
    this.setState({ isLoadingButton: false });
  }

  async updateNewSectionComponentFunction(sectionId, lpId, subpageId) {
    let subpage_id = subpageId !== undefined ? subpageId : "";
    let response = await createNewLpSectionBySectionIdAndLpId(
      sectionId,
      lpId,
      subpage_id,
      sectionId //standard layoutid == sectionId
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.itemSuccessfullyCreated"),
      });
      // this.setState({open: true});
      this.getLandingPageInfoFunction(
        this.state.organizationId,
        this.state.urlPage
      ); //Add info sections

    }
  }

  onGroupSubpageOrSubPageDelete = (data) => {
    let { organizationId, initialData } = this.state;
    let subpageOrgroupSubpageToUpdate = null;
    let id = data.id;
    id = id.substring(7, id.length);
    id = parseInt(id);
    initialData.forEach((element, index) => {
      if (element.id === id) {
        initialData[index].isEnabled = 0;
        subpageOrgroupSubpageToUpdate = initialData[index];
      }
    });
    if (subpageOrgroupSubpageToUpdate.style === "subpage") {
      this.updateLpSubpageByLpId(subpageOrgroupSubpageToUpdate);
    }
    if (subpageOrgroupSubpageToUpdate.style === "groupSubpage") {
      this.updateLpSubpageGroupByLpId(subpageOrgroupSubpageToUpdate);
    }
    this.setState({ openDialog: false });
    this.getLandingPageInfoFunction(organizationId, this.state.urlPage);
    this.setState({ openDialog: false });
  };

  OnchangeIsVisible = (data) => {
    let organizationId = this.state.organizationId;
    let initialData = this.state.initialData;
    let subpageOrgroupSubpageToUpdate = null;
    let id = data.id;
    id = id.substring(7, id.length);
    id = parseInt(id);
    initialData.forEach((element, index) => {
      if (element.id === id) {
        if (element.isVisible === 1) {
          initialData[index].isVisible = 0;
        } else {
          initialData[index].isVisible = 1;
        }
        subpageOrgroupSubpageToUpdate = initialData[index];
      }
    });
    this.updateLpSubpageByLpId(subpageOrgroupSubpageToUpdate, true);
    this.getLandingPageInfoFunction(organizationId, this.state.urlPage);
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      showWarning: true,
      contentNotificationModal: t("basicWords.modal.noSectionSelected"),
    });
    this.handleOkWarning();
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  getMyWebpage = () => {
    window.open("https://" + this.state.urlPage);
  };

  handleSelectTheme() {
    this.getThemesFunction(this.state.lpId, this.state.organizationId);
    this.setState({ themesModalVisible: true });
  }

  //-----------Update page after modal------------
  updatePage = (organizationId, urlPage, previousPath) => {
    // let lpId = this.state.lpId;
    this.getLandingPageInfoFunction(organizationId, urlPage);
    this.setState({
      previousPath,
    });
    this.setState({ themesModalVisible: false });
    this.forceUpdate();
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  SavePositionSection = () => {
    var { initialData, columnOrder, haveChangeInTheme, lpId, newThemeId } =
      this.state;
    columnOrder = columnOrder.slice(0, columnOrder.length);
    let orderColumns = [];
    columnOrder.forEach((element) => {
      if (element !== undefined) {
        orderColumns[orderColumns.length] = parseInt(
          element.substring(7, element.length)
        );
      }
    });
    let dataMySite = [];
    orderColumns.forEach((orderColumn) => {
      //reorder initial data
      initialData.forEach((data) => {
        if (data.id.length > 7) {
          if (parseInt(data.id.substring(7, data.id.length)) === orderColumn) {
            data.id = parseInt(data.id.substring(7, data.id.length));
            dataMySite.push(data);
          }
        } else {
          if (data.id === orderColumn) {
            dataMySite.push(data);
          }
        }
      });
    });

    this.updateLpSectionPositionFunction(dataMySite);
    if (haveChangeInTheme) {
      this.updateLpThemeFunction(lpId, newThemeId);
    }
    this.setState({
      FlagClassName: false,
      isLoading: true,
      saveButtonDisabled: true,
      openNotificationModal: true,
    });
  };

  addNewModal = () => {
    this.setState({ addNewModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({ goToWithoutSaveModalVisible: false });
  };

  onCloseAddNewModal = () => {
    this.setState({ addNewModalVisible: false });
  };

  onSaveEditAddNewModal = () => {
    const { t } = this.props;
    const { sectionToAdd, valueRadioGroup, lpId, organizationId } = this.state;
    let typeToAdd = parseInt(valueRadioGroup);
    this.setState({
      isLoadingButton: true,
      addNewModalVisible: false,
    });
    if (sectionToAdd === null || sectionToAdd === "" || sectionToAdd === " ") {
      this.setState({
        showWarning: true,
        contentNotificationModal: t("basicWords.nameSubpageOrGroupSubpage"),
      });
    } else {
      this.createLpSubpageOrGroupSubpage(
        lpId,
        organizationId,
        encodeURIComponent(sectionToAdd),
        typeToAdd
      );
    }
  };

  OnChangeAddNewModal = (e) => {
    let { sectionToAdd } = { ...this.state };
    sectionToAdd = e.value;
    this.setState({
      sectionToAdd,
    });
  };

  handleChange = (e) => {
    let { sectionToAdd } = { ...this.state };
    sectionToAdd = e.value;
    this.setState({
      sectionToAdd,
    });
  };

  handleChangeTheme = (e, id) => {
    this.setState({
      newThemeId: id,
      saveButtonDisabled: false,
      FlagClassName: true,
      haveChangeInTheme: true,
    });
  };

  setNotificationMovePostion = () => {
    const openNotificationMovePosition = this.state;
    this.setState({ openNotificationMovePosition: !openNotificationMovePosition });
  };

  OnChangeInput = (event) => {
    const { value } = event.target;
    let { sectionToAdd } = { ...this.state };
    sectionToAdd = value;
    this.setState({ sectionToAdd });
  };

  OnChangeRadioGroup = (e) => {
    this.setState({ valueRadioGroup: e.target.value });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result, provided) => {
    const { t } = this.props;
    const { destination, source, draggableId, type } = result;
    var typeMove = this.state.typeMove;
    var indexInitialDataChange = this.state.indexInitialDataChange;
    const openNotificationMovePosition = this.state.openNotificationMovePosition;
    var initialData = this.state.initialData;

    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    initialData.forEach((element, index) => {
      if (
        element.id ===
        parseInt(source.droppableId.substring(7, source.droppableId.length))
      ) {
        indexInitialDataChange = index;
      }
    });

    if (type === "horizontal") {
      if (
        this.state.columnOrder[source.index] === "column-10001" ||
        this.state.columnOrder[destination.index] === "column-10001"
      ) {
        this.setState({
          showWarning: true,
          contentNotificationModal: t("basicWords.mainpageAtTheTop"),
        });

        return;
      }
      if (
        this.state.columnOrder[destination.index] === "column-1000" ||
        this.state.columnOrder[source.index] === "column-1000"
      ) {
        this.setState({
          showWarning: true,
          contentNotificationModal: t("basicWords.mainpageAtTheTop"),
        });
        return;
      }
      const newColumnOrder = Array.from(this.state.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...this.state,
        columnOrder: newColumnOrder,
      };
      this.setState(newState);
      typeMove.push({ type: type, index: indexInitialDataChange });
      this.setState({
        typeMove,
        FlagClassName: true,
      });
      this.forceUpdate();
      return;
    }

    const home = this.state.columns[source.droppableId];
    const foreign = this.state.columns[destination.droppableId];

    if (home === foreign) {
      //if move is vertical
      const newSectionsIds = Array.from(home.sectionsIds);
      newSectionsIds.splice(source.index, 1);
      newSectionsIds.splice(destination.index, 0, parseInt(draggableId));

      const newHome = {
        ...home,
        sectionsIds: newSectionsIds,
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newHome.id]: newHome,
        },
      };
      var dataTarjet = initialData.find(
        (item) =>
          item.id ===
          parseInt(source.droppableId.substring(7, source.droppableId.length))
      );

      var sourceData = dataTarjet.sections[source.index];
      var destinationData = dataTarjet.sections[destination.index];
      if (
        sourceData.name === "HEADER" ||
        destinationData.name === "HEADER" ||
        sourceData.name === "HOME" ||
        destinationData.name === "HOME" ||
        sourceData.name === "FOOTER" ||
        destinationData.name === "FOOTER" ||
        sourceData.name === "CONTACT" ||
        destinationData.name === "CONTACT"
      ) {
        this.setState({
          showWarning: true,
          contentNotificationModal: t("basicWords.sectionsStructureMainPage"),
        });
        return;
      }
      typeMove.push({ type: type, index: indexInitialDataChange });
      var items = this.reorder(
        dataTarjet.sections,
        source.index,
        destination.index
      );
      items.forEach((item, index) => {
        items[index].positionInSubpageGroup = index;
      });
      initialData[indexInitialDataChange].sections = items;
      this.setState(newState);
      this.setState({
        typeMove,
        // indexInitialData,
        initialData,
        indexInitialDataChange,
        FlagClassName: true,
      });
      this.forceUpdate();
      return;
    }

    // moving from one list to another
    const homeSectionsId = Array.from(home.sectionsIds);
    homeSectionsId.splice(source.index, 1);
    const newHome = {
      ...home,
      sectionsIds: homeSectionsId,
    };

    const foreignSectionsIds = Array.from(foreign.sectionsIds);
    foreignSectionsIds.splice(destination.index, 0, parseInt(draggableId));
    const newForeign = {
      ...foreign,
      sectionsIds: foreignSectionsIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newHome.id]: newHome,
        [newForeign.id]: newForeign,
      },
    };

    const [sourceGroup] = initialData.filter(
      (item) =>
        item.id ===
        parseInt(source.droppableId.substring(7, source.droppableId.length))
    );

    const [destinationGroup] = destination
      ? initialData.filter(
        (item) =>
          item.id ===
          parseInt(
            destination.droppableId.substring(
              7,
              destination.droppableId.length
            )
          )
      )
      : { ...sourceGroup };

    if (
      (sourceGroup.style === "mainpage" || sourceGroup.style === "subpage") &&
      destinationGroup.style === "groupSubpage"
    ) {
      this.setState({
        showWarning: true,
      });
      return;
    }
    if (
      sourceGroup.style === "groupSubpage" &&
      (destinationGroup.style === "mainpage" ||
        destinationGroup.style === "subpage")
    ) {
      this.setState({
        showWarning: true,
        contentNotificationModal: t("basicWords.sectionsCanOnlyInMainPageOrSubpage")
      });
      return;
    }

    const movingSection = sourceGroup.sections[source.index];
    if (
      movingSection.mustBeSubpage === 1 &&
      (destinationGroup.style === "mainpage" ||
        destinationGroup.style === "groupSubpage")
    ) {
      this.setState({
        showWarning: true,
        contentNotificationModal: t("basicWords.subpagesCanOnlyInGroupSubpages"),
      });
      return;
    }

    if (
      movingSection.name === "HEADER" ||
      movingSection.name === "HOME" ||
      movingSection.name === "CONTACT" ||
      movingSection.name === "FOOTER"
    ) {
      this.setState({
        showWarning: true,
        contentNotificationModal: t("basicWords.sectionMustBeInSubpage"),
      });
      return;
    }

    movingSection.positionInMainPage = null;
    movingSection.positionsInSubpage = [];
    movingSection.positionsInSubpage[movingSection.positionsInSubpage.length] =
      null;

    if (!movingSection.subpages) {
      movingSection.subpages = [];
      movingSection.subpages[movingSection.subpages.length] =
        destinationGroup.id;
    } else {
      movingSection.subpages[movingSection.subpages.length] =
        destinationGroup.id;
    }
    sourceGroup.sections.forEach((element, index) => {
      sourceGroup.sections[index].positionsInSubpage = [index];
    });
    const newSourceGroup = sourceGroup.sections.splice(source.index, 1);
    sourceGroup.sections.forEach((item, index) => {
      sourceGroup.sections[index].positionInMainPage = index;
    });
    typeMove.push({ type: "anotherList", index: indexInitialDataChange });

    this.setState(newState);
    this.setState({
      typeMove,
      initialData,
      sourceDataMoved: sourceGroup,
      dataMoved: newSourceGroup[0],
      destinationDataMoved: destinationGroup,
      FlagClassName: true,
    });
    this.forceUpdate();
  };

  handleClickOpenDialog = (data) => {
    this.setState({ openDialog: true, columnSelected: data });
  };

  handleCloseDialog = (data) => {
    this.setState({ openDialog: false });
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  handleOpenViewHelpModal = () => {
    this.setState({ openViewHelpModal: true });
  };

  handleCloseViewHelpModal = () => {
    this.setState({ openViewHelpModal: false });
  };

  renderThemes(themesData) {
    const { lpThemeId, newThemeId } = this.state;
    let renderedComponents = [];
    if (themesData) {
      themesData.forEach((element, index) => {
        let name = element.name;
        let image = element.img;
        let description = element.description;
        if (image && index != 0) {
          // isLoadingInfo= false,
          renderedComponents.push(
            <div className="col-xs-12 col-md-12 col-lg-6 themeThumbnail" >
              <div className="cardSelectTheme tooltipTheme">
                <span className="tooltipThemeDescription">{description}</span>
                <img className="tooltipTheme"  src={image} style={{ height: "160px", width: "230px" }} onClick={()=>this.setShowOpenImageTheme(element)}/>
                <RadioGroup
                  name="theme"
                  value={newThemeId ? newThemeId : lpThemeId}
                  onChange={(e) => this.handleChangeTheme(e, element.id)}
                >
                  <FormControlLabel
                    value={element.id}
                    control={<Radio />}
                    label={name}
                  />
                </RadioGroup>
              </div>
            </div>
          );
        }
      });
    }
    return renderedComponents;
  }

  saveChangeName = () => {
    if (this.state.columnToEditName.style === "subpage") {
      this.updateLpSubpageByLpId(this.state.columnToEditName);
    }
    if (this.state.columnToEditName.style === "groupSubpage") {
      this.updateLpSubpageGroupByLpId(this.state.columnToEditName);
    }
  };

  handleClickOpenModalToChangeName = (itemToChangeName) => {
    let open = this.state.openModalToChangeName;
    this.setState({
      openModalToChangeName: !open,
      columnToEditName: itemToChangeName,
    });
  };

  OnChangeNameSubpageOrGroupsubpage = (e) => {
    let columnToEditName = this.state.columnToEditName;
    if (columnToEditName.id.length > 7) {
      columnToEditName.id = parseInt(
        columnToEditName.id.substring(7, columnToEditName.id.length)
      );
    }
    columnToEditName.name = e.target.value;

    this.setState({
      columnToEditName,
      FlagClassName: true,
    });
  };

  setShowError = () => {
    this.setState({ showError: false });
  }

  setShowSuccess = () => {
    this.setState({ showSuccess: false });
  }

  setShowWarning = () => {
    this.setState({ showWarning: false });
  }

  setShowCloseImageTheme = () => {
    this.setState({ showOpenImageTheme: false, themeSelected: null});
  }

  setShowOpenImageTheme = (theme) => {
    this.setState({ showOpenImageTheme: true, themeSelected: theme});
  }


  //renders
  render() {
    const {
      organizationName,
      FlagClassName,
      organizationId,
      sectionName,
      lpId,
      isOpenConfigStyle,
      themesData,
      registers,
      urlPage,
      showSuccess,
      showWarning,
      showError,
      contentNotificationModal,
      notAvailableWebpage,
      addNewModalVisible,
      valueRadioGroup,
      openModalToChangeName,
      showOpenImageTheme,
      themeSelected
    } = this.state;

    const { t } = this.props;

    return (
      <React.Fragment>

        {!!showWarning && (
          <SystemNotification
            setShowNotification={(e) => this.setShowWarning(e)}
            type="Warning"
            caption={contentNotificationModal}
          />
        )}
        {!!showSuccess && (
          <SystemNotification
            setShowNotification={(e) => this.setShowSuccess(e)}
            type="Success"
            caption={contentNotificationModal}
          />
        )}
        {!!showError && (
          <SystemNotification
            setShowNotification={(e) => this.setShowError(e)}
            type="Error"
            caption={contentNotificationModal}
          />
        )}

        <AddNewModal
          visible={addNewModalVisible}
          handleClose={this.onCloseAddNewModal}
          handleSave={this.onSaveEditAddNewModal}
          handleChange={this.OnChangeAddNewModal}
          handleChangeRadioGroup={this.OnChangeRadioGroup}
          handleChangeInput={this.OnChangeInput}
          valueRadioGroup={valueRadioGroup}
        />

        <GeneralModal
          visible={showOpenImageTheme}
          onClick={() => this.setShowCloseImageTheme()}
          title={themeSelected && themeSelected.name ? themeSelected.name : ''}
          body={[
            <div className="col-12" style={{ margin: "5px" }}>
              <div className="row">
                <div className="col-md-12 componentSpace">
                  <img src={themeSelected && themeSelected.img ? themeSelected.img : ''} 
                  alt={themeSelected && themeSelected.name ? themeSelected.name : 'Theme'} 
                  className="imageThemeModal"
                  />
                </div>
              </div>
            </div>,
          ]}
        />

        <GeneralModal
          visible={openModalToChangeName}
          title={"Cambiar nombre"}
          body={[
            <div className="col-12" style={{ margin: "5px" }}>
              <div className="row">
                <div className="col-md-12 componentSpace">
                  <TextField
                    variant="outlined"
                    style={{ width: "100%", marginLeft: 10 }}
                    label={t("basicWords.nameOfPage")}
                    onChange={(e) =>
                      this.OnChangeNameSubpageOrGroupsubpage(e)
                    }
                    defaultValue={
                      this.state.columnToEditName
                        ? decodeURIComponent(this.state.columnToEditName.name)
                        : ""
                    }
                  />
                </div>
              </div>
            </div>,
          ]}
          footer={[
            <div className="row buttonAtTheBottom">
              <div>
                <ButtonComponent
                  onClick={(e) => this.handleClickOpenModalToChangeName()}
                  shape="round"
                  text={t("basicWords.cancel")}
                  className="buttonDiscard"
                />
              </div>
              <div>
                <ButtonComponent
                  isDisabled={false}
                  onClick={() => this.saveChangeName()}
                  shape="round"
                  text={t("basicWords.ok")}
                />
              </div>
            </div>,
          ]}
          onClick={() => this.handleClickOpenModalToChangeName()}
        />

        <section className="Title">
          <BackArrow className="ico pointerCursor" onClick={() => window.history.back() } />
          <h3>{t("basicWords.myWebSite")}</h3>
        </section>
        <section className="sectionContainer">
          <div>
            {notAvailableWebpage ?
              <div className="containerTextManageWeb">
                <h1>{t("basicWords.myWebSiteNotAvailable")}</h1>
              </div>
              :
              <Card className="card">
                {/* <CardContent> */}
                <Fragment>
                  <div className="spaceLeft">
                    {registers.length === 0 ? (
                      <div
                        className="col-xs-12 col-md-12"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          transform: "translateY(10%)",
                          'min-height': '63vh'
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="col-xs-12 col-md-12">
                        <div className="containerHeader">
                          <div className="breadcrumb">
                            <Breadcrumb
                              principal={organizationName}
                              secondary={t("basicWords.websites")}
                              third={t("basicWords.myWebSite")}
                            />
                          </div>
                          <div className="buttonsHeader">
                            <div style={{ marginLeft: "10px" }}>
                              <ButtonComponent
                                icon={<Eye />}
                                shape="round"
                                text={t("basicWords.goToMyWebPage")}
                                onClick={() => this.getMyWebpage()}
                                className="viewMyWebSite"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-xs-12 col-md-12">
                          <div className="row componentSpace">
                            <div className="col-12">
                              <div
                                onClick={() =>
                                  this.setState({
                                    isOpenConfigStyle: !isOpenConfigStyle,
                                  })
                                }
                              >
                                {isOpenConfigStyle === true ? (
                                  <h4
                                    className="titleStyleConfig"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    {t("generalUser.styleConfig")}
                                    <ChevronUp
                                      size={30}
                                      style={{ transform: "translateY(30%)" }}
                                    />
                                  </h4>
                                ) : (
                                  <h4
                                    className="titleStyleConfig"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    {t("generalUser.styleConfig")}
                                    <ChevronDown
                                      size={30}
                                      style={{ transform: "translateY(30%)" }}
                                    />
                                  </h4>
                                )}
                              </div>
                            </div>
                          </div>
                          <Collapse isOpen={isOpenConfigStyle} transition="450ms">
                            <div className="row">
                              <div className="col-xs-12 col-md-12 containerThemes">
                                {this.renderThemes(themesData)}
                              </div>
                            </div>
                          </Collapse>
                        </div>
                        <Divider />
                        <div className="col-xs-12 col-md-12 text-center">
                          <div className="row componentSpace">
                            <div className="col-12">
                              <Grid container>
                                <Grid item style={{ marginRight: "1%" }}>
                                  <h4 className="titleStyleConfig">
                                    {t("generalUser.contentMyPage")}
                                  </h4>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    alignSelf: "center",
                                    width: "150px",
                                    textAlign: "left",
                                  }}
                                >
                                  <Tooltip title={<h3>{t("generalUser.tooltipMainpage")}</h3>}>
                                    <Button
                                      className="tagMainpage"
                                      style={{ fontSize: "14px !important" }}
                                    >
                                      {t("basicWords.mainpage")}{" "}
                                      <AlertCircle
                                        style={{ paddingLeft: "5%" }}
                                        size={18}
                                      />{" "}
                                    </Button>
                                  </Tooltip>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    alignSelf: "center",
                                    width: "150px",
                                    textAlign: "left",
                                  }}
                                >
                                  <Tooltip title={<h3>{t("generalUser.tooltipGroupsubpage")}</h3>}>
                                    <Button className="tagGroupsubpage">
                                      {t("basicWords.groupSubpages")}{" "}
                                      <AlertCircle
                                        style={{ paddingLeft: "5%" }}
                                        size={18}
                                      />{" "}
                                    </Button>
                                  </Tooltip>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    alignSelf: "center",
                                    width: "150px",
                                    textAlign: "left",
                                  }}
                                >
                                  <Tooltip title={<h3>{t("generalUser.tooltipSubpage")}</h3>}>
                                    <Button
                                      className="tagSubpage"
                                      style={{ fontSize: "14px !important" }}
                                    >
                                      {t("basicWords.subpage")}{" "}
                                      <AlertCircle
                                        style={{ paddingLeft: "5%" }}
                                        size={18}
                                      />{" "}
                                    </Button>
                                  </Tooltip>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    alignSelf: "center",
                                    width: "150px",
                                    textAlign: "left",
                                  }}
                                >
                                  <Tooltip title={<h3>{t("generalUser.tooltipGroupsubpage")}</h3>}>
                                    <Button
                                      className="tagSection"
                                      style={{ fontSize: "14px !important" }}
                                    >
                                      {t("basicWords.sections")}{" "}
                                      <AlertCircle
                                        style={{ paddingLeft: "5%" }}
                                        size={18}
                                      />{" "}
                                    </Button>
                                  </Tooltip>
                                </Grid>
                              </Grid>

                              <div className="col-xs-12 col-md-12">
                                <div>
                                  <div className="row componentSpace justify">
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                      <Droppable
                                        droppableId="all-columns"
                                        direction="horizontal"
                                        type="horizontal"
                                        key="horizontal"
                                      >
                                        {(provided) => (
                                          <div
                                            className="row"
                                            style={{ display: "flex" }}
                                          >
                                            <div
                                              className="col-xs-12 col-md-10"
                                              style={{
                                                display: "flex",
                                                width: "min-content",
                                              }}
                                              {...provided.droppableProps}
                                              ref={provided.innerRef}
                                            >
                                              {this.state.columnOrder.map(
                                                (columnId, index) => {
                                                  const column =
                                                    this.state.columns[columnId];
                                                  if (column !== undefined) {
                                                    const sectionMap =
                                                      this.state.structureSections;
                                                    const state = this.state;
                                                    const structureSections =
                                                      column.sectionsIds.map(
                                                        (sectionId) =>
                                                          sectionMap[sectionId]
                                                      );
                                                    return (
                                                      <div>
                                                        <HorizontalDragDrop
                                                          column={column}
                                                          structureSections={structureSections}
                                                          urlPage={urlPage}
                                                          index={index}
                                                          key={index}
                                                          state={state}
                                                          openDialog={this.state.openDialog}
                                                          columnSelected={this.state.columnSelected}
                                                          updateNewSectionComponentFunction={this.updateNewSectionComponentFunction}
                                                          disableSectionFunction={this.disableSectionFunction}
                                                          createLpSubpageByLpId={this.createLpSubpageByLpId}
                                                          getLandingPageInfoFunction={this.getLandingPageInfoFunction}
                                                          onGroupSubpageOrSubPageDelete={this.onGroupSubpageOrSubPageDelete}
                                                          OnchangeIsVisible={this.OnchangeIsVisible}
                                                          handleClickOpenDialog={this.handleClickOpenDialog}
                                                          handleCloseDialog={this.handleCloseDialog}
                                                          changePageOrGroupSubpage={this.changePageOrGroupSubpage}
                                                          handleClickOpenModalToChangeName={this.handleClickOpenModalToChangeName}
                                                        />
                                                      </div>
                                                    );
                                                  }
                                                }
                                              )}
                                              {provided.placeholder}
                                            </div>
                                            <div className="col-xs-12 col-md-2">
                                              <div
                                                className="conteinerTitleColumnAdd"
                                                onClick={(e) => this.addNewModal(e)}
                                              >
                                                <div className="iconTitle">
                                                  <Plus
                                                    style={{
                                                      paddingRight: "15px",
                                                      marginBottom: "90%"
                                                    }}
                                                    size={18}
                                                    // onClick={this.handleClickOpen}
                                                  />
                                                </div>
                                                <div className="titleColumn">
                                                  <p className="addGroupSubpageOrSubpage">
                                                    {t("basicWords.addGroupSubpageOrSubpage")}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <ButtonsFooterComponent
                            propsComponent={{
                              organizationId,
                              sectionName: sectionName,
                              lpId: lpId,
                              urlPage: urlPage,
                            }}
                            FlagclassName={FlagClassName}
                            path={"./"}
                            onClick={(e) => this.SavePositionSection(e)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </Fragment>
                {/* </CardContent> */}
              </Card>
              }
          </div>
          
        </section>
      </React.Fragment>
    );
  }
}

export default connect()(withTranslation()(MyWebPage));
