import React, { useState, useEffect, useContext } from "react";

//Localization
import { useTranslation } from "react-i18next";
//Context
import { UsersCommunityProvider, UsersCommunityContext } from "./contex";

import "./css/contentCommunity.css";

import RoleCard from "./components/roleCard.jsx";
import Pagination from "../global/components/create/pagination.jsx";
import MassiveActions from "../global/components/massiveActions";

const RoleCommunity = () => {
  const { RolData, RolPage, search, searcher, setTabActive, setSearch } = useContext(
    UsersCommunityContext
  );

  setTabActive("RoleCommunity");

  const { t, i18n } = useTranslation();

  const [pageShow, setPageShow] = useState(1);

  const numItemsShow = 8;

  const [counterCards, setCounterCards] = useState(0);

  function selectAll(event) {
    let selectAll = document.querySelectorAll("input.inputSelectAll")[0];
    let matches = document.querySelectorAll("input.checkboxCardUser");
    let cards = document.querySelectorAll(".cardUser");

    if (selectAll.checked) {
      matches.forEach((select) => (select.checked = true));
      cards.forEach((select) => select.classList.add("actionViewTrue")); // acciones masivas
      document.getElementById("massiveActions").classList.add("actionViewShow");
    }
    if (!selectAll.checked) {
      matches.forEach((select) => (select.checked = false));
      cards.forEach((select) => select.classList.remove("actionViewTrue")); // acciones masivas
      document
        .getElementById("massiveActions")
        .classList.remove("actionViewShow");
    }

    observerSelectAll(event);

    event.stopPropagation();
  }

  function observerSelectAll(event) {
    // verifica el # de audiecias selecioandas para activar el check de selecioanr todas

    // for Select All option  ---------------------------------------------------------
    let matches = document.querySelectorAll("input.checkboxCardUser");
    let selectAll = document.querySelectorAll("input.inputSelectAll")[0];

    let selectItem = 0; // contador # de audiencias selecioandas
    matches.forEach((select) =>
      !select.checked ? (selectAll.checked = false) : selectItem++
    );
    if (matches.length === selectItem) {
      selectAll.checked = true;
    }

    setCounterCards(selectItem);

    event.stopPropagation();
  }

  const RoleContext = useContext(UsersCommunityContext);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setSearch('');
    let { RolPage, RolData } = RoleContext;
    let itemShow = [];
    if (search.length > 0) {
      let itemStart = pageShow * numItemsShow - numItemsShow;
      let itemsShow = itemStart + numItemsShow;
      if (itemsShow > searcher.length) itemsShow = searcher.length;

      for (let i = itemStart; i < itemsShow; i++) {
        itemShow.push(searcher[i]);
      }
    } else {
      if (RolData && !RolData.length) {
        return "Cargando";
      } else {
        let itemStart = pageShow * numItemsShow - numItemsShow;
        let itemsShow = itemStart + numItemsShow;
        if (itemsShow > RolPage.total) itemsShow = RolPage.total;

        for (let i = itemStart; i < itemsShow; i++) {
          itemShow.push(RolData[i]);
        }
      }
    }
    setRoles(itemShow.filter((r) => typeof r !== "undefined"));
  }, [RoleContext, pageShow]);

  return (
    <React.Fragment>
      <div className="contentUsers">
        <div className="contentUsersSelectNoFilter">
          {/*<input type="checkbox" className="inputSelectAll" /> Seleccionar todo*/}
        </div>

        {/*<div className="actions" id="massiveActions">
          <MassiveActions />
          <div className="counterText">
            {counterCards} item
          </div>
        </div>*/}

        <div className="contentUsersCards" style={{ justifyContent: roles.length >= 4 ? 'space-arround' : 'flex-start' }} onChange={observerSelectAll}>
          {roles.map((item) => (
            <RoleCard
              type="role"
              key={item.uuid}
              uuid={item.uuid}
              name={item.name}
              description={item.description}
            />
          ))}
        </div>
      </div>

      <UsersCommunityContext.Consumer>
        {({ RolPage }) => {
          if (RolPage === undefined) {
            return "";
          } else {
            let pageEnd;
            if (search.length > 0) {
              pageEnd = Math.ceil(search.length / numItemsShow);
            } // paginación en busqueda
            else {
              pageEnd = Math.ceil(RolPage.total / numItemsShow);
            } // paginación en total

            return (
              <Pagination
                pageShow={pageShow}
                setPageShow={setPageShow}
                pageEnd={pageEnd}
              />
            );
          }
        }}
      </UsersCommunityContext.Consumer>
    </React.Fragment>
  );
};

export default RoleCommunity;
