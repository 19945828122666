import React, { useState } from "react";
import parse from 'html-react-parser';
import { get as _get } from "lodash";

import icon from '../iconLibrary.jsx';

//Localization
import { useTranslation } from 'react-i18next';

import MailPreview from './mailPostPreview.jsx';
import WhatsappPreview from './wpPostPreview.jsx';
import LinkedinPreview from './linkPostPreview.jsx';
import NotifyPreview from './notifyPostPreview';
import UhooPreview from './uhooPostPreview';

import "./css/PosPreview.css";

function PostPreview(props) {

  const { t, i18n } = useTranslation(); // Localization
  let URLactual = window.location;
  URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
  let isAura = URLactual.includes("aura")
  let title = parse(props.previewTitle);
  let text = parse(props.previewInfo);
  let image = props.previewImage;

  const [visualizer, setVisualizer] = useState(0);

  let preview = [
    {
      tag: isAura ? <icon.icoAura/> : <icon.icoUhoo/>,
      component: (
        <UhooPreview
          previewTitle={title}
          previewInfo={text}
          previewImage={image}
        />
      )
    },
    {
      tag: <icon.whatsapp/>,
      component: (
        <WhatsappPreview
          previewTitle={title}
          previewInfo={text}
          previewImage={image}
        />
      )
    },
    {
      tag: <icon.gmail/>,
      component: (
        <MailPreview
          previewTitle={title}
          previewInfo={text}
          previewImage={image}
        />
      )
    },
    {
      tag: <icon.linkedin/>,
      component: (
        <LinkedinPreview
          previewTitle={title}
          previewInfo={text}
          previewImage={image}
        />
      )
    },
    {
      tag: <icon.bell/>,
      component: (
        <NotifyPreview
          previewTitle={title}
          previewInfo={text}
          previewImage={image}
        />
      )
    }
  ]

  function next(event) {

    let item = visualizer;
    
    if (item !== preview.length-1) {
      item = item + 1;
    } else { item = 0; }

    setVisualizer(item)
    event.stopPropagation();
  }
  
  function previous(event) {
    
    let item = visualizer;
    
    if (item !== 0) {
      item = item - 1;
    } else { item = preview.length-1; }

    setVisualizer(item)
    event.stopPropagation();
  }

  return (
    <React.Fragment>
      <div className="contentPreview">
        <div className="controlPreview">

          <div role="button" className="pointerCursor" onClick={previous}>
            <icon.backArrow />
          </div>
          
          <div className="tagPreview pointerCursor" onClick={next}>{t('uhoo.createPost.preview')} &nbsp; {preview[visualizer].tag} &nbsp; </div>
          
          <div role="button" className="pointerCursor" onClick={next}>
            <icon.forwardArrow />
          </div>

        </div>
        <div className="Preview">

        {preview[visualizer].component}          

        </div>
      </div>
    </React.Fragment>
  );
};

export default PostPreview;


