import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { get as _get } from 'lodash'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// components
import Icon from '../../uhoo-components/iconLibrary.jsx';
import AudienceSelect from "../global/components/create/audienceSelect.jsx";
import SystemNotification from '../global/SystemNotification';
import { auraBasic, auraPro } from '../../reactUtils/generalUtils/auraPlans'
import Modal from '../../uhoo-components/Modal.jsx';
import CantCreate from '../global/modals/cantCreate.jsx'

// css
import "./css/createWidgetForm.css";

// query
import { getConfigs, putConfigsById, postMediaArbitrary } from "./../../actions/uhoo"

//Localization
import { useTranslation } from 'react-i18next';

import { useHistory } from "react-router-dom";

function WidgetForm( props ) {

  props.setActiveTab("Widget");

  const { t, i18n } = useTranslation(); // Localization
  let history = useHistory();
  /**
   * States
   */
  const [ nameWidget, setNameWidget ] = useState("");
  const [ urlWidget, setUrlWidget ] = useState("");
  const [ imageWidget, setImageWidget ] = useState(null);
  const [ typeWidget, setTypeWidget ] = useState("");
  const [ audiences, setAudiences ] = useState([]);

  const [ imageRender, setImageRender ] = useState(null);
  const [ imageLoading, setImageLoading ] = useState(false);

  // notifys
  const [showNotificatioError, setShowNotificatioError] = useState(false);
  const [showNotificationWarning, setShowNotificationWarning] = useState(false);
  const [showNotificationSuccessfull, setShowNotificationSuccessfull] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState('');
  const [showNotificationCheckAudiences, setShowNotificationCheckAudiences] = useState(false);
  const [channelPublish, setChannelPublish] = useState([]);
  const [maxLengthComplements, setMaxLengthComplements] = useState(null);
  const [lengthComplementsCreated, setLengthComplementsCreated] = useState(0);

  const [openModalCantCreate, setOpenModalCantCreate] = useState(false);
  const [textModalCantCreate, setTextModalCantCreate] = useState('');
  const [ isLoadingPost, setIsLoadingPost ] = useState(false);
  const [progressUploaded, setProgressUploaded] = useState(0);
  const [readyAudiences, setReadyAudiences] = useState(false);


    useEffect(async () => {
      const _configs = await getConfigs();
      let config = _configs.data;
      let _suscription = _get(config.find((element) => { return element.name === "suscription.plan"}), "value", 'basic');
      let currentConfigComplements = _get(_configs, 'data', []).find(config => config.name == 'app.community.webTab.apps');
      currentConfigComplements = _get(currentConfigComplements, 'value', {});
      let lengthComplements = 0;
      for (const element in currentConfigComplements) {
        lengthComplements++;
      }
      setLengthComplementsCreated(lengthComplements);
      if(_suscription == 'pro') {
        setMaxLengthComplements(_get(auraPro, 'maxLengthComplements', null));
      }else{
        setMaxLengthComplements(_get(auraBasic, 'maxLengthComplements', null));
      }
    }, [])
  
  props.setPreviewTitle(nameWidget); /// preview

  let URLactual = window.location;
  URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
  let isAura = URLactual.includes("aura");

  /**
   * Methods
   */
  const fileClick = (e) => {
    let file = e.target.files[0];

    props.setPreviewImage(URL.createObjectURL(file)); /// preview 

    var fr = new FileReader();
    fr.onload = () => {
      setImageRender(fr.result)
    }
    fr.readAsDataURL(file);
    setImageWidget(file)
  }
  const cleanNotify = () => {
    setShowNotificationMessage("")
    setShowNotificationWarning(false)
    setShowNotificatioError(false)
    setShowNotificationSuccessfull(false)
  }
  const toSave = async () => {
    setIsLoadingPost(true);
    cleanNotify();
    // check requeriments

    // process
    let configs
    try {
      configs = await getConfigs();
    } catch (error) {
      setShowNotificationMessage("Error to get configs")
      setShowNotificatioError(true);
      return;
    }

    if(channelPublish.length == 0){
      setShowNotificationCheckAudiences(true);
      setIsLoadingPost(false);
      return;
    }

    if(typeWidget == ''){
      setShowNotificationMessage(t('basicWords.modal.errorWIdget.type'))
      setShowNotificationWarning(true);
      setIsLoadingPost(false);
      return;
    }

    if(nameWidget == ''){
      setShowNotificationMessage(t('basicWords.modal.errorWIdget.name'))
      setShowNotificationWarning(true);
      setIsLoadingPost(false);
      return;
    }

    if(urlWidget == ''){
      setShowNotificationMessage(t('basicWords.modal.errorWIdget.url'))
      setShowNotificationWarning(true);
      setIsLoadingPost(false);
      return;
    }

    if(imageWidget == null){
      setShowNotificationMessage(t('basicWords.modal.errorWIdget.image'))
      setShowNotificationWarning(true);
      setIsLoadingPost(false);
      return;
    }

    let currentConfig = _get(configs, 'data', []).find(config => config.name == 'app.community.webTab.apps');
    let widges = _get(currentConfig, 'value', {});


    // upload media
    var fdata = new FormData();
    fdata.append("properties", "");
    fdata.append("image", imageWidget);
    fdata.append("thumbnail", imageWidget);
    let mediaArbitrary 
    try {
      mediaArbitrary = await postMediaArbitrary(fdata)
    } catch (error) {
      setShowNotificationMessage("Error to upload image")
      setShowNotificatioError(true);
      return;
    }

    // set data widget
    widges[Object.keys(_get(currentConfig, 'value', {})).length] = {
      "id": Object.keys(_get(currentConfig, 'value', {})).length,
      "order": Object.keys(_get(currentConfig, 'value', {})).length,
      "name": nameWidget ,
      "kind":typeWidget,
      "url": urlWidget,
      "icon": _get(mediaArbitrary, 'data[0].url', ''),
      "audiences": channelPublish,
      "enabled": true,
      "key_current": uuidv4()
    }

    // payload
    // https://api.brilliant360.net/api/config/9a1b56cd-338c-4b4c-b7ec-45f4ce3f7deb
    let configToSave = {
      "uuid": _get(currentConfig, 'uuid', ''),
      "name": "app.community.webTab.apps",
      "value": JSON.stringify(widges),
      "community": _get(currentConfig, 'community', ''),
      "properties": {},
      "editable": true
    }

    let result;
    const onUploadProgress = (event) => {
      const percentage = Math.round((100 * event.loaded) / event.total);
      setProgressUploaded(percentage)
    };
    try {
      result = await putConfigsById(_get(currentConfig, 'uuid', ''), configToSave, onUploadProgress);
    } catch (error) {
      setShowNotificationMessage("Error in creation widget")
      setShowNotificatioError(true);
      return;
    }
    setShowNotificationMessage("Widget creado correctamente")
    setShowNotificationSuccessfull(true);
    setTimeout(()=>{setShowNotificationSuccessfull(false);}, 2000);
    setTimeout(()=>{history.goBack();}, 1000);
    setIsLoadingPost(false);
  }

  const configOpeModalCanCreate = () => {
    setTextModalCantCreate(t('basicWords.buttons.buttons'));
    setOpenModalCantCreate(true);
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress className='buttonLoading' />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  let _user = JSON.parse(sessionStorage.getItem("own"));

  return(    
    <React.Fragment>
      <div className="contentsWidgetform">
        {!readyAudiences?
          <div className="col-xs-12 col-md-12 circularProgressCenter" >
            <CircularProgress />
          </div>
        :
          null
        }
        <div className="audience">          
          <div className="WidgetformTitles">{t('uhoo.createPost.form.audienceTitle')}</div>
            <AudienceSelect user={_user} setAudiencePublish={setAudiences} fullAudience={true} setChannelPublish={setChannelPublish} setReadyAudiences={setReadyAudiences}/>
          </div>

        <div className="title">          
          <div className="WidgetformTitles">{t('uhoo.widget.type')}</div>
          <select className="inputForms" onChange={e => setTypeWidget(e.target.value)}>
            <option value="" selected disabled hidden> {t('basicWords.selectOption')} </option> 
            <option value="web_embedded">{t('uhoo.widget.typeEmbedded')}</option>
            <option value="web_external">{t('uhoo.widget.typeExternal')}</option>
          </select>
        </div>

        <div className="title">          
          <div className="WidgetformTitles">{/*t('uhoo...')*/}{t('basicWords.name')}:</div>
          <input type="text" placeholder={t('uhoo.widget.name')} onChange={e => setNameWidget(e.target.value)} className="inputForms"/> {/*t('uhoo...')*/}
        </div>

        <div className="title">          
          <div className="WidgetformTitles">{/*t('uhoo...')*/}{t('basicWords.url')}:</div>
          <input type="text" placeholder={t('uhoo.widget.url')} onChange={e => setUrlWidget(e.target.value)} className="inputForms"/> {/*t('uhoo...')*/}
        </div>

        <div className="title">          
          <div className="WidgetformTitles">{/*t('uhoo...')*/}{t('uhoo.widget.image')}:</div>
          <div className="attachments">
            {
              imageRender ?
              <>
                <div
                  className="contentAttachments"
                  onClick={() => document.getElementById("attachmentsUploadWidget").click()}
                  style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                >
                  <img src={imageRender} width="100%" height="100%" style={{ objectFit: 'contain' }} />
                </div>
              </>
              :
              <>

                <div
                  className="contentAttachments"
                  onClick={() => document.getElementById("attachmentsUploadWidget").click()}
                  style={{ width: '100%', padding: '0px', marginLeft: '0px', marginRight: '0px' }}
                >
                  <Icon.upload />{t('uhoo.createPost.form.uploadAttachments')}
                </div>
              </>
            }
            <input
              hidden
              id="attachmentsUploadWidget"
              type="file"
              accept="image/*, .png, .jpeg, .jpg"
              onChange={fileClick}
            />
          </div>
        </div>
        { isLoadingPost ?
            <div className="actionsWidget">
              <div className="toWidgetDisabled" ><CircularProgressWithLabel value={progressUploaded}/></div>
            </div>
            :
            <div className="actionsWidget">
              <div className="toWidget" onClick={() => maxLengthComplements != null && maxLengthComplements  <= lengthComplementsCreated && isAura ? configOpeModalCanCreate() : toSave()}> {t('uhoo.widget.create')} </div>
            </div>
        }

      </div>

      {/*  Notifications  */}
      {!!showNotificationSuccessfull && (
        <SystemNotification
          setShowNotification={setShowNotificationSuccessfull}
          type="Success"
          caption={showNotificationMessage}
        />
      )}

      {!!showNotificationWarning && (
        <SystemNotification
          setShowNotification={setShowNotificationWarning}
          type="Warning"
          caption={showNotificationMessage}
        />
      )}

      {!!showNotificatioError && (
        <SystemNotification
          setShowNotification={setShowNotificatioError}
          type="Error"
          caption={showNotificationMessage}
        />
      )}
      {!!showNotificationCheckAudiences && (
        <SystemNotification
          setShowNotification={setShowNotificationCheckAudiences}
          type="Warning"
          caption={t('basicWords.mustSelectAudiences')} 
        />
      )}
      {openModalCantCreate ? (
        <Modal>
          <CantCreate
            setOpenModal={setOpenModalCantCreate}
            text={textModalCantCreate}
          />
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default WidgetForm;