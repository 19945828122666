import React, { useState, useEffect } from "react";
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import "../../community/components/css/configureUser.css";
import { get as _get } from "lodash";
import { useTranslation } from 'react-i18next';

import SystemNotification from "../SystemNotification.jsx";
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import { updateUsers } from "../../../actions/uhoo";

import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';

// Queries
import { getAudience } from "../../../actions/uhoo/Audience.actions";

function AudiencesModal(props) {
    const { t, i18n } = useTranslation(); //  Localization

    const [showWarning, setShowWarning] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [channelPublish, setChannelPublish] = useState([]);

    const [AudienceData, setAudienceData] = useState([]); // array de consulta, contiene las audiencias

    let open = props.setOpenModal;
    let usersSelected = props.usersSelected;
    let create = props.create;

    function closeModal() {
        open(false);
    }

    /// ---------- Queries ---------- ///

    const GetAudienceData = (query) => {
        useEffect(async () => {
            const info = await getAudience(query);

            let filtroActive = info.data.filter(audience => audience.active === 1)
            let filtroMedium = filtroActive.filter(audience => audience.properties.mediums !== undefined)

            setAudienceData(filtroMedium);

        }, []);
        return AudienceData;
    };

    GetAudienceData([{ key: 'page_size', value: '500' }])

    /// ---------- End Queries ---------- ///

    function actionEnabled(audience) {

        function stringAvatar(name) {
            return {
                children: `${name.split('')[0][0].toUpperCase()}`,
            };
        }

        try {
            return (
                <React.Fragment key={audience.uuid}>
                    <div className="audienceOption">
                        <input type="checkbox" id={audience.uuid} value={audience.uuid} name="audience" />
                        <label htmlFor={audience.uuid} className="audienceOptionLabel">
                            <Avatar
                                {...stringAvatar(audience.name)}
                                sx={{ width: 24, height: 24, bgcolor: '#FFCB96', }}
                            />
                            <div>{audience.name}</div>
                        </label><br />
                    </div>
                </React.Fragment>
            );
        } catch (error) {
            console.error(error);
            setShowError(true);
        }
    }

    ////
    function selectAudience() {

        let channelOptions = document.querySelectorAll('div.audienceOption > input');
        let channels = [];

        channelOptions.forEach(select => (select.checked === true)
            ? channels.push(select.value)
            : null
        );

        if ( channels.length === 0 ) {
            setShowWarning(true);
        } else {
            let responses = [];
            let haveError = false;
            channels.forEach(async channel => {
                const entities = {};
                usersSelected.forEach(lst => {
                    entities[lst] = {};
                });
                let data;
                if (create) {
                    data = {
                        kind: {
                            "891f6801-19eb-4c3b-98c9-64400c2837f3": { entities }
                        }
                    };
                } else {
                    data = {
                        kind_remove: {
                            "891f6801-19eb-4c3b-98c9-64400c2837f3": { entities }
                        }
                    };
                }
                let response = await updateUsers(channel, data); //add users
                responses.push(response);
            });
            responses.forEach(rsp => {
                if (_get(rsp, "message", "").includes("failed")) {
                    haveError = true;
                }
            });

            if (haveError) {
                setShowError(true);
                return;
            } else {
                setShowSuccess(true);
            }
        }      
    }

    ///


    return (
        <React.Fragment>
            <div className="modalAddColumn">
                <div className="titleModalAddColumn">
                    <div className="textTitleModalAddColumn">{t('uhoo.activity.action.title')}</div>
                    <div className="closeModalAddColumn" onClick={closeModal} role="button"><Close /></div>
                </div>

                <hr className="lines" />

                {AudienceData.length > 0 && AudienceData[0] !== undefined
                    ? (
                        <div className="contentModalAddColumn">
                            <div className="infoContentModalAddColumn">
                                <div className="status labelForm selectAudienceRadio">
                                    {AudienceData.map(item => actionEnabled(item))}
                                </div>
                            </div>
                        </div>
                    )
                    : (
                        <div className="contentModalAddColumn">
                            <CircularProgress />
                        </div>
                    )
                }

                <div className="actionContentModalAddColumn">
                    <div onClick={selectAudience} >
                        <SecondaryButton txt={t('basicWords.modal.update')} />
                    </div>
                    <div onClick={closeModal}>
                        <SecondaryButton txt={t('uhoo.globalWords.cancel')} outline />
                    </div>
                </div>
            </div>

            {!!showWarning && (
                <SystemNotification
                    setShowNotification={setShowWarning}
                    type="Warning"
                    caption={t('basicWords.modal.AudienceCommunityWarning')}
                />
            )}
            {!!showSuccess && (
                <SystemNotification
                    setShowNotification={setShowSuccess}
                    setShowModal={open}
                    type="Success"
                    caption={t('basicWords.modal.updateSuccess')}
                />
            )}
            {!!showError && (
                <SystemNotification
                    setShowNotification={setShowError}
                    type="Error"
                    caption={t('basicWords.internalServerError')}
                />
            )}
        </React.Fragment>
    );
}

export default AudiencesModal;
