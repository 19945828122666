import { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import "../css/sections.scss";

import SelectLayoutModal from "../../../../aura-components/SelectLayoutModal.jsx";
import ButtonsContainer from "../../../../aura-components/ButtonsContainer.jsx";

// Queries
import {
  getNewsSectionInformationByTopLimit,
  updateLpSectionComponentNode,
  insertSectionComponent,
  disableLpSectionComponent,
  updateSectionComponent,
  getSectionLayoutStyle,
  getFile,
  updateLpSectionComponentNodeImage,
  updateLpSectionLayout,
  updateLpSection,
} from "../../../../actions/aura";

import { Trash2, Plus, Edit2, X } from "react-feather";

// Components
import {
  Title,
  ButtonComponent,
  BreadcrumbSection,
  GeneralInput,
  DeleteItemModal,
  GeneralModal,
  AccordionComponent,
  DatePickerComponent,
  ButtonsFooterComponent
} from "../../../../aura-components";
import editorConfiguration from "../../../../aura-components/textEditorConfig";
import SystemNotification from "../../../global/SystemNotification";

import { Card, CircularProgress, TextField, Tooltip } from "@material-ui/core";

import { handleChangeImageFunction } from "../../../../reactUtils/generalUtils/handleImageChange.jsx";

//Localization
import { withTranslation } from "react-i18next";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;

class NewsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,

      landingPageId: null,
      organizationId: null,
      organizationName: null,
      country: null,
      region: null,
      city: null,
      lpSectionId: null,
      sectionName: null,

      totalElements: 0,

      loadingSection: true,
      loadingInfo: true,

      date: moment().format(),

      newsContainerTitle: {
        id: null,
        value: null,
        nodeTypeName: null,
        edited: false,
      },
      newsQuantity: {
        id: null,
        value: 0,
        nodeTypeName: null,
        edited: false,
      },
      newsToAdd: {
        id: null,
        title: "",
        text: "",
        date: "",
        dateToShow: "",
        author: "",
        buttons: [],
        multimedia: null,
      },
      newsToShow: {
        id: null,
        title: null,
        text: null,
        date: moment(),
        dateToShow: moment(),
        author: null,
        image: null,
        imageUri: null,
        buttons: [],
      },

      newMultimedia: {
        id: null,
        s3path: null,
        file: null,
        image: null,
        loading: false,
        value: null,
        imageUrl: null,
        videoUrl: null,
        edited: false,
      },
      parentLPSectionComponentId: null,
      isLoadingButton: false,
      FlagClassName: false,
      goToWithoutSaveModalVisible: false,

      lpLayoutImg: null,
      layoutModalVisible: false,
      deleteFlag: false,

      // Modals
      showSuccess: false,
      showWarning: false,
      showError: false,
      contentNotificationModal: "",

      //Modal general
      openDeleteImage: false,
      openDeleteNews: false,
      elementToDeleteId: null,
      haveChangeInLayout: false,

      //LayoutSelector
      newLayoutId: null,
      oldLayoutId: null,

      isReadyToEdit: false,
      addNew: false,

      //Name modal
      openModalToChangeName: false,
      customName: "",

      //Get image or video
      elementToEditMultimedia: {
        id: null,
        s3path: null,
        file: null,
        image: null,
        loading: false,
        value: null,
        imageUrl: null,
        videoUrl: null,
        edited: false,
      },

      //Select image or video modals edit
      openImageOrVideoEditModal: false,
      openImageFileOrLinkEditModal: false,
      openImageFileEditModal: false,
      openImageLinkEditModal: false,
      openSelectVideoEditModal: false,

      //Select image or video modals edit
      openImageOrVideoAddModal: false,
      openImageFileOrLinkAddModal: false,
      openImageFileAddModal: false,
      openImageLinkAddModal: false,
      openSelectVideoAddModal: false,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    const { props } = this.props.location;
    if (props) {
      this.getNewsInformation(props.sectionId, null);
      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
      this.handleSelectLayout();
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  getElementsToNews = (components, nodeType, paramsToFind) => {
    let object = {};
    if (components.nodes.find((node) => node.nodeType === nodeType) !== undefined) {
      paramsToFind.forEach(element => {
        if (element === "length") {
          object[element] = components.nodes.find((node) => node.nodeType === nodeType).value.length;
        } else if (element === "nodeTypeName") {
          object[element] = components.nodes.find((node) => node.nodeType === nodeType).nodeType;
        } else {
          object[element] = components.nodes.find((node) => node.nodeType === nodeType)[element];
        }
      });
    } else {
      paramsToFind.forEach(element => {
        if (element === "nodeTypeName") {
          object[element] = nodeType;
        } else {
          object[element] = "";
        }
      });
    }
    return object;
  };

  // Queries
  async getNewsInformation(lpSectionId, topLimit) {
    const newsNumber = topLimit != null ? topLimit : this.state.newsQuantity.value;
    const response = await getNewsSectionInformationByTopLimit(
      lpSectionId,
      newsNumber
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      const sectionInfo = response.data.data;
      const newsContainerComponent = sectionInfo.components.find((comp) => comp.name === "NewsContainerComponent");

      const objectKeys = ["id", "value", "nodeTypeName", "minLength", "maxLength", "length"];
      const newsContainerTitle = this.getElementsToNews(newsContainerComponent, "NewsContainerTitle", objectKeys);
      const newsQuantity = this.getElementsToNews(newsContainerComponent, "NewsQuantity", objectKeys);

      const parentLPSectionComponentId = newsContainerComponent.id;

      const news = sectionInfo.components.filter(
        (comp) => comp.name === "NewsComponent"
      );
      const newsList = [];

      for (let i = 0; i < news.length; i++) {
        if (!news[i].multimedia) {
          const multimediaNode = news[i].nodes.find(
            (node) => node.nodeType === "NewsImage"
          );
          if (multimediaNode) {
            if (multimediaNode.value != null && multimediaNode.value !== "") {
              // Get image or video
              var responseMultimedia = null;
              if (!multimediaNode.value.includes("https://")) {
                // checks if it's an uploaded image
                responseMultimedia = await getFile(
                  "https://somosaura-cms.s3.amazonaws.com/" +
                  multimediaNode.value
                );
                multimediaNode.image = responseMultimedia;
                multimediaNode.loading = false;
              } else if (
                !multimediaNode.value.includes("https://www.youtube.com/watch?")
              ) {
                // checks if it's an image link
                responseMultimedia = await getFile(multimediaNode.value);
                multimediaNode.imageUrl = responseMultimedia;
                multimediaNode.loading = false;
              } else if (
                multimediaNode.value.includes("https://www.youtube.com/watch?")
              ) {
                // checks if it's video link
                multimediaNode.videoUrl = multimediaNode.value;
              } else {
                multimediaNode.image = null;
                multimediaNode.loading = false;
              }
              news[i].multimedia = multimediaNode;
            }
          }
        }
        newsList.push(news[i]);
      }

      const newsToShow = [];

      newsList.forEach((element) => {
        const newsElement = {
          id: element.id,
          titleId: element.nodes.find((node) => node.nodeType === "NewsTitle")
            ? element.nodes.find((node) => node.nodeType === "NewsTitle").id
            : null,
          title: element.nodes.find((node) => node.nodeType === "NewsTitle")
            ? element.nodes.find((node) => node.nodeType === "NewsTitle").value
            : null,
          textId: element.nodes.find((node) => node.nodeType === "NewsText")
            ? element.nodes.find((node) => node.nodeType === "NewsText").id
            : null,
          text: element.nodes.find((node) => node.nodeType === "NewsText")
            ? element.nodes.find((node) => node.nodeType === "NewsText").value
            : null,
          dateId: element.nodes.find((node) => node.nodeType === "NewsDate")
            ? element.nodes.find((node) => node.nodeType === "NewsDate").id
            : null,
          date: element.nodes.find((node) => node.nodeType === "NewsDate")
            ? element.nodes.find((node) => node.nodeType === "NewsDate").value
            : null,
          authorId: element.nodes.find((node) => node.nodeType === "NewsAuthor")
            ? element.nodes.find((node) => node.nodeType === "NewsAuthor").id
            : null,
          author: element.nodes.find((node) => node.nodeType === "NewsAuthor")
            ? element.nodes.find((node) => node.nodeType === "NewsAuthor").value
            : null,
          // imageId: element.nodes.find((node) => node.nodeType === "NewsImage")
          //   ? element.nodes.find((node) => node.nodeType === "NewsImage").id
          //   : null,
          multimedia: element.multimedia,
          buttons: element.buttons,
          edited: false,
        };
        newsToShow.push(newsElement);
      });

      newsToShow.forEach((element, index) => {
        newsToShow[index].date = moment(element.date).subtract(5, "hours");
        newsToShow[index].date = moment(newsToShow[index].date._d);
      });

      //Get layout image
      let supgage = null;
      if (this.state.subpageId && this.state.subpageId !== 10001) {
        supgage = sectionInfo.subpages.find(
          (sp) => sp.id === this.state.subpageId
        );
      } else {
        supgage = sectionInfo.subpages.find((sp) => sp.id === null);
      }
      let subpageIndex = sectionInfo.subpages.indexOf(supgage);
      if (subpageIndex === -1) {
        subpageIndex = 0;
      }
      let layout =
        response.data.data.subpages[subpageIndex].lpSectionLayoutStyle
          .sectionLayoutId;
      let layoutImg = await getFile(
        "https://somosaura-cms.s3.amazonaws.com/" + layout.s3ImagePath
      );

      let customName =
        response.data.data.customName !== null
          ? response.data.data.customName
          : this.state.sectionName;

      this.setState({
        customName,
        newsContainerTitle,
        newsQuantity,
        newsToShow,
        loadingInfo: false,
        loadingSection: false,
        parentLPSectionComponentId,
        lpSectionLayoutStyle: layout,
        lpLayoutImg: layoutImg,
      });
    }
  }

  async updateLpSection(LpSubpageDto) {
    let response = await updateLpSection(LpSubpageDto);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),

      });
    }
    this.setState({
      newCustomName: "",
      customName: LpSubpageDto.customName,
      openModalToChangeName: false,
    });
  }

  async uploadFileToS3Bucket(imageUrl, key) {
    await updateLpSectionComponentNodeImage(imageUrl, key);
  }

  async insertSectionComponentFunction(componentToUpdate) {
    const { lpSectionId } = this.state;
    const response = await insertSectionComponent(componentToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.fieldEmpty"),

      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.newsSuccessfullyCreated"),
      });
      this.cleanStateVariables();
      this.getNewsInformation(lpSectionId, null);
    }

    this.setState({ isLoadingButton: false });
  }

  async updateSectionComponentFunction(componentToUpdate) {
    const { lpSectionId } = this.state;
    const response = await updateSectionComponent(componentToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),

      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.newsSuccessfullyUpdate"),
      });
      this.cleanStateVariables();

      this.getNewsInformation(lpSectionId, null);
    }

    this.setState({ isLoadingButton: false });
  }

  async updateNode(nodesToUpdate) {
    const lpSectionId = this.state.lpSectionId;
    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),

      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.newsSuccessfullyEdited"),
      });
      this.getNewsInformation(lpSectionId, null);
    }
    this.setState({ isLoadingButton: false });
  }

  async disableComponentFunction(id) {
    const { lpSectionId } = this.state;

    const response = await disableLpSectionComponent(id);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerError"),
        isLoadingButton: false,
        loadingInfo: false,
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.newsSuccessfullyDisabled"),
        isLoadingButton: false,
        loadingInfo: false,
      });
      this.getNewsInformation(lpSectionId, null);
    }
  }

  async getLayoutFunction(lpId) {
    let response = await getSectionLayoutStyle(lpId, 5);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),
      });
    } else {
      let responseData = response.data.data;
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].sampleLayoutImageS3Path) {
          //Get image
          let img = await getFile(
            "https://somosaura-cms.s3.amazonaws.com/" +
            responseData[i].sampleLayoutImageS3Path
          );
          responseData[i].img = img;
        }
      }
      this.setState({
        layoutData: responseData,
      });
    }
  }

  async updateLpSectionLayoutFunction(lpSectionId, newLayoutId) {
    let response = await updateLpSectionLayout(lpSectionId, newLayoutId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t("basicWords.modal.internalServerErrorContactSupport"),

      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t("basicWords.modal.positionSectionSuccessfullyModified"),
      });
    }
    this.setState({ isLoadingButton: false });
  }

  //   EVENTS

  warning = () => {
    const { t } = this.props;
    this.setState({
      showWarning: true,
      contentNotificationModal: t("basicWords.modal.noSectionSelected"),
    });
    this.handleOkWarning();
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  handleChangeDescription(html, element) {
    if (element != null) {
      this.handleChange(html, "text", "", true, element.id);
    } else {
      this.handleChange(html, "text", "", true, null);
    }
  }

  handleChange = (event, dataIndex, data, flagRichText, newsId) => {
    let id = null;
    let value = null;

    if (event && event.target) {
      id = event.target.id;
      value = event.target.value;
    }
    if (flagRichText) {
      id = dataIndex;
      value = event;
    }

    const {
      newsContainerTitle,
      newsToShow,
      newsToAdd,
      newsQuantity,
      imageIndex,
      newMultimedia,
    } = {
      ...this.state,
    };
    var { elementToEditMultimedia } = { ...this.state };

    if (newsId === null && id !== "videoUrl") {
      newsToAdd[id] = value; // new news
    }

    if (id === "NewsContainerTitle") {
      if (value.length <= newsContainerTitle.maxLength) {
        newsContainerTitle.value = value;
        newsContainerTitle.edited = true;
        newsContainerTitle.length = value.length;
      }
    }
    if (id === "NewsQuantity") {
      if (value.length <= newsQuantity.maxLength) {
        newsQuantity.value = value;
        newsQuantity.edited = true;
        newsQuantity.length = value.length;
      }
    }
    newsToShow.forEach((element, index) => {
      //news edit
      if (element.id === newsId) {
        if (id === "title") {
          newsToShow[index].title = value;
        }
        if (id === "author") {
          newsToShow[index].author = value;
        }
        if (id === "text") {
          newsToShow[index].text = value;
        }
        newsToShow[index].edited = true;
      }
    });
    if (id === "videoUrl") {
      if (imageIndex !== null) {
        newsToShow[imageIndex].multimedia.videoUrl = value;
        newsToShow[imageIndex].edited = true;
        newsToShow[imageIndex].multimedia.edited = true;
        elementToEditMultimedia.videoUrl = value;
      } else {
        newsToAdd.multimedia = value;
        newMultimedia.videoUrl = value;
        newMultimedia.edited = true;
      }
    }

    this.setState({
      newMultimedia,
      newsToAdd,
      newsToShow,
      newsContainerTitle,
      newsQuantity,
      FlagClassName: true,
      elementToEditMultimedia
    });
  };

  changeDateHandleEvent = (e, newsId) => {
    const { newsToAdd, newsToShow } = { ...this.state };
    const date = e.target.value;

    let dateToSave = date;
    newsToAdd.date = dateToSave;
    newsToAdd.dateToShow = dateToSave;
    newsToShow.forEach((element, index) => {
      if (element.id === newsId) {
        newsToShow[index].dateToShow = date;
        newsToShow[index].date = date;
        newsToShow[index].edited = true;
      }
    });
    this.setState({
      newsToAdd,
      newsToShow,
      FlagClassName: true,
    });
  };

  handleChangeImage = async (e, isAdd) => {
    const {
      country,
      city,
      organizationId,
      newsToShow,
      imageIndex,
      newsToAdd,
      elementToEditMultimedia,
    } = this.state;
    var newMultimedia = { ...this.state.newMultimedia };

    if (!e.target.value.includes("fakepath") && !e.target.files) {
      //ImageLink

      if (isAdd !== "add") {
        elementToEditMultimedia.imageUrl = e.target.value;
        elementToEditMultimedia.image = null;
        elementToEditMultimedia.value = e.target.value;
        elementToEditMultimedia.edited = true;
      } else {
        newMultimedia.imageUrl = e.target.value;
        newMultimedia.image = null;
        newMultimedia.value = e.target.value;
        newsToAdd.multimedia = e.target.value;
      }

      this.setState({
        newMultimedia,
        newsToAdd,
        newsToShow,
        elementToEditMultimedia,
        FlagClassName: true,
      });
    }

    if (e.target.files) {
      var fileUpload = e.target.files[0];
      let extension;
      if (fileUpload !== undefined) {
        extension = fileUpload.type.replace("image/", "");
      }
      const random = uuidv4();
      const key = `content/${country}/${city}/${organizationId}/img${random}.${extension}`;
      if (!extension) {
        newMultimedia.loading = true;
        newMultimedia.file = null;
        newMultimedia.image = null;

        this.setState({
          newMultimedia,
          FlagClassName: true,
        });
        return;
      }

      if (key !== undefined) {
        // Function to resize and reduce image quality
        // Save image in AWS
        let flag = true;
        newMultimedia = await handleChangeImageFunction(
          newMultimedia,
          fileUpload,
          extension,
          key,
          400,
          400
        ).catch((res) => {
          const { t } = this.props;
          this.setState({
            showError: true,
            contentNotificationModal: `${t(`${res.content}`).replace("R1", res.params.R1).replace("R2", res.params.R2)}`,
          });
          flag = false;
        });
        if (flag !== false) {
          if (newMultimedia.loading === false) {
            if (imageIndex === null) {
              //new news
              newMultimedia.loading = false;
              newMultimedia.edited = true;
              newMultimedia.value = key;
              newsToAdd.multimedia = key;
            } else if (imageIndex !== null) {
              // edited new
              newsToShow[imageIndex].multimedia.image = key;
              newsToShow[imageIndex].multimedia.value = key;
              newsToShow[imageIndex].multimedia.edited = true;
              elementToEditMultimedia.image = newMultimedia.image;
              elementToEditMultimedia.imageUrl = null;
            }

            this.uploadFileToS3Bucket(newMultimedia.file, key);
            this.setState({
              newMultimedia,
              newsToAdd,
              newsToShow,
              FlagClassName: true,
              elementToEditMultimedia
            });
            this.forceUpdate();
          }
        }
      }
    }
  };

  disableImage(elementToChange) {
    const { newsToShow, newsToAdd, imageIndex } = {
      ...this.state,
    };
    if (elementToChange !== null) {
      newsToShow[imageIndex].image = null;
      newsToShow[imageIndex].edited = true;
    } else {
      newsToAdd.image = null;
    }

    this.setState({
      isLoadingButton: true,
      FlagClassName: true,
      deleteFlag: true,
      newsToShow,
      newsToAdd,
    });
    this.handleCloseDeleteImage();
  }

  handleButtonListChange = (buttonList, idNew) => {
    let { newsToShow, newsToAdd } = this.state;
    newsToShow.forEach((element, index) => {
      if (element.id === idNew) {
        newsToShow[index].buttons = buttonList;
        newsToShow[index].edited = true;
      }
    });
    newsToAdd.buttons = buttonList;
    this.setState({ newsToShow, newsToAdd, FlagClassName: true });
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  handleClickOpenModalToChangeName = (itemToChangeName) => {
    let open = this.state.openModalToChangeName;
    this.setState({
      openModalToChangeName: !open,
      customName: itemToChangeName,
    });
  };

  OnChangeSectionCustomName = (e) => {
    let newCustomName = this.state.newCustomName;
    newCustomName = e.target.value;

    this.setState({
      newCustomName,
      FlagClassName: true,
    });
  };

  saveChangeName = () => {
    const { t } = this.props;
    var { newsContainerTitle, newCustomName, lpSectionId } = this.state;

    //Update lpsection custom name
    let dto = {
      id: lpSectionId,
      customName: newCustomName,
      isEnabled: 1,
    };
    this.updateLpSection(dto);

    //Update section title name
    const nodesToUpdate = [];
    let errorInput = false;

    if (newsContainerTitle) {
      if (newCustomName === "") {
        errorInput = true;
      } else {
        newsContainerTitle.value = newCustomName;
      }
      delete newsContainerTitle.edited;
      nodesToUpdate.push(newsContainerTitle);
    }

    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.fieldEmpty"),
          isLoadingButton: false,
        });
      }
    }
  };

  onSaveNews = (e) => {
    const {
      parentLPSectionComponentId,
      landingPageId,
      newsToShow,
      newsToAdd,
      deleteFlag,
    } = this.state;
    this.setState({
      isLoadingButton: true,
      FlagClassName: false,
    });
    const { t } = this.props;

    var newsToValidate = newsToShow.filter((n) => n.edited === true);
    if (newsToAdd.title !== "" && newsToAdd.title !== null) {
      newsToValidate = newsToValidate.concat(newsToAdd);
    }

    newsToValidate.forEach((newNews) => {
      const newsNodesToAdd = [];

      let newsButtons = newNews.buttons
        ? newNews.buttons.map((b) => {
          const button = {
            id: b.id,
            name: b.name,
            externalLink: b.externalLink,
            internalLpSectionComponentId: b.component
              ? b.component.id
              : b.internalLpSectionComponentId
                ? b.internalLpSectionComponentId
                : null,
            isEnabled: b.isEnabled,
          };
          return button;
        })
        : [];
      const newsToAdd = {
        // --- Con esto se crea el registro lpSectionComponent ---
        lpId: null, // Este valor se obtiene de la vista anterior
        sectionId: 5, // Valor estático para la sección de eventos
        lpSectionId: this.state.lpSectionId,
        sectionComponentId: 7, // Valor estático de 5, valor para el EventComponent

        parentLPSectionComponentId, // Valor estático de 4 puesto que el id 4 es el padre de este nuevo componente

        // --- Con el Id del lpSectionComponent creado, se crean los nodos de este componente
        nodesToAdd: [],
        buttons: newsButtons,
      };
      let errorInput = false;
      if (newNews.title != null) {
        const newsNode = {};

        newsNode.id = newNews.titleId ? newNews.titleId : null;
        newsNode.sectionComponentNodeId = 23;
        if (newNews.title === "") {
          errorInput = true;
        }
        newsNode.value = newNews.title;
        newsNode.nodeType = "NewsTitle";

        newsNodesToAdd.push(newsNode);
      }
      if (newNews.text != null) {
        const newsNode = {};

        newsNode.id = newNews.textId ? newNews.textId : null;
        newsNode.sectionComponentNodeId = 25;
        // if (newNews.text === "") {
        //   errorInput = true;
        // }
        newsNode.value = newNews.text;
        newsNode.nodeType = "NewsText";

        newsNodesToAdd.push(newsNode);
      }
      if (newNews.date != null) {
        const newsNode = {};
        newsNode.id = newNews.dateId ? newNews.dateId : null;
        newsNode.sectionComponentNodeId = 24;
        newsNode.value = moment(newNews.date, "YYYY-MM-DD HH:mm:ss");
        if (newsNode.value._isValid === false) {
          newsNode.value = "";
        }
        newsNode.nodeType = "NewsDate";
        newsNodesToAdd.push(newsNode);
      }
      if (newNews.author != null) {
        const newsNode = {};

        newsNode.id = newNews.authorId ? newNews.authorId : null;
        newsNode.sectionComponentNodeId = 27;
        // if (newNews.author === "") {
        //   errorInput = true;
        // }
        newsNode.value = newNews.author;
        newsNode.nodeType = "NewsAuthor";

        newsNodesToAdd.push(newsNode);
      }
      // if (newNews.image !== null) {
      //   const newsNode = {};
      //   newsNode.id = newNews.imageId ? newNews.imageId : null;
      //   newsNode.sectionComponentNodeId = 26;
      //   let haveHttp = false;
      //   var regex = /somosaura-cms.s3.amazonaws.com/;
      //   haveHttp = regex.test(newNews.image);
      //   if (haveHttp) {
      //     newsNode.value = newNews.image.slice(39, newNews.image.length); //Cut to only save image, not link
      //   } else {
      //     newsNode.value = newNews.image;
      //   }
      //   newsNode.nodeType = "NewsImage";

      //   newsNodesToAdd.push(newsNode);
      // }

      if (newNews !== null && newNews.multimedia && (newNews.multimedia.edited || newNews.multimedia.edited === true)) {
        const newsNode = {};
        let multimedia = newNews.multimedia;
        if (multimedia.image) {
          newsNode.value = multimedia.value;
        } else if (multimedia.imageUrl) {
          newsNode.value = multimedia.imageUrl;
        } else if (multimedia.videoUrl) {
          newsNode.value = multimedia.videoUrl;
        }
        newsNode.id = newNews.multimedia.id ? newNews.multimedia.id : null;
        newsNode.sectionComponentNodeId = 26;
        newsNode.nodeType = "NewsImage";

        newsNodesToAdd.push(newsNode);
      } else if (newNews.multimedia && !newNews.multimedia.edited && !newNews.multimedia.id) {
        //new
        const newsNode = {};
        newsNode.value = newNews.multimedia;
        newsNode.id = null;
        newsNode.sectionComponentNodeId = 26;
        newsNode.nodeType = "NewsImage";

        newsNodesToAdd.push(newsNode);
      } else if (!newNews.multimedia) {
        // News without image
        const newsNode = {};
        newsNode.value = "";
        newsNode.id = null;
        newsNode.sectionComponentNodeId = 26;
        newsNode.nodeType = "NewsImage";

        newsNodesToAdd.push(newsNode);
      }

      if (deleteFlag || (newNews.multimedia && newNews.multimedia == null)) {
        const newsNode = {};

        newsNode.id = newNews.imageId ? newNews.imageId : null;
        newsNode.sectionComponentNodeId = 26;
        newsNode.value = "";
        newsNode.nodeType = "NewsImage";
        newsNodesToAdd.push(newsNode);
      }
      newsToAdd.id = newNews.id;
      newsToAdd.lpId = landingPageId;
      newsToAdd.nodesToAdd = newsNodesToAdd;

      if (newsNodesToAdd.length > 0) {
        if (!errorInput) {
          let newsTitle = false;
          let newsText = false;
          let newsDate = false;
          let newsAuthor = false;

          newsNodesToAdd.forEach((obj) => {
            if (obj.nodeType === "NewsTitle") {
              newsTitle = true;
            }
            if (obj.nodeType === "NewsText") {
              newsText = true;
            }
            if (obj.nodeType === "NewsDate") {
              newsDate = true;
            }
            if (obj.nodeType === "NewsAuthor") {
              newsAuthor = true;
            }
          });
          if (newsTitle && newsText && newsDate && newsAuthor) {
            if (newNews.id) {
              this.updateSectionComponentFunction(newsToAdd);
            } else {
              this.insertSectionComponentFunction(newsToAdd);
            }
          } else {
            this.setState({
              showError: true,
              contentNotificationModal: t("basicWords.modal.fieldEmpty"),
              isLoadingButton: false,
            });
          }
        } else {
          this.setState({
            showError: true,
            contentNotificationModal: t("basicWords.modal.fieldEmpty"),
            isLoadingButton: false,
          });
        }
      }
    });
  };

  handleSelectLayout() {
    this.getLayoutFunction(this.props.location.props.landingPageId);

    this.setState({ layoutModalVisible: true });
  }

  setLayoutModalVisible = (isVisible) => {
    this.setState({ layoutModalVisible: isVisible });
  };

  //Functions
  cleanStateVariables = () => {
    let newsToAdd = {
      id: null,
      title: "",
      text: "",
      date: moment(),
      dateToShow: moment(),
      multimedia: null,
      author: "",
      buttons: [],
      topLimit: null,
    };

    let newMultimedia = {
      id: null,
      s3path: null,
      imageUrl: null,
      image: null,
      loading: false,
      buttons: [],
      file: null,
      value: null,
      videoUrl: null,
      edited: false,
    };

    let imageIndex = null;

    this.setState({
      imageIndex,
      newsToAdd,
      newMultimedia,
    });
  };

  saveNewsEvent = () => {
    this.setState({
      isLoadingButton: true,
      FlagClassName: false,
    });
    const nodesToUpdate = [];
    var {
      newsContainerTitle,
      haveChangeInLayout,
      newsToAdd,
      newsToShow,
      newsQuantity,
      lpSectionId,
      lpSectionLayoutStyle,
    } = {
      ...this.state,
    };
    const { t } = this.props;
    let errorInput = false;

    // Verify each one of the values of the component to add
    if (newsContainerTitle) {
      if (newsContainerTitle.value === "") {
        errorInput = true;
      } else {
        //Update lpsection custom name
        let dto = {
          id: lpSectionId,
          customName: newsContainerTitle.value,
          isEnabled: 1,
        };
        this.updateLpSection(dto);
        delete newsContainerTitle.edited;
        nodesToUpdate.push(newsContainerTitle);
      }
    }
    if (newsQuantity.edited) {
      if (newsQuantity.value === "") {
        errorInput = true;
      }
      delete newsQuantity.edited;
      nodesToUpdate.push(newsQuantity);
    }
    if (haveChangeInLayout) {
      this.updateLpSectionLayoutFunction(lpSectionId, lpSectionLayoutStyle);
    }
    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.fieldEmpty"),
          isLoadingButton: false,
        });
      }
    }
    if (newsToAdd.title !== "") {
      newsToShow.push(newsToAdd);
    }

    this.setState({ newsToShow });
    this.onSaveNews();
    newsToAdd = {
      id: null,
      title: "",
      text: "",
      date: moment(),
      dateToShow: moment(),
      author: "",
      multimedia: null,
      buttons: [],
    };
    this.setState({ newsToAdd, addNew: false });
  };

  handleChangeLayout = (e, id) => {
    this.setState({
      // newLayoutId: id,
      saveButtonDisabled: false,
      FlagClassName: true,
      lpSectionLayoutStyle: id,
      haveChangeInLayout: true,
    });
  };

  handleClickOpenDeleteImage = (index) => {
    if (index) {
      this.handleImageIndex(index);
      this.setState({ openDeleteImage: true });
    } else {
      this.setState({ openDeleteImage: true });
    }
  };

  handleCloseDeleteImage = () => {
    this.setState({ openDeleteImage: false });
  };

  handleClickOpenDeleteNews = (id) => {
    this.setState({ openDeleteNews: true, elementToDeleteId: id });
  };

  handleCloseDeleteNews = () => {
    this.setState({ openDeleteNews: false });
  };

  changeToEdit = (e) => {
    e.stopPropagation();
    const { isReadyToEdit } = this.state;
    this.setState({ isReadyToEdit: !isReadyToEdit });
  };

  disableNewsEvent = (id) => {
    this.setState({
      loadingInfo: true,
      openDeleteNews: false,
    });
    this.disableComponentFunction(id);
  };

  handleImageIndex = (multimediaToEdit) => {
    const { newsToShow } = { ...this.state };
    let elemToEdit = null;
    let imageIndex = null;

    if (multimediaToEdit && multimediaToEdit.id !== null) {
      elemToEdit = newsToShow.find(
        (e) => e.multimedia.id === multimediaToEdit.id
      );
      if (newsToShow.indexOf(elemToEdit) !== -1) {
        imageIndex = newsToShow.indexOf(elemToEdit);
      }
    }
    this.setState({
      imageIndex,
    });
    this.handleOpenImageOrVideoModal(multimediaToEdit);
  };

  renderNews(newsToShow) {
    let renderedComponents = [];
    const { t } = this.props;

    const {
      // openDeleteImage,
      landingPageId,
      isReadyToEdit,
      openImageOrVideoEditModal,
      openImageFileOrLinkEditModal,
      openDialog,
      openImageFileEditModal,
      elementToEditMultimedia,
      openImageLinkEditModal,
      openSelectVideoEditModal,
    } = this.state;

    if (newsToShow && newsToShow.length) {
      newsToShow.forEach((element, index) => {
        const multimedia = element.multimedia;
        renderedComponents.push(
          <div key={element.id} >
            <AccordionComponent
              disabled={isReadyToEdit}
              iconEdit={<Edit2 />}
              // onEditNews={(e) => this.changeToEdit(e)}
              onDeleteNews={(e) => this.handleClickOpenDeleteNews(element.id)}
              iconDelete={<Trash2 />}
              title={element.title}
              body={[
                <div className="accordionContent">
                  {/* First Line */}
                  <div className="accordionCenterLine">
                    <div
                      style={{
                        width: "50%",
                        marginLeft: "1%",
                        marginRight: "0.7%",
                      }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={t("basicWords.enterTitle")}
                        id="title"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.title}
                      />
                      {element.title.value && element.title.maxLength ? (
                        <span>
                          {element.title.length} / {element.title.maxLength}
                        </span>
                      ) : null}
                    </div>
                    <div
                      style={{
                        width: "50%",
                        marginRight: "1%",
                        marginLeft: "0.7%",
                      }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        // className="textInputWidth"
                        placeholder={t("sections.author")}
                        id="author"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.author}
                      />
                    </div>
                  </div>
                  <br />

                  {/* SecondLine */}
                  <div className="accordionCenterLine">
                    <div
                      style={{
                        width: "50%",
                        marginLeft: "1%",
                        marginRight: "0.7%",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <DatePickerComponent
                          disabled={isReadyToEdit}
                          string={t("basicWords.dateEvent")}
                          defaultValue={element.date}
                          onChange={(e) =>
                            this.changeDateHandleEvent(e, element.id)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <br />

                  {/* Third Line */}
                  <div className="accordionCenterLine">
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "0.5%",
                        marginRight: "0.5%",
                      }}
                    >
                      <ReactQuill
                        readOnly={isReadyToEdit}
                        // className="textInputWidth"
                        onChange={(e) =>
                          this.handleChangeDescription(e, element)
                        }
                        value={element.text}
                        modules={editorConfiguration.modules}
                        formats={editorConfiguration.formats}
                        placeholder={t("basicWords.description")}
                      />
                    </div>
                  </div>

                  {/* <div className="row">
                        <div className="iconXCardImagesSquare">
                          <a
                            onClick={() =>
                              this.handleClickOpenDeleteImage(index)
                            }
                          >
                            <X
                              className={
                                isReadyToEdit
                                  ? "iconImageEditInSectionSquareDisabled"
                                  : "iconImageEditInSectionSquare"
                              }
                            />
                          </a>
                        </div>
                      </div> */}

                  {/* New feature image or video */}

                  <div
                    className="rowSections componentSpace"
                    style={{
                      margin: "2%",
                      display: "flex",
                    }}
                  >
                    {multimedia &&
                      multimedia.imageUrl &&
                      !multimedia.image &&
                      !multimedia.videoUrl ? (
                      <img
                        style={{ objectFit: "contain" }}
                        src={multimedia.imageUrl}
                        alt="imagen"
                        width="100px"
                        height="100px"
                        alignitems="center"
                      ></img>
                    ) : null}

                    {multimedia &&
                      multimedia.image &&
                      !multimedia.imageUrl &&
                      !multimedia.videoUrl ? (
                      <img
                        style={{ objectFit: "contain" }}
                        src={multimedia.image}
                        alt="imagen"
                        width="100px"
                        height="100px"
                        alignitems="center"
                      ></img>
                    ) : null}

                    {multimedia &&
                      multimedia.videoUrl &&
                      !multimedia.image &&
                      !multimedia.imageUrl ? (
                      <img
                        style={{ objectFit: "contain" }}
                        src={
                          "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png"
                        }
                        alt="imagen"
                        width="100px"
                        height="100px"
                        alignitems="center"
                      ></img>
                    ) : null}
                    <div
                      style={{
                        margin: "2%",
                        display: "flex",
                        alignitems: "center",
                      }}
                    >
                      <ButtonComponent
                        onClick={() => this.handleImageIndex(multimedia)}
                        props={null}
                        shape="round"
                        text={t("basicWords.imageOrVideo")}
                      />
                    </div>
                  </div>

                  <GeneralModal //Select image or video modal
                    visible={openImageOrVideoEditModal}
                    title={t("generalMessages.selectFile")}
                    body={[
                      <div key={1}>
                        <div className="col-6">
                          <ButtonComponent
                            onClick={() =>
                              this.handleSelectImageOrVideoModal("image")
                            }
                            props={null}
                            shape="round"
                            text={t("basicWords.image")}
                          />
                        </div>
                        <br />
                        <div>
                          <ButtonComponent
                            onClick={() =>
                              this.handleSelectImageOrVideoModal("video")
                            }
                            props={null}
                            shape="round"
                            text={t("sections.video")}
                          />
                        </div>
                      </div>,
                    ]}
                    footer={[
                      <div className="row" key={1} style={{ display: "flex" }}>
                        <div className="col-6">
                          <ButtonComponent
                            onClick={() => this.handleCloseDialog()}
                            props={null}
                            shape="round"
                            text={t("basicWords.cancelText")}
                          />
                        </div>
                      </div>,
                    ]}
                    onClick={() => this.handleCloseDialog()}
                  />

                  <GeneralModal //Select image file or image link modal
                    visible={openImageFileOrLinkEditModal}
                    title={t("generalMessages.selectFile")}
                    body={[
                      <div key={1}>
                        <div className="col-6">
                          <ButtonComponent
                            onClick={() => this.handleImageFileOrLink("file")}
                            props={null}
                            shape="round"
                            text={t("generalMessages.uploadFile")}
                          />
                        </div>
                        <br />
                        <div>
                          <ButtonComponent
                            onClick={() => this.handleImageFileOrLink("link")}
                            props={null}
                            shape="round"
                            text={t("basicWords.buttons.externalLink")}
                          />
                        </div>
                      </div>,
                    ]}
                    footer={[
                      <div className="row" key={1} style={{ display: "flex" }}>
                        <div className="col-6">
                          <ButtonComponent
                            onClick={() => this.handleCloseDialog()}
                            props={null}
                            shape="round"
                            text={t("basicWords.cancelText")}
                          />
                        </div>
                      </div>,
                    ]}
                    onClick={() => this.handleCloseDialog()}
                  />

                  <GeneralModal // Select file image modal
                    visible={openImageFileEditModal}
                    title={t("generalMessages.uploadFile")}
                    body={[
                      <div key={1}>
                        <div className="col-12">
                          {/* Tooltip image */}
                          <Tooltip title={t("generalMessages.imagesFormat")}>
                            <div className="cardImagesSquare">
                              <div className="row">
                                <div className="iconXCardImagesSquare">
                                  <a>
                                    <X
                                      className="iconImageEditInSectionSquare"
                                      onClick={this.handleClickOpenDialog}
                                    />
                                  </a>
                                </div>
                              </div>

                              <div className="row">
                                <div className="centerImage">
                                  <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={(e) =>
                                      this.handleChangeImage(e, index)
                                    }
                                    hidden
                                  />
                                  <label htmlFor="contained-button-file">
                                    <div className="imageEdit">
                                      <div>
                                        {elementToEditMultimedia && elementToEditMultimedia.image !==
                                          null ? (
                                          <img
                                            src={elementToEditMultimedia.image}
                                            alt=""
                                            className="widthAndHeightImageSectionSquare"
                                          />
                                        ) : (
                                          <Plus className="imageEdit" />
                                        )}

                                        <GeneralModal
                                          visible={openDialog}
                                          title={t("basicWords.deleteItem")}
                                          footer={[
                                            <div
                                              className="row"
                                              key={1}
                                              style={{
                                                display: "flex",
                                              }}
                                            >
                                              <div className="col-6">
                                                <ButtonComponent
                                                  onClick={() =>
                                                    this.handleCloseDialog()
                                                  }
                                                  props={null}
                                                  shape="round"
                                                  text={t(
                                                    "basicWords.cancelText"
                                                  )}
                                                />
                                              </div>
                                              <div>
                                                <ButtonComponent
                                                  onClick={() =>
                                                    this.disableImage(
                                                      multimedia
                                                    )
                                                  }
                                                  props={null}
                                                  shape="round"
                                                  text={t("basicWords.okText")}
                                                />
                                              </div>
                                            </div>,
                                          ]}
                                          onClick={() =>
                                            this.handleCloseDialog()
                                          }
                                        />
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      </div>,
                    ]}
                    footer={[
                      <div className="row buttonAtTheBottom">
                        <div className="col-6">
                          <ButtonComponent
                            onClick={() => this.handleCloseDialog()}
                            shape="round"
                            text={t("basicWords.cancel")}
                            className="buttonDiscard"
                          />
                        </div>
                        <div className="col-6">
                          <ButtonComponent
                            isDisabled={false}
                            onClick={(e) =>
                              this.handleOkChangeImageType(e, "imageFile")
                            }
                            shape="round"
                            text={t("basicWords.ok")}
                          />
                        </div>
                      </div>,
                    ]}
                    onClick={() => this.handleCloseDialog()}
                  />

                  <GeneralModal // Select link image modal
                    visible={openImageLinkEditModal}
                    title={t("basicWords.buttons.externalLink")}
                    body={[
                      <div key={1}>
                        <div className="col-6">
                          <GeneralInput
                            text={t("basicWords.buttons.externalLink")}
                            id={"ImageLink"}
                            value={
                              elementToEditMultimedia && elementToEditMultimedia.imageUrl
                                ? elementToEditMultimedia.imageUrl
                                : ""
                            }
                            placeholder={t("basicWords.modal.addImageLink")}
                            isReq
                            onChange={(e, id) => this.handleChangeImage(e, id)}
                            iconYoutube={false}
                          />
                        </div>
                        <div
                          className="col-6"
                          style={{
                            alignitems: "center",
                            display: "flex",
                            justifyContent: "center",
                            margin: "5px",
                          }}
                        >
                          <p>{t("basicWords.modal.addImageLinkTooltip")}</p>
                          {elementToEditMultimedia && elementToEditMultimedia.imageUrl ? (
                            <img
                              style={{ objectFit: "contain" }}
                              src={elementToEditMultimedia.imageUrl}
                              alt="imagen"
                              width="100px"
                              height="100px"
                            ></img>
                          ) : null}
                        </div>
                      </div>,
                    ]}
                    footer={[
                      <div className="row buttonAtTheBottom">
                        <div>
                          <ButtonComponent
                            onClick={() => this.handleCloseDialog()}
                            shape="round"
                            text={t("basicWords.cancel")}
                            className="buttonDiscard"
                          />
                        </div>
                        <div className="col-6">
                          <ButtonComponent
                            isDisabled={false}
                            onClick={(e) =>
                              this.handleOkChangeImageType(e, "imageLink")
                            }
                            shape="round"
                            text={t("basicWords.ok")}
                          />
                        </div>
                      </div>,
                    ]}
                    onClick={() => this.handleCloseDialog()}
                  />

                  <GeneralModal // Select link video modal
                    visible={openSelectVideoEditModal}
                    title={t("sections.video")}
                    body={[
                      <div key={1}>
                        <div className="col-6">
                          <GeneralInput
                            text={t("basicWords.enterUrlVideo")}
                            id={"videoUrl"}
                            value={
                              elementToEditMultimedia && elementToEditMultimedia.videoUrl
                                ? elementToEditMultimedia.videoUrl
                                : ""
                            }
                            placeholder={t("basicWords.enterUrlVideo")}
                            isReq
                            onChange={(e, id) =>
                              this.handleChange(e, "videoUrl")
                            }
                            iconYoutube={true}
                          />
                        </div>
                      </div>,
                    ]}
                    footer={[
                      <div className="row buttonAtTheBottom">
                        <div>
                          <ButtonComponent
                            onClick={() => this.handleCloseDialog()}
                            shape="round"
                            text={t("basicWords.cancel")}
                            className="buttonDiscard"
                          />
                        </div>
                        <div className="col-6">
                          <ButtonComponent
                            isDisabled={false}
                            onClick={(e) =>
                              this.handleOkChangeImageType(e, "video")
                            }
                            shape="round"
                            text={t("basicWords.ok")}
                          />
                        </div>
                      </div>,
                    ]}
                    onClick={() => this.handleCloseDialog()}
                  />
                  <div className="rowSection componentSpace">
                    <div className="textLogo">{t("basicWords.buttons.buttons")}</div>
                  </div>
                  <div
                    className="rowSection componentSpace"
                    style={{
                      marginLeft: "2%",
                    }}
                  >
                    <ButtonsContainer
                      disabled={isReadyToEdit}
                      isModalVisible={true}
                      lpId={landingPageId}
                      buttonList={element.buttons}
                      handleButtonListChange={(buttonList) =>
                        this.handleButtonListChange(buttonList, element.id)
                      }
                    />
                  </div>
                </div>,
              ]}
            />
            <br />
          </div>
        );
      });
    }

    return renderedComponents;
  }

  renderAddNews() {
    const {
      newsToAdd,
      openDeleteImage,
      landingPageId,
      openImageOrVideoAddModal,
      openImageFileOrLinkAddModal,
      openDialog,
      openImageFileAddModal,
      openImageLinkAddModal,
      openSelectVideoAddModal,
      newMultimedia,
    } = this.state;
    const { t } = this.props;
    return (
      <div>
        <AccordionComponent
          title={t("basicWords.newNew")}
          className="accordionAdd"
          body={[
            <div className="accordionContent">
              {/* First Line */}
              <div className="accordionCenterLine">
                <div
                  style={{
                    width: "50%",
                    marginLeft: "1%",
                    marginRight: "0.7%",
                  }}
                >
                  <GeneralInput
                    className="textInputWidth"
                    placeholder={t("basicWords.enterTitle")}
                    id="title"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={newsToAdd.title}
                  />
                  {newsToAdd.value && newsToAdd.maxLength ? (
                    <span>
                      {newsToAdd.length} / {newsToAdd.maxLength}
                    </span>
                  ) : null}
                </div>
                <div
                  style={{
                    width: "50%",
                    marginRight: "1%",
                    marginLeft: "0.7%",
                  }}
                >
                  <GeneralInput
                    // className="textInputWidth"
                    placeholder={t("sections.author")}
                    id="author"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={newsToAdd.author}
                  />
                </div>
              </div>
              <br />

              {/* Second Line */}

              <div className="accordionCenterLine">
                <div
                  style={{
                    width: "50%",
                    marginLeft: "1%",
                    marginRight: "0.7%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <DatePickerComponent
                      string={t("basicWords.dateEvent")}
                      defaultValue={newsToAdd.date}
                      onChange={(e) => this.changeDateHandleEvent(e, null)}
                    />
                  </div>
                </div>
              </div>
              <br />

              {/* Third Line */}

              <div className="accordionCenterLine">
                <div
                  style={{
                    width: "100%",
                    marginLeft: "0.5%",
                    marginRight: "0.5%",
                  }}
                >
                  <ReactQuill
                    // className="textInputWidth"
                    onChange={(e) => this.handleChangeDescription(e, null)}
                    value={newsToAdd.text}
                    modules={editorConfiguration.modules}
                    formats={editorConfiguration.formats}
                    placeholder={t("basicWords.description")}
                  />
                </div>
              </div>

              <div
                className="rowSections componentSpace"
                style={{
                  margin: "2%",
                  display: "flex",
                }}
              >
                {newMultimedia.imageUrl &&
                  !newMultimedia.image &&
                  !newMultimedia.videoUrl ? (
                  <img
                    style={{ objectFit: "contain" }}
                    src={newMultimedia.imageUrl}
                    alt="imagen"
                    width="100px"
                    height="100px"
                    alignitems="center"
                  ></img>
                ) : null}

                {newMultimedia.image &&
                  !newMultimedia.imageUrl &&
                  !newMultimedia.videoUrl ? (
                  <img
                    style={{ objectFit: "contain" }}
                    src={newMultimedia.image}
                    alt="imagen"
                    width="100px"
                    height="100px"
                    alignitems="center"
                  ></img>
                ) : null}

                {newMultimedia.videoUrl &&
                  !newMultimedia.image &&
                  !newMultimedia.imageUrl ? (
                  <img
                    style={{ objectFit: "contain" }}
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png"
                    }
                    alt="imagen"
                    width="100px"
                    height="100px"
                    alignitems="center"
                  ></img>
                ) : null}
                <div
                  style={{
                    margin: "2%",
                    display: "flex",
                    alignitems: "center",
                  }}
                >
                  <ButtonComponent
                    onClick={() => this.handleImageIndex(newsToAdd)}
                    props={null}
                    shape="round"
                    text={t("basicWords.imageOrVideo")}
                  />
                </div>
              </div>

              <GeneralModal //Select image or video modal
                visible={openImageOrVideoAddModal}
                title={t("generalMessages.selectFile")}
                body={[
                  <div key={1}>
                    <div className="col-6">
                      <ButtonComponent
                        onClick={() =>
                          this.handleSelectImageOrVideoModal("image")
                        }
                        props={null}
                        shape="round"
                        text={t("basicWords.image")}
                      />
                    </div>
                    <br />
                    <div>
                      <ButtonComponent
                        onClick={() =>
                          this.handleSelectImageOrVideoModal("video")
                        }
                        props={null}
                        shape="round"
                        text={t("sections.video")}
                      />
                    </div>
                  </div>,
                ]}
                footer={[
                  <div className="row" key={1} style={{ display: "flex" }}>
                    <div className="col-6">
                      <ButtonComponent
                        onClick={() => this.handleCloseDialog()}
                        props={null}
                        shape="round"
                        text={t("basicWords.cancelText")}
                      />
                    </div>
                  </div>,
                ]}
                onClick={() => this.handleCloseDialog()}
              />

              <GeneralModal //Select image file or image link modal
                visible={openImageFileOrLinkAddModal}
                title={t("generalMessages.selectFile")}
                body={[
                  <div key={1}>
                    <div className="col-6">
                      <ButtonComponent
                        onClick={() => this.handleImageFileOrLink("file")}
                        props={null}
                        shape="round"
                        text={t("generalMessages.uploadFile")}
                      />
                    </div>
                    <br />
                    <div>
                      <ButtonComponent
                        onClick={() => this.handleImageFileOrLink("link")}
                        props={null}
                        shape="round"
                        text={t("basicWords.buttons.externalLink")}
                      />
                    </div>
                  </div>,
                ]}
                footer={[
                  <div className="row" key={1} style={{ display: "flex" }}>
                    <div className="col-6">
                      <ButtonComponent
                        onClick={() => this.handleCloseDialog()}
                        props={null}
                        shape="round"
                        text={t("basicWords.cancelText")}
                      />
                    </div>
                  </div>,
                ]}
                onClick={() => this.handleCloseDialog()}
              />

              <GeneralModal // Select file image modal
                visible={openImageFileAddModal}
                title={t("generalMessages.uploadFile")}
                body={[
                  <div key={1}>
                    <div className="col-12">
                      {/* Tooltip image */}
                      <Tooltip title={t("generalMessages.imagesFormat")}>
                        <div className="cardImagesSquare">
                          <div className="row">
                            <div className="iconXCardImagesSquare">
                              <a>
                                <X
                                  className="iconImageEditInSectionSquare"
                                  onClick={this.handleClickOpenDialog}
                                />
                              </a>
                            </div>
                          </div>

                          <div className="row">
                            <div className="centerImage">
                              <input
                                accept="image/*"
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={(e) =>
                                  this.handleChangeImage(e, "add")
                                }
                                hidden
                              />
                              <label htmlFor="contained-button-file">
                                <div className="imageEdit">
                                  <div>
                                    {newMultimedia.image ? (
                                      <img
                                        src={newMultimedia.image}
                                        alt=""
                                        className="widthAndHeightImageSectionSquare"
                                      />
                                    ) : (
                                      <Plus className="imageEdit" />
                                    )}

                                    <GeneralModal
                                      visible={openDialog}
                                      title={t("basicWords.deleteItem")}
                                      footer={[
                                        <div
                                          className="row"
                                          key={1}
                                          style={{
                                            display: "flex",
                                          }}
                                        >
                                          <div className="col-6">
                                            <ButtonComponent
                                              onClick={() =>
                                                this.handleCloseDialog()
                                              }
                                              props={null}
                                              shape="round"
                                              text={t("basicWords.cancelText")}
                                            />
                                          </div>
                                          <div>
                                            <ButtonComponent
                                              onClick={() =>
                                                this.disableImage(newsToAdd)
                                              }
                                              props={null}
                                              shape="round"
                                              text={t("basicWords.okText")}
                                            />
                                          </div>
                                        </div>,
                                      ]}
                                      onClick={() => this.handleCloseDialog()}
                                    />
                                  </div>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  </div>,
                ]}
                footer={[
                  <div className="row buttonAtTheBottom">
                    <div className="col-6">
                      <ButtonComponent
                        onClick={() => this.handleCloseDialog()}
                        shape="round"
                        text={t("basicWords.cancel")}
                        className="buttonDiscard"
                      />
                    </div>
                    <div className="col-6">
                      <ButtonComponent
                        isDisabled={false}
                        onClick={(e) =>
                          this.handleOkChangeImageType(e, "imageFile")
                        }
                        shape="round"
                        text={t("basicWords.ok")}
                      />
                    </div>
                  </div>,
                ]}
                onClick={() => this.handleCloseDialog()}
              />

              <GeneralModal // Select link image modal
                visible={openImageLinkAddModal}
                title={t("basicWords.buttons.externalLink")}
                body={[
                  <div key={1}>
                    <div className="col-6">
                      <GeneralInput
                        text={t("basicWords.buttons.externalLink")}
                        id={"ImageLink"}
                        value={
                          newMultimedia.imageUrl ? newMultimedia.imageUrl : ""
                        }
                        placeholder={t("basicWords.modal.addImageLink")}
                        isReq
                        onChange={(e) => this.handleChangeImage(e, "add")}
                        iconYoutube={false}
                      />
                    </div>
                    <div
                      className="col-6"
                      style={{
                        alignitems: "center",
                        display: "flex",
                        justifyContent: "center",
                        margin: "5px",
                      }}
                    >
                      <p>{t("basicWords.modal.addImageLinkTooltip")}</p>
                      {newMultimedia.imageUrl ? (
                        <img
                          style={{ objectFit: "contain" }}
                          src={newMultimedia.imageUrl}
                          alt="imagen"
                          width="100px"
                          height="100px"
                        ></img>
                      ) : null}
                    </div>
                  </div>,
                ]}
                footer={[
                  <div className="row buttonAtTheBottom">
                    <div>
                      <ButtonComponent
                        onClick={() => this.handleCloseDialog()}
                        shape="round"
                        text={t("basicWords.cancel")}
                        className="buttonDiscard"
                      />
                    </div>
                    <div className="col-6">
                      <ButtonComponent
                        isDisabled={false}
                        onClick={(e) =>
                          this.handleOkChangeImageType(e, "imageLink")
                        }
                        shape="round"
                        text={t("basicWords.ok")}
                      />
                    </div>
                  </div>,
                ]}
                onClick={() => this.handleCloseDialog()}
              />

              <GeneralModal // Select link video modal
                visible={openSelectVideoAddModal}
                title={t("sections.video")}
                body={[
                  <div key={1}>
                    <div className="col-6">
                      <GeneralInput
                        text={t("basicWords.enterUrlVideo")}
                        id={"videoUrl"}
                        value={
                          newMultimedia.videoUrl ? newMultimedia.videoUrl : ""
                        }
                        placeholder={t("basicWords.enterUrlVideo")}
                        isReq
                        onChange={(e) => this.handleChange(e, "videoUrl")}
                        iconYoutube={true}
                      />
                    </div>
                  </div>,
                ]}
                footer={[
                  <div className="row buttonAtTheBottom">
                    <div>
                      <ButtonComponent
                        onClick={() => this.handleCloseDialog()}
                        shape="round"
                        text={t("basicWords.cancel")}
                        className="buttonDiscard"
                      />
                    </div>
                    <div className="col-6">
                      <ButtonComponent
                        isDisabled={false}
                        onClick={(e) =>
                          this.handleOkChangeImageType(e, "video")
                        }
                        shape="round"
                        text={t("basicWords.ok")}
                      />
                    </div>
                  </div>,
                ]}
                onClick={() => this.handleCloseDialog()}
              />

              <br />

              {/* Fourth Line */}
              <div className="rowSections componentSpace">
                <div className="textLogo">{t("basicWords.buttons.buttons")}</div>
              </div>
              {/* Fifth Line */}

              <div
                className="rowSections componentSpace"
                style={{
                  marginLeft: "2%",
                }}
              >
                <ButtonsContainer
                  isModalVisible={true}
                  lpId={landingPageId}
                  buttonList={newsToAdd.buttons}
                  handleButtonListChange={(buttonList) =>
                    this.handleButtonListChange(buttonList, newsToAdd.id)
                  }
                />
              </div>
            </div>,
          ]}
        />
        <br />
      </div>
    );
  }

  addNew = (flag) => {
    if (flag !== this.state.addNew) {
      this.setState({ addNew: flag });
    }
  };

  //-----------Get image or video modals------------

  handleCloseDialog = (index) => {
    this.setState({
      openDialog: false,
      openImageOrVideoEditModal: false,
      openImageFileOrLinkEditModal: false,
      openImageFileEditModal: false,
      openImageLinkEditModal: false,
      openSelectVideoEditModal: false,
      openImageOrVideoAddModal: false,
      openImageFileOrLinkAddModal: false,
      openImageFileAddModal: false,
      openImageLinkAddModal: false,
      openSelectVideoAddModal: false,
    });
  };

  handleOpenImageOrVideoModal = (elementToEditMultimedia) => {
    const { imageIndex } = { ...this.state };
    if (!imageIndex) {
      this.setState({
        openImageOrVideoAddModal: true,
        openImageOrVideoEditModal: false,
      });
    } else {
      this.setState({
        openImageOrVideoEditModal: true,
        openImageOrVideoAddModal: false,
      });
    }

    this.setState({
      elementToEditMultimedia,
    });
  };

  handleSelectImageOrVideoModal = (imageOrVideo) => {
    const { imageIndex } = { ...this.state };
    if (!imageIndex) {
      this.setState({ openImageOrVideoAddModal: false });

      if (imageOrVideo === "image") {
        this.setState({
          openImageFileOrLinkAddModal: true,
          openSelectVideoAddModal: false,
        });
      } else if (imageOrVideo === "video") {
        this.setState({
          openSelectVideoAddModal: true,
          openImageFileOrLinkAddModal: false,
        });
      } else {
        this.setState({ openImageOrVideoAddModal: false });
      }
    } else {
      this.setState({ openImageOrVideoEditModal: false });

      if (imageOrVideo === "image") {
        this.setState({
          openImageFileOrLinkEditModal: true,
          openSelectVideoEditModal: false,
        });
      } else if (imageOrVideo === "video") {
        this.setState({
          openSelectVideoEditModal: true,
          openImageFileOrLinkEditModal: false,
        });
      } else {
        this.setState({ openImageOrVideoEditModal: false });
      }
    }
  };

  handleImageFileOrLink = (fileOrLink) => {
    const { imageIndex } = { ...this.state };
    if (!imageIndex) {
      this.setState({ openImageFileOrLinkAddModal: false });

      if (fileOrLink === "file") {
        this.setState({
          openImageFileAddModal: true,
          openImageLinkAddModal: false,
        });
      } else if (fileOrLink === "link") {
        this.setState({
          openImageLinkAddModal: true,
          openImageFileAddModal: false,
        });
      } else {
        this.setState({ openImageFileOrLinkAddModal: false });
      }
    } else {
      this.setState({ openImageFileOrLinkEditModal: false });

      if (fileOrLink === "file") {
        this.setState({
          openImageFileEditModal: true,
          openImageLinkEditModal: false,
        });
      } else if (fileOrLink === "link") {
        this.setState({
          openImageLinkEditModal: true,
          openImageFileEditModal: false,
        });
      } else {
        this.setState({ openImageFileOrLinkEditModal: false });
      }
    }
  };

  handleOkChangeImageType = (e, tag) => {
    const { newMultimedia, newsToShow, elementToEditMultimedia, imageIndex } =
      this.state;
    if (imageIndex !== null) {
      let elemToEdit = newsToShow.find(
        (e) => e.multimedia.id === elementToEditMultimedia.id
      );
      let elemIndex = newsToShow.indexOf(elemToEdit);

      newsToShow[elemIndex].edited = true;
      newsToShow[elemIndex].multimedia.edited = true;

      switch (tag) {
        case "imageFile":
          newsToShow[elemIndex].multimedia.imageUrl = null;
          newsToShow[elemIndex].multimedia.videoUrl = null;
          break;
        case "imageLink":
          newsToShow[elemIndex].multimedia.image = null;
          newsToShow[elemIndex].multimedia.file = null;
          newsToShow[elemIndex].multimedia.videoUrl = null;
          break;
        case "video":
          newsToShow[elemIndex].multimedia.imageUrl = null;
          newsToShow[elemIndex].multimedia.image = null;
          newsToShow[elemIndex].multimedia.file = null;
          break;
        default:
          break;
      }
    } else {
      switch (tag) {
        case "imageFile":
          newMultimedia.imageUrl = null;
          newMultimedia.videoUrl = null;
          break;
        case "imageLink":
          newMultimedia.image = null;
          newMultimedia.file = null;
          newMultimedia.videoUrl = null;
          break;
        case "video":
          newMultimedia.imageUrl = null;
          newMultimedia.image = null;
          newMultimedia.file = null;
          break;
        default:
          break;
      }
    }
    this.setState({
      newsToShow,
      //Select image or video modals edit
      openImageOrVideoEditModal: false,
      openImageFileOrLinkEditModal: false,
      openImageFileEditModal: false,
      openImageLinkEditModal: false,
      openSelectVideoEditModal: false,

      //Select image or video modals edit
      openImageOrVideoAddModal: false,
      openImageFileOrLinkAddModal: false,
      openImageFileAddModal: false,
      openImageLinkAddModal: false,
      openSelectVideoAddModal: false,
    });
  };

  //-----------Update page after modal------------
  updatePage = (lpSectionId, previousPath) => {
    this.getNewsInformation(lpSectionId);
    this.setState({
      previousPath,
    });
    this.setState({ layoutModalVisible: false });
    this.forceUpdate();
  };

  setShowError = (open) => {
    this.setState({ showError: open });
  };

  setShowSuccess = (open) => {
    this.setState({ showSuccess: open });
  };

  setShowWarning = (open) => {
    this.setState({ showWarning: open });
  };

  // Render Info
  render() {
    const {
      redirectBack,
      sectionName,
      newsQuantity,
      newsContainerTitle,
      newsToShow,
      organizationId,
      organizationName,
      name,
      subpageId,
      landingPageId,
      FlagClassName,
      layoutData,
      lpSectionLayoutStyle,
      lpUrl,
      isLoadingButton,

      // Modals
      showSuccess,
      showWarning,
      showError,
      contentNotificationModal,

      //Modal delete item
      openDeleteNews,
      elementToDeleteId,

      openModalToChangeName,
      customName,
      goToWithoutSaveModalVisible,
    } = this.state;
    const { t } = this.props;
    if (redirectBack) {
      return <Redirect to="/manage/web" />;
    }

    return (
      <Fragment>
        {!!showWarning && (
          <SystemNotification
            setShowNotification={(e) => this.setShowWarning(e)}
            type="Warning"
            caption={contentNotificationModal}
          />
        )}
        {!!showSuccess && (
          <SystemNotification
            setShowNotification={(e) => this.setShowSuccess(e)}
            type="Success"
            caption={contentNotificationModal}
          />
        )}
        {!!showError && (
          <SystemNotification
            setShowNotification={(e) => this.setShowError(e)}
            type="Error"
            caption={contentNotificationModal}
          />
        )}

        <GeneralModal
          visible={openModalToChangeName}
          title={"Cambiar nombre"}
          body={[
            <div className="col-12" style={{ margin: "5px" }}>
              <div className="row">
                <div className="col-md-12 componentSpace">
                  <TextField
                    variant="outlined"
                    style={{ width: "100%", marginLeft: 10 }}
                    label={t("basicWords.section")}
                    onChange={(e) => this.OnChangeSectionCustomName(e)}
                    defaultValue={
                      customName ? decodeURIComponent(customName) : ""
                    }
                  />
                </div>
              </div>
            </div>,
          ]}
          footer={[
            <div className="row buttonAtTheBottom">
              <div>
                <ButtonComponent
                  onClick={(e) => this.handleClickOpenModalToChangeName()}
                  shape="round"
                  text={t("basicWords.cancel")}
                  className="buttonDiscard"
                />
              </div>
              <div className="col-6">
                <ButtonComponent
                  isDisabled={false}
                  onClick={() => this.saveChangeName()}
                  shape="round"
                  text={t("basicWords.ok")}
                />
              </div>
            </div>,
          ]}
          onClick={() => this.handleClickOpenModalToChangeName()}
        />

        <DeleteItemModal
          visible={openDeleteNews}
          onCancel={this.handleCloseDeleteNews}
          onClick={(e) => this.disableNewsEvent(elementToDeleteId)}
        />

        <section className="sectionContainer">
          <Card className="card">
            {isLoadingButton === true ? (
              <div
                className="col-xs-12 col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  transform: "translateY(800%)",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="row componentSpace spaceBreadcrumb">
                  <BreadcrumbSection
                    principal={t("sections.news")}
                    secondary={organizationName}
                    route="./manage/web"
                    propsComponent={{
                      organizationId,
                      sectionName: sectionName,
                      nameSubpage: name ? name : null,
                      lpId: landingPageId,
                      subpageId: subpageId ? subpageId : null,
                      lpUrl: lpUrl,
                      customName: newsContainerTitle && newsContainerTitle.value ? decodeURIComponent(newsContainerTitle.value) : customName,
                    }}
                    handleClickOpenModalToChangeName={
                      this.handleClickOpenModalToChangeName
                    }
                    FlagclassName={FlagClassName}
                    onClick={(e) => this.saveAboutEvent(e)}
                    GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                    onCancelGotoWithoutMModal={(e) =>
                      this.onCancelGotoWithoutMModal(e)
                    }
                    goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                  />
                </div>
                <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                  <Title
                    title={t("basicWords.completeInformationOfYourSection")}
                  />
                </div>

                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <SelectLayoutModal
                    layoutData={layoutData}
                    lpLayoutId={
                      lpSectionLayoutStyle ? lpSectionLayoutStyle : null
                    }
                    handleChange={this.handleChangeLayout}
                  />
                </div>
                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <AccordionComponent
                    title={t("generalUser.sectionContent")}
                    body={[
                      <div className="accordionContent">
                        <div className="rowSection componentSpace">
                          <div
                            style={{
                              width: "70%",
                              marginRight: "1%",
                              marginLeft: "1%",
                            }}
                          >
                            <GeneralInput
                              className="textInputWidth"
                              placeholder={t("basicWords.enterTitle")}
                              id={
                                newsContainerTitle
                                  ? newsContainerTitle.nodeTypeName
                                  : null
                              }
                              onChange={(e, id) =>
                                this.handleChange(e, "", "", "", id)
                              }
                              value={
                                newsContainerTitle
                                  ? newsContainerTitle.value
                                  : null
                              }
                            />
                          </div>
                          <div style={{ width: "30%" }}>
                            <GeneralInput
                              placeholder={t("generalMessages.numberNews")}
                              id={newsQuantity ? newsQuantity.nodeTypeName : null}
                              onChange={(e, id) =>
                                this.handleChange(e, "", "", "", id)
                              }
                              value={newsQuantity ? newsQuantity.value : null}
                            />
                          </div>
                        </div>
                      </div>,
                    ]}
                  />
                </div>

                {/* Accordion to add notice */}
                {landingPageId ? (
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    {this.renderAddNews()}
                  </div>
                ) : null}

                <div>
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    <Title title={t("basicWords.listNews")} />
                  </div>
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    {this.renderNews(newsToShow)}
                  </div>
                </div>
              </>
            )}
          </Card>
        </section>
        <br />

        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName: sectionName,
            nameSubpage: name ? name : null,
            lpId: landingPageId,
            subpageId: subpageId ? subpageId : null,
            lpUrl: lpUrl,
          }}
          FlagclassName={FlagClassName}
          onClick={(e) => this.saveNewsEvent(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </Fragment>
    );
  }
}
export default withTranslation()(NewsSection);
