import axios from "axios";

import {
  protocol,
  host,
  port,
  route,
  dataSource,
} from "../../reactUtils/systemVariables/UhooServerInformation";

const resource = '/channel/';

export async function getChannels(Params) {
  let sendParams = "";

  if (Params !== undefined || null) {
    if (typeof Params !== "string") {
      //console.log(Params);
      //sendParams += "?"
      Params.forEach((item) =>
        item.key === "page"
          ? (sendParams += "page/" + item.value)
          : (sendParams += "")
      ); // Get Post Page --> Si Value no es definido por defecto va a ser 10
      Params = Params.filter((item) => item.key !== "page");
      //console.log(Params);

      Params.forEach((item) =>
        item.key === "size"
          ? (sendParams += "size/" + item.value)
          : (sendParams += "")
      ); // Get Size Post Filter
      Params = Params.filter((item) => item.key !== "size");
      //console.log(Params);

      Params.forEach(
        (item) => (sendParams += "?" + item.key + "=" + item.value + "&")
      ); // Envia otros parametros adicionales Ej: Get Post Page Filter
    } else {
      sendParams = Params;
    } // Asigna el String como parametro de la consulta Ej: main o size
  } else {
    sendParams = "";
  }

    //const objSessionInformation = JSON.parse(sessionStorage.userInfo);
    //const userToken = "Bearer " + objSessionInformation.token;
    const userToken = "Bearer " + sessionStorage.access_token;

  let GetMedia;

  try {
    GetMedia = await axios({
      method: "GET",
      url: protocol + host + port + route + dataSource + resource + sendParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    });

    if (!GetMedia.error) {
      return GetMedia.data;
    }
  } catch (error) {
    console.log("Error: API Brilliant");
    return error;
  }
}

export async function likePost(Params) {
  let sendParams = "";

  let payload_uuid, payload_value;

  if (Params !== undefined || null) {
    if (typeof Params !== "string") {

      Params.forEach((item) => item.key === "post" ? (payload_uuid = item.value) : null );
      Params.forEach((item) => item.key === "value" ? (payload_value = item.value) : null );

      Params.forEach((item) => item.key === "channel" ? (sendParams += item.value) : (sendParams += "") );
      
      Params.forEach((item) => item.key === "post" ? (sendParams += "/post/" + item.value) : (sendParams += "") );

      sendParams += "/like/"
      
    } 
  } else {
    sendParams = "";
  }

    const userToken = "Bearer " + sessionStorage.access_token;

  let like;

  try {
    like = await axios({
      method: "POST",
      url: protocol + host + port + route + dataSource + resource + sendParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data: { uuid: payload_uuid, value: payload_value }
    });

    if (!like.error) {
      return like;
    }
  } catch (error) {
    console.log("Error: API Brilliant");
    return error;
  }
}

export async function comentPost(Params) {
  let sendParams = "";

  let payload_comment;

  if (Params !== undefined || null) {
    if (typeof Params !== "string") {

      Params.forEach((item) => item.key === "comment" ? (payload_comment = item.value) : (payload_comment = "") );

      Params.forEach((item) => item.key === "channel" ? (sendParams += item.value) : (sendParams += "") );
      //Params = Params.filter((item) => item.key !== "channel");
      
      Params.forEach((item) => item.key === "post" ? (sendParams += "/post/" + item.value) : (sendParams += "") );
      //Params = Params.filter((item) => item.key !== "post");

      sendParams += "/comment/"
      
    } 
  } else {
    sendParams = "";
  }

    const userToken = "Bearer " + sessionStorage.access_token;

  let like;

  try {
    like = await axios({
      method: "POST",
      url: protocol + host + port + route + dataSource + resource + sendParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data: { comment: payload_comment }
    });

    if (!like.error) {
      return like;
    }
  } catch (error) {
    console.log("Error: API Brilliant");
    return error;
  }
}
/*
import {useState, useEffect} from "react";

import axios from 'axios';

import { protocol, host, port, route, dataSource } from '../../reactUtils/systemVariables/UhooServerInformation';

const query = '/channel/';

export async function GetChannels(Params)  { 

  console.log("Parametro: ", Params);
  
  let sendParams = "";

  if (Params !== undefined || null){

    if (typeof Params !== "string")
    {
      //sendParams += "?"
      Params.forEach(item => (item.key === 'page') ? sendParams += "page/" + item.value + "?" : sendParams += ""); // Get Post Page --> Si Value no es definido por defecto va a ser 10
      Params.forEach(item => (item.key === 'size') ? sendParams += "size/" + item.value + "?" : sendParams += ""); // Get Size Post Filter
      Params.forEach(item => sendParams += "?" + item.key + "=" + item.value + "&");  // Envia otros parametros adicionales Ej: Get Post Page Filter

    } else {  sendParams = Params }   // Asigna el String como parametro de la consulta Ej: main o size
    
  }else {
    sendParams = "";
  }

  const objSessionInformation = JSON.parse(sessionStorage.userInfo);
  const userToken = "Bearer " + objSessionInformation.token;

  console.log("into GetChannel");
  console.log(protocol + host + port + route + dataSource + query + sendParams);

  const [info, setInfo] = useState([]);

	useEffect(async() => {
		const response = await axios(
      {
        method: 'GET',
        url: protocol + host + port + route + dataSource + query + sendParams,      
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userToken,
          }
      }
    );
		setInfo(response.data);
	},  []);

    //console.log("response", info);
    return info;
};



export { GetChannels };

/*

const GetAllChannels = () => { 

  const objSessionInformation = JSON.parse(sessionStorage.userInfo);
  const userToken = "Bearer " + objSessionInformation.token;

  let Params = "size/";

  console.log("into GetAllChannels");
  //console.log(protocol + host + port + route + dataSource + query + Params);

  const [info, setInfo] = useState([]);

	useEffect(async() => {
		const AllChannels = await axios(
      {
        method: 'GET',
        url: protocol + host + port + route + dataSource + query + Params,      
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userToken,
          }
      }
    );

    console.log("response", AllChannels.data.page.size);

		setInfo(AllChannels.data.page.size);
	},  []);

  console.log("response async", info);
  console.log("type response async", typeof info);

  if (typeof info == 'number')
  {
    //const [data, setData] = useState([]);

    let inquire = [
      {
        key: 'page_size',
        value: info,
      },
    ];
    
    //setData(GetChannels(inquire));

    return GetChannels(inquire);

  }    
  
};

export { GetAllChannels };





const GetChannelMain = () => { 
  const objSessionInformation = JSON.parse(sessionStorage.userInfo);
  const userToken = "Bearer " + objSessionInformation.token;

  let Params = "main";

  //console.log("into GetChannelMain");
  console.log(protocol + host + port + route + dataSource + query + Params);

  const [info, setInfo] = useState([]);

	useEffect(async() => {
		const mainChannels = await axios(
      {
        method: 'GET',
        url: protocol + host + port + route + dataSource + query + Params,      
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userToken,
          }
      }
    );

		setInfo(mainChannels.data);
	},  []);
    
    return info;
};

export { GetChannelMain };

*/