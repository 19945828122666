import React from "react";
import { get as _get } from "lodash";
import "./css/PostMailPreview.css";

function MailPreview( props ) {
    let URLactual = window.location;
    URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
    let isAura = URLactual.includes("aura")
    let title = props.previewTitle;
    //let title = "Titulo";
    let text = props.previewInfo;
    let image = props.previewImage;
    let isImg = true;
    if(image){
      isImg = image.name.includes('png') || image.name.includes('jpeg') || image.name.includes('jpg');
    }
   return(    
    <React.Fragment>
      <div className="contentMailPreview">
        <div className="gmail">
          <div className="MailContent">

            {image !== "" || text !== "" || title !== ""
              ? 
              <React.Fragment>
              
                {title !== ""
                  ? <div className="titleMail">{title}</div>
                  : null
                }         
                <div className={isAura ? "subjectAura" : "subject"} />
                {/* <div className="subject"/> */}

              <div className="Mail">

                {text !== ""
                  ? <div className="textMail">{text}</div>
                  : null
                }
                <div className="contentImage">

                  {image.url !== "" ?
                  <>
                  {
                    image.name ? 
                    <>
                      {
                        image.name.search('.mp4') != -1 ?
                        <video className="imageUhoo" src={image.url} alt="image preview"></video>
                        :
                        <>
                        {
                            isImg ?
                            <img  className="imageUhoo" src={image.url} key={image.uuid} alt="image preview"/>
                            :
                            <img  className="imageUhoo" onClick={() => window.open(image.url,'_blank')} key={image.uuid} src='http://cdn.onlinewebfonts.com/svg/img_162799.png' alt='document' />

                        }
                      </>
                      }
                      
                    </>
                    :  null
                  }
                  </>
                  : null
                }
              </div>
              </div>

              </React.Fragment>
              
              : null
            }
            
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MailPreview;


