import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as Close } from "../../../assets/icons/navigation/closeModal.svg";
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import SystemNotification from "../../global/SystemNotification.jsx";
import { putRole, postRole } from "../../../actions/uhoo";
import "./css/configureUser.css";
import { cloneDeep as _cloneDeep } from "lodash";

//Context
import { UsersCommunityContext } from "../contex";

function ConfigureRole(props) {
  let newRole = {
    uuid: "",
    create_user: null,
    edit_user: null,
    delete_user: null,
    create_channel: null,
    edit_channel: null,
    delete_channel: null,
    edit_community: null,
    create_post: null,
    edit_post: null,
    publish_post: null,
    approve_post: null,
    delete_post: null,
    name: "",
    description: "",
    active: true,
    access_cms: null,
    default: null,
    super: null,
  };

  const [EditedRole, setEditedRole] = useState(
    props.RolSelect ? props.RolSelect[0] : newRole
  );
  const { t, i18n } = useTranslation(); //  Localization
  const [showModalSuccessful, setShowModalSuccessful] = useState(false); // Modal Seleccioanr Audiecias
  const [showModalFail, setShowModalFail] = useState(false); // Modal Seleccioanr Audiecias
  const { update, setUpdate } = useContext(UsersCommunityContext);
  const [showMailWarning, setShowMailWarning] = useState(false);
  const [ textModalWarning, setTextModalWarning ] = useState('');

  let open = props.setOpenModalRoleConfig;

  function closeModal() {
    open(false);
  }

  function saveRoleFunction(e) {

    if(EditedRole.name == ''){
      setTextModalWarning(t('basicWords.nameError'))
      setShowMailWarning(true);
      return;
    }

    let roleData = {
      uuid: EditedRole.uuid,
      create_user: EditedRole.create_user,
      edit_user: EditedRole.edit_user,
      delete_user: EditedRole.delete_user,
      create_channel: EditedRole.create_channel,
      edit_channel: EditedRole.edit_channel,
      delete_channel: EditedRole.delete_channel,
      edit_community: EditedRole.edit_community,
      create_post: EditedRole.create_post,
      edit_post: EditedRole.edit_post,
      publish_post: EditedRole.publish_post,
      approve_post: EditedRole.approve_post,
      delete_post: EditedRole.delete_post,
      name: EditedRole.name,
      description: EditedRole.description,
      active: true,
      access_cms: EditedRole.access_cms,
      default: null,
      super: EditedRole.super,
    };
    saveRole(roleData);
    open(false);
  }

  const saveRole = async (roleData) => {
    if (roleData.uuid !== "") {
      try {
        let roleEdited = await putRole(roleData);
        if (roleEdited.length === 0) {
          setShowModalFail(true);
        } else {
          setShowModalSuccessful(true);
          setUpdate(!update);
        }
      } catch (error) {
        setShowModalFail(true);
      }
    } else {
      try {
        let newRole = await postRole(roleData);

        if (newRole.length === 0) {
          setShowModalFail(true);
        } else {
          setShowModalSuccessful(true);
          setUpdate(!update);
        }
      } catch (error) {
        setShowModalFail(true);
      }
    }
  };

  function editRole(e, dataIndex, flag) {
    let value;
    if (dataIndex === "name" || dataIndex === "description") {
      value = e.target.value;
    } else {
      value = flag;
    }

    let _updateRole = _cloneDeep(EditedRole);
    _updateRole[dataIndex] = value;
    setEditedRole(_updateRole);
  }

  return (
    <React.Fragment>
      <div className="modalConfigureUser">
        <div className="titleModalConfigureUser">
          {props.RolSelect ? (
            <div className="textTitleModalConfigureUser">Editar rol</div>
          ) : (
            <div className="textTitleModalConfigureUser">Nuevo rol</div>
          )}

          <div
            className="closeModalConfigureUser"
            onClick={closeModal}
            role="button"
          >
            <Close />
          </div>
        </div>

        <hr className="lines" />

        <div className="contentModalConfigureUser">
          <div
            className="infoContentModalConfigureUser"
            style={{ top: "25px" }}
          >
            <div className="nameInfoContentModalConfigureUser half">
              <div className="labelForm">Nombre</div>
              <input
                type="text"
                name="name"
                value={EditedRole.name}
                className="inputContentModalConfigureUser"
                onChange={(e) => editRole(e, "name")}
              ></input>
            </div>
            <div className="nameInfoContentModalConfigureUser half">
              <div className="labelForm">Descripción</div>
              <input
                id="description"
                type="text"
                name="description"
                value={EditedRole.description}
                className="inputContentModalConfigureUser"
                onChange={(e) => editRole(e, "description")}
              ></input>
            </div>

            <div className="componentSpaceTable">
                <table className="tableRol">
                    <tr>
                      <th className="tableHeadRol">Privilegio</th>
                      <th className="tableHeadRol">Bloquear</th>
                      <th className="tableHeadRol">Permitir</th>
                    </tr>
                    <tr key={"create_user"} className="tableContainerBodyRol">
                      <th className="tableBodyRol">Crear usuario</th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.create_user === 0 ? true : false
                            }
                            onChange={(e) => editRole(e, "create_user", 0)}
                          />
                        </div>
                      </th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.create_user === 1 ? true : false
                            }
                            onChange={(e) => editRole(e, "create_user", 1)}
                          />
                        </div>
                      </th>
                    </tr>

                    <tr key={"edit_user"} className="tableContainerBodyRol">
                      <th className="tableBodyRol">Editar usuario</th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={EditedRole.edit_user === 0 ? true : false}
                            onChange={(e) => editRole(e, "edit_user", 0)}
                          />
                        </div>
                      </th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={EditedRole.edit_user === 1 ? true : false}
                            onChange={(e) => editRole(e, "edit_user", 1)}
                          />
                        </div>
                      </th>
                    </tr>

                    <tr key={"delete_user"} className="tableContainerBodyRol">
                      <th className="tableBodyRol">Eliminar usuario</th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.delete_user === 0 ? true : false
                            }
                            onChange={(e) => editRole(e, "delete_user", 0)}
                          />
                        </div>
                      </th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.delete_user === 1 ? true : false
                            }
                            onChange={(e) => editRole(e, "delete_user", 1)}
                          />
                        </div>
                      </th>
                    </tr>

                    <tr key={"create_channel"} className="tableContainerBodyRol">
                      <th className="tableBodyRol">Crear audiencia</th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.create_channel === 0 ? true : false
                            }
                            onChange={(e) => editRole(e, "create_channel", 0)}
                          />
                        </div>
                      </th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.create_channel === 1 ? true : false
                            }
                            onChange={(e) => editRole(e, "create_channel", 1)}
                          />
                        </div>
                      </th>
                    </tr>

                    <tr key={"edit_channel"} className="tableContainerBodyRol">
                      <th className="tableBodyRol">Editar audiencia</th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.edit_channel === 0 ? true : false
                            }
                            onChange={(e) => editRole(e, "edit_channel", 0)}
                          />
                        </div>
                      </th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.edit_channel === 1 ? true : false
                            }
                            onChange={(e) => editRole(e, "edit_channel", 1)}
                          />
                        </div>
                      </th>
                    </tr>

                    <tr key={"delete_channel"} className="tableContainerBodyRol">
                      <th className="tableBodyRol">Eliminar audiencia</th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.delete_channel === 0 ? true : false
                            }
                            onChange={(e) => editRole(e, "delete_channel", 0)}
                          />
                        </div>
                      </th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.delete_channel === 1 ? true : false
                            }
                            onChange={(e) => editRole(e, "delete_channel", 1)}
                          />
                        </div>
                      </th>
                    </tr>

                    <tr key={"edit_community"} className="tableContainerBodyRol">
                      <th className="tableBodyRol">Editar comunidad</th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.edit_community === 0 ? true : false
                            }
                            onChange={(e) => editRole(e, "edit_community", 0)}
                          />
                        </div>
                      </th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.edit_community === 1 ? true : false
                            }
                            onChange={(e) => editRole(e, "edit_community", 1)}
                          />
                        </div>
                      </th>
                    </tr>

                    <tr key={"create_post"} className="tableContainerBodyRol">
                      <th className="tableBodyRol">Crear publicación</th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.create_post === 0 ? true : false
                            }
                            onChange={(e) => editRole(e, "create_post", 0)}
                          />
                        </div>
                      </th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.create_post === 1 ? true : false
                            }
                            onChange={(e) => editRole(e, "create_post", 1)}
                          />
                        </div>
                      </th>
                    </tr>

                    <tr key={"edit_post"} className="tableContainerBodyRol">
                      <th className="tableBodyRol">Editar publicación</th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={EditedRole.edit_post === 0 ? true : false}
                            onChange={(e) => editRole(e, "edit_post", 0)}
                          />
                        </div>
                      </th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={EditedRole.edit_post === 1 ? true : false}
                            onChange={(e) => editRole(e, "edit_post", 1)}
                          />
                        </div>
                      </th>
                    </tr>

                    <tr key={"publish_post"} className="tableContainerBodyRol">
                      <th className="tableBodyRol">Publicar publicación</th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.publish_post === 0 ? true : false
                            }
                            onChange={(e) => editRole(e, "publish_post", 0)}
                          />
                        </div>
                      </th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.publish_post === 1 ? true : false
                            }
                            onChange={(e) => editRole(e, "publish_post", 1)}
                          />
                        </div>
                      </th>
                    </tr>

                    <tr key={"approve_post"} className="tableContainerBodyRol">
                      <th className="tableBodyRol">Aprobar publicación</th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.approve_post === 0 ? true : false
                            }
                            onChange={(e) => editRole(e, "approve_post", 0)}
                          />
                        </div>
                      </th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.approve_post === 1 ? true : false
                            }
                            onChange={(e) => editRole(e, "approve_post", 1)}
                          />
                        </div>
                      </th>
                    </tr>

                    <tr key={"delete_post"} className="tableContainerBodyRol">
                      <th className="tableBodyRol">Eliminar publicación</th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.delete_post === 0 ? true : false
                            }
                            onChange={(e) => editRole(e, "delete_post", 0)}
                          />
                        </div>
                      </th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={
                              EditedRole.delete_post === 1 ? true : false
                            }
                            onChange={(e) => editRole(e, "delete_post", 1)}
                          />
                        </div>
                      </th>
                    </tr>

                    <tr key={"access_cms"} className="tableContainerBodyRol">
                      <th className="tableBodyRol">Acceder al CMS</th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={EditedRole.access_cms === 0 ? true : false}
                            onChange={(e) => editRole(e, "access_cms", 0)}
                          />
                        </div>
                      </th>
                      <th className="tableBodyRol">
                        <div className="contentEmailSelect">
                          <input
                            type="radio"
                            className="inputSelectTable"
                            checked={EditedRole.access_cms === 1 ? true : false}
                            onChange={(e) => editRole(e, "access_cms", 1)}
                          />
                        </div>
                      </th>
                    </tr>
                </table>
            </div>
          </div>
        </div>
        <div
          className="actionContentModalConfigureRole"
          onClick={saveRoleFunction}
        >
          <SecondaryButton txt="Guardar" />
        </div>
      </div>

      {!!showMailWarning && (
          <SystemNotification
              setShowNotification={setShowMailWarning}
              type="Warning"
              caption={textModalWarning}
          />
      )}

      {!!showModalSuccessful && (
        <SystemNotification
          setShowNotification={setShowModalSuccessful}
          type="Success"
          caption="Se ha guardado exitosamente"
        />
      )}
      {!!showModalFail && (
        <SystemNotification
          setShowNotification={setShowModalFail}
          type="Error"
          caption="No se pudo guardar. Intente nuevamente."
        />
      )}
    </React.Fragment>
  );
}

export default ConfigureRole;
