import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import { useTranslation } from 'react-i18next';
import { get as _get } from "lodash";
import "../../community/components/css/deleteUser.css";

import SystemNotification from "../../global/SystemNotification.jsx";

import { DeletePost } from "../../../actions/uhoo/Post.actions";
import { useHistory } from "react-router-dom";

//Context
import { ActivityContext } from '../contex';

function DeleteUser(props) {

    const { t, i18n } = useTranslation(); //  Localization
    let open = props.setOpenModal;
    let history = useHistory();
    const { setUpdateAction, updateAction } = useContext(ActivityContext);

    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    function closeModal() { open(false); }

    //console.log("en eliminar", props.post);

    function deletePost() {
        const deleteActionPost = async (query) => {
            const info = await DeletePost(query);
            //console.log("respuesta delete", info);

            if (info.status === 200) {
                setShowSuccess(true);
            } else {
                console.error("Error", info.status, info.statusText);
                setShowError(true);
            }
        }

        deleteActionPost({ uuid: props.post.uuid, active: 0 });
        setUpdateAction(!updateAction)
        setTimeout(()=>{history.push({pathname: '/manage', })}, 100)
    }

    return (
        <React.Fragment>
            <div className="modalDeleteUser">
                <div className="titleModalDeleteUser">
                    <div className="textTitleModalDeleteUser">{t('basicWords.confirmation')}</div>
                    <div className="closeModalDeleteUser" onClick={() => closeModal()} role="button"><Close /></div>
                </div>

                <hr className="lines" />

                <div className="contentModalDeleteUser">
                    {t('uhoo.activity.post.delete')}
                </div>
                <div className="actionContentModalDeleteUser">
                    <div role="button" onClick={() => closeModal()}>
                        <SecondaryButton txt={t('uhoo.globalWords.cancel')} dark />
                    </div>
                    <div role="button" onClick={() => deletePost()}>
                        <SecondaryButton txt={t('uhoo.globalWords.delete')} danger />
                    </div>
                </div>
            </div>

            {!!showError && (
                <SystemNotification
                    setShowNotification={setShowError}
                    type="Error"
                    caption={t('basicWords.internalServerError')}
                    setShowModal={open}
                />
            )}
            {!!showSuccess && (
                <SystemNotification
                    setShowNotification={setShowSuccess}
                    type="Success"
                    caption={t('uhoo.activity.post.deleteComment')}
                    setShowModal={open}
                />
            )}

        </React.Fragment>
    );
}

export default DeleteUser;
