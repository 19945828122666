import React from "react";

import moment from "moment";

// Components
import {
  GeneralInput,
  TitleCard,
  SelectSingleStreamings,
  DateTimePickerComponent,
} from "../../../../aura-components";

import {
  Card,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
} from "@material-ui/core";

import ButtonsContainer from "../../../../aura-components/ButtonsContainer.jsx";

import "../css/sections.scss";

//Localization
import { useTranslation } from 'react-i18next';

// const { TextArea } = Input;
const format = "DD/MM/YYYY";

const AddStreamingModal = (props) => {
  var { newStreaming, isEditPreview, recurrenceSelect } = props;
  const { t, i18n } = useTranslation();
  
  var ocurrenceSelect = null;
  var scheduleEventType = newStreaming.scheduleEventType;
  var scheduleEventTypeValue;

  var regex1 = /www.youtube.com/;
  var resultYT = regex1.test(newStreaming.url);
  var regex1 = /www.facebook.com/;
  var resultFB = regex1.test(newStreaming.url);

  if (newStreaming.timeInit === null || newStreaming.timeFinal === null) {
    newStreaming.timeInit = newStreaming.dateInit;
    newStreaming.timeFinal = newStreaming.dateFinal;
  }

  if (newStreaming.dateInit != null) {
    var relativeDay = props.ordinalDayOfMonth(newStreaming.dateInit);
    var dayNumber = relativeDay[0];
    var relativeDayValue = relativeDay[1];
    var relativeDay = relativeDay[2];
    var day = props.dayNameofWeek(newStreaming.dateInit);
    var dayNameOfWeek = day[0];
    var dayWeek = day[1];
    var auxEventRule = newStreaming.eventRule;
    var monthName = props.monthOfYear(newStreaming.dateInit);
  }

  var lengthSelectScheduleEventType = 12;
  var lengthSelectOcurrency = 0;

  if (newStreaming.buttons === undefined) {
    newStreaming.buttons = [];
  }

  if (newStreaming.checked === true) {
    lengthSelectScheduleEventType = 6;
    lengthSelectOcurrency = 6;
  }

  if (newStreaming.checked === true) {
    newStreaming.checked = "true";
  }
  if (newStreaming.checked === false) {
    newStreaming.checked = "false";
  }

  if (isEditPreview === false) {
    ocurrenceSelect = null;
  } else {
    if (
      auxEventRule.frecuencyUnit === "MINUTES" ||
      auxEventRule.frecuencyUnit === "HOURS" ||
      auxEventRule.frecuencyUnit === "DAYS"
    ) {
      ocurrenceSelect = "CUSTOMS";
    }
    if (auxEventRule.frecuencyUnit === "WEEKS") {
      if (auxEventRule.weekdays) {
        ocurrenceSelect = "CUSTOMS";
      }
      if (
        auxEventRule.weekdays &&
        auxEventRule.weekdays[0] === "MON" &&
        auxEventRule.weekdays[1] === "TUE" &&
        auxEventRule.weekdays[2] === "WED" &&
        auxEventRule.weekdays[3] === "THU" &&
        auxEventRule.weekdays[4] === "FRI"
      ) {
        ocurrenceSelect = "MONTOFRI";
      }
      if (
        auxEventRule.weekdays &&
        auxEventRule.weekdays[0] === "SUN" &&
        auxEventRule.weekdays[1] === "MON" &&
        auxEventRule.weekdays[2] === "TUE" &&
        auxEventRule.weekdays[3] === "WED" &&
        auxEventRule.weekdays[4] === "THU" &&
        auxEventRule.weekdays[5] === "FRI" &&
        auxEventRule.weekdays[6] === "SAT"
      ) {
        ocurrenceSelect = "ALLDAYS";
      }
      if (
        auxEventRule.weekdays &&
        auxEventRule.weekdays[0] === dayWeek &&
        auxEventRule.frecuencyValue === "1"
      ) {
        ocurrenceSelect = "PERWEEK";
      }
    }
    if (auxEventRule.frecuencyUnit === "MONTHS") {
      if (auxEventRule.numberOcurrences) {
        ocurrenceSelect = "CUSTOMS";
      } else {
        if (auxEventRule.specificDayOfMonth) {
          ocurrenceSelect = "CUSTOMS";
        }
        if (auxEventRule.relativeDayOfMonth) {
          ocurrenceSelect = relativeDayValue + " " + dayWeek;
        }
      }
    }
    if (auxEventRule.frecuencyUnit === "YEARS") {
      if (auxEventRule.numberOcurrences || auxEventRule.frecuencyValue > 1) {
        ocurrenceSelect = "CUSTOMS";
      } else {
        ocurrenceSelect = "ANUALLY";
      }
    }
    if (auxEventRule.frecuencyValue > 1) {
      ocurrenceSelect = "CUSTOMS";
    }
  }

  var fullEditable = false;
  if (newStreaming.streamingComponentLastEventsGeneratorDate != "") {
    fullEditable = true;
    var lastEventsGeneratorDate = moment(
      newStreaming.streamingComponentLastEventsGeneratorDate
    ).format(format);
    // var monthNameAlert = props.monthOfYear(
    //   newStreaming.streamingComponentLastEventsGeneratorDate
    // );
    // var dayNumberAlert = moment(
    //   newStreaming.streamingComponentLastEventsGeneratorDate
    // ).format("D");
    // var yearAlert = moment(
    //   newStreaming.streamingComponentLastEventsGeneratorDate
    // ).format("YYYY");

    if (lastEventsGeneratorDate === undefined) {
      lastEventsGeneratorDate = moment().format(format);
      // var monthNameAlert = props.monthOfYear(lastEventsGeneratorDate);
      // var dayNumberAlert = moment(lastEventsGeneratorDate).format("D");
      // var yearAlert = moment(lastEventsGeneratorDate).format("YYYY");
    }
  }

  if (scheduleEventType === t('basicWords.scheduleEventType.mass')) {
    scheduleEventTypeValue = 1;
  }
  if (scheduleEventType === t('basicWords.scheduleEventType.rosary')) {
    scheduleEventTypeValue = 2;
  }
  if (scheduleEventType === t('basicWords.scheduleEventType.chapletOfTheDivineMercy')) {
    scheduleEventTypeValue = 3;
  }
  if (scheduleEventType === t('basicWords.scheduleEventType.conference')) {
    scheduleEventTypeValue = 4;
  }
  if (scheduleEventType === t('basicWords.scheduleEventType.integration')) {
    scheduleEventTypeValue = 5;
  }
  if (scheduleEventType === t('basicWords.scheduleEventType.retreat')) {
    scheduleEventTypeValue = 6;
  }
  if (scheduleEventType === t('basicWords.scheduleEventType.other')) {
    scheduleEventTypeValue = 7;
  }

  let titleMaxLength = 100;
  let descriptionMaxLength = 250;

  return (
    <>
      <Card
        className="card"
        style={{ margin: "2% 6% 2% 1%", height: "auto", padding: "20px" }}
      >
        <div
          className="row justify-content-center componentSpace"
          style={{
            display: "flex",
            alignitems: "center",
          }}
        >
          <TitleCard text={t('generalUser.addStreaming')} />
        </div>

        {/* Add streaming Modal */}
        <div className="accordionContent">
          {/* Title and URL */}
          <div className="accordionCenterLine">
            <div
              style={{
                width: "50%",
                marginLeft: "1%",
                marginRight: "0.7%",
              }}
            >
              <GeneralInput
                className="textInputWidth"
                id="StreamingComponentUrl"
                placeholder={t('generalMessages.url')}
                value={newStreaming.url}
                onChange={(value) => props.handleChange(value)}
                disabled={!fullEditable}
              />
            </div>
            <div
              style={{
                width: "50%",
                marginLeft: "0.7%",
                marginRight: "1%",
              }}
            >
              <GeneralInput
                className="textInputWidth"
                id="StreamingComponentTitle"
                placeholder={t('basicWords.title')}
                value={newStreaming.title}
                onChange={(e) => props.handleChange(e)}
                disabled={!fullEditable}
              />
              {/* <span>
              {newStreaming.title.length} / {titleMaxLength}
            </span> */}
            </div>
          </div>
        </div>

        {/* Description */}
        <br />
        <div className="accordionCenterLine">
          <div className="accordionCompleteInputs">
            <GeneralInput
              className="textInputWidth "
              id="StreamingComponentDescription"
              placeholder={t('basicWords.description')}
              value={newStreaming.description}
              onChange={(e) => props.handleChange(e)}
              disabled={!fullEditable}
            />
            <span>
              {newStreaming.description.length} / {descriptionMaxLength}
            </span>
          </div>
        </div>

        {/* Type and repeat */}
        <br />
        <div className="accordionCenterLine">
          <div
            style={{
              width: "50%",
              marginLeft: "1%",
              marginRight: "0.7%",
            }}
          >
            <SelectSingleStreamings
              defaultValue={scheduleEventTypeValue}
              text={t('basicWords.streaming.typeEvent')}
              isReq
              isDisabled={false}
              isLoading={false}
              name="scheduleEventTypeOptions"
              onChange={(e, option) =>
                props.handleSelectScheduleEventType(e, option)
              }
              options={props.scheduleEventTypeOptions}
            />
          </div>
        </div>

        <br />
        <div className="accordionCenterLine">
          <div
            style={{
              width: "40%",
              marginLeft: "1%",
              marginRight: "0.7%",
            }}
          >
            <DateTimePickerComponent
              onChange={(e) => props.initDate(e)}
              string={t('basicWords.streaming.initDate')}
              defaultValue={
                newStreaming.dateInit ? newStreaming.dateInit : null
              }
            />
          </div>
          <div
            style={{
              width: "40%",
              marginLeft: "0.7%",
              marginRight: "1%",
            }}
          >
            <DateTimePickerComponent
              onChange={(e) => props.finalDate(e)}
              string={t('basicWords.streaming.endDate')}
              defaultValue={
                newStreaming.dateFinal ? newStreaming.dateFinal : null
              }
            />
          </div>
        </div>

        <br />
        <div className="accordionCenterLine">
          <div
            style={{
              width: "20%",
              marginLeft: "1%",
              marginRight: "0.7%",
            }}
          >
            <div className="accordionCenterLine">
              <TitleCard text={t('basicWords.streaming.eventWillBeRepeat')} />
            </div>
            <div
              className="row justify-content-center "
              style={{
                display: "flex",
                alignitems: "center",
                marginLeft: "2%",
                transform: "translateY(-30%)",
              }}
            >
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
                onChange={(e) => props.handleSwitch(e)}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={t('basicWords.yes')}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={t('basicWords.no')}
                />
              </RadioGroup>
            </div>
          </div>
        </div>

        <br />

        <div className="accordionCenterLine">
          {newStreaming.checked === "true" ? (
            <div
              style={{
                width: "50%",
                marginLeft: "1%",
                marginRight: "0.7%",
              }}
            >
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t('basicWords.streaming.selectOcurrence')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={recurrenceSelect}
                    label={t('basicWords.streaming.selectOcurrence')}
                    onChange={(e) => props.handleSelectOcurrency(e)}
                  >
                    <MenuItem value="ALLDAYS">
                      {t('basicWords.streaming.allDays')}
                    </MenuItem>
                    <MenuItem value="MONTOFRI">
                      {t('basicWords.streaming.monToFri')}
                    </MenuItem>
                    <MenuItem value="PERWEEK">
                      {t('basicWords.streaming.everyWeek') +
                        "," +
                        " " +
                        t('basicWords.streaming.the') +
                        "  " +
                        dayNameOfWeek}
                    </MenuItem>
                    <MenuItem value="ANUALLY">
                      {t('basicWords.streaming.annually') +
                        ", " +
                        t('basicWords.streaming.the') +
                        " " +
                        dayNumber +
                        " " +
                        t('basicWords.streaming.on') +
                        " " +
                        monthName}
                    </MenuItem>
                    <MenuItem value={relativeDayValue + " " + dayWeek}>
                      {t('basicWords.streaming.allMonths') +
                        ", " +
                        relativeDay +
                        " " +
                        dayNameOfWeek}
                    </MenuItem>
                    <MenuItem value="CUSTOMS">
                      {t('basicWords.streaming.custom')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          ) : null}
        </div>

        <br />
        <div className="accordionCenterLine">
          <div className="textLogo" style={{ marginLeft: "1%" }}>
            {t('basicWords.buttons.buttons')}
          </div>
        </div>

        <br />
        <div className="accordionCenterLine">
          <div style={{ marginLeft: "1%" }}>
            <ButtonsContainer
              isModalVisible={props.visible}
              lpId={props.lpId}
              buttonList={newStreaming.buttons}
              handleButtonListChange={(buttonList) =>
                props.handleButtonListChange(buttonList)
              }
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default AddStreamingModal;
