import React from "react";

// Queries


const UserAudience = () => (
  <div>
    <br />
    <p> Audiencias </p>    
  </div>
);
export default UserAudience;
