import React, {useState} from "react";

import { ReactComponent as View } from '../../../assets/icons/navigation/view.svg';

import "./css/cardRecord.css";

//Localization
import { useTranslation } from 'react-i18next';

const CardRecord = () => {

  const { t, i18n } = useTranslation();

  return (
    <div className="containerCardRecord">
        <div className="titleCardRecord">
            Versión 2
            <View className="actionCardRecord"/>
        </div> 
        <hr className="dividerCardRecord"/>
        <div className="contentCardRecord">
            <div>newsletter.uhoo.io/template</div>
        </div>  
    </div>
  );  
};

export default CardRecord;
