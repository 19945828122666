import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual } from "lodash";

// components
import { ReactComponent as Close } from '../../../assets/icons/navigation/closeModal.svg';
import SecondaryButton from "../../../uhoo-components/buttons/secondaryButton.jsx";
import CheckUserSelect from '../components/checkUserSelect';
import SystemNotification from "./../../global/SystemNotification";

// request
import { getUser, createAudience, getConfigs, putConfigsById } from "./../../../actions"

// css
import "../css/creationAudience.css";

// contexts
import { UsersCommunityContext } from "./../contex";
import { ReactComponent as Search } from "../../../assets/icons/navigation/search.svg";


function ConfigureUser( props ) {
  /**
   * Props
   */
  let setModal = props.setOpenModalCreateAudience;
  let _listSteps = [
    { step: 0, name: "Configuración" }
  ]
  let PageSize = 10000;

    /**
   * Hooks
   */
  const { update, setUpdate } = useContext(UsersCommunityContext);
  

  /**
   * States
   */
  
  // creation
  const [ focusInputName, setFocusInputName ] = useState(false);
  const [ focusSearchValue, setFocusSearchValue ]  = useState(false);
  const [ name, setName ] = useState(null);
  const [ isPrivated, setIsPrivated ] = useState("false");
  const [ autoPublish, setAutoPublish ] = useState(false);
  const [ coments, setComents ] = useState(false);
  const [ likes, setLikes ] = useState(false);
  const [ leave, setLeave ] = useState(false);

  // members
  const [ allMenbers, setAllMenbers ] = useState("true");
  const [ members, setMembers ] = useState([]);

  // control
  const [ step, setStep ] = useState(0);
  const [ listSteps, setListSteps ] = useState(_listSteps);

  const [ page, setPage ] = useState(0);
  const [ totalPage, setTotalPage ] = useState(0);
  const [ users, setUsers ] = useState([]);
  const [ usersBase, setUsersBase ] = useState([]);
  const [ search, setSearch ] = useState('');
  const [configs, setConfigs] = useState({});
  const [loading, setLoading] = useState(false);

  // notify
  const [showNotificatioError, setShowNotificatioError] = useState(false);
  const [showNotificationWarning, setShowNotificationWarning] = useState(false);
  const [showNotificationSuccessfull, setShowNotificationSuccessfull] = useState(false);
  const [appPublicAudiencesEnabled, setAppPublicAudiencesEnabled] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState('');
  const { t, i18n } = useTranslation(); //  Localization
  let URLactual = window.location;
  URLactual = _get(URLactual, "host", "hub.bcodesolutions.com")
  let isAura = URLactual.includes("aura");

  /**
   * Efects
   */
  useEffect(async () => {
    loadUsers([ { key: "page", value: page } ])
    const _configs = await getConfigs();
    setConfigs(_configs)
    let _appPublicAudiencesEnabled = _get(_configs, 'data', []).find(config => config.name == 'app.public_audiences_enabled');
    _appPublicAudiencesEnabled = _get(_appPublicAudiencesEnabled, "value", 0);
    setAppPublicAudiencesEnabled(_appPublicAudiencesEnabled == 1)
  }, [])

  /**
   * Functions
   */
   const loadUsers = async (params) => {
    let _users = []
    let baseparams = [
      { key: "page_size", value: PageSize },
      { key: "active", value: 1 }
    ]
    try {
      _users = await getUser(baseparams.concat(params));
    } catch (error) {
      console.log("error", error);
      setShowNotificationMessage("Error al buscar los usuarios")
      setShowNotificatioError(true)
      return;
    }
    if(((_get(_users, 'page.page', '')+1) * PageSize) <= _get(_users, 'page.total', '')) {
      setPage(page + 1);
    } else {
      setPage(null);
    }
    setUsers( users.concat(_get(_users, 'data', [])) );
    setUsersBase(users.concat(_get(_users, 'data', [])))
    return _users;
  }
  const loadMore = async () => {
    let result = await loadUsers([ { key: "page", value: page + 1 } ])
    if(_get(result, 'page.page', '') * PageSize <= _get(result, 'page.total', '')) {
      setPage(page + 1);
    } else {
      setPage(null);
    }
  }
  const ButtonActionNext = async () => {
    // setLoading(true);
    if(step != listSteps.length - 1  && allMenbers == 'false') {
      setStep(1);
      return;
    }

    let params = {
      active: true,
      all_users: allMenbers == "true",
      allow_user_leave: leave,
      autopublish: autoPublish,
      comments_enabled: coments,
      description: "",
      likes_enabled: likes,
      mediums: {},
      name: name,
      private: 0,
    }

    if(allMenbers != "true") {
      if(members.length == 0){
        setShowNotificationMessage(t('basicWords.errorLengthUsersInAudiences'));
        setShowNotificationWarning(true);
        return;
      }
      params["users"] = members.map(u => u.uuid).reduce((a, v) => ({ ...a, [v]: true }), {})
    }
    
    let audiencefinal;
    try {
      audiencefinal = await createAudience(params);
      if(isPrivated == 'false'){
        let currentConfig = _get(configs, 'data', []).find(config => config.name == 'app.public_audiences');
        let publicAudiences = _get(currentConfig, "value", {});
        publicAudiences[audiencefinal.data[0].uuid] = {}
        let configToSave = {
          "uuid": _get(currentConfig, 'uuid', ''),
          "name": "app.public_audiences",
          "value": JSON.stringify(publicAudiences),
          "community": _get(currentConfig, 'community', ''),
          "properties": {},
          "editable": true
        }
    
        let result;
        try {
          result = await putConfigsById(_get(currentConfig, 'uuid', ''), configToSave);
        } catch (error) {
          setShowNotificatioError(true);
          return;
        }
      }
      setUpdate(!update);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setShowNotificationMessage("Error al crear la audiencia")
      setShowNotificatioError(true)
      return;
    }
    setShowNotificationMessage("Audiencia creada");
    setShowNotificationSuccessfull(true);
    setUpdate(!update)
    setModal(false)
   }

  const setMemberCheckbox = (user) => {
    let _members = members;
    let exist = members.find(_user => _user.uuid == user.uuid);
    if(!exist) {
      _members.push(user);
    } else {
      _members = _members.filter(_user => _user.uuid != user.uuid)
    }
    setMembers(_members);
  }
  const setAllowMembers = (status) => {
    let NewListSteps = _listSteps;
    if(status == "false") {
      NewListSteps.push({ step: 1, name: "Members" })
    }
    setListSteps(NewListSteps);
    setAllMenbers(status);
  }

  const onSearchValueChange = (event) => {
    let _search = event.target.value;
    let _users = [];
    if(_search != ""){
      usersBase.filter(user => {
        let fullName = ""
        try {
          fullName = _get(user, 'name', '').toLowerCase();
        } catch (error) {
          console.log(error)
        }
        if(fullName.includes(_search)){
          _users.push(user);
        }
      })
      console.log("_users", _users);
      setUsers(_users);
      setSearch(_search);
    }else{
      setUsers(usersBase);
    }
  };

  /**
   * Renders
   */

  const StepRender = (props) => (
    <React.Fragment>
      <div style={{ marginTop: '16px' }}>
        {
          listSteps.map((stp, index) => (
            <>
              <span class={ stp.step == props.current ? "text-step-current" : "text-step" }>{ stp.name }</span>
              { index != listSteps.length-1 ? <span className="arrow-step">{'>'}</span> : null }
            </>
          ))
        }
      </div>
    </React.Fragment>
  )
  const CreationAudience = () => (
    <React.Fragment>
      <StepRender current={0} />
      <div className="twoHalf">
        <div className="labelFormText">Nombre*</div>
        <input
          ref={(input) => {
            if (input && focusInputName) {
              input.focus();
            }
          }}
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={() => setFocusInputName(false)}
          onFocus={() => setFocusInputName(true)}
          className="inputContentModalNormal input_twoHalf"
        />
      </div>
      {
        isAura ? 
        <div className="twoHalf">
          <div className="labelFormText">Privacidad</div>
          <select id="private-audience" className="inputContentModalNormal input_twoHalf" value={isPrivated} onChange={(e) => setIsPrivated(e.target.value)}>
            <option selected value={"false"}>Pública</option>
            <option value={"true"}>Privada</option>
          </select>
        </div>
        :
        null
      }
      
      <div className="twoHalf">
        <div className="labelFormText">Miembros</div>
        <select id="members" className="inputContentModalNormal input_twoHalf" value={allMenbers} onChange={(e) => setAllowMembers(e.target.value)}>
          <option value={"true"}>Todos</option>
          <option value={"false"}>Específicos</option>
        </select>
      </div>
      <div className="twoHalf">
        <div className="labelFormText">Opciones de audiencia</div>
        <div style={{ marginTop: "10px" }}>
          <div style={{ marginTop: "5px" }}>
          {/* setAutoPublish(!autoPublish) */}
            <input type="checkbox" checked={autoPublish} onChange={(e) => setAutoPublish(!autoPublish)} />
            <label className="text-checkbox">Autopublicación</label>
          </div>
          <div style={{ marginTop: "5px" }}>
            <input type="checkbox" checked={coments} onChange={(e) => setComents(!coments)} />
            <label className="text-checkbox">Comentarios</label>
          </div>
          <div style={{ marginTop: "5px" }}>
            <input type="checkbox" checked={likes} onChange={(e) => setLikes(!likes)} />
            <label className="text-checkbox">Likes</label>
          </div>
          <div style={{ marginTop: "5px" }}>
            <input type="checkbox" checked={leave} onChange={(e) => setLeave(!leave)} />
            <label className="text-checkbox">Salida de usuarios</label>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
  const AddMembers = () => (
    <React.Fragment>
      <StepRender current={1} />
      <div className="twoHalf">
        <div className="userSearch">
          <Search className="icoCommunitySearch"/>
          <input
            // type="search"
            ref={(input) => {
              if (input && focusSearchValue) {
                input.focus();
              }
            }}
            onBlur={() => setFocusSearchValue(false)}
            onFocus={() => setFocusSearchValue(true)}
            onChange={(event) => onSearchValueChange(event)}
            className="inputSearch"
            defaultValue={search}
            placeholder={t("basicWords.searchAndFilter")}
          />
        </div>

        {
          users.map(user => {
            let exist = members.find(_user => _user.uuid == user.uuid);
            return (<CheckUserSelect checked={exist} change={(u) => setMemberCheckbox(u)} user={user} />)
          })
        }
        {
          page != null ?
            <div className="load-more" onClick={() => loadMore()}>
              <span> Cargar Mas Usuarios </span>
            </div>
          : null
        }
      </div>
    </React.Fragment>
  );

  return(
    <div className="modalConfigureUser">
      <div className="titleModalConfigureUser">
        <div className="textTitleModalConfigureUser">Crear Audiencia</div>
        <div className="closeModalConfigureUser" onClick={() => setModal(false)} role="button"><Close/></div>
        <hr className="lines"/>
      </div>

      <div className="contentModalConfigureUser">
        { step == 0 ? <CreationAudience /> : null }
        { step == 1 ? <AddMembers /> : null }
      </div>

      <div className="actionContentModalConfigureUser" >
        {step == 1 && listSteps.length == 2 ?
        <SecondaryButton txt={t('basicWords.back')} disabled={!name || loading} click={()=> {setStep(0); setSearch('');}} /> : null}
        <SecondaryButton
          txt={  step == 1 && listSteps.length == 2 ? "Crear audiencia" : ( allMenbers == "true" ? "Crear audiencia" : "Siguiente" ) }
          disabled={!name || loading}
          click={ButtonActionNext}
        />
      </div>


      {/*  Notifications  */}
      {!!showNotificationSuccessfull && (
        <SystemNotification
          setShowNotification={setShowNotificationSuccessfull}
          type="Success"
          caption={showNotificationMessage}
        />
      )}

      {!!showNotificationWarning && (
        <SystemNotification
          setShowNotification={setShowNotificationWarning}
          type="Warning"
          caption={showNotificationMessage}
        />
      )}

      {!!showNotificatioError && (
        <SystemNotification
          setShowNotification={setShowNotificatioError}
          type="Error"
          caption={showNotificationMessage}
        />
      )}
    </div>
  );
}

export default ConfigureUser;
