import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { get as _get } from "lodash";
import "../css/sections.scss";
import { Trash2, Plus, Edit2, X, Link, Download } from "react-feather";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import logoAura from "../../../../assets/images/logo.png";
import { useEffect, useRef } from "react";
import { Button } from "@material-ui/core";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Icon from "../../../../uhoo-components/iconLibrary.jsx";
import { generateUUID } from "../../../../reactUtils/generalUtils/generateUuid";

// Components
import {
  Title,
  ButtonComponent,
  BreadcrumbSection,
  GeneralInput,
  SelectMulti,
  DeleteItemModal,
  GeneralModal,
  AccordionComponent,
  DatePickerComponent,
  ButtonsFooterComponent,
} from "../../../../aura-components";
import editorConfiguration from "../../../../aura-components/textEditorConfig";

import SystemNotification from "../../../global/SystemNotification";
import ButtonsContainer from "../../../../aura-components/ButtonsContainer.jsx";
import {
  Card,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  Tooltip,
  TextField,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import SelectLayoutModal from "../../../../aura-components/SelectLayoutModal.jsx";

import QRCodeStyling from "qr-code-styling";

// Queries
import {
  insertSectionComponent,
  disableLpSectionComponent,
  updateSectionComponent,
  getDonationSectionByActiveState,
  getFile,
  updateLpSectionComponentNodeImage,
  getSectionLayoutStyle,
  updateLpSectionComponentNode,
  updateLpSectionLayout,
  updateLpSection,
  getLandingPageSections,
  getPaymentType,
} from "../../../../actions/aura";

import { backendDateFormat } from "../../../../reactUtils/systemVariables/generalVariables";
import { handleChangeImageFunction } from "../../../../reactUtils/generalUtils/handleImageChange.jsx";

//Language localization
import { withTranslation } from "react-i18next";
import { RadioButtonChecked, RadioButtonUnchecked } from "@material-ui/icons";

const tableSize = 64 + 70 + 15 + 20 + 62 + 157 + 64;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class DonationsSection extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      height: 0,

      lpSectionId: null,
      sectionName: null,
      landingPageId: null,
      organizationId: null,
      organizationName: null,
      country: null,
      region: null,
      city: null,

      activeState: 0,
      pageIdx: 1,
      pageSize: 4,
      totalElements: 0,
      donations: [],
      donationsToShow: [],

      loadingSection: true,
      loadingInfo: true,

      section: null,

      isAddModalVisible: false,
      isEditModalVisible: false,

      donationContainerTitle: {
        id: null,
        value: null,
        nodeTypeName: null,
        edited: false,
      },
      lpUrl: "",
      donationToAdd: {
        id: null,
        title: "",
        text: "",
        endDate: "-1",
        fixedValue: "-1",
        image: null,
        imageUri: null,
        imageLoading: false,
        type: 1,
        buttons: [
          {
            name: t("basicWords.donate"),
            externalLink: "PAYMENT_PLATFORM", //This indicates to buttonContainer component redirection to payment platforms
            isEnabled: 1,
          },
        ],
        questions: [],
      },
      donationToAddImage: {
        id: null,
        status: 0,
        s3path: null,
        imageUrl: null,
        image: null,
        loading: false,
        edited: false,
      },
      parentLPSectionComponentId: null,
      donationsToAdd: [],
      isLoadingButton: false,
      lpLayoutImg: null,
      FlagClassName: false,
      goToWithoutSaveModalVisible: false,
      donationTypes: [],
      donationCategory: [],

      //Modal notification
      haveChangeInLayout: false,

      // Modals
      showSuccess: false,
      showWarning: false,
      showError: false,
      contentNotificationModal: "",

      //Modal general
      openDeleteImage: false,
      openDeleteNews: false,
      elementToDeleteId: null,

      //LayoutSelector
      newLayoutId: null,
      oldLayoutId: null,

      isReadyToEdit: false,
      addDonation: false,
      deleteFlag: false,

      //Name modal
      openModalToChangeName: false,
      customName: "",
      community: { name: "" },
      showSecondQuestion: false,
    };
  }

  componentDidMount() {
    let _user = JSON.parse(sessionStorage.getItem("own"));
    let _community = _get(_user, "community", []);
    this.setState({ community: _community });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    const { location } = this.props;
    const { props } = location;
    const { activeState } = this.state;

    const { t } = this.props;

    let donationTypes = [
      { value: 0, label: t("basicWords.donations.types").all },
      { value: 1, label: t("basicWords.donations.types").open },
      { value: 2, label: t("basicWords.donations.types").closed },
      { value: 3, label: t("basicWords.donations.types").unlimited },
    ];
    if (props) {
      this.getDonationsInformation(
        props.landingPageId,
        props.sectionId,
        activeState
      );

      this.setState({
        lpSectionId: props.sectionId,
        sectionName: props.sectionName,
        landingPageId: props.landingPageId,
        organizationId: props.organizationId,
        organizationName: props.organizationName,
        country: props.country,
        region: props.region,
        city: props.city,
        donationTypes,
        previousPath: props.previousPath,
        name: props.sectionName,
        subpageId: props.subpageId,
        subpageFather: props.subpageFather,
        breadcrumb: props.breadcrumb,
        lpUrl: props.lpUrl,
      });
      this.handleSelectLayout();
      this.getCategories();
    } else {
      this.warning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - tableSize,
    });
  };

  // Queries
  async getDonationsInformation(lpId, lpSectionId, activeState) {
    this.setState({ loadingInfo: true });
    const response = await getDonationSectionByActiveState(
      lpId,
      lpSectionId,
      activeState
    );
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t(
          "basicWords.modal.internalServerErrorContactSupport"
        ),
      });
      this.setState({ isLoadingButton: false });
    } else {
      const sectionInfo = response.data.data;
      const donationContainerComponent = sectionInfo.components.find(
        (comp) => comp.name === "DonationsContainer"
      );
      const parentLPSectionComponentId = donationContainerComponent.id;
      const donationContainerTitle = {
        id: donationContainerComponent.nodes.find(
          (node) => node.nodeType === "DonationsContainerTitle"
        ).id,
        value: donationContainerComponent.nodes.find(
          (node) => node.nodeType === "DonationsContainerTitle"
        ).value,
        nodeTypeName: donationContainerComponent.nodes.find(
          (node) => node.nodeType === "DonationsContainerTitle"
        ).nodeType,
        minLength: donationContainerComponent.nodes.find(
          (node) => node.nodeType === "DonationsContainerTitle"
        ).minLength,
        maxLength: donationContainerComponent.nodes.find(
          (node) => node.nodeType === "DonationsContainerTitle"
        ).maxLength,
        length: donationContainerComponent.nodes.find(
          (node) => node.nodeType === "DonationsContainerTitle"
        ).value.length,
      };

      let donations = sectionInfo.components.filter(
        (comp) => comp.name === "DonationComponent"
      );
      donations = donations.sort(this.compareDonationDates);

      const totalElements = donations.length;

      this.setChildComponentsInfoPaged(donations);

      //Get layout image
      let supgage = null;
      if (this.state.subpageId && this.state.subpageId !== 10001) {
        supgage = sectionInfo.subpages.find(
          (sp) => sp.id === this.state.subpageId
        );
      } else {
        supgage = sectionInfo.subpages.find((sp) => sp.id === null);
      }
      let subpageIndex = sectionInfo.subpages.indexOf(supgage);
      if (subpageIndex === -1) {
        subpageIndex = 0;
      }
      let layout =
        response.data.data.subpages[subpageIndex].lpSectionLayoutStyle;
      let layoutImg = null;
      if (layout) {
        layoutImg = await getFile(
          "https://somosaura-cms.s3.amazonaws.com/" + layout.s3ImagePath
        );
      }

      let customName =
        response.data.data.customName !== null
          ? response.data.data.customName
          : this.state.sectionName;

      this.setState({
        customName,
        donationContainerTitle,
        donations,
        totalElements,
        parentLPSectionComponentId,
        loadingInfo: false,
        lpSectionLayoutStyle: layout,
        lpLayoutImg: layoutImg,
      });
    }
  }

  async getCategories() {
    let response = await getPaymentType();
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t(
          "basicWords.modal.internalServerErrorContactSupport"
        ),
      });
    } else {
      let responseData = response.data.data;
      let donationCategory = responseData.map(function (row) {
        return { value: row.id, name: row.name, id: row.id };
      });
      this.setState({ donationCategory });
    }
  }

  async setChildComponentsInfoPaged(donations) {
    const donationsList = [];
    for (let i = 0; i < donations.length; i++) {
      if (!donations[i].img) {
        const imgNode = donations[i].nodes.find(
          (node) => node.nodeType === "DonationComponentImage"
        );
        if (imgNode) {
          if (imgNode.value != null && imgNode.value !== "") {
            let regex = /somosaura-cms.s3.amazonaws.com/;
            let haveHttp = regex.test(imgNode.value);
            if (!haveHttp) {
              // Get image
              let img = await getFile(
                "https://somosaura-cms.s3.amazonaws.com/" + imgNode.value
              );
              donations[i].img = img;
            } else {
              donations[i].img = imgNode.value;
            }
          } else {
            donations[i].img = null;
          }
        }
      }
      donationsList.push(donations[i]);
    }

    const donationsToShow = [];
    if (donationsList.length > 0) {
      const { location } = this.props;
      const { props } = location;
      let _user = JSON.parse(sessionStorage.getItem("own"));
      let _community = _get(_user, "community", []);
      const organizationId = _get(_community, "uuid", 1);
      let responseOrganization = await getLandingPageSections(organizationId);
      this.setState({ lpUrl: responseOrganization.data.data.lpUrl });
    }
    donationsList.forEach((element) => {
      const donation = {
        id: element.id,
        showQR: false,
        titleId: element.nodes.find(
          (node) => node.nodeType === "DonationComponentTitle"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentTitle"
            ).id
          : null,
        title: element.nodes.find(
          (node) => node.nodeType === "DonationComponentTitle"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentTitle"
            ).value
          : null,
        textId: element.nodes.find(
          (node) => node.nodeType === "DonationComponentText"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentText"
            ).id
          : null,
        text: element.nodes.find(
          (node) => node.nodeType === "DonationComponentText"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentText"
            ).value
          : null,
        endDateId: element.nodes.find(
          (node) => node.nodeType === "DonationComponentEndDate"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentEndDate"
            ).id
          : null,
        endDate: element.nodes.find(
          (node) => node.nodeType === "DonationComponentEndDate"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentEndDate"
            ).value
          : null,
        fixedValueId: element.nodes.find(
          (node) => node.nodeType === "DonationComponentFixedValue"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentFixedValue"
            ).id
          : null,
        fixedValue: element.nodes.find(
          (node) => node.nodeType === "DonationComponentFixedValue"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentFixedValue"
            ).value
          : null,
        questionsId: element.nodes.find(
          (node) => node.nodeType === "DonationFormRule"
        )
          ? element.nodes.find((node) => node.nodeType === "DonationFormRule")
              .id
          : null,
        questions: element.nodes.find(
          (node) => node.nodeType === "DonationFormRule"
        )
          ? JSON.parse(
              element.nodes.find((node) => node.nodeType === "DonationFormRule")
                .value
            )
          : null,
        // donationSuccessTitleId: element.nodes.find( (node) => node.nodeType === "DonationSuccessTitle")
        //   ? element.nodes.find( (node) => node.nodeType === "DonationSuccessTitle").id : null,
        // donationSuccessTitle: element.nodes.find( (node) => node.nodeType === "DonationSuccessTitle"
        // )? element.nodes.find( (node) => node.nodeType === "DonationSuccessTitle").value: null,

        // donationSuccessTextId: element.nodes.find( (node) => node.nodeType === "DonationSuccessText")
        //   ? element.nodes.find( (node) => node.nodeType === "DonationSuccessText").id : null,
        // donationSuccessText: element.nodes.find( (node) => node.nodeType === "DonationSuccessText"
        // )? element.nodes.find( (node) => node.nodeType === "DonationSuccessText").value: null,

        // donationSuccessImageId: element.nodes.find( (node) => node.nodeType === "DonationSuccessImage")
        //   ? element.nodes.find( (node) => node.nodeType === "DonationSuccessImage").id : null,
        // donationSuccessImage: element.nodes.find( (node) => node.nodeType === "DonationSuccessImage"
        // )? element.nodes.find( (node) => node.nodeType === "DonationSuccessImage").value: null,

        imageId: element.nodes.find(
          (node) => node.nodeType === "DonationComponentImage"
        )
          ? element.nodes.find(
              (node) => node.nodeType === "DonationComponentImage"
            ).id
          : null,
        image: element.img,
        imageUri: element.img,
        buttons: element.buttons,
        edited: false,
        type: element.paymentTypeId ? element.paymentTypeId : null,
      };
      donationsToShow.push(donation);
    });
    donationsToShow.forEach((element, index) => {
      if (element.endDate !== "-1") {
        donationsToShow[index].endDate = moment(element.endDate).subtract(
          5,
          "hours"
        );
        donationsToShow[index].endDate = moment(
          donationsToShow[index].endDate._d
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      // element.type = "";
    });
    this.setState({
      donationsToShow,
      loadingSection: false,
    });
    this.cleanStateVariables();
  }

  async updateLpSection(LpSubpageDto) {
    let response = await updateLpSection(LpSubpageDto);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t(
          "basicWords.modal.internalServerErrorContactSupport"
        ),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t(
          "basicWords.modal.positionSectionSuccessfullyModified"
        ),
      });
    }
    this.setState({
      newCustomName: "",
      customName: LpSubpageDto.customName,
      openModalToChangeName: false,
    });
  }

  async uploadFileToS3Bucket(imageUrl, key) {
    await updateLpSectionComponentNodeImage(imageUrl, key);
  }

  async insertSectionComponentFunction(nodesToUpdate) {
    const { landingPageId, lpSectionId, activeState } = this.state;
    const response = await insertSectionComponent(nodesToUpdate);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t(
          "basicWords.modal.internalServerErrorContactSupport"
        ),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t(
          "basicWords.modal.donationSuccessfullyCreated"
        ),
      });
      this.cleanStateVariables();
      this.getDonationsInformation(landingPageId, lpSectionId, activeState);
    }

    this.setState({
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  }

  async updateSectionComponentFunction(componentToUpdate) {
    const { landingPageId, lpSectionId, activeState } = this.state;
    const response = await updateSectionComponent(componentToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t(
          "basicWords.modal.internalServerErrorContactSupport"
        ),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t(
          "basicWords.modal.donationSuccessfullyEdited"
        ),
      });

      this.cleanStateVariables();
      this.getDonationsInformation(landingPageId, lpSectionId, activeState);
    }

    this.setState({
      isLoadingButton: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  }

  async disableComponentFunction(id) {
    const { landingPageId, lpSectionId, activeState } = this.state;
    const response = await disableLpSectionComponent(id);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t(
          "basicWords.modal.internalServerErrorContactSupport"
        ),
        isLoadingButton: false,
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t(
          "basicWords.modal.donationSuccessfullyDisabled"
        ),
        isLoadingButton: false,
      });

      this.getDonationsInformation(landingPageId, lpSectionId, activeState);
    }
  }

  async getLayoutFunction(lpId) {
    let response = await getSectionLayoutStyle(lpId, 12);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t(
          "basicWords.modal.internalServerErrorContactSupport"
        ),
      });
    } else {
      let responseData = response.data.data;
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].sampleLayoutImageS3Path) {
          //Get image
          let img = await getFile(
            "https://somosaura-cms.s3.amazonaws.com/" +
              responseData[i].sampleLayoutImageS3Path
          );
          responseData[i].img = img;
        }
      }
      this.setState({
        layoutData: responseData,
      });
    }
  }

  async updateNode(nodesToUpdate) {
    const { landingPageId, lpSectionId, activeState } = this.state;

    const response = await updateLpSectionComponentNode(nodesToUpdate);
    const { t } = this.props;

    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t(
          "basicWords.modal.internalServerErrorContactSupport"
        ),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t(
          "basicWords.modal.donationSuccessfullyEdited"
        ),
      });
      this.getDonationsInformation(landingPageId, lpSectionId, activeState);
    }
    this.setState({ isLoadingButton: false });
  }

  async updateLpSectionLayoutFunction(lpSectionId, newLayoutId) {
    let response = await updateLpSectionLayout(lpSectionId, newLayoutId);
    const { t } = this.props;
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t(
          "basicWords.modal.internalServerErrorContactSupport"
        ),
      });
    } else {
      this.setState({
        showSuccess: true,
        contentNotificationModal: t(
          "basicWords.modal.positionSectionSuccessfullyModified"
        ),
      });
    }
    this.setState({ isLoadingButton: false });
  }

  compareDonationDates(a, b) {
    const aDateStr = a.nodes.find(
      (node) => node.nodeType === "DonationComponentEndDate"
    ).value;
    const bDateStr = b.nodes.find(
      (node) => node.nodeType === "DonationComponentEndDate"
    ).value;
    const aDate = moment(aDateStr, backendDateFormat);
    const bDate = moment(bDateStr, backendDateFormat);
    if (bDate.isAfter(aDate)) {
      return -1;
    }
    if (aDate.isAfter(bDate)) {
      return 1;
    }
    return 0;
  }

  addDonationEvent = () => {
    let { donationToAdd } = this.state;
    this.setState({
      isAddModalVisible: true,
      donationToAdd,
    });
  };

  editDonationEvent = (e, row) => {
    const donationToAdd = { ...row };

    const donationToAddImage = {
      id: donationToAdd.imageId,
      status: 0,
      s3path: null,
      imageUrl: null,
      image: donationToAdd.image,
      loading: false,
    };
    this.setState({
      isEditModalVisible: true,
      donationToAdd,
      donationToAddImage,
    });
  };

  handleChangeLayout = (e, id) => {
    this.setState({
      saveButtonDisabled: false,
      FlagClassName: true,
      lpSectionLayoutStyle: id,
      haveChangeInLayout: true,
    });
  };

  renderInputImage = (element, index) => {
    const { openDeleteImage } = this.state;
    const { t } = this.props;
    return (
      <>
        <div className="cardImagesSquare">
          <div className="row">
            <div className="iconXCardImagesSquare">
              <a>
                <X
                  className="iconImageEditInSectionSquare"
                  onClick={this.handleClickOpenDialog}
                />
              </a>
            </div>
          </div>

          <div className="row">
            {/* Tooltip image */}
            <Tooltip title={t("generalMessages.imagesFormat")}>
              <div className="centerImage">
                <input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(e) => this.handleChangeImage(e, index)}
                  hidden
                />
                <label htmlFor="contained-button-file">
                  <div className="imageEdit">
                    <div>
                      {element.image != null ? (
                        <img
                          src={element.imageUri}
                          alt="avatar"
                          className="widthAndHeightImageSectionSquare"
                          onClick={() => this.handleImageIndex(index)}
                        />
                      ) : (
                        <Plus
                          className="imageEdit"
                          onClick={() => this.handleImageIndex(index)}
                        />
                      )}

                      <GeneralModal
                        visible={openDeleteImage}
                        title={t("basicWords.deleteItem")}
                        footer={[
                          <div className="row" style={{ display: "flex" }}>
                            <div className="col-6">
                              <ButtonComponent
                                onClick={() => this.handleCloseDialog()}
                                props={null}
                                shape="round"
                                text={t("basicWords.cancelText")}
                              />
                            </div>
                            <div>
                              <ButtonComponent
                                onClick={() => this.disableImage(element)}
                                props={null}
                                shape="round"
                                text={t("basicWords.okText")}
                              />
                            </div>
                          </div>,
                        ]}
                        onClick={() => this.handleCloseDialog()}
                      />
                    </div>
                  </div>
                </label>
              </div>
            </Tooltip>
          </div>
        </div>
      </>
    );
  };

  handleChangeQuestion = (e, pos, edited, donationId) => {
    let { donationsToShow, donationToAdd } = this.state;
    if (edited) {
      donationsToShow.forEach((element, index) => {
        if (element.id === donationId) {
          donationsToShow[index].questions[pos].question = e.target.value;
          donationsToShow[index].edited = true;
        }
      });
      this.setState({ donationsToShow, FlagClassName: true });
    } else {
      donationToAdd.questions[pos].question = e.target.value;
      this.setState({ donationToAdd });
    }
  };

  onChangeObligatory = (e, pos, donation) => {
    let { donationsToShow, donationToAdd } = this.state;
    let valueSwitch = e.target.checked;
    if (donation) {
      donationsToShow.forEach((element, index) => {
        if (element.id === donation.id) {
          donationsToShow[index].questions[pos].obligatory = valueSwitch;
          donationsToShow[index].edited = true;
        }
      });
      this.setState({ donationsToShow, FlagClassName: true });
    } else {
      donationToAdd.questions[pos].obligatory = valueSwitch;
      this.setState({ donationToAdd });
    }
  };

  onChangeTypeQuestion = (event, pos, donation) => {
    let { donationsToShow, donationToAdd } = this.state;
    const { target } = event;
    let optionToAdd = target && target.value ? target.value : [];
    if (donation) {
      donationsToShow.forEach((element, index) => {
        if (element.id === donation.id) {
          donationsToShow[index].questions[pos].type = optionToAdd;
          donationsToShow[index].edited = true;
        }
      });
      this.setState({ donationsToShow, FlagClassName: true });
    } else {
      donationToAdd.questions[pos].type = optionToAdd;
      this.setState({ donationToAdd });
    }
  };

  onEditOptionQuestion = (e, posQuestion, posOptionQuestion, donation) => {
    let { donationsToShow, donationToAdd } = this.state;
    if (donation) {
      donationsToShow.forEach((element, index) => {
        if (element.id === donation.id) {
          donationsToShow[index].questions[posQuestion].options[
            posOptionQuestion
          ] = e.target.value;
          donationsToShow[index].edited = true;
        }
      });
      this.setState({ donationsToShow, FlagClassName: true });
    } else {
      donationToAdd.questions[posQuestion].options[posOptionQuestion] =
        e.target.value;
      this.setState({ donationToAdd });
    }
  };

  onAddOptionQuestion = (type, posQuestion, donation) => {
    let { donationsToShow, donationToAdd } = this.state;
    if (donation) {
      donationsToShow.forEach((element, index) => {
        if (element.id === donation.id) {
          if (type == "add") {
            if (donationsToShow[index].questions[posQuestion].options) {
              donationsToShow[index].questions[posQuestion].options.push("");
            } else {
              donationsToShow[index].questions[posQuestion].options = [];
              donationsToShow[index].questions[posQuestion].options.push("");
            }
            donationsToShow[index].edited = true;
          } else if (type == "subtract") {
            if (donationsToShow[index].questions[posQuestion].options) {
              donationsToShow[index].questions[posQuestion].options.pop();
            } else {
              donationsToShow[index].questions[posQuestion].options = [];
            }
          }
        }
      });
      this.setState({ donationsToShow, FlagClassName: true });
    } else {
      if (type == "add") {
        donationToAdd.questions[posQuestion].options.push("");
      } else if (type == "subtract") {
        donationToAdd.questions[posQuestion].options.pop();
      }
      this.setState({ donationToAdd });
    }
  };

  onAddQuestion = (donation) => {
    let { donationsToShow, donationToAdd } = this.state;
    let newQuestion = {
      question: "",
      type: "",
      options: [""],
      obligatory: false,
      uuid: generateUUID(),
    };
    if (donation) {
      donationsToShow.forEach((element, index) => {
        if (element.id === donation.id) {
          if (donationsToShow[index].questions) {
            donationsToShow[index].questions.push(newQuestion);
          } else {
            donationsToShow[index].questions = [];
            donationsToShow[index].questions.push(newQuestion);
          }
        }
      });
      this.setState({ donationsToShow, FlagClassName: true });
    } else {
      donationToAdd.questions.push(newQuestion);
      this.setState({ showSecondQuestion: true });
    }
  };

  onCloseSecondQuestion = (donation) => {
    let { donationsToShow, donationToAdd } = this.state;
    if (donation) {
      donationsToShow.forEach((element, index) => {
        if (element.id === donation.id) {
          if (donationsToShow[index].questions) {
            donationsToShow[index].questions.pop();
            donationsToShow[index].edited = true;
          } else {
            donationsToShow[index].edited = true;
            donationsToShow[index].questions = [];
          }
        }
      });
      this.setState({
        showSecondQuestion: false,
        donationsToShow,
        FlagClassName: true,
      });
    } else {
      donationToAdd.questions.pop();
      this.setState({ showSecondQuestion: false, donationToAdd });
    }
  };

  renderAddQuestion() {
    const { t } = this.props;
    const { donationToAdd, showSecondQuestion } = this.state;
    const options = [
      { value: t("basicWords.form.text"), id: "text" },
      { value: t("basicWords.form.mutiple"), id: "multiple" },
      { value: t("basicWords.form.select"), id: "select" },
    ];
    return (
      <div className="containerQuestions">
        {donationToAdd.questions.map((qst, index) => {
          return (
            <>
              {/* obligatory  */}
              <div className="containerResponseQuestion">
                <div />
                <div
                  style={{
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                    gap: "15px",
                  }}
                >
                  <div className="containerSwitchObligatory">
                    <label className="switch">
                      <input
                        type="checkbox"
                        value={qst.obligatory}
                        onChange={(e) => this.onChangeObligatory(e, index)}
                      />
                      <span className="slider round"></span>
                    </label>
                    <p>{t("basicWords.obligatory")}</p>
                  </div>
                  <div className="containerAddQuestion">
                    <div className="bgAddButton">
                      <Button
                        type="primary"
                        shape="circle"
                        className="addButton"
                        onClick={() => this.onCloseSecondQuestion()}
                      >
                        <Icon.trash />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {/* question  */}
              <div className="containerQuestionAndType">
                <div style={{ width: "50%", margin: "0px 5px" }}>
                  <GeneralInput
                    className="textInputWidth"
                    placeholder={t("basicWords.form.enterQuestion")}
                    id="title"
                    onChange={(e) => this.handleChangeQuestion(e, index)}
                    value={qst.question}
                  />
                </div>
                <div
                  style={{
                    width: "50%",
                    top: "-4px",
                    position: "relative",
                    margin: "0px 5px",
                  }}
                >
                  <FormControl sx={{ width: 300 }}>
                    <InputLabel
                      style={{ "margin-left": "10px", "margin-top": "-2px" }}
                    >
                      Type
                    </InputLabel>
                    <Select
                      id="demo-multiple-checkbox"
                      value={qst.type}
                      onChange={(e) => this.onChangeTypeQuestion(e, index)}
                      input={<OutlinedInput label="Tag" />}
                    >
                      {options.map((op) => (
                        <MenuItem key={op.id} value={op.id}>
                          {/* <Checkbox checked={qst.type.indexOf(op.id) > -1} /> */}
                          <ListItemText primary={op.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="containerResponseQuestion">
                <p> {t("basicWords.form.ansOptions")}</p>
              </div>
              {/* response */}
              <div className="accordionCenterLine">
                <div
                  style={{
                    width: "100%",
                    marginLeft: "1%",
                    marginRight: "0.7%",
                    marginBottom: "15px",
                  }}
                >
                  {qst.type === "text" ? (
                    <GeneralInput
                      className="textInputWidth"
                      placeholder={t("basicWords.form.textDescription")}
                      id="title"
                      value={""}
                      disabled={true}
                    />
                  ) : null}
                  {qst.type === "multiple" ? (
                    <>
                      <FormControl>
                        {qst.options.map((option, indexOption) => {
                          return (
                            <div className="containerSelect">
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  value={true}
                                  control={
                                    <RadioButtonUnchecked value={false} />
                                  }
                                />
                                {/* <Checkbox /> */}
                              </RadioGroup>
                              <TextField
                                id="input-standard-basic"
                                style={{ width: "90%" }}
                                onChange={(e) =>
                                  this.onEditOptionQuestion(
                                    e,
                                    index,
                                    indexOption
                                  )
                                }
                                value={option}
                              />
                              {indexOption != 0 ? (
                                <Button
                                  type="primary"
                                  shape="circle"
                                  className="addButton"
                                  onClick={() =>
                                    this.onAddOptionQuestion("subtract", index)
                                  }
                                >
                                  x
                                </Button>
                              ) : null}
                            </div>
                          );
                        })}
                      </FormControl>
                      <div className="accordionCenterLine">
                        <div className="bgAddButton">
                          <Button
                            type="primary"
                            shape="circle"
                            className="addButton"
                            onClick={() =>
                              this.onAddOptionQuestion("add", index)
                            }
                          >
                            +
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {qst.type === "select" ? (
                    <>
                      <FormControl>
                        {qst.options.map((option, indexOption) => {
                          return (
                            <div className="containerSelect">
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  value={true}
                                  control={<Checkbox value={false} />}
                                />
                              </RadioGroup>
                              <TextField
                                id="input-standard-basic"
                                style={{ width: "90%" }}
                                onChange={(e) =>
                                  this.onEditOptionQuestion(
                                    e,
                                    index,
                                    indexOption
                                  )
                                }
                                value={option}
                              />
                              {indexOption !== 0 ? (
                                <Button
                                  type="primary"
                                  shape="circle"
                                  className="addButton"
                                  onClick={() =>
                                    this.onAddOptionQuestion("subtract", index)
                                  }
                                >
                                  x
                                </Button>
                              ) : null}
                            </div>
                          );
                        })}
                      </FormControl>
                      <div className="accordionCenterLine">
                        <div className="bgAddButton">
                          <Button
                            type="primary"
                            shape="circle"
                            className="addButton"
                            onClick={() =>
                              this.onAddOptionQuestion("add", index)
                            }
                          >
                            +
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </>
          );
        })}

        <div className="containerAddQuestion">
          <div className="bgAddButton">
            <Button
              type="primary"
              shape="circle"
              className="addButton"
              onClick={() => this.onAddQuestion()}
            >
              +
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderAddDonations() {
    const { donationToAdd, landingPageId, donationCategory } = this.state;
    const { t } = this.props;

    return (
      <div>
        <AccordionComponent
          title={t("basicWords.newDonation")}
          className="accordionAdd"
          body={[
            <div className="accordionContent">
              {/* Cero Line */}

              <div className="accordionCenterLine">
                <div
                  style={{
                    width: "100%",
                    marginLeft: "1%",
                    marginRight: "0.7%",
                  }}
                >
                  {/* <div className="textTypeDonation">
                    {t("basicWords.buttons.buttons")}
                  </div>
                  <br /> */}
                  <FormControl sx={{ width: 300 }}>
                    <InputLabel
                      style={{ "margin-left": "10px", "margin-top": "-2px" }}
                    >
                      Type
                    </InputLabel>
                    <Select
                      id="demo-multiple-checkbox"
                      value={donationToAdd.type}
                      onChange={(e) => this.onChangeTypeDonation(e)}
                      input={<OutlinedInput label="Tag" />}
                    >
                      {donationCategory.map((op) => (
                        <MenuItem key={op.id} value={op.id}>
                          <ListItemText
                            primary={t(`basicWords.donation.type.${op.name}`)}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <br />
              <br />

              {/* First Line */}
              <div className="accordionCenterLine">
                <div
                  style={{
                    width: "100%",
                    marginLeft: "1%",
                    marginRight: "0.7%",
                  }}
                >
                  <GeneralInput
                    className="textInputWidth"
                    placeholder={
                      t("basicWords.enterTitle") +
                      " " +
                      t("basicWords.ofDonations")
                    }
                    id="title"
                    onChange={(e) => this.handleChange(e, "", "", "", null)}
                    value={donationToAdd.title}
                  />
                  {donationToAdd.title.value &&
                  donationToAdd.title.maxLength ? (
                    <span>
                      {donationToAdd.title.length} /{" "}
                      {donationToAdd.title.maxLength}
                    </span>
                  ) : null}
                </div>
              </div>

              {/* Second Line */}
              <br />
              <div className="accordionCenterLine">
                <div style={{ width: "100%", marginRight: "2%" }}>
                  <ReactQuill
                    className="accordionCompleteInputs"
                    onChange={(e) => this.handleChangeDescription(e, null)}
                    value={donationToAdd.text}
                    modules={editorConfiguration.modules}
                    formats={editorConfiguration.formats}
                    placeholder={
                      t("basicWords.description") +
                      " " +
                      t("basicWords.ofDonations")
                    }
                  />
                </div>
              </div>
              <br />

              {/* Third line */}
              <div className="accordionCenterLine">
                <div
                  className="textLogo"
                  style={{ marginLeft: "1%", marginRight: "87%" }}
                ></div>
                <div className="textLogo">{t("basicWords.value")}</div>
              </div>

              {/* Fourth line */}

              <div className="accordionCenterLine">
                <div
                  style={{ width: "35%", marginRight: "1%", marginLeft: "1%" }}
                >
                  <DatePickerComponent
                    string={t("basicWords.endDateDonation")}
                    defaultValue={donationToAdd.endDate}
                    onChange={(e) => this.changeDateHandleEvent(e, null)}
                    disabled={donationToAdd.endDate === "-1"}
                  />
                </div>
                <div
                  style={{ width: "10%", marginRight: "1%", marginLeft: "1%" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        id="unlimitedEndDate"
                        style={{ textAlign: "center" }}
                        checked={donationToAdd.endDate === "-1"}
                        onChange={(e) => this.handleChange(e)}
                      />
                    }
                    label={t("basicWords.unlimitedDate")}
                  />
                </div>
                <div
                  style={{ width: "35%", marginRight: "1%", marginLeft: "1%" }}
                >
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <OutlinedInput
                      id="fixedValue"
                      value={
                        donationToAdd.fixedValue === "-1"
                          ? "El usuario definirá el valor"
                          : donationToAdd.fixedValue
                      }
                      onChange={(e) => this.handleChange(e)}
                      startAdornment={
                        <InputAdornment position="start">
                          <AttachMoneyIcon
                            sx={{ color: "green", fontSize: 20 }}
                          ></AttachMoneyIcon>
                        </InputAdornment>
                      }
                      disabled={donationToAdd.fixedValue === "-1"}
                    />
                  </FormControl>
                </div>
                <div
                  style={{ width: "10%", marginRight: "1%", marginLeft: "1%" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        id="voluntaryValue"
                        style={{ textAlign: "center" }}
                        checked={donationToAdd.fixedValue === "-1"}
                        onChange={(e) => this.handleChange(e)}
                      />
                    }
                    label={t("basicWords.valueDefectByUser")}
                  />
                </div>
              </div>

              {/* Image */}
              <br />
              <div className="accordionCenterLine">
                <div className="textLogo">{t("basicWords.image")}</div>
                {this.renderInputImage(donationToAdd, null)}
              </div>

              {/* buttons */}
              <br />
              <div className="accordionCenterLine">
                <div className="textLogo" style={{ marginLeft: "1%" }}>
                  {t("basicWords.buttons.buttons")}
                </div>
              </div>
              <br />
              <div className="accordionCenterLine">
                <div style={{ marginLeft: "1%" }}>
                  <ButtonsContainer
                    isModalVisible={true}
                    lpId={landingPageId}
                    buttonList={donationToAdd.buttons}
                    handleButtonListChange={(buttonList) =>
                      this.handleButtonListChange(buttonList, donationToAdd.id)
                    }
                  />
                </div>
              </div>
              {/* Questions*/}
              <br />
              <div className="accordionCenterLine">
                <div className="textLogo" style={{ marginLeft: "1%" }}>
                  {t("basicWords.additionalQuestions")}
                </div>
              </div>

              <div className="accordionCenterLine">
              <div className="textDescription" style={{ margin: "1rem" }}>
                  {t("basicWords.form.formDescription")}
                </div>
              </div>

              <br />
              {this.renderAddQuestion()}
            </div>,
          ]}
        />
        <br />
      </div>
    );
  }

  handleClickOpenDeleteImage = () => {
    this.setState({ openDeleteImage: true });
  };

  handleCloseDeleteImage = () => {
    this.setState({ openDeleteImage: false });
  };

  handleClickOpenDeleteNews = (id) => {
    this.setState({ openDeleteNews: true, elementToDeleteId: id });
  };

  handleCloseDeleteNews = () => {
    this.setState({ openDeleteNews: false });
  };

  changeToEdit = (e) => {
    e.stopPropagation();
    const { isReadyToEdit } = this.state;
    this.setState({ isReadyToEdit: !isReadyToEdit });
  };

  disableDonationEvent = (id) => {
    this.setState({
      openDeleteNews: false,
      loadingInfo: true,
    });
    this.disableComponentFunction(id);
  };

  warning = () => {
    const { t } = this.props;
    this.setState({
      showWarning: true,
      contentNotificationModal: t("basicWords.modal.noSectionSelected"),
    });
    this.handleOkWarning();
  };

  handleOkWarning = () => {
    this.setState({
      redirectBack: true,
    });
  };

  disableImage = (element) => {
    const {
      landingPageId,
      lpSectionId,
      donationsToShow,
      imageIndex,
      activeState,
      donationToAdd,
    } = {
      ...this.state,
    };

    if (element !== null) {
      donationsToShow[imageIndex].image = null;
      donationsToShow[imageIndex].edited = true;
    } else {
      donationToAdd.image = null;
    }

    const nodesToUpdate = [];
    const node = {};
    node.id = element.id;
    node.value = "";
    node.image = "";
    node.nodeType = "IMAGE";

    nodesToUpdate.push(node);
    this.getDonationsInformation(landingPageId, lpSectionId, activeState);
    this.updateNode(nodesToUpdate);

    this.setState({
      isLoadingButton: true,
      deleteFlag: true,
      FlagClassName: false,
    });

    this.handleCloseDialog();
  };

  handleClickOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  // Add donation modal donations
  onCloseModal = () => {
    this.cleanStateVariables();

    this.setState({
      isAddModalVisible: false,
      isEditModalVisible: false,
    });
  };

  handleChangeDescription(html, element) {
    if (element != null) {
      this.handleChange(html, "text", "", true, element.id);
    } else {
      this.handleChange(html, "text", "", true, null);
    }
  }

  onChangeTypeDonation = (event, donation) => {
    let { newDonation, donationsToShow, donationToAdd } = this.state;
    let isNewDonation = true;
    let donationId = donation && donation.id ? donation.id : null;
    const { target } = event;
    let optionToAdd = target && target.value ? target.value : [];
    donationsToShow.forEach((element, index) => {
      if (element.id === donationId) {
        isNewDonation = false;
        if (donationsToShow[index].type) {
          donationsToShow[index].type = optionToAdd;
        } else {
          donationsToShow[index].type = "";
          donationsToShow[index].type = optionToAdd;
        }
        donationsToShow[index].edited = true;
      }
    });

    if (isNewDonation) {
      donationToAdd.type = optionToAdd;
    }

    this.setState({ donationToAdd, donationsToShow, FlagClassName: true });
  };

  handleChange = (e, dataIndex, data, flagRichText, donationId) => {
    const { donationContainerTitle, donationToAdd, donationsToShow } = {
      ...this.state,
    };

    var id = null;
    var value = null;
    var checked = null;

    if (e && e.target) {
      id = e.target.id;
      value = e.target.value;
      checked = e.target.checked;
    }
    if (flagRichText) {
      id = dataIndex;
      value = e;
    }

    let isNewDonation = true;

    donationsToShow.forEach((element, index) => {
      if (element.id === donationId) {
        isNewDonation = false;
        if (id === "title") {
          donationsToShow[index].title = value;
        }
        if (id === "text") {
          donationsToShow[index].text = value;
        }
        switch (id) {
          case "unlimitedEndDate":
            if (!checked) {
              donationsToShow[index].endDate = moment().add(1, "months");
            } else {
              donationsToShow[index].endDate = "-1";
            }
            break;
          case "voluntaryValue":
            if (!checked) {
              donationsToShow[index].fixedValue = value;
            } else {
              donationsToShow[index].fixedValue = "-1";
            }
            break;

          default:
            donationsToShow[id] = value;
            break;
        }
        donationsToShow[index].edited = true;
      }
    });
    if (isNewDonation) {
      // Check if the changed attribute requires a particular management or is set by default
      switch (id) {
        case "unlimitedEndDate":
          if (!checked) {
            donationToAdd.endDate = moment().add(1, "months");
          } else {
            donationToAdd.endDate = "-1";
          }
          break;
        case "voluntaryValue":
          if (!checked) {
            donationToAdd.fixedValue = 10000;
          } else {
            donationToAdd.fixedValue = "-1";
          }
          break;
        case "DonationsContainerTitle":
          if (value.length <= donationContainerTitle.maxLength) {
            donationContainerTitle.value = value;
            donationContainerTitle.edited = true;
            donationContainerTitle.length = value.length;
            this.setState({
              FlagClassName: true,
            });
          }
          break;
        default:
          donationToAdd[id] = value;
          break;
      }
    }

    this.setState({
      donationToAdd,
      donationsToShow,
      donationContainerTitle,
      FlagClassName: true,
    });
  };

  handleDonationType = (e) => {
    const { landingPageId, lpSectionId } = this.state;
    const { value } = e;
    this.getDonationsInformation(landingPageId, lpSectionId, value);
    this.setState({ activeState: value });
  };

  changeDateHandleEvent = (e, donationId) => {
    const { donationToAdd, donationsToShow } = { ...this.state };
    const date = e.target.value;
    donationToAdd.date = date;
    donationToAdd.dateToShow = date;
    donationToAdd.endDate = date;

    donationsToShow.forEach((element, index) => {
      if (element.id === donationId) {
        donationsToShow[index].endDate = date;
        donationsToShow[index].dateToShow = date;
        donationsToShow[index].edited = true;
      }
    });
    this.setState({
      donationToAdd,
      donationsToShow,
      FlagClassName: true,
    });
  };

  handleImageIndex = (index) => {
    this.setState({
      imageIndex: index,
    });
  };

  handleChangeImage = async (event) => {
    const {
      donationToAdd,
      donationsToShow,
      country,
      city,
      organizationId,
      imageIndex,
    } = this.state;
    var donationToAddImage = { ...this.state.donationToAddImage };
    var fileUpload = event.target.files[0];
    let extension;
    if (fileUpload !== undefined) {
      extension = fileUpload.type.replace("image/", "");
    }
    const random = uuidv4();
    const key = `content/${country}/${city}/${organizationId}/img${random}.${extension}`;
    if (!extension) {
      donationToAddImage.loading = true;
      donationToAddImage.file = null;
      donationToAddImage.image = null;

      this.setState({
        donationToAddImage,
        FlagClassName: true,
      });
      return;
    }

    if (key !== undefined) {
      // Function to resize and reduce image quality
      // Save image in AWS

      let flag = true;
      donationToAddImage = await handleChangeImageFunction(
        donationToAddImage,
        fileUpload,
        extension,
        key,
        400,
        400
      ).catch((res) => {
        const { t } = this.props;
        this.setState({
          showError: true,
          contentNotificationModal: `${t(`${res.content}`)
            .replace("R1", res.params.R1)
            .replace("R2", res.params.R2)}`,
        });
        flag = false;
      });
      if (flag !== false) {
        if (donationToAddImage.loading === false) {
          if (imageIndex === null) {
            //new news
            donationToAddImage.loading = false;
            donationToAddImage.edited = true;
            donationToAddImage.value = key;
            donationToAdd.image = key;
            donationToAdd.imageUri = donationToAddImage.image;
          } else {
            // edited new
            donationsToShow[imageIndex].image = key;
            donationsToShow[imageIndex].imageUri = donationToAddImage.image;
            donationsToShow[imageIndex].edited = true;
          }

          this.uploadFileToS3Bucket(donationToAddImage.file, key);
          this.setState({
            donationToAddImage,
            donationsToShow,
            FlagClassName: true,
          });
          this.forceUpdate();
        }
      }
    }
  };

  handleButtonListChange = (buttonList, donationId) => {
    let { donationToAdd, donationsToShow } = this.state;
    donationsToShow.forEach((element, index) => {
      if (element.id === donationId) {
        donationsToShow[index].buttons = buttonList;
        donationsToShow[index].edited = true;
      }
    });
    donationToAdd.buttons = buttonList;
    this.setState({ donationToAdd, donationsToShow, FlagClassName: true });
  };

  saveDonationGeneral = () => {
    const { t } = this.props;

    this.setState({
      isLoadingButton: true,
      FlagClassName: false,
    });
    const nodesToUpdate = [];
    var {
      haveChangeInLayout,
      lpSectionId,
      lpSectionLayoutStyle,
      donationContainerTitle,
      donationToAdd,
      donationsToShow,
    } = { ...this.state };
    let errorInput = false;

    // Verify each one of the values of the component to add
    if (donationContainerTitle) {
      if (donationContainerTitle.value === "") {
        errorInput = true;
      } else {
        //Update lpsection custom name
        let dto = {
          id: lpSectionId,
          customName: donationContainerTitle.value,
          isEnabled: 1,
        };
        this.updateLpSection(dto);
        delete donationContainerTitle.edited;
        nodesToUpdate.push(donationContainerTitle);
      }
    }

    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.fieldEmpty"),
          isLoadingButton: false,
        });
      }
    }

    if (haveChangeInLayout) {
      this.updateLpSectionLayoutFunction(lpSectionId, lpSectionLayoutStyle);
    }

    if (donationToAdd.title !== "") {
      donationsToShow.push(donationToAdd);
    }
    this.setState({ donationsToShow });

    this.onSaveDonationModal();

    donationToAdd = {
      id: null,
      type: 1,
      title: "",
      text: "",
      endDate: "-1",
      fixedValue: "-1",
      image: null,
      imageUri: null,
      imageLoading: false,
      buttons: [
        {
          name: t("basicWords.donate"),
          externalLink: "PAYMENT_PLATFORM", //This indicates to buttonContainer component redirection to payment platforms
          isEnabled: 1,
        },
      ],
      questions: [
        {
          uuid: generateUUID(),
          question: "",
          type: "",
          options: [""],
          obligatory: false,
        },
      ],
    };
    this.setState({ donationToAdd, addDonation: false });
  };

  handleClickOpenModalToChangeName = (itemToChangeName) => {
    let open = this.state.openModalToChangeName;
    this.setState({
      openModalToChangeName: !open,
      customName: itemToChangeName,
    });
  };

  OnChangeSectionCustomName = (e) => {
    let newCustomName = this.state.newCustomName;
    newCustomName = e.target.value;

    this.setState({
      newCustomName,
      FlagClassName: true,
    });
  };

  saveChangeName = () => {
    const { t } = this.props;
    var { donationContainerTitle, newCustomName, lpSectionId } = this.state;

    //Update lpsection custom name
    let dto = {
      id: lpSectionId,
      customName: newCustomName,
      isEnabled: 1,
    };
    this.updateLpSection(dto);

    //Update section title name
    const nodesToUpdate = [];
    let errorInput = false;

    if (donationContainerTitle) {
      if (newCustomName === "") {
        errorInput = true;
      } else {
        donationContainerTitle.value = newCustomName;
      }
      delete donationContainerTitle.edited;
      nodesToUpdate.push(donationContainerTitle);
    }

    if (nodesToUpdate.length > 0) {
      if (!errorInput) {
        this.updateNode(nodesToUpdate);
      } else {
        this.setState({
          showError: true,
          contentNotificationModal: t("basicWords.modal.fieldEmpty"),
          isLoadingButton: false,
        });
      }
    }
  };

  onSaveDonationModal = () => {
    const {
      parentLPSectionComponentId,
      landingPageId,
      donationsToShow,
      donationToAdd,
      deleteFlag,
    } = this.state;

    this.setState({
      isLoadingButton: true,
    });
    var donationsToValidate = donationsToShow.filter((n) => n.edited === true);
    if (donationToAdd.title !== "" && donationToAdd.title !== null) {
      donationsToValidate = donationsToValidate.concat(donationToAdd);
    }

    donationsToValidate.forEach((newDonation) => {
      const donationNodesToAdd = [];
      const { t } = this.props;
      let donationButtons = newDonation.buttons
        ? newDonation.buttons.map((b) => {
            const button = {
              id: b.id,
              name: b.name,
              externalLink: b.externalLink,
              internalLpSectionComponentId: b.component
                ? b.component.id
                : b.internalLpSectionComponentId
                ? b.internalLpSectionComponentId
                : null,
              isEnabled: b.isEnabled,
            };
            return button;
          })
        : [];
      const finalDto = {
        // --- Con esto se crea el registro lpSectionComponent ---
        // Con estos dos valores se obtiene el lpSectionId
        lpId: null, // Este valor se obtiene de la vista anterior
        lpSectionId: this.state.lpSectionId,
        sectionId: 12, // Valor estático para la sección de donaciones
        sectionComponentId: 20, // Valor estático de 20, valor para el DonationComponent
        parentLPSectionComponentId, // Valor estático de 4 puesto que el id 4 es el padre de este nuevo componente
        // --- Con el Id del lpSectionComponent creado, se crean los nodos de este componente
        nodesToAdd: [],
        buttons: donationButtons,
      };

      let errorInput = false;

      if (newDonation.title != null) {
        const donationNode = {};

        donationNode.id = newDonation.titleId ? newDonation.titleId : null;
        donationNode.sectionComponentNodeId = 66;
        // Required
        if (newDonation.title === "") {
          errorInput = true;
        }
        donationNode.value = newDonation.title;
        donationNode.nodeType = "DonationComponentTitle";

        donationNodesToAdd.push(donationNode);
      }

      if (newDonation.text != null) {
        const donationNode = {};

        donationNode.id = newDonation.textId ? newDonation.textId : null;
        donationNode.sectionComponentNodeId = 67;
        // Required
        // if (newDonation.text === "") {
        //   errorInput = true;
        // }
        donationNode.value = newDonation.text;
        donationNode.nodeType = "DonationComponentText";

        donationNodesToAdd.push(donationNode);
      }

      //questions
      if (newDonation.questions != null) {
        const donationNode = {};

        donationNode.id = newDonation.questionsId
          ? newDonation.questionsId
          : null;
        donationNode.sectionComponentNodeId = 99;
        donationNode.value = JSON.stringify(newDonation.questions);
        donationNode.nodeType = "DonationFormRule";

        donationNodesToAdd.push(donationNode);
      }

      if (newDonation.endDate != null) {
        const donationNode = {};
        donationNode.id = newDonation.endDateId ? newDonation.endDateId : null;
        donationNode.sectionComponentNodeId = 69;
        if (newDonation.endDate !== "-1") {
          donationNode.value = moment(
            newDonation.endDate,
            "YYYY-MM-DD HH:mm:ss"
          );
        } else {
          donationNode.value = "-1";
        }
        donationNode.nodeType = "DonationComponentEndDate";

        donationNodesToAdd.push(donationNode);
      }

      if (newDonation.fixedValue != null) {
        const donationNode = {};
        donationNode.id = newDonation.fixedValueId
          ? newDonation.fixedValueId
          : null;
        donationNode.sectionComponentNodeId = 70;
        let valueFilter = newDonation.fixedValue.replace(
          /[^a-zA-Z0-9 ]+/g,
          " "
        );
        if (valueFilter == " 1") {
          valueFilter = "-1";
        }
        donationNode.value = valueFilter;
        // donationNode.value = newDonation.fixedValue;
        donationNode.nodeType = "DonationComponentFixedValue";
        donationNodesToAdd.push(donationNode);
      }

      // if (newDonation.donationSuccessTitle != null) {
      //   const donationNode = {};

      //   donationNode.id = newDonation.fixedValueId
      //     ? newDonation.fixedValueId
      //     : null;
      //   donationNode.sectionComponentNodeId = 70;

      //   donationNode.value = newDonation.fixedValue;
      //   donationNode.nodeType = "DonationSuccessTitle";

      //   donationNodesToAdd.push(donationNode);
      // }

      // if (newDonation.donationSuccessText != null) {
      //   const donationNode = {};
      //   donationNode.id = newDonation.donationSuccessText ? newDonation.donationSuccessText : null;
      //   donationNode.sectionComponentNodeId = 70;
      //   donationNode.value = newDonation.donationSuccessText;
      //   donationNode.nodeType = "DonationSuccessText";
      //   donationNodesToAdd.push(donationNode);
      // }

      // if (newDonation.donationSuccessImage !== null) {
      //   const donationNode = {};
      //   let haveHttp = false;
      //   var regex = /somosaura-cms.s3.amazonaws.com/;
      //   haveHttp = regex.test(newDonation.donationSuccessImage);
      //   if (haveHttp) {
      //     donationNode.value = newDonation.donationSuccessImage.slice(39, newDonation.donationSuccessImage.length ); //Cut to only save image, not link
      //   } else {
      //     donationNode.value = newDonation.donationSuccessImage;
      //   }
      //   donationNode.id = newDonation.donationSuccessImageId ? newDonation.donationSuccessImageId : null;
      //   donationNode.sectionComponentNodeId = 68;
      //   donationNode.nodeType = "DonationSuccessImage";

      //   donationNodesToAdd.push(donationNode);
      // }
      if (newDonation.image !== null) {
        const donationNode = {};
        let haveHttp = false;
        var regex = /somosaura-cms.s3.amazonaws.com/;
        haveHttp = regex.test(newDonation.image);
        if (haveHttp) {
          donationNode.value = newDonation.image.slice(
            39,
            newDonation.image.length
          ); //Cut to only save image, not link
        } else {
          donationNode.value = newDonation.image;
        }

        donationNode.id = newDonation.imageId ? newDonation.imageId : null;
        donationNode.sectionComponentNodeId = 68;
        donationNode.nodeType = "DonationComponentImage";

        donationNodesToAdd.push(donationNode);
      }

      if (deleteFlag || newDonation.imageId == null) {
        const donationNode = {};

        donationNode.id = donationNode.imageId ? donationNode.imageId : null;
        donationNode.sectionComponentNodeId = 68;
        donationNode.value = "";
        donationNode.nodeType = "DonationComponentImage";
        donationNodesToAdd.push(donationNode);
      }

      finalDto.id = newDonation.id;
      finalDto.lpId = landingPageId;
      finalDto.nodesToAdd = donationNodesToAdd;
      if (newDonation.type === "") {
        errorInput = true;
      } else {
        finalDto.paymentTypeId = newDonation.type;
      }

      if (donationNodesToAdd.length > 0) {
        if (!errorInput) {
          let donationTitle = false;
          let donationText = false;
          let donationDate = false;

          donationNodesToAdd.forEach((obj) => {
            if (obj.nodeType === "DonationComponentTitle") {
              donationTitle = true;
            }
            if (obj.nodeType === "DonationComponentText") {
              donationText = true;
            }
            if (obj.nodeType === "DonationComponentEndDate") {
              donationDate = true;
            }
          });
          if (donationTitle && donationText && donationDate) {
            if (newDonation.id) {
                  this.updateSectionComponentFunction(finalDto);
            } else {
                this.insertSectionComponentFunction(finalDto);
            }
          } else {
            this.setState({
              showError: true,
              contentNotificationModal: t("basicWords.modal.fieldEmpty"),
              isLoadingButton: false,
            });
          }
        } else {
          this.setState({
            showError: true,
            contentNotificationModal: t("basicWords.modal.fieldEmpty"),
            isLoadingButton: false,
          });
        }
      }
    });
  };

  // Functions
  cleanStateVariables = () => {
    const { t } = this.props;
    let donationToAddImage = {
      id: null,
      status: 0,
      s3path: null,
      imageUrl: null,
      image: null,
      loading: false,
    };

    let donationToAdd = {
      id: null,
      type: 1,
      title: "",
      text: "",
      endDate: "-1",
      fixedValue: "-1",
      image: null,
      imageLoading: false,
      buttons: [
        {
          name: t("basicWords.donate"),
          externalLink: "PAYMENT_PLATFORM", //This indicates to buttonContainer component redirection to payment platforms
          isEnabled: 1,
        },
      ],
      questions: [],
    };

    this.setState({
      donationToAddImage,
      donationToAdd,
    });
  };

  timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  async copyLink(donation) {
    const { t } = this.props;
    this.setState({
      showSuccess: true,
      contentNotificationModal: t("basicWords.copiedLink"),
    });

    this.state.donationsToShow.map((element) => {
      if (element.id == donation.id) {
        element.showQR = true;
      } else {
        element.showQR = false;
      }
    });
    navigator.clipboard.writeText(
      `https://${this.state.lpUrl}/payment/${donation.id}`
    );
    await this.timeout(500);
    this.setState({
      false: true,
    });
  }

  downloadQRCode = async (donation) => {
    // Generate download with use canvas and stream
    const qrCode = new QRCodeStyling({
      width: 1000,
      height: 1000,
      margin: 150,
      type: "svg",
      data: `https://${this.state.lpUrl}/payment/${donation.id}.png`,
      image: logoAura,
      dotsOptions: { color: "black", type: "rounded" },
      backgroundOptions: { color: "#ffff" },
      cornersDotOptions: { color: "#510f8a", type: "extra-rounded" },
      cornersSquareOptions: { color: "black", type: "extra-rounded" },
      imageOptions: { crossOrigin: "anonymous", margin: 10 },
      qrOptions: { typeNumber: 10, mode: "Byte", errorCorrectionLevel: "H" },
    });
    qrCode.append(document.getElementById(`qr-code-${donation.id}`));
    // qrCode.download({ name: `${donation.title}`, extension: "png" });
    const canvas = await qrCode._getElement();
    const ctx = canvas.getContext("2d");
    const rectangle = new Path2D();
    rectangle.rect(10, 10, 980, 980);
    ctx.lineWidth = 4;
    ctx.strokeStyle = "#510f8a";
    ctx.stroke(rectangle);
    // text
    // ctx.font = "bold 50px serif";
    ctx.font = "bold 65px Verdana";
    ctx.textAlign = "center";
    ctx.textBaseline = "top";
    ctx.fillText(donation.title, 500, 60);
    ctx.font = "50px Verdana";
    ctx.fillStyle = "#510f8a";
    let commName = this.state.community.name;
    ctx.fillText(commName ? commName : donation.title, 500, 890);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${donation.title}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    await this.timeout(500);
  };

  renderDonations(donationsToShow) {
    let renderedComponents = [];
    const { t } = this.props;
    const { landingPageId, isReadyToEdit, donationCategory } = this.state;
    const options = [
      { value: t("basicWords.form.text"), id: "text" },
      { value: t("basicWords.form.mutiple"), id: "multiple" },
      { value: t("basicWords.form.select"), id: "select" },
    ];
    if (donationsToShow && donationsToShow.length) {
      donationsToShow.forEach((element, index) => {
        renderedComponents.push(
          <div key={element.id}>
            <AccordionComponent
              disabled={isReadyToEdit}
              iconEdit={<Edit2 />}
              // onEditNews={(e) => this.changeToEdit(e)}
              onDeleteNews={(e) => this.handleClickOpenDeleteNews(element.id)}
              iconDelete={<Trash2 />}
              buttonsHeader={
                <div className="contentButtonLink">
                  <button
                    className="buttonCopyLink"
                    onClick={() => this.copyLink(element)}
                  >
                    <Link className="iconLink" />
                    {t("basicWords.copyLink")}
                  </button>
                  <button
                    className="buttonCopyLink"
                    onClick={() => this.downloadQRCode(element)}
                  >
                    <Download className="iconLink" />
                    {t("basicWords.downloadQR")}
                  </button>
                </div>
              }
              title={element.title}
              body={[
                <div className="accordionContent">
                  <div className="accordionCenterLine">
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "1%",
                        marginRight: "0.7%",
                      }}
                    >
                      {/* <div className="textTypeDonation">
                        {t("basicWords.buttons.buttons")}
                      </div>
                      <br /> */}
                      <FormControl sx={{ width: 300 }}>
                        <InputLabel
                          style={{
                            "margin-left": "10px",
                            "margin-top": "-2px",
                          }}
                        >
                          Type
                        </InputLabel>
                        <Select
                          id="demo-multiple-checkbox"
                          value={element.type}
                          onChange={(e) =>
                            this.onChangeTypeDonation(e, element)
                          }
                          input={<OutlinedInput label="Tag" />}
                        >
                          {donationCategory.map((op) => (
                            <MenuItem key={op.id} value={op.id}>
                              <ListItemText
                                primary={t(
                                  `basicWords.donation.type.${op.name}`
                                )}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <br />
                  <br />

                  {/* First Line */}
                  <div className="accordionCenterLine">
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "1%",
                        marginRight: "0.7%",
                      }}
                    >
                      <GeneralInput
                        disabled={isReadyToEdit}
                        className="textInputWidth"
                        placeholder={
                          t("basicWords.enterTitle") +
                          " " +
                          t("basicWords.ofDonations")
                        }
                        id="title"
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={element.title}
                      />
                      {element.title.value && element.title.maxLength ? (
                        <span>
                          {element.title.length} / {element.title.maxLength}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  {/* Second Line */}
                  <br />
                  <div className="accordionCenterLine">
                    <div style={{ width: "100%", marginRight: "2%" }}>
                      <ReactQuill
                        readOnly={isReadyToEdit}
                        className="accordionCompleteInputs"
                        onChange={(e) =>
                          this.handleChangeDescription(e, element)
                        }
                        value={element.text}
                        modules={editorConfiguration.modules}
                        formats={editorConfiguration.formats}
                        placeholder={t("basicWords.description")}
                      />
                    </div>
                  </div>

                  <br />
                  {/* Third line */}
                  <div className="accordionCenterLine">
                    <div
                      className="textLogo"
                      style={{ marginLeft: "1%", marginRight: "87%" }}
                    ></div>
                    <div className="textLogo">{t("basicWords.value")}</div>
                  </div>

                  {/* Fourth line */}
                  <div className="accordionCenterLine">
                    <div
                      style={{
                        width: "35%",
                        marginRight: "1%",
                        marginLeft: "1%",
                      }}
                    >
                      <DatePickerComponent
                        string={t("basicWords.endDateDonation")}
                        defaultValue={element.endDate}
                        onChange={(e) =>
                          this.changeDateHandleEvent(e, element.id)
                        }
                        disabled={element.endDate === "-1"}
                      />
                    </div>
                    <div
                      style={{
                        width: "10%",
                        marginRight: "1%",
                        marginLeft: "1%",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            id="unlimitedEndDate"
                            style={{ textAlign: "center" }}
                            checked={element.endDate === "-1"}
                            onChange={(e) =>
                              this.handleChange(e, "", "", "", element.id)
                            }
                          />
                        }
                        label={t("basicWords.unlimitedDate")}
                      />
                    </div>
                    <div
                      style={{
                        width: "35%",
                        marginRight: "1%",
                        marginLeft: "1%",
                      }}
                    >
                      <TextField
                        id="voluntaryValue"
                        className={
                          element.fixedValue === "-1"
                            ? "basic-single-block"
                            : "basic-single"
                        }
                        label={t("basicWords.value")}
                        onChange={(e) =>
                          this.handleChange(e, "", "", "", element.id)
                        }
                        value={
                          element.fixedValue === "-1"
                            ? "El usuario definirá el valor"
                            : element.fixedValue
                        }
                        // disabled={element.fixedValue === "-1"}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {" "}
                              <AttachMoneyIcon
                                sx={{ color: "green", fontSize: 20 }}
                              />{" "}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div
                      style={{
                        width: "10%",
                        marginRight: "1%",
                        marginLeft: "1%",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            id="voluntaryValue"
                            style={{ textAlign: "center" }}
                            checked={element.fixedValue === "-1"}
                            onChange={(e) =>
                              this.handleChange(e, "", "", "", element.id)
                            }
                          />
                        }
                        label={t("basicWords.valueDefectByUser")}
                      />
                    </div>
                  </div>

                  {/* Image */}
                  <br />
                  <div className="accordionCenterLine">
                    <div className="textLogo" style={{ marginLeft: "1%" }}>
                      {t("basicWords.image")}
                    </div>
                    {this.renderInputImage(element, index)}
                  </div>

                  {/* <br />
                  <div className="accordionCenterLine">
                    <div className="textLogo" style={{ marginLeft: "1%" }}>
                      {t("basicWords.donationSuccess")}
                    </div>
                  </div>
                  <br /> */}

                  {/* Fifth line - donation title success*/}
                  {/* <div className="accordionCenterLine">
                    <div style={{ width: "100%", marginLeft: "1%", marginRight: "0.7%", marginBottom: "10px" }}  >
                      <GeneralInput disabled={isReadyToEdit} className="textInputWidth"
                        placeholder={t("basicWords.enterTitle")}
                        id="donationSuccessTitle" onChange={(e) =>this.handleChange(e, "", "", "", element.id) } value={element.donationSuccessTitle && element.donationSuccessTitle  != null ? element.donationSuccessTitle : ''}
                      />
                      <GeneralInput disabled={isReadyToEdit} className="textInputWidth"
                        placeholder={t("basicWords.enterText")}
                        id="donationSuccessText" onChange={(e) =>this.handleChange(e, "", "", "", element.id) } value={element.donationSuccessText && element.donationSuccessText  != null ? element.donationSuccessText : ''}
                      />
                      {this.renderInputImage(element, index)}

                    </div>
                  </div> */}
                  {/* buttons */}
                  <br />
                  <div className="accordionCenterLine">
                    <div className="textLogo" style={{ marginLeft: "1%" }}>
                      {t("basicWords.buttons.buttons")}
                    </div>
                  </div>

                  <br />
                  <div className="accordionCenterLine">
                    <div style={{ marginLeft: "1%" }}>
                      <ButtonsContainer
                        disabled={isReadyToEdit}
                        isModalVisible={true}
                        lpId={landingPageId}
                        buttonList={element.buttons}
                        handleButtonListChange={(buttonList) =>
                          this.handleButtonListChange(buttonList, element.id)
                        }
                      />
                    </div>
                  </div>
                  <canvas
                    id={`qr-code-${element.id}`}
                    style={{ display: "none" }}
                  />

                  {/* Questions*/}
                  <br />
                  <div className="accordionCenterLine">
                    <div className="textLogo" style={{ marginLeft: "1%" }}>
                      {t("basicWords.additionalQuestions")}
                    </div>
                  </div>
                  <div className="accordionCenterLine">
              <div className="textDescription" style={{ margin: "1rem" }}>
                  {t("basicWords.form.formDescription")}
                </div>
              </div>
              
                  <div className="containerQuestions">
                    {element.questions && element.questions.length > 0 ? (
                      <>
                        {element.questions.map((qst, index) => {
                          return (
                            <>
                              {/* obligatory  */}
                              <div className="containerResponseQuestion">
                                <div />
                                <div
                                  style={{
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-content": "center",
                                    gap: "15px",
                                  }}
                                >
                                  <div className="containerSwitchObligatory">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={qst.obligatory === true}
                                        onChange={(e) =>
                                          this.onChangeObligatory(
                                            e,
                                            index,
                                            element
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <div className="containerResponseQuestion">
                                      <p>{t("basicWords.obligatory")}</p>
                                    </div>
                                  </div>
                                  <div className="containerAddQuestion">
                                    <div className="bgAddButton">
                                      <Button
                                        type="primary"
                                        shape="circle"
                                        className="addButton"
                                        onClick={() =>
                                          this.onCloseSecondQuestion(element)
                                        }
                                      >
                                        <Icon.trash />
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* question  */}
                              <div className="containerQuestionAndType">
                                <div
                                  style={{ width: "50%", margin: "0px 5px" }}
                                >
                                  <GeneralInput
                                    className="textInputWidth"
                                    placeholder={t(
                                      "basicWords.form.enterQuestion"
                                    )}
                                    id="title"
                                    onChange={(e) =>
                                      this.handleChangeQuestion(
                                        e,
                                        index,
                                        true,
                                        element.id
                                      )
                                    }
                                    value={qst.question}
                                  />
                                </div>
                                <div
                                  style={{
                                    width: "50%",
                                    top: "-4px",
                                    position: "relative",
                                    margin: "0px 5px",
                                  }}
                                >
                                  <FormControl sx={{ width: 300 }}>
                                    <InputLabel
                                      style={{
                                        "margin-left": "10px",
                                        "margin-top": "-2px",
                                      }}
                                    >
                                      Type
                                    </InputLabel>
                                    <Select
                                      id="demo-multiple-checkbox"
                                      value={qst.type}
                                      onChange={(e) =>
                                        this.onChangeTypeQuestion(
                                          e,
                                          index,
                                          element
                                        )
                                      }
                                      input={<OutlinedInput label="Tag" />}
                                    >
                                      {options.map((op) => (
                                        <MenuItem key={op.id} value={op.id}>
                                          <ListItemText primary={op.value} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              <div className="containerResponseQuestion">
                                <p> {t("basicWords.form.ansOptions")}</p>
                              </div>
                              {/* response */}
                              <div className="accordionCenterLine">
                                <div
                                  style={{
                                    width: "100%",
                                    marginLeft: "1%",
                                    marginRight: "0.7%",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {qst.type === "text" ? (
                                    <GeneralInput
                                      className="textInputWidth"
                                      placeholder={t(
                                        "basicWords.form.textDescription"
                                      )}
                                      id="title"
                                      value={""}
                                      disabled={true}
                                    />
                                  ) : null}
                                  {qst.type === "multiple" ? (
                                    <>
                                      <FormControl>
                                        {qst.options
                                          ? qst.options.map(
                                              (option, indexOption) => {
                                                return (
                                                  <div className="containerSelect">
                                                    <RadioGroup
                                                      aria-labelledby="demo-radio-buttons-group-label"
                                                      defaultValue="female"
                                                      name="radio-buttons-group"
                                                    >
                                                      <FormControlLabel
                                                        value={true}
                                                        control={<Radio />}
                                                      />
                                                    </RadioGroup>
                                                    <TextField
                                                      id="input-standard-basic"
                                                      style={{ width: "90%" }}
                                                      onChange={(e) =>
                                                        this.onEditOptionQuestion(
                                                          e,
                                                          index,
                                                          indexOption,
                                                          element
                                                        )
                                                      }
                                                      value={option}
                                                    />
                                                    {indexOption !== 0 ? (
                                                      <Button
                                                        type="primary"
                                                        shape="circle"
                                                        className="addButton"
                                                        onClick={() =>
                                                          this.onAddOptionQuestion(
                                                            "subtract",
                                                            index,
                                                            element
                                                          )
                                                        }
                                                      >
                                                        x
                                                      </Button>
                                                    ) : null}
                                                  </div>
                                                );
                                              }
                                            )
                                          : null}
                                      </FormControl>
                                      <div className="accordionCenterLine">
                                        <div className="bgAddButton">
                                          <Button
                                            type="primary"
                                            shape="circle"
                                            className="addButton"
                                            onClick={() =>
                                              this.onAddOptionQuestion(
                                                "add",
                                                index,
                                                element
                                              )
                                            }
                                          >
                                            +
                                          </Button>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                  {qst.type === "select" ? (
                                    <>
                                      <FormControl>
                                        {qst.options.map(
                                          (option, indexOption) => {
                                            return (
                                              <div className="containerSelect">
                                                <RadioGroup
                                                  aria-labelledby="demo-radio-buttons-group-label"
                                                  defaultValue="female"
                                                  name="radio-buttons-group"
                                                >
                                                  <FormControlLabel
                                                    value={true}
                                                    control={<Checkbox />}
                                                  />
                                                </RadioGroup>
                                                <TextField
                                                  id="input-standard-basic"
                                                  style={{ width: "90%" }}
                                                  onChange={(e) =>
                                                    this.onEditOptionQuestion(
                                                      e,
                                                      index,
                                                      indexOption,
                                                      element
                                                    )
                                                  }
                                                  value={option}
                                                />
                                                {indexOption != 0 ? (
                                                  <Button
                                                    type="primary"
                                                    shape="circle"
                                                    className="addButton"
                                                    onClick={() =>
                                                      this.onAddOptionQuestion(
                                                        "subtract",
                                                        index,
                                                        element
                                                      )
                                                    }
                                                  >
                                                    x
                                                  </Button>
                                                ) : null}
                                              </div>
                                            );
                                          }
                                        )}
                                      </FormControl>
                                      <div className="accordionCenterLine">
                                        <div className="bgAddButton">
                                          <Button
                                            type="primary"
                                            shape="circle"
                                            className="addButton"
                                            onClick={() =>
                                              this.onAddOptionQuestion(
                                                "add",
                                                index,
                                                element
                                              )
                                            }
                                          >
                                            +
                                          </Button>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          );
                        })}

                        <div className="containerAddQuestion">
                          <div className="bgAddButton">
                            <Button
                              type="primary"
                              shape="circle"
                              className="addButton"
                              onClick={() => this.onAddQuestion(element)}
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="containerAddQuestion">
                        <div className="bgAddButton">
                          <Button
                            type="primary"
                            shape="circle"
                            className="addButton"
                            onClick={() => this.onAddQuestion(element)}
                          >
                            +
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>,
              ]}
            />
            <br />
          </div>
        );
      });
    }
    return renderedComponents;
  }

  handleSelectLayout() {
    this.getLayoutFunction(this.props.location.props.landingPageId);

    this.setState({ layoutModalVisible: true });
  }

  setLayoutModalVisible = (isVisible) => {
    this.setState({ layoutModalVisible: isVisible });
  };

  addDonation = (flag) => {
    if (flag !== this.state.addDonation) {
      this.setState({ addDonation: flag });
    }
  };

  //-----------Update page after modal------------
  updatePage = (lpSectionId, previousPath) => {
    const { landingPageId, activeState } = this.state;
    this.getDonationsInformation(landingPageId, lpSectionId, activeState);
    this.setState({
      previousPath,
    });
    this.setState({ layoutModalVisible: false });
    this.forceUpdate();
  };

  GoToWithoutSave = () => {
    this.setState({ goToWithoutSaveModalVisible: true });
  };

  onCancelGotoWithoutMModal = () => {
    this.setState({
      goToWithoutSaveModalVisible: false,
    });
  };

  setShowError = (open) => {
    this.setState({ showError: open });
  };

  setShowSuccess = (open) => {
    this.setState({ showSuccess: open });
  };

  setShowWarning = (open) => {
    this.setState({ showWarning: open });
  };

  // Render Info
  render() {
    const {
      redirectBack,
      isLoadingButton,
      sectionName,
      donationsToShow,
      organizationId,
      organizationName,
      name,
      subpageId,
      landingPageId,
      donationContainerTitle,
      layoutData,
      lpSectionLayoutStyle,
      FlagClassName,
      goToWithoutSaveModalVisible,
      lpUrl,

      // Modals
      showSuccess,
      showWarning,
      showError,
      contentNotificationModal,

      //Modal delete item
      openDeleteNews,
      elementToDeleteId,
      openModalToChangeName,
      customName,
    } = this.state;

    const { t } = this.props;

    if (redirectBack) {
      return <Redirect to="/manage/web" />;
    }

    return (
      <>
        {!!showWarning && (
          <SystemNotification
            setShowNotification={(e) => this.setShowWarning(e)}
            type="Warning"
            caption={contentNotificationModal}
          />
        )}
        {!!showSuccess && (
          <SystemNotification
            setShowNotification={(e) => this.setShowSuccess(e)}
            type="Success"
            caption={contentNotificationModal}
          />
        )}
        {!!showError && (
          <SystemNotification
            setShowNotification={(e) => this.setShowError(e)}
            type="Error"
            caption={contentNotificationModal}
          />
        )}

        <GeneralModal
          visible={openModalToChangeName}
          title={"Cambiar nombre"}
          body={[
            <div className="col-12" style={{ margin: "5px" }}>
              <div className="row">
                <div className="col-md-12 componentSpace">
                  <TextField
                    variant="outlined"
                    style={{ width: "100%", marginLeft: 10 }}
                    label={t("basicWords.section")}
                    onChange={(e) => this.OnChangeSectionCustomName(e)}
                    defaultValue={
                      donationContainerTitle && donationContainerTitle.value
                        ? decodeURIComponent(donationContainerTitle.value)
                        : ""
                    }
                  />
                </div>
              </div>
            </div>,
          ]}
          footer={[
            <div className="row buttonAtTheBottom">
              <div>
                <ButtonComponent
                  onClick={(e) => this.handleClickOpenModalToChangeName()}
                  shape="round"
                  text={t("basicWords.cancel")}
                  className="buttonDiscard"
                />
              </div>
              <div className="col-6">
                <ButtonComponent
                  isDisabled={false}
                  onClick={() => this.saveChangeName()}
                  shape="round"
                  text={t("basicWords.ok")}
                />
              </div>
            </div>,
          ]}
          onClick={() => this.handleClickOpenModalToChangeName()}
        />

        <DeleteItemModal
          visible={openDeleteNews}
          onCancel={this.handleCloseDeleteNews}
          onClick={(e) => this.disableDonationEvent(elementToDeleteId)}
        />

        <section className="sectionContainer">
          <Card className="card">
            {isLoadingButton === true ? (
              <div
                className="col-xs-12 col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  transform: "translateY(800%)",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="row componentSpace spaceBreadcrumb">
                  <BreadcrumbSection
                    principal={t("sections.donations")}
                    secondary={organizationName}
                    route="./manage/web"
                    propsComponent={{
                      organizationId,
                      sectionName: sectionName,
                      nameSubpage: name ? name : null,
                      lpId: landingPageId,
                      subpageId: subpageId ? subpageId : null,
                      lpUrl: lpUrl,
                      customName:
                        donationContainerTitle && donationContainerTitle.value
                          ? decodeURIComponent(donationContainerTitle.value)
                          : customName,
                    }}
                    handleClickOpenModalToChangeName={
                      this.handleClickOpenModalToChangeName
                    }
                    FlagclassName={FlagClassName}
                    // onClick={(e) => this.saveDonationGeneral(e)}
                    GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
                    onCancelGotoWithoutMModal={(e) =>
                      this.onCancelGotoWithoutMModal(e)
                    }
                    goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
                  />
                </div>

                <div className="row componentSpace adjustPositionXLeft adjustPositionYTop">
                  <Title
                    title={t("basicWords.completeInformationOfYourSection")}
                  />
                </div>

                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <SelectLayoutModal
                    layoutData={layoutData}
                    lpLayoutId={
                      lpSectionLayoutStyle ? lpSectionLayoutStyle : null
                    }
                    handleChange={this.handleChangeLayout}
                  />
                </div>

                <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                  <AccordionComponent
                    title={t("generalUser.sectionContent")}
                    body={[
                      <div className="accordionContent">
                        <div style={{ width: "60%" }}>
                          <GeneralInput
                            className="textInputWidth"
                            placeholder={
                              t("basicWords.enterTitle") +
                              " " +
                              t("basicWords.ofDonations")
                            }
                            id={
                              donationContainerTitle
                                ? donationContainerTitle.nodeTypeName
                                : null
                            }
                            onChange={(e, id) =>
                              this.handleChange(e, "", "", "", id)
                            }
                            value={
                              donationContainerTitle
                                ? donationContainerTitle.value
                                : null
                            }
                          />
                        </div>
                      </div>,
                    ]}
                  />
                </div>

                {/* Accordion to add donation */}
                {landingPageId ? (
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    {this.renderAddDonations()}
                  </div>
                ) : null}

                <div>
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    <Title title={t("basicWords.listDonations")} />
                  </div>
                  <div className="row componentSpace adjustPositionXLeft adjustPositionXRigth">
                    {this.renderDonations(donationsToShow)}
                  </div>
                </div>
              </>
            )}
          </Card>
        </section>

        <ButtonsFooterComponent
          propsComponent={{
            organizationId,
            sectionName,
            name,
            landingPageId,
            subpageId,
            lpUrl,
          }}
          FlagclassName={FlagClassName}
          onClick={(e) => this.saveDonationGeneral(e)}
          GoToWithoutSave={(e) => this.GoToWithoutSave(e)}
          onCancelGotoWithoutMModal={(e) => this.onCancelGotoWithoutMModal(e)}
          goToWithoutSaveModalVisible={goToWithoutSaveModalVisible}
        />
      </>
    );
  }
}
export default withTranslation()(DonationsSection);
