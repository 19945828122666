const userSessionActions = {
   PRIVILEGES: 'PRIVILEGES',
   USER_SESSION: 'USER_SESSION',

   userPrivileges: (privileges) => (
      {
         type: userSessionActions.PRIVILEGES,
         privileges
      }
   ),
   userSession: (userSession) => ( 
      {
         type: userSessionActions.USER_SESSION,
         userSession
      }
   )
};

export default userSessionActions;