import axios from "axios";

import {
  host,
  port,
  route,
  datasource,
} from "../../reactUtils/systemVariables/CmsServerInformation";

const resource = "/lpsubpage";

export async function getLpSubpagesByLpId(lpId) {
  try {
    let response = await axios.get(
      host + port + route + datasource + resource,
      {
        params: {
          lpId: lpId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function setLpSubpagesByLpSectionId(lpSectionId, lpSubpages) {
  try {
    const data = JSON.stringify(lpSubpages);
    let response = await axios({
      method: "PUT",
      url: host + port + route + datasource + resource,
      params: { lpSectionId },
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function createLpSubpageByLpId(lpId, LpSubpageDto) {
  try {
    const data = JSON.stringify(LpSubpageDto);
    let response = await axios({
      method: "POST",
      url: host + port + route + datasource + resource,
      params: {
        lpId,
      },
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateLpSubpageByLpId(LpSubpageDto) {
  try {
    const data = JSON.stringify(LpSubpageDto);
    let response = await axios({
      method: "PUT",
      url: host + port + route + datasource + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}
