import React, { Component, Fragment } from "react";
// import { Modal, Radio, Tooltip } from "antd";

// import { InfoCircleOutlined } from "@ant-design/icons";

//Components
import {
  GeneralInput,
  SelectSingle,
  GeneralModal,
  ButtonComponent,
} from "./";
import SystemNotification from "../pages/global/SystemNotification";

import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";

//Localization
import { withTranslation } from 'react-i18next';

//Queries
import { getLpSectionComponentsByLpSectionId } from "../actions/aura";

 class AddButtonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      button: {
        name: "",
      },
      buttonType: 1,
      sectionId: null,
      componentId: null,
      components: [],
      sections: [],
      componentsLoading: false,

      //Modal notification
      showSuccess: false,
      showWarning: false,
      showError: false,
      contentNotificationModal: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isModalVisible !== prevProps.isModalVisible) {
      if (this.props.button) {
        let button = JSON.parse(JSON.stringify(this.props.button));
        let buttonType, sectionId, componentId;
        if (button.section) {
          buttonType = 1;
          this.getLpSectionComponents(button.section.id);
          sectionId = button.section.id;
          componentId = button.component.id;
        } else {
          buttonType = 2;
          sectionId = null;
          componentId = null;
        }

        this.setState({ button, buttonType, sectionId, componentId });
      } else {
        this.setState({
          button: {},
          buttonType: 1,
          sectionId: null,
          componentId: null,
        });
      }
    }
    if (this.props.sections !== this.state.sections) {
      this.setState({ sections: this.props.sections });
    }
  }

  async getLpSectionComponents(lpSectionId) {
    this.setState({ componentsLoading: true });
    let response = await getLpSectionComponentsByLpSectionId(lpSectionId);
    const {t} = this.props
    if (String(response).includes("Error:")) {
      this.setState({
        showError: true,
        contentNotificationModal: t('basicWords.modal.internalServerErrorContactSupport'),
      });
    } else {
      let components = response.data.data;
      //Filter only sections that can be linked and map to value/label
      components = components.map((c, index) => ({ value: c.id, label: c.name, key: index }));
      this.setState({ components, componentsLoading: false });
    }
  }

  onButtonTypeChange = (val) => {
    this.setState({ buttonType: parseInt(val.target.value), section: null });
  };

  onButtonNameChange = (val) => {
    let { button } = this.state;
    button.name = val.target.value;
    this.setState({ button });
  };

  onButtonLinkChange = (val) => {
    let { button } = this.state;
    button.externalLink = val.target.value;
    this.setState({ button });
  };

  onSectionSelect = (e) => {
    const { sections } = this.state;
    let sel = e.target;
    let sec = sections.filter((sections) => sections.value === sel.value);
    this.getLpSectionComponents(sel.value);
    let { button } = this.state;
    let section = { id: sec[0].value, name: sec[0].label };
    button.section = section;
    this.setState({ sectionId: sel.value, button });
  };

  onComponentSelect = (e) => {
    const { components } = this.state;
    let sel = e.target;
    let comp = components.filter(
      (components) => components.value === sel.value
    );
    let component = { id: comp[0].value, name: comp[0].label };
    let { button } = this.state;
    button.component = component;
    this.setState({ componentId: sel.value, button });
  };

  renderAddButtonInputs = () => {
    const { button, buttonType, sections, sectionId, components, componentId } =
      this.state;
      const {t} = this.props
    if (buttonType === 1) {
      return (
        <Fragment>
          <br />
          <SelectSingle
            text={t('basicWords.section')}
            options={sections}
            selectedOptions={sectionId}
            onChange={(val) => this.onSectionSelect(val)}
          />
          {sectionId ? (
            <Fragment>
              <br />
              <SelectSingle
                text={t('basicWords.component')}
                options={components}
                selectedOptions={componentId}
                onChange={(val) => this.onComponentSelect(val)}
              />
            </Fragment>
          ) : (
            ""
          )}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <br />
          <GeneralInput
            disabled={button.externalLink === "PAYMENT_PLATFORM"}
            placeholder={t('basicWords.link')}
            value={button.externalLink ? button.externalLink : ""}
            onChange={(val) => this.onButtonLinkChange(val)}
            isReq={true}
          />
        </Fragment>
      );
    }
  };

  handleCancel = () => {
    this.props.onModalCancel();
  };

  handleOk = () => {
    const {t} = this.props
    const { button } = this.state;
    //save button validation according to buttonType
    if (this.state.buttonType === 1) {
      if (button.name && button.section && button.component) {
        button.isEnabled = 1;
        if (!button.isPinned) {
          button.isPinned = 0;
        }
        this.props.onModalOk(button);
      } else {
        if (!button.name) {
          this.setState({
            showError: true,
            contentNotificationModal: t('basicWords.modal.nameButtonEmpty'),
          });
        }
        if (button.name && !button.section) {
          this.setState({
            showError: true,
            contentNotificationModal: t('basicWords.modal.sectionUnselected'),
          });
        }
        if (!button.component && button.section && button.name) {
          this.setState({
            showError: true,
            contentNotificationModal: t('basicWords.modal.componentUnselected'),
          });
        }
      }
    }
    if (this.state.buttonType === 2) {
      if (button.name && button.externalLink) {
        button.isEnabled = 1;
        if (!button.isPinned) {
          button.isPinned = 0;
        }
        this.props.onModalOk(button);
      } else {
        if (!button.name) {
          this.setState({
            showError: true,
            contentNotificationModal: t('basicWords.modal.nameButtonEmpty'),
          });
        }
        if (button.name && !button.externalLink) {
          this.setState({
            showError: true,
            contentNotificationModal: t('basicWords.modal.linkButtonEmpty'),
          });
        }
      }
    }
  };

  setShowError = (open) => {
    this.setState({ showError: open });
  };

  setShowSuccess = (open) => {
    this.setState({ showSuccess: open });
  };

  setShowWarning = (open) => {
    this.setState({ showWarning: open });
  };

  render() {
    const {
      button,
      buttonType,
      // Modals
      showSuccess,
      showWarning,
      showError,
      contentNotificationModal
    } = this.state;
    const {t} = this.props;
    if (buttonType === 1) {
      delete button.externalLink;
    }
    if (buttonType === 2) {
      delete button.component;
      delete button.section;
    }
    let modalName = this.props.button
      ? t('basicWords.buttons.editButton')
      : t('basicWords.buttons.addButton');

    return (
      <div>
        {!!showWarning && (
          <SystemNotification
            setShowNotification={(e) => this.setShowWarning(e)}
            type="Warning"
            caption={contentNotificationModal}
          />
        )}
        {!!showSuccess && (
          <SystemNotification
            setShowNotification={(e) => this.setShowSuccess(e)}
            type="Success"
            caption={contentNotificationModal}
          />
        )}
        {!!showError && (
          <SystemNotification
            setShowNotification={(e) => this.setShowError(e)}
            type="Error"
            caption={contentNotificationModal}
          />
        )}
        <GeneralModal
          visible={this.props.isModalVisible}
          title={modalName}
          body={[
            <div>
              <RadioGroup
                onChange={(val) => this.onButtonTypeChange(val)}
                value={buttonType ? buttonType : ''}
                disabled={button.externalLink === "PAYMENT_PLATFORM"}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={t('basicWords.buttons.ownComponent') + "  "}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label={t('basicWords.buttons.externalLink') + "  "}
                />
              </RadioGroup>
              <br />
              <GeneralInput
                placeholder={t('basicWords.name')}
                tooltip={t('basicWords.buttons.nameTooltip')}
                value={button.name ? button.name : ""}
                onChange={(val) => this.onButtonNameChange(val)}
                isReq={true}
                maxLength={45}
              />
              <br />
              {this.renderAddButtonInputs()}
            </div>,
          ]}
          footer={[
            <div className="row" key={1} style={{ display: "flex" }}>
              <div className="col-6">
                <ButtonComponent
                  onClick={() => this.handleCancel()}
                  props={null}
                  shape="round"
                  className="buttonDiscard"
                  text={t('basicWords.discard')}
                />
              </div>
              <div>
                <ButtonComponent
                  onClick={() => this.handleOk()}
                  props={null}
                  shape="round"
                  text={t('basicWords.save')}
                />
              </div>
            </div>,
          ]}
          onClick={() => this.handleCancel()}
        />
      </div>
    );
  }
}
export default withTranslation()(AddButtonModal)