import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CardContent } from "@material-ui/core";
import moment from "moment";

// Components
import HeaderComponent from "./HeaderComponent.jsx";
import MenuComponent from "./MenuComponent.jsx";
import FooterComponent from "./FooterComponent.jsx";

//import Modal from "../../uhoo-components/Modal.jsx";

import { NotificationModal } from "../../aura-components/index";

// Queries
import { getUserSessionInformation, refreshToken } from "../../actions/index";

// Actions
import userSessionActions from "../../reactUtils/actions/privilege.actions";

// CSS
import "../../assets/styles/Uhoo.scss";
//import "./css/CMSMainContent.scss";
import { withTranslation } from "react-i18next";

import  "../../index.css";


// const { Content } = Layout;
const { userPrivileges, userSession } = userSessionActions;
let chroneTime = 0;

class CMSMainContent extends Component {
  static propTypes = {
    body: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      height: 0,

      userSessionInformation: {
        userId: null,
        name: null,
        lastName1: null,
        lastName2: null,
        documentIdNumber: null,
        organizationId: null,
        organizationName: null,
      },

      //Modal notification
      haveChangeInTheme: false,
      openNotificationModal: false,
      titleNotificationModal: "",
      contenNotificationModal: "",
      typeNotificationModal: "",
    };
  }

  componentDidMount() {
    const { userSession, userPrivileges } = this.props;
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    // Get saved token information
    const accessToken = sessionStorage.getItem("access_token"); /// ???

    const base64Url = accessToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const payload = JSON.parse(jsonPayload);

    const expDateMiliseconds = payload.exp * 1000;
    const dateInMiliseconds = moment().unix() * 1000;

    // Chrone to verify token duration
    chroneTime = expDateMiliseconds - dateInMiliseconds - 800000;

    const userSessionInfo = { /// set here

      id: payload.user_id,
      username: payload.user_name,
      organizationId: payload.organization_id,



    };
    //this.getNewToken();
    //this.getUserSessionInformationFunction();




    // Save user session information and privileges
    let userData = userSession(userSessionInfo);
    userPrivileges(payload.authorities);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight - 64 - 70 - 15,
    });
  };


  async getUserSessionInformationFunction() {


    //console.log("En getUseSessionInfo");

    const userSessionInformation = { ...this.state.userSessionInformation };

    var objSessionInformation = JSON.parse(sessionStorage.userInfo); // INFO


    userSessionInformation.userId = objSessionInformation.uuid;
    userSessionInformation.name = objSessionInformation.name;
    userSessionInformation.lastName1 = objSessionInformation.last_name;
    userSessionInformation.lastName2 = objSessionInformation.last_name;

    userSessionInformation.documentIdNumber = "identityCardNumber";
    userSessionInformation.organizationId = "organizationId";
    userSessionInformation.organizationName = "organizationName";

    //console.log(userSessionInformation); ///////////////////////////

    this.setState({ userSessionInformation });
  }
/*
  async getRefreshTokenFunction() {
    const response = await refreshToken();

    if (String(response).includes("Error:")) {
      // this.setState({
      //   titleNotificationModal: Strings.modal.errorAuthentication,
      //   contenNotificationModal: Strings.modal.errorAuthenticationToLogin,
      //   typeNotificationModal: "warning",
      // });
      // this.setState({ openNotificationModal: true });
    }
  }

  getNewToken = () => {
    setInterval(() => {
      this.getRefreshTokenFunction();
    }, chroneTime);
  };
*/
  // reload web page due an error authentication
  handleOkWarning = () => {
    localStorage.clear();
    sessionStorage.clear();

    window.location.reload(false);
  };

  handleCloseNotificationModal = () => {
    this.setState({ openNotificationModal: false });
  };

  render() {
    const { body } = this.props;
    const { t } = this.props
    const {
      userSessionInformation,
      openNotificationModal,
      titleNotificationModal,
      contenNotificationModal,
      typeNotificationModal,
    } = this.state;
    return (
      <React.Fragment>
        <MenuComponent />
        <CardContent className={"content"}>{body}</CardContent>
        {/* <HeaderComponent /> */}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    privileges: state.userSession.privileges,
  };
}

export default connect(mapStateToProps, {
  userPrivileges,
  userSession,
})(withTranslation()(CMSMainContent));
