import React, {useState, useEffect} from "react";
import '../../css/react-tabs.scss';
import "./css/community.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from "@material-ui/core";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

//Localization
import { useTranslation } from 'react-i18next';

const TableUsers = (props) => {
    const { t, i18n } = useTranslation();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
        },
    },
    };
    let titles = [];
    for (const col in  props.columns) {
        titles.push(props.columns[col]);
    }
    let { AudienceData, onlyOneAudience, rolData, onlyOneRol, type } = props;
    
    props.usersSelected.map(user => {return user.uuid})

    return (
    <div className="Content">
        {
            props && props.users && props.users.length ? 
            <TableContainer style={{ maxHeight: "440" }}>
            <div className="contentleftImportUsersSelect">
            <label className="MuiTableCell-body"><input type="checkbox" id="select_all" value="select_all" onChange={()=> props.onChange(props.users, type)} /> {t('basicWords.selectAll')}</label>
            </div>
            <Table>
              <TableHead className="tableHead">
                <TableRow className="tableRowHeader">
                    { props.disableInput ? null : <TableCell>Select</TableCell> }
                    { titles.map(title =>{  return(<TableCell>{t(`basicWords.${title}`)}</TableCell>) }) }
                    {AudienceData && !onlyOneAudience ?  <TableCell>Audiencia</TableCell> : null }
                    {rolData && !onlyOneRol && type == 'add' ?  <TableCell>Rol</TableCell> : null }
                </TableRow>
              </TableHead>
              <TableBody>
                { props.users.map(prop =>{
                    return (
                        <>
                        <div className="spaceIntoRows" />
                        <TableRow key={prop.uuid} className="tableRow">
                            {
                                props.disableInput ? null :
                                <div className="contentImportUsersSelect">
                                    <input type="checkbox" 
                                    checked={ type == 'add' ? props.usersSelected.includes(prop.email) ? true : false : props.usersSelected.includes(prop.uuid) ? true : false}  
                                    id={prop.uuid} onChange={()=> props.onChange(prop, type)}/>
                                </div>
                            }
                            { titles.map(title => {return ( <TableCell>{prop[title]}</TableCell>) }) }
                            {
                                AudienceData && !onlyOneAudience? 
                                <TableCell className="tableRow">
                                    <FormControl sx={{ m: 1, width: 300 }}>
                                        <Select id="demo-multiple-checkbox-audience" multiple labelId="demo-multiple-checkbox-audience"
                                        value={prop.nameAudiences} onChange={(e)=> props.onSelectAudienceUser(prop, type, e)}
                                        renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps} >
                                            {AudienceData.length > 0 ?
                                            AudienceData.map((audience) => (
                                                <MenuItem key={audience.uuid} value={audience}>
                                                <Checkbox checked={prop.nameAudiences.indexOf(audience.name) > -1} />
                                                <ListItemText primary={audience.name} />
                                                </MenuItem>
                                            ))
                                            :
                                            <p className="MuiTableCell-body">{t('basicWords.dontHaveAudiences')}</p>}
                                        
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                : null
                            }
                            {
                                rolData && !onlyOneRol && type == 'add' ? 
                                <TableCell className="tableRow">
                                    <FormControl sx={{ m: 1, width: 300 }}>
                                        <Select id="demo-multiple-checkbox-rol" labelId="demo-multiple-checkbox-rol"
                                            value={prop.nameRoles} onChange={(e)=> props.onSelectRolUser(prop, type, e)}
                                            renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps} >
                                                {rolData.length > 0 ?
                                                rolData.map((rol) => (
                                                    <MenuItem key={rol.uuid} value={rol}>
                                                    <Checkbox checked={prop.rol.uuid == rol.uuid} />
                                                    <ListItemText primary={rol.name} />
                                                    </MenuItem>
                                                ))
                                            :
                                                <p className="MuiTableCell-body">{t('basicWords.dontHaveRol')}</p>
                                                }
                                        
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                : null
                            }
                        </TableRow>
                        </>
                    )})
                }
              </TableBody>
            </Table>
          </TableContainer>
            : <h1>No hay usuarios</h1>
        }
    </div>
  );  
};

export default TableUsers;

