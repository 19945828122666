import axios from 'axios';

import { protocol, host, port, route, dataSource } from '../../reactUtils/systemVariables/UhooServerInformation';

const query = "/rol/";

const userToken = "Bearer " + sessionStorage.access_token;

export async function GetUserRol(Params) {
  
  let sendParams = "";

  if (Params !== undefined || null){

    if (typeof Params !== "string")
    {
      //sendParams += "?"
      Params.forEach(item => (item.key === 'page') ? sendParams += "page/" + item.value + "?" : sendParams += ""); // Get Post Page --> Si Value no es definido por defecto va a ser 10
      Params = Params.filter((item) => item.key !== 'page');

      Params.forEach(item => (item.key === 'size') ? sendParams += "size/" + item.value + "?" : sendParams += ""); // Get Size Post Filter
      Params = Params.filter((item) => item.key !== 'size');

      Params.forEach(item => sendParams += "?" + item.key + "=" + item.value + "&");  // Envia otros parametros adicionales Ej: Get Post Page Filter

    } else {  sendParams = Params }   // Asigna el String como parametro de la consulta Ej: main o size
    
  } else { sendParams = ""; }
  
    //const objSessionInformation = JSON.parse(sessionStorage.userInfo);
    //const userToken = "Bearer " + objSessionInformation.token;
  
    try {
        let GetUserRolData = await axios({
        method: 'GET',
        url: protocol + host + port + route + dataSource + query + sendParams,      
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userToken,
          }
        })
      
      if (!GetUserRolData.error) {
 
        return GetUserRolData.data;
        //console.log("Consulta inicial: ", GetUserRolData);
  
      } 
  
    } catch (error) {
      return error;
    };
  } 

  export async function putRole(data) {
    try {
      let config = await axios({
        method: 'PUT',
        url: protocol + host + port + route + dataSource + query + data.uuid,      
        headers: {
          'Content-Type': 'application/json',
          'Authorization': userToken,
        },
        data: data
      })
      if(config) {
        return ("Success", config.data);
      }
      return null;
    } catch (error) {
      return error;
    };
  }

  export async function postRole(data) {
    try {
      let config = await axios({
        method: 'POST',
        url: protocol + host + port + route + dataSource + query,      
        headers: {
          'Content-Type': 'application/json',
          'Authorization': userToken,
        },
        data: data
      })
      if(config) {
        return config.data;
      }
      return null;
    } catch (error) {
      return error;
    };
  }

  export async function deleteRole(data) {
    try {
      let config = await axios({
        method: 'DELETE',
        url: protocol + host + port + route + dataSource + query + data,      
        headers: {
          'Content-Type': 'application/json',
          'Authorization': userToken,
        },
      })
      if(config) {
        return config.data;
      }
      return null;
    } catch (error) {
      return error;
    };
  }