// const generate = require('string-to-color')
import Compressor from 'compressorjs';

export function fileThumbnailer (file, params = {}) {
  if (file.type.indexOf('image') > -1) return resizeFileImageWithCanvas(file, Object.assign({ height: 120, width: 120 }, params))
  if (file.type.indexOf('video') > -1) return videoThumbnailer(URL.createObjectURL(file), file.name.split('.')[0], params)
  return new Promise((resolve, reject) => {
    const name = file.name.split('.')
    const ext = name.length > 1 ? name[name.length - 1].substring(0, 3) : name[0].substring(0, 3)
    let canvas = document.createElement("canvas")
    canvas.height = 120
    canvas.width = 120
    let ctx = canvas.getContext('2d')
    
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.font = '40px Arial'
    ctx.textBaseline = 'middle'
    // ctx.fillStyle = generate(ext.toLowerCase())
    ctx.textAlign = 'center'
    ctx.fillText('.' + ext.toLowerCase(), canvas.width/2, canvas.height/2)
    resizeFileImageWithCanvas(base64ToFile(canvas.toDataURL(), { name: `${name[0]}.png` }))
      .then(resolve)
      .catch(reject)
  })
}

export function base64ToFile (dataURI, paramsFile = {name: 'file'}) {
  var byteString, mimestring;

  if(dataURI.split(',')[0].indexOf('base64') !== -1 ) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = decodeURI(dataURI.split(',')[1]);
  }

  mimestring = dataURI.split(',')[0].split(':')[1].split(';')[0];

  var content = new Array();
  for (var i = 0; i < byteString.length; i++) {
    content[i] = byteString.charCodeAt(i);
  }

  var newFile = new File(
    [new Uint8Array(content)], paramsFile.name, {type: mimestring}
  );


  // Copy props set by the dropzone in the original file
  delete paramsFile.name

  for (const key in paramsFile) {
    if (paramsFile.hasOwnProperty(key)) {
      newFile[key] = paramsFile[key];
    }
  }

  return newFile;
}


export function videoThumbnailer (videoB64, name, params) {
  const secondOfThumbnail = 3
  return new Promise((resolve, reject) => {
    if (typeof videoB64 !== 'string') throw new Error('Video source param is not string')
    const videoTag = document.createElement('video')
    let canvas = document.createElement("canvas")

    videoTag.src = videoB64
    videoTag.addEventListener('loadedmetadata', () => {
      canvas.width = videoTag.videoWidth
      canvas.height = videoTag.videoHeight
      videoTag.currentTime = secondOfThumbnail
    })
    videoTag.addEventListener('timeupdate', () => {
      name = `${name}.png`
      const ctx = canvas.getContext('2d')
      ctx.drawImage(videoTag, 0, 0, videoTag.videoWidth, videoTag.videoHeight)
      resizeFileImageWithCanvas(base64ToFile(canvas.toDataURL(), { name }), Object.assign({ height: 120, width: 120 }, params))
        .then(resolve)
        .catch(reject)
    })
  })
}


export function resizeFileImageWithCanvas (file, params = {}) {
  return new Promise(async (resolve, reject) => {
    var reader = new FileReader();
    // Convert file to img
    reader.addEventListener("load", event => {
      var origImg = new Image();
      origImg.src = event.target.result;
      // Reading image
      origImg.addEventListener("load", () => {
        var resizedFile = base64ToFile(resizeImageWithCanvas(origImg, params), file);
        resolve(resizedFile)
      });

      origImg.onabort = () => reject('Abort Image')
      origImg.onerror = reject
    });

    reader.onabort = () => reject('Abort File')
    reader.onerror = reject
    reader.readAsDataURL(file);
  })
}

export function resizeImageWithCanvas(img, params = {}) {
  let MAX_WIDTH = params.width || 1200;
  let MAX_HEIGHT = params.height || 1200;
  let OUTPUT_QUALITY = params.quality || .7;

  let width = img.width;
  let height = img.height;

  // Don't resize if it's small enough
  if (width > MAX_WIDTH || height > MAX_HEIGHT) {
    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
  }

  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");

  ctx.drawImage(img, 0, 0);
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(img, 0, 0, width, height);

  if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
    return canvas.toDataURL("image/jpeg", OUTPUT_QUALITY);
  } else {
    return canvas.toDataURL("image/jpeg");
  }
}

export function compressImage(imageFile) {
  return new Promise((resolve, reject) => {
    new Compressor(imageFile, {
      quality: 0.6,
      success(result) {
        return resolve(result)
      },
      error(err) {
        console.log(err.message);
        return reject(err)
      },
    });
  })
}